import { Component, OnInit, Input } from '@angular/core';
import { ImagePreviewComponent } from '../image-preview/image-preview.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import {combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
@Component({
  selector: 'app-view-file',
  templateUrl: './view-file.component.html',
  styleUrls: ['./view-file.component.css']
})
export class ViewFileComponent implements OnInit {

  @Input() title: string;
  @Input() patientInteractionId: string;
  @Input() docId:any;
  @Input() benificiaryId:any;
  @Input() isPocScreen: any;
  @Input() is_self:any;
  image: string = '';
  origin: string = '';
  previewFileList: any
  previewFileListNew: Array<any> = [];
  result:any;
  // benId:any;
  // doctorId:any;

  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private registrationService: RegistrationService
    , private modalService: NgbModal) {
    // this.origin = this.registrationService.viewFilePath;
  }

  ngOnInit(): void {
    // if (+this.patientInteractionId > 0) {
    //   this.registrationService.getviewFile(`beneficiary/getinvestigationfiles?patientInteractionId=${this.patientInteractionId}&viewFile=true`)
    //     .subscribe(res => {
    //       if (res.status == 'OK') {
    //         if (res.data == null || res.data.length == 0)
    //           this.toastr.info('No records available', 'View Files');
    //         else
    //           this.previewFileList = res.data;
    //       }
    //     });
    // }
    // if (+this.patientInteractionId > 0) {
    //   this.registrationService.getviewFile(`beneficiary/getinvestigationfiles?patientInteractionId=${this.patientInteractionId}&viewFile=true`)
    //     .subscribe(res => {
    //       if (res.status == 'OK') {
    //         if (res.data == null || res.data.length == 0)
    //           this.toastr.info('No records available', 'View Files');
    //         else
    //           this.previewFileList = res.data;
    //       }
    //     });
    // }
      // this.registrationService.getviewFileNew(`beneficiary/getbeneficiaryinvestigationfiles?beneficiaryid=${this.benificiaryId}&doctorid=${this.docId}`)
      //   .subscribe(res => {
      //     if (res.status == 'OK') {
      //       if (res.data == null || res.data.length == 0)
      //         this.toastr.info('No records available', 'View Files');
      //       else
      //         this.previewFileListNew = res.data;
      //     }
      //   });
    //console.log(this.docId)
          // combineLatest([
          //   this.registrationService.getviewFile(`beneficiary/getinvestigationfiles?patientInteractionId=${this.patientInteractionId}&viewFile=true`),
          //   this.registrationService.getviewFileNew(`beneficiary/getbeneficiaryinvestigationfiles?beneficiaryid=${this.benificiaryId}&doctorid=${this.docId}&viewFile=true`)
          // ]).pipe(
          //   map(([pidData, benData]) => {
          //     const result = [];
          //     result.push(
          //       {
          //         name: 'PID',
          //         response: pidData.data
          //       }
          //     );
          //     result.push(
          //       {
          //         name: 'Benificiary',
          //         response: benData.data
          //       }
          //     );
          //     return {
          //       result
          //     };
          //   })
          // ).subscribe( rolesData => {
          //   this.previewFileList = rolesData.result;
          // //  console.log(this.previewFileList)
          // });

          // old view files functionality 
          // if(this.isPocScreen){
          //   combineLatest([
          //     this.registrationService.getviewFile(`beneficiary/getinvestigationfiles?patientInteractionId=${this.patientInteractionId}&viewFile=true`),
          //    // this.registrationService.getviewFileNew(`beneficiary/getbeneficiaryinvestigationfiles?beneficiaryid=${this.benificiaryId}&doctorid=${this.docId}&viewFile=true`)
          //   ]).pipe(
          //     map(([pidData]) => {
          //       const result = [];
          //       result.push(
          //         {
          //           name: 'PID',
          //           response: pidData.data
          //         }
          //       );
          //       // result.push(
          //       //   {
          //       //     name: 'Benificiary',
          //       //     response: benData.data
          //       //   }
          //       // );
          //       return {
          //         result
          //       };
          //     })
          //   ).subscribe( rolesData => {
          //     this.previewFileList = rolesData.result;
          //   });
          //   if(this.is_self){
          //     combineLatest([
          //       // this.registrationService.getviewFile(`beneficiary/getinvestigationfiles?patientInteractionId=${this.patientInteractionId}&viewFile=true`),
          //       //  this.registrationService.getviewFileNew(`beneficiary/getbeneficiaryinvestigationfiles?beneficiaryid=${this.benificiaryId}&doctorid=${this.docId}&viewFile=true`)
          //       this.registrationService.getviewFileNew(`beneficiary/investigationfiles?beneficiaryid=${this.benificiaryId}`)
          //     ]).pipe(
          //        map(([pidData]) => {
          //          const result = [];
          //          result.push(
          //            {
          //              name: 'PID',
          //              response: pidData.data
          //            }
          //          );
          //          // result.push(
          //          //   {
          //          //     name: 'Benificiary',
          //          //     response: benData.data
          //          //   }
          //          // );
          //          return {
          //            result
          //          };
          //        })
          //      ).subscribe( rolesData => {
          //       // for(let i=0;i<rolesData.result.length;i++){
          //        //  this.previewFileList.push(rolesData.result[i]);
          //       //   }
          //       this.previewFileList = rolesData.result;
          //      });
          //   } else {
          //     combineLatest([
          //       // this.registrationService.getviewFile(`beneficiary/getinvestigationfiles?patientInteractionId=${this.patientInteractionId}&viewFile=true`),
          //    //    this.registrationService.getviewFileNew(`beneficiary/getbeneficiaryinvestigationfiles?memberId=${this.benificiaryId}&doctorid=${this.docId}&viewFile=true`)
          //    this.registrationService.getviewFileNew(`beneficiary/investigationfiles?memberId=${this.benificiaryId}`)
          //   ]).pipe(
          //        map(([pidData]) => {
          //          const result = [];
          //          result.push(
          //            {
          //              name: 'PID',
          //              response: pidData.data
          //            }
          //          );
          //          // result.push(
          //          //   {
          //          //     name: 'Benificiary',
          //          //     response: benData.data
          //          //   }
          //          // );
          //          return {
          //            result
          //          };
          //        })
          //      ).subscribe( rolesData => {
          //         //for(let i=0;i<rolesData.result.length;i++){
          //        // this.previewFileList = rolesData.result;
          //        this.previewFileList = rolesData.result;
          //        //  }

          //     });
          //   }
           
          // }else {
          //   combineLatest([
          //     this.registrationService.getviewFile(`beneficiary/getinvestigationfiles?patientInteractionId=${this.patientInteractionId}&viewFile=true`),
          //    // this.registrationService.getviewFileNew(`beneficiary/getbeneficiaryinvestigationfiles?beneficiaryid=${this.benificiaryId}&doctorid=${this.docId}&viewFile=true`)
          //   ]).pipe(
          //     map(([pidData]) => {
          //       const result = [];
          //       result.push(
          //         {
          //           name: 'PID',
          //           response: pidData.data
          //         }
          //       );
          //       // result.push(
          //       //   {
          //       //     name: 'Benificiary',
          //       //     response: benData.data
          //       //   }
          //       // );
          //       return {
          //         result
          //       };
          //     })
          //   ).subscribe( rolesData => {
          //     this.previewFileList = rolesData.result;
          //   });
          // }
       
      this.onViewFiles();
  }

  // sushma view file 03-08-2022
  onViewFiles(){
    if(this.is_self){
    this.registrationService.getviewFile(`beneficiary/getBeneficiaryInvestigationFiles?patientInteractionId=${this.patientInteractionId}&beneficiaryId=${this.benificiaryId}&viewFile=true`).subscribe(res => {
      if (res.status === 'OK') {
        if (res.data) {
          this.previewFileList = res.data;
        }
      }
    },
      );}
    else{
      {
        this.registrationService.getviewFile(`beneficiary/getBeneficiaryInvestigationFiles?patientInteractionId=${this.patientInteractionId}&memberId=${this.benificiaryId}&viewFile=true`).subscribe(res => {
          if (res.status === 'OK') {
            if (res.data) {
              this.previewFileList = res.data;
            }
          }
        },
          );}
    }
    
  }

  openModal() {
    const modalRef = this.modalService.open(ImagePreviewComponent, { size: 'lg', scrollable: true });
    modalRef.componentInstance.image = this.origin;
  }

  showImageFile(item:any ,file: string) {
    let fileType: string = file.split('.').pop().toLowerCase();
    this.origin = `${this.registrationService.viewFilePath}${file}`;

    (fileType == 'pdf') ? this.showPDF_File() : this.openModal();
  }

  showPDF_File() {
    window.open(this.origin, '_blank', 'left=300,top=50,width=700,height=600,toolbar=1,resizable=0');
  }
}