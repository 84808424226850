import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { Common } from 'src/app/common';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FileService } from 'src/app/shared/services/file.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
import { ImagePreviewComponent } from '../../modals/image-preview/image-preview.component';

declare let $: any;

@Component({
  selector: 'app-agent-registration',
  templateUrl: './agent-registration.component.html',
  styleUrls: ['./agent-registration.component.css'],
  providers: [DatePipe],
})

export class AgentRegistrationComponent implements OnInit {

  @Input() isEditable: boolean = false;
  @Input() title: string; @Input() agent: any; @Input() sbmtDsbld: boolean = false; agentForm: FormGroup; logUsr: any; isNIG = environment.isNIG;
  mobPtrn = ''; mobErrMsg = ''; maxLngth = ''; tentId = sessionStorage.getItem('currentTenant'); isSAGE = environment.isSAGE;
  locationTypeList = []; stateList = []; districtList:any=[]; mandalList = []; villageList = []; labList = []; pharmacyList = []; cityList: [];
  uploadFileList: Array<{
    id: number, reg_id: number, name: string, File: any, extensions: string, file_name: string, fileType: string,
    document_path: string
  }> = []; bankList = []; genderList = []; languagesList = []; selectedItems_language = []; ddLangStngs = {};
  maxDOB = new Date(new Date().setFullYear(+`${new Date().getFullYear() - 18}`)); submitted = false; isShwPic = false; isEdit = false;
  getAllcenters; getagenttypes; getallhubslist;
  adminRoleEnabledFlag: any;
  pharmacyDropdown: any;
  labDropdown: any;
  constructor(public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , private toastr: ToastrService
    , private datePipe: DatePipe
    , private formBuilder: FormBuilder
    , private fileService: FileService
    , private registrationService: RegistrationService
    , private authServ: AuthenticationService
    , private notificationService: NotificationService
    , private userServ:UserService
    , private masterService: MasterService) {
    this.tentId = sessionStorage.getItem('currentTenant');

  }

  get f() { return this.agentForm.controls; }

  ngOnInit(): void {
    ['AadharCard'].forEach((e, i) => this.uploadFileList.push({
      id: 0, reg_id: i + 1, name: e, File: null, extensions: '', file_name: '',
      fileType: ['12'][i], document_path: ''
    })), ['jpg,jpeg,pdf'].forEach((e, i) => this.uploadFileList[i].extensions = e);
    this.logUsr = this.authServ.currentUserValue, this.ddLangStngs = {
      singleSelection: false, idField: 'id', textField: 'language_name',
      itemsShowLimit: 3, allowSearchFilter: true, selectAllText: 'Select All', unSelectAllText: 'Deselect All',
    },
      this.authServ.mobPtrnSubject.subscribe(s => this.mobPtrn = s), this.authServ.mobErrMsgSubject.subscribe(s => this.mobErrMsg = s),
      this.authServ.maxLngthSubject.subscribe(s => this.maxLngth = s), this.getAllMasters(), this.initFormControls()
    this.locationChanged('', 'state') //, Common.setFocus('doctor_type_id');
      // this.locationChanged('36', 'district');//QA Pointing
   this.locationChanged('5', 'district');//Training Pointing
    if (this.isNIG)
      this.agentForm.get('aadharFile').setValidators(null), this.agentForm.get('aadharFile').updateValueAndValidity();
    this.getallcentersGrid();
    this.agenttypes();
    this.getallhubsdata();
  }

  initFormControls() {
    this.agentForm = this.formBuilder.group({
      title_id: [1],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      gender_id: [null, Validators.required],
      email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      mobile: ['', [Validators.required, Validators.pattern(this.mobPtrn)]],
      address: ['', Validators.required],
      bank_account_number: [''],
      location_type_id: ['', Validators.required],
      bank_id: [null],
      agent_type: ['', Validators.required],
      birth_date: ['', Validators.required],
      payment_by_agent: [true, Validators.required], // self - true, patient - false
      payment_type: ['Pre Paid'],
      //centerMapping:[0],
      center_id: [null],
      max_payment_capacity: [null],
      send_notification: [false],
      branch_location: [''],
      ifsc_code: ['', Validators.pattern('[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$')],
      //  state_id: [36, Validators.required],//QA Pointing
     state_id: [5, Validators.required],//Training Pointing
      district_id: ['', Validators.required],
      city_id: [''],
      mandal_id: [''],
      village_id: [''],
      pin_code: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      user_types: [[{ user_type_id: 13 }]],
      preferred_lab_id: [null],
      preferred_pharmacy_id: [null],
      login_name: [''],
      password: ['123456'],
      alternate_mobile_no: [this.logUsr?.alternate_mobile_no ?? '', [Validators.maxLength(+this.maxLngth), Validators.pattern(this.mobPtrn)]],
      registration_source_id: [this.logUsr?.master_registration_source?.id ?? 1],
      branch_id: [this.logUsr?.orgnization_branch?.id ?? 0],
      account_activated: [true],
      is_active: [true],
      organization_id: [+sessionStorage.getItem('logUsrRoleId') == 1 ? +sessionStorage.getItem('currentTenant') : (this.logUsr?.orgnization_registration?.id ?? 1)],
      aadharFile: ['', Validators.required],
      id: [0],
      is_healthcard: [false],
      hub_id: [null],
      center_type: ['']
      // agent_languages: ['', Validators.required],
      // agent_fee: [''],
      // account_holder_name: [''],
    });
  }
  getUserPermission() {
    let orgIdValue = JSON.parse(sessionStorage.getItem('currentTenant'));
    this.userServ.getUserPermissionByOrgId(orgIdValue).subscribe((res: any) => {
      if (res.status == 'OK') {
        for (let i = 0; i < res.data.length; i++) {
        
          if (res.data[i].feature_id.feature_name ==="pharmacyDropdown") {
          
            if (res.data[i].patient) {
              this.pharmacyDropdown = true;
            } else {
              this.pharmacyDropdown = false;
            }
          } if (res.data[i].feature_id.feature_name ==="labRegistrationDropdown") {
            
            if (res.data[i].patient) {
              this.labDropdown = true;
            } else {
              this.labDropdown = false;
            }
          }
        
        
        }
      }
    });
  }

  getAllMasters() {
    const lab = this.registrationService.getAll('lab/getalllabregistrations');
    const pharmacy = this.registrationService.getAll('pharmacy/getallrecords');
    const locationType = this.masterService.getAll('getalllocationtypes');
    const banks = this.masterService.getAll('getallmasterbankdetails');
    const gender = this.masterService.getAll('getallgenders');
    const languages = this.masterService.getAll('getalllanguages');

    forkJoin([lab, pharmacy, locationType, banks, gender, languages]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              console.log('No records available');
            else {
              const assignList = {
                '0': () => this.labList = list.data,
                '1': () => this.pharmacyList = list.data,
                '2': () => this.locationTypeList = list.data,
                '3': () => this.bankList = list.data,
                '4': () => this.genderList = list.data,
                '5': () => this.languagesList = list.data,
              }
              assignList[ind]();
            }
          }
        });
      },
      error: err => console.error('something wrong occurred: ', err),
      complete: () => {
         this.isShwPic = false, 
         this.labList=this.labList.sort((a,b)=>a.lab_name.toLowerCase()>b.lab_name.toLowerCase()?1:-1);
         this.pharmacyList=this.pharmacyList.sort((a,b)=>a.pharmacy_name.toLowerCase()>b.pharmacy_name.toLowerCase()?1:-1);
         this.editAgent(); }
    });
  }

  locationChanged(id: string, locationType: string) {
    let query: string = '', locations = {
      'location': () => {
        this.mandalList = [], this.villageList = [], this.cityList = [], this.agentForm.get('district_id').setValue('');
        this.agentForm.get('mandal_id').setValue(''); this.agentForm.get('city_id').setValue(''); this.agentForm.get('village_id').setValue('');
      },
      'state': () => { this.districtList = [], this.mandalList = [], this.villageList = [], this.cityList = [], query = 'getallstates'; },
      'district': () => {
        this.districtList = [], this.mandalList = [], this.villageList = [], this.cityList = [];
        if (+id > 0)
          this.agentForm.get('mandal_id').setValue('');
        this.agentForm.get('city_id').setValue('');
        //  query = `getstatebyid?stateId=${36}`; //QA Pointing
       query = `getstatebyid?stateId=5`;//Training Pointing
      },
      'mandal': () => {
        this.mandalList = [], this.villageList = [], this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
        this.agentForm.get('village_id').setValue('');
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };
    locations[locationType]();
    if (query.length > 0)
      this.getLocations(query, locationType);
  }

  onChngDD(ctrl: string, val: any) {
    if (val == 'null')
      this.agentForm.get(ctrl).setValue(null);
  }

  getLocations(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
      // this.districtList.sort((a,b) => 0 - (a.district_name > b.district_name ? -1 : 1));
     this.districtList.sort((a,b)=>a.district_name>b.district_name?1:-1);
    }, 
    error => this.toastr.error(error));
  }

  onFileUpload(event: any, fileNumber: number, fileType: string, frmCntrl: string = '') {
    fileNumber = fileNumber - 1;
    let fileExt: string = this.uploadFileList[fileNumber].extensions;
    if (event.target.files.length > 0) {
      const fileList = event.target, fsize = fileList.files.item(0).size, currentfile = Math.round((fsize / (1024 * 5)));
      if (fileList.files[0]) {
        const fileExtension: string = fileList.files[0].name.split('.').pop().toLowerCase();
        if (!this.uploadFileList[fileNumber].extensions.split(',').includes(fileExtension)) {
          this.toastr.warning(`Please Select ${fileExt} files only`);
          if (frmCntrl != '') {
            this.agentForm.get(frmCntrl).clearValidators(), this.agentForm.get(frmCntrl).setValue(''), // this.cancelFile(fileNumber+1), 
              this.agentForm.get(frmCntrl).setValidators([Validators.required]), this.agentForm.get(frmCntrl).updateValueAndValidity();
          }
          return false;
        }
      }
      if (currentfile >= (1024 * 5)) {
        this.toastr.error(fileList.files[0].name + ' too Big, please select a file less than 5MB', 'Doctor Registration');
        return;
      }
      else {
        this.uploadFileList[fileNumber].File = fileList.files.item(0), this.uploadFileList[fileNumber].file_name = fileList.files[0].name,
          this.uploadFileList[fileNumber].fileType = fileType;
        if (this.agent) {
          let f = this.agent.doctor_documents?.filter(f => f.file_type_id == +this.uploadFileList[fileNumber].fileType) ?? '';
          this.uploadFileList[fileNumber].id = f.length > 0 ? f[0].id : 0;
        }
      }
    }
  }

  editAgent() {
    var user = this.authServ.currentUserValue;
    if (this.agent) {
      let isFile = false;
      if (user?.user_role_mapping[0].user_type_id.id == 1) {
        this.isEdit = false;
      }
      else{
        this.isEdit = true;
      }
      
      this.isShwPic = true, this.agentForm.patchValue(this.agent), this.agent.agent_documents.forEach(f => {
        const docu = this.uploadFileList.find(e => e.fileType == f.file_type_id);
        if (docu) {
          docu.id = f.id;
          docu.document_path = f.document_path;
          docu.file_name = f.file_name;
          isFile = true;
        }
      });
      if (isFile)
        // console.log('agent resp', this.agent);
      this.agentForm.get('aadharFile').setValidators(null), this.agentForm.get('aadharFile').updateValueAndValidity();
      Promise.all([this.locationChanged(this.agentForm.get('state_id').value, 'district'),
      this.locationChanged(this.agent.district_id, 'city'),
      this.locationChanged(this.agent.district_id, 'mandal'),
      this.locationChanged(this.agent.mandal_id, 'village'),

      ]).then(() => {
        this.agentForm.patchValue({
          district_id: this.agent.district_id,
          mandal_id: this.agent.mandal_id,
          village_id: this.agent.village_id,
          city_id: this.agent.city_id,
          birth_date: new Date(this.agentForm.value.birth_date),
          hub_id: this.agent.hub_id,
          center_id: this.agent.center_id,
          agent_type: this.agent.agent_type
        });
      });
    }
  }

  cancelFile(ind: number, frmCntrl: string = '', fileTypeId: number = 0) {
    let view_File = this.uploadFileList[ind - 1];
    view_File.File = null, view_File.file_name = '';
    if (this.agent) {
      if (fileTypeId > 0) {
        this.agent.doctor_documents.forEach((e, i) => {
          if (e) {
            if (e.file_type_id == fileTypeId)
              this.agent.doctor_documents[i] = null;
          }
        });
        this.uploadFileList.forEach(e => {
          if (e) {
            if (+e.fileType == fileTypeId)
              e.document_path = '', e.file_name = '', e.id = 0;
          }
        });
        this.isShwPic = false;
      }
    }
    if (frmCntrl != '') {
      this.agentForm.get(frmCntrl).clearValidators(), this.agentForm.get(frmCntrl).setValue(''),
        this.agentForm.get(frmCntrl).setValidators([Validators.required]), this.agentForm.get(frmCntrl).updateValueAndValidity();
    }
  }

  viewFile(ind: number) {
    let view_File = this.uploadFileList[ind - 1], imagePath = '';
    const agent_id = +this.agentForm.get('id').value, fileExtension: string = view_File.file_name.split('.').pop().toLowerCase();
    if (fileExtension == 'pdf')
      (view_File.File) ? Common.showPDF_File(URL.createObjectURL(view_File.File)) :
        Common.showPDF_File(`${this.registrationService.viewFilePath}AGENT/${agent_id}/${view_File.name}/${view_File.document_path}`);
    else if (['jpg', 'jpeg', 'pdf'].includes(fileExtension)) {
      const modalRef = this.modalService.open(ImagePreviewComponent, { size: 'lg', scrollable: true });
      if (view_File.File) {
        const reader = new FileReader();
        reader.readAsDataURL(view_File.File);
        reader.onload = (event1: any) => {
          imagePath = event1.target.result;
          modalRef.componentInstance.image = imagePath;
        };
      }
      else {
        imagePath = `${this.registrationService.viewFilePath}AGENT/${agent_id}/${view_File.name}/${view_File.document_path}`;
        modalRef.componentInstance.image = imagePath;
      }
    }
  }

  onFromDateChange() {

  }
  onSubmit(event: any) {
    if(this.agentForm.value.is_active=true){
      this.agentForm.value.account_activated=true;
    }
    else{
      this.agentForm.value.account_activated=false;
    }
    event.preventDefault(), this.submitted = true, Common.getFormValidationErrors(this.agentForm);
    if (this.agent) {
      this.onFromDateChange();
    }
    if (this.agentForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else {
      let payLoad = this.agentForm.getRawValue();
      delete payLoad.aadharFile;
      if (+this.agentForm.get('id').value == 0)
        delete payLoad.id;
      payLoad.birth_date = this.datePipe.transform(payLoad.birth_date, "yyyy-MM-dd"),
        payLoad.login_name = payLoad.mobile,
        //payLoad.payment_by_agent = payLoad.payment_by_agent == 'true';
        payLoad.user_id = this.agent?.user_id;
      this.registrationService.postAPI('agent/registration', payLoad).subscribe(res => {
        if (res.status == "OK") {
          let id: number = +(payLoad.id ?? 0);
          // if (id == 0) {
          //   const notificationData = { email: payLoad.email, mobile_no: +payLoad.mobile, login_name: payLoad.login_name,
          //     name: `${payLoad.first_name} ${payLoad.last_name}` };
          //   this.send_Notification(notificationData);
          // }
          if (this.uploadFileList.some(f => f.File != null))
            this.saveFile(res.data.id, id);
          else
            this.showMessage(id);
        }
        else
          this.toastr.error(res.message);
      }, error => this.toastr.error(error.message));
    }
  }

  saveFile(id: string, record_id: number) {
    let sendAPIfiles = [];
    this.uploadFileList.forEach(item => {
      if (item.File)
        sendAPIfiles.push(this.fileService.uploadFile(Common.form_Data(item.id.toString(), item.File, id, 'AGENT', item.fileType)));
    });

    forkJoin([...sendAPIfiles]).subscribe(res => console.log, err => console.log, () => { this.showMessage(record_id); });
  }

  send_Notification(payload: { email: string, mobile_no: number, name: string, login_name: string }) {
    const postData = {
      "email": payload.email,
      "login_name": payload.login_name,
      "mobile_no": payload.mobile_no,
      "user_id": 13,
      "user_type": "Agent",
      "name": payload.name.toUpperCase()
    }
    this.notificationService.sendNotification(postData).subscribe();
  }

  submitForm() {
    $('#btnsubmit').click();
  }

  onChngPM(val: any) {
    if (val != '') {
      if (val != 'self') {
        // this.agentForm.get('agent_fee').setValue(''), this.agentForm.get('agent_fee').setValidators([Validators.required]),
        //   this.agentForm.get('agent_fee').updateValueAndValidity();
      }
      else {
        ['payment_type', 'max_payment_capacity', 'bank_account_number', 'bank_id', 'branch_name', 'ifsc_code'].  // ['payment_type', 'max_payment_capacity', 'bank_account_number', 'account_holder_name', 'bank_id', 'branch_name', 'ifsc_code'].
          forEach(e => {
            this.agentForm.get(e).setValue('');
            if (this.isNIG && e == 'ifsc_code')
              this.agentForm.get(e).setValidators([Validators.required]);
            else
              this.agentForm.get(e).setValidators(null);
            this.agentForm.get(e).updateValueAndValidity();
          });
      }
    }
  }

  showMessage(id: number = 0) {
    this.activeModal.close('changes saved'), this.toastr.success(`Record ${(id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Pharmacist/Healthcare Professional Registration');
  }
  getallcentersGrid() {
    let payreqt = {
      "state_id": 0,
      "district_id": 0,
      "location_type_id": 0,
      "mandal_id": 0
    }
    this.registrationService.getAllCenters(payreqt).subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info(res.message);
        else
          // this.getAllcenters = res.data;
          this.getAllcenters=res.data.sort((a,b)=>a.center_name.toLowerCase()>b.center_name.toLowerCase()?1:-1);
      }

    })
  }
  agenttypes() {
    this.registrationService.agenttypes().subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info(res.message);
        else
          this.getagenttypes = res.data;
      }

    })
  }
  getallhubsdata() {
    let PayLoad = {
      "state_id": 0,
      "district_id": 0,
      "mandal_id": 0,
      "location_type_id": 0
    }
    this.registrationService.getallhubs(PayLoad).subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0) {
          this.toastr.info(res.message);
        }
        else {
          // this.getallhubslist = res.data;
          this.getallhubslist=res.data.sort((a,b)=>a.hub_name.toLowerCase()>b.hub_name.toLowerCase()?1:-1);
        }
      }

    })
  }

}