import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { Common, Avail } from 'src/app/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { PrescriptionComponent } from '../modals/prescription/prescription.component';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/shared/base.component';
import { MasterService } from 'src/app/shared/services/master.service';
import { ViewFileComponent } from '../modals/view-file/view-file.component';
import { CovidComponent } from '../configuration/covid/covid.component';
import { forkJoin, Subject, Observable } from 'rxjs';
import { ManualprescriptionComponent } from '../modals/manualprescription/manualprescription.component';
import { environment } from 'src/environments/environment';
import { WebcamInitError, WebcamImage } from 'ngx-webcam';
import { FileService } from 'src/app/shared/services/file.service';
import { ImagePreviewComponent } from '../modals/image-preview/image-preview.component';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
import { ConsultationLinkWithAbhaComponent } from './consultation-link-with-abha/consultation-link-with-abha.component';
import { ViewMapComponent } from '../view-map/view-map.component';
declare let $: any;


@Component({
  selector: 'app-consultation',
  templateUrl: './consultation.component.html',
  styleUrls: ['./consultation.component.css'],
  providers: [DatePipe]
})

export class ConsultationComponent extends BaseComponent implements OnInit {
  pipe = new DatePipe('en-US');
  consultForm: FormGroup; orgn = window.location.href.includes('localhost') ? environment.applicationURL : window.origin;
  from_date: any = new Date(); to_date: any = new Date(); max_date = new Date(); calltypesList: Array<any> = []; gendersList: Array<any> = [];
  lstGrid = []; minDate: Date; minToDate: Date; docId = ''; imgPath = ''; submitted = false; modalTitle: string = ''; isPrvwHdn = true;
  isLcl = window.location.href.includes('localhost'); isShowDP = !location.href.includes('vidmedanh');
  
  //#region Video Uplaod
  isDgtl = 'Upload'; dgtlFile: any = null; isUpld = false; isCamAcsd: boolean; httpOptions: any; benfId = ''; patIntId = ''; pocId = '';
  public showWebcam = false; public allowCameraSwitch = true; public multipleWebcamsAvailable = false; public deviceId: string; upldFile = '';
  public videoOptions: MediaTrackConstraints = { }; public errors: WebcamInitError[] = []; public webcamImage: WebcamImage = null;orgId:any;
  private trigger: Subject<void> = new Subject<void>(); private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  //#endregion
  fileName = 'Doctor consultation list.xlsx';
  excelData: any=[];
  roleId = +sessionStorage.getItem('logUsrRoleId');
  adminRoleEnabledFlag: any;
  constructor(config: NgbModalConfig
    , private modalService: NgbModal
    , private route: Router
    , private regService: RegistrationService
    , protected toastr: ToastrService
    , private formBuilder: FormBuilder
    , private datePipe: DatePipe
    , private fileSvc: FileService
    , private masterService: MasterService
    , private http: HttpClient,
    public changeDetection: ChangeDetectorRef,
    private excelDownloadService:ExcelDownloadService
    , private authSvc: AuthenticationService) {
    super();
    this.orgId = JSON.parse(sessionStorage.getItem('currentTenant'))
    config.backdrop = 'static';
    config.keyboard = false;
    authSvc.crntOrgIDSubject.subscribe(s => { this.lstGrid = [], Common.loadDataTable(this.lstGrid, '#table');
      if (location.href.includes('home/consultation'))
        this.ngOnInit();
    }), authSvc.isShowGlblOrg.next(true);
  }
  ngOnInit(): void {
   
    //sushma 08-07-2022 gender is coming properly
    this.getAllMasters();
    // this.authSvc.isShowGlblOrg.next(true), this.authSvc.tenantId = sessionStorage.getItem('defCrntTnnt'),
    //   sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt'));
    let doc = JSON.parse(sessionStorage.getItem('DoctorDetails')); sessionStorage.removeItem('pocEdit');
    this.docId = doc?.id || 0,  this.initFormControls(), this.LoadData();

  }
 // new method added for add consent 15-06-2023
 addConsent(item:any){
  sessionStorage.setItem('benIdConsent',item.beneficiary_id);
  this.route.navigate([`home/consentlist`])
}
  initFormControls() {
    this.consultForm = this.formBuilder.group({
      from_date: [new Date(), Validators.required],
      to_date: [new Date(), Validators.required],
      doctor_id: ['']
    });
  }
  viewMap(item: any = null) {
    const modalRef = this.modalService.open(ViewMapComponent, { scrollable: true, size: 'xl' });
    // modalRef.componentInstance.consultData = item;
    // modalRef.componentInstance.title = 'Covid 19';
    modalRef.result.then(result => { }, (reason) => { });
  }

  get f() { return this.consultForm.controls; }

  LoadData() {
    if (this.lstGrid.length > 0) {
      $('.dataTable').DataTable().destroy();;
      $('.dataTable').DataTable({ destroy: true, searching: false });
    }
    this.submitted = true;
    let sendData = this.consultForm.value;
    let user = this.authSvc.currentUserValue;
   // let user = this.authSvc.currentUserValue;
    this.adminRoleEnabledFlag= user?.user_role_mapping[0].user_type_id.id;
    [sendData.from_date, sendData.to_date] = [this.datePipe.transform(sendData.from_date, 'yyyy-MM-dd'),
    this.datePipe.transform(sendData.to_date, 'yyyy-MM-dd')];  // HH:mm:ss

    sendData.doctor_id = user?.user_role_mapping[0].user_type_id.id == 7 ? this.docId : 0;

    // this.regService.saveData('getdoctorrecordbydate', this.consultForm.value).subscribe(res => {
    this.regService.getConsByDates('web_list', this.consultForm.value).subscribe(res => {
      if (res.status === "ACCEPTED") { // OK
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Doctor Consulation'), this.lstGrid = [];
        else
          this.lstGrid = res.data;

          this.lstGrid.map(res =>{
            if(res.direct_auth_status!=null){
              // let formatData=res.direct_auth_status.split(".");
              res.formated_direct_auth_status=res.direct_auth_status;
              res.authStatus=res.formated_direct_auth_status?.includes('GRANTED')
              // console.log('Auth status',res.authStatus);
              // let da= res.direct_auth_status.split("-",2);
              // // console.log('78787',da);
              // let data= res.direct_auth_status?.split(":");
              // // console.log('@@@@@@',data,res.direct_auth_status);
              // let dateData= data[0].split("-",4);
              // // console.log('$%$%$%$',dateData);
              //   res.authStatus = dateData[0];
                // let date=dateData[3]?.split("T");
                // res.authDate=date[0]+'-'+dateData[2]+'-'+dateData[1];
                // res.timeStamp=data[1];
                // res.timeStamp= new Date(data[1]).toLocaleString(undefined, {timeZone: 'Asia/Kolkata'});
              //   console.log('DDHDHDHD',res.timeStamp);
              //   let dateString = '2019-08-08 08:57:59';
              // let yourDate: Date = new Date(res.timeStamp + 5);
              //   console.log('*&*&&&*&',yourDate);
             }
            if(res.center_latitude && res.center_longitude ){
              res.centreLatLng=res.center_latitude+','+res.center_longitude;
            }else{
              res.centreLatLng='';
            }
           if(res.latitude && res.longitude){
            res.tabLatLng=res.latitude+','+res.longitude;
           } else {
            res.tabLatLng='';
           }
           
            res.formatedVisited_date = this.pipe.transform(res?.visit_date, 'dd-MM-yyyy');
            res.formated_patient_name = (res?.advice?.is_self ? (res?.beneficiary_first_name + ' ' + (res?.beneficiary_middle_name ? res.beneficiary_middle_name: '' ) + ' ' + res?.beneficiary_last_name) : (res?.advice?.family_member?.first_name  + ' ' + res?.advice?.family_member?.last_name)).toUpperCase();
            if(res?.advice?.is_self==true){
              res.formated_gender =  this.setGender(res?.beneficiary_gender_id);
            } else{
             res.formated_gender = this.setGender(res?.advice?.family_member?.gender_id);
            }
           // res.formated_gender=(res?.advice?.is_self == true ?this.getGender(res?.beneficiary_gender_id):this.getGender(res?.advice?.family_member?.gender_id)).toUpperCase();
            res.formated_mobile= res?.advice?.is_self?res?.beneficiary_mobile:(res?.advice?.family_member?.member_mobile != null ? res?.advice?.family_member?.member_mobile : res?.advice?.family_member?.member_mobile);
            res.formated_patient_age= res?.advice?.is_self?res?.beneficiary_age:res?.advice?.family_member?.age;
            res.formated_reg_no= res?.advice?.is_self?res?.beneficiary_id:res?.advice?.family_member?.id;
            res.formated_cons_no= res?.patient_interaction_id;
           if(res?.call_type_id==1){
            res.formated_type_of_call= 'Video';
           } else  if(res?.call_type_id==13){
            res.formated_type_of_call= 'Offline';
           } else {
            res.formated_type_of_call= 'Audio';
           } 
            if(res?.referrad_doctor && res?.referrad_doctor!=null && res?.referrad_doctor!==' '){
              res.formated_doctor_name=(res?.doctor_first_name + ' ' + res?.doctor_last_name).toUpperCase() +','+ (res?.referrad_doctor).toUpperCase();
            }
            else{
              res.formated_doctor_name=(res?.doctor_first_name + ' ' + res?.doctor_last_name).toUpperCase();
            }
      
          })
        
          this.excelData =this.lstGrid;
        Common.loadDataTable(this.lstGrid, '#table',);
      }
    });
  }
  setGender(beneficiary_gender_id: any) {
    if(beneficiary_gender_id==1){
  return 'MALE';
    } else if(beneficiary_gender_id==2){
  return 'FEMALE';
    } else{
      return 'TRANSGENDER'
    }
  }
// new method added for consultation link with ABHA 18-10-2022
openLinkwithAbhaConsultation(item) {
  this.changeDetection.detach();
  // console.log('Item value',item);
  // this.regService.getByID('beneficiary/getbeneficiaryrecordbyid?beneficiaryId=', this.benID).subscribe(res => {
    // if (res.status == "OK") {
      //this.benDetails = res.data
      const modalRef = this.modalService.open(ConsultationLinkWithAbhaComponent, { scrollable: true, size: 'md' });
      modalRef.componentInstance.data = item;
      modalRef.componentInstance.title = this.modalTitle;
      modalRef.result.then(result => {
        this.changeDetection.reattach();
        // if (result == 'changes saved')
          this.LoadData();
      }, (reason) => { });
  //     modalRef.componentInstance.screentitle = 'Edit';
  //   }
  // }, err => { this.toastr.error(err) });
}
  // onFromDateChange(val) {
  //   // this.consultForm.patchValue({to_date:null});
  //   this.minToDate = new Date();
  //   if (val != null) {
  //     let edate = this.datePipe.transform(val, 'yyyy-MM-dd').split('-'), cnvDate = new Date(parseInt(edate[0]), parseInt(edate[1]) - 1, parseInt(edate[2]), 0, 0, 0, 0);
  //     this.minToDate = new Date(cnvDate);
  //   }
  // }

  onFromDateChange(val: Date) {
    if (val) {
      let from_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.consultForm.get('from_date').setValue(new Date(from_date.setDate(from_date.getDate())));
      this.minDate = from_date;
    }
  }
  onToDateChange(val: Date) {
    if (val) {
      let to_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.consultForm.get('to_date').setValue(new Date(to_date.setDate(to_date.getDate())));
      this.minDate = to_date;
    }
  }

  clear() {
    this.consultForm.value.from_date = this.consultForm.value.to_date = '';
  }

  onEditPOC(itemPOC: any) {
    this.route.navigate(['home/poc']), sessionStorage.setItem('pocEdit', JSON.stringify(itemPOC)); // this.route.navigate(['home/poc'], { queryParams: { isEdit: '1' } });
  }

  openModal(item: any = null) {
    const modalRef = this.modalService.open(PrescriptionComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.prescriptionData = item;
    modalRef.result.then(result => {
      // if (result == 'changes saved')
      // this.LoadData();
    }, (reason) => { });
  }

  checkCovid(item) {
    return Object.keys(item).length > 0;
  }

  openModalMP(conId: any, benId: any, ptntIntrId: any) {
    navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then(() => {
      const modalRef = this.modalService.open(ManualprescriptionComponent, { scrollable: true, size: 'xl' });
      modalRef.componentInstance.title = 'Upload Prescription';
      modalRef.componentInstance.consId = conId;
      modalRef.componentInstance.benId = benId;
      modalRef.componentInstance.piId = ptntIntrId;
      modalRef.result.then(result => { if (sessionStorage.getItem('isMPSave') == '1') this.LoadData(); }, (reason) => { });      
    }).catch((err) => { console.log(err), this.toastr.info('Please provide Camera access'); });
  }

  openConsultModal(item: any = null) {
    const modalRef = this.modalService.open(CovidComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.consultData = item;
    modalRef.componentInstance.title = 'Covid 19';
    modalRef.result.then(result => { }, (reason) => { });
  }

  getAllMasters() {
    const genders = this.masterService.getAll('getallgenders');
    const calltypes = this.masterService.getAll('getallcalltypes');
    forkJoin([genders, calltypes]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              console.log('No records available');
            else {
              const assignList = {
                '0': () => this.gendersList = list.data,
                '1': () => this.calltypesList = list.data,
              }
              assignList[ind]();
            }
          }
        });
      },
      error: err => console.error('something wrong occurred: ', err),
      complete: () => { }
    });
  }

  getCallType(id: string): string {
    let result = this.calltypesList.find(c => c.id == id);
    return (result) ? result.call_type : '';
  }

  getGender(id: string): string {
    let result = this.gendersList.find(c => c.id == id);
    return (result) ? result.gender_name : '';
  }

  openViewFileModal(id: string,pid:any,docid:any,advice) {
    const modalRef = this.modalService.open(ViewFileComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = 'View Files';
    modalRef.componentInstance.isPocScreen=false;
    modalRef.componentInstance.patientInteractionId = +id;
    if(advice.is_self){
      modalRef.componentInstance.benificiaryId = pid;
    } else{
      modalRef.componentInstance.benificiaryId = advice.family_member.id;
    }
   
    modalRef.componentInstance.docId = docid;
    modalRef.componentInstance.is_self = advice.is_self;
    //is_self
    modalRef.result.then(result => { }, (reason) => { });
  }

  onViewPresOld(item: any) {
    if (item.beneficiary_visit_files.length > 0) {
      let temp = item.beneficiary_visit_files.filter(f => f.file_type_id == Avail.VstFleFTId), res = temp.length > 0 ? temp[temp.length-1] : null, link = document.createElement("a");
      if (res != null)
        this.imgPath = `${this.orgn}/${environment.viewFilePath}${Avail.VstFilesTxt}${res.document_path.split('_')[0]}/${environment.prescription}/${res.document_path}`, link.setAttribute('href', this.imgPath) ,link.setAttribute('visibility', 'hidden'), link.setAttribute('display', 'none'), link.setAttribute('target', '_blank'), document.body.appendChild(link), link.click();
      else
        this.toastr.info('No files found');
    }
    else
      this.toastr.info('No files found');
  }

  onViewPres(docPath: string) {
    let link = document.createElement("a");
    if (docPath != '' && docPath != null){
      this.imgPath = `${this.orgn}/${environment.viewFilePath}${Avail.VstFilesTxt}${docPath.split('_')[0]}/${environment.prescription}/${docPath}`, 
      link.setAttribute('href', this.imgPath) ,
      link.setAttribute('visibility', 'hidden'), 
      link.setAttribute('display', 'none'), 
      link.setAttribute('target', '_blank'), 
      document.body.appendChild(link), link.click();
    }
      
    else
      this.toastr.info('No files found');
  }

  //#region WebCam
  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errors.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean|string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage; console.info('received webcam image', webcamImage);
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }
 
  uploadImg() {
    let eFile = null;
    if (this.isDgtl == 'Upload') {
      if (this.dgtlFile)
        eFile = this.dgtlFile;
    }
    else {
      if (this.webcamImage)
        eFile = this.dataURItoBlob(this.webcamImage.imageAsDataUrl);
    }
    if (eFile != null) {
      this.fileSvc.uploadFile(Common.form_Data('0', eFile, this.benfId, Avail.BnfcryTxt.replace('/', ''),
        Avail.MnlPresFTId.toString(), this.patIntId, true)).subscribe(s => {
        if (s.status == "success") {
          this.regService.genPres(this.pocId).subscribe(s => { this.toastr.success('File Uploaded successfully'), this.isUpld = false,
            this.dgtlFile = null, this.pocId = '', this.benfId = '', this.patIntId = '', this.LoadData();
          });
        }
        else
          this.toastr.error(s.status);
      });
    }
  }

  dataURItoBlob(dataURI: any) {
    var byteString = atob(dataURI.split(',')[1]), mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length), ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++)
      ia[i] = byteString.charCodeAt(i);

    return new Blob([ab], {type: mimeString});
  }

  onFile(evnt: any) {
    if (evnt.target.files.length > 0) {
      var fileExtsn: string = evnt.target.files[0].type;
      if (fileExtsn.includes('jpg') || fileExtsn.includes('jpeg') || fileExtsn.includes('png'))
        this.dgtlFile = evnt.target.files[0], this.upldFile = evnt.target.files[0].name;
      else
        this.toastr.error('Please upload image files like jpg/jpeg/png');
    }
  }

  viewFile() {
    if (this.dgtlFile) {
      let view_File = this.dgtlFile;
      const fileExtension: string = view_File.name.split('.').pop().toLowerCase();
      if (['jpg', 'jpeg', 'png'].includes(fileExtension)) {
        const modalRef = this.modalService.open(ImagePreviewComponent, { size: 'lg', scrollable: true });
        if (view_File) {
          const reader = new FileReader();
          reader.readAsDataURL(view_File); // read file as data url
          reader.onload = (event: any) => { modalRef.componentInstance.image = event.target.result; };
        }
      }
    }
    else
      this.toastr.info('No file found');
  }
  //#endregion
   downloadfile(id:any){
    //  if (this.orgId == 2){
    //    window.open('https://doctor.doctoranh.org/recording-fileservice/download?consulationId='+id+'&TENANT_ID=1','_blank');
    //  }else{
    //   window.open(this.regService.dwnldUrl+'recording-fileservice/download?consulationId='+id+'&TENANT_ID='+this.orgId,'_blank')
    //  }
    if (this.orgId == 2){
    const url =   sessionStorage.getItem('tenantUrl');
      window.open('https://doctor.doctoranh.org/recording-downloads/download?consulationId='+id+'&TENANT_ID=1','_blank');

    }
    else{
      const url =   sessionStorage.getItem('tenantUrl')
        window.open(url+'/recording-downloads/download?consulationId='+id+'&TENANT_ID='+this.orgId,'_blank')
       }
 }
//  exportexcel(): void {

//   var table = $('#table').DataTable();

//   if (!table.data().count()) {
//     this.toastr.error('No data available in table! Please select and try again');
//   }
//   else {
//     / table id is passed over here /
//     let element = document.getElementById('table');
//     const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

//     / generate workbook and add the worksheet /
//     const wb: XLSX.WorkBook = XLSX.utils.book_new();
//     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//     ws['!cols'] = [];
//     ws['!cols'][10] = { hidden: true };

//     / save to file /
//     XLSX.writeFile(wb, this.fileName);
//   }
// }
downLoadExcel(){
 // this.excelData = this.lstGrid
  const fileName = 'Consultation_reg'
  var excelData = this.lstGrid;
  if(excelData.length>0){
    // excelData.map(res =>{
      // res.formatedVisited_date = this.pipe.transform(res.visit_date, 'dd-MM-yyyy');
      // res.formated_patient_name = (res.advice.is_self ? (res.beneficiary_first_name + ' ' + res.beneficiary_last_name) : (res.advice.family_member.first_name  + ' ' + res.advice.family_member.last_name)).toUpperCase();
      // res.formated_gender=(res.advice.is_self?this.getGender(res.beneficiary_gender_id):this.getGender(res.advice.family_member.gender_id)).toUpperCase();
      // res.formated_mobile= res.advice.is_self?res.beneficiary_mobile:(res.advice.family_member.member_mobile != null ? res.advice.family_member.member_mobile : res.advice.family_member.member_mobile);
      // res.formated_patient_age= res.advice.is_self?res.beneficiary_age:res.advice.family_member.age;
      // res.reg_no= res.advice.is_self?res.beneficiary_id:res.advice.family_member.id;
      // res.cons_no= res.patient_interaction_id;
      // res.type_of_call= ((+res.call_type_id==9)?'Audio':'Video');
      // if(res.referrad_doctor && res.referrad_doctor!=null && res.referrad_doctor!==' '){
      //   res.formated_doctor_name= (res.referrad_doctor).toUpperCase()
      // }
      // else{
      //   res.formated_doctor_name= (res.doctor_first_name + ' ' + res.doctor_last_name).toUpperCase()
      // }
      // res.formatedVisited_date = this.pipe.transform(res?.visit_date, 'dd-MM-yyyy');
      // res.formated_patient_name = (res?.advice?.is_self ? (res?.beneficiary_first_name + ' ' + res?.beneficiary_last_name) : (res?.advice?.family_member?.first_name  + ' ' + res?.advice?.family_member?.last_name)).toUpperCase();
      // res.formated_gender=(res?.advice?.is_self?this.getGender(res?.beneficiary_gender_id):this.getGender(res?.advice?.family_member?.gender_id)).toUpperCase();
      // res.formated_mobile= res?.advice?.is_self?res?.beneficiary_mobile:(res?.advice?.family_member?.member_mobile != null ? res?.advice?.family_member?.member_mobile : res?.advice?.family_member?.member_mobile);
      // res.formated_patient_age= res?.advice?.is_self?res?.beneficiary_age:res?.advice?.family_member?.age;
      // res.reg_no= res?.advice?.is_self?res?.beneficiary_id:res?.advice?.family_member?.id;
      // res.cons_no= res?.patient_interaction_id;
      // res.type_of_call= ((+res?.call_type_id==9)?'Audio':'Video');
      // if(res?.referrad_doctor && res?.referrad_doctor!=null && res?.referrad_doctor!==' '){
      //   res.formated_doctor_name= (res?.referrad_doctor).toUpperCase()
      // }
      // else{
      //   res.formated_doctor_name= (res?.doctor_first_name + ' ' + res?.doctor_last_name).toUpperCase()
      // }
      // res.formatedVisited_date = this.pipe.transform(res?.visit_date, 'dd-MM-yyyy');
      // res.formated_patient_name = (res?.advice?.is_self ? (res?.beneficiary_first_name + ' ' + res?.beneficiary_last_name) : (res?.advice?.family_member?.first_name  + ' ' + res?.advice?.family_member?.last_name)).toUpperCase();
      // res.formated_gender=(res?.advice?.is_self?this.getGender(res?.beneficiary_gender_id):this.getGender(res?.advice?.family_member?.gender_id)).toUpperCase();
      // res.formated_mobile= res?.advice?.is_self?res?.beneficiary_mobile:(res?.advice?.family_member?.member_mobile != null ? res?.advice?.family_member?.member_mobile : res?.advice?.family_member?.member_mobile);
      // res.formated_patient_age= res?.advice?.is_self?res?.beneficiary_age:res?.advice?.family_member?.age;
      // res.formated_reg_no= res?.advice?.is_self?res?.beneficiary_id:res?.advice?.family_member?.id;
      // res.formated_cons_no= res?.patient_interaction_id;
      // res.formated_type_of_call= ((+res?.call_type_id==9)?'Audio':'Video');
      // if(res?.referrad_doctor && res?.referrad_doctor!=null && res?.referrad_doctor!==' '){
      //   res.formated_doctor_name= (res?.referrad_doctor).toUpperCase()
      // }
      // else{
      //   res.formated_doctor_name= (res?.doctor_first_name + ' ' + res?.doctor_last_name).toUpperCase()
      // }
     
    // })
    const excelHeaders = ['Visit Date', 'Patient Name','Gender','Mobile','Patient Age','Doctor Name','Registration No','Consultation No','Type Of Call','Centre Lat/Long','Tab Lat/Long','Distance(KM)'];
    const internalHeaders = ['formatedVisited_date', 'formated_patient_name','formated_gender','formated_mobile','formated_patient_age','formated_doctor_name','formated_reg_no','formated_cons_no','formated_type_of_call','centreLatLng','tabLatLng','distance'];
    this.toastr.success('Please wait excel is downloading')
    this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
  }
  else{
    this.toastr.error('No data to download')
  }
  
}

}