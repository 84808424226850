import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { RegistrationService } from 'src/app/shared/services/registration.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { Common } from 'src/app/common';
import { OrgPlansControlsComponent } from '../org-plans-controls/org-plans-controls.component';
import { UserService } from 'src/app/shared/services/user.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

declare let $: any;

@Component({
  selector: 'app-org-plans',
  templateUrl: './org-plans.component.html',
  styleUrls: ['./org-plans.component.css']
})

export class OrgPlansComponent extends BaseComponent implements OnInit {

  modalTitle: string = 'Configure License Plans'; lstPlans: Array<any> = []; lstLabs = []; lstPharma = []; lstLocTypes = [];

  constructor(config: NgbModalConfig
    , private toastr: ToastrService
    , private regService: RegistrationService
    , private modalService: NgbModal
    , private usrSvc: UserService
    , private authSvc: AuthenticationService) {
      super(); config.backdrop = 'static', config.keyboard = false;
  }

  ngOnInit(): void {
    this.authSvc.isShowGlblOrg.next(false), this.authSvc.tenantId = sessionStorage.getItem('defCrntTnnt'),
      sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt'));
    this.LoadGrid();
  }

  onPlanModal(plan: any = null) {
    const modalRef = this.modalService.open(OrgPlansControlsComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = this.modalTitle + ' Details';
    modalRef.componentInstance.plan = plan;
    modalRef.result.then(result => {
      if (result == 'changes saved')
        this.LoadGrid();
    }, (reason) => { });
  }

  LoadGrid() {
    this.usrSvc.getAll('licenseplan/getall').subscribe(res => {
      if (res) {
        if (res.status == "OK")
          this.lstPlans = res.data, Common.loadDataTable(this.lstPlans, '#planTable');
        else
          this.toastr.error(res.message);
      }
    });
  }

  onDelete(id: any) {
    if (confirm(`Are you sure you want delete record?`)) {
      // this.regService.delByAgentId(id).subscribe(res => {
      //   if (res.status == "OK")
      //     this.LoadGrid(), this.toastr.success('Record Deleted Successfully');
      // }, error => { this.toastr.error('An Error Occured'); });
    }
  }

}