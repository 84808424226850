import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RegistrationService } from 'src/app/shared/services/registration.service';

declare let JqueryReady: any;
declare let joinSession: any;

@Component({
  selector: 'app-agentlist-call',
  templateUrl: './agentlist-call.component.html',
  styleUrls: ['./agentlist-call.component.css']
})

export class AgentlistCallComponent implements OnInit {

  agentlist: any;

  constructor(private route: Router, private regSrv: RegistrationService) { }

  ngOnInit() {
    this.getagentdata();
  }

  join(val: any) {
    (<HTMLInputElement>document.getElementById('sessionName')).value = val, this.route.navigate(['home/agentvideo']).then(e => {
      Promise.all([JqueryReady()]).then(res => { joinSession(); });
    });
  }

  getagentdata() {
    this.regSrv.getagentByuserId(+sessionStorage.getItem('agentUserId')).subscribe(a => {
      if (a) {
        let res: any = a, agtTxt = 'Agt. ' + res.data[0].first_name + ' ' + res.data[0].last_name;
        agtTxt = agtTxt.length > 25 ? agtTxt.substring(0, 23) + '..' : agtTxt;
        (<HTMLInputElement>document.getElementById('user')).value = agtTxt;
        sessionStorage.agentId = res.data[0].id
        this.regSrv.getAgentId(res.data[0].id).subscribe(l => { this.agentlist = l; });
      }
    });
  }

}