import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { LaboratoryRegistrationComponent } from '../laboratory-registration/laboratory-registration.component';
import { BaseComponent } from 'src/app/shared/base.component';
import { ApiService } from 'src/app/shared/services/api.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { Common } from './../../../common';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import * as XLSX from 'xlsx';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';

declare let $: any;

@Component({
  selector: 'app-lab-list',
  templateUrl: './lab-list.component.html',
  styleUrls: ['./lab-list.component.css']
})

export class LabListComponent extends BaseComponent implements OnInit {

  modalTitle: string = '';
  lstGrid: Array<any> = [];
  fileName = 'Lab list.xlsx';
  excelData: any=[];

  constructor(protected toastr: ToastrService
    , protected router: Router
    , protected apiServ: ApiService
    , config: NgbModalConfig
    , private modalService: NgbModal
    , private authSvc: AuthenticationService,
     private excelDownloadService:ExcelDownloadService
    , private regService: RegistrationService
    , public cdr: ChangeDetectorRef) {
    super();
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    authSvc.crntOrgIDSubject.subscribe(s => { this.lstGrid = [], Common.loadDataTable(this.lstGrid, '#labTable');
      if (location.href.includes('home/labList'))
        this.ngOnInit();
    }), authSvc.isShowGlblOrg.next(true);
  }

  ngOnInit(): void {
    this.LoadData();
  }

  ChkLimit(): boolean {
    let result: boolean;
    this.regService.chkLimit('lab').subscribe(res => {
      if (res) {
        if (res.status == "OK")
          result = true, this.openLabModal(null);
        else
          result = false, this.toastr.warning(res.message);
      }
    });
    return result;
  }

  LoadData() {
    //below line commented for new API integration 08-07-2022
    // this.regService.getAll('lab/getalllabregistrations').subscribe(res => { //getalllabregistrations
    this.regService.getAll('lab/newgetalllabregistrations').subscribe(res => { //getalllabregistrations
      if (res.status === "OK") {
        this.cdr.detectChanges();
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Lab List');
        else
          this.lstGrid = res.data;
          this.excelData = this.lstGrid;
          this.cdr.detectChanges();
        Common.loadDataTable(this.lstGrid, '#labTable');
      }
    });
  }

  openLabModal(item: any) {
    if(item!==null){
      const val = this.regService.getLabByLabId(item.lab_id).toPromise().then(res=>{
        if (res.status == "OK") {
            
          const modalRef = this.modalService.open(LaboratoryRegistrationComponent, { scrollable: true, size: 'xl' });
          modalRef.componentInstance.title = this.modalTitle;
          modalRef.componentInstance.labData = res.data;
          modalRef.result.then(result => {
            if (result == 'changes saved' || result=='Close click'){
              this.LoadData();
            }
            
          }, (reason) => { });
          
       }
      });
    } else{
      const modalRef = this.modalService.open(LaboratoryRegistrationComponent, { scrollable: true, size: 'xl' });
      modalRef.componentInstance.title = this.modalTitle;
      modalRef.componentInstance.labData = item;
      modalRef.result.then(result => {
        if (result == 'changes saved' || result=='Close click'){
          this.LoadData();
        }
        
      }, (reason) => { });
      
    }  error => this.toastr.error(error.message);
   
  }

  onDelete(id: any) {
    if (confirm(`Are you sure you want delete record?`)) {
    this.regService.deleteById('lab/deletelabregistrationsbyid?labId=', id).subscribe(res => {
      if (res.status == "OK") {
        this.cdr.detectChanges();
        this.LoadData();
        this.toastr.success('Record Deleted Successfully');
      }
    },
      error => { this.toastr.error('An Error Occured'); });
  }
  }

  downLoadExcel(){
    const fileName = 'Lab_reg'
      var excelData = this.excelData;
      if(excelData.length>0){
        excelData.map(res =>{
          res.contact_person_fullname = (res.contact_person_name +' '+ res.contact_person_last_name);
          res.location_type_name= (res.location_type_id == 2 ? 'Urban' : 'Rural')
        })
      const excelHeaders = ['Lab Name', 'Lab Contact No', 'Contact Person Name', 'Contact Mobile No', 'Lab Registration No', 'Location Type'];
      const internalHeaders = ['lab_name', 'lab_conatct_no', 'contact_person_fullname', 'contact_person_mobile', 'registration_no', 'location_type_name'];
      this.toastr.success('Please wait excel is downloading')
      this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
      }
      else{
        this.toastr.error('No data to download')
      }
    
  }
  



}