import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

import { MasterService } from 'src/app/shared/services/master.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';

@Component({
  selector: 'app-dr-module-controls',
  templateUrl: './dr-module-controls.component.html',
  styleUrls: ['./dr-module-controls.component.css']
})

export class DrModuleControlsComponent implements OnInit {

  @Input() editData: any; isShow = false; data: any; drugnamesList: Array<any> = []; tmplName = ''; pocOptns = ''; drgTyp = ''; chsnCnt = 0;
  lstMstr = []; lstCC = []; lstPD = []; lstInv = []; lstDT = []; lstDN = []; lstChsn = []; lstChsnDN = []; lstDel = []; lstDelDN = []; dnCnt = 0;
  pocOptionsData = [];
  constructor(public activeModal: NgbActiveModal, private mstrSvc: MasterService
    , private registrationsrv: RegistrationService
    , private toastr: ToastrService) {
    this.activeModal.close('changes saved');
  }

  ngOnInit(): void {
    this.LoadMasters(); this.getAllPocOptions();
  }

  getAllPocOptions() {
    this.mstrSvc.getAllPocOptions().subscribe(response => {
      if(response){
        if(response.status == 'OK') 
        this.pocOptionsData = response.data;
      }
    })
  }

  LoadMasters() {
    const chiefComplaint = this.mstrSvc.getAll('getallchiefcomplaints'), diagnosis = this.mstrSvc.getAll('getalldiagnosis'),
      investigations = this.mstrSvc.getAll('getallinvestigations'), drugtypes = this.mstrSvc.getAll('getalldrugtypes');
    forkJoin([chiefComplaint, diagnosis, investigations, drugtypes]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              console.log('No records available', 'Plan of Care');
            else {
              const assignList = {
                '0': () => this.lstCC = list.data,
                '1': () => this.lstPD = list.data,
                '2': () => this.lstInv = list.data,
                '3': () => this.lstDT = list.data,
              }
              assignList[ind]();
            }
          }
        });
      },
      error: err => console.error('something wrong occurred: ', err),
      complete: () => { },
    });
  }

  onSave() {
    let result: any, mstrData = [], msterdrug = [], modName = this.pocOptns == '1' ? 'Chief Complaints' :
      (this.pocOptns == '2' ? 'Provisional Diagnosis' : (this.pocOptns == '3' ? 'Investigations' : 'Drug Type'));
      if (this.pocOptns == '1') {
      this.lstChsn.forEach(e => { mstrData.push({ id: e.id, chief_complaint: e.name }); });
      result = { id: 0, module_name: this.tmplName, module_data: { ChiefComplaints: mstrData, module_type: modName, module_type_id: this.pocOptns } };
    } else if (this.pocOptns == '2') {
      this.lstChsn.forEach(e => { mstrData.push({ id: e.id, diagnosis_name: e.name }); });
      result = { id: 0, module_name: this.tmplName, module_data: { ProvisionalDiagnosis: mstrData, module_type: modName, module_type_id: this.pocOptns } };
    } else if (this.pocOptns == '3') {
      this.lstChsn.forEach(e => { mstrData.push({ id: e.id, investigation: e.name }); });
      result = { id: 0, module_name: this.tmplName, module_data: { master: mstrData, module_type: modName, module_type_id: this.pocOptns } };
    } else {
      if (this.lstChsn.filter(f => f.lstDrgs.length == 0).length > 0)
        this.toastr.error('need drugs');
      else {
        this.lstChsn.forEach(e => { let lstDNs = [];
          e.lstDrgs.forEach(dn => { lstDNs.push({ id: dn.id, name: dn.name }); });
          if (lstDNs.length > 0)
            mstrData.push({ id: e.id, drug_type: e.name, drug_names: lstDNs });
        });        
        result = { id: 0, module_name: this.tmplName, module_data: { drug_types: mstrData , module_type: modName, module_type_id: this.pocOptns} };
      }
    }
    if (result) {
      this.registrationsrv.saveDctrMdule(result).subscribe(s => {
        if (s.status === 'OK')
          this.toastr.success(s.message), this.activeModal.close('changes saved');
        else
          this.toastr.error(s.message);
      });
    }
  }

  patchingdata() {
    // if(this.editData) {
    this.registrationsrv.DctrMduleById(this.editData).subscribe(s => {
      this.data = s;
    })
    // }
  }

  onChngPO(val: string) {
    this.lstMstr = [], this.lstDN = [], this.lstChsn = [], this.lstChsnDN = [], this.lstDel = [], this.chsnCnt = 0, this.dnCnt = 0;
    if (val != '') {
      let mstrs: any[] = (val == '1' ? this.lstCC : (val == '2' ? this.lstPD : (val == '3' ? this.lstInv : this.lstDT))),
        key: string = (val == '1' ? 'chief_complaint' : (val == '2' ? 'diagnosis_name' : (val == '3' ? 'investigation' : 'drug_type')));
      mstrs.forEach(e => { this.lstMstr.push({ id: e.id, name: e[key], isChkd: false, isChsn: false, isDeld: false, lstDrgs: [] }); });
    }
  }

  onAdd() {
    this.lstMstr.forEach(e => { if (e.isChkd && this.lstChsn.filter(f => f.id == e.id).length == 0) this.lstChsn.push(e); });
  }

  onDNAdd() {
    this.lstDN.forEach(e => { if (e.isChkd && this.lstChsnDN.filter(f => f.id == e.id).length == 0) this.lstChsnDN.push(e); });
    if (this.drgTyp != '')
      this.lstChsn.filter(f => f.id == this.drgTyp)[0].lstDrgs = this.lstChsnDN;
  }

  onDel() {
    this.lstDel.forEach(e => {
      this.lstMstr.filter(f => f.id == e.id)[0].isChkd = false, this.lstMstr.filter(f => f.id == e.id)[0].isDeld = false,
        this.lstChsn = this.lstChsn.filter(f => f.id != e.id)
    }), this.chsnCnt = this.lstMstr.filter(f => f.isChkd == true).length, this.lstDel = [], this.lstDN = []; this.lstChsnDN = [];
  }

  onDelDN() {
    this.lstDelDN.forEach(e => {
      this.lstDN.filter(f => f.id == e.id)[0].isChkd = false, this.lstDN.filter(f => f.id == e.id)[0].isDeld = false,
      this.lstChsnDN = this.lstChsnDN.filter(f => f.id != e.id)
    }), this.dnCnt = this.lstDN.filter(f => f.isChkd == true).length, this.lstDelDN = [];
    if (this.drgTyp != '')
      this.lstChsn.filter(f => f.id == this.drgTyp)[0].lstDrgs = this.lstChsnDN;
  }

  onChngDT(dtId: any) {
    this.lstDN = [], this.lstChsnDN = [], this.mstrSvc.searchbyIdMasters(`drugtypemapping/getdrugbydrugtypeid?drugTypeId=${dtId}`).subscribe(res => {
      if (res) {
        if (res.status === 'OK') {
          let result = res.data;
          result.forEach(e => { this.lstDN.push({ id: e.drug_id.id, name: e.drug_id.drug_name, isChkd: false, isChsn: false, isDeld: false }) });
        }
      }
    });
  }

  onChkMstr(isChk: boolean, data: any) {
    this.lstMstr.filter(f => f.id == data.id)[0].isChkd = isChk, this.chsnCnt = this.lstMstr.filter(f => f.isChkd == true).length;
  }

  onChkDN(isChk: boolean, data: any) {
    this.lstDN.filter(f => f.id == data.id)[0].isChkd = isChk, this.dnCnt = this.lstDN.filter(f => f.isChkd == true).length;
  }

  onSrtng(ordrType: string) {
    if (ordrType == 'a')
      this.lstChsn.sort(function (a: Object, b: Object) { return a['name'].localeCompare(b['name']); });
    else
      this.lstChsn.sort(function (b: Object, a: Object) { return a['name'].localeCompare(b['name']); });
  }

  onSrtngDN(ordrType: string) {
    if (ordrType == 'a')
      this.lstChsnDN.sort(function (a: Object, b: Object) { return a['name'].localeCompare(b['name']); });
    else
      this.lstChsnDN.sort(function (b: Object, a: Object) { return a['name'].localeCompare(b['name']); });
  }

  onChsnSlct(data: any, event: any) {
    event.target.style.backgroundColor = event.target.style.backgroundColor ? null : '#00cfd5';
    this.lstMstr.filter(f => f.id == data.id)[0].isDeld = event.target.style.backgroundColor != '',
      this.lstDel = this.lstMstr.filter(f => f.isDeld == true);
  }

  onChsnDNSlct(data: any, event: any) {
    event.target.style.backgroundColor = event.target.style.backgroundColor ? null : '#00cfd5';
    this.lstDN.filter(f => f.id == data.id)[0].isDeld = event.target.style.backgroundColor != '',
      this.lstDelDN = this.lstDN.filter(f => f.isDeld == true);
  }

}