import { ExcelService } from 'src/app/shared/services/excel.service';
import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Common } from 'src/app/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
declare let $: any;
@Component({
  selector: 'app-network-monthly-report',
  templateUrl: './network-monthly-report.component.html',
  styleUrls: ['./network-monthly-report.component.css']
})
export class NetworkMonthlyReportComponent implements OnInit {
  networkStatusForm:FormGroup;
  networkMonthlyForm: FormGroup;
  start_date: any = new Date();
  end_date: any = new Date();
  max_date = new Date();
  networkCentreList:any = []; 
  networkDateList: any=[];
  uploaded_file_with_signArray = new Array();
  minDate: Date; minToDate: Date; docId = '';
  submitted = false; userNames = [];
  userList: Array<any> = [];
  showExport: boolean = true;
  fileName = 'Network Status Report.xlsx';
  excelData: any;
  pipe = new DatePipe('en-US');
  mandalList: any[];
  villageList: any[];
  cityList: any[];
  districtList: any[];
  stateList: any;
  hospitalList: any=[];
  departmentList: any[];
  isNIG = environment.isNIG;
  getagents:any
  centerHospitals: any[];
  dropdownSettings_centre: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; itemsShowLimit: number; allowSearchFilter: boolean; };
  selectedDistricts: any=[];
  dropdownSettings_district: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; itemsShowLimit: number; allowSearchFilter: boolean; };
  distList:any=[];
  centList: any[];
  selectedCentres: any=[];
  
  isVisible: any;
  isSelected: boolean = true;
  isCentreWiseSelect: boolean = true;
  isDateWiseSelect: boolean = false;
  currDiv: string;
 
  constructor(private regService: RegistrationService
    , protected toastr: ToastrService
    , private formBuilder: FormBuilder
    , private datePipe: DatePipe
    , private masterService: MasterService,
    private excelDownloadService:ExcelDownloadService
     , private registrationService: RegistrationService
    , private authSvc: AuthenticationService
    ) {
    // this.minDate = new Date();
    // this.minDate.setDate(this.minDate.getDate());

  }

  ngOnInit(): void {
    this.initFormControls(),
    this.networkCentreList=[]; 
    this.networkDateList = [];
    this.defaultLocations();
  
    Common.loadDataTable(this.networkCentreList, '#networkMonthlyReport');
    this.locationChanged('', 'state');
    this.locationChanged('5', 'district');//Training
    // this.locationChanged('', 'state'),
    // this.locationChanged('5', 'district');
    this.registrationService.getallagentspharmacy().subscribe(data => {
      this.getagents=data.data;
    })
     this.LoadData()
   
  }

  show(){
    if(this.selectedDistricts.length == 0){
      this.toastr.error('Please select District')
      return;
      } 
  }
  initFormControls() {
    this.networkMonthlyForm = this.formBuilder.group({
      start_date: [new Date(), Validators.required],
      end_date: [new Date(), Validators.required],
     // state_id: ['5', Validators.required],
      district_ids: [],
      center_id:[],
     // centerwise:[],
    //  datewise:[]

    });
  }
  get f() { return this.networkMonthlyForm.controls; }
 getCentres(data:any){
  this.distList=[];
  //console.log('Check Data',this.selectedDistricts);
  //let payload =id;
   // let payload =this.distList;
   for(let dist of this.selectedDistricts){
    this.distList.push(dist.id);
    //console.log("distList", this.distList);
    
 }
 
//  alert('Check');
  this.registrationService.getcenterrecordsbyDistrictIds(this.distList).subscribe(res => {
   // console.log("distListop", this.distList);
    if (res.status === 'OK') {
      if (res.data == null || res.data.length == 0) {
        this.centerHospitals = [];
      }
      else
        // this.centerHospitals = res.data;
        this.centerHospitals=res.data.sort((a,b)=>a.center_name.toLowerCase()>b.center_name.toLowerCase()?1:-1);
     //   console.log('centerHospitals', this.centerHospitals);
        
      //console.log('centerHospitals', JSON.stringify(this.centerHospitals));
    }
  })
 }

  LoadData() {
    let sendData = JSON.parse(JSON.stringify(this.networkMonthlyForm.value));
    [sendData.from_date, sendData.to_date] =
      [
        this.datePipe.transform(sendData.start_date, 'yyyy-MM-dd'),
        this.datePipe.transform(sendData.end_date, 'yyyy-MM-dd')
      ];
      delete sendData.start_date;
      delete sendData.end_date;
    if (this.networkMonthlyForm.value.agent_id == "") {
      this.toastr.error('Please Select Pharmacist/Healthcare Professional');
      return;
    }
    
    // District Id start
   // console.log('Array check',this.districtList);
    this.distList =[];
    // for(let dist of this.districtList){
    //    this.distList.push(dist.id);
    // }
    if(this.selectedDistricts.length>0){
      let selectDistList=[];
      for(let dist of this.selectedDistricts){
        selectDistList.push(dist.id);
     }
      sendData.district_ids=selectDistList;
     } else{
      sendData.district_ids= [-1];
     }
    // sendData.district_ids=this.distList;
    // console.log('send data check',sendData);
     // District Id end

    // Center Id start
   // console.log('Array check',this.centerHospitals);
    this.centList =[];
    // for(let cent of this.centerHospitals){
    //    this.centList.push(cent.id);
    // }
    if(this.selectedCentres.length>0){
      let selectCentList=[];
      for(let cent of this.selectedCentres){
        selectCentList.push(cent.id);
     }
      sendData.center_id=selectCentList;
     } else{
      sendData.center_id= [-1];
     }
     // Center Id end
  if(this.isCentreWiseSelect){
  //center wise report call
  this.regService.getNetworkMonthlyReport('Centre',sendData).subscribe(res => {
    this.networkCentreList = res.data;
      Common.loadDataTable(this.networkCentreList, '#networkMonthlyReport');  
      this.excelData = this.networkCentreList;
      Common.loadDataTable(this.networkCentreList, '#networkMonthlyReport');
   
  });
  } else{
  //date wise report call
  this.regService.getNetworkMonthlyReport('Date',sendData).subscribe(res => {
    this.networkDateList = res.data;
    Common.loadDataTable(this.networkDateList, '#networkMonthlyReport');  
          this.excelData = this.networkDateList;
      Common.loadDataTable(this.networkDateList, '#networkMonthlyReport');
   
  });
  }
   
   
  }

  onFromDateChange(val: Date) {
    if (val) {
      let start_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.networkMonthlyForm.get('end_date').setValue(new Date(start_date.setDate(start_date.getDate())));
      this.minDate = start_date;
    }
  }
  //new line added for on To date change 11-04-2022
  onToDateChange(val: Date) {
    if (val) {
      let end_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.networkMonthlyForm.get('end_date').setValue(new Date(end_date.setDate(end_date.getDate())));
      //this.minDate = end_date;
    }
  }

  // clear() {
  //   this.initFormControls();
  //    this.networkStatuslist = [];
  //   Common.loadDataTable(this.networkStatuslist, '#networkStatusReport');
  // }
  // clear(){
  //   this.initFormControls();
  //   Common.loadDataTable(this.networkStatuslist, '#networkStatusReport');
  // }
 
 

  // 22-07-22
  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
      //   this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];

        // this.labForm.get('state_id').setValue('');
        this.networkMonthlyForm.get('district_ids').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        // if (+id > 0)
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        // if (+id > 0)
        //   query = `getstatebyid?stateId=36`;
        query = `getstatebyid?stateId=5`;
      //  this.getCentres(id);
      },
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }
  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        // console.log('stateList', this.stateList);
        
        
        
        assign_locations[type]();
      }
      this.districtList.sort((a, b) => 0 - (a.district_name > b.district_name ? -1 : 1));
      // console.log('districtList', this.districtList);
    }, error => this.toastr.error(error));
  }


  // Agent List
  agentchange(agentid){
    if(agentid!='null'){
      this.networkMonthlyForm.value.agent_id=agentid;
    }
  
    
  }
  // exportexcel
  exportexcel(): void {

    var table = $('#networkMonthlyReport').DataTable();

    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {
      / table id is passed over here /
      let element = document.getElementById('networkMonthlyReport');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);


      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };


      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
  }

  downLoadExcel(){
    const fileName = 'NetworkStatus_Report'
      var excelData = this.excelData;
      if(excelData.length>0){
        excelData.map(res =>{
          res.formated_handoverReport = this.pipe.transform(res.handoverReport, 'dd-MM-yyyy');
        })
      
        if(this.isCentreWiseSelect==true){
          var excelHeaders = ['District', 'Block', 'Center Name', 'Pharmacist/Healthcare Professional','Pharmacist/Healthcare Professional Number', 'Up Time', 'Down Time'];
          var internalHeaders = ['district_name', 'mandal_name', 'center_name', 'health_associate_name','health_associate_mobile', 'up_time', 'down_time'];
        }else{
          var excelHeaders = ['District', 'Block', 'Center Name', 'Pharmacist/Healthcare Professional','Pharmacist/Healthcare Professional Number', 'date', 'Up Time', 'Down Time'];
          var internalHeaders = ['district_name', 'mandal_name', 'center_name', 'health_associate_name','health_associate_mobile', 'date', 'up_time', 'down_time'];
        }

         

      //  const excelHeaders = ['User Name', 'Mobile','Email','Attendance Date','Start Time','End Time'];
      //  const internalHeaders = ['user_name', 'mobile','email','formated_attendance_date','start_time','end_time'];
        this.toastr.success('Please wait excel is downloading')
        this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
      }
      else{
        this.toastr.error('No data to download')
      }
    
  }

  // multi selection start 
  defaultLocations() {

    // District Multiple Drop Down
    this.dropdownSettings_district = {
      singleSelection: false,
      idField: 'id',
      textField: 'district_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    };
   
    this.dropdownSettings_centre = {
      singleSelection: false,
      idField: 'id',
      textField: 'center_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      allowSearchFilter: true
    }
  }
    // Multi-Select District Method
    onItemDeSelectDistrict(item: any) {
      let data = this.selectedDistricts;
      data.forEach((x, i) => { if (x.id == item.id) data.splice(i, 1); });
      this.selectedDistricts = [...data];
    //  this.selectedDistricts = [];
      this.onDeSelectAllCentre(this.selectedDistricts);
      if (this.selectedDistricts.length == 0) {
        //this.onDeSelectAllHub('');
        this.selectedDistricts = [];
      }else{
        this.getCentres(this.selectedDistricts);
      }
      // this.onDistrictChange();
  
      // this.onClearblocks(item.id);
    //  this.getCentres(this.selectedDistricts);
    }
  
    onItemSelectDistrict(item: any) {
      if (this.selectedDistricts.filter(f => f.id == item.id).length == 0)
        this.selectedDistricts.push(item);
      this.selectedDistricts = [...this.selectedDistricts];
     // this.onDistrictChange();
     this.getCentres(this.selectedDistricts);
     
    }
  
    public onSelectAllDistrict(item: any) {
      this.selectedDistricts = item;
      this.selectedDistricts = [...this.selectedDistricts];
     // this.onDistrictChange()
   //  this.selectedDistricts = [];
     this.getCentres(this.selectedDistricts);
    }
    public onDeSelectAllDistrict(item: any) {
      this.selectedDistricts = [];
      this.onDeSelectAllCentre(this.selectedDistricts);
      //this.onDeSelectAllHub('');
     // this.onDistrictChange()
   // this.getCentres(this.selectedDistricts);
  
    }
   
    // END Multi-Select District Method


     // Multi-Select Centre Method
     onItemDeSelectCentre(item: any) {
      let data = this.selectedCentres;
      data.forEach((x, i) => { if (x.id == item.id) data.splice(i, 1); });
      this.selectedCentres = [...data];
     
    }
  
    onItemSelectCentre(item: any) {
      if (this.selectedCentres.filter(f => f.id == item.id).length == 0)
        this.selectedCentres.push(item);
      this.selectedCentres = [...this.selectedCentres];
     // this.onDistrictChange();
    // this.getCentres(this.selectedDistricts);
     
    }
  
    public onSelectAllCentre(item: any) {
      this.selectedCentres = item;
      this.selectedCentres = [...this.selectedCentres];
     // this.onDistrictChange()
   //  this.selectedDistricts = [];
     this.getCentres(this.selectedCentres);
    }
    public onDeSelectAllCentre(item: any) {
      this.selectedCentres = [];
      this.centerHospitals=[];
      //this.mandalList=[];
      //this.onDeSelectAllHub('');
     // this.onDistrictChange()
     //this.getCentres(this.selectedCentres);
  
    }
   
    // END Multi-Select District Method

  //   onItemSelect(item: any) {
  //     if (this.selectedCentres.filter(f => f.id == item.id).length == 0)
  //     this.selectedCentres.push(item);
  //   this.selectedCentres = [...this.selectedCentres];
  // }
  // onItemDeSelect(item: any) {
  //   let data = this.selectedCentres;
  //     data.forEach((x, i) => { if (x.id == item.id) data.splice(i, 1); });
  //     this.selectedCentres = [...data];
  // }
  // onSelectAll(item: any) {
  //   this.selectedCentres = item;
  //   this.selectedCentres = [...this.selectedCentres];
  // }
  // onUnSelectAll() {
  //   this.selectedDistricts = [];
  // }
  
  // multi selection end 
  
  handleCentreChange(evt){
    this.isCentreWiseSelect= true;
    this.isDateWiseSelect = false;
    this.LoadData();
   
  }
  handleDateChange(evt){
    this.isCentreWiseSelect = false;
    this.isDateWiseSelect= true;
    this.LoadData();
  }
  
  ShowDiv(divVal: string) {
    this.currDiv = divVal;
  }



}
