import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Common } from 'src/app/common';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { MasterService } from 'src/app/shared/services/master.service';
declare let $: any;
import * as _ from 'lodash';

@Component({
  selector: 'app-drugs-to-agentmapping',
  templateUrl: './drugs-to-agentmapping.component.html',
  styleUrls: ['./drugs-to-agentmapping.component.css'],
  providers: [DatePipe]
})
export class DrugsToAgentmappingComponent implements OnInit {

  drugAgentForm: FormGroup; 
  @Input() title: string = ''; @Input() DrugAgent: any; submitted = false; sbmtDsbld: boolean = false;
  statedrugList: Array<any> = []; stateList: Array<any> = []; lstAgents: Array<any> = []
  expDate: any[];
  mfgDate: any[];
  dateValue: any[];
  state_id: any;

  constructor(public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , private toastr: ToastrService
    , private dtPipe: DatePipe
    , private formBuilder: FormBuilder
    , private registration: RegistrationService,
    private masterService: MasterService,

  ) { }

  ngOnInit(): void {
    this.getmasters(); this.initFormControls();
  }
  initFormControls() {
    this.drugAgentForm = this.formBuilder.group({
      // state_id: ['',Validators.required],
      //  state_id: [36, Validators.required],//QA Pointing
     state_id: [5, Validators.required],//Training
      agent_id: ['',Validators.required],
      drugtype_id: ['',Validators.required],
      qty_id: ['',Validators.required],
      available_stock: [''],
      expiry_date: [''],
      mfg_date: [''],
      available_stock_state: ['']
    });
  }
  get f() { return this.drugAgentForm.controls; }

  onSubmit(event: any) {
    this.submitted = true, Common.getFormValidationErrors(this.drugAgentForm);
    if (this.drugAgentForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    } else {
      const drug = _.find(this.statedrugList, (data) => { return data.id == this.drugAgentForm.value.drugtype_id })
      const req = [
        {
          "bar_code": "bar0098",
          "batch_no": drug.batch_no,
          "stock": this.drugAgentForm.value.qty_id,
          "agent_id": this.drugAgentForm.value.agent_id,
          "drug_mapping_id": drug.drug_mapping_id,
          "drug_name": drug.drug_name,
          "drug_type": drug.drug_type,
          "received_stock": "0",
          "expiry_date": this.dtPipe.transform(this.drugAgentForm.value.expiry_date, 'yyyy-MM-dd HH:mm:ss'),
          "is_active": true,
          "manufactured_date": this.dtPipe.transform(this.drugAgentForm.value.mfg_date, 'yyyy-MM-dd HH:mm:ss'),
          "stateId": this.drugAgentForm.value.state_id
        }

      ]
      if (this.drugAgentForm.value.qty_id > this.drugAgentForm.value.available_stock_state) {
        this.toastr.error('Entered Qty should be less than Available stock at State');
        this.submitted = false;

      }
      this.registration.AgentDrugDetails(req).subscribe(res => {
        if (res) {
          if (res.status == "OK")
            this.toastr.success(res.message), this.activeModal.close('changes saved');
          // else
          //   this.toastr.warning(res.message);
        }
        else
          this.toastr.info(res.message);

      })


    }



    // const postdata = JSON.parse(JSON.stringify(this.drugAgentForm.getRawValue()));

  }

  getcheckdrug() {

    const drug = _.find(this.statedrugList, (data) => { return data.id == this.drugAgentForm.value.drugtype_id })
    // this.drugAgentForm.controls.expiry_date.setValue(this.dtPipe.transform(drug.expiry_date, 'yyyy-MM-dd'));
    // this.drugAgentForm.controls.mfg_date.setValue(this.dtPipe.transform(drug.manufactured_date, 'yyyy-MM-dd'));
    this.drugAgentForm.controls.expiry_date.patchValue(drug.expiry_date.split(' ')[0]);
    this.drugAgentForm.controls.mfg_date.patchValue(drug.manufactured_date.split(' ')[0]);
    this.drugAgentForm.controls.available_stock_state.patchValue(drug.current_stock);
    // this.drugAgentForm.controls.expiry_date.setValue(drug.expiry_date);
    this.registration.getagentcheckdrug(this.drugAgentForm.value.agent_id, drug.drug_mapping_id, drug.batch_no).subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available');
        else {
          const data = res.data;

          if (data.id) {
            this.drugAgentForm.controls.available_stock.setValue(data.stock);

          } else {
            this.drugAgentForm.controls.available_stock.setValue(0);

          }
        }
      }
    })
  }
  // qtyErr(){
  //   if( this.drugAgentForm.value.qty_id >  this.drugAgentForm.value.current_stock ){
  //     this.toastr.error('Entered Qty is Greater Than Available Stock')

  //   }
  // }

  getmasters() {
    this.masterService.getAll('getallstates').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available');
        else
          this.stateList = res.data;
          this.state_id = this.stateList[0].id;
          this.getAllstates(this.state_id)
      }
    });
  }
  getAllstates(stateId: string) {
    if (stateId != '') {
      this.statedrugList = []; 
      this.registration.getstatebydrugs(+stateId).subscribe(res => {
        if (res) {
          if (res.status == "OK") {
            if (res.data == null || res.data.length == 0)
              this.toastr.info('No records available');
            else
              this.statedrugList = res.data
          }
        }
        else
          this.statedrugList = [];
      });
    }
    this.lstAgents = []; 
    this.registration.getAgentByStateId(+stateId).subscribe(res => {
      // getAll('agent/getagentrecords')
      if (res) {
        if (res.status == "OK") {
          if (res.data == null || res.data.length == 0)
            this.toastr.info('No records available');
          else
            this.lstAgents = res.data
        }
      }
      else
        this.lstAgents = [];
    });

  }

}
