import { UploadConsentFormsComponent } from './modules/configuration/upload-consent-forms/upload-consent-forms.component';
import { SMSSMTPComponent } from './modules/configuration/sms-smtp/sms-smtp.component';
import { PaymentGatewayComponent } from './modules/configuration/payment-gateway/payment-gateway.component';
import { CustomisePrescriptionComponent } from './modules/configuration/customise-prescription/customise-prescription.component';
import { UploadLogoComponent } from './modules/configuration/upload-logo/upload-logo.component';
import { CouponListComponent } from './modules/masters/coupon-list/coupon-list.component';
import { SupportComponent } from './modules/consultation/support/support.component';
import { HospitalDetailListComponent } from './modules/registration/hospital-detail-list/hospital-detail-list.component';
import { InActiveListComponent } from './modules/registration/in-active-list/in-active-list.component';
import { UserDetailListComponent } from './modules/admin/user-detail-list/user-detail-list.component';
import { RoleListComponent } from './modules/admin/role-list/role-list.component';
import { DrugsListComponent } from './modules/masters/drugs-list/drugs-list.component';
import { PromotionListComponent } from './modules/masters/promotion-list/promotion-list.component';
import { CompaniesListComponent } from './modules/masters/companies-list/companies-list.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './modules/home/home.component';
import { LoginComponent } from './modules/login/login.component';
import { PlanOfCareComponent } from './modules/plan-of-care/plan-of-care.component';
import { ForgotPasswordComponent } from './modules/forgot-password/forgot-password.component';
import { ConsultationComponent } from './modules/consultation/consultation.component';
import { LabInvestigationListComponent } from './modules/masters/lab-investigation-list/lab-investigation-list.component';
import { DoctorListComponent } from './modules/registration/doctor-list/doctor-list.component';
import { LabListComponent } from './modules/registration/lab-list/lab-list.component';
import { PharmacyListComponent } from './modules/registration/pharmacy-list/pharmacy-list.component';
import { AppointmentComponent } from './modules/consultation/appointment/appointment.component';
import { PaymentComponent } from './modules/consultation/payment/payment.component';
import { PatientListComponent } from './modules/registration/patient-list/patient-list.component';
import { PatientRegistrationComponent } from './modules/registration/patient-registration/patient-registration.component';
import { PocComponent } from './modules/poc/poc.component';
import { ChangePasswordComponent } from './modules/change-password/change-password.component';
import { UserprofileComponent } from './modules/userprofile/userprofile.component';
import { DistrictListComponent } from './modules/masters/district-list/district-list.component';
import { MandalListComponent } from './modules/masters/mandal-list/mandal-list.component';
import { CityListComponent } from './modules/masters/city-list/city-list.component';
import { VillageListComponent } from './modules/masters/village-list/village-list.component';
import { StatesListComponent } from './modules/masters/states-list/states-list.component';
import { OrganizationListComponent } from './modules/configuration/organization-list/organization-list.component';
import { NodalOfficerListComponent } from './modules/masters/nodal-officer-list/nodal-officer-list.component'
import { FavoriteComponent } from './modules/consultation/favorite/favorite.component'
import { DrugTypeMappingListComponent } from './modules/masters/drug-type-mapping-list/drug-type-mapping-list.component';
import { RefundRequestComponent } from './modules/admin/refund-request/refund-request.component'
import { DashboardComponent } from './modules/dashboard/dashboard.component'
import { TransactionStatusComponent } from '../app/modules/consultation/transaction-status/transaction-status.component'
import { TestComponent } from './modules/test/test.component';
import { ManualprescriptionComponent } from './modules/modals/manualprescription/manualprescription.component';
import { AgentListComponent } from './modules/registration/agent-list/agent-list.component';
import { PermissionListComponent } from './modules/admin/permission-list/permission-list.component';
import { ReferralListComponent } from './modules/masters/referral-list/referral-list.component';
import { OrgPlansComponent } from './modules/configuration/org-plans/org-plans.component';
import { DrTemplateListComponent } from './modules/configuration/dr-template-list/dr-template-list.component';
import { CorporateplanslistComponent } from './modules/configuration/corporateplanslist/corporateplanslist.component';
import { EnrollcorpemplistComponent } from './modules/masters/enrollcorpemplist/enrollcorpemplist.component';
import { AgentlistCallComponent } from './modules/consultation/agentlist-call/agentlist-call.component';
import { AgentVideoComponent } from './modules/consultation/agent-video/agent-video.component';
import { ServicesListComponent } from './modules/masters/services-list/services-list.component';
import { DrugsToAgentmappingListComponent } from './modules/masters/drugs-to-agentmapping-list/drugs-to-agentmapping-list.component';
import { DrugsToStatemappingListComponent } from './modules/masters/drugs-to-statemapping-list/drugs-to-statemapping-list.component';
import { ApartmentComponent } from './modules/apartment/apartment.component';
import { VendorListComponent } from './modules/registration/vendor-list/vendor-list.component';
import { MatersResolver } from './shared/resolver/masters.resolver';
import { CenterRegistrationComponent } from './modules/registration/center-registration/center-registration.component';
import { CenterRegistrationFormComponent } from './modules/registration/center-registration-form/center-registration-form.component';
import { DiagnosticsReportComponent } from './modules/Reports/diagnostics-report/diagnostics-report.component';
import { OtcMedicinesReportComponent } from './modules/Reports/otc-medicines-report/otc-medicines-report.component';
import { HubCoordinatorRegistrationComponent } from './modules/registration/hub-coordinator-registration/hub-coordinator-registration.component';
import { HubCoordinatorListComponent } from './modules/registration/hub-coordinator-list/hub-coordinator-list.component';
import { WebMobileSettingsComponent } from './modules/configuration/web-mobile-settings/web-mobile-settings.component';
import { ReferralComponent } from './modules/Referral/referral/referral.component';
import { ReferralsListComponent } from './modules/Referral/referrals-list/referrals-list.component';
import { HubListComponent } from './modules/registration/hub-list/hub-list.component';
import { ReferalPatientListComponent } from './modules/consultation/referal-patient-list/referal-patient-list.component';
import { DssReportComponent } from './modules/Reports/dss-report/dss-report.component';
import { AttendanceReportComponent } from './modules/Reports/attendance-report/attendance-report.component';
import { DrugDispenseComponent } from './modules/consultation/drug-dispense/drug-dispense.component';
import { MedicinesDetailsComponent } from './modules/consultation/medicines-details/medicines-details.component';
import { AssessmentManagementComponent } from './modules/masters/assessment-management/assessment-management.component';
import {AssesmentReportComponent} from './modules/Reports/assesment-report/assesment-report.component'
import { AttendanceCapturingComponent } from './modules/masters/attendance-capturing/attendance-capturing.component';
import { StockManagementComponent } from './modules/masters/stock-management/stock-management.component';
import { EhrReportComponent } from './modules/Reports/ehr-report/ehr-report.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { HandoverformReportComponent } from './modules/Reports/handoverform-report/handoverform-report.component';
import { NewPocComponent } from './modules/new-poc/new-poc.component';
import { NetworkStatusReportComponent } from './modules/Reports/network-status-report/network-status-report.component';
import { NetworkMonthlyReportComponent } from './modules/Reports/network-monthly-report/network-monthly-report.component';
import { DssReportNewComponent } from './modules/Reports/dss-report-new/dss-report-new.component';
import { DoctorListNewComponent } from './modules/registration/doctor-list-new/doctor-list-new/doctor-list-new.component';
import { NdhmNotificationComponent } from './modules/registration/ndhm-notification/ndhm-notification.component';
import { ConsentListComponent } from './modules/ndhm/consent-list/consent-list.component';
const routes: Routes = [
  {
    path: 'home', component: HomeComponent,canActivate:[AuthGuard],
     children: [
      { path: 'eTest', component: TestComponent },
      { path: 'MP', component: ManualprescriptionComponent },
      { path: 'dashboard', component: DashboardComponent },
      // Registration
      { path: 'labList', component: LabListComponent },
      { path: 'pharmacyList', component: PharmacyListComponent },
      // { path: 'doctorList', component: DoctorListComponent },
      { path: 'doctorList', component: DoctorListNewComponent },
      { path: 'consultation', component: ConsultationComponent },
      { path: 'hospitalDetailList', component: HospitalDetailListComponent },
      { path: 'inActiveList', component: InActiveListComponent },
      { path: 'patient', component: PatientRegistrationComponent },
      { path: 'patientList', component: PatientListComponent },
      { path: 'agent', component: AgentListComponent },
      { path: 'refrl', component: ReferralListComponent },
      { path: 'centers', component:CenterRegistrationComponent },
      { path: 'hub-coordinator', component:HubCoordinatorListComponent },
      { path: 'hubs', component:HubListComponent},
      // { path: 'centersForm', component:CenterRegistrationFormComponent },
      { path: 'vendor', component: VendorListComponent, resolve: { mastersData: MatersResolver } },
      // Consultation
      { path: 'appointment', component: AppointmentComponent },
      { path: 'payment', component: PaymentComponent },
      { path: 'pocc', component: PlanOfCareComponent },
      { path: 'poc', component: NewPocComponent},
     // { path: 'poc', component: PocComponent},
      { path: 'support', component: SupportComponent },
      { path: 'favorite', component: FavoriteComponent },
      { path: 'transaction', component: TransactionStatusComponent },
      { path: 'agentlistcall', component: AgentlistCallComponent },
      { path: 'agentvideo', component: AgentVideoComponent },
      { path: 'referalpatientlist', component: ReferalPatientListComponent },
      { path: 'drugdispense', component: DrugDispenseComponent },
      { path: 'medicinesdetails', component: MedicinesDetailsComponent },
      { path: 'ehr', component: EhrReportComponent },
      // Masters
      { path: 'nodal', component: NodalOfficerListComponent },
      { path: 'couponList', component: CouponListComponent },
      { path: 'companyList', component: CompaniesListComponent },
      { path: 'promotionList', component: PromotionListComponent },
      { path: 'drugsList', component: DrugsListComponent },
      { path: 'labInvestigationList', component: LabInvestigationListComponent },
      { path: 'stateList', component: StatesListComponent },
      { path: 'districtList', component: DistrictListComponent },
      { path: 'mandalList', component: MandalListComponent },
      { path: 'cityList', component: CityListComponent },
      { path: 'villageList', component: VillageListComponent },
      { path: 'drugTypeMappingList', component: DrugTypeMappingListComponent },
      { path: 'enrlEmpList', component: EnrollcorpemplistComponent },
      { path: 'serviceslist', component: ServicesListComponent },
      { path: 'drugsStateList', component: DrugsToStatemappingListComponent },
      { path: 'drugsAgentList', component: DrugsToAgentmappingListComponent },
      { path: 'assessmentmanagement', component: AssessmentManagementComponent },
      { path: 'attendanceCapturing', component: AttendanceCapturingComponent },
      { path: 'viewUpdateStock', component:StockManagementComponent },
      // Admin
      { path: 'roleList', component: RoleListComponent },
      { path: 'userList', component: UserDetailListComponent },
      { path: 'changepassword', component: ChangePasswordComponent },
      { path: 'userprofile', component: UserprofileComponent },
      { path: 'requestref', component: RefundRequestComponent },
      { path: 'screens', component: PermissionListComponent },
      //apartment schedule
      { path: 'aprtment', component: ApartmentComponent },
      // Configuration Settings
      { path: 'logo', component: UploadLogoComponent },
      { path: 'prescription', component: CustomisePrescriptionComponent },
      { path: 'paymentGateway', component: PaymentGatewayComponent },
      { path: 'sms', component: SMSSMTPComponent },
      { path: 'consentForms', component: UploadConsentFormsComponent },
      { path: 'organizationList', component: OrganizationListComponent },
      { path: 'orgPlans', component: OrgPlansComponent },
      { path: 'drtmplt', component: DrTemplateListComponent },
      { path: 'corplanList', component: CorporateplanslistComponent },
      { path: 'settings', component: WebMobileSettingsComponent },
      //Reports
      { path: 'diagnostics', component: DiagnosticsReportComponent },
      { path: 'otcmed', component: OtcMedicinesReportComponent },
      // { path: 'dssreport', component: DssReportComponent },
      { path: 'dssreport', component: DssReportNewComponent },
      { path: 'atndncreport', component: AttendanceReportComponent },
      { path: 'assesmentreport', component: AssesmentReportComponent },
      //Referral
      { path: 'referral', component: ReferralsListComponent },
      {path: 'handoverformreport', component: HandoverformReportComponent},
      // new line for network status 29-08-2022
      {path: 'networkstatusreport', component: NetworkStatusReportComponent},
      {path: 'networkmonthlystatusreport', component: NetworkMonthlyReportComponent},
      // 07-04-23 
      {path: 'ndhmNotification', component:NdhmNotificationComponent},
      {path :'consentlist', component:ConsentListComponent}
    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'forgotpassword', component: ForgotPasswordComponent },
  { path: '', component: LoginComponent },
  { path: '**', redirectTo: 'login' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [MatersResolver]
})

export class AppRoutingModule { }