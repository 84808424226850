<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Hospital Registration
                        <button *ngIf="setPrivilege(Screen_Name.HospitalRegistration, Permission.Add)"
                            (click)="modalTitle='Hospital Registration';ChkLimit();"
                            class="btn btn-blue btn-icon float-right"><i class="fa fa-plus"></i> <!-- openModal() -->
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="mt3">
                            <button type="button" class="btn btn-sm px-4 mb-2 btn-secondary float-right" *ngIf ="excelData.length>0" (click)="downLoadExcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button>
                        </div>
                        <div class="table-responsive">
                            <table datatable id="hospitalTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>S No </th>
                                        <th>District</th>
                                        <th>Block</th>
                                        <th>Hospital Name</th>
                                        <th>Hospital Type</th>
                                        <th>Category</th>
                                        <th>Address</th>
                                        <th class="text-center" style="width:12%;">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of lstGrid;let i=index;">
                                        <td>{{i+1}}</td>
                                        <td>{{item.district_name}}</td>
                                        <td>{{item.mandal_name}}</td>
                                        <td class="text-uppercase">{{item.hospital_name}}</td>
                                        <td>{{item.center_type}}</td>
                                        <td>{{item.category}}</td>
                                        <td>{{item.address}}</td>
                                        <td class="singleLine">
                                            <!-- <td><div class="badge badge-success badge-pill">
                                                    <a  data-toggle="modal" data-target="#exampleModalScrollable">
                                                <i class="fa fa-edit text-white"></i> </a> </div> -->
                                            <div class="badge badge-primary badge-pill pointer"
                                                *ngIf="setPrivilege(Screen_Name.HospitalRegistration, Permission.Edit)">
                                                <a (click)="modalTitle='Edit Hospital Details';openModal(item)"> <i
                                                    class="fa fa-edit text-white"></i></a></div>&nbsp;&nbsp;&nbsp;
                                            <div class="badge badge-danger badge-pill pointer"
                                                *ngIf="setPrivilege(Screen_Name.HospitalRegistration, Permission.Delete)">
                                                <a (click)="onDelete(item.id)"><i class="fa fa-trash text-white"></i></a></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>