
    <div class="modal-header">
        <!-- <h4 class="modal-title">{{title}}</h4> -->
        <button type="button" class="close" aria-label="Close" 
        (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
          <form [formGroup]="cityForm" (ngSubmit)="onSubmit($event)">
    
              <div class="row">
                <div class="col-xl-4">
                  <div class="form-group">
                    <label class="control-label">State <span class="text-danger">*</span> </label>
                    <div>
                      <select formControlName="state_id" id="state_id" #State (change)="locationChanged(State.value,'district')"
                         class="form-control form-control-sm"
                         [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }">
                        <option value="">Select</option>
                        <option *ngFor="let state of stateList" [value]="state.id">
                          {{state.state_name}}</option>
                      </select>
                      <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                        <div *ngIf="f.state_id.errors.required">State is required
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                      <label class="control-label">District <span class="text-danger">*</span> </label>
                      <div>
                        <select formControlName="district_id" id="district_id" #District
                          (change)="locationChanged(District.value,'mandal')"
                           class="form-control form-control-sm"
                           [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }">
                          <option value="">Select</option>
                          <option *ngFor="let district of districtList" [value]="district.id">
                            {{district.district_name}}</option>
                        </select>
                        <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                          <div *ngIf="f.district_id.errors.required">District is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="form-group">
                      <label class="control-label">City Name <span class="text-danger">*</span> </label>
                      <div>
                        <input type="text" autocomplete="off" formControlName="city_name" id="city_name"
                          placeholder="City Name" maxlength="50"
                          class="form-control form-control-sm "
                           [ngClass]="{ 'is-invalid': submitted && f.city_name.errors }"
                          onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'> 
                      
                        <div *ngIf="submitted && f.state_name.errors" class="invalid-feedback">
                            <div *ngIf="f.city_name.errors.required">State Name is required</div>
                          </div> 
                        </div>
  
                    </div>
                  </div>
  
                </div>
              <input type="submit"  id="btnsubmit" style="display: none;">

          </form>
        </div>
    </div>
  
    <div class="modal-footer">
      <input type="submit" class="btn btn-sm btn-primary" (click)="submitForm()" value="Submit">&nbsp;&nbsp;
      <button type="button" class="btn btn-sm btn-outline-dark"
          (click)="activeModal.close('Close click')">Close</button>
  </div>
   