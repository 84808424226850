<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Pharmacist/Healthcare Professional Sessions List
                        <button *ngIf="setPrivilege(Screen_Name?.AgentDetails, Permission.Add)"
                            (click)="modalTitle='Pharmacist/Healthcare Professional Registration Details';ChkLimit()"
                            class="btn btn-blue btn-icon float-right"> <i class="fa fa-plus"></i>
                            <!-- openAgentModal() -->
                        </button>
                    </div>
                    <!-- <div class="card-body">
                        <div class="table-responsive">
                            <table datatable id="agentTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th style="width:7%;" class="singleLine">S No </th>
                                        <th class="singleLine">Health Associate Name</th>
                                        <th>Health Associate Contact No</th>
                                        <th>Lab Preferred</th>
                                        <th>Pharmacy Preferred</th>
                                        <th>Location Type</th>
                                        <th class="text-center" style="width:12%;">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let agent of lstAgents;let ind=index;">
                                        <td class="singleLine text-center">{{(ind+1)}}</td>
                                        <td class="singleLine text-uppercase">{{agent?.first_name}} {{agent?.last_name}}</td>
                                        <td>{{agent?.mobile}}</td>
                                        <td>{{getName(agent?.preferred_lab_id, 'lab')}}</td>
                                        <td>{{getName(agent?.preferred_pharmacy_id)}}</td>
                                        <td>{{getName(agent?.location_type_id, 'loc')}}</td>
                                        <td class="singleLine text-center">
                                            <span>
                                                <a *ngIf="setPrivilege(Screen_Name?.AgentDetails, Permission.Edit)" title="Edit"
                                                    (click)="modalTitle='Health Associate Registration Details';onAgentEdit(agent?.id)"
                                                    class="badge badge-info badge-pill"><i class="fa fa-edit text-white pointer"></i></a>&nbsp;
                                            </span>
                                            <span>
                                                <a *ngIf="setPrivilege(Screen_Name?.AgentDetails, Permission.Delete)" (click)="onDelete(agent?.id)"
                                                    title="Delete" class="badge badge-danger badge-pill"><i class="fa fa-trash text-white pointer"></i></a>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div> -->
                    <div class="card-body">
                        <form [formGroup]="agenttSearchForm" method="POST" (ngSubmit)="getAgentsData(0)">
                            <div class="row ">
                                <div class="container">
                                    <div class="row ">
                                        <div class="col-md-1">
                                            <label class="control-label"
                                                style="line-height:3"><strong>Keyword:</strong></label>
                                        </div>
                                        <div class='col-md-5'>
                                            <div class="form-group">
                                                <input id="search_string" name="search_string" type="text"
                                                    autocomplete="off" formControlName="search_string"
                                                    class="form-control"
                                                    placeholder="Search by : Name / Email / Mobile Number"
                                                    maxlength="50" ngMaxlength=50 value="{{search_string}}">
                                                <small class="text-warning text-center" style="margin-left:30px;">(
                                                    Search by : Name / Email / Mobile Number )</small>
                                            </div>
                                        </div>
                                        <div class='col-md-2'>
                                            <button style="margin-top:4px;" type="submit" class="btn btn-sm btn-primary"
                                                value="Submit"><i class="fa fa-search"></i> Search</button>
                                        </div>
                                        <div class="col-md" >
                                            <!-- <button type="button" style="margin-top:4px;" class="btn btn-sm btn-secondary px-4 float-right" *ngIf="lstAgents && lstAgents.length>0" (click)="downLoadExcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button>  -->
                                            <!-- <button type="button" class="btn  btn-sm btn-secondary float-right" *ngIf ="excelData.length>0" (click)="downLoadExcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp;  -->
                                            <button type="button" class="btn btn-secondary btn-sm px-4 float-right mb-1 text-center" *ngIf ="excelData.length>0" (click)="downLoadExcel()" >
                                                <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> 
                                        </div>
                

                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="table-responsive" *ngIf="lstAgents.length > 0 && submitted;else empty_tbl_response">
                            <table id="agentTable" class="table table-striped table-bordered table-hover table-sm"
                                style="width:100%">
                                <thead class="text-center">
                                    <tr>
                                        <th style="width:7%;" class="singleLine">S No </th>
                                        <th>District</th>
                                        <th>Block</th>
                                        <th>Hub/Centre</th>
                                        <th class="singleLine">Pharmacist/Healthcare Professional Name</th>
                                        <th class="singleLine">Pharmacist/Healthcare Professional Type</th>
                                        <th>Pharmacist/Healthcare Professional Contact No</th>
                                        <th>Lab Preferred</th>
                                        <th>Pharmacy Preferred</th>
                                        <th>Location Type</th>
                                        <th class="text-center" style="width:12%;">Actions</th>
                                        <!-- <th>DSS</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let agent of lstAgents;let ind=index;">
                                        <td class="singleLine text-center">{{(rowIndex+1+ind)}}</td>
                                        <td class="singleLine text-center">{{(agent.district_name)}}</td>
                                        <td class="singleLine text-center">{{(agent.mandal_name)}}</td>
                                        <td *ngIf="agent.center_type==='Center' || agent.center_type==='Spoke' || agent.center_type==='Asha'">{{agent.center_name}}</td>
                                        <td *ngIf="agent.center_type==='Hub' || agent.center_type==='Referral'">{{agent.hub_name}}</td>
                                       
                                      
                                        <td class="singleLine text-uppercase">{{agent?.first_name}} {{agent?.last_name}}
                                        </td>
                                        <td>{{agent?.center_type}}</td>
                                        <td>{{agent?.mobile}}</td>
                                        <td>{{getName(agent?.preferred_lab_id, 'lab')}}</td>
                                        <td>{{getName(agent?.preferred_pharmacy_id)}}</td>
                                        <td>{{getName(agent?.location_type_id, 'loc')}}</td>
                                        <td class="singleLine text-center">
                                            <span>
                                                <a *ngIf="setPrivilege(Screen_Name?.AgentDetails, Permission.Edit)"
                                                    title="Edit"
                                                    (click)="modalTitle='Health Associate Registration Details';onAgentEdit(agent?.id)"
                                                    class="badge badge-info badge-pill"><i
                                                        class="fa fa-edit text-white pointer"></i></a>&nbsp;
                                            </span>
                                            <span>
                                                <a *ngIf="setPrivilege(Screen_Name?.AgentDetails, Permission.Delete)"
                                                    (click)="onDelete(agent?.id)" title="Delete"
                                                    class="badge badge-danger badge-pill"><i
                                                        class="fa fa-trash text-white pointer"></i></a>
                                            </span>
                                        </td>
                                        <!-- <td><button class="btn btn-sm btn-dark" (click)="openDSSForm()">DSS</button></td> -->
                                    </tr>
                                </tbody>
                            </table>
                            <div class="row">
                                <div class="col-sm-12 col-md-5">
                                    <div>
                                        Showing {{(rowIndex+1)}} to {{(rowIndex+lstAgents.length)}} of {{totalElements}}
                                        entries.
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-7">
                                    <div>
                                        <nav aria-label="Page navigation example">
                                            <ul class="pagination justify-content-end">
                                                <li class="page-item" *ngIf="PrevpageNumber >= 0"><a class="page-link"
                                                        (click)="getAgentsData(PrevpageNumber)"
                                                        style="cursor:pointer"><span>Previous</span></a></li>
                                                <li class="page-item active" aria-current="page"
                                                    *ngIf="NextpageNumber > 0"><a class="page-link "
                                                        (click)="getAgentsData(NextpageNumber)"
                                                        style="cursor:pointer"><span>Next</span></a></li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ng-template #empty_tbl_response>
                            <div class="text-danger">No matching records found</div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>