<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <div class="table-responsive">
                    <table datatable id="consentFilesList" class="table table-striped table-bordered table-sm text-center"
                style="width:100%">
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Resource Type</th>
                            <th>HIP Name</th>
                            <!-- <th>Patiene Name</th>
                            <th>Gender</th>
                            <th>Title</th>
                            <th>Created On</th> -->
                            <!-- <th>Type of File</th> -->
                            <th>File</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of fileList; let i=index;">
                            <td>{{i+1}}</td>
                    <!-- <td>{{item?.decryptedDocument?.document_title}}</td>
                    <td>{{item?.doc_name}}</td>
                    <td>{{item?.pat_name}}</td>
                    <td>{{item?.gender}}</td>
                    <td>{{item?.title}}</td>
                    <td>{{item?.created_on | date: 'dd-MM-yyyy & HH:mm'}}</td> -->
                    <td>{{item?.decryptedDocument?.entry[0]?.resource.title}}</td>
                    <td>{{item?.hip_name}}</td>
                    <!-- <td>{{item?.file_type}}</td>  -->
                 <td>
                      <!-- <button class="btn btn-sm btn-primary">View</button> -->
                      <!-- <button class="btn btn-primary mb-2 me-2 btn-sm"  data-target="#appModal" (click)="domSanitize(item)">View</button> -->
                      <button class="btn btn-primary mb-2 me-2 btn-sm"   (click)="openViewModal(item)">View</button>
                 
                    </td>
                        </tr>
                    </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
    </div> 
 <div class="modal-footer">
    <button class="btn btn-primary btn-sm me-md-2" type="submit" (click)="activeModal.dismiss('Cross click')">Close</button>
    <!-- <pre>Selected time: {{ time }}</pre> -->
    <!-- <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
</div> 
<!-- <div class="modal fade t10" id="appModal">
    <div class="modal-dialog">
        <div class="modal-content modalpopborder" style="width: 51rem;margin-left: -16rem;">
            <div class="modal-header headerbg modalpopborderhead p-2">
                <h6 class="modal-title text-white">File</h6>
                <button type="button" id="closeid" class="close text-white" (click)="close()">&times;</button>
            </div>
            <div class="modal-body modalbodybg">
                <img [src]="myBase64" alt="Image Source" style="width: 50rem;">
            </div>
        </div>
    </div>
</div> -->