import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Common } from 'src/app/common';
import { RegistrationService } from 'src/app/shared/services/registration.service';
declare let $: any;
import * as uuid from 'uuid';
@Component({
  selector: 'app-consent-file-details',
  templateUrl: './consent-file-details.component.html',
  styleUrls: ['./consent-file-details.component.css']
})

export class ConsentFileDetailsComponent implements OnInit {

  @Input() title;
  @Input() consentId;
  @Input() item: any;
  fileList: any;
  fileListNew: any = [];
  myBase64: any;
  isOrgDetailsAvail: boolean;
  medicationRequest: any;
  medicationList: Array<any> = [];
  conditionList: Array<any> = [];
  allrgyList: Array<any> = [];
  carePlanList: Array<any> = [];
  ProcedureList: Array<any> = [];
  appointmentList: Array<any> = [];
  documentReferenceList: any = [];
  docRefcodeList: any = [];
  fileUrl: any;
  obersvationsList: any = [];
  constructor(private regService: RegistrationService
    , public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , protected toastr: ToastrService
    , private sanitizer: DomSanitizer) { }
  ngOnInit(): void {
    this.loadFiles();
  }
  loadFiles() {


    let Resp = this.item.decryptedDocument;

    this.fileList = Resp.entry;
    // console.log('file list', this.fileList);
    let document_title, status, title, doc_name, pat_name, created_on, file_type, url, file, fileName, gender, medicationList = [], medicInstructionList = [], conditionInstructionList = [], conditionList = [],
      encounter: any, carePlan: any, procedure: any, diagRprt: any, appointment: any, orgDetails: any = {}, section = [];
    let data: any;

    if (this.fileList.length > 0) {
      this.fileListNew = [];
      setTimeout(() => {
        this.fileList.map(re => {
          if (re.resource.resourceType === 'Composition') {
            // created_on=re.date;
            document_title = re.resource.title;
            status = re.resource.status;
            section = re.resource.section;
          }
          else if (re.resource.resourceType === 'Practitioner') {
            if (re.resource.name) {
              doc_name = re.resource.name[0]?.text;
            } else {
              doc_name = '';
            }

          }
          else if (re.resource.resourceType === 'Patient') {
            if (re.resource.name) {
              if (re.resource.name[0]?.text) {
                pat_name = re.resource.name[0]?.text;
              } else if (re.resource.family || (re.resource.given && re.resource.given[0])) {
                if (re.resource.given[0]) {
                  pat_name = re.resource.given[0];
                } if (re.resource.family) {
                  pat_name = pat_name + re.resource.family;
                }

              } else {
                pat_name = '';
              }

            } if (re.resource.gender) {
              gender = re.resource.gender;
            }


          }
          else if (re.resource.resourceType === 'Organization') { //organizationList

            orgDetails = {
              'orgName': re.resource.name, 'telecom': re.resource.telecom,
              'address': re.resource.address, 'Website': re.resource.endpoint
            };
            this.isOrgDetailsAvail = true;

          }
          else if (re.resource.resourceType === 'DocumentReference') {
            let obj: any = { 'fileName': '', 'status': re.resource.status, 'file': '' }
            // this.docRefcodeList = re.resource.;
            if (re.resource.type?.coding[0]) {
              title = re.resource.type?.coding[0]?.display;
              file_type = re.resource.type?.coding[0]?.contentType;
              obj.fileName = title;
            }
            if (re.resource.content) {
              file = re.resource.content[0]?.attachment?.data;
              url = re.resource.content[0]?.attachment?.url;
              fileName = re.resource.content[0]?.attachment?.title;
              obj.file = re.resource.content[0]?.attachment?.data;
            }
            if (re.resource.content && re.resource.type?.coding[0]?.display && re.resource.content[0]?.attachment?.data && obj.fileName !== 'PRESCRIPTION') {
              this.documentReferenceList.push(obj);
            }
          }
          else if (re.resource.resourceType === 'Binary') {
           // alert('binary')
            let obj: any = { 'fileName': '', 'status': re.resource.status, 'file': '' }
            // this.docRefcodeList = re.resource.;
            if (re.resource.type?.coding[0]) {
              title = re.resource.type?.coding[0]?.display;
              file_type = re.resource.type?.coding[0]?.contentType;
              obj.fileName = title;
            }else{
              obj.fileName = "PRESCRIPTION";
            }
            if (re.resource.content) {
              file = re.resource.content[0]?.attachment?.data;
              url = re.resource.content[0]?.attachment?.url;
              fileName = re.resource.content[0]?.attachment?.title;
              obj.file = re.resource.content[0]?.attachment?.data;
            }

            if (re.resource.contentType && re.resource.contentType === 'application/pdf') {
              file = re.resource?.data;
              url = re.resource?.url;
              fileName = "PRESCRIPTION";
              obj.file = re.resource?.data;
            }

            if (re.resource.content && re.resource.type?.coding[0]?.display && re.resource.content[0]?.attachment?.data && obj.fileName !== 'PRESCRIPTION') {
              this.documentReferenceList.push(obj);
            }else if (re.resource.contentType && re.resource.contentType === 'application/pdf'&&obj.fileName!=='PRESCRIPTION') {
              this.documentReferenceList.push(obj);
            }

          }
          
          else if (re.resource.resourceType === 'Procedure') {

            created_on = re.resource.performedDateTime;
          }
          else if (re.resource.resourceType === 'Medication') { //Medication
            let medic = { 'name': re.resource.code?.text, 'id': re.resource.id, 'instruction': '' };

            // if(medicInstructionList.length>0){
            // medic.instruction=medicInstructionList.find(f=> +f.id== +medic.id)?.instruction;
            this.medicationList.push(medic);
            // }
            //MedicationRequest

          }
          else if (re.resource.resourceType === 'Condition') { //Condition
            let cdn: any;
            // if(re.resource.category[0]){
            // cdn= {'name':re.resource.category[0]?.coding[0]?.display,'id':re.resource.id,'instruction':''};
            // }else{
            if (re.resource?.code) {
              cdn = { 'name': re.resource?.code?.text, 'id': re.resource.id, 'severity': re.resource.severity?.text, 'instruction': '', 'clinicalStatus': re.resource.clinicalStatus?.text };
              this.conditionList.push(cdn);
            }
            // cdn = { 'name': re.resource?.code?.text, 'id': re.resource.id, 'severity': re.resource.severity?.text, 'instruction': '', 'clinicalStatus': re.resource.clinicalStatus?.text };
            // }


            // if(conditionInstructionList.length>0){
            //   cdn.instruction=conditionInstructionList.find(f=> +f.id== +cdn.id)?.instruction;
            this.conditionList.push(cdn);
            // }


          }
          else if (re.resource.resourceType === 'MedicationRequest') { //MedicationRequest
            this.medicationRequest = re;

          }
          else if (re.resource.resourceType === 'AllergyIntolerance') { //AllergyIntolerance
            let allrgy: any = { 'allrgyType': re.resource.type, 'category': re.resource.category ? re.resource.category[0] : null, 'criticality': re.resource.criticality, 'duration': re.resource.onsetString, 'consultedDoctor': re.resource.asserter?.display, 'remarks': re.resource.note ? re.resource.note[0]?.text : '' };
            this.allrgyList.push(allrgy);
          }


          else if (re.resource.resourceType === 'Encounter') { //MedicationRequest
            encounter = { 'encounterName': re.resource.class?.display, 'status': re.resource.status, 'date': re.resource.period?.start };
          }
          //   "resourceType": "CarePlan",
          else if (re.resource.resourceType === 'CarePlan') { //CarePlan

            carePlan = { 'title': re.resource.title, 'description': re.resource.description, 'status': re.resource.status, 'startdate': re.resource.period?.start, 'enddate': re.resource.period?.end, 'remarks': re.resource.note };
            if (re.resource.title) {
              this.carePlanList.push(carePlan);
            }

          }
          //  Procedure 
          else if (re.resource.resourceType === 'Procedure') { //Procedure
            procedure = { 'name': re.resource.coding ? re.resource.coding[0]?.display : null, 'performedDateTime': re.resource.performedDateTime, 'status': re.resource.status, 'patName': pat_name, 'complication': re.resource.complication ? re.resource.complication[0]?.coding[0]?.display : null };
            if (re.resource.coding && re.resource.coding[0]?.display) {
              this.ProcedureList.push(procedure);
            }

          }
          else if (re.resource.resourceType === 'DiagnosticReport') { //DiagnosticReport
            let media: any;
            // if(re.resource.media){
            // let link=re.resource?.media[0]?.link
            //  media={'media':re.resource?.media[0]?.comment,'link':re.resource?.media[0]?.link};
            // }else{
            //   media={'media':null,'link':null};
            // }
            diagRprt = {
              'reportName': re.resource.code?.text, 'patName': re.resource.subject?.display, 'effectiveDateTime': re.resource?.effectiveDateTime, 'issuedDate': re.resource.issued,
              'satus': re.resource.status, 'media': media, 'conclusion': re.resource.conclusion, 'result': re.resource.result ? re.resource.result : []
            };
          }
          //  "resourceType": "Appointment",
          else if (re.resource.resourceType === 'Appointment') { //Appointment
            if (re.resource.participant && re.resource.participant[0]) {
              appointment = {
                'docName': re.resource.participant[0]?.actor.display, 'patName': pat_name, 'startDate': re.resource.start, 'endDate': re.resource.end,
                'satus': re.resource.status, 'accept': re.resource.participant[0]?.status, 'description': re.resource.description
              };
              this.appointmentList.push(appointment);
            }

          }
          else if (re.resource.resourceType === 'Observation') { //organizationList
            let observation: any;
            if (re.resource.valueQuantity) {
              observation = { 'code': re.resource.code?.text, 'value': re.resource.valueQuantity?.value, 'unit': re.resource.valueQuantity?.unit };
            } else {
              observation = { 'code': re.resource.code?.text, 'value': re.resource.valueString, 'unit': re.resource.valueQuantity ? re.resource.valueQuantity?.unit : '' };
            }
            if (re.resource.code?.text) {
              this.obersvationsList.push(observation);
            }
          }





        })

        // setTimeout(() => {
        // alert('NEW CHECK');
        this.assignMedicationRequest();
        data = { 'document_title': document_title, 'status': status, 'section': section, 'doc_name': doc_name, 'pat_name': pat_name, 'gender': gender, 'title': title, 'created_on': created_on, 'file_type': file_type, 'file': file, 'url': url, 'fileName': fileName, 'encounter': encounter, 'orgDetails': orgDetails, 'observations': this.obersvationsList, 'medicationList': this.medicationList, 'conditionList': this.conditionList, 'allrgyList': this.allrgyList, 'diagRprt': diagRprt, 'carePlanList': this.carePlanList, 'procedureList': this.ProcedureList, 'appointmentList': this.appointmentList, 'documentReference': this.documentReferenceList };
        this.fileListNew.push(data);
        // alert('NEW CHECK');
        // console.log('######',this.fileListNew);
        // alert('#################');
        if (this.fileListNew.length > 0) {
          Common.loadDataTable(this.fileListNew, '#consentFilesList');
        }
        // alert('########');
        // }, 2500);

      }, 2000);

    }

    // });
    // console.log('file list',this.fileList);
    // alert('#########');

  }

  downloadAsPDF(item: any) {
    if (item.url && item.url !== '' && item.url !== null) {
      let link = document.createElement("a");
      link.setAttribute('href', item.url),
        link.setAttribute('visibility', 'hidden'),
        link.setAttribute('display', 'none'),
        link.setAttribute('target', '_blank'),
        document.body.appendChild(link), link.click();

    } else {
      var base64String = item.file;
      if (base64String.startsWith("JVB")) {
        base64String = "data:application/pdf;base64," + base64String;
        this.downloadFileObject(base64String, item);
      } else if (base64String.startsWith("data:application/pdf;base64")) {
        this.downloadFileObject(base64String, item);
      } else {
        alert("Not a valid Base64 PDF string. Please check");
      }
    }

  }

  downloadFileObject(base64String, item) {
    const linkSource = base64String;
    const downloadLink = document.createElement("a");
    const fileName = item.fileName + ".pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  loadFilesNew() {


    let Resp = this.item.decryptedDocument;

    this.fileList = Resp.entry;
    console.log('file list', this.fileList);
    let document_title, status, title, doc_name, pat_name, created_on, file_type, file, gender, medicationList = [], medicInstructionList = [], conditionInstructionList = [], conditionList = [],
      encounter: any, carePlan: any, procedure: any, diagRprt: any, appointment: any, orgDetails: any = {}, section = [];
    let data: any;

    if (this.fileList.length > 0) {
      this.fileListNew = [];
      setTimeout(() => {
        this.fileList.map(re => {
          if (re.resource.resourceType === 'Composition') {
            // created_on=re.date;
            document_title = re.resource.title;
            status = re.resource.status;
            section = re.resource.section;
          }
          else if (re.resource.resourceType === 'Practitioner') {
            if (re.resource.name) {
              doc_name = re.resource.name[0]?.text;
            }

          }
          else if (re.resource.resourceType === 'Patient') {
            if (re.resource.name) {
              pat_name = re.resource.name[0]?.text;
            } if (re.resource.gender) {
              gender = re.resource.gender;
            }


          }
          else if (re.resource.resourceType === 'Organization') { //organizationList

            orgDetails = {
              'orgName': re.resource.name, 'telecom': re.resource.telecom,
              'address': re.resource.address, 'Website': re.resource.endpoint
            };
            this.isOrgDetailsAvail = true;

          }
          else if (re.resource.resourceType === 'DocumentReference') {
            let obj: any = { 'fileName': '', 'status': re.resource.status, 'file': '' }
            // this.docRefcodeList = re.resource.;
            if (re.resource.type?.coding[0]) {
              title = re.resource.type?.coding[0]?.display;
              file_type = re.resource.type?.coding[0]?.contentType;
              obj.fileName = title;
            }
            if (re.resource.content) {
              file = re.resource.content[0]?.attachment?.data;
              obj.file = re.resource.content[0]?.attachment?.data;
            }

            this.documentReferenceList.push(obj)

          }
          else if (re.resource.resourceType === 'Procedure') {

            created_on = re.resource.performedDateTime;
          }
          else if (re.resource.resourceType === 'Medication') { //Medication
            let medic = { 'name': re.resource.code?.text, 'id': re.resource.id, 'instruction': '' };

            this.medicationList.push(medic);
            // }
            //MedicationRequest

          }
          else if (re.resource.resourceType === 'Condition') { //Condition
            let cdn: any;

            cdn = { 'name': re.resource?.code?.text, 'id': re.resource.id, 'severity': re.resource.severity?.text, 'instruction': '', 'clinicalStatus': re.resource.clinicalStatus?.text };

            this.conditionList.push(cdn);



          }
          else if (re.resource.resourceType === 'MedicationRequest') { //MedicationRequest
            this.medicationRequest = re;

          }
          else if (re.resource.resourceType === 'AllergyIntolerance') { //AllergyIntolerance
            let allrgy: any = { 'allrgyType': re.resource.type, 'category': re.resource.category ? re.resource.category[0] : null, 'criticality': re.resource.criticality, 'duration': re.resource.onsetString, 'consultedDoctor': re.resource.asserter?.display, 'remarks': re.resource.note ? re.resource.note[0]?.text : '' };
            if (re.resource.type) {
              this.allrgyList.push(allrgy);
            }
          }


          else if (re.resource.resourceType === 'Encounter') { //MedicationRequest
            encounter = { 'encounterName': re.resource.class?.display, 'status': re.resource.status, 'date': re.resource.period?.start };
          }
          //   "resourceType": "CarePlan",
          else if (re.resource.resourceType === 'CarePlan') { //CarePlan

            carePlan = { 'title': re.resource.title, 'description': re.resource.description, 'status': re.resource.status, 'startdate': re.resource.period?.start, 'enddate': re.resource.period?.end, 'remarks': re.resource.note };
            if (re.resource.title) {
              this.carePlanList.push(carePlan);
            }

          }
          //  Procedure 
          else if (re.resource.resourceType === 'Procedure') { //Procedure
            procedure = { 'name': re.resource.coding ? re.resource.coding[0]?.display : null, 'performedDateTime': re.resource.performedDateTime, 'status': re.resource.status, 'patName': pat_name, 'complication': re.resource.complication ? re.resource.complication[0]?.coding[0]?.display : null };
            if (re.resource.coding && re.resource.coding[0]?.display) {
              this.ProcedureList.push(procedure);
            }

          }
          else if (re.resource.resourceType === 'DiagnosticReport') { //DiagnosticReport
            let media: any;

            diagRprt = {
              'reportName': re.resource.code?.text, 'patName': re.resource.subject?.display, 'effectiveDateTime': re.resource?.effectiveDateTime, 'issuedDate': re.resource.issued,
              'satus': re.resource.status, 'media': media, 'conclusion': re.resource.conclusion, 'result': re.resource.result ? re.resource.result : []
            };
          }
          //  "resourceType": "Appointment",
          else if (re.resource.resourceType === 'Appointment') { //Appointment

            appointment = {
              'docName': re.resource.participant[0]?.actor.display, 'patName': pat_name, 'startDate': re.resource.start, 'endDate': re.resource.end,
              'satus': re.resource.status, 'accept': re.resource.participant[0]?.status, 'description': re.resource.description
            };
            this.appointmentList.push(appointment);
          }
          else if (re.resource.resourceType === 'Observation') { //organizationList
            let observation: any;
            if (re.resource.valueQuantity) {
              observation = { 'code': re.resource.code?.text, 'value': re.resource.valueQuantity?.value, 'unit': re.resource.valueQuantity?.unit };
            } else {
              observation = { 'code': re.resource.code?.text, 'value': re.resource.valueString, 'unit': re.resource.valueQuantity ? re.resource.valueQuantity?.unit : '' };
            }
            if (re.resource.code?.text) {
              this.obersvationsList.push(observation);
            }

          }





        })


        this.assignMedicationRequest();
        data = { 'document_title': document_title, 'status': status, 'section': section, 'doc_name': doc_name, 'pat_name': pat_name, 'gender': gender, 'title': title, 'created_on': created_on, 'file_type': file_type, 'file': file, 'encounter': encounter, 'orgDetails': orgDetails, 'observations': this.obersvationsList, 'medicationList': this.medicationList, 'conditionList': this.conditionList, 'allrgyList': this.allrgyList, 'diagRprt': diagRprt, 'carePlanList': this.carePlanList, 'procedureList': this.ProcedureList, 'appointmentList': this.appointmentList, 'documentReference': this.documentReferenceList };
        this.fileListNew.push(data);

        if (this.fileListNew.length > 0) {
          Common.loadDataTable(this.fileListNew, '#consentFilesList');
        }


      }, 2000);

    }



  }
  assignMedicationRequest() {
    if (this.medicationRequest) {
      let medicReq: any;
      medicReq = this.medicationRequest.resource.medicationReference?.reference.split("/")
      let data: any;
      if (medicReq && medicReq[0] === 'Medication') {
        // let me:any=this.medicationRequest.resource.medicationReference?.reference;
        // me=me.splice('/');

        let data = { 'instruction': this.medicationRequest.resource?.dosageInstruction[0]?.text, 'id': medicReq[1] };

        // medicInstructionList.push(data);
        if (this.medicationList.length > 0) {
          this.medicationList.map(re => {
            if (re.id === data.id) {
              re.instruction = data?.instruction;
            }
          })
        }


      } else if (medicReq && medicReq[0] === 'Condition') {
        let cd: any = this.medicationRequest.resource.medicationReference?.reference;
        cd = cd.splice('/');
        let data = { 'instruction': this.medicationRequest.resource.dosageInstruction[0]?.text, 'id': cd[1] };
        // conditionInstructionList.push(data);
        if (this.conditionList.length > 0) {
          this.conditionList.map(re => {
            if (re.id === data.id) {
              re.instruction = data?.instruction;
            }
          })


        }
      }
    }
  }

  domSanitize(item: any) {
    // let base64 = sessionStorage.getItem('ndhmbase')
    setTimeout(() => {
      this.myBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(
        `data:image/png/pdf;base64, ${item.file}`
      );
      $("#appModal").modal("show");
      Common.showPDF_File(URL.createObjectURL(this.myBase64))
    }, 1500);

    // this.isShowModal=true;
    // $("#appModal").modal("show");
    // console.log(dwnldCards);
  }
  domSanitizePdf(item: any) {
    // let base64 = sessionStorage.getItem('ndhmbase')
    setTimeout(() => {
      this.myBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(
        `data:image/png/pdf;base64, ${item}`
      );
      $("#appModal").modal("show");
      Common.showPDF_File(URL.createObjectURL(this.myBase64))
    }, 1500);

    // this.isShowModal=true;
    // $("#appModal").modal("show");
    // console.log(dwnldCards);
  }
  close() {
    $("#appModal").modal("hide");
  }
}