import { ExcelService } from 'src/app/shared/services/excel.service';
import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Common } from 'src/app/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
declare let $: any;

@Component({
  selector: 'app-ndhm-notification',
  templateUrl: './ndhm-notification.component.html',
  styleUrls: ['./ndhm-notification.component.css']
})
export class NdhmNotificationComponent implements OnInit {
  networkStatusForm:FormGroup;
  ndhmNotificationForm: FormGroup;
  start_date: any = new Date();
  end_date: any = new Date();
  max_date = new Date();
  ndhmList:any = []; 
  networkDateList: any=[];
  uploaded_file_with_signArray = new Array();
  minDate: Date; minToDate: Date; docId = '';
  submitted = false; userNames = [];
  userList: Array<any> = [];
  showExport: boolean = true;
  fileName = 'Network Status Report.xlsx';
  excelData: any;
  pipe = new DatePipe('en-US');
  mandalList: any[];
  villageList: any[];
  cityList: any[];
  districtList: any[];
  stateList: any;
  hospitalList: any=[];
  departmentList: any[];
  isNIG = environment.isNIG;
  getagents:any
  centerHospitals: any[];
  dropdownSettings_centre: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; itemsShowLimit: number; allowSearchFilter: boolean; };
  selectedDistricts: any=[];
  dropdownSettings_district: { singleSelection: boolean; idField: string; textField: string; selectAllText: string; unSelectAllText: string; itemsShowLimit: number; allowSearchFilter: boolean; };
  distList:any=[];
  centList: any[];
  selectedCentres: any=[];
  
  isVisible: any;
  isSelected: boolean = true;
  isCentreWiseSelect: boolean = true;
  isDateWiseSelect: boolean = false;
  currDiv: string;
 
  constructor(private regService: RegistrationService
    , protected toastr: ToastrService
    , private formBuilder: FormBuilder
    , private datePipe: DatePipe
    , private masterService: MasterService,
    private excelDownloadService:ExcelDownloadService
     , private registrationService: RegistrationService
    , private authSvc: AuthenticationService
    ) {
    // this.minDate = new Date();
    // this.minDate.setDate(this.minDate.getDate());

  }

  ngOnInit(): void {
    this.initFormControls(),

  
    Common.loadDataTable(this.ndhmList, '#networkMonthlyReport');
;
    this.registrationService.getallagentspharmacy().subscribe(data => {
      this.getagents=data.data;
    })
     this.LoadData()
   
  }

  show(){
    if(this.selectedDistricts.length == 0){
      this.toastr.error('Please select District')
      return;
      } 
  }
  initFormControls() {
    this.ndhmNotificationForm = this.formBuilder.group({
      start_date: [new Date(), Validators.required],
      end_date: [new Date(), Validators.required],
     // state_id: ['5', Validators.required],
      district_ids: [],
      center_id:[],
     // centerwise:[],
    //  datewise:[]

    });
  }
  get f() { return this.ndhmNotificationForm.controls; }


  LoadData() {
    let sendData = JSON.parse(JSON.stringify(this.ndhmNotificationForm.value));
    [sendData.from_date, sendData.to_date] =
      [
        this.datePipe.transform(sendData.start_date, 'yyyy-MM-dd'),
        this.datePipe.transform(sendData.end_date, 'yyyy-MM-dd')
      ];
      delete sendData.start_date;
      delete sendData.end_date;
    if (this.ndhmNotificationForm.value.agent_id == "") {
      this.toastr.error('Please Select Pharmacist/Healthcare Professional');
      return;
    }
    
    this.regService.getAllConsents().subscribe(res => {
      this.ndhmList = res.data;
      console.log("ndhmList", this.ndhmList);
      
        Common.loadDataTable(this.ndhmList, '#networkMonthlyReport');  
        this.excelData = this.ndhmList;
        Common.loadDataTable(this.ndhmList, '#networkMonthlyReport');
     
    });
   
  }

  onFromDateChange(val: Date) {
    if (val) {
      let start_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.ndhmNotificationForm.get('end_date').setValue(new Date(start_date.setDate(start_date.getDate())));
      this.minDate = start_date;
    }
  }
  //new line added for on To date change 11-04-2022
  onToDateChange(val: Date) {
    if (val) {
      let end_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.ndhmNotificationForm.get('end_date').setValue(new Date(end_date.setDate(end_date.getDate())));
      //this.minDate = end_date;
    }
  }

  reload(){
    this.ngOnInit();
  }

  // exportexcel
  exportexcel(): void {

    var table = $('#networkMonthlyReport').DataTable();

    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {
      / table id is passed over here /
      let element = document.getElementById('networkMonthlyReport');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);


      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };


      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
  }

  downLoadExcel(){
    const fileName = 'NetworkStatus_Report'
      var excelData = this.excelData;
      if(excelData.length>0){
        excelData.map(res =>{
          res.formated_handoverReport = this.pipe.transform(res.handoverReport, 'dd-MM-yyyy');
        })
      
        if(this.isCentreWiseSelect==true){
          var excelHeaders = ['District', 'Block', 'Center Name', 'Pharmacist/Healthcare Professional','Pharmacist/Healthcare Professional Number', 'Up Time', 'Down Time'];
          var internalHeaders = ['district_name', 'mandal_name', 'center_name', 'health_associate_name','health_associate_mobile', 'up_time', 'down_time'];
        }else{
          var excelHeaders = ['District', 'Block', 'Center Name', 'Pharmacist/Healthcare Professional','Pharmacist/Healthcare Professional Number', 'date', 'Up Time', 'Down Time'];
          var internalHeaders = ['district_name', 'mandal_name', 'center_name', 'health_associate_name','health_associate_mobile', 'date', 'up_time', 'down_time'];
        }

         

      //  const excelHeaders = ['User Name', 'Mobile','Email','Attendance Date','Start Time','End Time'];
      //  const internalHeaders = ['user_name', 'mobile','email','formated_attendance_date','start_time','end_time'];
        this.toastr.success('Please wait excel is downloading')
        this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
      }
      else{
        this.toastr.error('No data to download')
      }
    
  }

  


   
    // END Multi-Select District Method

  //   onItemSelect(item: any) {
  //     if (this.selectedCentres.filter(f => f.id == item.id).length == 0)
  //     this.selectedCentres.push(item);
  //   this.selectedCentres = [...this.selectedCentres];
  // }
  // onItemDeSelect(item: any) {
  //   let data = this.selectedCentres;
  //     data.forEach((x, i) => { if (x.id == item.id) data.splice(i, 1); });
  //     this.selectedCentres = [...data];
  // }
  // onSelectAll(item: any) {
  //   this.selectedCentres = item;
  //   this.selectedCentres = [...this.selectedCentres];
  // }
  // onUnSelectAll() {
  //   this.selectedDistricts = [];
  // }
  
  // multi selection end 
  


  
  ShowDiv(divVal: string) {
    this.currDiv = divVal;
  }



}
