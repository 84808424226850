<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Apartment Approval
                    </div>
                    <div class="card-body">
                        <form [formGroup]="apartmentForm" (ngSubmit)="LoadData()">
                            <div class="container">
                                <div class="row ">
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label class="control-label">State</label>
                                            <select formControlName="state_id" id="state_id"
                                                (change)="locationChanged($event.target,'district')"
                                                class="form-control form-control-sm">
                                                <option value="">Select</option>
                                                <option *ngFor="let state of stateList" [value]="state.id">
                                                    {{state.state_name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-group">
                                            <label class="control-label">District</label>
                                            <select formControlName="district_id" id="district_id"
                                                class="form-control form-control-sm">
                                                <option value="">Select</option>
                                                <option *ngFor="let district of districtList" [value]="district.id">
                                                    {{district.district_name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class='col-md-3'>
                                        <div class="form-group">
                                            <div class='date' id='datetimepicker6'>
                                                <label class="control-label">From Date
                                                </label>
                                                <div>
                                                    <input id="from_date" name="from_date" type="text"
                                                        autocomplete="off" onpaste="return false" placeholder="From"
                                                        bsDatepicker bsDatepicker #dp="bsDatepicker" (focus)="dp.show()"
                                                        (focus)="dp1.hide()" formControlName="from_date"
                                                        [maxDate]="max_date" (bsValueChange)="onFromDateChange($event)"
                                                        onkeypress='return event.charCode == 8'
                                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                                                        class="form-control form-control-sm">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class='col-md-3'>
                                        <div class="form-group">
                                            <div class='date' id='datetimepicker7'>
                                                <label class="control-label">To Date</label>
                                                <div>
                                                    <input id="to_date" name="to_date" type="text" autocomplete="off"
                                                        onpaste="return false" placeholder="To" bsDatepicker
                                                        #dp1="bsDatepicker" (focus)="dp1.show()" (focus)="dp.hide()"
                                                        formControlName="to_date"
                                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                                                        [maxDate]="max_date" [minDate]="f.from_date.value"
                                                        (bsValueChange)="onFromDateChange($event)"
                                                        class="form-control form-control-sm"
                                                        onkeypress='return event.charCode == 8'>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class='col-md-3'>
                                        <div class="form-group">
                                            <label class="control-label">Status</label>
                                            <select formControlName="status_id" id="status_id"
                                                class="form-control form-control-sm">
                                                <option value="">Select</option>
                                                <option *ngFor="let status of statusList" [value]="status.id">
                                                    {{status.status_name}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class='col-md-2' style="margin-top: 2rem;">
                                        <button type="submit" class="btn btn-sm btn-primary"
                                            value="Submit">Submit</button>&nbsp;&nbsp;
                                        <!-- <button type="button" class="btn btn-sm btn-danger" (click)="clear()">Clear</button> -->
                                    </div>
                                    <div class='col-md-2' style="margin-top: 2rem;">
                                        <button type="button" class="btn  btn-sm btn-secondary float-right" (click)="exportexcel()"> 
                                            <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="table-responsive">
                            <table datatable id="aprtmentTable" class="table table-striped table-bordered table-sm"
                                style="width:100%">
                                <thead class="text-center">
                                    <tr>
                                        <th>S No</th>
                                        <th>State</th>
                                        <th>District</th>
                                        <th>Block</th>
                                        <th>City</th>
                                        <th>Area</th>
                                        <th>Pincode</th>
                                        <th>Apartment/society Name</th>
                                        <th>Ref ID</th>
                                        <th>Name</th>
                                        <th>Mobile No.</th>
                                        <th>No.of memebers to vaccinated</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of loadgrid; let ind=index">
                                        <td>{{ind+1}}</td>
                                        <td>{{item.state_name}}</td>
                                        <td>{{item.district_name}}</td>
                                        <td>{{item.mandal_name}}</td>
                                        <td>{{item.city}}</td>
                                        <td>{{item.location}}</td>
                                        <td>{{item.pin_code}}</td>
                                        <td>{{item.society_name}}</td>
                                        <td>{{item.registration_no}}</td>
                                        <td>{{item.name}}</td>
                                        <td>{{item.mobile_no}}</td>
                                        <td>{{item.no_of_members}}</td>
                                        <td class="text-center">{{item?.admin_status?.status_name}}</td>
                                        <td class="text-center">
                                            <button type="button" class="btn btn-success btn-sm"
                                             (click)="openModal(item)" *ngIf="setPrivilege(Screen_Name.ApartmentAdminApproval, Permission.Edit)">Click</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>