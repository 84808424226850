<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        {{modalTitle}} List
                        <button class="btn btn-blue btn-icon float-right" (click)="openModal()"
                            *ngIf="setPrivilege(Screen_Name.DoctorModule, Permission.Add)">
                            <i class="fa fa-plus"></i>
                        </button>
                    </div>
                    <div class="col-xl-1 text-right" style="padding-top: 2%;">
                        <!-- <button type="submit" (click)="onAddEdit()" class="btn btn-info mb-2 mr-1 text-center"><i class="fa fa-plus"></i> Add</button> -->
                        <button type="button" class="btn btn-secondary mb-2 mr-1 text-center" *ngIf="(excelData.length>0)" (click)="downLoadExcel()" >
                           <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table datatable id="DoctortemplatelistTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>S No </th>
                                        <th>Module Name</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let dctr of doctorlist; let i=index">
                                        <td style="width: 8%;" class="text-center">{{i+1}}</td>
                                        <td class="">{{dctr.module_name}}</td>
                                        <td>
                                            <div class="badge badge-info pointer"
                                                *ngIf="setPrivilege(Screen_Name.DoctorModule, Permission.Edit)">
                                                <i class="fa fa-edit text-white mx-auto" (click)="openModal(dctr)"></i>
                                            </div>&nbsp;
                                            <div class="badge badge-danger badge-pill pointer"
                                                *ngIf="setPrivilege(Screen_Name.DoctorModule, Permission.Delete)">
                                                <a (click)="doctorlistDeleteById(dctr.id)"><i class="fa fa-trash text-white"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>