import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder,FormArray,ValidationErrors } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import * as jquery from 'jquery';
declare let $: any;
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Common } from 'src/app/common';
import { forkJoin } from 'rxjs';
import { RegistrationService } from 'src/app/shared/services/registration.service'
import { environment } from 'src/environments/environment';
import { MasterService } from 'src/app/shared/services/master.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-referral',
  templateUrl: './referral.component.html',
  styleUrls: ['./referral.component.css']
})

export class ReferralComponent implements OnInit {
 dropdownSettings_language = {};
  cntrCtrlsForm: FormGroup; minDt = new Date(); minToDt = new Date(); maxDt = new Date(); maxDtDOB = new Date();
   submitted = false; cntrItem: any; isAgntShow = false; cntrCode = ''; vcId = 0; isAct = '1';
  lstgrid = []; locationType_list; pincode_list = []; area_list = []; city_list = []; division_list = []; district_list = []; block_list = []; grampanchayat_list = [];
  paymentlist = []; userlist = []; role_list = []; lstData = []; panchayat_list = []; village_list = []; fileLst = []; centr: any;
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  mandalList: Array<any> = [];
  cityList: Array<any> = [];
  villageList: Array<any> = [];
  isNIG = environment.isNIG;
  paymenttypes:any;dropdownSettings={};selectedItems: Array<any> = [];;
  paymentArr: Array<{ created_by: string, is_active: true, payment_per_head: null, payment_type_id: null }> = [];
  firstname:any;lastname:any;username;
  constructor(private formBuilder: FormBuilder
    , public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , private toastr: ToastrService
    , private masterService: MasterService
    , private authServ: AuthenticationService
    , private registrationService: RegistrationService
    ,private router:Router
    , private authSvc:AuthenticationService
   ) {
    this.minToDt.setDate(this.minToDt.getDate());
    this.firstname =JSON.parse(sessionStorage.getItem('firstname'))
    this.lastname=JSON.parse(sessionStorage.getItem('lastname'))
    this.username=this.firstname+' '+this.lastname;
    
  }


  ngOnInit(): void {
    this.initFormControls();this.defaultLocations();
    this.locationChanged('', 'state');
    this.getAllMasters();
    this.masterService.getAllpaymenttypes().subscribe(data => {
      this.paymenttypes=data.data;
    })
    // for(let i=0;i<this.registrationService.centerregeditobj.centermapping.length;i++){
    //   this.selectedItems.push(this.registrationService.centerregeditobj.centermapping[i])
    // }
    //this.selectedItems=this.registrationService.centerregeditobj.centermapping.paymentType;
//     this.cntrCtrlsForm.patchValue({
//       id:this.registrationService.centerregeditobj.id,
//    center_name: this.registrationService.centerregeditobj.center_name,
//    centermapping: [
//      {
//       created_by:this.registrationService.centerregeditobj.created_by,
//        is_active: this.registrationService.centerregeditobj.is_active,
//        payment_per_head: this.registrationService.centerregeditobj.payment_per_head,
//        payment_type_id: this.registrationService.centerregeditobj.payment_type_id
//      }
//    ],
//    created_by: this.registrationService.centerregeditobj.created_by,
//    district_id: this.registrationService.centerregeditobj.district_id,
//    incharge_mobile_no:this.registrationService.centerregeditobj.incharge_mobile_no,
//    incharge_name: this.registrationService.centerregeditobj.incharge_name,
//    is_active: this.registrationService.centerregeditobj.is_active,
//    location_type_id: this.registrationService.centerregeditobj.location_type_id,
//    mandal_id: this.registrationService.centerregeditobj.mandal_id,
//    state_id: this.registrationService.centerregeditobj.state_id,
//    village_id: this.registrationService.centerregeditobj.village_id
//  })
 //this.selectedItems=this.registrationService.centerregeditobj.payment_type_id;
  }
  defaultLocations() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'payment_type',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 4,
      allowSearchFilter: true
    };
  }
  initFormControls() {
    this.cntrCtrlsForm = this.formBuilder.group({
      id: [0],
      center_name: ['', Validators.required],
      location_type_id: [0, Validators.required],
      //pincodeid: [-1],
      state_id: [0, Validators.required],
     // divisionid: [0, Validators.required],
      district_id: [0, Validators.required],
      mandal_id: [0],
      //areaid: [''],
      //cityid: [''],
      //panchyatisamitiid: [''],
      //gramapanchayatiid: [''],
      village_id: [''],
      // sarpanchname: [''],
      // sarpanchmobilenumber: [''],
      incharge_name: [''],
      incharge_mobile_no: [''],
     // paymentperhead: [''],
     // paymenttypeid: [0, Validators.required],
      // fromDate: [''],
      // toDate: [''],
      is_active: [true],
      created_by:[this.username],
      centermapping: this.formBuilder.array([]),
    });
  }
  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        this.cntrCtrlsForm.get('districtid').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      },
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
      this.districtList.sort((a,b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error.message));
  }
  // getAllMasters() {
  //   const locationType = this.masterService.getAll('getalllocationtypes');
  //   const hospital = this.registrationService.getAll('hospital/getallhospitalrecords');
  //   const pharmacy = this.registrationService.getAll('pharmacy/getallrecords');
  //   const bankName = this.masterService.getAll('getallmasterbankdetails');
  //   forkJoin([locationType, hospital, pharmacy, bankName]).subscribe({
  //     next: res => {
  //       res.forEach((list, ind) => {
  //         if (list.status === 'OK') {
  //           if (list.data == null || list.data.length == 0)
  //             console.log('No records available');
  //           else {
  //             const assignList = {
  //               '0': () => this.locationTypeList = list.data,
  //               // '1': () => this.hospitalList = list.data,
  //               // '2': () => this.pharmacyList = list.data,
  //               // '3': () => this.bankNamesList = list.data,
  //             }
  //             assignList[ind]();
  //           }
  //         }
  //       });
  //     },
  //     error: err => console.error('something wrong occurred: ', err),
  //    // complete: () => { this.editPharmacy(); }
  //   });
  // }
  getAllMasters() {
  this.masterService.getAll('getalllocationtypes').subscribe(res=>{
    if(res.status === 'OK') {
      if (res.data == null || res.data.length == 0)
        this.toastr.info(res.message);
      else
        this.locationTypeList = res.data;
    }
  });
 // locationType.subscribe({})
  }
  get f() { return this.cntrCtrlsForm.controls; }
  onItemSelect(item: any, type: string) {
   // console.log(item, type);
    this.addpayment();
  }
  onSelectAll(items: any, type: string) {
   // console.log(items, type);
    this.selectedItems = items;
    this.addpayment();
  }
  newamount(item: any): FormGroup {
    return this.formBuilder.group({
      created_by: [this.username],
      is_active: [true],
      payment_per_head: [''],
      payment_type_id: [item.id],
    });
  }
  addpayment() {
    // this.isDropdownDisabled = true;
    this.vaccinations().clear();
    this.selectedItems.forEach(e => this.vaccinations().push(this.newamount(e)));
  }
  vaccinations(): FormArray {
    return this.cntrCtrlsForm.get("centermapping") as FormArray;
  }
  

  removeVaccine(i: number) {
    const arr = [...this.selectedItems];
    if (arr.splice(i, 1).length > 0) {
      this.selectedItems = arr;
      // this.isDropdownDisabled = arr.length > 0;
    }
    this.vaccinations().removeAt(i);
  }
  getFormValidationErrors() {
    Object.keys(this.cntrCtrlsForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.cntrCtrlsForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
  

}
