<div class="row">
  <div class="container-fluid pl-0 pr-0">
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-header-actions mx-auto">
          <!-- <div class="card-header">
            EHR
            <!-- <button type="button" class="btn btn-primary" (click)="btnClick();"><i class="bi bi-arrow-left"></i> Back</button>          -->
          <!-- </div> --> 
          <div class="row card-header" 
          >
            <div class="col-xl-7">
              EHR
            </div>
            <!-- <div class="col-xl-5 text-right">
               <a (click)="onBack()"><i
                class="fas fa-arrow-alt-circle-left"></i>Back</a>  
            </div>  -->
          </div>
          <!-- <div class="row card-header" *ngIf="!ehrBack">
            <div class="col-xl-7">
              EHR
            </div>
            <div class="col-xl-5 text-right">
               <a (click)="onBack(2)"><i
                class="fas fa-arrow-alt-circle-left"></i>Back</a>  
            </div> 
          </div> -->
          <div class="card-body">
              <div class="form-group mb-1 mt-2 col-md-4" >
                  <div class="form-group" style="margin-right: -550px;float: right;" disabled autocomplete="off" formControlName="photo_path"
                  name="photo_path">
                  <img class="img-fluid" src="../../../../assets/images/ph60.png" style="width: 100px;height: 100px; border-radius: 50%;"  *ngIf="profile_photo==' ' || profile_photo==null || profile_photo==undefined"/>
                      <img src="{{profile_photo}}" class="img-fluid" style="width: 100px;height: 100px; border-radius: 50%;"  *ngIf="profile_photo!=' ' && profile_photo!=null">
                  </div>
                </div>
              <form [formGroup]="ehrFormRpt">

                  <div class="row offset-2 col-md-8  widgetsbg mt-4 mb-2  mr-1" style="margin-left: 0px;">
                    <div class="form-group mb-1 mt-2 col-md-4">
                      <div class="form-group">
                        <label class="text-dark  "> ABHA ID </label>
                        <input class="form-control ml-1 text-primary  form-control-sm" disabled autocomplete="off" formControlName="abhaid"
                          name="abhaid" placeholder="ABHA ID"> 
                      </div>
                    </div>
                    <div class="form-group mb-1 mt-2 col-md-4">
                      <div class="form-group">
                        <label class="text-dark  "> State </label>
                        <input class="form-control ml-1 text-primary  form-control-sm" disabled autocomplete="off" formControlName="state"
                          placeholder="State">
                      </div>
                    </div>
                    <div class="form-group mb-1 mt-2 col-md-4">
                      <div class="form-group">
                        <label class="text-dark  "> District </label>
                        <input class="form-control ml-1 text-primary  form-control-sm" disabled autocomplete="off" formControlName="district"
                          placeholder="District">
                      </div>
                    </div>
                    <!-- <div class="form-group mb-1 mt-2 col-md-2">
                      <div class="form-group">
                        <label class="text-dark  "> Block </label>
                        <input class="form-control ml-1  form-control-sm" disabled autocomplete="off" formControlName="block"
                          placeholder="Block">
                      </div>
                    </div> -->
                    <!-- <div class="form-group mb-1 mt-2 col-md-2">
                      <div class="form-group">
                        <label class="text-dark  "> Village </label>
                        <input class="form-control ml-1  form-control-sm" disabled autocomplete="off" formControlName="village"
                          placeholder="village">
                      </div>
                    </div> -->
          
                    <div class="form-group mb-1 mt-2 col-md-4">
                      <div class="form-group">
                        <label class="text-dark"> Center Name </label>
                        <input class="form-control text-primary ml-1  form-control-sm" disabled autocomplete="off" formControlName="centername"
                          placeholder="Center Name">
                      </div>
                    </div>
          
                    <div class="form-group mb-1 mt-2 col-md-4">
                      <div class="form-group">
                        <label class="text-dark"> Name </label>
                        <input class="form-control text-primary ml-1  form-control-sm" disabled autocomplete="off" formControlName="name"
                          placeholder="name">
                      </div>
                    </div>
          
                    <div class="form-group mb-1 mt-2 col-md-4">
                      <div class="form-group">
                        <label class="text-dark"> Gender </label>
                        <input class="form-control ml-1 text-primary  form-control-sm" disabled autocomplete="off" formControlName="gender"
                          placeholder="gender">
                      </div>
                    </div>
                    <div class="form-group mb-1 mt-2 col-md-4">
                      <div class="form-group">
                        <label class="text-dark"> Age </label>
                        <input class="form-control ml-1 text-primary  form-control-sm" disabled autocomplete="off" formControlName="age"
                          placeholder="age">
                      </div>
                    </div>
                 
                    <div class="form-group mb-1 mt-2 col-md-4">
                      <div class="form-group">
                        <label class="text-dark  "> Mobile </label>
                        <input class="form-control ml-1 text-primary  form-control-sm" disabled autocomplete="off" formControlName="mobile"
                          placeholder="mobile">
                      </div>
                    </div>
                    <!-- <div class="form-group mb-1 mt-2 col-md-4">
                      <div class="form-group">
                        <label class="text-dark" style="width: 8rem;"> Marital Status </label>
                        <input class="form-control ml-1 text-primary  form-control-sm" disabled autocomplete="off"
                          formControlName="maritalStatus" placeholder="marital Status">
                      </div>
                    </div> -->
                  </div>

                </form>
            <div class="table-responsive" *ngIf="vitalList && vitalList.length>0">
              <div>
                  <div class="text-black">
                      Vitals
                  </div>
                </div>
              <table datatable id="tablevitals" class="table table-striped table-bordered table-sm" style="width:100%">
                <thead>
                  <tr>
                    <th class="singleLine">S No </th>
                    <th class="singleLine">Date </th>
                    <th class="singleLine">Height(CM)</th>
                    <th class="singleLine">Weight(kg)</th>
                    <th class="singleLine">BMI</th>
                    <th class="singleLine">Blood Pressure</th>
                    <th class="singleLine">Heart Rate (bpm)</th>
                    <th class="singleLine">Temp (°F)</th>
                    <th class="singleLine">SpO2 (%)</th>
                   
                  </tr>
                </thead>
                <tbody>
                  
                  <!-- <ng-container *ngFor="let item of benHistory;let i=index"> -->
                      <!-- {{item | json}} -->
                <tr *ngFor="let itemn of vitalList;let i=index">
                  <!-- <ng-container *ngIf="itemn.vital_history?.systolic !='' || itemn.vital_history?.temperature !=''"> -->
                  <td>{{(i+1)}}</td>
                  <td>{{itemn.vital_capture_date | date:'dd-MM-yyyy'}}</td>
                  <td>{{itemn.height}}</td>
                  <td>{{itemn.weight}}</td>
                  <td>{{itemn.bmi}}</td>
                  <td>{{itemn.bp}}</td>
                  <!-- <td>{{itemn.systolic}}<span *ngIf="itemn.systolic !='' && itemn.systolic !=null">/</span>{{itemn.diastolic}}</td> -->
                  <td>{{itemn.heart_rate}}</td>
                  <td>{{itemn.temperature}}</td>
                  <td>{{itemn.spo2}}</td>
                <!-- </ng-container> -->
                </tr>
                  <!-- </ng-container> -->
                </tbody>
              </table>
            </div>
            <div>
          <!-- <div class="text-black">
           Charts
          </div>
        </div>
        <div class="row widgetsbg  mt-4 mb-2  mr-1">
         
        </div> -->
        <div class="table-responsive">
        <div>
          <div class="text-black">
            Electronic Health Record History
          </div>
        </div>
              <table datatable id="ehrhistory" 
              class="table table-striped table-bordered table-sm" style="width:100%">
              <thead>
                  <tr>
                      <th  class="singleLine">S No </th>
                      <th class="singleLine">Date</th>
                      <!-- below line commented MOM changes 22-02-2023 -->
                      <!-- <th class="singleLine">Past History</th> -->
                      <th class="singleLine">Allergies</th>
                      <th class="singleLine">Diagnosis</th>
                      <th class="singleLine">Investigations</th>
                      <th class="singleLine">Prescription</th>
              </thead>
              <tbody>
                <tr *ngFor="let itemn of ehrhistory;let i=index">
                  <!-- <ng-container *ngIf="itemn.vital_history?.systolic !='' || itemn.vital_history?.temperature !=''"> -->
                  <td>{{(i+1)}}</td>
                  <td>{{itemn.visit_date | date:'dd-MM-yyyy'}}</td>
                    <!-- below line commented MOM changes 22-02-2023 -->
                  <!-- <td>{{itemn.past_history}}</td> -->
                  <td>{{itemn.allergy}}</td>
                  <td>{{itemn.diagnosis}}</td>
                  <td>{{itemn.investigation}}</td> 
                  <!-- <td> <p (click)="openWindow(itemn.poc_file_path)" style="color: green; padding-bottom: 5px; cursor: pointer;" *ngIf="itemn.poc_file_path && itemn.poc_file_path!==null && itemn.poc_file_path!==''">{{itemn.visit_date | date:'dd-MM-yyyy'}} - {{itemn.visit_id}} <i class="fa fa-file" aria-hidden="true"></i> </p></td> -->
                  <td *ngIf="itemn.prescription && itemn.prescription!==null"> <p (click)="openWindow(itemn.prescription)" style="color: green; padding-bottom: 5px; cursor: pointer;" *ngIf="itemn.prescription && itemn.prescription!==null && itemn.prescription!==''">{{itemn.visit_id}}<i class="fa fa-file" aria-hidden="true"></i> </p></td>
                  <td *ngIf="itemn.prescription==null"> <p  style="color: blue; padding-bottom: 5px; cursor: pointer;">Not Authorized </p></td>
               
                <!-- </ng-container> -->
                </tr>
              </tbody>
           </table>
            
        
      
      </div>
      <!-- <div>
          <div class="text-black">
           Diagnosis
          </div>
        </div> -->
        <!-- <div>
            <div class="container ">
              <table datatable
              class="table table-striped table-bordered table-sm" >
              <thead>
                  <tr>
                      <th  class="singleLine">S No </th>
                      <th class="singleLine">Date</th>
                      <th class="singleLine">Diagnosis</th>
                    
              </thead>
              <tbody>
                <tr *ngFor="let itemn of ehrhistory;let i=index">
                <td>{{(i+1)}}</td>
                  <td>{{itemn.visit_date | date:'dd-MM-yyyy'}}</td>
                  <td>{{itemn.diagnosis}}</td>
                </tr>
              </tbody>
           </table>
            </div>       
      </div> -->
      <!-- <div>
          <div class="text-black">
            Services
          </div>
        </div> -->
        <!-- <div>
            <div class="container ">
              <table datatable
              class="table table-striped table-bordered table-sm" >
              <thead>
                  <tr>
                      <th  class="singleLine">S No </th>
                      <th class="singleLine">Date</th>
                      <th class="singleLine">Services</th>
                      
              </thead>
              <tbody>
                <tr *ngFor="let itemn of ehrhistory;let i=index">
                  <td>{{(i+1)}}</td>
                  <td>{{itemn.visit_date | date:'dd-MM-yyyy'}}</td>
                  <td>{{itemn.services}}</td>
               </tr>
              </tbody>
           </table>
            </div>
        
      </div> -->
      <!-- <div>
          <div class="text-black">
            Investigations
          </div>
        </div> -->
        <!-- <div>
            <div class="container ">
              <table datatable
              class="table table-striped table-bordered table-sm" >
              <thead>
                  <tr>
                      <th  class="singleLine">S No </th>
                      <th class="singleLine">Date</th>
                      <th class="singleLine">Test</th>
                   
              </thead>
              <tbody>
                <tr *ngFor="let itemn of ehrhistory;let i=index">
                  <td>{{(i+1)}}</td>
                  <td>{{itemn.visit_date | date:'dd-MM-yyyy'}}</td>
                  <td>{{itemn.investigation}}</td>
                </tr>
              </tbody>
           </table>
            </div>       
      </div> -->
      <!-- <div>
        <div class="text-black">
          Prescription
        </div>
      </div> -->
      <!-- <div>
        <div class="container ">
          <div *ngFor="let itemn of ehrhistory;let i=index">
           <p (click)="openWindow(itemn.poc_file_path)" style="color: green; padding-bottom: 5px;">{{itemn.visit_date}} - {{itemn.visit_id}} <i class="fa fa-file" aria-hidden="true"></i> </p>
          </div>
        </div>       
  </div> -->
  <div>
    <!-- <div class="col-xl-3">
          
          <div>
              <input type="checkbox" [(ngModel)]="isPrintOnlyUpdated" (change)="isChngPrintOnlyUpdated()">&nbsp; <label class="control-label">Print Only Updated</label>
          </div>
       </div> -->
       <!-- <div class="row" style="text-align: center;"> -->
    
        <!-- <button type="button" mdbBtn color="primary" mdbWavesEffect (click)="printEhr()">Print</button>    -->
        <!-- <button type="button" mdbBtn color="primary" mdbWavesEffect (click)="closeEhr()">Close</button> -->
       <!-- </div> -->
  </div>
  <div style="border: 10px 50%;">
  <button type="button"  class="btn btn-sm btn-primary" style="width: 100px;height: 50px;" (click)="printEhr()">Print</button>
  </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


