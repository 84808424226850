         

                <div class="row">
                    <div class="container-fluid pl-0 pr-0">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card card-header-actions mx-auto">
                                    <div class="card-header">
                                           District Registration
                                        <button class="btn btn-blue btn-icon float-right"
                                        (click)="modalTitle='District Registration';openDistrictModal()">
                                            <i class="fa fa-plus"></i>
                
                                        </button>
                                    </div>
                                    
                                        <div class="card-body">
                                        <form [formGroup]="districtForm" (ngSubmit)="loaddistricts()">
                                                   <div class="row">                                              
                                                    <!-- <div class="form-group row"> -->
                                                        <label class="control-label">State <span class="text-danger">*</span> </label>
                                                      <div class="col-sm-3">
                                                        <select [(ngModel)]="state_id" formControlName="state_id" id="state_id" #State 
                                                        class="form-control form-control-sm">
                                                         <option [value]="0">Select</option>
                                                         <option *ngFor="let state of stateList" [value]="state.id">
                                                           {{state.state_name}}</option>
                                                       </select>
                                                  
                                                      <!-- </div> -->
                                                    
                                                    

                                                    </div>
                                                    <div class="col-sm-2">
                                                        <!-- <input type="submit" class="btn btn-sm btn-primary" (click)="submitForm()" value="Submit">&nbsp;&nbsp; -->
    
                                                        <button type="button" class="btn btn-primary btn-sm"(click)="loaddistricts()" >Submit</button>
                                                         </div>
                                                </div>
                                                </form>
                                               
                                    </div>
  
                                    <div class="card-body">
                                        <div class="table-responsive">
                                            <table datatable id="districtTable" class="table table-striped table-bordered table-sm">
                                                <thead>
                                                    <tr>
                                                        <th>S No</th>
                                                        <th>District Name  </th> 
                                                        <th style="width:10%;">Actions</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                          <tr *ngFor="let item of datalist;let ind=index;">
                                                            <td>{{(ind+1)}}</td>
                                                               <td>{{item.district_name}}</td>
                                                       
                                                        <td>
                                                           
                    <div class="badge badge-success badge-pill"> <a a (click)="modalTitle='Edit State Details';openDistrictModal()"> <i  class="fa fa-edit text-white"></i></a></div>&nbsp;&nbsp;
                    <div class="badge badge-danger badge-pill"><a (click)="deleteItem(item.id)"><i class="fa fa-trash text-white"></i></a></div> </td> 
                                                     
                                                    </tr>
                                                </tbody>
                                            </table>
                                           
                                        </div>
                
                                    </div>
                                </div>
                            </div>
                        </div>
                
                    </div>
                </div>  