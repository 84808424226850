<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Vendor Registration
                        <button (click)="modalTitle='Vendor Registration'; openVendorModal()"
                            class="btn btn-blue btn-icon float-right"><i class="fa fa-plus"></i> <!-- openModal() -->
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table datatable id="vendorTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>S No </th>
                                        <th>Vendor Name</th>
                                        <th>Vendor Email</th>
                                        <th>Mobile</th>
                                        <th>State</th>
                                        <th>District</th>
                                        <th>City</th>
                                        <th>Pincode</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let vendr of vendorList; let i=index">
                                        <td>{{i+1}}</td>
                                        <td>{{vendr.vendor_name}}</td>
                                        <td>{{vendr.vendor_email}}</td>
                                        <td>{{vendr.vendor_mobile_no}}</td>
                                        <td>{{vendr.state_name}}</td>
                                        <td>{{vendr.district_name}}</td>
                                        <td>{{vendr.city}}</td>
                                        <td>{{vendr.pin_code}}</td>
                                        <td>
                                            <div class="badge badge-success badge-pill pointer" title="Edit">
                                                <a (click)="openVendorModal(vendr)"><i
                                                        class="fa fa-edit text-white"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>