import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, Validators, FormGroup,ValidationErrors } from '@angular/forms';
import { MasterService } from 'src/app/shared/services/master.service';
import { ApiService } from 'src/app/shared/services/api.service';


@Component({
  selector: 'app-state',
  templateUrl: './state.component.html',
  styleUrls: ['./state.component.css']
})
export class StateComponent implements OnInit {

 
  @Input() title: string;
  @Input() stateData: any;
  stateForm: FormGroup;
  submitted = false;
  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService,
    private formBuilder: FormBuilder,
    private masterService: MasterService,
    private apiServ: ApiService,
  ) { }

  ngOnInit(): void {
    this.initFormControls();
    if (this.stateData) {
      
      this.stateForm.patchValue(this.stateData);
      let [data,] = [this.stateData, ''];
      this.stateForm.patchValue(data);
      this.stateForm.patchValue({
        state_name: data.state_name,
      
      });
    }
  }
 
 

  initFormControls() {
    this.stateForm = this.formBuilder.group({
      id: ['0'],
      // user_id: this.authenticationService.currentUserValue.id,  
      state_name: ['', Validators.required],
    });
  }
  get f() { return this.stateForm.controls; }

  onSubmit(event: any) {
    event.preventDefault();
    this.submitted = true;
    this.getFormValidationErrors();
    if (this.stateForm.invalid)
      return;
    else
     {
            const sendData = JSON.parse(JSON.stringify(this.stateForm.getRawValue()));   
        this.masterService.save('saveandupdatestate', JSON.stringify(sendData)).subscribe(
          res => {
        let apiResponse = res;
        if (apiResponse.status === "OK") {
          this.toastr.success(res.message, 'State');
          this.activeModal.close();
          this.initFormControls();
         
        }
      },
       error => this.toastr.error(error));
    }
  }
  setFocus(elementName: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementName)));
    el.value = '';
    el.focus();
  }

  getFormValidationErrors() {
    Object.keys(this.stateForm.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.stateForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
  restFormValues() {
    this.initFormControls();
  }
  submitForm() {
    document.getElementById('btnsubmit').click();
  }

}
