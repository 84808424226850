import { Component,OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Common } from 'src/app/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import * as XLSX from 'xlsx';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
declare let $: any;

@Component({
  selector: 'app-assesment-report',
  templateUrl: './assesment-report.component.html',
  styleUrls: ['./assesment-report.component.css']
})
export class AssesmentReportComponent implements OnInit {
  assessementForm: FormGroup;
  from_date: any = new Date();
  to_date: any = new Date();
  max_date = new Date();
  assessementlist = []; 
  minDate: Date; minToDate: Date; docId = '';
  submitted = false; userNames = [];
  userList: Array<any> = [];
  pipe = new DatePipe('en-US');
  showExport: boolean = true;
  fileName = 'Assessement_Report';
  excelData: any =[];

  constructor(private regService: RegistrationService
    , protected toastr: ToastrService
    , private formBuilder: FormBuilder
    , private datePipe: DatePipe,
    private excelDownloadService:ExcelDownloadService
    , private masterService: MasterService
    , private authSvc: AuthenticationService) {
    // this.minDate = new Date();
    // this.minDate.setDate(this.minDate.getDate());

    }

  ngOnInit(): void {
    this.initFormControls(), this.getallUsers();
    this.assessementlist = [];
    Common.loadDataTable(this.assessementlist, '#assessementReport');
  }

  initFormControls() {
    this.assessementForm = this.formBuilder.group({
      from_date: [new Date(), Validators.required],
      to_date: [new Date(), Validators.required],
      user_type: ['', Validators.required],
      user_id: [null]
    });
  }

  get f() { return this.assessementForm.controls; }

  getallUsers() {
    this.regService.getallUserTypes().subscribe(res => {
      if (res.status == 'OK') {
        this.userList = res.data
      } else {
        this.userList = [];
        this.toastr.error(res.err)
      }
    })
  }
  onUserChange(e: any) {
    this.userNames = [];
    this.assessementForm.patchValue({'user_id':null});
    this.getAllUserNames();
  }
  getAllUserNames() {
    let UserType = this.assessementForm.value.user_type;
    this.regService.getAllUsers(UserType).subscribe(res => {
      if (res.status == 'OK') {
        if(res.data.length > 0){
          this.userNames = res.data;
        } 
        else {
          this.userNames = [];
          this.toastr.info('No Records Found');
        }
      } 
    })
  }
  LoadData() {
    let sendData = JSON.parse(JSON.stringify(this.assessementForm.value));
    [sendData.from_date, sendData.to_date] =
      [this.datePipe.transform(sendData.from_date, 'yyyy-MM-dd'),
      this.datePipe.transform(sendData.to_date, 'yyyy-MM-dd')];
    if (this.assessementForm.value.user_type == "") {
      this.toastr.error('Please Select UserType');
      return;
    }
    if (this.assessementForm.value.from_date == null || this.assessementForm.value.to_date==null ) {
      this.toastr.error('Please Select Date');
      return;
    }
    this.regService.getassessementReports(sendData).subscribe(res => {
      
      if (res.data == null || res.data.length == 0) {
        this.assessementlist = [];
        this.toastr.info('No records available', 'Assessement Reports List');
        Common.loadDataTable(this.assessementlist, '#assessementReport');
        this.showExport = false;
      }
      else {
        this.assessementlist = res.data;
        this.excelData = this.assessementlist;
        this.assessementlist.map(obj=>{
         obj.verified=obj.checked?'Verified':'Not Verified';
         obj.working_status=obj.working_status?'Working':'Not Working';
         });
       
        Common.loadDataTable(this.assessementlist, '#assessementReport');
        this.showExport = true;
      }
    });
  }

  onFromDateChange(val: Date) {
    if (val) {
      let from_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.assessementForm.get('to_date').setValue(new Date(from_date.setDate(from_date.getDate())));
      this.minDate = from_date;
    }
  }
  //new line added for on To date change 11-04-2022
  onToDateChange(val: Date) {
    if (val) {
      let to_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.assessementForm.get('to_date').setValue(new Date(to_date.setDate(to_date.getDate())));
      //this.minDate = to_date;
    }
  }

  clear() {
    this.initFormControls();
    this.assessementlist = [];
    Common.loadDataTable(this.assessementlist, '#assessementReport');
  }
  exportexcel(): void {

    var table = $('#assessementReport').DataTable();

    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {
      / table id is passed over here /
      let element = document.getElementById('assessementReport');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);


      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };


      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
  }
  downLoadExcel(){
    const fileName = this.fileName;
      var excelData = this.excelData;
      if(excelData.length>0){
        excelData.map(res =>{
          res.formated_created_on = this.pipe.transform(res.created_on, 'dd-MM-yyyy');
        })
        const excelHeaders = ['Pharmacist/Healthcare Professional Name', 'Pharmacist/Healthcare Professional Type','Created On','Equipment Name','Remarks','verification','Status'];
        const internalHeaders = ['user_name', 'center_type','formated_created_on','assessment_name','remarks','verified','working_status'];
        this.toastr.success('Please wait excel is downloading')
        this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
      }
      else{
        this.toastr.error('No data to download')
      }
    
  }
}
