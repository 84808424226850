import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

import { MasterService } from 'src/app/shared/services/master.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';

@Component({
  selector: 'app-dr-tmplt-create',
  templateUrl: './dr-tmplt-create.component.html',
  styleUrls: ['./dr-tmplt-create.component.css']
})

export class DrTmpltCreateComponent implements OnInit {

  @Input() title: any; @Input() editData: any; slctDT = ''; errMsg = ''; istmpltError = false; submitted = false;
  ccDDStngs = {}; pdDDStngs = {}; invDDStngs = {}; dtDDStngs = {}; dnDDStngs = {}; payLoad: DrMod = new DrMod();
  lstCC: any[] = []; lstPD: any[] = []; lstINV: any[] = []; lstDT: any[] = []; lstDN: any[] = [];
  ccEdt: any[] = []; pdEdt: any[] = []; invEdt: any[] = []; dtEdt: any[] = []; dnEdt: any[] = [];drugtypeId:any;
  constructor(public activeModal: NgbActiveModal
    , private regSvc: RegistrationService
    , private toastr: ToastrService
    , private mstrSvc: MasterService) { }

  ngOnInit(): void {
    this.setMultiDDLs(), this.LoadMasters();
  }

  setMultiDDLs() {
    this.ccDDStngs = {
      singleSelection: false, idField: 'id', textField: 'chief_complaint', selectAllText: 'Select All',
      unSelectAllText: 'Deselect All', itemsShowLimit: 3, allowSearchFilter: true
    }, this.pdDDStngs = {
      singleSelection: false,
      idField: 'id', textField: 'diagnosis_name', selectAllText: 'Select All', unSelectAllText: 'Deselect All', itemsShowLimit: 3,
      allowSearchFilter: true
    }, this.invDDStngs = {
      singleSelection: false, idField: 'id', textField: 'investigation', selectAllText:
        'Select All', unSelectAllText: 'Deselect All', itemsShowLimit: 3, allowSearchFilter: true
    }, this.dtDDStngs = {
      singleSelection: false,
      idField: 'id', textField: 'drug_type', selectAllText: 'Select All', unSelectAllText: 'Deselect All', itemsShowLimit: 3,
      allowSearchFilter: true
    }, this.dnDDStngs = {
      singleSelection: false, idField: 'id', textField: 'drug_name', selectAllText: 'Select All',
      unSelectAllText: 'Deselect All', itemsShowLimit: 3, allowSearchFilter: true
    };
  }

  LoadMasters() {
    const chiefComplaint = this.mstrSvc.getAll('getallchiefcomplaints'), diagnosis = this.mstrSvc.getAll('getalldiagnosis'),
      drugtypes = this.mstrSvc.getAll('getalldrugtypes'), investigations = this.mstrSvc.getAll('getallinvestigations');

    forkJoin([chiefComplaint, diagnosis, investigations, drugtypes]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              console.log('No records available', 'Plan of Care');
            else {
              const assignList = {
                '0': () => this.lstCC = list.data,
                '1': () => this.lstPD = list.data,
                '2': () => this.lstINV = list.data,
                '3': () => this.lstDT = list.data,
              }
              assignList[ind]();
            }
          }
        });
      },
      error: err => console.error('something went wrong: ', err),
      complete: () => { if (this.editData) this.BindEditData(); },
    });
  }

  BindEditData() {
    ['created_on', 'is_active', 'updated_on'].forEach(e => { delete this.editData[e] }), [this.payLoad, this.ccEdt, this.pdEdt, this.invEdt,
    this.dtEdt] = [this.editData, [...this.editData.module_data.ChiefComplaints], [...this.editData.module_data.ProvisionalDiagnosis],
    [...this.editData.module_data.Investigations], [...this.editData.module_data.DrugTypes]];
  }

  onMultiSlct(evnt: any, type: string, ctrl: string) {
    if (ctrl != 'dn') {
      this.lstDN = [];
      if (type == 's')
        this.payLoad.module_data[ctrl].push(ctrl == 'DrugTypes' ? Object.assign(evnt, { drug_names: [] }) : evnt);
      else
        this.payLoad.module_data[ctrl] = this.payLoad.module_data[ctrl].filter(f => f.id != evnt.id);
    }
    else {
      if (this.slctDT) {
        if (type == 's')
          this.payLoad.module_data.DrugTypes.filter(f => f.id == this.slctDT)[0].drug_names.push(evnt);
        else
          this.payLoad.module_data.DrugTypes.filter(f => f.id == this.slctDT)[0].drug_names = this.payLoad.module_data.DrugTypes.filter(f => f.id == this.slctDT)[0].drug_names.filter(g => g.id != evnt.id);
      }
    }
  }

  onChngDT(dtId: any) {
    this.drugtypeId=dtId;
    [this.lstDN, this.dnEdt] = [[], []];
    if (dtId) {
      this.mstrSvc.searchbyIdMasters(`drugtypemapping/getdrugbydrugtypeid?drugTypeId=${dtId}`).subscribe(res => {
        if (res) {
          if (res.status === 'OK') {
            let result = res.data;
            result.forEach(e => { this.lstDN.push({ id: e.drug_id.id, drug_name: e.drug_id.drug_name }); }), this.lstDN = [...this.lstDN];
          }
          if (this.payLoad.module_data.DrugTypes.filter(f => f.id == dtId).length > 0)
            this.dnEdt = [...this.payLoad.module_data.DrugTypes.filter(f => f.id == dtId)[0].drug_names];
        }
      });
    }
  }

  onKeyUp(e: string) {
    this.istmpltError = e.length < 5 || e.length > 30 || +e == 0 || e.length == 1 && +e == 0;
    if (e.length == 1 && +e == 0)
      this.errMsg = 'First character should not be 0 '
    if (e.length == 2 && e.length < 5)
      this.errMsg = 'Minimum of Module Name should be 5 characters';
    if (e.length >= 5 && +e == 0)
      this.errMsg = 'Entering all zeros is not allowed';
    if (e.length > 30)
      this.errMsg = 'Maximum of Module Name should be 30 characters';
  }

  onSave() {
    this.submitted = true;
    this.istmpltError = this.payLoad.module_name.length < 5 || this.payLoad.module_name.length > 30;
    if (this.payLoad.module_name.length < 5)
      this.errMsg = 'Minimum of Module Name should be 5 characters';
    if (this.payLoad.module_name.length > 30)
      this.errMsg = 'Maximum of Module Name should be 30 characters';
    if (this.payLoad.module_name.length == 0 || this.payLoad.module_data.ChiefComplaints.length == 0 || this.payLoad.module_data.ProvisionalDiagnosis.length == 0 ||
      this.payLoad.module_data.Investigations.length == 0 || this.payLoad.module_data.DrugTypes.length == 0)
      this.toastr.info('Please choose All the mandatory field');
    else {
      let mtDNs = 0;
      this.payLoad.module_data.DrugTypes.forEach(e => { if (e.drug_names.length == 0) mtDNs++; });
      if (mtDNs > 0)
        this.toastr.info('Please choose at least one Drug Name for each selected Drug Type');
      else {
        if (this.payLoad) {
          this.regSvc.saveDctrMdule(this.payLoad).subscribe(s => {
            if (s.status === 'OK')
              this.toastr.success(s.message), this.activeModal.close('changes saved');
            else
              this.toastr.error(s.message);
          });
        }
      }
    }
  }

  onSelectAll(evnt: any, type: string, ctrl: string) {
    if (ctrl != 'dn') {
      this.lstDN = [];
      if (type == 'm'){
      this.payLoad.module_data.DrugTypes=[];
      this.slctDT='';
     //this.payLoad.module_data.DrugTypes.forEach(f =>{ f.id =null;f.drug_type=''});
        //this.payLoad.module_data[ctrl].push(ctrl == 'DrugTypes' ? Object.assign(evnt, { drug_names: [] }) : evnt);
        evnt.forEach(e => { this.payLoad.module_data[ctrl].push(ctrl == 'DrugTypes' ? Object.assign(e, { drug_names: [] }) : e); });
        //this.payLoad.module_data[ctrl]
      }
      else
        this.payLoad.module_data[ctrl] = this.payLoad.module_data[ctrl].filter(f => f.id != evnt.id);
    }
    else {
      if (this.slctDT) {
        if (type == 'm'){
          this.payLoad.module_data.DrugTypes.forEach(f =>{ if(f.id == this.drugtypeId)f.drug_names=[];});
          // this.payLoad.module_data.DrugTypes.filter(f => f.id == this.slctDT)[0].drug_names.push(evnt);
          evnt.forEach(e => { this.payLoad.module_data.DrugTypes.filter(f => f.id == this.slctDT)[0].drug_names.push(e); });
        }
        else
          this.payLoad.module_data.DrugTypes.filter(f => f.id == this.slctDT)[0].drug_names = this.payLoad.module_data.DrugTypes.filter(f => f.id == this.slctDT)[0].drug_names.filter(g => g.id != evnt.id);
      }
    }
  }
  onDeSelectAll(evnt: any, type: string, ctrl: string){
    if (type == 'm' && ctrl =='dn'){
      this.payLoad.module_data.DrugTypes.forEach(f =>{ if(f.id == this.drugtypeId)f.drug_names=[];});
    }
    if (type == 'm' && ctrl =='ChiefComplaints'){
      this.payLoad.module_data.ChiefComplaints=[];
    }
    if (type == 'm' && ctrl =='ProvisionalDiagnosis'){
      this.payLoad.module_data.ProvisionalDiagnosis=[];
    }
    if (type == 'm' && ctrl =='Investigations'){
      this.payLoad.module_data.Investigations=[];
    }
    if (type == 'm' && ctrl =='DrugTypes'){
      this.payLoad.module_data.DrugTypes=[];
    }
  }
  onSelectAllComplaints(evnt: any, type: string, ctrl: string){
    if(ctrl=='ChiefComplaints'){
      this.payLoad.module_data.ChiefComplaints=[];
      evnt.forEach(e => {this.payLoad.module_data.ChiefComplaints.push(e)})
    }
    if(ctrl=='ProvisionalDiagnosis'){
      this.payLoad.module_data.ProvisionalDiagnosis=[];
      evnt.forEach(e => {this.payLoad.module_data.ProvisionalDiagnosis.push(e)})
    }
    if(ctrl=='Investigations'){
      this.payLoad.module_data.Investigations=[];
      evnt.forEach(e => {this.payLoad.module_data.Investigations.push(e)})
    }
    // if(ctrl=='DrugTypes'){
    //   this.payLoad.module_data.DrugTypes=[];
    //   evnt.forEach(e => {this.payLoad.module_data.DrugTypes.push(e)})
    // }
  }
}

export class DrMod {
  id: number = 0;
  module_name: string = '';
  module_data: {
    ChiefComplaints: any[],
    ProvisionalDiagnosis: any[],
    Investigations: any[]
    DrugTypes: any[],
  }
  constructor() {
    this.module_data = {
      ChiefComplaints: [],
      ProvisionalDiagnosis: [],
      Investigations: [],
      DrugTypes: [],
    }
  }
}