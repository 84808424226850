import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { NgbModalConfig, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import { Subject, pipe } from 'rxjs';
declare let $: any;
@Component({
  selector: 'app-village',
  templateUrl: './village.component.html',
  styleUrls: ['./village.component.css']
})
export class VillageComponent implements OnInit {

  @Input() pharmaData: any;
  villageForm: FormGroup;
  submitted = false;
  mandal_id: any;
  village_name: any;
  pin_code: any;
  modalTitle: string = '';
  dtOptions: any = {};
  dtTrigger: any = new Subject();
  villagelist: any;
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = []
  mandalList: Array<any> = [];
  villageList: Array<any> = [];
  constructor(
    config: NgbModalConfig,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private masterService: MasterService,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.locationChanged('', 'state');
    this.initFormControls()
    this.getFormValidationErrors()


    if (this.pharmaData) {
      Promise.all([this.locationChanged(this.pharmaData.state_id, 'district'),
      this.pharmaData.location_type_id == 2 ? (this.locationChanged(this.pharmaData.district_id, 'mandal'),
        this.locationChanged(this.pharmaData.mandal_id, 'village')) : this.locationChanged(this.pharmaData.district_id, 'city')])
        .then(() => {
          this.villageForm.patchValue(this.pharmaData);
        });
    }
  }
  initFormControls() {
    this.villageForm = this.formBuilder.group({
      id: ['0'],
      state_id: ['', Validators.required],
      district_id: ['', Validators.required],
      mandal_id: ['', Validators.required],
      pin_code: ['', Validators.required],
      village_name: ['', Validators.required],
    });
  }
  get f() { return this.villageForm.controls; }
  onSubmit(event: any) {
    let changeData = {
      "mandal_id": this.mandal_id,
      "pin_code": this.pin_code,
      "village_name": this.village_name
    }

    this.submitted = true;
    this.getFormValidationErrors();
    if (this.villageForm.invalid)
      return;
    else {
      // const sendData = JSON.parse(JSON.stringify(this.villageForm.getRawValue()));  
      //  var sendData = event.value

      // delete sendData.id;
      this.masterService.save('saveandupdatevillage', JSON.stringify(changeData)).subscribe(
        res => {
          let apiResponse = res;
          if (apiResponse.status == "success") {
            let responseData: any = apiResponse.response;
            this.initFormControls();
            this.villageForm.patchValue(responseData);
            // this.toastr.success(`Record ${(+sendData.id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Pharmacy Registration');
          }
        }, error => this.toastr.error(error));
    }
  }
  openVillageModal() {
    const modalRef = this.modalService.open(VillageComponent);
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.result.then(result => {

    }, (reason) => { });
  }

  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        this.mandalList = [];
        this.villageList = [];
        this.villageForm.get('district_id').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        // if (+id > 0)
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      },
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },

    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
        };
        assign_locations[type]();
      }
      this.districtList.sort((a,b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error));
  }

  loadVillages() {
    var id = $('#state_id').val()
    // this.loading = true;
    $('.dataTable').DataTable().destroy();
    $('.dataTable').DataTable({ searching: false });
    //   this.masterService.searchbyVillages(id).subscribe((data: any) => {
    // // this.loading = false;
    // this.villagelist = data.data;
    // this.dtTrigger.next();

    // });
    // this.loading = true;
  }
  deleteItem(id: any) {
    // this.masterService.deleteByStateId(id).subscribe(res => {
    //   if (res.status == "OK") {
    //     // this.getAllStates();
    //     this.toastr.success('Record Deleted Successfully');
    //   }
    // },
    // error => { this.toastr.error('An Error Occured'); });
  }

  getFormValidationErrors() {
    Object.keys(this.villageForm.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.villageForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
  restFormValues() {
    this.initFormControls();
  }
  submitForm() {
    document.getElementById('btnsubmit').click();
  }
}
