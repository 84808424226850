<div class="row">
  <div class="container-fluid pl-0 pr-0">
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-header-actions mx-auto">
          <div class="card-header">Transaction Status Report</div>
          <div class="card-body">
            <form [formGroup]="appointmentForm" (ngSubmit)="LoadGrid()"> <!-- LoadData() -->
              <div class="row ">
                <div class="container">
                  <div class="row ">
                    <div class='col-md-3'>
                      <div class="form-group">
                        <div class='date' id='datetimepicker6'>
                          <label class="control-label">From Date <span class="text-danger">*</span> </label>
                          <div>
                            <input id="fromDate" name="fromDate" type="text" autocomplete="off" onpaste="return false"
                              placeholder="From" bsDatepicker #dp="bsDatepicker" class="form-control form-control-sm"
                              [maxDate]="max_date" (focus)="dp1.hide()" formControlName="fromDate"
                              (bsValueChange)="onFromDateChange($event)" onkeypress='return event.charCode == 8'
                              [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                              [ngClass]="{ 'is-invalid': submitted && f.fromDate.errors }" (focus)="dp.show()">
                            <div *ngIf="submitted && f.fromDate.errors" class="invalid-feedback">
                              <div *ngIf="f.fromDate.errors.required">From Date is required</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='col-md-3'>
                      <div class="form-group">
                        <div class='date' id='datetimepicker7'>
                          <label class="control-label">To Date <span class="text-danger">*</span> </label>
                          <div>
                            <input id="toDate" name="toDate" type="text" autocomplete="off" onpaste="return false"
                              placeholder="To" bsDatepicker formControlName="toDate" [minDate]="f.fromDate.value"
                              (bsValueChange)="onFromDateChange($event)" bsDatepicker #dp1="bsDatepicker"
                              class="form-control form-control-sm" onkeypress='return event.charCode == 8'
                              [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}" (focus)="dp.hide()"
                              [ngClass]="{ 'is-invalid': submitted && f.toDate.errors }" (focus)="dp1.show()">
                            <div *ngIf="submitted && f.toDate.errors" class="invalid-feedback">
                              <div *ngIf="f.toDate.errors.required">To Date is required</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='col-md-3' style="margin-top: 2rem;">
                      <button type="submit" class="btn btn-sm btn-primary" value="Submit" (focus)="dp1.hide()">Submit</button>&nbsp;&nbsp;
                      <button type="button" class="btn btn-sm btn-success" (click)="xportXL()" *ngIf="lstGrid.length!=0">
                        <i class="fa fa-file-excel" aria-hidden="true"></i>&nbsp;&nbsp;Export Excel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="table-responsive">
              <table datatable id="table" class="table table-striped table-bordered table-sm" style="width:100%">
                <thead class="text-center">
                  <tr>
                    <th>S No</th>
                    <th>Patient Name</th>
                    <th>Patient<br /> Registration<br /> Number</th>
                    <th>Mobile<br /> Number</th>
                    <th>Payment<br /> Date</th>
                    <th>Amount</th>
                    <th>Order No</th>
                    <th>Call<br /> Status</th>
                    <th>Transaction<br /> Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of lstGrid;let i=index">
                    <td>{{i+1}}</td>
                    <td>{{item.patient_name}}</td>
                    <td>{{item.patient_registration_number}}</td>
                    <td>{{item.mobile_number}}</td>
                    <td>{{item.transaction_date | date:'dd-MM-yyyy'}}</td>
                    <td>{{item.amount}}</td>
                    <td>{{item.order_no}}</td>
                    <td>{{item.call_status}}</td>
                    <td>{{(item.payment_status===true)?'True':'False'}}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>