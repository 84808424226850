
<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Add Promotion
                        <button class="btn btn-blue btn-icon float-right"
                        (click)="modalTitle='Create Promotion';openPromotionModal()">

                            <i class="fa fa-plus"></i>
                        </button>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">

                            <table datatable id="promotionList" class="table table-striped table-bordered table-sm" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>State </th>
                                        <th>Company Name</th>
                                        <th>Promotion Name</th>
                                        <th>Promotion Code</th>
                                        <th>Lab Discount</th>
                                        <th>Pharmacy Discount</th>
                                        <th>Doctor Discount</th>
                                        <th>Start Dat</th>
                                        <th>End Date</th>
                                        <th>Status</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody></tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>


        