import { HospitalDetailComponent } from './../hospital-detail/hospital-detail.component';
import { PharmacyRegistrationComponent } from './../pharmacy-registration/pharmacy-registration.component';
import { DoctorRegistrationComponent } from './../doctor-registration/doctor-registration.component';
import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { LaboratoryRegistrationComponent } from '../laboratory-registration/laboratory-registration.component';
import { CompanyComponent } from './../../masters/company/company.component'
import { MasterService } from 'src/app/shared/services/master.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import * as XLSX from 'xlsx';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
import { UserService } from 'src/app/shared/services/user.service';
declare let $: any;


@Component({
  selector: 'app-in-active-list',
  templateUrl: './in-active-list.component.html',
  styleUrls: ['./in-active-list.component.css']
})

export class InActiveListComponent extends BaseComponent implements OnInit {

  modalTitle: string = '';
  headerList: Array<string> = [];
  dataList: Array<any> = [];
  selectedTest: string = 'doctor';
  checkedList: Array<{ id: number, account_activated: boolean, email_id: string, mobile_no: string, name: string }> = [];
  chkdLst: Array<{ id: number, account_activated: boolean, is_active: boolean, user_type: string }> = [];
  isSAGE = environment.isSAGE;idval1:any;idval2:any;
  fileName = '';
  excelData: any =[];
  excelType: string;
  hidePharmacy: boolean;
  hideLab: boolean;
  constructor(config: NgbModalConfig
    , private modalService: NgbModal
    , private toastr: ToastrService
    , private notificationService: NotificationService
    , private regService: RegistrationService
    , private authSvc: AuthenticationService,
    private excelDownloadService:ExcelDownloadService
    , private masterService: MasterService
    , private userServ: UserService ) {
    super();
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.authSvc.isShowGlblOrg.next(false), this.authSvc.tenantId = sessionStorage.getItem('defCrntTnnt')
    if (this.authSvc.roleId == 1) {
      sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt'));
    }
      this.getHeadings('doctor');
  }

  getHeadings(type: string) {
    this.excelType = type;
this.idval1='#'+type;
this.idval2=type;
    const heading = {
      doctor: ['District','Hub/Centre','Doctor Name', `${this.isSAGE ? 'KMPDU Number' : 'MCI Number'}`, 'Email', 'Actions', 'View'], // Status
      pharmacy: ['District','Block','Centre','Pharmacy Name', 'Registration Number', 'Status', 'View'],
      lab: ['District','Block','Centre','Lab Name', 'Registration Number', 'NABL Certificate', 'Status', 'View'],
      company: ['Company Name', 'Registration Number', 'Status', 'View'],
      hospital: ['District','Block','Centre','Hospital Name', 'Registration Number', 'Status', 'View'],
    }
    if (this.headerList.length > 0) {
      $('.dataTable').DataTable().destroy();;
      $('.dataTable').DataTable({ destroy: true, searching: false });
    }
    this.selectedTest = type, this.headerList = heading[type], this.loadTable(type), this.checkedList = [];
  }
   // new method added for permission 21-02-2023
   getUserPermission() {
    let orgIdValue = JSON.parse(sessionStorage.getItem('currentTenant'));
    this.userServ.getUserPermissionByOrgId(orgIdValue).subscribe((res: any) => {
      if (res.status == 'OK') {
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].feature_id.feature_name == "hidePharmacyPrefered") {
            if (res.data[i].patient) {
              this.hidePharmacy = true;
            } else {
              this.hidePharmacy = false;
            }
          }
          if (res.data[i].feature_id.feature_name == "hideLabPreferred") {
            if (res.data[i].patient) {
              this.hideLab = true;
            } else {
              this.hideLab = false;
            }

          }
        }
      }
    });
  }

  destroyExistingTables(tableId: string) {
    let [tablesName, destroyTables] = [['doctor', 'pharmacy', 'lab', 'company', 'hospital'], ''];
    tablesName.forEach(item => { if (tableId !== item) destroyTables += `#${item},#${item}_wrapper,`; });
    destroyTables = destroyTables.substring(0, destroyTables.length - 1);
    setTimeout(() => {
      $(destroyTables).attr("hidden", "hidden");
      $(`#${tableId},#${tableId}_wrapper`).removeAttr("hidden");
      $(`#${tableId}`).DataTable({ destroy: true });
    }, 1000);
  }

  tableDataFromAPI(subURL: string,type:string) {
    this.dataList = [];
    this.regService.getAll(subURL).subscribe({
      next: res => {
        if (res.status === 'OK') {
          if (res.data == null || res.data.length == 0)
            this.toastr.info('No records available', 'In Active List');
          else
            this.dataList = res.data;
            this.excelData= this.dataList;
        }
      },
      error: err => { this.toastr.error(err) },
      complete: () => { this.destroyExistingTables(type); }
    });
  }

  tableDataFromAPI2(subURL: string) {
    this.dataList = [], this.masterService.getAll(subURL).subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'In Active List');
        else
          this.dataList = res.data;
      }
    });
  }

  tableDataFromAPI3() {
    this.regService.getByAllDoctorsNew().subscribe({
      next: res => {
        if (res.message === 'All doctor records') {
          if (res.data == null || res.data.length == 0)
            this.toastr.info('No records available', 'In Active List');
          else
            this.dataList = res.data; // .filter(f => f.is_active == true);
        }
      },
      error: err => { this.toastr.error(err) },
      complete: () => { this.destroyExistingTables('doctor'); }
    });
  }

  loadTable(type: string) {
    let data = {
      'doctor': () => { this.tableDataFromAPI3() },  // 'doctor': () => { this.tableDataFromAPI('doctor/getdoctorrecords',type) }
      'pharmacy': () => { this.tableDataFromAPI('pharmacy/allpharmacyrecords', type) }, // getallrecords
      'lab': () => { this.tableDataFromAPI('lab/alllabregistrations', type)},   // getalllabregistrations
      'company': () => { this.tableDataFromAPI2('mastercompany/getallcompanies'); this.destroyExistingTables(type); }, 
      'hospital': () => { this.tableDataFromAPI('hospital/gethospitalrecords',type)},  // getallhospitalrecords
    }
    if (type == '')
      this.dataList = [], this.destroyExistingTables(type);
    else
      data[type]();
  }

  statusChanged(id: number, status: boolean, email_id: string, mobile_no: string, first_name: string, last_name: string) {
    const newItem = this.checkedList.find(item => item.id == id);
    if (newItem)
      newItem.account_activated = status;
    else
      this.checkedList.push({ id: id, account_activated: status, email_id: email_id, mobile_no: mobile_no, name: `${first_name} ${last_name}` });
  }

  onChkBoxChng(id: number, isChkd: boolean, usrType: string, type: string ) {
    const chkItem = this.chkdLst.find(item => item.id == id), rowItem = this.dataList.find(item => item.id == id);
    if (chkItem) {
      if (type == 'a')
        chkItem.account_activated = isChkd;
      else
        chkItem.is_active = isChkd;
    }
    else
      this.chkdLst.push({ id: id, account_activated: type == 'a' ? isChkd : rowItem.account_activated,
        is_active: type == 'd' ? isChkd : rowItem.is_active, user_type: usrType });
    this.chkdLst.forEach(e => {
      if (e.id == rowItem.id) {
        if (e.account_activated == rowItem.account_activated && e.is_active == rowItem.is_active)
          this.chkdLst = this.chkdLst.filter(f => f.id != rowItem.id);
      }
    });
    // if (type == 'd')
    //   rowItem.is_active = isChkd;
  }

  submit() {
    if (this.selectedTest == 'doctor')
      this.onSubmitNew();
    else {
      if (this.checkedList.length > 0) {
        let [emailList, url] = [[], ''];
        let usersListType = [
          { id: 4, name: 'pharmacy' },
          { id: 5, name: 'lab User' },
          { id: 7, name: 'doctor' },
          { id: 0, name: 'hospital' },
        ];
        let saveTypes = {
          'doctor': () => url = 'doctor/updatedoctorstatus',
          'pharmacy': () => url = 'pharmacy/updatepharmacystatus',
          'lab': () => url = 'lab/updatelabstatus',
          'company': () => url = 'mastercompany/updatecompanystatus',
          'hospital': () => url = 'hospital/updatehospitalstatus',
        }
        saveTypes[this.selectedTest]();

        let user = usersListType.find(item => item.name == this.selectedTest);
        if (url) {
          // this.toastr.success(`${this.selectedTest.toUpperCase()} Records Submitted Successfully`);
          let arrList = [];
          this.checkedList.forEach(e => {
            arrList.push({ id: e.id, account_activated: e.account_activated });
            if (e.account_activated && user)
              emailList.push({ email: e.email_id, mobile_no: e.mobile_no, name: e.name, user_id: user.id, user_type: user.name });
          })
          if ('company' == this.selectedTest)
            this.masterService.save(url, arrList).subscribe(res => {
              if (res.status == 'OK') // console.log(res)
                this.toastr.success(`${this.selectedTest.toUpperCase()} records Submitted Successfully`);
              else
                this.toastr.warning(res.message)
            });
          else
            this.regService.save(url, arrList).subscribe(res => {
              if (res.status == 'OK') // console.log(res)
                this.toastr.success(`${this.selectedTest.toUpperCase()} records Submitted Successfully`);
              else
                this.toastr.warning(res.message)
            });
          //const notificationData = { email: sendData.email, mobile_no: +sendData.mobile, name: `${sendData.first_name} ${sendData.last_name}` };
          emailList.forEach(e => this.send_Notification(e));
        }
      }
      else
        this.toastr.error('Please select atleast on record');
    }
  }

  onSubmitNew() {
    if (this.chkdLst.length > 0) {
      this.regService.postAPI2(`updatedelteuserstatus`, this.chkdLst).subscribe(res => {
        if (res) {
          if (res.status == 'OK') {
            this.toastr.success(res.message), this.chkdLst.forEach(e => {
              if (e.account_activated) {
                let rowItem = this.dataList.find(item => item.id == e.id), payload: any;
                if (rowItem) {
                  payload = { email: rowItem.email, mobile_no: +rowItem.mobile, name: `${rowItem.first_name} ${rowItem.last_name}`,
                    user_id: +rowItem.id, user_type: this.selectedTest };
                  this.send_Notification(payload);
                }
              }
            });
          }
          else
            this.toastr.warning(res.message);
        }
      })
    }
    else
      this.toastr.info('Please change at least one record..!');
  }

  send_Notification(payload: { email: string, mobile_no: number, name: string, user_id: number, user_type: string }) {
    const postData = {
      "email": payload.email,
      "mobile_no": payload.mobile_no,
      "user_id": payload.user_id,
      "user_type": payload.user_type,
      "name": payload.name.toUpperCase()
    }
    this.notificationService.sendNotification(postData).subscribe();
  }

  openModal(item: any = null, componentType: string = 'doctor', title: string) {
    let comp: any;
    let list = {
      'doctor': () => comp = DoctorRegistrationComponent,
      'lab': () => comp = LaboratoryRegistrationComponent,
      'pharmacy': () => comp = PharmacyRegistrationComponent,
      'company': () => comp = CompanyComponent,
      'hospital': () => comp = HospitalDetailComponent,
    }
    list[componentType]();
    const modalRef = this.modalService.open(comp, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = title;
    modalRef.componentInstance.isSubmit_Disabled = true;
    if (componentType == 'lab')
      modalRef.componentInstance.labData = item;
    else if (componentType == 'doctor')
      modalRef.componentInstance.doctor = item;
    else if (componentType == 'pharmacy')
      modalRef.componentInstance.pharmaData = item;
    else if (componentType == 'hospital')
      modalRef.componentInstance.hospitalData = item;
    else if (componentType == 'company')
      modalRef.componentInstance.DataEdit = item;
    modalRef.result.then(result => {
    }, (reason) => { });
  }

  ViewModal(id: any, type: string = 'doctor', title: string = 'Doctor Registration Details') {
    let comp: any, list = {
      'doctor': () => comp = DoctorRegistrationComponent,
      'lab': () => comp = LaboratoryRegistrationComponent,
      'pharmacy': () => comp = PharmacyRegistrationComponent,
      'company': () => comp = CompanyComponent,
      'hospital': () => comp = HospitalDetailComponent,
    }
    list[type]();

    if (id != null) {
      if (type == 'doctor')
        this.regService.getByDoctorId(id).subscribe(res => {
          const modalRef = this.modalService.open(comp, { scrollable: true, size: 'xl' });
          modalRef.componentInstance.title = title;
          modalRef.componentInstance.doctor = res.data;
          modalRef.componentInstance.isSubmit_Disabled = false;
          modalRef.result.then(result => { }, (reason) => { });
    });
    }
  }

  downLoadExcel(){
    this.excelData = this.dataList;
    const fileName = 'Active_Inactive_List'
      var excelData = this.excelData;
      if(excelData.length>0){
        if(this.excelType==='doctor'){
          const fileName = 'Doctor_Active_Inactive_List'
          excelData.map(res =>{
            res.fullName = (res.first_name +' '+ res.last_name)
          })
          const excelHeaders = this.headerList;
          const internalHeaders = ['district_name','center_type','fullName', 'mci_number', 'email'];
          this.toastr.success('Please wait excel is downloading')
          this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
        }
          
        if(this.excelType==='pharmacy'){
          const fileName = 'Pharmacy_Active_Inactive_List'
          excelData.map(res =>{
            res.fullName = (res.pharmacy_name)
          })
          const excelHeaders = this.headerList;
          const internalHeaders = ['district_name','mandal_name','center_name','fullName', 'registration_no'];
          this.toastr.success('Please wait excel is downloading')
          this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
        }
        if(this.excelType==='lab'){
          const fileName = 'Lab_Active_Inactive_List'
          excelData.map(res =>{
            res.fullName = (res.lab_name)
          })
          const excelHeaders = this.headerList;
          const internalHeaders = ['district_name','mandal_name','center_name','fullName', 'registration_no'];
          this.toastr.success('Please wait excel is downloading')
          this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
        }
        if(this.excelType==='hospital'){
          const fileName = 'Hospital_Inactive_List'
          excelData.map(res =>{
            res.fullName = (res.hospital_name)
          })
          const excelHeaders = this.headerList;
          const internalHeaders = ['district_name','mandal_name','center_name','fullName', 'registration_no'];
          this.toastr.success('Please wait excel is downloading')
          this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
        }
        if(this.excelType==='company'){
          const fileName = 'Company_Inactive_List'
          excelData.map(res =>{
            res.fullName = (res.company_name)
          })
          const excelHeaders = this.headerList;
          const internalHeaders = ['fullName', 'registration_no'];
          this.toastr.success('Please wait excel is downloading')
          this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
        }
        }
      
      else{
        this.toastr.error('No data to download')
      }
    
  }


}