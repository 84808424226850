<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Lab Registration
                        <button class="btn btn-blue btn-icon float-right"
                            (click)="modalTitle='Lab Registration Details';ChkLimit()"
                            *ngIf="setPrivilege(Screen_Name.LabRegistration, Permission.Add)">
                            <i class="fa fa-plus"></i> <!-- openLabModal() -->
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="" style="margin-top: 1rem;" >
                            <button type="button" class="btn btn-sm btn-secondary btn-sm px-4 mb-2 float-right" *ngIf ="excelData.length>0"(click)="downLoadExcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                        </div>
                        <div class="table-responsive">
                            <table datatable id="labTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>S No </th>
                                        <th>Lab Name </th>
                                        <th>Lab Contact No</th>
                                        <th>Contact Person Name</th>
                                        <th>Contact Person Mobile No</th>
                                        <th>Lab Registration No</th>
                                        <th>Location Type</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of lstGrid; let i=index">
                                        <td>{{i+1}}</td>
                                        <td class="text-uppercase">{{item.lab_name}}</td>
                                        <td>{{item.lab_conatct_no}}</td>
                                        <td class="text-uppercase">{{item.contact_person_name}}</td>
                                        <td>{{item.contact_person_mobile}}</td>
                                        <td>{{item.registration_no}}</td>
                                        <td>{{item.location_type_id == 2 ? 'Urban' : 'Rural'}}</td>
                                        <td>
                                            <!-- <td><div class="badge badge-success badge-pill">
                                                <a  data-toggle="modal" data-target="#exampleModalScrollable">
                                                <i class="fa fa-edit text-white"></i> </a> </div> -->
                                            <div class="badge badge-info badge-pill pointer"
                                                *ngIf="setPrivilege(Screen_Name.LabRegistration, Permission.Edit)">
                                                <a (click)="modalTitle='Edit';openLabModal(item)">
                                                    <i class="fa fa-edit text-white"></i></a>
                                            </div>&nbsp;
                                            <div class="badge badge-danger badge-pill pointer"
                                                *ngIf="setPrivilege(Screen_Name.LabRegistration, Permission.Delete)">
                                                <a (click)="onDelete(item.id)" title="Delete"><i class="fa fa-trash text-white"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>