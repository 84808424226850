    
    <div class="modal-header">
        <h4 class="modal-title">{{title}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <form [formGroup]="stateForm" (ngSubmit)="onSubmit($event)">
       
            <div class="row">
        
  
                     <div class="col-xl-6">
                      <div class="form-group">
                        <label class="control-label">State Name <span class="text-danger">*</span> </label>
                        <div>
                          <input type="text" autocomplete="off" formControlName="state_name" id="state_name"
                            placeholder="State Name" maxlength="50" 
                            [ngClass]="{ 'is-invalid': submitted && f.state_name.errors }"
                            class="form-control form-control-sm "
                            onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'> 
                        
                            <div *ngIf="submitted && f.state_name.errors" class="invalid-feedback">
                              <div *ngIf="f.state_name.errors.required">State Name is required</div>
                            </div>
                          </div>
                </div></div>
            </div>
            <input type="submit"  id="btnsubmit" style="display: none;">
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <input type="submit" class="btn btn-sm btn-primary" (click)="submitForm()" value="Submit">&nbsp;&nbsp;
        <button type="button" class="btn btn-sm btn-outline-dark"
            (click)="activeModal.close('Close click')">Close</button>
    </div>