import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
// const httpOptions = {
//     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
// };
@Injectable({ providedIn: 'root' })

export class MasterService {
    // url: string = 'http://registration.dhanushinfotech.com/esubcenter_master/';
    mastersAPI: string = '';
    constructor(private api: ApiService) {
        this.mastersAPI = environment.mastersAPI;
    }

    getLocations(queryString: string): Observable<any> { return this.api.get(`${this.mastersAPI}${queryString}`); }
    getAll(queryString: string): Observable<any> { return this.api.get(`${this.mastersAPI}${queryString}`); }
  //  getAll(queryString: string): Observable<any> { return this.api.get(`${this.url1}masters/${queryString}`); }
    getAPI(queryString: string): Observable<any> { return this.api.get(`${queryString}`); }
   
    //investigation
    saveInvestigation(data: any): Observable<any> { return this.api.post(`${this.mastersAPI}saveandupdateinvestigation`, data); }
    deleteByinvestId(id: number): Observable<any> { return this.api.get(`${this.mastersAPI}deleteinvestigation?investigationId=${id}`); }

    //drug
    saveDrug(data: any): Observable<any> { return this.api.post(`${this.mastersAPI}saveandupdatedrug`, data); }
    deleteBydrugId(id: number): Observable<any> { return this.api.get(`${this.mastersAPI}deletedrug?drugId=${id}`); }
    deleteByCoupon(id: number): Observable<any> { return this.api.get(`${this.mastersAPI}deletecoupon?couponId=${id}`); }
    deleteByCompany(id: number): Observable<any> { return this.api.get(`${this.mastersAPI}mastercompany/deletebyid?id=${id}`); }
    
    // save(methodName: string, data: any): Observable<any> { return this.api.post(`${this.mastersAPI}${methodName}`, data); }
    //company
    getAllCompanies(apiMethod: string): Observable<any> { return this.api.get(`${this.mastersAPI}${apiMethod}`); }

    save(methodName: string, data: any): Observable<any> { return this.api.post2(`${this.mastersAPI}${methodName}`, data); }
    searchbyIdMasters(queryString: string): Observable<any> { return this.api.get(`${this.mastersAPI}${queryString}`); }

    //deletbystate
    deleteById(queryString: string): Observable<any> { return this.api.get(`${this.mastersAPI}${queryString}`); }
    
    //getallpocoptions
    getAllPocOptions(): Observable<any> { return this.api.get(`${this.mastersAPI}getallmastermoduletype`); }
    getalldrugs(): Observable<any> { return this.api.get(`${this.mastersAPI}getalldrugtypes`); }
    getDrugDetails(id: Number): Observable<any> { return this.api.get(`${this.mastersAPI}drugtypemapping/getdrugbydrugtypeid?drugTypeId=${id}`); }
    getAllpaymenttypes(): Observable<any> { return this.api.get(`${this.mastersAPI}vle/getall`); }

    getallhitypes():Observable<any>{
      // below line commented for new api added 04-07-2023
      // return this.api.get(`${this.mastersAPI}ndhm/hitype/getallhitypes`);
      return this.api.get(`${this.mastersAPI}master-approval-status/get-all-type-id/8`);
      // https://telemedicine-uk-training.vmedtm.com/masters/ndhm/hitype/getallhitypes
    }
    
    getallpurposeofuse():Observable<any>{
      // below line commented for new api call 04-07-2023
      // return this.api.get(`${this.mastersAPI}ndhm/purpose/getallpurposeofuse`);
      return this.api.get(`${this.mastersAPI}master-approval-status/get-all-type-id/7`);
      // https://telemedicine-uk-training.vmedtm.com/masters/ndhm/hitype/getallhitypes
    }
  }