<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        State Registration
                        <button class="btn btn-blue btn-icon float-right"
                        (click)="modalTitle='State Registrationn Details';openStateModal()">

                            <i class="fa fa-plus"></i>
                        </button>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">

                            <table datatable id="stateTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>S No</th>
                                        <th>State Name  </th> 
                                        <th style="width:10%;">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                         <tr *ngFor="let state of stateList;let ind=index;">
                                        <td>{{(ind+1)}}</td>
                                        <td>{{state.state_name}}</td>
                                       
                                        <td>
                                           
    <div class="badge badge-success badge-pill"> <a (click)="modalTitle='Edit State Details';openStateModal(state)"> <i  class="fa fa-edit text-white"></i></a></div>&nbsp;&nbsp;
    <div class="badge badge-danger badge-pill"><a (click)="deleteItem(state.id)"><i class="fa fa-trash text-white"></i></a></div> </td> 
                                     
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>


