

    <div class="container-fluid pl-0 pr-0">
        <div class="row">
          <div class="col-xl-12">
            <div class="card card-header-actions mx-auto">
              <div class="card-header">
                  Network Status Report
              </div>
    
              <div class="card-body">
                <form [formGroup]="networkStatusForm" (ngSubmit)="LoadData()">
                  <!-- new ui start  -->
                  <div class="row">
                    <div class="col-md">
                      <label class="control-label">From Date <span class="text-danger">*</span> </label>
                      <div>
                           <input id="start_date" name="start_date" type="text" autocomplete="off" onpaste="return false"
                                  placeholder="From" bsDatepicker #dp="bsDatepicker" (focus)="dp.show()" (focus)="dp1.hide()"
                                  formControlName="start_date" [maxDate]="max_date"
                                  (bsValueChange)="onFromDateChange($event)" onkeypress='return event.charCode == 8'
                                  [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                                  [ngClass]="{ 'is-invalid': submitted && f.start_date.errors }"
                                  class="form-control form-control-sm">
                           <div *ngIf="submitted && f.start_date.errors" class="invalid-feedback">
                              <div *ngIf="f.start_date.errors.required">From Date is required</div>
                           </div>
                      </div>
                    </div>
                    <div class="col-md">
                      <label class="control-label">To Date <span class="text-danger">*</span></label>
                      <div>
                        <input id="end_date" name="end_date" type="text" autocomplete="off" onpaste="return false"
                          placeholder="To" bsDatepicker formControlName="end_date"  [minDate]="f.start_date.value" [maxDate]="max_date" (focus)="dp.hide()"
                          bsDatepicker #dp1="bsDatepicker" (focus)="dp1.show()" (bsValueChange)="onToDateChange($event)"
                          [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                          [ngClass]="{ 'is-invalid': submitted && f.end_date.errors }"
                          class="form-control form-control-sm" onkeypress='return event.charCode == 8'>
                        <div *ngIf="submitted && f.end_date.errors" class="invalid-feedback">
                          <div *ngIf="f.end_date.errors.required">To Date is required</div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md">
                      <label class="control-label">{{isNIG ? 'States' : 'State'}}  </label>
                      <div>
                          <select formControlName="state_id" id="state_id" #State
                              (change)="locationChanged(State.value,'district')"
                              [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }"
                              class="form-control form-control-sm"
                              disabled>
                              <option value="">Select</option>
                              <option *ngFor="let state of stateList" [value]="state.id">
                                  {{state.state_name}}</option>
                          </select>
                          <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                              <div *ngIf="f.state_id.errors.required">
                                  {{isNIG ? 'States' : 'State'}} is required
                              </div>
                          </div>
                      </div>
                    </div>
                    <div class="col-md">
                        <!-- <label class="control-label">{{isNIG ? 'State Capitals' : 'District'}} </label>
                        <div>
                          <ng-multiselect-dropdown
                          [placeholder]="'Select'"
                          [settings]="districtSettings"
                          [data]="districtList"
                          formControlName="district_id"
                          (onSelect)="onItemSelect($event)"
                          (onSelectAll)="onSelectAll($event)"
                          (onDeSelect)="onItemDeSelect($event)"
                          (onDeSelectAll)="onUnSelectAll()">
                      </ng-multiselect-dropdown>
                        </div> -->
                        <label class="form-label" >Select {{isNIG ? 'State Capitals' : 'District'}}</label>         
                        <ng-multiselect-dropdown 
                                    [placeholder]="'Select'"
                                    [settings]="dropdownSettings_district"
                                    [data]="districtList"
                                    name="district_id"
                                    formControlName="district_id"
                                    [(ngModel)]="selectedDistricts"
                                    (onSelect)="onItemSelectDistrict($event)"
                                    (onDeSelect)="onItemDeSelectDistrict($event)"
                                    (onSelectAll)="onSelectAllDistrict($event)"
                                    (onDeSelectAll)="onDeSelectAllDistrict($event)">
                           <!-- [(ngModel)]="selectedDistricts" -->
                        </ng-multiselect-dropdown>
                    </div>
                    
                    <div class="col-md">
                      <label class="form-label" >Select Centres</label>
                      <!-- <label class="control-label">Centres </label> -->
                      <div>
                        <!-- formControlName="center_id" -->  
                      
                        <ng-multiselect-dropdown  (click)="show()"
                                        [placeholder]="'Select'"
                                        [settings]="dropdownSettings_centre"
                                        [data]="centerHospitals"
                                        [(ngModel)]="selectedCentres"
                                        name="center_id" formControlName="center_id"
                                        (onSelect)="onItemSelectCentre($event)"
                                        (onSelectAll)="onSelectAllCentre($event)"
                                        (onDeSelect)="onItemDeSelectCentre($event)"
                                        (onDeSelectAll)="onDeSelectAllCentre($event)">
                        </ng-multiselect-dropdown>
                      </div>
                    </div>
            
              
                  </div>
                  <div class='float-right' style="margin: 20px 0px;"  >
                    <button type="submit" class="btn btn-sm btn-primary" value="Submit" (focus)="dp1.hide()">Submit</button>&nbsp;
                    <!-- <button type="button" class="btn btn-sm btn-danger" (click)="clear()">Clear</button> &nbsp; -->
                     <button type="button" class="btn  btn-sm btn-secondary  text-center" *ngIf="excelData && excelData.length>0" (click)="downLoadExcel()" > <i class="fa fa-file-excel-o"></i>Export</button>
                 </div>
                
                   <!-- new ui end  -->
                </form>
    
                <div class="table-responsive mt-3">
                  <table datatable id="networkStatusReport" class="table table-striped table-bordered table-sm" style="width:100%">
                    <thead class="text-center">
                      <tr>
                        <th class="singleLine">S.No</th>
                        <th class="singleLine">District</th>
                        <th class="singleLine">Block</th>
                        <th class="singleLine">Pharmacist/Healthcare Professional Name</th>
                        <th class="singleLine">Pharmacist/Healthcare Professional Number</th>
                        <th class="singleLine">Date and Time</th>
                        <th class="singleLine">Centre</th>
                        <th class="singleLine">RF</th>
                        <th class="singleLine">SIM</th>
                        <th class="singleLine">ODCPE</th>
                        <th class="singleLine">Centre Status</th>
                        <th class="singleLine">Network Status</th>

                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of networkStatuslist;let i= index;">
                        <td class="singleLine text-center">{{i+1}}</td>
                        <td class="singleLine text-center">{{item?.district_name}}</td>
                        <td class="singleLine text-center">{{item?.mandal_name}}</td>
                        
                        <td class="singleLine text-center">{{item?.health_associate_name}}</td>
                        <td class="singleLine text-center">{{item?.health_associate_mobile}}</td>
                        <td class="singleLine text-uppercase">{{item?.modified_on| date:'dd-MM-yyyy hh:mm:ss'}}</td>
                        <td class="singleLine text-center">{{item?.center_name}}</td>
                        <td class="singleLine text-center">{{item?.rf}}</td>
                        <td class="singleLine  text-center">{{item?.sim}}</td>
                        <td class="singleLine  text-center">{{item?.odcpe}}</td>
                        <td class="singleLine  text-center">{{item?.center_network_status}}</td>
                        <td class="singleLine  text-center">{{item?.network_status}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
    
              </div>
            </div>
          </div>
        </div>
    
      </div>
  
  
  