import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Common } from 'src/app/common';
import { BaseComponent } from 'src/app/shared/base.component';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ServicesComponent } from '../services/services.component';
import * as XLSX from 'xlsx';
declare let $: any;

@Component({
  selector: 'app-services-list',
  templateUrl: './services-list.component.html',
  styleUrls: ['./services-list.component.css']
})
export class ServicesListComponent extends BaseComponent implements OnInit {

  modalTitle: string = ''; servicelist = [];
  fileName = 'services list.xlsx';

  constructor(private modalService: NgbModal, private registrationserv: RegistrationService
    , private toastr: ToastrService) {
      super();
    }

  ngOnInit(): void {
    this.getAllTypeofservice();
  }

  openServicesModal(DataEdit: any = null) {
    const modalRef = this.modalService.open(ServicesComponent);
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.DataEdit = DataEdit;
    modalRef.result.then(result => {
      if (result == 'changes saved')
        this.getAllTypeofservice();
    }, (reason) => { });
  }

  getAllTypeofservice() {
    this.registrationserv.getallTypeofservice().subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Coupon List');
        else
          this.servicelist = res.data;
        Common.loadDataTable(this.servicelist, '#services');
      }
    });
  }
  deleteTypeofserviceById(id: any) {
    if (confirm(`Are you sure you want delete record?`)) {
      this.registrationserv.deleteTypeofServiceById(id).subscribe(res => {
        if (res.status == "OK") {
          this.getAllTypeofservice();
          this.toastr.success('Record Deleted Successfully');
        }
      },
        error => { this.toastr.error('An Error Occured'); });
    }
  }

  exportexcel(): void {
    var table = $('#services').DataTable();
    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {	
      / table id is passed over here /
      let element = document.getElementById('services');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };

      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
  }

}
