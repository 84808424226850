import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Common } from 'src/app/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import * as XLSX from 'xlsx';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
declare let $: any;

@Component({
  selector: 'app-dss-report',
  templateUrl: './dss-report.component.html',
  styleUrls: ['./dss-report.component.css']
})
export class DssReportComponent implements OnInit {
  dssreportdata;totaldssreport;totaldssreport2;patientname='';totaldssreport3;totaldssreport4;totaldssreport5;
  singleValuedata;totaldssreport6;totaldssreport7;totaldssreport8;totaldssreport9;totaldssreport10;childdatashow=false;
  motherdatashow=false;newitem;flowtype;totaldssreport11;
  fileName = 'DSS_report_list';
  excelData: any =[];

  constructor(private regService: RegistrationService
    , protected toastr: ToastrService
    , private formBuilder: FormBuilder
    , private datePipe: DatePipe
    , private masterService: MasterService,
    private excelDownloadService:ExcelDownloadService
    , private authSvc: AuthenticationService) { }

  ngOnInit(): void {
    this.getalldssdata();
  }
  viewdata(item){
    this.childdatashow=false;
  this.motherdatashow=false;
  if(Object.keys(item).includes('flow_type')){
  this.flowtype=item.flow_type;
  }
    this.patientname=item.name;
    this.totaldssreport=[];this.totaldssreport2=[];this.totaldssreport3=[];this.totaldssreport4=[];this.totaldssreport5=[];
  this.singleValuedata=[];this.totaldssreport6=[];this.totaldssreport7=[];this.totaldssreport8=[];this.totaldssreport9=[];this.totaldssreport10=[];
  this.totaldssreport11=[];
  this.singleValuedata=item.summary.childJson;
    if(Object.keys(item.summary).includes('childJson')){
      this.childdatashow=true;
    if(Object.keys(item.summary.childJson).includes('advices')){
//this.totaldssreport=item.summary.advices[0][0].advises;
if(item.summary.childJson.advices.length !=0){
  for(let i=0;i<item.summary.childJson.advices.length;i++){
this.totaldssreport.push(item.summary.childJson.advices[i]);
  }
}
    }
    if(Object.keys(item.summary.childJson).includes('childAssesForData')){
this.totaldssreport2=item.summary.childJson.childAssesForData;
    }
    if(Object.keys(item.summary.childJson).includes('diarrhoeaResponseJson')){
this.totaldssreport3=item.summary.childJson.diarrhoeaResponseJson;
    }
    if(Object.keys(item.summary.childJson).includes('feedingAssesData')){
this.totaldssreport4=item.summary.childJson.feedingAssesData;
    }
    if(Object.keys(item.summary.childJson).includes('referralJson')){
this.totaldssreport5=item.summary.childJson.referralJson;
    }
    if(Object.keys(item.summary.childJson).includes('vaccineData')){
      this.totaldssreport11=item.summary.childJson.vaccineData;
          }
  }
  if(Object.keys(item.summary).includes('motherJson')){
    this.motherdatashow=true;
    if(Object.keys(item.summary.motherJson).includes('advices')){
      if(item.summary.motherJson.advices.length !=0){
      this.totaldssreport6=JSON.parse(item.summary.motherJson.advices[0][0].advises);
      }
    }
    if(Object.keys(item.summary.motherJson).includes('askFordata')){
      this.totaldssreport7=item.summary.motherJson.askFordata;
    }
    if(Object.keys(item.summary.motherJson).includes('examinations')){
      this.totaldssreport8=item.summary.motherJson.examinations;
    }
    if(Object.keys(item.summary.motherJson).includes('pastHistoryList')){
      this.totaldssreport9=item.summary.motherJson.pastHistoryList;
    }
    if(Object.keys(item.summary.motherJson).includes('selectedSymptoms')){
      this.totaldssreport10=item.summary.motherJson.selectedSymptoms;
    }
  }
}
  getalldssdata(){
    // this.regService.getalldsssummary().subscribe(res => {
      this.regService.getalldsssummary().subscribe(res=>{
      if (res.data == null || res.data.length == 0)
      this.dssreportdata = [], Common.loadDataTable(this.dssreportdata, "#dssr"),
        this.toastr.error(res.message);
      else
      this.dssreportdata = res.data;
      this.excelData = this.dssreportdata;
          Common.loadDataTable(this.dssreportdata, '#dssr');
    })
  }

  exportexcel(): void {

    var table = $('#dssr').DataTable();

    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {
      / table id is passed over here /
      let element = document.getElementById('dssr');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };

      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
}

downLoadExcel(){
  const fileName = this.fileName;
    var excelData = this.excelData;
    if(excelData.length>0){
      const excelHeaders = ['Patient Name', 'Mobile No'];
      const internalHeaders = ['name', 'mobile'];
      this.toastr.success('Please wait excel is downloading')
      this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
    }
    else{
      this.toastr.error('No data to download')
    }
  
}
}
