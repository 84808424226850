<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="vendorForm" (ngSubmit)="onSubmit($event)">
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Vendor Name <span class="text-danger">*</span> </label>
                        <input type="text" class="form-control form-control-sm" autocomplete="off"
                            formControlName="vendor_name" id="vendor_name" maxlength="20" placeholder="Vendor Name"
                            [ngClass]="{ 'is-invalid': submitted && f.vendor_name.errors }" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'>
                    </div>
                    <div *ngIf="submitted && f.vendor_name.errors" class="invalid-feedback">
                        <div *ngIf="f.vendor_name.errors.required">Vendor Name is required</div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Vendor Email <span class="text-danger">*</span> </label>
                        <input type="text" class="form-control form-control-sm" formControlName="vendor_email"
                            id="vendor_email" autocomplete="off" maxlength="40"
                            [ngClass]="{ 'is-invalid': submitted && f.vendor_email.errors }"
                            onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode == 64) ||(event.charCode == 46) ||
                                (event.charCode >= 97 && event.charCode <= 122)) || (event.charCode >= 48 && event.charCode <= 57)' placeholder="name@example.com">
                    </div>
                    <div *ngIf="submitted && f.vendor_email.errors" class="invalid-feedback">
                        <div *ngIf="f.vendor_email.errors.required">Vendor Email is required</div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Mobile Number <span class="text-danger">*</span> </label>
                        <input type="text" class="form-control form-control-sm" formControlName="vendor_mobile_no"
                            id="vendor_mobile_no" [ngClass]="{ 'is-invalid': submitted && f.vendor_mobile_no.errors }"
                            onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' autocomplete="off"
                            maxlength="10" placeholder="Mobile Number">
                    </div>
                    <div *ngIf="submitted && f.vendor_mobile_no.errors" class="invalid-feedback">
                        <div *ngIf="f.vendor_mobile_no.errors.required">Mobile Number is required</div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">State <span class="text-danger">*</span></label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="state_id"
                                [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }" id="state"
                                (change)="locationChanged($event.target.value, 'district')">
                                <option value="">Select</option>
                                <option *ngFor="let state of stateList" [value]="state.id">{{state.state_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                <div *ngIf="f.state_id.errors.required">State is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">District <span class="text-danger">*</span> </label>
                        <select class="form-control form-control-sm" formControlName="district_id"
                            [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }"
                            (change)="locationChanged($event.target.value,'mandal')">
                            <option value="">Select</option>
                            <option *ngFor="let dist of districtList" [value]="dist.id">{{dist.district_name}}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                            <div *ngIf="f.district_id.errors.required">
                                District is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">City</label>
                        <input type="text" class="form-control form-control-sm" formControlName="city" id="city">
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Location <span class="text-danger">*</span></label>
                        <input type="text" class="form-control form-control-sm" autocomplete="off"
                            formControlName="location_name" id="location_name" maxlength="20" placeholder="Location"
                            [ngClass]="{ 'is-invalid': submitted && f.location_name.errors }">
                        <div *ngIf="submitted && f.location_name.errors" class="invalid-feedback">
                            <div *ngIf="f.location_name.errors.required">Location is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Pin Code <span class="text-danger">*</span></label>
                        <input type="text" class="form-control form-control-sm" autocomplete="off"
                            placeholder="Pin Code" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                            formControlName="pin_code" id="pin_code" maxlength="6"
                            [ngClass]="{ 'is-invalid': submitted && f.pin_code.errors}">
                        <div *ngIf="submitted && f.pin_code.errors" class="invalid-feedback">
                            <div *ngIf="f.pin_code.errors.required">Pin Code is required</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="control-label">Address <span class="text-danger">*</span></label>
                        <textarea type="text" class="form-control form-control-sm" autocomplete="off"
                            formControlName="address" id="address" maxlength="20" placeholder="Address"
                            [ngClass]="{ 'is-invalid': submitted && f.address.errors }">
                        </textarea>
                        <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                            <div *ngIf="f.address.errors.required">Address is required</div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Vaccination Minimum</label>
                        <input type="text" class="form-control form-control-sm" formControlName="vaccine_minimum"
                            placeholder="Vaccination Minimum">
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Vaccination Maximum</label>
                        <input type="text" class="form-control form-control-sm" formControlName="vaccine_maximum"
                            placeholder="Vaccination Maximum">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6">
                    <div class="col-md-12" style="margin-left: 2px;">
                        <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Vaccination'"
                            [ngModelOptions]="{standalone: true}" [data]="vaccineList" [(ngModel)]="selectedItems"
                            (onSelect)="onItemSelect($event,'add')" (onDeSelect)="onItemSelect($event,'delete')"
                            (onSelectAll)="onSelectAll($event,'add')" (onDeSelectAll)="onSelectAll($event,'delete')">
                        </ng-multiselect-dropdown>
                    </div>
                </div>
            </div>
            <div class="table-responsive" formArrayName="vaccine_mapping">
                <table class="table table-sm table-borderless small w-50" style="min-width:500px !important;">
                    <thead>
                        <tr>
                            <th scope="col" class="bg-light">Vaccine Name</th>
                            <th scope="col" class="bg-light">Stock</th>
                            <th scope="col" class="bg-light">Price</th>
                            <th scope="col" class="bg-light">&nbsp;&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let control of vaccinations().controls; let i=index" [formGroupName]="i">
                            <td class="align-middle">{{selectedItems[i].vaccine_name}}</td>
                            <td>
                                <div class="form-label-group outline">
                                    <input type="text" autocomplete="off" formControlName="available_stock"
                                        id="available_stock" maxlength="6" onpaste="return false"
                                        [ngClass]="{ 'is-invalid': submitted && control.get('available_stock').errors }"
                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                        class="form-control" placeholder="" />
                                    <div *ngIf="submitted && control.get('available_stock').errors"
                                        class="invalid-feedback d-block small">
                                        <div *ngIf="control.get('available_stock').errors.required">Stock
                                            is required
                                        </div>
                                    </div>

                                </div>
                            </td>
                            <td>
                                <div class="form-label-group outline">
                                    <input type="text" autocomplete="off" formControlName="price" id="available_stock"
                                        maxlength="6" onpaste="return false"
                                        [ngClass]="{ 'is-invalid': submitted && control.get('price').errors }"
                                        onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                        class="form-control" placeholder="">
                                    <div *ngIf="submitted && control.get('available_stock').errors"
                                        class="invalid-feedback d-block small">
                                        <div *ngIf="control.get('available_stock').errors.required">Stock
                                            is required
                                        </div>
                                    </div>
                                </div>
                            <td>
                                <a (click)="removeVaccine(i)" class="btn btn-sm btn-danger" data-bs-toggle="tooltip"
                                    data-bs-placement="top" title="Tooltip on top">
                                    <i class="fas fa-times"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <input type="submit" id="btnsubmit" style="display: none;" (click)="onSubmit($event)">
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" (click)="submitForm()" value="Submit">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>