<div class="row">
    <div class="container-fluid pl-0 pr-0">
      <div class="row">
        <div class="col-xl-12">
          <div class="card card-header-actions mx-auto" style="height:550px">
            <div class="card-header">
              Drug Dispense
            
            </div>
            <div class="">
              <div class="custom-control-inline custom-control custom-radio">
                <input checked class="custom-control-input" id="customRadio11" type="radio" name="is_active"
                  (change)="isShow=true;radioclick()">
                <label class="custom-control-label" for="customRadio11"><b>Drug Dispense Without Prescription</b></label>
              </div>
              <div class="custom-control-inline custom-control custom-radio">
                <input class="custom-control-input " id="customRadio22" type="radio" name="is_active"
                  (change)="isShow=false;radioclick2()">
                <label class="custom-control-label" for="customRadio22"><b>Drug Dispense With Prescription</b></label>
              </div>
            </div> <br />
            <div class="card-body" [hidden]="!isShow">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-12">
                                <div class="row mt-1">
                                    <!-- <div class="col-xl-2">
                                        <label>State <span class="text-danger"><b>*</b></span> </label>
                                        <select class="form-control form-control-sm" id="state" formControlName="state_code"
                                           >
                                            <option value=0>Select</option>
                                        </select>
                                    </div> -->
                                    <div class="col-xl-2">
                                      <div class="form-group">
                                        <label class="control-label">Patient List</label>
                                        <div style="width: 220px;">
                                          <!-- <select [(ngModel)]="drugname" (change)="drugchange(drugname)"
                                            class="form-control form-control-sm">
                                            <option value="">Select</option>
                                            <option *ngFor="let item of druglist" [value]="item.id">
                                              {{item.drug_name}}</option>
                                          </select> -->
                                          <ng-multiselect-dropdown [settings]="dropdownSettingspatient" [data]="patientList" (onSelect)="onItemSelectpatient($event)" (onDeSelect)="onItemSelectpatientD($event)"
                                          [placeholder]="'Select'"  [(ngModel)]="patientname" [ngModelOptions]="{standalone: true}"></ng-multiselect-dropdown>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-xl-2" *ngIf="drgtyphow">
                                      <div class="form-group">
                                        <label class="control-label" style="margin-left: 100px;width: 220px">Drug Type  </label>
                                        <div style="width: 220px;margin-left: 98px;">
                                          <ng-multiselect-dropdown [settings]="drugSettings"  [placeholder]="'Select'"
                         (onSelect)="onDrgSlct($event, 'dt')" (onDeSelect)="onDrgDeSlct('dt')"
                        [data]="drugTypelist" [(ngModel)]="dT">
                      </ng-multiselect-dropdown>
                                         
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-xl-2" *ngIf="drgnshow">
                                        <div class="form-group">
                                          <label class="control-label" style="margin-left: 188px;width: 220px">Drug Name  </label>
                                          <div style="width: 220px;margin-left: 188px;">
                                           
                                            <ng-multiselect-dropdown [settings]="dropdownSettingsdrugs" [data]="druglist" (onSelect)="onItemSelectreferal($event)" (onDeSelect)="onItemSelectreferalD($event)"
                                            [placeholder]="'Select'"  [(ngModel)]="drugname" [ngModelOptions]="{standalone: true}"></ng-multiselect-dropdown>
                                          </div>
                                        </div>
                                      </div>
                                 
                                </div> 
                        </div> 
                    </div>
                </div> 
              <div class="table-responsive" *ngIf="tableshow">
                <table datatable id="drugdispensetable" class="table table-striped table-bordered table-sm"
                  style="width:100%">
                  <thead class="text-center">
                    <tr>
                      <th>S No</th>
                      <th>Drug Name</th>
                      <th>Drug Type</th>
                      <th>Stock Count</th>
                      <th>Quantity</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let agent of tabledrugList;let i=index;">
                      <td class="text-center">{{i+1}}</td>
                      <td class="text-center">{{agent.drug_name}}</td>
                      <td class="text-center">{{agent.drug_type}}</td>
                      <td class="text-center">{{agent.stock}}</td>
                      <td class="text-center" >
                        <input type="text"   [(ngModel)]="agent.qty" id="qtyv" (ngModelChange)="validationcheckwop(agent)" onkeypress='return event.charCode >= 48 && event.charCode <= 57'  class="form-control" placeholder="" style="width:68px;" />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div class="col-xl-12 mt-3 text-center " >
                    <button type="submit" class="btn btn-primary mb-2 text-center" (click)="dispensesubmit()" [disabled]="wopbtn"
                     > Submit</button>
                   
                </div>
              </div>
            </div>
            <div class="card-body" [hidden]="isShow">
              <div class="container-fluid">
               
                  <div class="row">
                      <div class="col-xl-12">
                              <div class="row mt-1">
                                  <!-- <div class="col-xl-2">
                                      <label>State <span class="text-danger"><b>*</b></span> </label>
                                      <select class="form-control form-control-sm" id="state" formControlName="state_code"
                                         >
                                          <option value=0>Select</option>
                                      </select>
                                  </div> -->
                                  <div class="col-xl-2">
                                    <div class="form-group">
                                      <label class="control-label">Patient List</label>
                                      <div style="width: 220px;">
                                        <!-- <select [(ngModel)]="drugname" (change)="drugchange(drugname)"
                                          class="form-control form-control-sm">
                                          <option value="">Select</option>
                                          <option *ngFor="let item of druglist" [value]="item.id">
                                            {{item.drug_name}}</option>
                                        </select> -->
                                        <ng-multiselect-dropdown [settings]="dropdownSettingspatient" [data]="patientList" (onSelect)="onItemSelectpatientwp($event)" (onDeSelect)="onItemSelectpatientD($event)"
                                        [placeholder]="'Select'"  [(ngModel)]="patientname" [ngModelOptions]="{standalone: true}"></ng-multiselect-dropdown>
                                      </div>
                                    </div>
                                  </div>
                                  <!-- <div class="col-xl-2">
                                      <div class="form-group">
                                        <label class="control-label">Drug Name  </label>
                                        <div>
                                         
                                          <ng-multiselect-dropdown [settings]="dropdownSettingsdrugs" [data]="druglist" (onSelect)="onItemSelectreferaldrug($event)" (onDeSelect)="onItemSelectreferalD($event)"
                                          [placeholder]="'Select'"  [(ngModel)]="drugname" [ngModelOptions]="{standalone: true}"></ng-multiselect-dropdown>
                                        </div>
                                      </div>
                                    </div> -->
                               
                              </div> 
                      </div> 
                  </div>
              </div> 
              <div class="table-responsive" *ngIf="prvconstlist">
                <strong style="text-align: center;">Consultation Details</strong>
              <table datatable id="drugdispensetable" class="table table-striped table-bordered table-sm"
                style="width:100%">
                <thead class="text-center">
                  <tr>
                    <th>S No</th>
                    <th>Patient Name</th>
                    <th>Symptoms</th>
                    <th>Doctor Name</th>
                    <th>Appointment Date & Time</th>
                    <th>consultation_status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let patient of prvconstdata;let i=index;">
                    <td class="text-center">{{i+1}}</td>
                    <td class="text-center">{{patient.name}}</td>
                    <td class="text-center">{{patient.symptoms}}</td>
                    <td class="text-center">{{patient.doctor_name}}</td>
                    <td class="text-center" >{{patient.appointment_date}} {{patient.appointment_time}}</td>
                    <td class="text-center">{{patient.consultation_status}}</td>
                    <td><button  class="btn btn-sm btn-success" (click)="getconsultationid(patient.consultation_id)">Proceed</button></td>
                  </tr>
                </tbody>
              </table>
              <!-- <div class="col-xl-12 mt-3 text-center " >
                  <button type="submit" class="btn btn-primary mb-2 text-center" (click)="dispensemsubmit()"
                   > Submit</button>
                 
              </div> -->
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" data-backdrop="static" data-keyboard="false" style="z-index: 99999 !important;"
  id="dspmedlistM" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        Medicines Details
        <!-- <h5 class="modal-title" id="exampleModalLabel">Patient History</h5> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        
        <div>
            <div class="card-body" *ngFor="let med of prvconstdata;let i=index;"> 
            <!-- <div class="card mt-1 shadow cardrounded h-100"> -->
              <!-- <div class="card-header"><strong> Medicines Details </strong></div> -->
              <div *ngIf="i==0">
                <div class="form-row small">
                 
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Doctor Name :  {{med.doctor_name}}</label>
                  
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Patient Name : {{med.name}}</label>
                  
                    </div>
                  </div>
                  <div class="form-row small">
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Case Number : {{med.consultation_id}}</label>
                   
                  </div>
                  <!-- <div class="form-group col-md-6">
                    <label for="inputEmail4">Gender : {{med.Patient_Gender}}</label>
                  
                  </div> -->
              </div>
              <!-- <div class="form-row small">
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Mobile Number :  {{med.Patient_Mobile_Number}}</label>
            
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputEmail4"></label>
                    <div class="input_wrapper">
                
                    </div>
                  </div>
              </div> -->
             
              <!-- <button type="button" class="btn btn-sm btn-secondary" (click)="submitvitals()">Submit</button> -->
                 
              
              </div>
            </div>
          </div>
          <div class="table-responsive" style="margin-top: -175px;">
            <table datatable id="drugdispensetable" class="table table-striped table-bordered table-sm"
              style="width:100%">
              <thead class="text-center">
                <tr>
                  <th>S No</th>
                  <th>Material Name</th>
                  <th>Expiry Date & Time</th>
                  <th>Pharmacist/Healthcare Professional Stock</th>
                  <th>Doctor Suggested Quantity</th>
                  <th>Required Issue Quantity</th>
                  <!-- <th>Issue Quantity</th>
                  <th>Unit Price</th> -->
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let medd of prvconstprecrplistNew;let i=index;">
                  <td class="text-center">{{i+1}}</td>
                  <td class="text-center">{{medd.name}}</td>
                  <td class="text-center">{{medd.exp_date}}</td>
                  <td class="text-center">{{medd.agentStock}}</td>
                  <td class="text-center">{{medd.doctor_qty}}</td>
                  <td class="text-center" >
                    <input type="text" [disabled]="medd.agentStock==0"  [(ngModel)]="medd.disp_qty" onkeypress='return event.charCode >= 48 && event.charCode <= 57' (ngModelChange)="validationcheck(medd)"  class="form-control" placeholder="" style="width:68px;" />
                  </td>
                  <!-- <td class="text-center">{{medd.Issued_Quantity}}</td>
                  <td class="text-center">{{medd.Sale_Price}}</td> -->
                </tr>
              </tbody>
            </table>
            <div class="col-xl-12 mt-3 text-center " >
                <button type="submit" class="btn btn-primary mb-2 text-center" (click)="dispensemsubmitown()"
                 > Submit</button>
               
            </div>
          </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button> -->
        <!-- <button class="badge badge-dark" type="button" (click)="openVitalFileModal()">
            <i class="" aria-hidden="true"></i> <strong> View Vitals History</strong>
          </button> -->
      </div>
    </div>
  </div>
</div>
  </div>
