import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { Common } from 'src/app/common';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FamilyMemberRegistrationComponent } from '../../registration/family-member-registration/family-member-registration.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
@Component({
  selector: 'app-ehr-report',
  templateUrl: './ehr-report.component.html',
  styleUrls: ['./ehr-report.component.css']
})
export class EhrReportComponent implements OnInit {
  ehrFormRpt:FormGroup;lstbenHstry = [];benHistory = []; benHistoryRef = [];selectedData:any;ehrhistory:any=[];
  profile_photo: any; roleId:any;
  isPrintOnlyUpdated: boolean;
  vitalList: any[];
  is_self: boolean = true;ehrBack:boolean = false;
  documentHistory: any=[];
  ehrButton = true;
  docData: any;
  orgName: any;
  constructor(private formBuilder:FormBuilder,
    private registrationService: RegistrationService,
    private toastr: ToastrService,
    private router: Router,
    private modalService: NgbModal,
    private authSvc:AuthenticationService,
    public activeModal: NgbActiveModal
    ) { 
    }

  ngOnInit(): void {
    this.roleId = this.authSvc.roleId;
      this.docData = JSON.parse(sessionStorage.getItem('DoctorDetails'));

      this.orgName = sessionStorage.getItem('orgName');
      // console.log('Test Org',this.orgName);

    if( sessionStorage.getItem('is_self_ehr')==='1'){
      this.getBenHistoryDetails(sessionStorage.getItem('callingpatient_id'));
    } else {
      this.getMemBenHistoryDetails(sessionStorage.getItem('callingpatient_id'));
    }
    this.inItFormControls();
   
}
btnClick= function () {
  this.router.navigateByUrl('/home/patientList');
};

  inItFormControls(){
    this.ehrFormRpt = this.formBuilder.group({
      abhaid:[''],
      photo_path:[''],
      state: [''],
      district:[''],
      block:[''],
      Village:[''],
      centername:[''],
      name:[''],
      gender:[''],
      age:[''],
      mobile:[''],
      maritalStatus:[''] ,
      // isPrintOnlyUpdated:[false]

    });
  }
  
  getBenHistoryDetails(id) {
    this.lstbenHstry = [];
    this.registrationService.getehr(id).subscribe(res => {
    
      // if(res.data.ehrReport.length>0){
      //   this.selectedData=res.data.ehrReport[0];
      //   this.profile_photo=res.data.ehrReport[0].photo_path;
      // }else{
        this.selectedData=res.data.BeneficiaryDetails[0];
        this.profile_photo=this.orgName.url_path+res.data.BeneficiaryDetails[0].photo_path;
      // }
    
      this.ehrhistory=res.data.ehrReport;
      this.ehrhistory.map((res=>{
      if(this.docData!==null && res?.doctor_id==this.docData['id']){
        res.prescription= res.poc_file_path;
      } else if(this.roleId==2 || this.roleId==1){
        res.prescription= res.poc_file_path
      } else{
        res.prescription= null;
      }
      }
        ))
     
      this.documentHistory=res.data.documentReport;
     
        this.vitalList=res.data.vitalReport;
      this.vitalList.map((res=>{
        let hgt = (res.height/100)*(res.height/100);
        let bmi = res.weight/hgt;
        if(bmi<18.5){
        res.bmi='Under Weight';
        } if(bmi>=18.5 && bmi<=24.9){
          res.bmi='Normal';
        } if(bmi>=25 && bmi<=29.9){
          res.bmi='Over Weight';
        }if(bmi>=30.0){
          res.bmi='Very Over Weight'
        }
      }
        ))
      //Common.loadDataTable(this.ehrhistory, '#tablevitals');
      setTimeout(() => {
        this.bindData();
      }, 2000);
      
    }, err => { this.toastr.error(err) });

  //  this.getBenVitalDetails(id);
  }
  //Get New lines added for vitals New API Integration 09-04-2022
  getBenVitalDetails(id) {
   
   this.vitalList=[];
    this.registrationService.getVitals(id).subscribe(res => {
      
      // if (res.data.length > 0) {
      //   res.data.forEach((item,i) => {
      //     item.vital_history['visitdate']=item.visit_date;
      //   });
      //   this.benHistory = res.data;
      //   Common.loadDataTable(this.benHistory, '#tablevitals');
      // }
      // this.benHistoryRef = res.data[0];
      this.vitalList=res.data;
      this.vitalList.map((res=>{
        let hgt = (res.height/100)*(res.height/100);
        let bmi = res.weight/hgt;
        if(bmi<18.5){
        res.bmi='Under Weight';
        } if(bmi>=18.5 && bmi<=24.9){
          res.bmi='Normal';
        } if(bmi>=25 && bmi<=29.9){
          res.bmi='Over Weight';
        }if(bmi>=30.0){
          res.bmi='Very Over Weight'
        }
      }
        ))
      //Common.loadDataTable(this.ehrhistory, '#tablevitals');
      setTimeout(() => {
        this.bindData();
      }, 2000);
     
      // res.data.forEach(e => {
      //   let temp = e.beneficiary_visit_files;
      //   let resDgns = e.diagnosis.provisionalDiagnosis;
      //   var doc_id = e.doctor_id.id;
      //   //Object.keys(e)
      //   if (Object.keys(e).includes('prescribed_medications')) {
      //     e.prescribed_medications['doc_id'] = doc_id;
      //   }
      //   // let isStr = (typeof e.diagnosis?.provisionalDiagnois == 'string');
      //   if (resDgns.length > 0) {
      //     resDgns.forEach(rd => {
      //       this.lstbenHstry.push({ visit_date: e.visit_date, docCmnt: e.advice.doctorComment, dgns: rd?.diagnosis_name, document_path: temp });
      //     });
      //   }
      // });
    }, err => { this.toastr.error(err) });
  }
  //End of New line Vitals API Integration 

  getMemBenHistoryDetails(id) {
    this.lstbenHstry = [];
    this.registrationService.getmemberehr(id).subscribe(res => {
      // if(res.data.ehrReport.length>0){
      //   this.selectedData=res.data.ehrReport[0];
      //   this.profile_photo=res.data.ehrReport[0].photo_path;
      // }else{
        this.selectedData=res.data.BeneficiaryDetails[0];
        this.profile_photo=this.orgName.url_path+res.data.BeneficiaryDetails[0].photo_path;
      // }
      
 
       this.ehrhistory=res.data.ehrReport;

      
       this.ehrhistory.map((res=>{
        if(this.docData!==null && res?.doctor_id==this.docData['id']){
          res.prescription= res.poc_file_path;
        } else if(this.roleId==2 || this.roleId==1){
          res.prescription= res.poc_file_path
        } else{
          res.prescription= null;
        }
        }
          ))
  
      for(let i=0;i<this.ehrhistory.length;i++){
        for(let j=0;j<this.documentHistory.length;j++){
              if(this.ehrhistory[i].beneficiary_id ==this.documentHistory[j].beneficiary_id && this.ehrhistory[i].visit_date == this.documentHistory[j].visit_date){
                this.ehrhistory[i].prescription=this.documentHistory[j].lab_report;
              }
      }
    }
        this.vitalList=res.data.vitalReport;
        this.vitalList.map((res=>{
          let ht= (res.height/100)*(res.height/100);
          let bmi = res.weight/ht;
          if(bmi<18.5){
          res.bmi='Under Weight';
          } if(bmi>=18.5 && bmi<=24.9){
            res.bmi='Normal';
          } if(bmi>=25 && bmi<=29.9){
            res.bmi='Over Weight';
          }if(bmi>=30.0){
            res.bmi='Very Over Weight'
          }
         
        }
          ))
      //Common.loadDataTable(this.ehrhistory, '#tablevitals');
      setTimeout(() => {
        this.bindData();
      }, 2000);
     
     
    }, err => { this.toastr.error(err) });

    this.getBenVitalDetails(id);
  }
  openWindow(poc_file){
    window.open(poc_file, '_blank', 'left=300,top=50,width=700,height=600,toolbar=1,resizable=0');
    
  }
  bindData(){
    this.ehrFormRpt.patchValue({
     abhaid:this.selectedData.health_id,
     state:this.selectedData.state_name,
     district:this.selectedData.district_name,
     centername:this.selectedData.center_name,
     name:this.selectedData.dependent_id && this.selectedData.dependent_id!==null && this.selectedData.dependent_id!==''?this.selectedData.dependent_name:this.selectedData.beneficiary_name,
     gender:this.selectedData.gender,
     age:this.selectedData.age,
     mobile:this.selectedData.mobile,
     maritalStatus:this.selectedData.marital_status
    })
  }
  isChngPrintOnlyUpdated(){
  this.isPrintOnlyUpdated = !this.isPrintOnlyUpdated;
  }
  printEhr(){
    window.print();
  }
 
  closeEhr(){

  }

  onBack() {
    //sushma 08-07-2022 hide back button
   let data = JSON.parse(localStorage.getItem('ehrback'));
   if(data==true)
   {
    // this.ehrBack = true;
    this.router.navigate(['home/patientList']);
   }
   
    // else if (id ==2 && this.roleId==7 || this.roleId==2){
    else {  
      // this.ehrBack = false;
      const modalRef = this.modalService.open(FamilyMemberRegistrationComponent, { scrollable: true, size: 'xl' });
     // console.log(modalRef);
      // modalRef.componentInstance.
    }
    // else{
    //   this.router.navigate(['home/patientList']);
    // }
  }
}
