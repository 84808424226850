import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors, FormControl } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/shared/services/master.service';
import { Common } from 'src/app/common';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ApartmentStatusComponent } from '../apartment-status/apartment-status.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseComponent } from 'src/app/shared/base.component';
import * as XLSX from 'xlsx';
declare let $: any;

@Component({
  selector: 'app-apartment',
  templateUrl: './apartment.component.html',
  styleUrls: ['./apartment.component.css'],
  providers: [DatePipe]
})
export class ApartmentComponent extends BaseComponent implements OnInit {
  title = "Apartment Status";
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  statusList: Array<any> = [];
  apartmentForm: FormGroup;
  submitted = false;
  from_date: any = new Date();
  to_date: any = new Date();
  max_date = new Date();
  minDate: Date;
  minToDate: Date;
  loadgrid: Array<any> = [];
  fileName = 'Apartment list.xlsx';
  constructor(private formBuilder: FormBuilder
    , private masterService: MasterService
    , private datePipe: DatePipe
    , private toastr: ToastrService
    , private regService: RegistrationService
    , private modalService: NgbModal) {
    super();
  }


  ngOnInit(): void {
    this.getStatus();
    this.initformcontrols();
    this.LoadData();
    this.locationChanged('', 'state');
  }

  openModal(DataEdit: any = null) {
    const modalRef = this.modalService.open(ApartmentStatusComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = this.title;
    modalRef.componentInstance.DataEdit = DataEdit;
    modalRef.result.then(result => {
      if (result == 'changes saved') {
        this.LoadData();
      }
    }, (reason) => { });
  }

  initformcontrols() {
    this.apartmentForm = this.formBuilder.group({
      state_id: [''],
      district_id: [''],
      from_date: [new Date()],
      to_date: [new Date()],
      status_id: ['']
    });
  }

  get f() { return this.apartmentForm.controls; }

  LoadData() {
    let payload = {
      "state_id": this.apartmentForm.get('state_id').value,
      "district_id": this.apartmentForm.get('district_id').value,
      "from_date": this.datePipe.transform(this.apartmentForm.value.from_date, 'yyyy-MM-dd'),
      "to_date": this.datePipe.transform(this.apartmentForm.value.to_date, 'yyyy-MM-dd'),
      "status_id": this.apartmentForm.get('status_id').value
    };
    this.regService.getSocietySearch(payload).subscribe(resp => {
      this.loadgrid = [];
      if (resp.status == 'OK') {
        if (resp.data == null || resp.data.length == 0)
          this.toastr.info('No records available');
        else {
          this.loadgrid = resp.data;
          // console.log(this.loadgrid)
        }
        Common.loadDataTable(this.loadgrid, '#aprtmentTable');
      }
    })
  }


  locationChanged(id: any, locationType: string) {
    id = id.value;
    let query: string = '';
    let locations: any = {
      'state': () => {
        this.districtList = [];
        // if (+id > 0)
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      },
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations: any = {
          'state': () => this.stateList = arr,
          'district': () => this.districtList = arr.districts,
        };
        assign_locations[type]();
      }
      this.districtList.sort((a,b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error.message));
  }

  getStatus() {
    this.masterService.getAll('status/getall').subscribe(res => {
      this.statusList = res.data;
    }, error => this.toastr.error(error.message));
  }

  onFromDateChange(val: Date) {
    if (val) {
      let to_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.apartmentForm.get('to_date').setValue(new Date(to_date.setDate(to_date.getDate())));
      this.minDate = to_date;
    }
  }
  exportexcel(): void {
    var table = $('#aprtmentTable').DataTable();
    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {	
      / table id is passed over here /
      let element = document.getElementById('aprtmentTable');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };

      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
  }


}
