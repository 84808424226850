<input type="button" id="btnMissCallCount" class="d-none" (click)="GetMisCalData()">
<div>
  <nav [hidden]="!isDoctor" class="topnav navbar navbar-expand shadow navbar-light bg-white" id="sidenavAccordion">
    <a class="navbar-brand" [routerLink]="">
      <img [src]="logo" class="img-fluid brand_logo" />
    </a>
    <button class="btn btn-icon btn-transparent-dark order-1 order-lg-0 mr-lg-2" id="sidebarToggle">
      <i class="fa fa-bars"></i></button>
    <!-- <button data-toggle="modal" data-placement="top" title="Chat" data-toggle="modal" data-target="#chatmodel" style="margin-left: 34rem;"
      [style.background-color]="style1 ? 'red' : (style2 ? 'blue' : null)" class="btn btn-blue btn-icon float-right"> <i
        class="fas fa-comment"></i>
    </button> -->
    <ul class="navbar-nav align-items-start" *ngIf="roleId==1 && isGlblOrgShow">
      <li class="list-inline-item mr-3">
        <div class="form-group mb-0">
          <label for="homeOrg mb-0 font-weight-bold"
            style="margin-bottom: 0px !important;font-weight: bold !important;">Organization</label>
          <select class="form-control form-control-sm text-uppercase" id="homeOrg"
            (change)="onOrgChng($event.target.value)">
            <option class="text-uppercase" *ngFor="let org of orgList" [value]="org.id">{{org.orgnization_name}}
            </option>
          </select>
        </div>
      </li>
    </ul>
    <ul class="navbar-nav align-items-center ml-auto">
      <li class="list-inline-item pointer" title="Missed Call" (click)="onMsdCal()" style="color: #3291d4"
        *ngIf="+roleId==7"><i class="fa fa-phone fa-1x" aria-hidden="true"></i>
        <span class="text-danger" style="font-weight: bold;" *ngIf="msdCalVal!=0"> ({{msdCalVal}})</span>
      </li>
      <li class="list-inline-item d-none d-sm-none d-md-block">
        <p class="text-primary mb-0"><strong>Welcome, {{userName}}<span *ngIf="roleId==7">({{hub_name}})</span> </strong></p>
        <p>
          <span class="mb-0 text-right"><strong>({{role_name}}{{lcnsPlanName}})</strong></span>
        </p>
      </li>
      <li class="nav-item dropdown no-caret mr-3 dropdown-user d-none d-sm-none d-md-block"> <a
          class="btn btn-icon btn-transparent-dark dropdown-toggle" id="navbarDropdownUserImage"
          href="javascript:void(0);" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <img class="img-fluid" src="../../../assets/images/ph60.png" />
          <!-- <img class="img-fluid" src="https://via.placeholder.com/60" /> -->
        </a>
        <div class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up"
          aria-labelledby="navbarDropdownUserImage">
          <!-- <h6 class="dropdown-header d-flex align-items-center"> <img class="dropdown-user-img rounded"
              src="https://source.unsplash.com/QAB-WJcbgJk/60x60" />
            <div class="dropdown-user-details">
              <div class="dropdown-user-details-name">Valerie Luna</div>
              <div class="dropdown-user-details-email">vluna@aol.com</div>
            </div>
          </h6> -->
          <!-- <a class="btn btn-sm " [routerLink]="['/home/changepassword']"><i class="fa fa-key"></i>&nbsp; &nbsp; Change Password</a> -->
          <a class="btn btn-sm " (click)="openModal()"><i class="fa fa-key"></i>&nbsp; &nbsp; Change Password</a>
          <div class="dropdown-divider"></div>
          <a class="btn btn-sm " (click)="onUsrPrfl()"><i class="fa fa-user"></i>&nbsp; &nbsp; User Profile</a>
          <div class="dropdown-divider"></div>
          <!-- <a class="nav-link" >
            <div class="dropdown-item-icon"><i data-feather="settings"></i></div>Change  Password </a><a class="dropdown-item" >
            <div class="dropdown-item-icon" (click)="logOut()"><i data-feather="log-out"></i></div> Logout
          </a> -->
          <a (click)="logOut()" class="btn btn-sm pointer"><i class="fa fa-sign-out-alt"></i>&nbsp; &nbsp; Logout</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item dropitemalign"><i class="fa fa-code-fork" aria-hidden="true"></i>Version
            {{version}}</a>
        </div>
      </li>
    </ul>
  </nav>
  <nav *ngIf="isDoctor" class="navbar navbar-expand  navbar-light bg-white mt-5 d-block d-sm-block d-md-none"
    id="sidenavAccordion">
    <ul class="navbar-nav align-items-right small pt-1 pb-1">
      <!-- <li class="nav-item  no-caret mr-3 text-dark">
        <a href=""  style="color: #000000 !important;">Uma Mahesh (Doctor)</a>     
    </li> -->
      <li class="list-inline-item text-right">
        <p class="text-primary mb-0"><strong>Welcome, {{userName}} <span *ngIf="roleId==7">({{center_type}})</span>, ({{role_name}}) </strong></p>
        <!-- <span class="text-info subcenter">Yashoda Hospital, Hyderbad</span> -->
      </li>
      <li class="nav-item dropdown no-caret mr-3 dropdown-user"> <a class="btn p-0 dropdown-toggle"
          id="navbarDropdownUserImage" href="javascript:void(0);" role="button" data-toggle="dropdown"
          aria-haspopup="true" aria-expanded="false">
          <!-- <img class="img-fluid" src="https://via.placeholder.com/60"/>  -->
          <i class="fa fa-angle-down text-dark"></i>
        </a>
        <div class="dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up"
          aria-labelledby="navbarDropdownUserImage">
          <!-- <h6 class="dropdown-header d-flex align-items-center"> <img class="dropdown-user-img rounded"
              src="https://source.unsplash.com/QAB-WJcbgJk/60x60" />
            <div class="dropdown-user-details">
              <div class="dropdown-user-details-name">Valerie Luna</div>
              <div class="dropdown-user-details-email">vluna@aol.com</div>
            </div>
          </h6> -->
          <!-- <a class="btn btn-sm " [routerLink]="['/home/changepassword']"><i class="fa fa-key"></i>&nbsp; &nbsp; Change Password</a> -->
          <a class="btn btn-sm " (click)="openModal()"><i class="fa fa-key"></i>&nbsp; &nbsp; Change Password</a>
          <div class="dropdown-divider"></div>
          <a class="btn btn-sm " (click)="onUsrPrfl()"><i class="fa fa-user"></i>&nbsp; &nbsp; User Profile</a>
          <div class="dropdown-divider"></div>
          <!-- <a class="nav-link" >
            <div class="dropdown-item-icon"><i data-feather="settings"></i></div>Change  Password </a><a class="dropdown-item" >
            <div class="dropdown-item-icon" (click)="logOut()"><i data-feather="log-out"></i></div> Logout
          </a> -->
          <a (click)="logOut()" class="btn btn-sm pointer"><i class="fa fa-sign-out-alt"></i>&nbsp; &nbsp; Logout</a>
        </div>
      </li>
    </ul>
  </nav>
  <!-- Doctor -->
  <div *ngIf="!isDoctor">
    <nav
      class="navbar navbar-expand navbar-dark fixed-top headerbg pt-0 pb-0 pl-0 shadow-sm d-none d-sm-none d-md-block">
      <a class="navbar-brand pt-0 pb-0" ><img [src]="logo1" /></a>
      <!-- <button class="navbar-toggler bg-success" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation"> <span class="navbar-toggler-icon"></span> </button> -->
      <div class="collapse navbar-collapse float-right" id="navbarCollapse">
        <ul class=" "></ul>
        <div class="navbar-collapse m-auto navbar-nav menufont">
          <div class="navbar-collapse m-auto ">
            <ul class="nav navbar ml-auto list-inline">
              <li class="list-inline-item"><span class="text-white">Welcome,</span> <span class="text-success">
                  <strong>{{userName}}<span *ngIf="roleId==7">({{center_type}})</span> ({{role_name}}{{lcnsPlanName}})</strong></span><br>
                <!-- <span class="text-info subcenter">Yashoda Hospital, Hyderbad</span> -->
              </li>
              <li class="list-inline-item nav-item dropdown"> <a  class="dropdown-toggle" id="navDropDownLink"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <img
                    src="../../../assets/images/ph60.png" class="user"> </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navDropDownLink"> <a
                    class="dropdown-item dropitemalign" (click)="fromPOCUserProfile()" ><i class="fas fa-user"> </i>User Profile</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item dropitemalign pointer" (click)="logOut()">
                    <i class="fas fa-sign-out-alt fontopacity"></i> Logout</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item dropitemalign"><i class="fas fa-code-fork"></i>Version {{version}}</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <div class="fixed-top headerbg pt-0 pb-0 pl-0 shadow-sm d-block d-sm-block d-md-none">
      <div class="row">
        <div class="col-12"> <a class="navbar-brand pt-0 pb-0" ><img [src]="logo1" class="img-fluid" /></a>
        </div>
        <div class="col-12">
          <div class="navbar-collapse m-auto ">
            <ul class="nav navbar ml-auto list-inline">
              <li class="list-inline-item" style="font-size:12px;"><span class="text-white">Welcome,</span> <span
                  class="text-success">
                  <strong>{{userName}}<span *ngIf="roleId==7">({{center_type}})</span> ({{role_name}})</strong></span><br>
                <!-- <span class="text-info subcenter">Yashoda Hospital, Hyderbad</span> -->
              </li>
              <li class="list-inline-item nav-item dropdown"> <a  class="dropdown-toggle" id="navDropDownLink"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> <img
                    src="../../../assets/images/ph60.png" class="user"> </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navDropDownLink"> <a
                    class="dropdown-item dropitemalign" ><i class="fas fa-user"> </i>
                    Profile</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item dropitemalign pointer" (click)="logOut()"><i
                      class="fas fa-sign-out-alt fontopacity"></i>
                    Logout</a>
                  <div class="dropdown-divider"></div>
                  <a class="dropdown-item dropitemalign"><i class="fas fa-code-fork"></i>Version {{version}}</a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="layoutSidenav">
    <div *ngIf="isDoctor" id="layoutSidenav_nav">
      <nav class="sidenav shadow-right sidenav-light">
        <div class="sidenav-menu mt-3">
          <div class="nav accordion" id="accordionSidenav">
            <a class="nav-link collapsed" href="javascript:void(0);" data-toggle="collapse"
              data-target="#collapseLayouts" aria-expanded="false" aria-controls="collapseLayouts"
              *ngIf="moduleList[0]?.canView && ptientListHide && docConsultationHide">
              <div class="nav-link-icon" ngbTooltip="Consultation done by me"><i data-feather="Consultation"></i></div> Consultation
              <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
            </a>
            <div class="collapse" [ngClass]="{'show':+roleId==7}" id="collapseLayouts" data-parent="#accordionSidenav"
              *ngIf="moduleList[0]?.canView">
              <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavLayout">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/patientList']" (click)="shMenu()" *ngIf="!isReferral && ptientListHide">
                  <i class="fas fa-users"></i> &nbsp; Patients List </a>
                <a class="nav-link" routerLinkActive="active" ngbTooltip="Consultation done by me" [routerLink]="['/home/consultation']" id="btnMenuItem"
                  *ngIf="setPrivilege(Screen_Name.Consultation, Permission.View) && docConsultationHide" (click)="shMenu()">
                  <i class="fas fa-user-md"></i> &nbsp; Doctor Consultation</a>
                <a class="nav-link" routerLinkActive="active" ngbTooltip="Video and Audio Appointment Schedules" [routerLink]="['/home/appointment']"
                  *ngIf="setPrivilege(Screen_Name.AppointmentSchedule, Permission.View) && !isReferral" (click)="shMenu()">
                  <i class="far fa-calendar-check"></i> &nbsp; Appointments Scheduled </a>
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/payment']"
                  *ngIf="setPrivilege(Screen_Name.PaymentStatus, Permission.View) && !isReferral" (click)="shMenu()"> <i
                    class="fas fa-money-check-alt"></i> &nbsp; Payment Status</a>
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/support']"
                  *ngIf="+roleId>1 && setPrivilege(Screen_Name.Support, Permission.View) && !isReferral" (click)="shMenu()"> <i
                    class="fas fa-question"></i> &nbsp; Support</a>
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/transaction']"
                  *ngIf="+roleId>1 && !isReferral && setPrivilege(Screen_Name.TransactionStatus, Permission.View)" (click)="shMenu()">
                  <i class="fa fa-exchange"></i> &nbsp; Transaction Status Report</a>
                <a class="nav-link" routerLinkActive="active" ngbTooltip="Availability Calender" [routerLink]="['/home/doctorList']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.DoctorRegistrationwithSlotscheduling, Permission.View) && +roleId==7 && !isReferral">
                  <i class="fa fa-user-md"></i> &nbsp; Doctor Slots</a>
                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/favorite']">
                   <i class="fas fa-question"></i> &nbsp; Favorite</a> -->
                <!-- *ngIf="+roleId>1" -->
                <a class="nav-link" routerLinkActive="active" *ngIf="isLcl && !isReferral" [routerLink]="['/home/poc']"><i
                    class="fas fa-clinic-medical"></i> POC</a>
               
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/agentlistcall']"
                  *ngIf="!isReferral && setPrivilege(Screen_Name.HASession, Permission.View)">
                  <i class="fas fa-user-md">&nbsp;</i> &nbsp; Pharmacist/Healthcare Professional Sessions</a>
                  <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/referalpatientlist']"
                  *ngIf="setPrivilege(Screen_Name.ReferralPatientList, Permission.View) && isReferral">
                  <i class="fas fa-users"></i> &nbsp; Referral Patient List</a>
                  <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/drugdispense']"
                  >
                  <i class="fas fa-capsules"></i> &nbsp; Drug Dispense</a>
a class="nav-link" routerLinkActive="active" [routerLink]="['/home/attendanceCapturing']"
                  >
                  <i class="fas fa-file-invoice"></i> &nbsp; Attendance Capturing</a>
                  <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/viewUpdateStock']" (click)="shMenu()"
                  ><i class="fas fa-capsules"></i>
                  &nbsp;
                View And Update Stock</a> -->
                  <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/medicinesdetails']"
                  >
                  <i class="fas fa-capsules"></i> &nbsp; Medecines Details</a> -->
                <!-- <a class="nav-link" routerLinkActive="active" *ngIf="isLcl" [routerLink]="['/home/screens']">Screens</a>
                <a class="nav-link" routerLinkActive="active" *ngIf="isLcl" [routerLink]="['/home/eTest']">Test</a> -->
                <!-- <a class="nav-link" routerLinkActive="active" *ngIf="isLcl" [routerLink]="['/home/MP']">mUpload</a> -->
              </nav>
            </div>
            <a class="nav-link collapsed" href="javascript:void(0);" data-toggle="collapse"
              data-target="#collapseMasters" aria-expanded="false" aria-controls="collapseMasters"
              *ngIf="moduleList[1]?.canView && +roleId!=7 && roleId !=null && !isReferral">
              <div class="nav-link-icon"><i data-feather="Masters"></i></div>
              Registrations
              <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
            </a>
            <div class="collapse" [ngClass]="{'show':+roleId==1}" id="collapseMasters" data-parent="#accordionSidenav"
              *ngIf="moduleList[1]?.canView">
              <nav class="sidenav-menu-nested nav accordion" id="accordionSidenavLayout">
                <a class="nav-link" routerLinkActive="active" ngbTooltip="HUB Registration" [routerLink]="['/home/hubs']"
                      *ngIf="setPrivilege(Screen_Name.HubRegistration, Permission.View)"  (click)="shMenu()"> <i
                        class="fas fa-user-tie"></i> &nbsp; Hub Registration</a>
                        <a class="nav-link" routerLinkActive="active" ngbTooltip="Hospital Registration" [routerLink]="['/home/hospitalDetailList']"
                  *ngIf="setPrivilege(Screen_Name.HospitalRegistration, Permission.View)" (click)="shMenu()"> <i
                    class="fas fa-hospital"></i> &nbsp; Hospital
                  Registration</a>
                  <a class="nav-link" routerLinkActive="active" ngbTooltip="Centre Registration" [routerLink]="['/home/centers']"
                  *ngIf="setPrivilege(Screen_Name.CenterRegistration, Permission.View) && menuItems.isVle" (click)="shMenu()"> <i
                    class="fas fa-user-tie"></i> &nbsp; Centre Registration</a>

                <a class="nav-link" routerLinkActive="active" ngbTooltip="Doctor Registration" [routerLink]="['/home/doctorList']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.DoctorRegistrationwithSlotscheduling, Permission.View)">
                  <i class="fa fa-user-md"></i> &nbsp; Doctor Registration</a>
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/pharmacyList']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.PharmacyRegistration, Permission.View) && pharmacyRegistartionMenuInAdmin"> <i class="fa fa-pills"></i>
                  &nbsp; Pharmacy Registration</a>
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/labList']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.LabRegistration, Permission.View) && labRegistrationMenuInAdmin"> <i class="fas fa-vials"></i>
                  &nbsp; Lab Registration</a>
                
                <a class="nav-link" routerLinkActive="active" ngbTooltip="Pharmacist/Healthcare Professional Registration" [routerLink]="['/home/agent']"
                  *ngIf="setPrivilege(Screen_Name.AgentDetails, Permission.View)" (click)="shMenu()"> <i
                    class="fas fa-user-tie"></i> &nbsp; Pharmacist/Healthcare Professional Registration</a>
             
                      
                      <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/hub-coordinator']"
                      *ngIf="setPrivilege(Screen_Name.AgentDetails, Permission.View)" (click)="shMenu()"> <i
                        class="fas fa-user-tie"></i> &nbsp; Hub-Coordinator Registration</a> -->
                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/vendor']" (click)="shMenu()"> <i
                    class="fas fa-user-tie"></i> &nbsp; Vendor Registration</a> -->
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/inActiveList']"
                  *ngIf="setPrivilege(Screen_Name.ActiveInActiveList, Permission.View)" (click)="shMenu()"> <i
                    class="fas fa-toggle-on"></i> &nbsp;Active/Inactive
                  List</a>
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/patientList']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.PatientList, Permission.View) && ptientListHide"><i class="fas fa-list-ul"></i> &nbsp;
                  Patient List</a>
              </nav>
            </div>
            <a class="nav-link collapsed" href="javascript:void(0);" data-toggle="collapse"
              *ngIf="moduleList[2]?.canView && !isReferral" data-target="#collapseComponents" aria-expanded="false"
              aria-controls="collapseComponents">
              <div class="nav-link-icon"><i data-feather="package"></i></div>
              Administration
              <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
            </a>
            <div class="collapse" id="collapseComponents" data-parent="#accordionSidenav"
              *ngIf="moduleList[2]?.canView">
              <nav class="sidenav-menu-nested nav">
                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/aprtment']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.ApartmentAdminApproval, Permission.View)"><i
                    class="fas fa fa-building"></i>
                  &nbsp; Apartment Approval</a> -->
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/roleList']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.RoleManagement, Permission.View)"><i class="fas fa-users-cog"></i>
                  &nbsp; Role Management</a>
                <a class="nav-link" routerLinkActive="active" ngbTooltip="User Management Create/Edit" [routerLink]="['/home/userList']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.UserManagement, Permission.View)"><i class="fas fa-user-friends"></i>
                  &nbsp; User Management</a>
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/requestref']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.RefundRequest, Permission.View)"><i class="fas fa fa-exchange"></i>
                  &nbsp; Refund Request</a>
              </nav>
            </div>
            <!-- need to fix dynamic enable disable master menu #chandu 07-06-2022 -->
            <!-- <a class="nav-link collapsed" href="javascript:void(0);" data-toggle="collapse"
              data-target="#collapseUtilities" aria-expanded="false" aria-controls="collapseUtilities"
              *ngIf="moduleList[3]?.canView && !isReferral">
              <div class="nav-link-icon"><i data-feather="Administration"></i></div>Master
              <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
            </a> -->
            <div class="collapse" id="collapseUtilities" data-parent="#accordionSidenav" *ngIf="moduleList[3]?.canView && !isReferral">
              <nav class="sidenav-menu-nested nav">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/nodal']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.NodalOfficer, Permission.View)">
                  <i class="fas fa-question"></i> &nbsp; Nodal Officer</a>
                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/couponList']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.CouponManagement, Permission.View)"><i class="fas fa-gift"></i> &nbsp;
                  Coupon</a> -->
                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/companyList']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.CompanyRegistration, Permission.View)"><i class="fas fa-building"></i>
                  &nbsp; Company</a> -->
                <a class="nav-link" routerLinkActive="active"
                  *ngIf="setPrivilege(Screen_Name.ReferralDiscount, Permission.View)" [routerLink]="['/home/refrl']"
                  (click)="shMenu()"> <i class="fas fa-users"></i> &nbsp; Referral Discount</a>
                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/promotionList']"
                  *ngIf="setPrivilege(Screen_Name.PromotionManagement, Permission.View)"><i class="fas fa-tags"></i>
                  &nbsp; Promotion</a> -->
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/drugsList']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.DrugMaster, Permission.View)"><i class="fas fa-capsules"></i> &nbsp;
                  Drug Type</a>

                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/drugsStateList']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.AddDrugsToState, Permission.View)  "><i class="fas fa-capsules"></i>
                  &nbsp;
                  Add Drugs to State</a>

                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/drugsAgentList']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.AddDrugsToAgent , Permission.View) "><i class="fas fa-capsules"></i>
                  &nbsp;
                  Add Drugs to Agent</a> -->


                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/drugTypeMappingList']"
                  *ngIf="setPrivilege(Screen_Name.DrugMaster, Permission.View)"><i class="fas fa-capsules"></i> &nbsp;
                  Drug Mapping</a> -->
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/labInvestigationList']"
                  *ngIf="setPrivilege(Screen_Name.LabInvestigationMaster, Permission.View) " (click)="shMenu()"><i
                    class="fas fa-microscope"></i> &nbsp; Lab Investigation</a>
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/enrlEmpList']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.EnrollCorporateEmployee, Permission.View)">
                  <i class="fas fa-users"></i> &nbsp;Enroll Corp. Employees
                </a>
                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/serviceslist']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.service, Permission.View)">
                  <i class="fab fa-servicestack"></i> &nbsp;Services
                </a> -->
                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/stateList']"><i class="fas fa-city"></i>  &nbsp; State
                </a> -->
                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/districtList']">District</a>
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/mandalList']">Mandal</a> -->
                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/labInvestigationList']">City</a>
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/stateList']">State</a>
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/districtList']">District</a>
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/labInvestigationList']">District</a>
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/labInvestigationList']">Mandal</a>
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/labInvestigationList']">City</a>
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/labInvestigationList']">Village</a> -->
              </nav>
            </div>
            <a class="nav-link collapsed" href="javascript:void(0);" data-toggle="collapse"
              data-target="#configurationSettings" aria-expanded="false" aria-controls="configurationSettings"
              *ngIf="moduleList[4]?.canView && !isReferral">
              <div class="nav-link-icon"><i data-feather="admin"></i></div>
              Organization Setting
              <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
            </a>
            <div class="collapse" id="configurationSettings" data-parent="#accordionSidenav"
              *ngIf="moduleList[4]?.canView">
              <nav class="sidenav-menu-nested nav">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/organizationList']"
                  (click)="shMenu()" *ngIf="setPrivilege(Screen_Name.Orgnization, Permission.View)"> <i
                    class="fa fa-hospital"></i> &nbsp;
                  Organization</a>
                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/supporter']">Support</a> -->
                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/logo']">Upload Logo</a> -->
                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/prescription']">Customise
                  Prescription</a> -->
                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/paymentGateway']">Payment
                  Gateway</a> -->
                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/sms']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.Configuration, Permission.View)"> <i class="fas fa-cogs"></i>
                  &nbsp; Configuration</a> -->
                  <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/settings']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.TenantConfigurations, Permission.View)" >
                   <i class="fas fa-cogs"></i> &nbsp;Tenant Configurations
                 </a>
                <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/consentForms']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.FormTemplate, Permission.View)"><i class="fas fa-columns"></i> &nbsp;
                  Form Templates</a> -->
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/orgPlans']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.LicensePlan, Permission.View)"> <i class="fas fa-sitemap"></i>
                  &nbsp;License Plans
                </a>
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/drtmplt']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.DoctorModule, Permission.View)">
                  <i class="fas fa-star"></i> &nbsp;Doctor Modules
                </a>
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/corplanList']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.CorporatePlan, Permission.View)">
                  <i class="fas fa-globe"></i> &nbsp;Corporate Plans
                </a>
              </nav>
            </div>
            <!-- <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/dashboard']">
              <i class="fas fa-columns"></i> &nbsp;Dash Board</a> -->
              <a class="nav-link collapsed" href="javascript:void(0);" data-toggle="collapse"
              data-target="#inventory" aria-expanded="false" aria-controls="inventory"  *ngIf="hideInventoryMenuInAdmin && moduleList[7]?.canView && !isReferral"
             >
              <div class="nav-link-icon"><i data-feather="admin"></i></div>
            Inventory
              <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
            </a>
            <div class="collapse" id="inventory" data-parent="#accordionSidenav" *ngIf="moduleList[7]?.canView"
           >
            <nav class="sidenav-menu-nested nav">
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/drugsStateList']" (click)="shMenu()"
            *ngIf="setPrivilege(Screen_Name.AddDrugsToState, Permission.View)  "><i class="fas fa-capsules"></i>
            &nbsp;
            Add Drugs To State</a>
  
        <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/drugsAgentList']" (click)="shMenu()"
        *ngIf="setPrivilege(Screen_Name.AddDrugsToAgent , Permission.View) "><i class="fas fa-capsules"></i>
        &nbsp;
       Add Drugs To Pharmacist/Healthcare Professional</a>
       
            </nav>
            </div>
            <!-- <a class="nav-link collapsed" href="javascript:void(0);" data-toggle="collapse"
            data-target="#referral" aria-expanded="false" aria-controls="referral"  *ngIf="moduleList[7]?.canView"
           >
            <div class="nav-link-icon"><i data-feather="admin"></i></div>
          Referral
            <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
          </a>
          <div class="collapse" id="referral" data-parent="#accordionSidenav" *ngIf="moduleList[7]?.canView"
         >
          <nav class="sidenav-menu-nested nav">
       

          </nav>
          </div> -->
            <a class="nav-link collapsed" href="javascript:void(0);" data-toggle="collapse" data-target="#reports"
              aria-expanded="false" aria-controls="reports" *ngIf="moduleList[6]?.canView && !isReferral">
              <div class="nav-link-icon"><i data-feather="admin"></i></div>
              Reports
              <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
            </a>
            <div class="collapse" id="reports" data-parent="#accordionSidenav" *ngIf="moduleList[6]?.canView">
              <nav class="sidenav-menu-nested nav">
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/diagnostics']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.DiagnosticReport, Permission.View)">
                  <i class="fas fa-file"></i> &nbsp;Diagnostics Reports
                </a>
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/otcmed']" (click)="shMenu()"
                  *ngIf="setPrivilege(Screen_Name.OtcReport, Permission.View)">
                  <i class="fas fa-file"></i> &nbsp;OTC Medicines
                </a>
                <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/dssreport']" (click)="shMenu()"
                *ngIf="setPrivilege(Screen_Name.DssReport, Permission.View)">
                <i class="fa fa-receipt"></i> &nbsp;DSS Report
              </a>
              <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/atndncreport']" (click)="shMenu()"
            >
              <i class="fa fa-receipt"></i> &nbsp;Attendance Report
            </a>
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/assesmentreport']" (click)="shMenu()"
            >
              <!-- <i class="fas fa-file-invoice"></i> &nbsp;Assessment Report -->
              <i class="fa fa-receipt"></i> &nbsp;Assets Report
            </a>
            <!-- new line added for hanover form report 22-07-2022 #salman -->
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/handoverformreport']" (click)="shMenu()"
            >
              <!-- <i class="fas fa-file-invoice"></i> &nbsp;Assessment Report -->
              <i class="fa fa-receipt"></i> &nbsp;HandOver Form Report
            </a>
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/networkmonthlystatusreport']" (click)="shMenu()"
            >
              <!-- <i class="fas fa-file-invoice"></i> &nbsp;Assessment Report -->
              <i class="fa fa-receipt"></i> &nbsp;Network Monthly Report
            </a>

            <!-- new lines added for network status report 29-08-2022-->
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/networkstatusreport']" (click)="shMenu()"
            >
              <i class="fa fa-receipt"></i> &nbsp;Network Status Report</a>
              <!-- <i class="fas fa-file-invoice"></i> &nbsp;Network Status Report
            </a>
            <a class="nav-link" routerLinkActive="active" [routerLink]="['/home/networkmonthlystatusreport']" (click)="shMenu()"> -->
              <!-- <i class="fas fa-file-invoice"></i> &nbsp;Assessment Report -->
              <!-- <i class="fas fa-file-invoice"></i> &nbsp;Network Monthly Status Report
            </a> -->
            <!-- new lines ended 29-08-2022 -->
             </nav>
            </div>
          
            <a class="nav-link collapsed" href="javascript:void(0);" data-toggle="collapse" data-target="#help"
              aria-expanded="false" aria-controls="help" *ngIf="+roleId==1 || +roleId==2 || +roleId==7 || roleId == null && !isReferral">
              <div class="nav-link-icon"><i data-feather="admin"></i></div>Tutorials
              <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
            </a>
            <div class="collapse" id="help" data-parent="#accordionSidenav">
              <nav class="sidenav-menu-nested nav">
                <span *ngIf="!isNIG">
                  <!-- <a class="nav-link pointer" [href]='d1Vdo' target="_blank" *ngIf="+roleId==2 || +roleId==7">
                    <i class="fas fa-play"></i> &nbsp; Home Screen</a>
                  <a class="nav-link pointer" [href]='d2Vdo' target="_blank" *ngIf="+roleId==2 || +roleId==7">
                    <i class="fas fa-play"></i> &nbsp; Video Consultation</a>
                  <a class="nav-link pointer" [href]='adminUMpath' target="_blank" *ngIf="+roleId==2">
                    <i class="fa fa-file"></i> &nbsp; Admin User Manual</a>
                  <a class="nav-link pointer" [href]='docUMpath' target="_blank" *ngIf="+roleId==2 || +roleId==7">
                    <i class="fa fa-file"></i> &nbsp; Doctor User Manual</a>
                  <a class="nav-link pointer" routerLinkActive="active" (click)="onManual()" *ngIf="false">
                    <i class="fas fa-play"></i> &nbsp; Demo Video</a>
                  <a class="nav-link pointer" [href]='docPathUM' target="_blank" *ngIf="false">
                    <i class="fa fa-file"></i> &nbsp; User Manual</a> -->
                    <a class="nav-link pointer" [href]='tutorialUrl' target="_blank">
                      <i class="fa fa-file help">Help</i></a>
                </span>
                <!-- <span *ngIf="isNIG || isLcl">
                  <a class="nav-link pointer" (click)="openECMnl('ecSAUM')" *ngIf="+roleId==1">
                    <i class="fas fa-file"></i> &nbsp; Super Admin User Manual</a>
                  <a class="nav-link pointer" (click)="openECMnl('ecADUM')" *ngIf="+roleId==1 || +roleId==2">
                    <i class="fas fa-file"></i> &nbsp; Admin User Manual</a>
                  <a class="nav-link pointer" *ngIf="(+roleId==1 || +roleId==2 || +roleId==7) && isEclnc"
                    (click)="openECMnl('ecDRUM')"> <i class="fa fa-file"></i> &nbsp; Doctor User Manual</a>
                  <a class="nav-link pointer" *ngIf="(+roleId==1 || +roleId==2 || +roleId==7) && isSMR"
                    (click)="openECMnl('smDRUM')"> <i class="fa fa-file"></i> &nbsp; Doctor User Manual</a>
                  <a class="nav-link pointer" *ngIf="(+roleId==1 || +roleId==2 || +roleId==7) && isKMP"
                    (click)="openECMnl('kpDRUM')"> <i class="fa fa-file"></i> &nbsp; Doctor User Manual</a>
                  <a class="nav-link pointer" (click)="openECMnl('ecSAVD')" *ngIf="+roleId==1">
                    <i class="fas fa-play"></i> &nbsp; Super Admin Tutorial</a>
                  <a class="nav-link pointer" (click)="openECMnl('ecADVD')" *ngIf="+roleId==1 || +roleId==2">
                    <i class="fas fa-play"></i> &nbsp; Admin Tutorial</a>
                  <a class="nav-link pointer" *ngIf="(+roleId==1 || +roleId==2 || +roleId==7) && isEclnc"
                    (click)="openECMnl('ecDRVD')"> <i class="fas fa-play"></i> &nbsp; Doctor Tutorial</a>
                  <a class="nav-link pointer" *ngIf="(+roleId==1 || +roleId==2 || +roleId==7) && isSMR"
                    (click)="openECMnl('smDRVD')"> <i class="fas fa-play"></i> &nbsp; Doctor Tutorial</a>
                  <a class="nav-link pointer" *ngIf="(+roleId==1 || +roleId==2 || +roleId==7) && isKMP"
                    (click)="openECMnl('kpDRVD')"> <i class="fas fa-play"></i> &nbsp; Doctor Tutorial</a>
                </span> -->
                <!-- <a class="nav-link pointer" target="_blank" href="assets/demo/AgentModule_BRD.doc" download>
                  <i class="fa fa-file"></i> &nbsp; User Manual</a> -->
                <!-- <a class="nav-link pointer" (click)="umDocDwnld()"><i class="fa fa-file"></i> &nbsp; User Manual</a> -->
              </nav>
            </div>
            <!-- NDHM Notification new menu added for admin 10-04-2023 -->
             
            <a class="nav-link collapsed" href="javascript:void(0);" data-toggle="collapse" data-target="#ndhm"
            aria-expanded="false" aria-controls="help" *ngIf="+roleId==1 || +roleId==2">
            <div class="nav-link-icon"><i data-feather="admin"></i></div>NDHM Notification
            <div class="sidenav-collapse-arrow"><i class="fas fa-angle-down"></i></div>
          </a>
          <div class="collapse" id="ndhm" data-parent="#accordionSidenav">
            <nav class="sidenav-menu-nested nav">
              <span *ngIf="!isNIG">
              
                  <a class="nav-link pointer"  [routerLink]="['/home/ndhmNotification']">NDHM Notification</a>
              </span>
            </nav>
          </div>

            <!-- NDHM Notification end -->
            <div *ngIf = "isUkt && nameArray && nameArray?.length !=0 && roleId ==7">
            <h1 class="ml-5"> Chat List</h1> 
            <div class="ml-3" id="chatalign" *ngFor="let item of nameArray;let ind=index;">
              <span style="margin-left: 20px;font-size: medium;" (click)="benId(item.beneficiary_id,item.patient_name)" *ngIf="item.usertype !='Doctor' && item.doctor_id == drData.id">{{item.patient_name}} <span data-toggle="modal" data-placement="top" title="Chat" data-toggle="modal" data-target="#chatmodel1"><i class="fa fa-paper-plane" aria-hidden="true"></i></span></span>
            </div>
            </div>
          </div>
          <div class="collapse" id="collapseFlows" data-parent="#accordionSidenav">
            <nav class="sidenav-menu-nested nav"><a class="nav-link" href="multi-tenant-select.html">Multi-Tenant
                Registration</a></nav>
          </div>
        </div>
      </nav>
    </div>
    <!-- <div id="layoutSidenav_content" [ngClass]="{'padding-left':isDoctor?'15rem':'0rem'}"> -->
    <div id="layoutSidenav_content" [ngClass]="!isDoctor?'padding1':'padding2'">
      <main>
        <div *ngIf="isDoctor" class="page-header pb-10 page-header-dark bg-gradient-primary-to-secondary">
          <div class="container-fluid">
            <div class="page-header-content">
              <h1 class="page-header-title d-block">
                <div class="page-header-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" stroke-width="2"
                    stroke-linejoin="round" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-linecap="round"
                    class="feather feather-activity">
                    <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                  </svg>
                </div>
                <span>Welcome to {{clientName}}</span>
                <span class="float-xl-right float-lg-right float-md-right mx-auto mr-0 mb-2" *ngIf="isShowAPK">
                  <!-- <input type="text" value="https://apps.apple.com/us/app/dhanush-vidmed/id1516571105/eshwar" #apple hidden> -->
                  <span class="d-none d-sm-none d-md-none d-lg-none d-xl-block">
                    <img src="./../../../assets/vmLogos/apk_pics/app_store.png" title="Click to Copy"
                      class="pointer mr-1" (click)="onAPK('a')"> <!-- onPS , apple -->
                    <img src="./../../../assets/vmLogos/apk_pics/play_store.png" title="Click to Copy"
                      class="pointer mr-1" (click)="onAPK('g')">
                  </span>
                </span>
              </h1>
              <div class="d-block d-sm-block d-md-block d-lg-block d-xl-none mb-2">
                <img src="./../../../assets/vmLogos/apk_pics/app_store.png" title="Click to Copy" class="pointer mr-1"
                  (click)="onAPK('a')"> <!-- onPS , apple -->
                <img src="./../../../assets/vmLogos/apk_pics/play_store.png" title="Click to Copy" class="pointer mr-1"
                  (click)="onAPK('g')">
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid " style="margin-top: -7rem ;">
          <router-outlet></router-outlet>
        </div>
      </main>
      <footer class="footer mt-auto footer-light mb-5" id="footerHome">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-6 small"> <span *ngIf="!isNIG">Copyright {{current_year}} &copy;Dhanush InfoTech. All
                rights reserved</span> </div> <!-- {{clientName}} -->
            <div class="col-md-6 small">
              <div class="supportHome">
                <h6>For Support, Please contact {{clientName}}</h6>
                <div class="text-right"><a class="btn btn-info" (click)="onTollFree()">
                    <i class="fa fa-phone" aria-hidden="true"></i>&nbsp;&nbsp;{{tollFree}}</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</div>
<button id="logOut" style="display: none;" (click)="logOut(true)"></button>
<div class="modal fade" data-backdrop="static" data-keyboard="false" style="z-index: 99999 !important;" id="chatmodel"
  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div style="margin-left: 340px;">
          <b>Chat</b>
        </div>

        <button type="button" id="closeid" class="close"  (click)="close1()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div id="chatalign" *ngFor="let item of nameArray;let ind=index;">
        <span style="margin-left: 20px;font-size: medium;" (click)="benId(item.beneficiary_id,item.patient_name)" *ngIf="item.usertype !='Doctor'">{{item.patient_name}} <span data-toggle="modal" data-placement="top" title="Chat" data-toggle="modal" data-target="#chatmodel1"><i class="fa fa-paper-plane" aria-hidden="true"></i></span></span>
      </div>


    </div>
  </div>
</div>
<div class="modal fade" data-backdrop="static" data-keyboard="false" style="z-index: 99999 !important;" id="chatmodel1"
  tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div style="margin-left: 340px;">
          <b>Chat</b>
        </div>

        <button type="button" id="closeid" class="close"  (click)="close()" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ng-container id="chatalign" *ngFor="let item of chatArray;let ind=index;">
        <!-- <span style="margin-left: 20px;font-size: medium;" *ngIf="item.beneficiary_id == benificiary_id || item.usertype == 'Doctor'">{{item.message}}</span> -->
        <p  class="patstyle rounded-pill" *ngIf="item.beneficiary_id == benificiary_id && item.usertype != 'Doctor' " id="chatShow"><b>{{item.usertype}}:</b>&nbsp;&nbsp;{{item.message}}</p>
        <!-- <span *ngIf="item.usertype == 'Patient'"><br></span>  -->
        <p  class="docstyle rounded-pill" *ngIf="item.usertype == 'Doctor' && item.beneficiary_id == benificiary_id && item.doctor_id == drData.id" id="chatShoww"><b>{{item.usertype}}:</b>&nbsp;&nbsp;{{item.message}} </p>
        <!-- <span *ngIf="item.usertype == 'Doctor'"><br></span>  -->
        <!-- <span>{{msgDate | date:'dd-MM-yyyy HH:mm a'}}</span> -->
      </ng-container>
      <form (ngSubmit)="sendMessage($event)">
        <div class="modal-footer">
          <div class='col-md-5'>
            <div class="form-group">
              <div class="input_wrapper2">
                <textarea  cols="4" rows="4"  id="search_string" name="search_string" type="text" [(ngModel)]="messagechat" autocomplete="off" 
                class="form-control" placeholder="Chat Here"></textarea>
                <!-- <input id="search_string" name="search_string" type="text" [(ngModel)]="messagechat" autocomplete="off" 
                  class="form-control" placeholder="Chat Here"> -->
                <div class="unit" *ngIf="messagechat !=''">
                  <button type="submit" class="btn btn-sm btn-primary"><i class="fa fa-paper-plane" aria-hidden="true"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
  <button type="button" style="display: none;" id="btn_logOut" (click)="forceLogout()"></button>
</div>