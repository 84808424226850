import { Component, OnInit,Input } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Common } from 'src/app/common';
import { BaseComponent } from 'src/app/shared/base.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormControl } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service'
declare let $: any;
declare let checkboxuncheck:any;
@Component({
  selector: 'app-assessment-management',
  templateUrl: './assessment-management.component.html',
  styleUrls: ['./assessment-management.component.css']
})
export class AssessmentManagementComponent implements OnInit {
  isShow = true;
  companyList: Array<any>=[];
  companyList1: Array<any>=[];
  companyList2: Array<any>=[];
  corporateList: any;
  OrgId: string;
  date= new Date();
  form: any;remarksshow=false;qrcodetxt='';remrks='';payload:any;assesckeckList:any;viewassespayload:any;checkqrcode;
  asset:any;
  constructor(private userservice: UserService,
    private toastr: ToastrService,
    private registrationService: RegistrationService) { }

  ngOnInit(): void {
    this.getcompanyByorgid();
    this.getassesmntChecklistdata();
  }
  getcorporatebyorgid(orgId: any) {
    (<HTMLInputElement>document.getElementById('vrfy')).checked=false;
  }
  getcompanyByorgid() {
   
    this.registrationService.getassessment().subscribe(res => {
      // this.getAllcenters=data.data;
      if(res.status === 'ACCEPTED') {
       if (res.data == null || res.data.length == 0){
       this.companyList=[]
         this.toastr.info(res.message);
       }
       else
         this.companyList = res.data[0]["Spoke for 4G Sim Connectivity"];
         this.companyList1=res.data[1]["Spoke for RF Connectivity"];
         this.companyList2= res.data[2]["Spoke for VSA Connectivity"]
       Common.loadDataTable(this.companyList, '#companyTable');
     }
     else
       this.companyList = [], Common.loadDataTable(this.companyList, "#companyTable");
     })
  }
    
  

  onNativeChange($event:any,item:any) { // here e is a native event
    item.checked=false;
    checkboxuncheck();
    this.checkqrcode=item.qr_code;
    if($event.target.checked){
      item.checked=true;
      this.viewassespayload=[ {			
        "id": 0,
         "created_by": sessionStorage.getItem('userroleA'),
         "deleted":"false",
         "agent_id":sessionStorage.agentId,
         "checked":"false",
         "assessment_id":item.id,
         "remarks":this.remrks,
         "reported":"false",
         "user_id":sessionStorage.getItem('agentUserId'),
         "user_name":sessionStorage.getItem('userchatname'),
         "uk_user_type_id":2,
         "qr_code":this.qrcodetxt
         
       }]
      this.remarksshow=true;
      this.viewassespayload;
      // alert("hey popup is working");
      $('#myModal').modal();
    }
  }
  assestdataget(data:any){
    this.qrcodetxt='';
this.payload={
	"id":0,
	"user_id": sessionStorage.getItem('agentUserId'),
	"assessment_id": data.id,
	"assessment_name":data.assessment_name,
	"agent_id": sessionStorage.agentId,
	"qr_code": this.qrcodetxt,
	"is_scanned":true
}

  }
  regAsset(){
    this.payload.qr_code=this.qrcodetxt;
    this.registrationService.registerAsset(this.payload).subscribe(res => {
      if(res.data==null){
        this.toastr.error(res.message)
      }else{
        this.toastr.success(res.message)
        $('#myModal').modal('hide');
      }
      this.qrcodetxt='';
      this.payload=null;
      })
  }
  getassesmntChecklistdata(){
    this.registrationService.getassetmntchecklist().subscribe(res => {
      // this.getAllcenters=data.data;
      if(res.status === 'ACCEPTED') {
       if (res.data == null || res.data.length == 0){
       this.assesckeckList=[];
       Common.loadDataTable(this.assesckeckList, '#asseschecklist');
         this.toastr.info(res.message);
       }
       else
         this.assesckeckList = res.data;
       Common.loadDataTable(this.assesckeckList, '#asseschecklist');
     }
     else
       this.companyList = [], Common.loadDataTable(this.assesckeckList, "#asseschecklist");
     })
  }
  viewregAsset(){
    this.viewassespayload[0].qr_code=this.qrcodetxt;
    this.viewassespayload[0].remarks=this.remrks;
    if(this.viewassespayload[0].qr_code !=this.checkqrcode){
      this.toastr.error('Please enter the valid qr text code')
      return;
    }
    this.registrationService.saveviewregisterAsset(this.viewassespayload).subscribe(res => {
      if(res.data==null){
        this.toastr.error(res.message)
      }else{
        this.toastr.success(res.message)
        $('#myModal').modal('hide');
      }
      this.qrcodetxt='';
      this.payload=null;
      })
  }
  assetcancel(){
    //this.asset.checked=false;
    checkboxuncheck();
    (<HTMLInputElement>document.getElementById('vrfy')).checked=false;
  }
}
