<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        {{modalTitle}} List
                        <button *ngIf="setPrivilege(Screen_Name?.LicensePlan, Permission.Add)" hidden
                            class="btn btn-blue btn-icon float-right" (click)="onPlanModal()">
                            <i class="fa fa-plus"></i>
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table datatable id="planTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th style="width:7%;" class="singleLine">S No </th>
                                        <th class="singleLine">Plan</th>
                                        <th>Hospitals</th>
                                        <th>Doctors</th>
                                        <th>Patients</th>
                                        <th>Labs</th>
                                        <th>Pharmacies</th>
                                        <!-- <th>Health Care Worker</th> -->
                                        <th>Pharmacist/Healthcare Professional</th>
                                        <th class="text-center" style="width:12%;">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let plan of lstPlans;let i=index;">
                                        <td class="singleLine text-center">{{i+1}}</td>
                                        <td class="singleLine text-uppercase">{{plan?.license_plan}}</td>
                                        <td>{{plan?.hospital_count}}</td>
                                        <td>{{plan?.doctor_count}}</td>
                                        <td>{{plan?.patient_count == null ? 'Unlimited' : plan?.patient_count}}</td>
                                        <td>{{plan?.lab_count}}</td>
                                        <td>{{plan?.pharmacy_count}}</td>
                                        <td>{{plan?.agent_count}}</td>
                                        <td class="singleLine text-center">
                                            <span>
                                                <a *ngIf="setPrivilege(Screen_Name?.LicensePlan, Permission.Edit)" title="Edit"
                                                    (click)="onPlanModal(plan)" class="badge badge-info badge-pill">
                                                    <i class="fa fa-edit text-white pointer"></i></a>&nbsp;
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>