import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Common } from 'src/app/common';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { UserService } from 'src/app/shared/services/user.service';
import { environment } from 'src/environments/environment';
declare let $: any;

@Component({
  selector: 'app-center-registration-form',
  templateUrl: './center-registration-form.component.html',
  styleUrls: ['./center-registration-form.component.css']
})
export class CenterRegistrationFormComponent implements OnInit {
  @Input() isEditable: boolean = false;
  dropdownSettings_language = {};
  cntrCtrlsForm: FormGroup; minDt = new Date(); minToDt = new Date(); maxDt = new Date(); maxDtDOB = new Date();
  submitted = false; cntrItem: any; isAgntShow = false; cntrCode = ''; vcId = 0; isAct = '1';
  lstgrid = []; locationType_list; pincode_list = []; area_list = []; city_list = []; division_list = []; district_list = []; block_list = []; grampanchayat_list = [];
  paymentlist = []; userlist = []; role_list = []; lstData = []; panchayat_list = []; village_list = []; fileLst = []; centr: any;
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  mandalList: Array<any> = [];
  cityList: Array<any> = [];
  villageList: Array<any> = [];
  isNIG = environment.isNIG; HospTypeList: Array<any> = []; isrefChecked: any
  isGNM = environment.isGNM; isSAGE = environment.isSAGE;
  paymenttypes: any; dropdownSettings = {}; selectedItems: Array<any> = []; hubsList = [];
  centerHospitals: any = [];
  hospitalrefdataNew: any[];
  dropdownSettingsreferalNew: {};
  paymentArr: Array<{ created_by: string, is_active: true, payment_per_head: null, payment_type_id: null }> = [];
  firstname: any; lastname: any; username; newpaymenttypeid: any; maxLngth = ''; mobPtrn = ''; mobErrMsg = ''; getAllgaps: any; getallgapregpayload: any;
  orgid: any; hospitalrefdata: any; dropdownSettingsreferal = {}; selectedItemsref: Array<any> = []; arrrefer = []; newarrA = []; distidhospital: any; arrefer = []; hospid: any;
  constructor(private formBuilder: FormBuilder
    , public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , private toastr: ToastrService
    , private masterService: MasterService
    , private authServ: AuthenticationService
    , private registrationService: RegistrationService
    , private router: Router
    , private authSvc: AuthenticationService
    , private userService: UserService
  ) {
    this.minToDt.setDate(this.minToDt.getDate());
    this.firstname = JSON.parse(sessionStorage.getItem('firstname'))
    this.lastname = JSON.parse(sessionStorage.getItem('lastname'))
    this.username = this.firstname + ' ' + this.lastname;
    this.orgid = JSON.parse(sessionStorage.getItem('orgid'))
  }


  ngOnInit(): void {
    this.getallgapregpayload = {
      "state_id": 0,
      "district_id": 0,
      "location_type_id": 0,
      "mandal_id": 0
    }
    this.authServ.mobPtrnSubject.subscribe(s => this.mobPtrn = s),
      //this.authServ.mobErrMsgSubject.subscribe(s => this.mobErrMsg = s),
      this.authServ.mobErrMsgSubject.subscribe(s => this.mobErrMsg = s),
      this.authServ.maxLngthSubject.subscribe(s => this.maxLngth = s),
      this.initFormControls(); this.defaultLocations(); this.defaulthospitalref();
    this.locationChanged('', 'state');
    if (this.registrationService.centerregeditobj == undefined) {
      //  this.locationChanged('36', 'district');//QA Pointing
     this.locationChanged('5', 'district');//Training Pointing
    }

    //sushma 29-06-2022 set referal doctor value in edit mode
    Promise.all([this.getAllMasters(),
    this.getAllHospTypes(),
    this.getallHubsGrid(),
    this.getallhospitalRef(),
    this.getAllCenterHospital(),
    this.radioclick('true'),
    this.LoadData()]).then(() => {


      if (this.registrationService.centerregeditobj != undefined) {
        if (this.registrationService.centerregeditobj.is_active == true) {
          this.isAct = '1';
        }
        else {
          this.isAct = '0';
        }
        this.cntrCtrlsForm.value.state_id = this.registrationService.centerregeditobj.state_id;
        // if(this.registrationService.centerregeditobj.is_state==true){
        //   this.distidhospital=0;
        // }else{
        //   this.distidhospital=this.registrationService.centerregeditobj.district_id;
        // }
        //this.distidhospital=this.registrationService.centerregeditobj.district_id; chandu
        this.cntrCtrlsForm.value.district_id = this.registrationService.centerregeditobj.district_id;
        // this.getallhospitalRef();
        this.locationChanged(this.registrationService.centerregeditobj.state_id, 'district')
        this.locationChanged(this.registrationService.centerregeditobj.district_id, 'mandal')
        this.locationChanged(this.registrationService.centerregeditobj.mandal_id, 'village')
        this.locationChanged(this.registrationService.centerregeditobj.district_id, 'city')

        setTimeout(() => {
          for (let i = 0; i < this.registrationService.centerregeditobj.center_referral_hospital_mapping?.length; i++) {
            let id_ct = this.registrationService.centerregeditobj.center_referral_hospital_mapping[i].referral_hospital_id + '-' + this.registrationService.centerregeditobj.center_referral_hospital_mapping[i].hospital_type_id;
            var name = this.hospitalrefdataNew?.find(f => f.id_concat == id_ct)?.hospital_name_concat ?? '';
            let obj = {
              id_concat: id_ct,
              hospital_name_concat: name
            }
            this.selectedItemsref.push(obj);
            this.selectedItemsref = [...this.selectedItemsref];
            // this.selectedItemsref.push(id_concat=this.registrationService.centerregeditobj.center_referral_hospital_mapping[i].referral_hospital_id+'-'+this.registrationService.centerregeditobj.center_referral_hospital_mapping[i].hospital_type_id);
            this.arrrefer.push({
              is_active: true,
              referral_hospital_id: parseInt(this.registrationService.centerregeditobj.center_referral_hospital_mapping[i].referral_hospital_id),
              hospital_type_id: parseInt(this.registrationService.centerregeditobj.center_referral_hospital_mapping[i].hospital_type_id)
              //referral_hospital_id
              //hospital_type_id
              //hospital_name:item.hospital_name

            })
          }
        }, 3000);



        // this.cntrCtrlsForm.value.center_referral_hospital_mapping=this.arrrefer;
        this.cntrCtrlsForm.value.center_referral_hospital_mapping = this.selectedItemsref;
        this.cntrCtrlsForm.patchValue({
          id: this.registrationService.centerregeditobj.id,
          center_name: this.registrationService.centerregeditobj.center_name,
          created_by: this.registrationService.centerregeditobj.created_by,
          district_id: this.registrationService.centerregeditobj.district_id,
          city_id: this.registrationService.centerregeditobj.city_id,
          incharge_mobile_no: this.registrationService.centerregeditobj.incharge_mobile_no,
          incharge_name: this.registrationService.centerregeditobj.incharge_name,
          is_active: this.registrationService.centerregeditobj.is_active,
          location_type_id: this.registrationService.centerregeditobj.location_type_id,
          mandal_id: this.registrationService.centerregeditobj.mandal_id,
          state_id: this.registrationService.centerregeditobj.state_id,
          village_id: this.registrationService.centerregeditobj.village_id,
          hospital_type_id: this.registrationService.centerregeditobj.hospital_type_id,
          hub_id: this.registrationService.centerregeditobj.hub_id,
          is_referral: this.registrationService.centerregeditobj.is_referral,
          latitude: this.registrationService.centerregeditobj.latitude,
          longitude: this.registrationService.centerregeditobj.longitude,
          center_id: this.registrationService.centerregeditobj.center_hospital_mapping[0].hospital_id,
        })
        setTimeout(() => {
          this.getallgapregpayload.state_id = this.registrationService.centerregeditobj.state_id;
          this.getallgapregpayload.district_id = this.registrationService.centerregeditobj.district_id;
          this.isrefChecked = this.registrationService.centerregeditobj.is_referral
          // this.getallgapregpayload.mandal_id=this.registrationService.centerregeditobj.mandal_id;
          // this.getallgapregpayload.location_type_id=this.registrationService.centerregeditobj.location_type_id;
          // this.getallgapreg();
        }, 500);
        setTimeout(() => {
          if (this.registrationService.centerregeditobj.center_hospital_mapping != null) {
            this.cntrCtrlsForm.patchValue({
              //gap_id: this.registrationService.centerregeditobj.gap_id,
              center_hospital_mapping: this.registrationService.centerregeditobj.center_hospital_mapping[0].hospital_id
            })
          }
        }, 1000);
      }
    })
  }

  defaultLocations() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'payment_type_id',
      textField: 'payment_type',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableCheckAll: false,
      itemsShowLimit: 4,
      allowSearchFilter: true
    };
  }
  initFormControls() {
    this.cntrCtrlsForm = this.formBuilder.group({
      center_code: [0, Validators.required],
      id: [0],
      center_name: ['', Validators.required],
      location_type_id: ['', Validators.required],
      //  state_id: [36, Validators.required],//QA Pointing
     state_id: [5, Validators.required],//Trainig
      district_id: ['', Validators.required],
      mandal_id: [''],
      village_id: [''],
      city_id: [''],
      hub_id: ['', Validators.required],
      latitude: [''],
      longitude: [''],
      incharge_name: ['', Validators.required],
      incharge_mobile_no: ['', [Validators.required, Validators.pattern(this.mobPtrn)]],
      is_active: [true],
      is_state: [false],
      is_referral: [false],
      created_by: [this.username],
      center_hospital_mapping: [
      ],
      hospital_type_id: [''],
      centermapping: [''],
      center_id: ['', Validators.required],

    });
  }

  locationChanged(id: string, locationType: string) {
    if (this.registrationService.centerregeditobj == undefined && this.cntrCtrlsForm.value.center_code == 0) {
      this.selectedItemsref = [];
      this.arrrefer = [];
      // this.cntrCtrlsForm.value.center_hospital_mapping=[];
    }
    else {
      // setTimeout(() => {
      //       if(this.registrationService.centerregeditobj.district_id !=this.cntrCtrlsForm.value.district_id && this.cntrCtrlsForm.value.district_id !=''){
      //        if(this.registrationService.centerregeditobj.center_hospital_mapping !=null){
      //       for (let i = 0; i <  this.registrationService.centerregeditobj.center_hospital_mapping.length; i++) {
      //         this.registrationService.centerregeditobj.center_hospital_mapping[i].is_active=false;
      //         for (let j = 0; j <  this.cntrCtrlsForm.value.center_hospital_mapping.length; j++) {
      //           this.cntrCtrlsForm.value.center_hospital_mapping[j].is_active=false;
      //         }
      //       }

      //       this.selectedItemsref=[];
      //       this.cntrCtrlsForm.patchValue({
      //         is_state:false
      //       })
      //       this.registrationService.centerregeditobj.is_state=false;
      //     //}
      // //  },1500);
      //     }
      //   }
    }
    if (locationType == 'district') {
      this.cntrCtrlsForm.patchValue({
        district_id: ''
      })
    }
    if (this.registrationService.centerregeditobj != undefined && this.registrationService.centerregeditobj.state_id == this.cntrCtrlsForm.value.state_id) {
      this.cntrCtrlsForm.value.state_id = this.registrationService.centerregeditobj.state_id;
      if (this.registrationService.centerregeditobj.is_state == true) {
        this.distidhospital = 0;
      } else {
        this.distidhospital = this.registrationService.centerregeditobj.district_id;
      }
      this.distidhospital = this.registrationService.centerregeditobj.district_id;
    } else {
      this.distidhospital = this.cntrCtrlsForm.value.district_id;
    }

    if (locationType == 'mandal' || locationType == 'city') {
      this.cntrCtrlsForm.patchValue({
        mandal_id: ''
      })
      if (this.registrationService.centerregeditobj != undefined) {
        if (this.registrationService.centerregeditobj.is_state == false) {
          this.distidhospital = id;
        }
      }
      //if(this.registrationService.centerregeditobj==undefined){
      // this.getallhospitalRef();
      // }
    }
    this.cntrCtrlsForm.value.gap_id = null;
    if (id != '') {
      // this.getallgapreg();
    }
    let query: string = '';
    let locations = {
      'location': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        this.cntrCtrlsForm.get('district_id').setValue('');
        this.cntrCtrlsForm.get('mandal_id').setValue('');
        this.cntrCtrlsForm.get('village_id').setValue('');
        this.cntrCtrlsForm.get('city_id').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          //  query = `getstatebyid?stateId=36`;// QA pointing
         query = `getstatebyid?stateId=5`;//Training
        // query = 'getallstates';
        this.cntrCtrlsForm.get('mandal_id').setValue('');
        this.cntrCtrlsForm.get('city_id').setValue('');
      },
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
        this.cntrCtrlsForm.get('village_id').setValue('');
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
      this.districtList.sort((a, b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error));
  }
  // getAllMasters() {
  //   const locationType = this.masterService.getAll('getalllocationtypes');
  //   const hospital = this.registrationService.getAll('hospital/getallhospitalrecords');
  //   const pharmacy = this.registrationService.getAll('pharmacy/getallrecords');
  //   const bankName = this.masterService.getAll('getallmasterbankdetails');
  //   forkJoin([locationType, hospital, pharmacy, bankName]).subscribe({
  //     next: res => {
  //       res.forEach((list, ind) => {
  //         if (list.status === 'OK') {
  //           if (list.data == null || list.data.length == 0)
  //             console.log('No records available');
  //           else {
  //             const assignList = {
  //               '0': () => this.locationTypeList = list.data,
  //               // '1': () => this.hospitalList = list.data,
  //               // '2': () => this.pharmacyList = list.data,
  //               // '3': () => this.bankNamesList = list.data,
  //             }
  //             assignList[ind]();
  //           }
  //         }
  //       });
  //     },
  //     error: err => console.error('something wrong occurred: ', err),
  //    // complete: () => { this.editPharmacy(); }
  //   });
  // }
  getAllMasters() {
    this.masterService.getAll('getalllocationtypes').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info(res.message);
        else
          this.locationTypeList = res.data;
      }
    });
    // locationType.subscribe({})
  }
  getAllHospTypes() {
    this.masterService.getAll('getallahospitaltypes').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info(res.message);
        else
          this.HospTypeList = res.data;
      }
      // console.log('HospTypeList',this.HospTypeList);
    });
    // locationType.subscribe({})
  }
  get f() { return this.cntrCtrlsForm.controls; }
  onItemSelect(item: any, type: string) {
    //this.newpaymenttypeid=item.payment_type_id;
    // console.log(item, type);
    this.addpayment();
  }
  onSelectAll(items: any, type: string) {
    //  console.log(items, type);
    this.selectedItems = items;
    this.addpayment();
  }
  newamount(item: any, payment_per_head: any): FormGroup {
    // var centralmapping2 = [];
    //   for (let i = 0; i < this.selectedItems.length; i++) {
    //     // this.cntrCtrlsForm.patchValue({
    //     //   payment_per_head:this.selectedItems[i].payment_per_head
    //     // })

    //     centralmapping2.push({
    //       created_by: this.selectedItems[i].created_by,
    //       is_active: this.selectedItems[i].is_active,
    //       payment_per_head: this.selectedItems[i].payment_per_head,
    //       payment_type_id: this.selectedItems[i].payment_type_id
    //     })
    //   }
    return this.formBuilder.group({
      created_by: [this.username],
      is_active: [true],
      payment_per_head: [payment_per_head, Validators.required],
      payment_type_id: [item.payment_type_id, Validators.required],
    });
  }
  // addpayment(){
  //   this.vaccinations().clear();
  //   this.selectedItems.forEach(e => this.vaccinations().push(this.newamount(e)));
  // }
  addpayment() {
    // this.isDropdownDisabled = true;
    // this.cntrCtrlsForm.value;
    // var dataa =this.vaccinations();
    //this.cntrCtrlsForm.value.centermapping.payment_type_id=this.newpaymenttypeid;
    // for (let i = 0; i < this.cntrCtrlsForm.value.centermapping.length; i++) {
    //   this.cntrCtrlsForm.value.centermapping[i].payment_type_id=this.newpaymenttypeid
    // }
    var centralform = this.cntrCtrlsForm.value.centermapping;

    this.vaccinations().clear();

    this.selectedItems.forEach(e => {
      //payment_per_head
      var selectditem = centralform.filter(cf => cf.payment_type_id === e.payment_type_id);
      // var payment_per_headVal = '';
      var payment_per_headVal = '';
      if (selectditem != null && selectditem !== undefined && selectditem.length > 0) {
        for (let i = 0; i < selectditem.length; i++) {
          payment_per_headVal = selectditem[i].payment_per_head;
        }

      }
      this.vaccinations().push(this.newamount(e, payment_per_headVal))
    });
  }
  vaccinations(): FormArray {
    return this.cntrCtrlsForm.get("centermapping") as FormArray;
  }

  getrefHosp(item: any) {
    this.arrefer.push({
      is_active: true,
      hospital_id: item.hospital_id,

    })
  }
  centerregistrationSubmit(event: any) {
    Common.getFormValidationErrors(this.cntrCtrlsForm);
    if (this.cntrCtrlsForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    this.cntrCtrlsForm.value.center_hospital_mapping = [{ is_active: true, hospital_id: this.cntrCtrlsForm.value.center_id }]
    this.cntrCtrlsForm.value.centermapping = [];
    // this.cntrCtrlsForm.value.center_referral_hospital_mapping = []
    // this.cntrCtrlsForm.value.center_hospital_mapping=this.arrrefer;
    this.cntrCtrlsForm.value.center_referral_hospital_mapping = this.arrrefer;
    this.cntrCtrlsForm.value.is_referral = this.isrefChecked;
    if (this.cntrCtrlsForm.value.is_referral == undefined) {
      this.cntrCtrlsForm.value.is_referral = false;
    }
    // this.districtId = this.districtList?.filter(e => this.district_ids?.includes(e.id));
    delete this.cntrCtrlsForm.value.center_id;
    this.registrationService.saveCenterRegistration(this.cntrCtrlsForm.value).subscribe(res => {
      // console.log(res);
      if (res.status === 'OK') {
        if (res.message == 'Center Already Exist.') {
          this.toastr.error(res.message);
          return;
        }
        if (res.data == null || res.data.length == 0)
          this.toastr.info(res.message);
        else
          this.toastr.info(res.message);
        //this.router.navigate(['/home/centers'])
        this.registrationService.centerregeditobj = undefined;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.router.navigate(['/home/centers']);
        });
        this.activeModal.close('changes saved');
      }
    }, err => this.toastr.error(err.error))

  }
  removeVaccine(i: number) {
    const arr = [...this.selectedItems];
    if (arr.splice(i, 1).length > 0) {
      this.selectedItems = arr;
      // this.isDropdownDisabled = arr.length > 0;
    }
    this.vaccinations().removeAt(i);
  }
  getFormValidationErrors() {
    Object.keys(this.cntrCtrlsForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.cntrCtrlsForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
  cancelform() {
    this.registrationService.centerregeditobj = undefined;
    this.registrationService.centerregeditobj.center_hospital_mapping = null;
  }
  onKeyNmbr(type: string, val: string) {
    if (val != '') {
      if (+this.maxLngth == 9) {
        if (+val[0] == environment.mobValdValSAGE)
          this.cntrCtrlsForm.get(type).setValue('');
      }
      if (+this.maxLngth == 10) {
        if (+val[0] < environment.mobValdValIND)
          this.cntrCtrlsForm.get(type).setValue('');
      }
    }
  }
  getallHubsGrid() {
    let PayLoad = {
      "state_id": 0,
      "district_id": 0,
      "mandal_id": 0,
      "location_type_id": 0
    }
    this.registrationService.getAllhubs(PayLoad).subscribe(res => {
      // this.getAllcenters=data.data;
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0) {
          this.hubsList = []
          this.toastr.info(res.message);
        }
        else
          // this.hubsList = res.data;
          this.hubsList=res.data.sort((a,b)=>a.hub_name>b.hub_name?1:-1);
      }
      else
        this.hubsList = []
    })
  }
  radioclick(item: any) {
    if (item == 'true') {
      this.isAct = '1';
      this.cntrCtrlsForm.patchValue({
        is_active: true
      })
    } else {
      this.isAct = '0';
      this.cntrCtrlsForm.patchValue({
        is_active: false
      })
    }
  }

  //   onItemSelectreferal(item: any){
  //     console.log('on select',item);
  //     // this.arrrefer=[];
  //     // if(this.cntrCtrlsForm.value.center_hospital_mapping==null){
  //     //   this.cntrCtrlsForm.value.center_hospital_mapping=[];
  //     // }
  //     this.arrrefer.push({
  //       is_active: true,
  //       hospital_id: item,
  //       //referral_hospital_id
  //       //hospital_type_id
  //       //hospital_name:item.hospital_name

  //     })
  // }
  onItemSelectreferalNew(item: any) {

    let ids = item.id_concat.split('-');
    //  console.log('ids',ids);
    // this.arrrefer=[];
    // if(this.cntrCtrlsForm.value.center_hospital_mapping==null){
    //   this.cntrCtrlsForm.value.center_hospital_mapping=[];
    // }

    this.arrrefer.push({
      is_active: true,
      referral_hospital_id: parseInt(ids[0]),
      hospital_type_id: parseInt(ids[1])
      //referral_hospital_id
      //hospital_type_id
      //hospital_name:item.hospital_name

    })
    //   this.arrrefer.forEach((element,index)=>{
    //     if(element.referral_hospital_id!=ids[0]) delete  this.arrrefer[index];
    //  });
  }

  defaulthospitalref() {
    this.dropdownSettingsreferal = {
      singleSelection: false,
      idField: 'hospital_id',
      textField: 'hospital_name_concat',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableCheckAll: false,
      itemsShowLimit: 4,
      allowSearchFilter: true
    };
    this.dropdownSettingsreferalNew = {
      singleSelection: false,
      idField: 'id_concat',
      textField: 'hospital_name_concat',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableCheckAll: false,
      itemsShowLimit: 4,
      allowSearchFilter: true
    };
  }
  onItemSelectreferalD(item) {
    let ids = item.id_concat.split('-');
    // this.arrrefer.push({
    //   is_active: false,
    //   referral_hospital_id:parseInt(ids[0]) ,
    //   hospital_type_id:parseInt(ids[1])
    //   //referral_hospital_id
    //   //hospital_type_id
    //   //hospital_name:item.hospital_name

    // })
    // this.arrrefer = this.arrrefer.filter((el) => el.ids !== ids);
    //sushma 29-06-2022 multiselect dropdown deselct
    let lst = this.arrrefer.findIndex(a => (a.hospital_type_id == ids[1]) && (a.referral_hospital_id == ids[0]));
    this.arrrefer.splice(lst, 1);
    //this.arrrefer =lst;
    //this.arrrefer = this.arrrefer.filter(f => f.hospital_type_id != parseInt(ids[1]) && f.referral_hospital_id != parseInt(ids[0]) );
    // this.arrrefer = lst;
    // console.log('delete check',item);
    // // let ids = item.id_concat.split('-');
    //   this.arrrefer.forEach((element,index)=>{
    // if(element.hospital_type_id==parseInt(ids[1])) 
    // {
    //   console.log('check',element);
    //   delete this.arrrefer[index];
    //   //this.arrrefer.slice(element,1);
    //   console.log('list',this.arrefer);
    // }

    //});
    //this.cntrCtrlsForm.value.center_hospital_mapping.slice(item,1)
    //  this.cntrCtrlsForm.value.center_hospital_mapping = this.cntrCtrlsForm.value.center_hospital_mapping.filter(f => f.referral_hospital_id != ids[0]);
  }
  getallhospitalRef() {

    // this.registrationService.gethospitalref(0,0).subscribe(res => {
    //   if(res.status === 'OK') {
    //     if (res.data == null || res.data.length == 0){
    //       this.hospitalrefdata=[];
    //     }
    //     else
    //       this.hospitalrefdata = res.data;
    //   }
    // })
    this.registrationService.gethospitalrefNew('Referral').subscribe(res => {
      // console.log('resp of refferal',res);
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0) {
          this.hospitalrefdataNew = [];
        }
        else
          this.hospitalrefdataNew = res.data;
      }
      this.hospitalrefdataNew.map(res => {
        for (let i = 0; i < this.HospTypeList.length; i++) {
          // console.log('step1',this.HospTypeList[i]); 
          if (this.HospTypeList[i].id == res.hospital_type_id) {
            // console.log('step2',this.HospTypeList[i].id,res.hospital_type_id);
            res.hospital_name_concat = res.hospital_name + '-' + this.HospTypeList[i].hospital_type;
            res.id_concat = res.id + '-' + this.HospTypeList[i].id;
          }
        }
      }
      )
      this.hospitalrefdataNew=this.hospitalrefdataNew.sort((a,b)=>a.hospital_name_concat>b.hospital_name_concat?1:-1);

    })
   

  }

  //get center hospital new API integration added on 05-04-2022
  getAllCenterHospital() {
    //  console.log('get All Center hospital');
    this.registrationService.getallcenterhospitals('Center').subscribe(res => {
      // console.log('res',res);
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0) {
          this.centerHospitals = [];
        }
        else
          // this.centerHospitals = res.data;
          this.centerHospitals=res.data.sort((a,b)=>a.hospital_name.toLowerCase()>b.hospital_name.toLowerCase()?1:-1);
      }
    })

  }

  LoadData() {
    this.registrationService.getAll('hospital/getallhospitalrecords').subscribe(res => { //hospital/getallhospitalrecords
      if (res.status === "OK") {
        if (res.data == null || res.data.length == 0)
          this.hospitalrefdata = [];
        else
          this.hospitalrefdata = res.data;
      }
    });
  }
  gethospitalstotal(val: any) {
    if (this.cntrCtrlsForm.value.state_id == '' || this.cntrCtrlsForm.value.state_id == 0) {
      this.toastr.error('Please Select State');
      return;
    }
    if (val == true) {
      this.distidhospital = 0;
    } else {
      this.distidhospital = this.cntrCtrlsForm.value.district_id;
    }
    // this.getallhospitalRef();
  }
  onRef(event) {
    this.isrefChecked = event.target.checked;
  }

  getcncthsptlName(itemId) {
    return this.hospitalrefdataNew?.find(f => f.id_concat == itemId)?.hospital_name_concat ?? '';
  }
}
