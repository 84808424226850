<!-- [class.focused]="focused" -->
<ngb-datepicker #dp [displayMonths]="1" [dayTemplate]="t" [minDate]="minDate" [maxDate]="maxDate"> </ngb-datepicker>

<ng-template #t let-date="date" let-focused="focused">
  <span
    class="custom-day"
    (click)="onDateSelection($event,date)"
    [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
    [class.faded]="isHovered(date) || isInside(date)"
    [class.selected]="isDateSelected(date)"
    (mouseenter)="hoveredDate = date"
    (mouseleave)="hoveredDate = null"
  >
    {{ date.day }}
  </span>
</ng-template>
