import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/shared/services/master.service';
import { DistrictComponent } from '../district/district.component';
import { Subject, pipe } from 'rxjs';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { Common } from 'src/app/common';

declare let $: any;
@Component({
  selector: 'app-district-list',
  templateUrl: './district-list.component.html',
  styleUrls: ['./district-list.component.css']
})
export class DistrictListComponent implements OnInit {
  submitted = false;
  public loading = false;
  districtForm: FormGroup;
  modalTitle: string = '';
  stateList: Array<any> = [];
  dtOptions: any = {};
  dtTrigger: any = new Subject();
  datalist: Array<any> = [];
  state_id: number = 0;
  constructor(config: NgbModalConfig,
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private masterService: MasterService) {

  }

  ngOnInit(): void {
    this.getAllStates()
    this.initFormControls();

  }
  initFormControls() {
    this.districtForm = this.formBuilder.group({
      state_id: ['', Validators.required],
    });
  }

  openDistrictModal(editData: any = null) {
    const modalRef = this.modalService.open(DistrictComponent);
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.editData = editData;
    modalRef.result.then(result => {
      //  this.getAllStates()
    }, (reason) => { });
  }
  getAllStates() {

    if (this.stateList.length > 0) {
      $('.dataTable').DataTable().destroy();;
      $('.dataTable').DataTable({ destroy: true, searching: false });
    }
    this.masterService.getAll('getallstates').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'District List');
        else {
          this.stateList = res.data;
          setTimeout(() => $(`#districtTable`).DataTable({ destroy: true }));
        }
      }
    });
  }

  loaddistricts() {
    if (this.state_id == 0) {
      this.toastr.error('Please Select State');
      Common.setFocus('state_id');
      return;
    }



    var id = $('#state_id').val()

    if (this.datalist.length > 0) {
      $('.dataTable').DataTable().destroy();;
      $('.dataTable').DataTable({ destroy: true, searching: false });
    }
    this.masterService.searchbyIdMasters(`getdistrictsbystateid?stateId=${id}`).subscribe((data: any) => {
      if (data.status === 'OK') {
        if (data.data == null || data.data.length == 0)
          this.toastr.info('No records available', 'District List');
        else {
          this.datalist = data.data;
          setTimeout(() => $(`#stateTable`).DataTable({ destroy: true }));
        }
      }
    });

  }
  setFocus(elementName: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementName)));
    el.value = '';
    el.focus();
  }
  deleteItem(id: any) {
    this.masterService.deleteById(id).subscribe(res => {
      if (res.status == "OK") {
        this.getAllStates();
        this.toastr.success('Record Deleted Successfully');
      }
    },
      error => { this.toastr.error('An Error Occured'); });
  }

}