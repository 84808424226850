import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Common } from 'src/app/common';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { VendorRegistrationComponent } from '../vendor-registration/vendor-registration.component';
@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.css']
})
export class VendorListComponent implements OnInit {
  modalTitle: string = '';

  vendorList: Array<any> = [];
  vaccineList: Array<any> = [];
  stateList: Array<any> = [];

  private readonly onDestroy = new Subscription();
  constructor(config: NgbModalConfig
    , private route: ActivatedRoute
    , private modalService: NgbModal
    , private toastr: ToastrService
    , private regSrve: RegistrationService) { }

  ngOnInit(): void {
    this.onDestroy.add(this.route.data.subscribe(response => this.getAll(response.mastersData)));
    this.loadTableData();
  }

  ngOnDestroy(): void {
    this.onDestroy.unsubscribe();
  }

  openVendorModal(item: any = null) {
    const modalRef = this.modalService.open(VendorRegistrationComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.editData = item;
    modalRef.componentInstance.stateList = this.stateList;
    modalRef.componentInstance.vaccineList = this.vaccineList;
    modalRef.result.then(result => {
      if (result == 'changes saved')
        this.loadTableData();
      // here call Table load API
    }, (reason) => { });

  }

  loadTableData() {
    const getVendor = this.regSrve.getVendorList().subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Vendor List Details');
        else {
          this.vendorList = res.data;
          Common.loadDataTable(this.vendorList, '#vendorTable');
        }
      }
    });
    this.onDestroy.add(getVendor);
  }

  getAll(resultList: any) {
    resultList.forEach((list: { status: string; data: any[]; }, ind: number) => {
      if (list.status === 'OK') {
        if (list.data == null || list.data.length == 0)
          console.log('No records available');
        else {
          const assignList = {
            '0': () => this.stateList = list.data,
            '1': () => this.vaccineList = list.data,
          }
          assignList[ind]();
        }
      }
    });
  }
}