<form [formGroup]="companyForm" (ngSubmit)="onSubmit($event)">
    <div class="modal-header">
        <h4 class="modal-title">{{title}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">State Name<span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="stateId" id="stateId"
                                [ngClass]="{ 'is-invalid': submitted && f.stateId.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let state of stateList" [value]="state.id">
                                    {{state.name}}</option>
                            </select>
                            <div *ngIf="submitted && f.stateId.errors" class="invalid-feedback">
                                <div *ngIf="f.stateId.errors.required">State Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Company Name <span class="text-danger">*</span>
                        </label>
                        <div>
                            <select formControlName="companyId" id="companyId"
                                [ngClass]="{ 'is-invalid': submitted && f.companyId.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let company of companyList" [value]="company.id">{{company.name}}
                                </option>
                            </select>
                            <div *ngIf="submitted && f.companyId.errors" class="invalid-feedback">
                                <div *ngIf="f.companyId.errors.required">Company Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Promotion Name <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" id="promotionName" formControlName="promotionName"
                                [ngClass]="{ 'is-invalid': submitted && f.promotionName.errors }"
                                placeholder="Promotion Name" class="form-control form-control-sm"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'>
                            <div *ngIf="submitted && f.promotionName.errors" class="invalid-feedback">
                                <div *ngIf="f.promotionName.errors.required">promotion Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Promotion Code <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" id="promotionCode" placeholder="Company Name"
                                formControlName="promotionCode"
                                [ngClass]="{ 'is-invalid': submitted && f.promotionCode.errors }"
                                class="form-control form-control-sm"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'>
                            <div *ngIf="submitted && f.promotionCode.errors" class="invalid-feedback">
                                <div *ngIf="f.promotionCode.errors.required">Promotion Code is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Lab Discount <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" id="labDiscount" placeholder="Lab Discount"
                                formControlName="labDiscount"
                                [ngClass]="{ 'is-invalid': submitted && f.labDiscount.errors }"
                                class="form-control form-control-sm"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="10">
                            <div *ngIf="submitted && f.labDiscount.errors" class="invalid-feedback">
                                <div *ngIf="f.labDiscount.errors.required">Lab Discount is required</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="row">
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Pharmacy Discount <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" id="pharmacyDiscount"
                                placeholder="Contact Person Name" formControlName="pharmacyDiscount"
                                [ngClass]="{ 'is-invalid': submitted && f.pharmacyDiscount.errors }"
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.pharmacyDiscount.errors" class="invalid-feedback">
                                <div *ngIf="f.pharmacyDiscount.errors.required">Pharmacy Discount is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Doctor Discount <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" id="doctorDiscount"
                                placeholder="Contact Person Number" formControlName="doctorDiscount"
                                [ngClass]="{ 'is-invalid': submitted && f.doctorDiscount.errors }"
                                class="form-control form-control-sm"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="10">
                            <div *ngIf="submitted && f.doctorDiscount.errors" class="invalid-feedback">
                                <div *ngIf="f.doctorDiscount.errors.required">Doctor Discount is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Start Date <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="start_date" id="start_date"
                                placeholder="Date of Birth" bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                [ngClass]="{ 'is-invalid': submitted && f.start_date.errors }"
                                class="form-control form-control-sm"
                                onkeypress='return ((event.charCode >= 65 && 
                            event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'>
                            <div *ngIf="submitted && f.start_date.errors" class="invalid-feedback">
                                <div *ngIf="f.start_date.errors.required">Start Date is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-xl-8">
                    <div class="form-group">
                        <label class="control-label">End Date <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="end_date" id="end_date"
                                placeholder="Date of Birth" bsDatepicker
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                [ngClass]="{ 'is-invalid': submitted && f.end_date.errors }"
                                class="form-control form-control-sm"
                                onkeypress='return ((event.charCode >= 65 && 
                            event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'>
                            <div *ngIf="submitted && f.end_date.errors" class="invalid-feedback">
                                <div *ngIf="f.end_date.errors.required">End Date is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Status <span class="text-danger">*</span> </label>
                        <div>
                            <!-- <div class="custom-control custom-radio">
                                <input type="radio" class="custom-control-input" id="defaultGroupExample1" name="groupOfDefaultRadios">
                                <label class="custom-control-label" for="defaultGroupExample1">Option 1</label>
                              </div>
                              
                              <div class="custom-control custom-radio">
                                <input type="radio" class="custom-control-input" id="defaultGroupExample2" name="groupOfDefaultRadios" checked>
                                <label class="custom-control-label" for="defaultGroupExample2">Option 2</label>
                              </div> -->
                            <input type="radio" name="status" formControlName="status" value="Active"> Active
                            <input type="radio" name="status" formControlName="status" value="In Active">In Active
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <input type="submit" class="btn btn-sm btn-primary" value="Submit">&nbsp;&nbsp;
        <button type="button" class="btn btn-sm btn-outline-dark"
            (click)="activeModal.close('Close click')">Close</button>
    </div>
</form>