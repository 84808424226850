<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Pharmacy Registration
                        <button class="btn btn-blue btn-icon float-right pointer"
                            (click)="modalTitle='Pharmacy Registration Details';ChkLimit()"
                            *ngIf="setPrivilege(Screen_Name.PharmacyRegistration, Permission.Add)">
                            <i class="fa fa-plus"></i> <!-- openPharmacyModal() -->
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="" style="margin-top: 2rem;" >
                            <button type="button" class="btn btn-sm btn-secondary float-right mb-3 px-4" *ngIf ="excelData.length>0"(click)="downLoadExcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button>
                        </div>
                        <div class="table-responsive">
                            <table datatable id="pharmacyTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>S No</th>
                                        <th>Pharmacy Name </th>
                                        <th>Pharmacy Contact</th>
                                        <th>Contact Person Name</th>
                                        <th>Contact Person Mobile No</th>
                                        <th> Registration No</th>
                                        <th>Location Type</th>
                                        <th style="width:10%;">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let pharma of pharmacyList;let ind=index;">
                                        <td>{{(ind+1)}}</td>
                                        <td class="text-uppercase">{{pharma.pharmacy_name}}</td>
                                        <td>{{pharma.pharmacy_conatct_no}}</td>
                                        <td class="text-uppercase">{{pharma.contact_person_name}}</td>
                                        <td>{{pharma.contact_person_mobile}}</td>
                                        <td>{{pharma.registration_no}}</td>
                                        <td>{{pharma.location_type_id == 2 ? 'Urban' : 'Rural'}}</td>
                                        <td>
                                            <div class="badge badge-success badge-pill pointer" title="Edit"
                                                *ngIf="setPrivilege(Screen_Name.PharmacyRegistration, Permission.Edit)">
                                                <a (click)="openPharmacyModal(pharma)"><i class="fa fa-edit text-white"></i></a></div>&nbsp;&nbsp;
                                            <div class="badge badge-danger badge-pill pointer" title="Delete"
                                                *ngIf="setPrivilege(Screen_Name.PharmacyRegistration, Permission.Delete)">
                                                <a (click)="deleteItem(pharma.id)"><i class="fa fa-trash text-white"></i></a></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>