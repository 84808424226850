import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

import { MasterService } from 'src/app/shared/services/master.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-enrollcorpempcontrols',
  templateUrl: './enrollcorpempcontrols.component.html',
  styleUrls: ['./enrollcorpempcontrols.component.css']
})

export class EnrollcorpempcontrolsComponent implements OnInit {
  
  @Input() editData: any; savedataa: any; enrollform: FormGroup; genderList: Array<any> = []; employeeList: Array<any> = [];

  constructor(public activeModal: NgbActiveModal
    , private masterService: MasterService
    , private formbuilder: FormBuilder
    , private toastr: ToastrService
    , private userServicedata: UserService
    , private registrationService: RegistrationService) {
    this.activeModal.close('changes saved')
  }

  ngOnInit(): void {
    this.formcontrolsdata(), this.getMasterdata();
  }

  formcontrolsdata() {
    this.enrollform = this.formbuilder.group({
      id: [0],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      coporateId: ['', Validators.required],
      employee_id: ['', Validators.required],
      employee_level_id: ['', Validators.required],
      gender_id: ['', Validators.required],
      address: ['', Validators.required],
      is_active: [true],
      user_id: [0],
      account_activated: [true],
      corporate_id: [0]
    })
  }

  getMasterdata() {
    const gender = this.masterService.getAll('getallgenders'), employeelevel = this.userServicedata.getAll('masteremployeelevel/getall');
    forkJoin([gender, employeelevel]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              console.log('No records available');
            else {
              const assignList = {
                '0': () => this.genderList = list.data,
                '1': () => this.employeeList = list.data
              }
              assignList[ind]();
            }
          }
        });
      },
      complete: () => {
        if (this.editData) {
          let data = this.editData;
          this.enrollform.patchValue({ id: data.id, first_name: data.first_name, last_name: data.last_name, email: data.email,
            mobile: data.mobile, coporateId: data.coporateId, employee_id: data.employee_id, employee_level_id: data.employee_level_id,
            gender_id: data.gender_id, address: data.address, user_id: data.user_id, account_activated: data.account_activated,
            corporate_id: data.corporate_id, is_active: data.is_active });
        }
      }
    })
  }
  
  onSubmit(event: any) {
    /* const sendData = JSON.stringify(this.enrollform.getRawValue());
    this.registrationService.saveBenfbyCompy(sendData).subscribe(resp => {
      if (resp.status === 'OK')
        this.toastr.success(resp.message, 'Employee Details'), this.activeModal.close('changes saved');
      else
        this.toastr.error(resp.message);
    }); */
    let aprvLst = [];
    aprvLst.push({ id: this.editData.user_id, is_active: this.enrollform.get('is_active').value });
    if (aprvLst.length > 0) {
      this.registrationService.ActiveBenf(JSON.stringify(aprvLst)).subscribe(res => {
        if (res) {
          if (res.status == "OK")
            this.toastr.success(res.message), this.activeModal.close('changes saved');
          else
            this.toastr.error(res.message);
        }
        else
          this.toastr.error(res.message);
      });
    }
  }

  SubmitForm() {
    document.getElementById('btnsubmit').click();
  }

}