import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Common } from 'src/app/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import * as XLSX from 'xlsx';
declare let $: any;

@Component({
  selector: 'app-otc-medicines-report',
  templateUrl: './otc-medicines-report.component.html',
  styleUrls: ['./otc-medicines-report.component.css'],
  providers: [DatePipe]
})
export class OtcMedicinesReportComponent implements OnInit {
  otcForm: FormGroup;
  from_date: any = new Date();
  to_date: any = new Date();
  max_date = new Date();
  otcList = []; minDate: Date; minToDate: Date; docId = '';
  submitted = false;
  showExport: boolean = true;
  fileName = 'OTC Report.xlsx';
  constructor(private regService: RegistrationService
    , protected toastr: ToastrService
    , private formBuilder: FormBuilder
    , private datePipe: DatePipe
    , private masterService: MasterService
    , private authSvc: AuthenticationService) {
    // this.minDate = new Date();
    // this.minDate.setDate(this.minDate.getDate());
  }

  ngOnInit(): void {
    this.initFormControls();this.LoadData();
  }

  initFormControls() {
    this.otcForm = this.formBuilder.group({
      from_date: [new Date(), Validators.required],
      to_date: [new Date(), Validators.required]
    });
  }

  get f() { return this.otcForm.controls; }

  LoadData() {
    let sendData = JSON.parse(JSON.stringify(this.otcForm.value));
    [sendData.from_date, sendData.to_date] =
      [this.datePipe.transform(sendData.from_date, 'yyyy-MM-dd '),
      this.datePipe.transform(sendData.to_date, 'yyyy-MM-dd ')]
    this.regService.getotcReports(sendData).subscribe(res => {
      if (res.data == null || res.data.length == 0){
    this.otcList = [];
    this.toastr.info('No records available','OTC  Reports List');
    Common.loadDataTable(this.otcList,'#otcReport');
    this.showExport = false;
  }   
else {
  this.otcList = res.data;
  Common.loadDataTable(this.otcList,'#otcReport');
 this.showExport = true
}
});
  }

  onFromDateChange(val: Date) {
    if (val) {
      let to_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.otcForm.get('to_date').setValue(new Date(to_date.setDate(to_date.getDate())));
      this.minDate = to_date;
    }
  }

  clear() {
    this.otcForm.value.from_date = this.otcForm.value.to_date = '';
  }
  exportexcel(): void {

    var table = $('#otcReport').DataTable();

    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {
      / table id is passed over here /
      let element = document.getElementById('otcReport');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);


      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };


      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
  }
}

