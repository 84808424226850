import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { CenterRegistrationFormComponent } from 'src/app/modules/registration/center-registration-form/center-registration-form.component';
import { environment } from 'src/environments/environment';
import { PatientRegistrationComponent } from '../patient-registration/patient-registration.component';
import { Common } from 'src/app/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { HubRegistrationComponent } from '../hub-registration/hub-registration.component';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
declare let $:any;

@Component({
  selector: 'app-hub-list',
  templateUrl: './hub-list.component.html',
  styleUrls: ['./hub-list.component.css']
})
export class HubListComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject(); minDt = new Date(); minToDt = new Date(); maxDt = new Date(); maxDtDOB = new Date();
  cntrLstForm: FormGroup; cntrCtrlsForm: FormGroup; district_code = '';locationtypeid=''; block_code = ''; grmpcht_code = ''; isAdd = 'add'; isAct = '0';
  submitted = false; locationType_list; lstgrid = []; state_list = []; division_list = []; district_list = []; block_list = []; grampanchayat_list = [];
  paymentlist = []; userlist = []; role_list = []; lstData = []; panchayat_list = []; village_list = []; fileLst = [];state_code = 1;
  fileName= 'Centers Report.xlsx';
  
  modalTitle: string = '';
  lstGrid = [];
  getAllcenters=[];
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  mandalList: Array<any> = [];
  cityList: Array<any> = [];
  villageList: Array<any> = [];
  isNIG = environment.isNIG;
  excelData: any=[];
  searchList: any=[];

  constructor(config: NgbModalConfig
    , public modalService: NgbModal
    , private regService: RegistrationService
    , private authSvc: AuthenticationService
    , protected toastr: ToastrService
    , private router:Router
    , private formBuilder:FormBuilder
    , public cdr: ChangeDetectorRef
    , private excelDownloadService:ExcelDownloadService
    , private masterService: MasterService) {
     
  }


  ngOnInit(): void {
    this.initGridForm();
    this.locationChanged('', 'state');
    this.locationChanged('5', 'district');
    this.getAllMasters();
   this.getallcentersGrid();
   var table = $('#centerregTable').DataTable();

   $('#centerregTable').on('search.dt', function() {
       var value = $('.dataTables_filter input').val();
      // console.log(value); // <-- the value
     // this.searchInList(value);
   }); 
  }
  get f() { return this.cntrCtrlsForm.controls; }
  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        this.cntrCtrlsForm.get('districtid').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      },
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }
  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
      this.districtList.sort((a,b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error));
  }
  getAllMasters() {
    this.masterService.getAll('getalllocationtypes').subscribe(res=>{
      if(res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info(res.message);
        else
          this.locationTypeList = res.data;
      }
    });
   // locationType.subscribe({})
    }
  initGridForm() {
    this.cntrLstForm = this.formBuilder.group({
      // state_code:[0, Validators.required],
      // district_code:[0, Validators.required],
      // locationtypeid:[0, Validators.required],
      // block_code: [0]
      // state_id: [36, Validators.required],
       state_id: [5, Validators.required],
       district_id: [0, Validators.required],
       mandal_id: [0],
       location_type_id:[0, Validators.required]
       //village_id: [''],
      
    });
 
  }
  searchInList(val){
    const result = this.getAllcenters.map((item =>  {
      if(item.hub_name)
      this.searchList.push();
     }));
  }
  downLoadExcel(){
    const fileName = 'Hub_reg'
      var excelData = this.excelData;
      if(excelData.length>0){
        const excelHeaders = ['Hub Name', 'State','District','Block','Village','Address','Pincode'];
        const internalHeaders = ['hub_name', 'stateName','districtName','mandalName','villageName','address','pincode'];
        this.toastr.success('Please wait excel is downloading')
        this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
      }
      else{
        this.toastr.error('No data to download')
      }
    
  }
    // openPatientModal() {
    //   const modalRef = this.modalService.open(CenterRegistrationFormComponent, { scrollable: true, size: 'xl' });
    //   modalRef.componentInstance.title = this.modalTitle;
    // }

    
    // immediately load data in datatable salman 
    openPatientModal() {
      this.regService.hubregeditobj = undefined;
      const modalRef = this.modalService.open(HubRegistrationComponent, { scrollable: true, size: 'xl' });
      modalRef.componentInstance.title = this.modalTitle;
      modalRef.result.then(result => {
        //  this.changeDetection.reattach();
          if (result == 'changes saved' || result=='Close click'){
            this.getallcentersGrid();
          }
          
        }, (reason) => { });
    }
    onAddEdit(item: any) {
      this.regService.hubregeditobj=item;
      const modalRef = this.modalService.open(HubRegistrationComponent, { scrollable: true, size: 'xl' });

      modalRef.componentInstance.title = this.modalTitle;
      modalRef.result.then(result => {
        //  this.changeDetection.reattach();
       // this.changeDetection.detach();
          if (result == 'changes saved' || result=='Close click'){
            $('.dataTable').DataTable().destroy(), $('.dataTable').DataTable({ searching: false });
            this.getallcentersGrid();
          }
          
        }, (reason) => { });
   }
 
    onSearch() {
      $('.dataTable').DataTable().destroy(), $('.dataTable').DataTable({ searching: false });
      this.getallcentersGrid();
    }

    // onReset() {
    //   this.cntrLstForm.patchValue({district_id:0, mandal_id:0,location_type_id:0  }), 
    //   this.submitted = true;
    //   this.getallcentersGrid();
    // // this.cntrLstForm.reset();
    //  // $('.dataTable').DataTable().clear(),$('.dataTable').DataTable().destroy() ;
    // }
    onReset(){
      this.cntrLstForm.patchValue({district_id:0, mandal_id:0,location_type_id:0  })
      this.getallcentersGrid();
      $('.dataTable').DataTable().clear(),$('.dataTable').DataTable().destroy() ;
    }
  getallcentersGrid(){
    this.regService.getAllhubs(this.cntrLstForm.value).subscribe(res => {
     // this.getAllcenters=data.data;
    // this.cdr.detectChanges();
     if(res.status === 'OK') {
      if (res.data == null || res.data.length == 0){
      this.getAllcenters=[]
        this.toastr.info(res.message);
      }
      else
        this.getAllcenters = res.data;
        this.excelData = this.getAllcenters;
      //  this.cdr.detectChanges();
      Common.loadDataTable(this.getAllcenters, '#centerregTable');
    }
    else
      this.getAllcenters = [], Common.loadDataTable(this.getAllcenters, "#centerregTable");
    })
  }
  

}
