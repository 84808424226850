<div class="row" [hidden]="isUpld">
  <div class="container-fluid pl-0 pr-0">
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-header-actions mx-auto">
          <div class="card-header">
            Consultation
          </div>
          <div class="card-body">
            <form [formGroup]="consultForm" (ngSubmit)="LoadData()">
              <div class="row ">
                <div class="container">
                  <div class="row ">
                    <div class='col-md-3'>
                      <div class="form-group">
                        <div class='date' id='datetimepicker6'>
                          <label class="control-label">From Date <span class="text-danger">*</span> </label>
                          <div>
                            <input id="from_date" name="from_date" type="text" autocomplete="off" onpaste="return false"
                              placeholder="From" bsDatepicker bsDatepicker #dp="bsDatepicker" (focus)="dp.show()"
                              (focus)="dp1.hide()" formControlName="from_date" [maxDate]="max_date"
                              (bsValueChange)="onFromDateChange($event)" onkeypress='return event.charCode == 8'
                              [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                              [ngClass]="{ 'is-invalid': submitted && f.from_date.errors }"
                              class="form-control form-control-sm">
                            <div *ngIf="submitted && f.from_date.errors" class="invalid-feedback">
                              <div *ngIf="f.from_date.errors.required">From Date is required</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='col-md-3'>
                      <div class="form-group">
                        <div class='date' id='datetimepicker7'>
                          <label class="control-label">To Date <span class="text-danger">*</span> </label>
                          <div>
                            <input id="to_date" name="to_date" type="text" autocomplete="off" onpaste="return false"
                              placeholder="To" bsDatepicker #dp1="bsDatepicker" (focus)="dp1.show()" (focus)="dp.hide()"
                              formControlName="to_date"
                              [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}" [maxDate]="max_date"
                              [minDate]="f.from_date.value" [ngClass]="{ 'is-invalid': submitted && f.to_date.errors }"
                              (bsValueChange)="onToDateChange($event)" class="form-control form-control-sm"
                              onkeypress='return event.charCode == 8'>
                            <div *ngIf="submitted && f.to_date.errors" class="invalid-feedback">
                              <div *ngIf="f.to_date.errors.required">To Date is required</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='col-md-2' style="margin-top: 2rem;">
                      <button type="submit" class="btn btn-sm btn-primary" value="Submit"
                        (focus)="dp1.hide()">Submit</button>&nbsp;&nbsp;
                      <!-- <button type="button" class="btn btn-sm btn-danger" (click)="clear()">Clear</button> -->
                    </div>
                    <div class='col-md-2' style="margin-top: 2rem;">
                      <button type="button" class="btn  btn-sm btn-secondary float-right" *ngIf ="excelData.length>0" (click)="downLoadExcel()"> 
                        <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> 
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="table-responsive">
              <table datatable id="table" class="table table-striped table-bordered table-sm" style="width:100%">
                <thead>
                  <tr>
                    <th class="singleLine">S No </th>
                    <th class="singleLine">Visit Date </th>
                    <th class="singleLine">Patient Name</th>
                    <th class="singleLine">Gender</th>
                    <th class="singleLine">Mobile</th>
                    <th class="singleLine">Patient Age</th>
                    <th class="singleLine">Doctor Name</th>
                    <th class="singleLine">Registration No</th>
                    <th class="singleLine">Consultation No</th>
                    <th class="singleLine">Type of Call</th>
                    <th class="singleLine">Centre Lat/Long</th>
                    <th class="singleLine">Tab Lat/Long</th>
                    <th class="singleLine">Distance(KM)</th>
                    <!-- <th class="singleLine">Map View</th> -->
                    <th class="singleLine">ABHA Address</th>
                    <th class="singleLine">Link Care Context</th>
                    <!-- new line added for direct auth status 15-02-2023 -->
                    <th class="singleLine">Direct Auth Status</th>
                    <!-- <th class="singleLine">Add Consent</th> -->
                    <!-- <th class="singleLine">Lab Test</th> -->
                    <th class="singleLine">View Files</th>
                    <!-- <th class="text-center">View</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of lstGrid;let ind=index">
                    <td class="text-center">{{(ind+1)}}</td>
                    <td class="singleLine">{{item?.formatedVisited_date}}</td>
                    <td class="singleLine text-uppercase">
                      {{item?.formated_patient_name}}
                    </td>
                    <td class="singleLine">
                     {{item?.formated_gender}}
                    </td>
                    <td class="singleLine">
                      {{item?.formated_mobile}}
                      </td>
                    <td class="singleLine">{{item?.formated_patient_age}}
                    </td>
                    <td class="singleLine text-uppercase">
                      {{item?.formated_doctor_name}}
                    </td>
                    <td class="singleLine">
                     {{item?.formated_reg_no}}
                    </td>
                    <td class="singleLine">
                      {{item?.formated_cons_no}}
                    </td>
                    <td class="singleLine">{{item?.formated_type_of_call}}</td>
                  <!-- New changes below for lat lng -->
                     <td class="singleLine">{{item?.centreLatLng}}</td>
                    <td class="singleLine">{{item?.tabLatLng}}</td>
                    <td class="singleLine">{{item?.distance | number:'1.1-4'}}</td>
                    <td class="singleLine">{{item?.health_id}}</td>
                    <!-- <td> 
                    <div class="badge badge-info badge-pill" title="Download Recording" style="cursor: pointer;">
                    <a (click)="viewMap(item?.patient_interaction_id)"  > <i
                      
                        class="fa fa-download text-white"></i></a>
                  </div>
                  </td> -->
                  <!--End New changes below for lat lng -->
                    <!-- <td class="singleLine">{{getCallType(item?.call_type_id)}}</td>
                    <td class="singleLine"></td> -->
                    <!-- <td *ngIf ="item.health_id == '' || item.health_id == null" style="text-align: center !important;">No HealthId</td> -->
                    <td *ngIf ="item.health_id == '' || item.health_id == null" style="text-align: center !important;">No ABHA Address</td>
                    <td *ngIf ="item.health_id!== '' && item.health_id!== null && item.is_care_context_linked" style="text-align: center !important; color: green;">Care Context Added</td>
                    <td *ngIf ="item.health_id!== '' && item.health_id!== null && (!item.is_care_context_linked || item.is_care_context_linked==null)"  style="text-align: center !important;"><button class="btn btn-sm btn-primary"  (click)="modalTitle='Link Care context with ABHA';openLinkwithAbhaConsultation(item)">Link Care Context</button></td>
                    <!-- <td *ngIf="item.direct_auth_status!=null && (!item.is_care_context_linked || item.is_care_context_linked==null)" style="text-align: center !important;color: red !important;">{{item?.authStatus}} {{item?.authDate}}</td>
                    <td *ngIf="item.direct_auth_status==null || item.is_care_context_linked" style="text-align: center !important;"></td>
                     -->
                     <!-- new line added for always show auth status 10-04-2023 -->
                     <td style="text-align: center !important;color: red !important;" *ngIf="!item?.authStatus">{{item.direct_auth_status!=null?item.formated_direct_auth_status:''}}</td>
                     <td style="text-align: center !important;color: green !important;" *ngIf="item?.authStatus">{{ item.direct_auth_status!=null?item.formated_direct_auth_status:''}}</td>
                    
                     <!-- Auth status end -->
                     <!-- <td *ngIf ="item.health_id == '' || item.health_id == null" style="text-align: center !important;">No ABHA Address</td> -->
                    
                     <!-- <td *ngIf ="item.health_id!== '' && item.health_id!== null"  style="text-align: center !important;"  style="text-align: center !important;"><button class="btn btn-sm btn-primary" (click)="addConsent(item)">Add Consent</button></td> -->
                    
                     
                    <td style="display:inline-flex !important; width:100% !important">               
                      <div class="badge badge-primary badge-pill" title="Prescription" style="cursor: pointer;"
                        *ngIf="setPrivilege(Screen_Name.Consultation, Permission.View)">
                        <a (click)="imgPath='';onViewPres(item?.document_path)"> <i class="fa fa-eye text-white"></i></a> <!-- item -->
                        <!-- openModal(item) -->
                      </div><span *ngIf="setPrivilege(Screen_Name.Consultation, Permission.View)">&nbsp;&nbsp;</span>

                      <div class="badge badge-info badge-pill" title="Patient Files" style="cursor: pointer;"
                        *ngIf="setPrivilege(Screen_Name.Consultation, Permission.View)">
                        <a (click)="openViewFileModal(item?.patient_interaction_id,item?.beneficiary_id,item?.doctor_id,item?.advice)"> <i
                            class="fa fa-eye text-white"></i></a>
                      </div><span *ngIf="setPrivilege(Screen_Name.Consultation, Permission.View)">&nbsp;&nbsp;</span>
                        <div class="badge badge-info badge-pill" title="Download Recording" style="cursor: pointer;"
                        *ngIf="setPrivilege(Screen_Name.Consultation, Permission.View) && item?.formated_type_of_call ==='Video' && adminRoleEnabledFlag=='2' "   >
                        <a (click)="downloadfile(item?.patient_interaction_id)"  > <i
                          
                            class="fa fa-download text-white"></i></a>
                      </div>
                      
                      <div *ngIf="isShowDP">
                        <div class="badge badge-success badge-pill" title="Upload Prescription" style="cursor: pointer;"
                          *ngIf="item?.is_epaper"><a
                            (click)="isUpld=true;dgtlFile=null;pocId=item?.poc_id;benfId=item?.beneficiary_id;patIntId=item?.patient_interaction_id;">
                            <i class="fa fa-upload text-white"></i></a>
                        </div><span *ngIf="item?.is_epaper">&nbsp;&nbsp;</span>
                      </div>
                      <!-- <div class="badge badge-success badge-pill" title="Upload Prescription" style="cursor: pointer;">
                        <a (click)="openModalMP(item?.id, item?.beneficiary_id, item?.patient_interaction_id)">
                          <i class="fa fa-upload text-white"></i></a>
                      </div><span>&nbsp;&nbsp;</span> -->
                      <div class="badge badge-secondary badge-pill" title="Covid Form" *ngIf="checkCovid(item?.covid19)"
                        style="cursor: pointer;">
                        <!-- *ngIf="setPrivilege(Screen_Name.Consultation, Permission.View)" -->
                        <a (click)="openConsultModal(item?.covid19)"> <i class="fa fa-eye text-white"></i></a>
                      </div><span *ngIf="checkCovid(item?.covid19)">&nbsp;&nbsp;</span>
                      <div class="badge badge-warning badge-pill" title="Edit" style="cursor: pointer;" *ngIf="isLcl">
                        <a (click)="onEditPOC(item)"><i class="fa fa-pencil-alt text-white"></i></a>
                      </div>                  
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div [hidden]="!isUpld">
  <div class="row">
    <div class="col-xl-12">
      <div class="card mt-3 shadow cardrounded">
        <div class="card-header"><strong> Digital Prescription </strong></div>
        <div class="card-body">
          <div class="form-row small">
            <div class="form-group col-md-4">
              <div class="custom-control-inline custom-control custom-radio eRdoBtn">
                <input class="custom-control-input" type="radio" id="isUpld" value="Upload" [checked]="isDgtl=='Upload'"
                  (change)="isDgtl='Upload';isPrvwHdn=true">
                <label class="custom-control-label" for="isUpld">File Upload</label>
              </div>
              <div class="custom-control-inline custom-control custom-radio eRdoBtn">
                <input class="custom-control-input" type="radio" id="isCmra" value="Camera" [checked]="isDgtl=='Camera'"
                  (change)="isDgtl='Camera';isPrvwHdn=true">
                <label class="custom-control-label" for="isCmra">Image Capture</label>
              </div>
            </div>
            <div class="form-group col-md-4">
              <div class="custom-file" *ngIf="isDgtl=='Upload'">
                <!-- <div class="col-xl-11 col-1"> -->
                <input type="file" class="custom-file-input" id="dgtlPres" (change)="onFile($event)"
                  accept="image/jpeg,image/jpg,image/png" #dgtlPres>
                <label class="custom-file-label form-control-sm" for="dgtlPres">Digital Prescription</label>
                <span class="text-primary" *ngIf="false">Dimensions should be 300(w) * 80(h)</span>&nbsp;
                <!-- </div>
                <div class="col-xl-1 col-1"> -->
                File format should be jpg/jpeg/png&nbsp;&nbsp;&nbsp;<br />
                <span *ngIf="dgtlFile">
                  <span *ngIf="upldFile!=''">
                    <a href="javascript:void(0)" class="fa fa-eye" title="View File" (click)="viewFile()"
                      *ngIf="upldFile!=''"><i class=""></i>&nbsp;{{upldFile}}</a>
                  </span>
                </span>
                <!-- </div> -->
              </div>
            </div>
            <div class="form-group col-md-4" style="padding-top: 0.2%;" *ngIf="isDgtl=='Upload'">
              <button type="button" class="btn btn-sm btn-primary" (click)="uploadImg()"
                [disabled]="!(dgtlFile!=undefined && dgtlFile!=null)">Upload & Save</button>&nbsp;&nbsp;&nbsp;
              <button type="button" class="btn btn-sm btn-danger"
                (click)="isUpld=false;dgtlFile=null;pocId='';benfId='';patIntId='';">Cancel</button>
            </div>
          </div>
          <!-- <div class="form-row small" >
            <div class="form-group col-md-1"></div>
            <div class="form-group col-md-4" style="text-align: center;"></div>
            <div class="form-group col-md-1"></div>
            <div class="form-group col-md-4" style="text-align: center; padding-left: 10%;" *ngIf="webcamImage">
            </div>
            <div class="form-group col-md-1"></div>
          </div> -->
          <div class="row" [hidden]="isDgtl!='Camera'">
            <div class="col-xl-6">
              <span><strong>Camera</strong></span>
              <webcam [trigger]="triggerObservable" (imageCapture)="handleImage($event)" *ngIf="isDgtl=='Camera'"
                [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
                [videoOptions]="videoOptions" [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)"
                (initError)="handleInitError($event)"></webcam>
              <br>
              <div class="mt-1 text-center">
                <button class="btn btn-sm btn-orange" (click)="triggerSnapshot();isPrvwHdn=false;">Freeze</button>
              </div>
            </div>
            <div class="col-xl-6" [hidden]="isPrvwHdn">
              <div *ngIf="webcamImage">
                <span><strong>Preview</strong></span>
                <div class=" snapshot" *ngIf="webcamImage" style="text-align: center;">
                  <img [src]="webcamImage.imageAsDataUrl" class="img-fluid" />
                  <br>
                  <div class="mt-1 text-center">
                    <button *ngIf="webcamImage" class="btn btn-sm btn-success" (click)="uploadImg()">Upload &
                      Save</button>&nbsp;&nbsp;&nbsp;
                    <button *ngIf="webcamImage" class="btn btn-sm btn-danger" (click)="webcamImage=null">Clear</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="" [hidden]="isDgtl!='Camera'">

            
            <div class="form-group">
            <br />
              
               
            </div>
            <div class="form-group col-md-1"></div>
           
            <div class="form-group col-md-1"></div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>