import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

import { Common } from 'src/app/common';
import { UserService } from 'src/app/shared/services/user.service';

declare let $: any;

@Component({
  selector: 'app-org-plans-controls',
  templateUrl: './org-plans-controls.component.html',
  styleUrls: ['./org-plans-controls.component.css']
})

export class OrgPlansControlsComponent implements OnInit {

  OrgPlanForm: FormGroup; @Input() title: string; @Input() plan: any;
  planList = []; submitted = false; isChk = false; isEdit = false; plnId: any; plnName: any;

  constructor(public activeModal: NgbActiveModal
    , private frmBldr: FormBuilder
    , private usrSvc: UserService
    , private toastr: ToastrService) { }

  ngOnInit(): void {
    this.planList = [], this.initFormControls(), this.getPlans();
    if (this.plan) {
      this.isEdit = true, this.OrgPlanForm.patchValue(this.plan), this.plnId = +this.OrgPlanForm.get('id').value,
        this.plnName = this.OrgPlanForm.get('license_plan').value, this.isChk = (this.OrgPlanForm.get('patient_count').value == null);
      if (this.isChk)
        this.OrgPlanForm.get('patient_count').setValidators(null);
      else
        this.OrgPlanForm.get('patient_count').setValidators([Validators.required]);
      this.OrgPlanForm.get('patient_count').updateValueAndValidity();
    }
  }

  get f() { return this.OrgPlanForm.controls; }

  initFormControls() {
    this.OrgPlanForm = this.frmBldr.group({
      id: [0],
      agent_count: [null, Validators.required],
      doctor_count: [null, Validators.required],
      hospital_count: [null, Validators.required],
      lab_count: [null, Validators.required],
      license_plan: ['', Validators.required],
      patient_count: [null, Validators.required],
      pharmacy_count: [null, Validators.required],
    });
  }

  getPlans() {
    this.usrSvc.getAll('licenseplan/getall').subscribe(res => {
      if (res) {
        if (res.status == "OK")
          this.planList = res.data;
        else
          this.toastr.error(res.message);
      }
    });
  }

  onChngCB() {
    this.isChk = !this.isChk;
    if (this.isChk)
      this.OrgPlanForm.get('patient_count').setValidators(null), this.OrgPlanForm.get('patient_count').setValue(null);
    else
      this.OrgPlanForm.get('patient_count').setValidators([Validators.required]);
    this.OrgPlanForm.get('patient_count').updateValueAndValidity();
  }

  onValChng(val: any, type: string, maxVal: number, ulChk = false) {
    if (val > 0 && val != '' && val != null && val != undefined) {
      if (ulChk && this.isChk) {
        this.OrgPlanForm.get(type).setValue(null);
        return;
      }
      if (+val > maxVal)
        this.OrgPlanForm.get(type).setValue(maxVal);
    }
    else
      this.OrgPlanForm.get(type).setValue(null);
  }

  onSubmit(event: any) {
    // let ptntCnt = this.OrgPlanForm.get('patient_count'), payLoadData = null;
    // if (!this.isChk && ptntCnt.value == null)
    //   ptntCnt.setValidators([Validators.required]), ptntCnt.updateValueAndValidity();
    event.preventDefault(), this.submitted = true, Common.getFormValidationErrors(this.OrgPlanForm);
    if (this.OrgPlanForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else {
      this.OrgPlanForm.get('patient_count').setValue(this.isChk ? null : this.OrgPlanForm.get('patient_count').value)
      let payLoadData = this.OrgPlanForm.getRawValue();
      if (+this.OrgPlanForm.get('id').value == 0)
        delete payLoadData.id;
      this.usrSvc.save('licenseplan/save', payLoadData).subscribe(res => {
        if (res) {
          if (res.status == "OK")
            this.showMessage(res.data.id);
          else
            this.toastr.error(res.message);
        }
      });
    }
  }

  submitForm() {
    $('#btnsubmit').click();
  }

  showMessage(id: number = 0) {
    this.activeModal.close('changes saved'), this.toastr.success(`Record ${(id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Configure Plan');
  }

}