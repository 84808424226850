<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="patientForm">
            <h4>Link ABHA</h4>
            <a style="display: none;" id="autodownload" (click)="downloadHc2()">Download Health Card</a>
            <a style="display: none;" id="autodownloadnew"
                [href]="'data:application/octet-stream;base64,' + baseimgnew | safe" download="healthcard.jpg"
                (click)="downloadHc3($event)">Download Health Card</a>
            <div class="row">
                <div class="col-xl-4">
                    <!-- <div>
                           Create ABHA <input type="checkbox" name="checkbox" [(ngModel)]="isABHAChecked"
                                [ngModelOptions]="{standalone: true}" (change)="onABHACheck($event)">
                        </div> -->
                    <!-- <div class="form-group">
                        <div>
                            Do You Have ABHA / ABHA Address <input type="checkbox" name="checkbox" [(ngModel)]="isHidChecked"
                                [ngModelOptions]="{standalone: true}" (change)="onHidCheck($event)">
                        </div>
                     
                    </div> -->

                    <div class="form-check form-check-inline">
                        <input type="checkbox" name="checkbox" [(ngModel)]="isHidChecked"
                            [ngModelOptions]="{standalone: true}" (change)="onHidCheck($event)">
                        &nbsp;&nbsp; <label class="form-check-label" for="inlineCheckbox22"> Do You Have ABHA Number /
                            ABHA Address</label>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="isHidChecked">
                    <div class="form-group">
                        <div>
                            <label class="control-label">ABHA / ABHA Address </label>
                            <input type="text" autocomplete="off" maxlength="20" formControlName="health_Id"
                                class="form-control form-control-sm">
                        </div>
                    </div>
                    <div>
                        <input type="checkbox" name="checkbox" [(ngModel)]="istermsChecked"
                            [ngModelOptions]="{standalone: true}" (change)="termscheck($event)">
                        &nbsp;&nbsp; <label class="form-check-label" for="inlineCheckbox22"
                            style="color: #0061f2 !important;cursor: pointer !important;" data-toggle="modal"
                            data-target="#termsModal"> Terms and Conditions </label>
                    </div>
                    <div>
                        <button class="btn btn-sm btn-success" type="submit" value="submit" (click)="existhealthID()"
                            id="ver">Verify</button>
                        <button class="btn btn-sm btn-success ml-2" type="submit" value="submit"
                            (click)="forgotHealthID()" id="forgot">Forgot ABHA</button>&nbsp;
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="isHidChecked">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Verify With</label>
                            <!-- <select class="form-control form-control-sm" formControlName="verify_with"
                                (change)="onMethodChange($event.target.value)">
                                <option value="" selected hidden>Select</option>
                                <option *ngFor="let mthd of verifyMethods" [value]="mthd">{{mthd}}
                                </option>
                            </select> -->
                            <select class="form-control form-control-sm" formControlName="verify_with"
                                (change)="onMethodChange($event.target.value)">
                                <option value="" selected hidden>Select</option>
                                <option *ngFor="let mthd of verifyMethod" [value]="mthd.method">{{mthd.method}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <button class="btn btn-sm btn-success" type="submit" [disabled]="authBtn" value="submit"
                        (click)="authenticateIt()" id="ver2">Authenticate</button>
                    <!-- <a *ngIf ="authBtn1" [href]="'data:application/octet-stream;base64,' + baseimg | safe"
                    download="healthcard.jpg"
                     (click)="downloadHc()" >Download Health Card</a> 
                    <button *ngIf ="authBtn1" class="btn btn-sm btn-success ml-2" id="applist"  data-toggle="modal" data-target="#appModal" (click)="domSanitize()" >View Health Card</button> -->
                </div>
                <div class="col-xl-4" *ngIf="enableOtp">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Enter OTP </label>
                            <input type="text" autocomplete="off" maxlength="20" formControlName="otp_exist"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                    <button *ngIf="!authBtn1" class="btn btn-sm btn-success" (click)="confirmOTP()"
                        id="Btn">Verify</button>
                    <a *ngIf="authBtn1" [href]="'data:application/octet-stream;base64,' + baseimg | safe"
                        download="healthcard.jpg" (click)="downloadHc()">Download Health Card</a>
                    <button *ngIf="authBtn1" class="btn btn-sm btn-success ml-2" id="applist" data-toggle="modal"
                        data-target="#appModal" (click)="domSanitize()">View Health Card</button>
                </div>
                <div class="col-xl-4" *ngIf="enablepwd">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Enter Password </label>
                            <input type="password" autocomplete="off" maxlength="20" formControlName="pwd"
                                class="form-control form-control-sm" (keyup)="patternCheck($event.target.value)">
                        </div>
                        <div class="alert alert-danger" *ngIf="!patternResult">
                            Provide Strong password (ex:Asdf_12345)
                        </div>
                    </div>
                    <button class="btn btn-sm btn-success" (click)="serachHealthID()" id="Btn">Verify</button>
                </div>
            </div>
            <h4 class="mt-2" *ngIf="!isHidChecked && !forgotHid">Create ABHA</h4>
            <div class="row" *ngIf="!isHidChecked && !forgotHid" id="">
                <div class="col-xl-4">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Aadhaar Number </label>
                            <input type="text" autocomplete="off" id="craadhar" maxlength="12" formControlName="aadhar"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                    <div >
                        <input type="checkbox"  name="checkbox" [(ngModel)]="istermsChecked"
                            [ngModelOptions]="{standalone: true}" (change)="termscheck($event)">
                        &nbsp;&nbsp; <label class="form-check-label" for="inlineCheckbox22"
                            style="color: #0061f2;cursor: pointer;" data-toggle="modal" data-target="#termsModal">
                            Terms and Conditions </label>

                    </div>
                    <div >
                        <button class="btn btn-sm btn-success" (click)="GetOtp()" id="Btn">Get Aadhaar OTP</button>
                        <!-- &nbsp; -->
                        <!-- <a style="color: #0061f2;cursor: pointer;" data-toggle="modal"
                        data-target="#termsModal">Terms and Conditions</a> <input type="checkbox" class="ml-2" name="checkbox" [(ngModel)]="istermsChecked"
                            [ngModelOptions]="{standalone: true}" (change)="termscheck($event)"> -->

                        
                    </div>
                    <!-- <button hidden class="btn btn-sm" id="encAdhar" (click)="getEncryptAadhar()">Get OTP</button> -->
                    <!-- <input type="text" class="encVal" id="enc" hidden> -->
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <div class="ml-4">
                            <label class="control-label">Enter OTP </label>
                            <input type="text" autocomplete="off" maxlength="6" formControlName="otp" size="8"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                    <button class="btn btn-sm btn-success" [disabled]="entrOTP" id="Btn2"
                        (click)="verifyOTP('ver')">Submit OTP</button>
                </div>
                <div class="col-xl-4" *ngIf="showHcBtn" style="margin-top: 25px;">
                    <div class="form-group">
                        <div>
                            <a [href]="'data:application/octet-stream;base64,' + baseimg | safe"
                                download="healthcard.jpg" (click)="downloadHc()">Download Health Card</a>
                            <button class="btn btn-sm btn-success ml-2" id="applist" data-toggle="modal"
                                data-target="#appModal" (click)="domSanitize()">View Health Card</button>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="!showHcBtn">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Enter Aadhaar Linked Mobile Number </label>
                            <input type="text" autocomplete="off" maxlength="10" formControlName="adhrNum"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                    <button class="btn btn-sm btn-success" [disabled]="genOTP" id="Btn3"
                        (click)="generateOTP()">Generate OTP</button>
                </div>
                <div class="col-xl-4" *ngIf="!showHcBtn">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Enter OTP</label>
                            <input type="text" autocomplete="off" maxlength="6" formControlName="verotp"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                    <button class="btn btn-sm btn-success" id="Btn4" [disabled]="verOTP" (click)="verifyMobOTP()">Submit
                        OTP</button>

                </div>
                <div class="col-xl-4" *ngIf="showFields">
                    <div class="form-group">
                        <div>
                            <label class="control-label">First Name <span class="text-danger">*</span></label>
                            <input type="text" autocomplete="off" formControlName="firstName" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="showFields">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Middle Name</label>
                            <input type="text" autocomplete="off" maxlength="20" formControlName="middleName"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="showFields">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Last Name <span class="text-danger">*</span></label>
                            <input type="text" autocomplete="off" maxlength="20" formControlName="lastName" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="showFields">
                    <div class="form-group">
                        <div>
                            <label class="control-label">ABHA<span class="text-danger">*</span></label>
                            <input type="text" autocomplete="off" maxlength="20" formControlName="healthId"
                                class="form-control form-control-sm" (blur)="checkexhealthid()">
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="showFields">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Password <span class="text-danger">*</span> </label>
                            <input type="password" autocomplete="off" maxlength="10" formControlName="password1"
                                onkeypress="return checkPattern($event.target.value)"
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="showFields">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Email <span class="text-danger">*</span></label>
                            <div>
                                <input type="text" autocomplete="off" maxlength="60"
                                    class="form-control form-control-sm" formControlName="email1">
                                <!-- <div *ngIf="submitted && f.email1.errors" class="invalid-feedback">
                                        <div *ngIf="f.email1.errors.pattern">Enter Valid Email</div>
                                    </div> -->
                            </div>
                        </div>
                    </div>
                    <button *ngIf="!showHcBtn" class="btn btn-sm btn-success" (click)="createHealthId()">Create
                        ABHA</button>
                    <a *ngIf="showHcBtn" [href]="'data:application/octet-stream;base64,' + baseimg | safe"
                        download="healthcard.jpg" (click)="downloadHc()">Download Health Card</a>
                    <button *ngIf="showHcBtn" class="btn btn-sm btn-success ml-2" id="applist" data-toggle="modal"
                        data-target="#appModal" (click)="domSanitize()">View Health Card</button>
                </div>
            </div>
            <div class="row" *ngIf="!isHidChecked && forgotHid">
                <div class="col-xl-4">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Verify With <span class="text-danger">*</span></label>
                            <select class="form-control form-control-sm" formControlName="verify_with"
                                (change)="onMethodChange($event.target.value)">
                                <option value="" selected hidden>Select</option>
                                <option *ngFor="let mthd of verifyMethod" [value]="mthd.method">{{mthd.method}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <!-- <button class="btn btn-sm btn-success" type="submit" value="submit" (click)="forgotAuth()" >Verify</button> -->
                    <!-- <a *ngIf ="authBtn1" [href]="'data:application/octet-stream;base64,' + baseimg | safe"
                    download="healthcard.jpg"
                     (click)="downloadHc()" >Download Health Card</a> 
                    <button *ngIf ="authBtn1" class="btn btn-sm btn-success ml-2" id="applist"  data-toggle="modal" data-target="#appModal" (click)="domSanitize()" >View Health Card</button> -->
                </div>

                <div class="col-xl-4" *ngIf="!isDemoGraphic && !isForgotMob">
                    <div class="form-group">
                        <div>
                            <label class="control-label"> Aadhaar Number <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" maxlength="12" formControlName="aadhar"
                                class="form-control form-control-sm">
                        </div>
                    </div>
                    <button class="btn btn-sm btn-success" (click)="forgotAuth()" id="Btn">Get OTP</button>
                    <!-- <button hidden class="btn btn-sm" id="encAdhar" (click)="getEncryptAadhar()">Get OTP</button> -->
                    <!-- <input type="text" class="encVal" id="enc" hidden> -->
                </div>
                <div class="col-xl-4" *ngIf="!isDemoGraphic && !isForgotMob">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Enter OTP </label>
                            <input type="text" autocomplete="off" maxlength="6" formControlName="otp"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                    <button class="btn btn-sm btn-success" [disabled]="entrOTP" id="Btn2"
                        (click)="verifyForgotOTP('forgot')">Submit Aadhaar OTP</button>
                </div>
                <div class="col-xl-4" *ngIf="isDemoGraphic">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Name <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" formControlName="name" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="isDemoGraphic">
                    <div class="form-group">
                        <div>
                            <label class="control-label"> Gender <span class="text-danger">*</span> </label>
                            <div>
                                <select class="form-control form-control-sm" formControlName="gender">
                                    <option value="" selected hidden>Select</option>
                                    <option *ngFor="let gen of genderList" [value]="gen.id">{{gen.gender_name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="isDemoGraphic">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Mobile <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" maxlength="10" formControlName="mob"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="isDemoGraphic">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Year Of Birth <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" maxlength="4" formControlName="yob"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="isDemoGraphic" style="margin-top: 30px;">
                    <button class="btn btn-sm btn-success" (click)="verifyDemographics()" id="Btn">Verify
                        Demographics</button>
                </div>
                <div class="col-xl-4" *ngIf="isDemoGraphicM">
                    <div class="form-group">
                        <div>
                            <label class="control-label"> Authentication Methods <span class="text-danger">*</span>
                            </label>
                            <div>
                                <!-- <select class="form-control form-control-sm" formControlName="verify_with"
                                    (change)="onAuthMethod($event.target.value)">
                                    <option value="" selected hidden>Select</option>
                                    <option *ngFor="let mthd of demoGraphRes" [value]="mthd">{{mthd}}
                                    </option>
                                </select> -->
                                <select class="form-control form-control-sm" formControlName="verify_with"
                                    (change)="onAuthMethod($event.target.value)">
                                    <option value="" selected hidden>Select</option>
                                    <option *ngFor="let mthd of verifyMethodnew" [value]="mthd.method">{{mthd.method}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="isDgAadhr">
                    <div class="form-group">
                        <div>
                            <label class="control-label">{{verifyMthd}} <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" maxlength="12" formControlName="aadhar"
                                class="form-control form-control-sm">
                        </div>
                    </div>
                    <button class="btn btn-sm btn-success" (click)="forgotAuth()" id="Btn">Get OTP</button>
                    <!-- <button hidden class="btn btn-sm" id="encAdhar" (click)="getEncryptAadhar()">Get OTP</button> -->
                    <!-- <input type="text" class="encVal" id="enc" hidden> -->
                </div>
                <div class="col-xl-4" *ngIf="isDgAadhr">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Enter OTP </label>
                            <input type="text" autocomplete="off" maxlength="6" formControlName="otp"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                    <button class="btn btn-sm btn-success" [disabled]="entrOTP" id="Btn2"
                        (click)="verifyForgotOTP('forgot')">Submit OTP</button>
                </div>

                <!-- <div class="col-xl-4" *ngIf="showfrgtBtn">
                    <div class="form-group">
                        <div>
                            <a [href]="'data:application/octet-stream;base64,' + baseimg | safe"
                                download="healthcard.jpg" (click)="downloadHc()">Download Health Card</a>
                            <button class="btn btn-sm btn-success ml-2" id="applist" data-toggle="modal"
                                data-target="#appModal" (click)="domSanitize()">View Health Card</button>
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-4" *ngIf="isForgotMob">
                    <div class="form-group">
                        <div>
                            <label class="control-label"> Mobile Number <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" maxlength="10" formControlName="aadhar"
                                class="form-control form-control-sm">
                        </div>
                    </div>
                    <button class="btn btn-sm btn-success" (click)="forgotAuth()" id="Btn">Get OTP</button>
                    <!-- <button hidden class="btn btn-sm" id="encAdhar" (click)="getEncryptAadhar()">Get OTP</button> -->
                    <!-- <input type="text" class="encVal" id="enc" hidden> -->
                </div>
                <div class="col-xl-4" *ngIf="isForgotMob">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Enter OTP </label>
                            <input type="text" autocomplete="off" maxlength="6" formControlName="otp"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="isForgotMob">
                    <div class="form-group">
                        <div>
                            <label class="control-label">First Name <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" maxlength="20" formControlName="f_name" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <!-- Uncommented below lines for middle name 12-05-2022 #chandu  -->
                <div class="col-xl-4" *ngIf="isForgotMob">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Middle Name </label>
                            <input type="text" autocomplete="off" maxlength="20" formControlName="m_name" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <!--middle name lines ended 12-05-2022 #chandu    -->
                <div class="col-xl-4" *ngIf="isForgotMob">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Last Name <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" maxlength="20" formControlName="l_name" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="isForgotMob">
                    <div class="form-group">
                        <div>
                            <label class="control-label"> Gender <span class="text-danger">*</span> </label>
                            <div>
                                <select class="form-control form-control-sm" formControlName="gender">
                                    <option value="" selected hidden>Select</option>
                                    <option *ngFor="let gen of genderList" [value]="gen.id">{{gen.gender_name}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="isForgotMob">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Day Of Birth <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" maxlength="2" formControlName="db"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="isForgotMob">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Month Of Birth <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" maxlength="2" formControlName="mb"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="isForgotMob">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Year Of Birth <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" maxlength="4" formControlName="yob"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="isForgotMob" style="margin-left: 55rem;">
                    <!-- <div class="form-group">
                        <div>
                            <label class="control-label">Enter OTP </label>
                            <input type="text" autocomplete="off" maxlength="6" formControlName="otp"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                        </div>
                    </div> -->
                    <button class="btn btn-sm btn-success" [disabled]="entrOTP" id="Btn2"
                        (click)="verifyForgotOTP('forgot')">Submit Mobile OTP</button>
                </div>
                <div class="col-xl-4" *ngIf="showfrgtBtn">
                    <div class="form-group">
                        <div>
                            <!-- <label class="control-label" > View And Download Health Card </label> -->
                            <a [href]="'data:application/octet-stream;base64,' + baseimg | safe"
                                download="healthcard.jpg" (click)="downloadHc()">Download Health Card</a>
                            <button class="btn btn-sm btn-success ml-2" id="applist" data-toggle="modal"
                                data-target="#appModal" (click)="domSanitize()">View Health Card</button>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-4">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Enter Aadhar Linked Mobile Number </label>
                            <input type="text" autocomplete="off" maxlength="10" formControlName="adhrNum"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                    <button class="btn btn-sm btn-success"  [disabled]="genOTP" id="Btn3" (click)="generateOTP()">Generate OTP</button>
                </div> -->
            </div>
            <h4 class="mt-2">Patient Details</h4>
            <div class="row">
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">First Name <span class="text-danger">*</span> </label>
                        <input type="text" autocomplete="off" maxlength="20" formControlName="first_name"
                            class="form-control form-control-sm text-uppercase" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                            [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }">
                        <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                            <div *ngIf="f.first_name.errors.required"> First Name is required</div>
                        </div>
                    </div>
                </div>
                <!-- Middle Name line started #chandu 16-05-2022 -->
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Middle Name</label>
                        <input type="text" autocomplete="off" maxlength="20" formControlName="middle_name"
                            class="form-control form-control-sm text-uppercase" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'>
                    </div>
                </div>
                <!-- Middle name end 16-05-2022 -->
                <div class="col-xl-4">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Last Name <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" formControlName="last_name" maxlength="20"
                                [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm text-uppercase">
                            <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                <div *ngIf="f.last_name.errors.required">Last Name is required</div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Age <span class="text-danger">*</span> </label>
                        <input type="text" autocomplete="off" formControlName="age" maxlength="3"
                            [ngClass]="{ 'is-invalid': submitted && f.age.errors }"
                            onkeypress='return(event.charCode >= 48 && event.charCode <= 57)'
                            class="form-control form-control-sm text-uppercase">
                        <div *ngIf="submitted && f.age.errors" class="invalid-feedback">
                            <div *ngIf="f.age.errors.required">Age is required</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label"> Mobile Number <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="mobile" id=" mobile"
                                [ngClass]="{ 'is-invalid': submitted && f. mobile.errors }" [maxlength]="maxLngth"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                <div *ngIf="f.mobile.errors?.required">Mobile Number is required</div>
                                <div *ngIf="f.mobile.errors.pattern">Mobile Number {{mobErrMsg}}</div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-xl-4" [hidden]="ispwd">
                    <div class="form-group">
                        <label class="control-label">Set Password <span class="text-danger">*</span> </label>
                        <input type="password" autocomplete="off" formControlName="password"
                            onkeypress="return checkPattern($event.target.value)"
                            [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                            class="form-control form-control-sm">
                        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                            <div *ngIf="f.password.errors.required">Set Password is required</div>
                        </div>
                    </div>

                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Email </label>
                        <div>
                            <input type="text" autocomplete="off" maxlength="30" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.pattern">Enter Valid Email</div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row">
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Gender <span class="text-danger">*</span> </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="gender_id"
                                [ngClass]="{ 'is-invalid': submitted && f.gender_id.errors }">

                                <option value="" selected hidden>Select</option>
                                <option *ngFor="let gen of genderList" [value]="gen.id">{{gen.gender_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.gender_id.errors" class="invalid-feedback">
                                <div *ngIf="f.gender_id.errors.required">Gender is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- new lines added for block and village 28-07-2022 #KRANTHI -->
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">{{isNIG ? 'States' : 'State'}} <span class="text-danger">*</span>
                        </label>
                        <div>
                            <select formControlName="state_id" id="state_id" #State
                                (change)="locationChanged(State.value,'district')"
                                [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let state of stateList" [value]="state.id">
                                    {{state.state_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                <div *ngIf="f.state_id.errors.required">
                                    {{isNIG ? 'States' : 'State'}} is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Location Type <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="location_type_id" id="location_type_id" #locationId
                                [ngClass]="{ 'is-invalid': submitted && f.location_type_id.errors }"
                                (change)="locationChanged('','location')" class="form-control form-control-sm"
                                [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let location of locationTypeList" [value]="location.id">
                                    {{location.location_type}}</option>
                            </select>
                            <div *ngIf="submitted && f.location_type_id.errors" class="invalid-feedback">
                                <div *ngIf="f.location_type_id.errors.required">Location Type is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">{{isNIG ? 'State Capitals' : 'District'}} <span
                                class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="district_id" id="district_id" #District
                                (change)="locationChanged(District.value,(+locationId.value==2)?'city':'mandal')"
                                [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }"
                                class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let district of districtList" [value]="district.id">
                                    {{district.district_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                                <div *ngIf="f.district_id.errors.required">
                                    {{isNIG ? 'State Capitals' : 'District'}} is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">
                            {{isSAGE ? 'Location' : (isNIG ? 'Local Government Areas' : 'Block')}}
                            <span *ngIf="+locationId?.value==1" class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="mandal_id" id="mandal_id" #Mandal
                                [attr.disabled]="(+locationId.value==2 || isEditable)?true:null"
                                (change)="locationChanged(Mandal.value,'village')"
                                [ngClass]="{ 'is-invalid': submitted && f.mandal_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let mandal of mandalList" [value]="mandal.id">
                                    {{mandal.mandal_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.mandal_id.errors" class="invalid-feedback">
                                <div *ngIf="f.mandal_id.errors.required">
                                    {{isSAGE ? 'Location' : (isNIG ? 'Local Government Areas' : 'Block')}} is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- </div>
            <div class="row"> -->
                <div class="col-xl-4" *ngIf="+locationId.value==2">
                    <div class="form-group">
                        <label class="control-label">City </label>
                        <div>
                            <select formControlName="city_id" id="city_id"
                                [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }"
                                class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null">
                                <option value="">Select</option>
                                <option *ngFor="let city of cityList" [value]="city.id">
                                    {{city.city_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
                                <div *ngIf="f.city_id.errors.required">City is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="+locationId.value != 2 && !isNIG">
                    <div class="form-group">
                        <label class="control-label">Village <span *ngIf="+locationId.value==1"
                                class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="village_id" id="village_id"
                                [attr.disabled]="(+locationId.value==2 || isEditable)?true:null"
                                [ngClass]="{ 'is-invalid': submitted && f.village_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let village of villageList" [value]="village.id">
                                    {{village.village_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.village_id.errors" class="invalid-feedback">
                                <div *ngIf="f.village_id.errors.required">Village is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--end of new lines added for block and village 27-07-2022 #KRANTHI -->


            </div>
            <div class="row">
                <div class="col-xl-4">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Address </label>
                            <input type="text" autocomplete="off" maxlength="50" formControlName="address"
                                class="form-control form-control-sm text-uppercase">
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-xl-4" style="margin-top: 27px;">
                    <div class="form-group">
                        <div>
                            <label class="control-label"></label>
                          <button class="btn btn-sm btn-primary"   id="applist" data-toggle="modal" data-target="#appModal"
                >Generate HealthID</button>
                        </div>
                    </div>
                    <button class="btn btn-sm btn-success" id="Btn4"  (click)="verifyMobOTP()">Submit OTP</button>
                   
                </div> -->


            <div class="row" style="display: none;">
                <div class="col-xl-12">
                    <div class="table-responsive">
                        <table class="table table-bordered table-sm">
                            <tbody>
                                <tr>
                                    <td>
                                        <input type="file" class="d-none" id="aadhaar" #aAdhar
                                            (change)="onFileUpload($event,1,'26','proofFile')">
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="aAdhar.click()"> Upload ABHA Health Card </p>
                                        <!-- <span class="text-danger">*</span> -->
                                        <p class="small mb-1">jpg only</p>
                                        <!-- <div *ngIf="submitted && f.proofFile.errors" class="invalid-feedback">
                                            <div *ngIf="f.proofFile.errors.required">{{isSAGE ? 'National ID' :
                                                'ID Proof'}} is required</div>
                                        </div> -->
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill pointer" title="Cancel File"
                                                *ngIf="uploadFileList[0]?.File || isShwPic" (click)="cancelFile(1)">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)"
                                            (click)="viewFile(1)">{{uploadFileList[0]?.file_name}}</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <!-- <input type="submit" id="btnsubmit" style="display: none;" (click)="onSubmit($event)"> -->
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" value="Submit" (click)="onSubmit($event)">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
<div class="modal fade t10" id="appModal">
    <div class="modal-dialog">
        <div class="modal-content modalpopborder" style="width: 51rem;margin-left: -16rem;">
            <div class="modal-header headerbg modalpopborderhead p-2">
                <h6 class="modal-title text-white">Health Card</h6>
                <button type="button" id="closeid" class="close text-white" (click)="close()">&times;</button>
            </div>
            <div class="modal-body modalbodybg">
                <img [src]="myBase64" alt="Image Source" style="width: 50rem;">
            </div>
        </div>
    </div>
</div>
<div class="modal fade t10" id="termsModal">
    <div class="modal-dialog">
        <div class="modal-content modalpopborder" style="width: 51rem;margin-left: -16rem;">
            <div class="modal-header headerbg modalpopborderhead p-2">
                <h6 class="modal-title text-white">Terms and Conditions</h6>
                <button type="button" id="closeid" class="close text-white" (click)="closeterms()">&times;</button>
            </div>
            <div class="modal-body modalbodybg" style="height: 315px;">
                <p style="padding: 0 10px !important;
               margin: 0 !important;
               text-align: justify !important;">
                    &nbsp; &nbsp; I, hereby declare that I am voluntarily sharing my Aadhaar Number / Virtual ID and
                    demographic information issued by UIDAI, with National Health Authority (NHA) for the sole purpose
                    of creation of ABHA. I understand that my ABHA can be used and shared for purposes as may be
                    notified by ABDM from time to time including provision of healthcare services. Further, I am aware
                    that my personal identifiable information (Name, Address, Age, Date of Birth, Gender and Photograph)
                    may be made available to the entities working in the National Digital Health Ecosystem (NDHE) which
                    inter alia includes stakeholders and entities such as healthcare professionals (e.g. doctors),
                    facilities (e.g. hospitals, laboratories) and data fiduciaries (e.g. health programmes), which are
                    registered with or linked to the Ayushman Bharat Digital Mission (ABDM), and various processes there
                    under.
                    I authorize NHA to use my Aadhaar number / Virtual ID for performing Aadhaar based authentication
                    with UIDAI as per the provisions of the Aadhaar (Targeted Delivery of Financial and other Subsidies,
                    Benefits and Services) Act, 2016 for the aforesaid purpose. I understand that UIDAI will share my
                    e-KYC details, or response of “Yes” with NHA upon successful authentication.
                </p>
                <br>
                <!-- <span style="margin-left: 42rem;color: #0061f2;cursor: pointer;" (click)="showmoreclick()">More Info</span> -->
                <!-- <p *ngIf="showmore"> -->
                <p style="padding: 0 10px!important;
                   margin: 0 !important;
                   text-align: justify !important;">
                    &nbsp; &nbsp; I have been duly informed about the option of using other IDs apart from Aadhaar;
                    however, I consciously choose to use Aadhaar number / Virtual ID for the purpose of availing
                    benefits across the NDHE. I am aware that my personal identifiable information excluding Aadhaar
                    number / VID number can be used and shared for purposes as mentioned above. I reserve the right to
                    revoke the given consent at any point of time as per provisions of Aadhaar Act and Regulations.
                </p>
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal fade t10" id="appModal" *ngIf="isShowModal">
    <div class="modal-dialog">
      <div class="modal-content modalpopborder">  
        <div class="modal-header headerbg modalpopborderhead p-2">
          <h6 class="modal-title text-white">Fill The Form For Generating HealthID</h6>
          <button type="button" id="closeid" class="close text-white" (click)="close()" >&times;</button>
        </div>  
        <div class="modal-body modalbodybg">
         <form [formGroup]="ndhmForm">
             <div class="row">
                <div class="col-xl-4" >
                    <div class="form-group">
                        <div>
                            <label class="control-label">First Name <span class="text-danger">*</span></label>
                            <input type="text" autocomplete="off" maxlength="30" formControlName="firstName" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" >
                    <div class="form-group">
                        <div>
                            <label class="control-label">Middle Name</label>
                            <input type="text" autocomplete="off" maxlength="30" formControlName="middleName"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" >
                    <div class="form-group">
                        <div>
                            <label class="control-label">Last Name <span class="text-danger">*</span></label>
                            <input type="text" autocomplete="off" maxlength="30" formControlName="lastName" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Password <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" maxlength="10" formControlName="password1"
                         class="form-control form-control-sm">
                           <div *ngIf="submitted && f.password1.errors" class="invalid-feedback">
                                <div *ngIf="f.password1.errors.pattern">Password Must Contain One Small,Capital letters & Numerical  </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Email <span class="text-danger">*</span></label>
                            <div>
                                <input type="text" autocomplete="off" maxlength="60"
                                class="form-control form-control-sm" formControlName="email1">
                                <div *ngIf="submitted && f.email1.errors" class="invalid-feedback">
                                    <div *ngIf="f.email1.errors.pattern">Enter Valid Email</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-sm btn-success" (click)="createHealthId()">Create HealthID</button>
                </div>
             </div>
         </form>
        </div>
      </div>
    </div>
    </div> -->