<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Users Details
                        <button class="btn btn-blue btn-icon float-right"
                            (click)="modalTitle='User Details';openModal()" *ngIf="setPrivilege(Screen_Name.UserManagement, Permission.Add)">
                            <i class="fa fa-plus"></i>
                        </button>
                    </div>
                    <div class="container-fluid" *ngIf="roleId==1">
                        <div class="row mt-4">
                            <div class="col-xl-4">
                                <div class="form-group">
                                    <label class="control-label"><strong>Organization</strong> <span class="text-danger">*</span> </label>
                                    <select id="organization_id" class="form-control form-control-sm"
                                        (change)="tenantId=$event.target.value;getAllUsers()">
                                        <option value="">Select</option>
                                        <option *ngFor="let org of orgList" [value]="org.id">{{org.orgnization_name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="col-md-9 mb-3" style="margin-top: 2rem;" >
                            <button type="button" class="btn  btn-sm btn-secondary float-right"  *ngIf="excelData && excelData.length>0" (click)="downLoadExcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                        </div>
                        <div class="table-responsive">
                            <table datatable id="userTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>S No </th>
                                        <th>Full Name</th>
                                        <th>Mobile No</th>
                                        <th>Login Name</th>
                                        <th>Organization</th>
                                        <th>Role</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of userList;let i = index;">
                                        <td style="width: 6%;" class="text-center">{{i+1}}</td>
                                        <td class="text-uppercase">{{item.first_name+' '+item.last_name}}</td>
                                        <td style="width: 12%;">{{item.mobile_no}}</td>
                                        <td style="width: 18%;">{{item.login_name}}</td>
                                        <td style="width: 15%;">{{item.orgnization_name}}</td>
                                        <td>{{item.m_usertype}}</td>
                                        <td style="width: 6%;">
                                            <div (click)="modalTitle='Update User Details';getUserbyId(item.user_id)"
                                                *ngIf="setPrivilege(Screen_Name.UserManagement, Permission.Edit)"
                                                class="badge badge-info pointer"> <i class="fa fa-edit text-white"></i>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>