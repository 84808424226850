<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Referral Patient List 
                        <!-- <div  class='col-md-2'>
                            <button data-toggle="modal"
                            (click)="modalTitle='Patient Registration Details'; openPatientModal()"
                                   class="btn btn-blue btn-icon float-right"> <i class="fa fa-plus"></i> 
                               </button>
                        </div> -->
                    </div>
                    <div class="card-body">
                        <form [formGroup]="refpatform" (ngSubmit)="LoadData()">
                            <div class="row ">
                              <div class="container">
                                <div class="row ">
                                  <div class='col-md-3'>
                                    <div class="form-group">
                                      <div class='date' id='datetimepicker6'>
                                        <label class="control-label">From Date  </label>
                                        <div>
                                          <input id="from_date" name="from_date" type="text" autocomplete="off" onpaste="return false"
                                            placeholder="From" bsDatepicker bsDatepicker #dp="bsDatepicker" (focus)="dp.show()"
                                            (focus)="dp1.hide()" formControlName="from_date" [maxDate]="max_date"
                                            onkeypress='return event.charCode == 8'
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                                            
                                            class="form-control form-control-sm">
                                       
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class='col-md-3'>
                                    <div class="form-group">
                                      <div class='date' id='datetimepicker7'>
                                        <label class="control-label">To Date  </label>
                                        <div>
                                          <input id="to_date" name="to_date" type="text" autocomplete="off" onpaste="return false"
                                            placeholder="To" bsDatepicker #dp1="bsDatepicker" (focus)="dp1.show()" (focus)="dp.hide()"
                                            formControlName="to_date"
                                            [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}" [maxDate]="max_date"
                                            [minDate]="f.from_date.value" 
                                             class="form-control form-control-sm"
                                            onkeypress='return event.charCode == 8'>
                                       
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class='col-md-2' style="margin-top: 2rem;">
                                    <button type="submit" class="btn btn-sm btn-primary" value="Submit"
                                      (focus)="dp1.hide()">Submit</button>&nbsp;&nbsp;
                                    <!-- <button type="button" class="btn btn-sm btn-danger" (click)="clear()">Clear</button> -->
                                  </div>
                                  <div class="col-xl-1 text-right" style="padding-top: 2%;">
                                    <!-- <button type="submit" (click)="onAddEdit()" class="btn btn-info mb-2 mr-1 text-center"><i class="fa fa-plus"></i> Add</button> -->
                                    <button type="button" class="btn btn-secondary mb-2 mr-1 text-center"(click)="exportexcel()" >
                                       <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                                </div>
                                </div>
                              </div>
                            </div>
                          </form>
                        <div class="table-responsive">
                            <table datatable id="referalpatient" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th class="text-center" style="font-size:15px;">S.No</th>
                                        <!-- <th style="font-size:15px;">Arogya Kendra Code</th> -->
                                        <th style="font-size:15px;">Patient Name</th>
                                        <th style="font-size:15px;width: 15%;">Mobile Number</th>
                                        <th style="font-size:15px;width: 15%;">Schedule Date</th>
                                        <th style="font-size:15px;">Status</th>
                                        <th style="font-size:15px;width: 20%;">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of getreferalpatientdata; let i = index">
                                        <td>{{i+1}}</td>
                                        <td>{{item.beneficiary_name}}</td>
                                        <td style="width: 15%;">{{item.beneficiary_mobile}}</td>
                                        <td style="width: 15%;">{{item.schedule_date  | date: 'dd-MM-yyyy'}}</td>
                                        <td>{{item.referral_type_id_new}}</td>
                                        <!-- <td *ngIf="item.referral_type_id== 2">In Progress</td>
                                        <td *ngIf="item.referral_type_id== 3">Pending</td>
                                        <td *ngIf="item.referral_type_id== 4">Close</td> -->
                                        
                                        <td style="width: 20%;">
                                            <button  class="btn btn-sm btn-success" data-toggle="modal" data-target="#scheduledateid" (click)="scheduledateclick(item)">Schedule Date</button>
                                            <button  class="btn btn-sm btn-success ml-2" (click)="OfflinePocSubmit(item)">POC</button>&nbsp;&nbsp;
                                           
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="modal fade" data-backdrop="static" data-keyboard="false" id="scheduledateid" tabindex="-1" role="dialog"
    aria-labelledby="scheduledateidTitle" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg " role="document">
      <div class="modal-content panelbg">
        <div class="modal-body">
          <h5>Referral Patient</h5>
          <div>
            <!-- <div class="col-md-5 col-6 pr-0"> -->
              <form [formGroup]="refpatformmodal">
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Schedule Date<span class="text-danger"></span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="schedule_date" [minDate]="max_date" placeholder="Select Date" (click)="datenewclick()"  id="reg_expiry_date" 
                                bsDatepicker #dp1="bsDatepicker"  class="form-control form-control-sm"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                >
                            <!-- <div *ngIf="submitted && f.reg_expiry_date.errors" class="invalid-feedback">
                                <div *ngIf="f.reg_expiry_date.errors.required">Registration Expiry Date is required</div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                      <label class="control-label">Status </label>
                      <div>
                        <select formControlName="referral_type_id"  id="statusdata"
                           class="form-control form-control-sm">
                          <option value="null">Select</option>
                          <option *ngFor="let sts of statusget" [value]="sts.id">
                            {{sts.referral_type}}</option>
                        </select>

                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4">
                   
                      <button type="button" (click)="saverefdata()"  class="btn btn-sm btn-primary" style="margin-top: 30px;" >
                        Submit</button>

                    </div>
                
                </div>
              
                
            </form>
          <div class="modal-footer">
             
          </div>
        </div>
      
        
          <button type="button"  class="btn btn-danger" data-dismiss="modal" style="margin-left:90%;height:33px;" (click)="closemodal()">
            Close<br /></button>
         
        </div>
      </div>
    </div>
  </div>
</div>