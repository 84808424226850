
                  <div class="row">
                    <div class="container-fluid pl-0 pr-0">
                        <div class="row">
                            <div class="col-xl-12">
                                <div class="card card-header-actions mx-auto">
                                      <div class="card-header">
                                           Mandal Registration
                                        <button class="btn btn-blue btn-icon float-right"
                                        (click)="modalTitle='STATE LIST';openMandalModal()">
                                            <i class="fa fa-plus"></i>
                
                                        </button>
                                    </div>
                                    
                                        <div class="card-body">
                                          <form [formGroup]="mandalListForm" (ngSubmit)="loadMandals()">

                                           
                <div class="row">
                                                <div class="col-xl-3">
                                                    <div class="form-group">
                                                      <label class="control-label">State <span class="text-danger">*</span> </label>
                                                      <div>
                                                        <select  formControlName="state_id" id="state_id" #State (change)="locationChanged(State.value,'district')"
                                                        class="form-control form-control-sm">
                                                       <option [value]="0">Select</option>
                                                       <option *ngFor="let state of stateList" [value]="state.id">
                                                         {{state.state_name}}</option>
                                                     </select>
                                                
                                                      </div>
                                                    </div>
                                                  </div>

                                                  <div class="col-xl-3">
                                                    <div class="form-group">
                                                      <label class="control-label">District <span class="text-danger">*</span> </label>
                                                      <div>
                                                        <select formControlName="district_id" id="district_id" #District
                                                          (change)="locationChanged(District.value,'mandal')"
                                                           class="form-control form-control-sm">
                                                          <option [value]="0">Select</option>
                                                          <option *ngFor="let district of districtList" [value]="district.id">
                                                            {{district.district_name}}</option>
                                                        </select>
                                                      
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div class="col-sm-2">
                                                    <!-- <input type="submit" class="btn btn-sm btn-primary" (click)="loadcities()" value="Submit">&nbsp;&nbsp; -->
        
                                                    <button type="button" class="btn btn-primary btn-sm" style="margin-top:2rem;" (click)="loadMandals()" >Submit</button>
                                                     </div>
                                                  </div>
                                                
                                                  <!-- <input type="submit"  id="btnsubmit" style="display: none;"> -->
                                          </form>
                                         
                                    </div>
                                  
                                 
                                   
                                   
                
                                    <div class="card-body">
                                        <div class="table-responsive">
                                         
                      <table datatable id="districtTable" class="table table-striped table-bordered table-sm">
                                                      <thead>
                                                          <tr>
                                                              <th>S No</th>
                                                              <th>District Name  </th> 
                                                              <th style="width:10%;">Actions</th>
                                                          </tr>
                                                      </thead>
                                                      <tbody>
                                                                <tr *ngFor="let mandal of mandallist;let ind=index;">
                                                                  <td>{{(ind+1)}}</td>
                                                                     <td>{{mandal.mandal_name}}</td>
                                                             
                                                              <td>
                                                                 
                          <div class="badge badge-success badge-pill"> <a (click)="modalTitle='Edit State Details';openMandalModal()"> <i  class="fa fa-edit text-white"></i></a></div>&nbsp;&nbsp;
                          <div class="badge badge-danger badge-pill"><a ><i class="fa fa-trash text-white"></i></a></div> </td> 
                                                           
                                                          </tr>
                                                      </tbody>
                                                  </table>
                                           
                                        </div>
                
                                    </div>
                                </div>
                            </div>
                        </div>
                
                    </div>
                </div>  