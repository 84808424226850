<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        {{roleId != 7 ? 'Doctor Registration' : 'Doctor Slots'}}
                        <button class="btn btn-blue btn-icon float-right"
                            (click)="modalTitle='Doctor Registration Details';ChkLimit()"
                            *ngIf="setPrivilege(Screen_Name.DoctorRegistrationwithSlotscheduling, Permission.Add)">
                            <i class="fa fa-plus"></i> <!-- openDoctorModal() -->
                        </button>
                    </div>

                    <div class="card-body">
                        <div class="col-md-9 mb-3" style="margin-top: 2rem;" >
                            <button type="button" class="btn  btn-sm btn-secondary float-right" *ngIf ="excelData.length>0" (click)="downLoadExcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                        </div>
                        <div class="table-responsive">  
                            <table datatable id="doctorTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th style="width:7%;" class="singleLine">S No </th>
                                        <th>District <span>(Based on centre location)</span></th>
                                        <th>Block <span>(Based on centre location)</span></th>
                                        <th>Hospital</th>
                                        <th>Category Type</th>
                                        <th>Hub/Centre/Referral Name</th>
                                        <th class="singleLine">Doctor Name</th>
                                        <th>Qualification</th>
                                        <th>Specialization</th>
                                        <th>Department</th>
                                        <th>Mobile</th>
                                        <th>Email ID</th>
                                        <th>MCI Number</th>
                                        <th>Experience</th>
                                        <th class="text-center" style="width:12%;">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let doctor of doctorList;let ind=index;">
                                        <td class="singleLine text-center">{{(ind+1)}}</td>
                                        <td>{{doctor.district_name}}</td>
                                        <td>{{doctor.mandal_name}}</td>
                                        <td>{{doctor.hospital_name}}</td>
                                        <td>{{doctor.center_type}}</td>
                                        <td *ngIf="doctor.center_type==='Center'">{{doctor.center_name}}</td>
                                        <td *ngIf="doctor.center_type==='Hub'">{{doctor.hub_name}}</td>
                                        <td *ngIf="doctor.center_type==='Referral'">{{doctor.hospital_type}}</td> 
                                        <!-- <td class="singleLine text-uppercase" style="color:blue;cursor: pointer;"
                                            (click)="getdatesincal(doctor.id)" id="slotsAvailableModal"
                                            data-toggle="modal" data-target="#slotsModalCenter">{{doctor.first_name+'
                                            '+doctor.last_name}}</td> -->
                                            <!-- <td class="singleLine text-uppercase" style="color:blue;cursor: pointer;">{{doctor.first_name+'  '+doctor.last_name}}</td> -->
                                            <td class="singleLine text-uppercase">{{doctor.first_name+'  '+doctor.last_name}}</td>
                                        <td>{{doctor.qualificationName + ((doctor.superDegree != undefined) ? ', ' +
                                            doctor.superDegree : '' )}}</td>
                                            <td>{{doctor.specialization_name}}</td>
                                            <td>{{doctor.department_name}}</td>
                                        <td>{{doctor.mobile}}</td>
                                        <td>{{doctor.email}}</td>
                                        <td>{{doctor.mci_number}}</td>
                                        <td>{{doctor.years_experience}}</td>
                                        <td class="singleLine">
                                            <span>
                                                <a class="badge badge-info badge-pill pointer" title="Edit"
                                                    (click)="modalTitle='Doctor Registration Details';onDocEdit(doctor.id)"
                                                    *ngIf="setPrivilege(Screen_Name.DoctorRegistrationwithSlotscheduling, Permission.Edit)">
                                                    <i class="fa fa-edit text-white"></i></a>&nbsp;
                                            </span>
                                            <span>
                                                <a class="badge badge-danger badge-pill pointer" title="Delete"
                                                    (click)="onDelete(doctor.id)"
                                                    *ngIf="setPrivilege(Screen_Name.DoctorRegistrationwithSlotscheduling, Permission.Delete)">
                                                    <i class="fa fa-trash text-white"></i></a>
                                            </span>
                                            &nbsp;
                                            <span>
                                                <a class="badge badge-success badge-pill pointer"
                                                    title="Schedule Slots">
                                                    <i class="fa fa-clock text-white"
                                                        (click)="modal(doctor.id,doctor.first_name+' '+doctor.last_name)"
                                                        data-toggle="modal" data-target="#myModal"
                                                        aria-hidden="true"></i></a>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        
                        <div class="modal fade" data-backdrop="static" data-keyboard="false" id="slotsModalCenter"
                            tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div class="modal-dialog modal-dialog-centered modal-lg " role="document">
                                <div class="modal-content panelbg">
                                    <div class="modal-body">
                                        <h5>Slots Available in Month</h5>
                                        <!-- <ngb-datepicker id='slots'  (dateSelect)="onDateSelect($event)"></ngb-datepicker> -->
                                        <ngb-datepicker #d [(ngModel)]="model"></ngb-datepicker>
                                        <!-- <ngb-multi-date-picker [datesSelected]="datesSelected" (datesSelectedChange)="change($event)"></ngb-multi-date-picker> -->
                                    </div>
                                </div>
                                
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-light" (click)="activeModal.close('Close click')">Close</button>
                                    </div>
                                

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="myModal" data-backdrop="static" data-keyboard="false" class="modal fade" role="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h4>Doctor Available Slots </h4>
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-xl-12">
                            <div class="form-group">
                                <label class="control-label"> Doctor Name <span class="text-danger">*</span> </label>
                                <div>
                                    <input type="text" autocomplete="off" [ngModel]="doctor_name" id="first_name"
                                        disabled maxlength="50" placeholder="Name"
                                        class="form-control form-control-sm text-uppercase">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-xl-3">
                            <div class="form-group">
                                <label class="control-label">From Date<span class="text-danger">*</span></label>
                                <div>
                                    <input type="text" autocomplete="off" id="week_startDate"
                                        [(ngModel)]="week_startDate" placeholder="From Date" bsDatepicker
                                        [daysDisabled]="[0,2,3,4,5,6]" [minDate]="start_minDate"
                                        (bsValueChange)="dateChange($event)" readonly
                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                        class="form-control form-control-sm">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3">
                            <div class="form-group">
                                <label class="control-label">To Date<span class="text-danger">*</span></label>
                                <div>
                                    <input type="text" autocomplete="off" id="week_endDate" placeholder="To Date"
                                        bsDatepicker [(ngModel)]="week_endDate" disabled
                                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                        class="form-control form-control-sm">
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-4">
                            <div class="form-group">
                                <label class="control-label">Continue Last Week Slots?</label>
                                <div>
                                    <div class="custom-control-inline custom-control custom-checkbox">
                                        <input class="custom-control-input"
                                            [attr.disabled]="((week_endDate||'')!='')?null:true"
                                            (change)="assignPreviousSlots($event.target.checked)" type="checkbox"
                                            id="icheckbox">
                                        <label class="custom-control-label" for="icheckbox"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                  <!-- <div class="table-responsive">
                        <table class="table table-bordered table-sm  small">
                            <tbody>
                                <tr>
                                    <td style="width:15%;" class="text-center"><strong> Monday</strong>
                                        <br>
                                        ({{week_dates[0] | date:'dd-MM-yyyy'}})
                                    </td>
                                    <td>
                                        <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Select'"
                                            [(ngModel)]="selectedItems_Monday" [data]="timeslotsList"
                                            [disabled]="checkDates(week_dates[0])">
                                        </ng-multiselect-dropdown>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center"><strong>Tuesday</strong>
                                        <br>
                                        ({{week_dates[1] | date:'dd-MM-yyyy'}})
                                    </td>
                                    <td>
                                        <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Select'"
                                            [(ngModel)]="selectedItems_Tuesday" [data]="timeslotsList"
                                            [disabled]="checkDates(week_dates[1])">
                                        </ng-multiselect-dropdown>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center"><strong>Wednesday</strong>
                                        <br>
                                        ({{week_dates[2] | date:'dd-MM-yyyy'}})
                                    </td>
                                    <td>
                                        <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Select'"
                                            [(ngModel)]="selectedItems_Wednesday" [data]="timeslotsList"
                                            [disabled]="checkDates(week_dates[2])">
                                        </ng-multiselect-dropdown>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center"><strong>Thursday </strong>
                                        <br>
                                        ({{week_dates[3] | date:'dd-MM-yyyy'}})
                                    </td>
                                    <td>
                                        <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Select'"
                                            [(ngModel)]="selectedItems_Thursday" [data]="timeslotsList"
                                            [disabled]="checkDates(week_dates[3])">
                                        </ng-multiselect-dropdown>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center"><strong>Friday </strong>
                                        <br>
                                        ({{week_dates[4] | date:'dd-MM-yyyy'}})
                                    </td>
                                    <td>
                                        <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Select'"
                                            [(ngModel)]="selectedItems_Friday" [data]="timeslotsList"
                                            [disabled]="checkDates(week_dates[4])">
                                        </ng-multiselect-dropdown>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center"><strong>Saturday </strong>
                                        <br>
                                        ({{week_dates[5] | date:'dd-MM-yyyy'}})
                                    </td>
                                    <td>
                                        <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Select'"
                                            [(ngModel)]="selectedItems_Saturday" [data]="timeslotsList"
                                            [disabled]="checkDates(week_dates[5])">
                                        </ng-multiselect-dropdown>
                                    </td>
                                </tr>
                                <tr>
                                    <td class="text-center"><strong>Sunday </strong>
                                        <br>
                                        ({{week_dates[6] | date:'dd-MM-yyyy'}})
                                    </td>
                                    <td>
                                        <ng-multiselect-dropdown [settings]="dropdownSettings" [placeholder]="'Select'"
                                            [(ngModel)]="selectedItems_Sundday" [data]="timeslotsList"
                                            [disabled]="checkDates(week_dates[6])">
                                        </ng-multiselect-dropdown>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> -->
                    <div class="float-right">
                        <input type="submit" class="btn btn-sm btn-primary" (click)="submit()"
                            value="Submit">&nbsp;&nbsp;
                        <button type="button" id="btnClose" class="btn btn-sm btn-outline-dark"
                            data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>