import { DrugTypeMappingComponent } from './../drug-type-mapping/drug-type-mapping.component';
import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterService } from 'src/app/shared/services/master.service';
import { ToastrService } from 'ngx-toastr';
import { Common } from 'src/app/common';
import { BaseComponent } from 'src/app/shared/base.component';

@Component({
  selector: 'app-drug-type-mapping-list',
  templateUrl: './drug-type-mapping-list.component.html',
  styleUrls: ['./drug-type-mapping-list.component.css']
})
export class DrugTypeMappingListComponent extends BaseComponent implements OnInit {
  modalTitle: string = '';
  druglist: any;
  constructor(config: NgbModalConfig
    , private modalService: NgbModal
    , private masterService: MasterService
    , private toastr: ToastrService) {
    super();
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.getAllDrugs();
  }

  openDrugModal(DataEdit: any = null) {
    const modalRef = this.modalService.open(DrugTypeMappingComponent, { size: 'lg' });
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.DataEdit = DataEdit;
    modalRef.result.then(result => {
      if (result == 'changes saved')
        this.getAllDrugs();
    }, (reason) => { });
  }

  getAllDrugs() {
    this.masterService.getAll('drugtypemapping/getall').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Drug Mapping List');
        else
          this.druglist = res.data;
        Common.loadDataTable(this.druglist, '#drugTable');
      }

    });
  }

  deleteItem(id: any) {
    if (confirm(`Are you sure you want delete record?`)) {
    this.masterService.getAll(`deletedrugtype?drugTypeId=${id}`).subscribe(res => {
      if (res.status == "OK") {
        this.getAllDrugs();
        this.toastr.success(res.message, 'Drug Mapping List');
      }
    },
      error => { this.toastr.error('An Error Occured'); });
  }
}
}