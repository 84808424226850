<div class="container-fluid pl-0 pr-0">
    <div class="row">
        <div class="col-xl-12">
            <div class="card card-header-actions mx-auto">
                <div class="card-header">
                 Attendance Capturing
                </div>
                <div class="mt-2 ml-2">
                    <div class="custom-control-inline custom-control custom-radio">
                      <input checked class="custom-control-input" id="customRadio11" type="radio" name="is_active"
                    (change)="isShow=true;">
                      <label class="custom-control-label" for="customRadio11"><b>Capture Start Time </b></label>
                    </div>
                    <div class="custom-control-inline custom-control custom-radio">
                      <input class="custom-control-input " id="customRadio22" type="radio" name="is_active"
                      (change)="isShow=false;LoadDataForendTime()">
                      <label class="custom-control-label" for="customRadio22"><b>Capture End time</b></label>
                    </div>
                  </div> <br />
                <div class="card-body" [hidden]="!isShow"> 
                    <form [formGroup]="captureStartForm" class="form-horizontal"(ngSubmit)="captureStartTime()">
                        <div class="card-body" > 
                            <div class="row">
                                <label class="control-label">Attendance Date: </label>
                                <div class="col-xl-2">
                                    <input id="attendance_date" name="attendance_date" type="text" autocomplete="off" onpaste="return false"
                                    placeholder="To" bsDatepicker formControlName="attendance_date"
                                    bsDatepicker #dp1="bsDatepicker"  [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                                    class="form-control form-control-sm" onkeypress='return event.charCode == 8' disabled>
                                </div>
                                <label class="control-label">Start Time:</label>
                                <div class="col-xl-2">
                                    <input type="text" class="form-control form-control-sm"  formControlName="start_time" disabled>
                                    <!-- <input id="attendance_date" name="attendance_date" type="text" autocomplete="off" onpaste="return false"
                                    placeholder="To" bsDatepicker formControlName="attendance_date"  (focus)="dp.hide()"
                                    bsDatepicker #dp1="bsDatepicker" (focus)="dp1.show()"
                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                                    class="form-control form-control-sm" onkeypress='return event.charCode == 8'> -->
                                </div>
                                <div class="col-xl-2">
                                    <button type="submit" class="btn btn-sm btn-primary" value="Submit">
                                        Submit</button>&nbsp;&nbsp;
                                </div>  
                            </div>
                            </div>
                        </form>
                </div>
                <div class="card-body" [hidden]="isShow">
                    <form [formGroup]="captureEndForm" (ngSubmit)="captureEndTime()" > 
                        <div class="card-body" >
                            <div class="row">
                            <label class="control-label">Attendance Date: </label>
                            <div class="col-xl-2">
                                <input id="attendance_date" name="attendance_date" type="text" autocomplete="off" onpaste="return false"
                                placeholder="To" bsDatepicker formControlName="attendance_date"
                                bsDatepicker #dp1="bsDatepicker"  [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                                class="form-control form-control-sm" onkeypress='return event.charCode == 8' disabled>
                            </div>
                            <label class="control-label">Start Time: </label>
                            <div class="col-xl-2">
                                <input type="text" class="form-control form-control-sm" formControlName="start_time" disabled>
                            </div>
                            <label class="control-label">End Time: </label>
                            <div class="col-xl-2">
                                <input type="text" class="form-control form-control-sm" formControlName="end_time" disabled >
                            </div>
                            <div class="col-xl-2">
                                <button type="submit" class="btn btn-sm btn-primary" value="Submit" >Submit</button>&nbsp;&nbsp;
                            </div>
                            </div>
                        </div>
                    </form> 
                </div>
            </div>
        </div>
    </div>
</div>

