import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Common } from 'src/app/common';
import { DatePipe } from '@angular/common';
import * as XLSX from 'xlsx';
declare let $: any;
declare let JqueryReady: any;
declare const answerCall: any;
declare let joinSession: any;
@Component({
  selector: 'app-referal-patient-list',
  templateUrl: './referal-patient-list.component.html',
  styleUrls: ['./referal-patient-list.component.css'],
  providers: [DatePipe],
})
export class ReferalPatientListComponent implements OnInit {

  modalTitle: string = '';
  getreferalpatientdata;
  doctorDetails: any;
  patientSearchForm: FormGroup;
  search_string: any = '';
  submitted = false;
  fileName= 'Referral Patient List.xlsx';
  is_agent: boolean = sessionStorage.logUsrRoleId == '14';schdate=null;payload;statusget;statusdata;
  refpatform: FormGroup;max_date = new Date(); refpatformmodal: FormGroup;nonconvertdate=null;
  constructor(config: NgbModalConfig
    , private modalService: NgbModal
    , private toastr: ToastrService
    , private router: Router
    , private formBuilder: FormBuilder
    , private authSvc: AuthenticationService
    , private regService: RegistrationService
    ,private datePipe: DatePipe) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;

  }

  ngOnInit(): void {
    this.initFormControls();
    this.patientSearchForm = this.formBuilder.group({ search_string: ['', Validators.required] });
    this.authSvc.isShowGlblOrg.next(false), this.authSvc.tenantId = sessionStorage.getItem('defCrntTnnt') || sessionStorage.getItem('TENANT_ID'),
      sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt') || sessionStorage.getItem('TENANT_ID')),
    this.doctorDetails = JSON.parse(sessionStorage.getItem('DoctorDetails'));
    this.statusreferdata();
    this.getPatientsData();
    // this.getAllPatients()
    // $(document).ready(function () {
    //   var table = $('#pharmacyTable').DataTable({
    //     lengthChange: false,
    //     destory: true,
    //     // buttons: ['copy', 'excel', 'pdf', 'colvis']
    //   });

    //   table.buttons().container()
    //     .appendTo('#example1_wrapper .col-md-6:eq(0)');
    // });
  }

  openPharmacyModal() {
    // const modalRef = this.modalService.open(PatientRegistrationComponent, { size: 'xl' });
    // modalRef.componentInstance.title = this.modalTitle;
    // modalRef.result.then(result => {
    //   // here call Table load API
    // }, (reason) => { });
  }
  openPatientModal() {
    // const modalRef = this.modalService.open(PatientRegistrationComponent, { scrollable: true, size: 'xl' });
    // modalRef.componentInstance.title = this.modalTitle;
  }

  getPatientsData() {
   let fromdate=this.datePipe.transform(this.refpatform.value.from_date,'yyyy-MM-dd');
   let todate=this.datePipe.transform(this.refpatform.value.to_date,'yyyy-MM-dd');
    this.regService.getallrefhospbydocid(this.doctorDetails.id,fromdate,todate).subscribe(res => {
      if(res.status === 'ACCEPTED') {
        if (res.data == null || res.data.length == 0)
        this.getreferalpatientdata = [], Common.loadDataTable(this.getreferalpatientdata, "#referalpatient"),
          this.toastr.info(res.message);
        else
        for (let i = 0; i < res.data.length; i++) {
          if(res.data[i].referral_type_id==1){
            res.data[i]["referral_type_id_new"]="Open"
          }
          if(res.data[i].referral_type_id==2){
            res.data[i]["referral_type_id_new"]="In_progress"
          }
          if(res.data[i].referral_type_id==3){
            res.data[i]["referral_type_id_new"]="Pending"
          }
          if(res.data[i].referral_type_id==4){
            res.data[i]["referral_type_id_new"]="Close"
          }
        }
          this.getreferalpatientdata = res.data;
          Common.loadDataTable(this.getreferalpatientdata, '#referalpatient');
      }
    });

  }

  OfflinePocSubmit(patient_data: any) {
    sessionStorage.setItem('fromrefpat','referalpatientList');
    sessionStorage.setItem('patientidchat',patient_data.beneficiary_id);
    sessionStorage.setItem('mem_type','true');
    sessionStorage.setItem('callingpatient_id',patient_data.beneficiary_id)
    sessionStorage.setItem('beforepocid',patient_data.before_ref_poc_id)
    sessionStorage.setItem('afterrefid',patient_data.id)
    sessionStorage.setItem('ref_doc_id',patient_data.doctor_id)
    sessionStorage.setItem('ref_doc_name',patient_data.doctor_name)
    sessionStorage.setItem('ref_type_id_ref',patient_data.referral_type_id)
    sessionStorage.setItem('ref_hosp_id_ref',patient_data.referral_hospital_id)
    sessionStorage.setItem('schedule_date',patient_data.schedule_date)
    let payload = {
      "patient_id": patient_data.beneficiary_id,
      "doctor_id": this.doctorDetails.id,
      "patient_name": patient_data.beneficiary_name,
      "patient_email": 'prasad@gmail.com'
    }
    this.regService.generateConsulation('POST', payload).subscribe(res => {
      if (res.status == 'OK') {
        let fromId = (this.doctorDetails.id).toString();
        let toId = (patient_data.beneficiary_id).toString();
        $("#fromId").val("doc_" + fromId);
        $("#toId").val("pat_" + toId);
        $("#order_no").val(res.data.order_no);
        $("#is_self").val(true);
        $("#patient_name").val(patient_data.beneficiary_name);
        $("#patient_interaction_id").val(res.data.consultation_id);
        sessionStorage.setItem('chat_consultationid',res.data.consultation_id);
        $("#patient_info").val(JSON.stringify({ "patient_interaction_id": res.data.consultation_id, "order_no": res.data.order_no, "hospital_id": "", "symptoms": "", "is_self": true }));
        sessionStorage.isPatient = true;
        this.router.navigate(['home/poc'])
          .then((e) => {
            let ws = JSON.parse(sessionStorage.getItem('webSkt')); // JqueryReady(); // answerCall(fromId, toId);
            Promise.all([JqueryReady()]).then(res => { answerCall(fromId, toId, ws.expn, ws.qlfnName, ws.grCode, ws.irCode, ws.erCode); });
            if (e)
              console.log("Navigation is successful!");
            else
              console.log("Navigation has failed!");
          });

      }
      else {
        this.toastr.warning(res.message, 'Generate Consulation');
      }
    });


  }

  deleteItem(id: any) {
    this.regService.deleteById('pharmacy/deleterecordbypharmacyid?pharmacyId=', id).subscribe(res => {
      if (res.status == "OK")
        this.getPatientsData, this.toastr.success('Record Deleted Successfully');
    }, error => { this.toastr.error('An Error Occured'); });
  }
  scheduledateclick(item:any){
    if(item.schedule_date !=null){
      this.nonconvertdate=this.refpatformmodal.value.schedule_date=item.schedule_date;
    var schd=this.datePipe.transform(new Date(item.schedule_date),'dd-MM-yyyy HH:mm:ss');
    }
    this.schdate=schd;
    this.statusdata=item.referral_type_id;
    this.refpatformmodal.patchValue({
      beneficiary_id: item.beneficiary_id,
      dependent_id: item.dependent_id,
      doctor_id: item.doctor_id,
      referral_hospital_id:item.referral_hospital_id,
      is_active:item.is_active,
      beneficiary_name:item.beneficiary_name,
      beneficiary_mobile:item.beneficiary_mobile,
      schedule_date:this.schdate,
      before_ref_poc_id:item.before_ref_poc_id,
      after_ref_poc_id:item.after_ref_poc_id,
      procedure:item.procedure,
      referral_type_id:item.referral_type_id,
      doctor_name:item.doctor_name,
      id:item.id
    });
// this.payload={
// 	"beneficiary_id": item.beneficiary_id,
// 	"dependent_id": item.dependent_id,
// 	"doctor_id": item.doctor_id,
// 	"referral_hospital_id": item.referral_hospital_id,
// 	"is_active" : item.is_active,
// 	"beneficiary_name":item.beneficiary_name,
// 	"beneficiary_mobile": item.beneficiary_mobile,
// 	"schedule_date":null ,
// 	"before_ref_poc_id": item.before_ref_poc_id,
// 	"after_ref_poc_id":item.after_ref_poc_id,
// 	"procedure":item.procedure,
// 	"referral_type_id": this.statusdata,
// 	"doctor_name":item.doctor_name,
//   "id":item.id
// }

  }
  saverefdata(){
    if(this.refpatformmodal.value.schedule_date==null || this.refpatformmodal.value.schedule_date==''){
      this.toastr.error("Please Select Schedule Date");
      return;
    }
    if(this.refpatformmodal.value.referral_type_id==null || this.refpatformmodal.value.referral_type_id==''){
      this.toastr.error("Please Select Status");
      return;
    }
   // var newd=new Date(this.schdate+' '+'00:00:00');
   //var mydate=new Date(this.refpatformmodal.value.schedule_date);
   //this.refpatformmodal.value.schedule_date=this.refpatformmodal.value.schedule_date.toDateString();
   let payLoad = this.refpatformmodal.getRawValue();
   if(this.nonconvertdate !=null){
   payLoad.schedule_date=this.datePipe.transform(this.nonconvertdate, "yyyy-MM-dd HH:mm:ss");
   }
   else{
    payLoad.schedule_date=this.datePipe.transform(payLoad.schedule_date, "yyyy-MM-dd HH:mm:ss");
   }
   //this.refpatformmodal.value.schedule_date=this.datePipe.transform(new Date(this.refpatformmodal.value.schedule_date), "yyyy-MM-dd HH:mm:ss");
    // this.payload.schedule_date=datesch;
    //this.payload.referral_type_id=this.statusdata;
    this.regService.savereferal(payLoad).subscribe(res => {
      if (res.status == "OK"){
        this.schdate='';this.statusdata='';this.nonconvertdate=null;
        this.getPatientsData(),
       $("#scheduledateid").modal("hide"); this.toastr.success(res.message);
      }
    })
  }
  statusreferdata(){
    this.regService.statusrefer().subscribe(res => {
this.statusget=res.data;
    })
  }
  closemodal(){
    this.schdate='';this.statusdata='';this.nonconvertdate=null;
    this.initFormControls();
  }
  initFormControls() {
    this.refpatform = this.formBuilder.group({
      from_date: [new Date()],
      to_date: [new Date()],
      doctor_id: ['']
    });
    this.refpatformmodal = this.formBuilder.group({
      beneficiary_id: [''],
      dependent_id: [''],
      doctor_id: [''],
      referral_hospital_id:[''],
      is_active:[''],
      beneficiary_name:[''],
      beneficiary_mobile:[''],
      schedule_date:[''],
      before_ref_poc_id:[''],
      after_ref_poc_id:[''],
      procedure:[''],
      referral_type_id:[null],
      doctor_name:[''],
      id:['']
    });
  }
  LoadData(){
    this.getPatientsData();
  }
  get f() { return this.refpatform.controls; }
  datenewclick(){
    this.nonconvertdate=null;
  }
  exportexcel(): void 
  {
     
     var table = $('#referalpatient').DataTable();
 
    if ( ! table.data().count() ) {
    this.toastr.error( 'No data available in table! Please select and try again' );
   }
   else{
     / table id is passed over here /  
     let element = document.getElementById('referalpatient'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
     

     / generate workbook and add the worksheet /
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     ws['!cols'] = [];
     ws['!cols'][5] = { hidden: true };
     

     / save to file /
     XLSX.writeFile(wb, this.fileName);
     }
    }
}
