<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <form [formGroup]="hospitalForm" (ngSubmit)="onSubmit($event)">
      <div class="row">
        <div class="col-xl-3">
          <div class="form-group">
            <label class="control-label"> Name of the Hospital <span class="text-danger">*</span> </label>
            <div>
              <input type="text" autocomplete="off" formControlName="hospital_name" id="hospital_name"
                [ngClass]="{ 'is-invalid': submitted && f.hospital_name.errors }" maxlength="50"
                class="form-control form-control-sm" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                  (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32 || event.charCode == 46))'>
              <div *ngIf="submitted && f.hospital_name.errors" class="invalid-feedback">
                <div *ngIf="f.hospital_name.errors.required">Name of the Hospital is required</div>
                <div *ngIf="f.hospital_name.errors.maxlength">Max Length limit 50</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="form-group">
              <label class="control-label">Hospital Type<span class="text-danger">*</span></label>
              <div>
                <select class="form-control form-control-sm" id="centerType" (change)='changeHospitalType($event.target.value)'
                formControlName="center_type" >
                    <option value="null">Select</option>
                    <option *ngFor="let type of getallhospitalTypeList" [value]="type">
                      {{type}}</option>
                </select>
               
              </div>
          </div>
      </div>
        <div class="col-xl-3" *ngIf="getallhospitalTypeList && getallhospitalTypeList.length>0 && getallhubslist && getallhubslist.length>0">
          <div class="form-group">
              <label class="control-label">Hub Hospitals</label>
              <div>
                <select class="form-control form-control-sm" id="hub"
                formControlName="hub_id">
                    <option value="null">Select</option>
                    <option *ngFor="let hub of getallhubslist" [value]="hub.id">
                      {{hub.hub_name}}</option>
                </select>
                <!-- <div *ngIf="submitted && f.hub_id.errors" class="invalid-feedback">
                  <div *ngIf="f.hub_id.errors.required">Hub is required</div>
                </div> -->
              </div>
          </div>
      </div>
      
       <!-- Hospital Types new API added 04-04-2022 -->
       <div class="col-xl-3" *ngIf="getallhospitalTypesList && getallhospitalTypesList.length>0">
        <div class="form-group">
            <label class="control-label">Type of Refferal Hospital</label>
            <div>
              <select class="form-control form-control-sm" id="hospital_type_id"
              formControlName="hospital_type_id">
                  <option value="null">Select</option>
                  <option *ngFor="let type of getallhospitalTypesList" [value]="type.id">
                    {{type.hospital_type}}</option>
              </select>
              <div *ngIf="submitted && f.referral_type.errors" class="invalid-feedback">
                <div *ngIf="f.hospital_type_id.errors.required">Referral is required</div>
              </div>
            </div>
        </div>
    </div>
        <div class="col-xl-3">
          <div class="form-group">
            <label class="control-label">Location Type <span class="text-danger">*</span> </label>
            <div>
              <select formControlName="locationtype_id" id="locationtype_id" #locationId
                [ngClass]="{ 'is-invalid': submitted && f.locationtype_id.errors }"
                (change)="locationChanged('','location')" class="form-control form-control-sm">
                <option value="">Select</option>
                <option *ngFor="let location of locationTypeList" [value]="location.id">
                  {{location.location_type}}</option>
              </select>
              <div *ngIf="submitted && f.locationtype_id.errors" class="invalid-feedback">
                <div *ngIf="f.locationtype_id.errors.required">Location Type is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="form-group">
            <label class="control-label">{{isNIG ? 'States' : 'State'}} <span class="text-danger">*</span> </label>
            <div>
              <select formControlName="state_id" id="state_id" #State (change)="locationChanged(State.value,'district')"
                [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }" class="form-control form-control-sm"
                disabled>
                <option value="">Select</option>
                <option *ngFor="let state of stateList" [value]="state.id">
                  {{state.state_name}}</option>
              </select>
              <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                <div *ngIf="f.state_id.errors.required">State is required
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="form-group">
            <label class="control-label">{{isNIG ? 'State Capitals' : 'District'}} <span class="text-danger">*</span>
            </label>
            <div>
              <select formControlName="district_id" id="district_id" #District
                (change)="locationChanged(District.value,(+locationId.value==2)?'city':'mandal')"
                [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }" class="form-control form-control-sm">
                <option value="">Select</option>
                <option *ngFor="let district of districtList" [value]="district.id">
                  {{district.district_name}}</option>
              </select>
              <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                <div *ngIf="f.district_id.errors.required">District is required
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="form-group">
            <label class="control-label">
              {{isSAGE ? 'Location' : (isNIG ? 'Local Government Areas' : 'Block')}}
              <span *ngIf="+locationId?.value==1" class="text-danger">*</span> </label>
            <div>
              <select formControlName="mandal_id" id="mandal_id" #Mandal
                [attr.disabled]="(+locationId.value==2 || isEditable)?true:null"
                (change)="locationChanged(Mandal.value,'village')"
                [ngClass]="{ 'is-invalid': submitted && f.mandal_id.errors }" class="form-control form-control-sm">
                <option value="">Select</option>
                <option *ngFor="let mandal of mandalList" [value]="mandal.id">
                  {{mandal.mandal_name}}</option>
              </select>
              <div *ngIf="submitted && f.mandal_id.errors" class="invalid-feedback">
                <div *ngIf="f.mandal_id.errors.required">
                  {{isSAGE ? 'Location' : (isNIG ? 'Local Government Areas' : 'Block')}} is required
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3" *ngIf="+locationId.value==2">
          <div class="form-group">
            <label class="control-label">City </label>
            <div>
              <select formControlName="city_id" id="city_id" [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }"
                class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null">
                <option value="">Select</option>
                <option *ngFor="let city of cityList" [value]="city.id">
                  {{city.city_name}}</option>
              </select>
              <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
                <div *ngIf="f.city_id.errors.required">City is required</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3" *ngIf="+locationId.value!=2 && !isNIG">
          <div class="form-group">
            <label class="control-label">Village <span class="text-danger">*</span> </label>
            <div>
              <select formControlName="village_id" id="village_id"
                [ngClass]="{ 'is-invalid': submitted && f.village_id.errors }" class="form-control form-control-sm">
                <option value="">Select</option>
                <option *ngFor="let village of villageList" [value]="village.id">
                  {{village.village_name}}</option>
              </select>
              <div *ngIf="submitted && f.village_id.errors" class="invalid-feedback">
                <div *ngIf="f.village_id.errors.required">Village is required
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="col-xl-3">
          <div class="form-group">
            <label class="control-label">Address <span class="text-danger">*</span> </label>
            <div>
              <textarea type="text" autocomplete="off" formControlName="address" id="address" (keyup)="keyUp('address')"
                maxlength="100"
                [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                class="form-control form-control-sm"></textarea>
              <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                <div *ngIf="f.address.errors.required">Address is required</div>
                <div *ngIf="f.address.errors.maxlength">Address exceeds max length limit 100</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="form-group">
            <label class="control-label">Pin Code <span class="text-danger">*</span></label>
            <div>
              <input type="text" autocomplete="off" formControlName="pin_code" id="pin_code" (keyup)="keyUp('pin_code')"
                onkeypress='return event.charCode >= 48 && event.charCode <= 57' maxlength="6"
                [ngClass]="{ 'is-invalid': submitted && f.pin_code.errors }" class="form-control form-control-sm">
              <div *ngIf="submitted && f.pin_code.errors" class="invalid-feedback">
                <div *ngIf="f.pin_code.errors?.required">Pin Code is required</div>
                <div *ngIf="f.pin_code.errors.pattern">Enter Valid Pin Code</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="form-group">
            <label class="control-label">Hospital Email<span class="text-danger">*</span> </label>
            <div>
              <input type="text" autocomplete="off" formControlName="contact_person_email" id="contact_person_email"
                (keyup)="keyUp('contact_person_email')" maxlength="60"
                onkeypress='return ((event.charCode == 64) ||(event.charCode == 46) || (event.charCode == 95) || (event.charCode == 45) ||
                  (event.charCode >= 97 && event.charCode <= 122)) || (event.charCode >= 48 && event.charCode <= 57)'
                [ngClass]="{ 'is-invalid': submitted && f.contact_person_email.errors }"
                class="form-control form-control-sm">
              <div *ngIf="submitted && f.contact_person_email.errors" class="invalid-feedback">
                <div *ngIf="f.contact_person_email.errors.pattern"> Sample email address is x@yahoo.com </div>
                <div *ngIf="f.contact_person_email.errors.required"> Hospital Email is required </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="form-group">
            <label class="control-label">Hospital Contact Person</label>
            <div>
              <input type="text" autocomplete="off" formControlName="contact_person_first_name"
                id="contact_person_first_name" (keyup)="keyUp('contact_person_first_name')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                  (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))'
                [ngClass]="{ 'is-invalid': submitted && f.contact_person_first_name.errors }"
                class="form-control form-control-sm">
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="form-group">
            <label class="control-label">Contact Person Number <span class="text-danger">*</span></label>
            <div>
              <input type="text" autocomplete="off" formControlName="contact_person_phone_number"
                onkeypress='return event.charCode >= 48 && event.charCode <= 57' [maxlength]="maxLngth"
                [ngClass]="{ 'is-invalid': submitted && f.contact_person_phone_number.errors }"
                class="form-control form-control-sm" [pattern]="mobPtrn"
                (keyup)="onKeyNmbr('contact_person_phone_number', $event.target.value)"
                id="contact_person_phone_number"> <!-- (keyup)="keyUp('contact_person_phone_number')" -->
              <div *ngIf="submitted && f.contact_person_phone_number.errors" class="invalid-feedback">
                <div *ngIf="f.contact_person_phone_number.errors.required">Contact Person Number is required</div>
                <div *ngIf="f.contact_person_phone_number.errors.pattern">Contact Person Number {{mobErrMsg}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="form-group">
            <label class="control-label">Hospital Registration Number <span class="text-danger">*</span> </label>
            <div>
              <input type="text" autocomplete="off" formControlName="registration_no" id="registration_no"
                (keyup)="keyUp('registration_no')"
                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32)) || (event.charCode >= 48 && event.charCode <= 57)'
                maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.registration_no.errors }"
                class="form-control form-control-sm">
              <div *ngIf="submitted && f.registration_no.errors" class="invalid-feedback">
                <div *ngIf="f.registration_no.errors.required">Hospital Registration Number is required
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3">
          <div class="form-group">
            <label class="control-label">Hospital Whatsapp Number</label>
            <div>
              <input type="text" autocomplete="off" formControlName="whatsapp_number" id="whatsapp_number"
                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                [ngClass]="{ 'is-invalid': submitted && f.whatsapp_number.errors }" class="form-control form-control-sm"
                [pattern]="mobPtrn" [maxlength]="maxLngth" (keyup)="onKeyNmbr('whatsapp_number', $event.target.value)">
              <div *ngIf="submitted && f.whatsapp_number.errors" class="invalid-feedback">
                <div *ngIf="f.whatsapp_number.errors.required">Whatsapp Number is required</div>
                <div *ngIf="f.whatsapp_number.errors.pattern">Whatsapp Number {{mobErrMsg}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3" style="display: none;">
          <div class="form-group">
            <label class="control-label">Hospital Bank Account Number </label>
            <div>
              <input type="text" autocomplete="off" formControlName="bank_account_number" id="bank_account_number"
                (keyup)="keyUp('bank_account_number')" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                maxlength="20" [ngClass]="{ 'is-invalid': submitted && f.bank_account_number.errors }"
                class="form-control form-control-sm">
              <div *ngIf="f.bank_account_number.touched && f.bank_account_number.dirty" class="invalid-feedback">
                <div *ngIf="f.bank_account_number.errors">Enter Valid Number</div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-3" style="display: none;">
          <div class="form-group">
            <label class="control-label">Bank Name </label>
            <div>
              <select formControlName="branch_name" id="branch_name" class="form-control form-control-sm"
                [ngClass]="{ 'is-invalid': submitted && f.branch_name.errors }">
                <option value="">Select</option>
                <option *ngFor="let item of bankNamesList" [value]="item.id">
                  {{item.bank_name}}</option>
              </select>
            </div>
          </div>
        </div>
        <div class="col-xl-3" style="display: none;">
          <div class="form-group">
            <label class="control-label">Branch Location </label>
            <div>
              <input type="text" autocomplete="off" formControlName="branch_location" id="branch_location"
                (keyup)="keyUp('branch_location')" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                  (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))' maxlength="50"
                [ngClass]="{ 'is-invalid': submitted && f.branch_location.errors }"
                class="form-control form-control-sm">
            </div>
          </div>
        </div>
        <div class="col-xl-3" style="display: none;">
          <div class="form-group">
            <label class="control-label">IFSC Code </label>
            <div>
              <input type="text" autocomplete="off" formControlName="ifsc_code" id="ifsc_code"
                (keyup)="keyUp('ifsc_code')"
                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                            (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32)) || (event.charCode >= 48 && event.charCode <= 57)'
                maxlength="11" [ngClass]="{ 'is-invalid': submitted && f.ifsc_code.errors }"
                class="form-control form-control-sm">
              <div *ngIf="submitted && f.ifsc_code.errors" class="invalid-feedback">
                <div *ngIf="f.ifsc_code.errors.pattern">
                  Enter Valid IFSC, ex: SBIN0001050
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-2">
          <div class="form-group">
            <label class="control-label"> Status <span class="text-danger">*</span> </label>
            <div>
              <input type="radio" formControlName="is_active" [value]="true">Active
              <input type="radio" formControlName="is_active" [value]="false">In Active
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <!-- <div class="form-group">
            <label class="control-label">Do you want exclusivity with Patients &nbsp;&nbsp;&nbsp;
              <input type="checkbox" formControlName="exclude_external_patients" #exl
                (change)="onExcLogo($event.target.checked)"></label>
          </div> -->
          <div class="form-check form-check-inline">
            <input type="checkbox" formControlName="exclude_external_patients" #exl
              (change)="onExcLogo($event.target.checked)">
            &nbsp;&nbsp; <label class="form-check-label" for="inlineCheckbox22"> Do you want exclusivity with
              Patients</label>
          </div>
        </div>
        <div class="col-xl-3">
          <!-- <div class="form-group">
            <label class="control-label">is HeadOffice </label>
            <div>
              <input type="checkbox" name="isheadOffice" value="one" formControlName="is_headoffice">
            </div>
          </div> -->

          <div class="form-check form-check-inline">
            <input type="checkbox" name="isheadOffice" value="one" formControlName="is_headoffice">
            &nbsp;&nbsp; <label class="form-check-label" for="inlineCheckbox22"> is HeadOffice</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3">
          <div class="form-group">
            <label class="control-label">Departments <span class="text-danger">*</span></label>
            <div tabindex="0" class="">
              <ng-multiselect-dropdown [settings]="dropdownSettings_departments" [placeholder]="'Select'"
                [ngClass]="{ 'is-invalid': submitted && f.hospital_department_list.errors }" [data]="departmentList"
                formControlName="hospital_department_list"></ng-multiselect-dropdown>
              <div *ngIf="submitted && f.hospital_department_list.errors" class="invalid-feedback">
                <div *ngIf="f.hospital_department_list.errors.required">Departments is required</div>
              </div>
            </div>
          </div>
        </div>
        <!-- Hospital Type new API added 04-04-2022 -->
       

      </div>

      <div class="row">
        <div class="table-responsive">
          <table class="table table-bordered table-sm">
            <tbody>
              <tr>
                <td>
                  <input type="file" class="d-none" formControlName="regFile" #regFile name="input-file-preview"
                    (change)="singleFileUpload($event,0,'1')"
                    [ngClass]="{'is-invalid': submitted && f.regFile.invalid }" />
                  <p class="btn badge badge-success badge-pill text-white" (click)="regFile.click()">Hospital
                    Registration</p>
                  &nbsp;<span class="text-danger">*</span>
                  <p class="small mb-1">Png,Jpg or pdf format only</p>
                  <div *ngIf="submitted && f.regFile.errors" class="invalid-feedback">
                    <div *ngIf="f.regFile.errors.required">Hospital Registration is required</div>
                  </div>
                </td>
                <td>
                  <input type="file" class="d-none" name="input-file-preview" formControlName="exclogo" #exclogo
                    [attr.disabled]="(exl.checked)?null:true" (change)="singleFileUpload($event,1,'2')"
                    [ngClass]="{'is-invalid': submitted && f.exclogo.invalid }" />
                  <p class="btn badge badge-success badge-pill text-white" (click)="exclogo.click()">Upload logo for
                    exclusivity</p>
                  <p class="small mb-1">Png, jpg or pdf format only</p>
                  <div *ngIf="submitted && f.exclogo.errors" class="invalid-feedback">
                    <div *ngIf="f.exclogo.errors.required">
                      Logo is required
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <span> <a class="badge badge-danger badge-pill" title="Cancel File" *ngIf="uploadFileList[0].File"
                      (click)="cancelFile(1)"> <i class="fa fa-trash text-white"></i></a>&nbsp;
                  </span> <a href="javascript:void(0)" (click)="viewFile(1)">{{uploadFileList[0].file_name}}</a>
                </td>
                <td>
                  <span> <a class="badge badge-danger badge-pill" title="Cancel File" *ngIf="uploadFileList[1].File"
                      (click)="cancelFile(2)"> <i class="fa fa-trash text-white"></i></a>&nbsp;
                  </span> <a href="javascript:void(0)" (click)="viewFile(2)">{{uploadFileList[1].file_name}}</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <input type="submit" id="btnsubmit" style="display: none;">
    </form>
  </div>
</div>
<div class="modal-footer">
  <input type="submit" class="btn btn-sm btn-primary" [disabled]="isSubmit_Disabled?true:null" (click)="submitForm()"
    value="Submit">&nbsp;&nbsp;
  <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>