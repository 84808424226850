import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Common } from 'src/app/common';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { forkJoin } from 'rxjs';
import * as XLSX from 'xlsx';
declare let $: any;
@Component({
  selector: 'app-dss-report-new',
  templateUrl: './dss-report-new.component.html',
  styleUrls: ['./dss-report-new.component.css']
})
export class DssReportNewComponent implements OnInit {
  dssreportdata:any=[];
  dssReportCustomData:any=[];
  totaldssreport;
  patientname='';totaldssreport3;totaldssreport4;totaldssreport5;
  singleValuedata;totaldssreport6;totaldssreport7;totaldssreport8;totaldssreport9;totaldssreport10;childdatashow=false;
  motherdatashow=false;newitem;flowtype;totaldssreport11;
  fileName = 'DSS report list.xlsx';

  constructor(private regService: RegistrationService
    , protected toastr: ToastrService
    , private formBuilder: FormBuilder
    , private datePipe: DatePipe
    , private masterService: MasterService
    , private authSvc: AuthenticationService) { }

  ngOnInit(): void {
    this.getalldssdata();
  }
  getalldssdata(){
    // this.regService.getalldsssummary().subscribe(res => {
      this.regService.getalldsssummary().subscribe(res=>{
      if (res.data == null || res.data.length == 0)
      this.dssreportdata = [], Common.loadDataTable(this.dssreportdata, "#dssr"),
        this.toastr.error(res.message);
      else
      this.dssreportdata = res.data;
      this.dssreportdata = this.dssreportdata.map(item => { 
        item['patientName'] = item.name;
        item['mobile'] = item.mobile;
        if(item.summary?.summaryOthersJson?.summaryOthers.length>0){
          for(let i=0;i<item.summary.summaryOthersJson?.summaryOthers?.length;i++){
            item['gender'] = item.summary.summaryOthersJson.summaryOthers[i].GENDER;
            item['age'] = item.summary.summaryOthersJson.summaryOthers[i].AGE;
            item['cheifComplaints'] = item.summary.summaryOthersJson.summaryOthers[i].CHEIF_COMPLAINTS;
            item['investigation'] = item.summary.summaryOthersJson.summaryOthers[i].Investigation;
            item['testName'] = item.summary.summaryOthersJson.summaryOthers[i].TEST_NAME;
            item['drugs'] = item.summary.summaryOthersJson.summaryOthers[i].DRUGS;
          }
        }
        this.dssReportCustomData= this.dssreportdata
      });
          Common.loadDataTable(this.dssReportCustomData, '#dssr');
    })
  }
  viewdata(item){
   
    this.patientname=item.name;
    this.totaldssreport=[];
  this.singleValuedata=[];
  
  this.singleValuedata=item.summary.summaryOthersJson.summaryOthers;
if(item.summary.summaryOthersJson.summaryOthers.length !=0){
  for(let i=0;i<item.summary.summaryOthersJson.summaryOthers.length;i++){
this.totaldssreport.push(item.summary.summaryOthersJson.summaryOthers[i]);
  }
}
     
}
  exportexcel(): void {

    var table = $('#dssr').DataTable();

    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {
      / table id is passed over here /
      let element = document.getElementById('dssr');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };

      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
}


}
