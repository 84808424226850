import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/shared/services/api.service';
import { UserService } from 'src/app/shared/services/user.service';
import { Common } from 'src/app/common';
import { NodalOfficerDetailComponent } from '../nodal-officer-detail/nodal-officer-detail.component';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { BaseComponent } from 'src/app/shared/base.component';

@Component({
  selector: 'app-nodal-officer-list',
  templateUrl: './nodal-officer-list.component.html',
  styleUrls: ['./nodal-officer-list.component.css']
})
export class NodalOfficerListComponent extends BaseComponent implements OnInit {
  organizationList: Array<any> = [];
  modalTitle: string = '';

  constructor(config: NgbModalConfig,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private apiServ: ApiService,
    private userServices: UserService
    ,private masterService: MasterService
    , private registrationService: RegistrationService) {
      super();
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.loadTableData();
  }

  loadTableData() {
    this.registrationService.getAll('nodalofficer/getall').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Nodal Officer Details');
        else {
          this.organizationList = res.data;
          Common.loadDataTable(this.organizationList, '#organizationTable');
        }
      }
    });
  }

  openModal(DataEdit: any = null) {
    const modalRef = this.modalService.open(NodalOfficerDetailComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.DataEdit = DataEdit;
    modalRef.result.then(result => {
      if (result == 'changes saved')
        this.loadTableData();
    }, (reason) => { });
  }
  deleteItem(id: any) {
    if (confirm(`Are you sure you want delete record?`)) {
    this.registrationService.deleteBynodalId(id).subscribe(res => {
      if (res.status == "OK") {
        // this.getAllStates();
         this.loadTableData();
        this.toastr.success('Record Deleted Successfully');
      }
    },
      error => { this.toastr.error('An Error Occured'); });
  }
}
}


