import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Common } from 'src/app/common';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { RegistrationService } from 'src/app/shared/services/registration.service'
import { MasterService } from 'src/app/shared/services/master.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { DrugsToStatemappingListComponent } from '../drugs-to-statemapping-list/drugs-to-statemapping-list.component';

@Component({
  selector: 'app-drugs-to-statemapping',
  templateUrl: './drugs-to-statemapping.component.html',
  styleUrls: ['./drugs-to-statemapping.component.css'],
  providers: [DatePipe]
})
export class DrugsToStatemappingComponent implements OnInit {
  max_date = new Date(); expiry_date: any = new Date(); mfg_date: any = new Date(); minDate: Date; minToDate: Date;
  drugStateForm: FormGroup; @Input() title: string = ''; @Input() DrugAgent: any; submitted = false; sbmtDsbld: boolean = false;
  stateList: Array<any> = [];
  drugList: Array<any> = [];
  drug_type_name = []; isNIG = environment.isNIG;
  drug_type_list: any[]; stateListdata: any[]; materialId:any;materialFormId:any;
  constructor(public activeModal: NgbActiveModal
    , private modalService: NgbModal
    ,  private dtPipe: DatePipe
    , private toastr: ToastrService
    , private formBuilder: FormBuilder
    , private MasterService: MasterService
    , private RegistrationService: RegistrationService
  ) { }

  ngOnInit(): void {
    this.getmasters(); this.initFormControls(); this.getdrugs();
  }

  getdrugs() {
    this.MasterService.getalldrugs().subscribe(res => {
    //  console.log(res);
      this.drug_type_list = res.data
    }, err => {
      this.toastr.error(err.message)
    })
  }
 
  getmasters() {
    this.MasterService.getAll('getallstates').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available');
        else
          this.stateList = res.data;
      }
    });
  }



  getDrug() {
    this.MasterService.getDrugDetails(this.drugStateForm.value.drugtype_id).subscribe(res => {
      this.drug_type_name = _.map(res.data, (data) => {
        this.materialId = data.drug_type_id.id;
        return {
          drug_id: data.id,
          drug_Name: data.drug_id
        }
      })
    })
  }
  initFormControls() {
    this.drugStateForm = this.formBuilder.group({
      // state_id: ['',Validators.required],
      //  state_id: [36, Validators.required],//QA Pointing
     state_id: [5, Validators.required],//Training
      drugtype_id: ['',Validators.required],
      drugName_id: ['',Validators.required],
      batchNo_id: ['',Validators.required],
      qty_id: ['',Validators.required],
      available_stock:[''],
      expiry_date:  [new Date()],
      mfg_date: [new Date()],
    });
  }
  get f() { return this.drugStateForm.controls; }
  onSubmit(event: any) {
    this.submitted = true;
    Common.getFormValidationErrors(this.drugStateForm);
    if (this.drugStateForm.invalid) {
      this.toastr.error('Please Enter All Mandatory Fields');
      return;
    }
    if (this.drugStateForm.value.expiry_date <= this.drugStateForm.value.mfg_date) {
      this.toastr.error('Expiry Date Should be Greater Than MFD Date');
      return;
    }
    if(this.drugStateForm.value.qty_id<=0){
      this.toastr.error('Please enter quantity greater than zero');
      return;
    }
   
    else{
    const drug = _.find(this.drug_type_name, (data) => { return data.drug_id == Number(this.drugStateForm.value.drugName_id) })
    const drugType = _.find(this.drug_type_list, (data) => {return data.id == Number(this.drugStateForm.value.drugtype_id) }
    )
    const reqObj = [
      {
        "bar_code": "bar0098",
        "batch_no": this.drugStateForm.value.batchNo_id,
        "current_stock": this.drugStateForm.value.qty_id,
        "drug_mapping_id": this.drugStateForm.value.drugName_id,
        "drug_name": drug.drug_Name.drug_name,
        "drug_type": drugType.drug_type,
        "expiry_date": this.dtPipe.transform(this.drugStateForm.value.expiry_date, 'yyyy-MM-dd HH:mm:ss'),
        "is_active": true,
        "manufactured_date": this.dtPipe.transform(this.drugStateForm.value.mfg_date, 'yyyy-MM-dd HH:mm:ss'),
        "state_id": this.drugStateForm.value.state_id,
        "mrp_price":this.drugStateForm.value.mrp_price,
        "purchase_price":this.drugStateForm.value.purchase_price,
        "sale_price":null,
        "agent_purchase_price":this.drugStateForm.value.agent_purchase_price,
        "agent_sale_price":this.drugStateForm.value.agent_sale_price,
        "material_form_id":this.materialId,
        "material_id":drug.drug_Name.id
      }
      
    ]
    const payload =  JSON.parse(JSON.stringify(reqObj));
    this.RegistrationService.saveDrugDetails(payload).subscribe(res => {
      if (res) {
       
        if (res.status == "OK"){
          this.toastr.success(res.message), this.activeModal.close('changes saved');
        }
        
         
        else
          this.toastr.error(res.message);
      }
      else
        this.toastr.error(res.message);

    })
  }
  }
  getcheckdrug() {
    this.RegistrationService.getcheckdrug(this.drugStateForm.value.state_id,this.drugStateForm.value.drugName_id,this.drugStateForm.value.batchNo_id).subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available');
        else{
          const data = res.data;
          if(data.id){
            this.drugStateForm.controls.available_stock.setValue(data.current_stock);
            this.drugStateForm.controls.expiry_date.setValue(this.dtPipe.transform(data.expiry_date,'yyyy-MM-dd'));
            this.drugStateForm.controls.mfg_date.setValue(this.dtPipe.transform(data.manufactured_date,'yyyy-MM-dd'));
          }else{
            this.drugStateForm.controls.available_stock.setValue(0)
          }
        }
      }
    })
  }

}
