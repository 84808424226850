<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Add Drug Type Mapping
                        <button class="btn btn-blue btn-icon float-right"
                            (click)="modalTitle='Drug Type Mapping Details';openDrugModal()"
                            *ngIf="setPrivilege(Screen_Name.DrugMaster, Permission.Add)">
                            <i class="fa fa-plus"></i>
                        </button>
                    </div>

                    <div class="card-body">
                        <div class="table-responsive">

                            <table id="drugTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>S No</th>
                                        <th>Drug Name </th>
                                        <th>Action </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of druglist;let i=index">
                                        <td class="text-center" style="width: 8%;">{{i+1}}</td>
                                        <td>{{item.drug_name}}</td>
                                        <td style="width: 10%;">
                                            <div class="badge badge-info badge-pill"
                                                *ngIf="setPrivilege(Screen_Name.DrugMaster, Permission.Edit)">
                                                <a (click)="modalTitle='Edit Drug Type Mapping Details';openDrugModal(item)">
                                                    <i class="fa fa-edit text-white"></i></a>
                                            </div>&nbsp;&nbsp;&nbsp;
                                            <div class="badge badge-danger badge-pill"
                                                *ngIf="setPrivilege(Screen_Name.DrugMaster, Permission.Delete)">
                                                <a (click)="deleteItem(item.id)"><i
                                                        class="fa fa-trash text-white"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>