import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { HospitalDetailComponent } from 'src/app/modules/registration/hospital-detail/hospital-detail.component';
import { Common } from 'src/app/common';
import * as XLSX from 'xlsx';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
declare let $: any;

@Component({
  selector: 'app-hospital-detail-list',
  templateUrl: './hospital-detail-list.component.html',
  styleUrls: ['./hospital-detail-list.component.css']
})

export class HospitalDetailListComponent extends BaseComponent implements OnInit {

  modalTitle: string = ''; lstGrid = [];
  fileName = 'Hospital Detail list.xlsx';
  excelData: any =[];

  constructor(config: NgbModalConfig
    , private modalService: NgbModal
    , private regService: RegistrationService
    , private authSvc: AuthenticationService,
    public changeDetection: ChangeDetectorRef,
    private excelDownloadService:ExcelDownloadService,
     protected toastr: ToastrService
     ,private cdr: ChangeDetectorRef) {
      super(); // customize default values of modals used by this component tree
    config.backdrop = 'static', config.keyboard = false;
    authSvc.crntOrgIDSubject.subscribe(s => { this.lstGrid = [], Common.loadDataTable(this.lstGrid, '#hospitalTable');
      if (location.href.includes('home/hospitalDetailList'))
        this.ngOnInit();
    }), authSvc.isShowGlblOrg.next(true);
  }

  ngOnInit(): void {
    this.LoadData();
  }

  ChkLimit(): boolean {
    let result: boolean;
    this.regService.chkLimit('hospital').subscribe(res => {
      if (res) {
        if (res.status == "OK")
          result = true, this.openModal(null);
        else
          result = false, this.toastr.warning(res.message);
      }
    });
    return result;
  }

  LoadData() {
     //new Api added for list hospital below 11-07-2022
     this.regService.getAll('hospital/getallhospitalrecordsnew').subscribe(res => { //hospital/getallhospitalrecords
    //this.regService.getAll('hospital/getallhospitalrecords').subscribe(res => { //hospital/getallhospitalrecords
    $('.dataTable').DataTable().destroy(), $('.dataTable').DataTable({ searching: false });
      if (res.status === "OK") {
        this.cdr.detectChanges();
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Hospital Detail List');
        else
          this.lstGrid = res.data;
          this.excelData=this.lstGrid
          this.cdr.detectChanges();
        Common.loadDataTable(this.lstGrid, '#hospitalTable');
      }
    });
  }

  openModal(item: any) {
    this.changeDetection.detach();
    if(item!==null){
  
      const val = this.regService.getHospitalByhospId(item.id).toPromise().then(res=>{
        if (res.status == "OK") {
            
          const modalRef = this.modalService.open(HospitalDetailComponent, { scrollable: true, size: 'xl' });
          modalRef.componentInstance.title = this.modalTitle;
          modalRef.componentInstance.hospitalData = res.data;
          modalRef.result.then(result => {
           this.changeDetection.reattach();
            if (result == 'changes saved' || result=='Close click'){
              this.LoadData();
            }
            
          }, (reason) => { });
          
       }
      });
    } else{
      //this.changeDetection.detach();
      const modalRef = this.modalService.open(HospitalDetailComponent, { scrollable: true, size: 'xl' });
      modalRef.componentInstance.title = this.modalTitle;
      modalRef.componentInstance.hospitalData = item;
      modalRef.result.then(result => {
      //  this.changeDetection.reattach();
        if (result == 'changes saved' || result=='Close click'){
          this.LoadData();
        }
        
      }, (reason) => { });
      
    }  error => this.toastr.error(error.message);
   
  
  }

  onDelete(id: any) {
    if (confirm(`Are you sure you want delete record?`)) {
      this.regService.deleteById('hospital/deletebyhospitalid?hospitalId=', id).subscribe(res => {
        if (res.status == "OK")
          this.LoadData(), this.toastr.success('Record Deleted Successfully');
      },
      error => { this.toastr.error('An Error Occured'); });
  }
}

downLoadExcel(){
  const fileName = 'Hospital_reg'
    var excelData = this.excelData;
    if(excelData.length>0){
      const excelHeaders = ['District','Block','Hospital Name','Hospitcal Type','Category', 'Address'];
      const internalHeaders = ['district_name','mandal_name','hospital_name','center_type','category', 'address'];
      this.toastr.success('Please wait excel is downloading')
      this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
    }
    else{
      this.toastr.error('No data to download')
    }
  
}


}