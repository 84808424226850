import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { MasterService } from 'src/app/shared/services/master.service';
import { UserService } from 'src/app/shared/services/user.service';
import { Common } from 'src/app/common';

declare let $: any;
@Component({
  selector: 'app-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.css']
})
export class PermissionListComponent implements OnInit {
  @Input() title: string;
  @Input() roleId: string;

  screenList: Array<any> = [];
  permissionList: Array<any> = [];
  roleName: string = '';
  permissionForm: FormGroup;
  screenId = null; screenLabel = ''; moduleName = '';

  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private formBuilder: FormBuilder
    , private masterService: MasterService
    , private userServices: UserService) { }

  ngOnInit(): void { 
    this.initFormControls();
    this.LoadScreensData();
    this.LoadPermissionsById();
  }

  initFormControls() {
    this.permissionForm = this.formBuilder.group({
      permissions: this.formBuilder.array([]),
    });
  }

  rowForm(): FormGroup {
    return this.formBuilder.group({
      id: [0],
      canView: [false],
      canInsert: [false],
      canUpdate: [false],
      canDelete: [false],
      isActive: [true],
      createdOn: [null],
      roleId: [+this.roleId],
      screenId: [this.screenId],
      screen_label: [this.screenLabel],
      module_name: [this.moduleName]
    });
  }

  get getRowFormControls() {
    const control = this.permissionForm.get('permissions') as FormArray;
    return control;
  }

  addRow(item) {
    this.screenId = null; this.screenLabel = ''; this.moduleName = '';
    [this.screenId, this.screenLabel, this.moduleName] = [item.id, item.screen_label, item.module.module_name];

    const control = this.permissionForm.get('permissions') as FormArray;
    control.push(this.rowForm());
  }

  async addScreenList() {
    this.screenList.forEach(e => {
      this.addRow(e);
    });

    setTimeout(() => { this.editPermissions() }, 1000);
    Common.loadDataTable(this.getRowFormControls.controls, '#permissionTable');
    // console.log(this.getRowFormControls.value);
  }

  onSubmit() {
    let payload = this.getRowFormControls.getRawValue();
    payload.forEach(e => { delete e.module_name, delete e.screen_label });

    this.userServices.save('userrolescreenpermission/save', payload).subscribe(res => {
      this.toastr.success('Saved Successfully', 'Permission');
      this.activeModal.close('saved');
    },
      err => { this.toastr.error(err.message) });
  }

  setFocus(elementName: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementName)));
    el.value = '';
    el.focus();
  }

  LoadScreensData() {
    this.userServices.getAll('screen/getall').subscribe(
      {
        next: res => {
          if (res.status === 'OK') {
            if (res.data == null || res.data.length == 0)
              this.toastr.info('No records available', 'Permission List');
            else
              this.screenList = res.data;
          }
        },
        error: error => this.toastr.error(error.message),
        complete: () => { this.addScreenList(); }
      })
  }

  LoadPermissionsById() {
    this.userServices.getById(`userrolescreenpermission/get_by_roleid?role_id=${this.roleId}`).subscribe(
      {
        next: res => {
          if (res.status === 'success')
            this.permissionList = res.response;
        },
        error: error => this.toastr.error(error.message),
        complete: () => { }
      })
  }

  editPermissions() {
    if (this.permissionList.length > 0) {
      this.getRowFormControls.value.forEach((e, i) => {
        let d = this.permissionList.filter(f => f.screenId == e.screenId);
        if (d.length > 0)
          this.getRowFormControls.at(i).patchValue(d[0]);
      });
      // console.log(this.getRowFormControls.value);
      Common.loadDataTable(this.getRowFormControls.controls, '#permissionTable');
    }
  }

  
  submitForm() {
    document.getElementById('btnsubmit').click();
  }
}