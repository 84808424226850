import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { DatePipe } from '@angular/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { Common } from 'src/app/common';
import { UserService } from 'src/app/shared/services/user.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-referral-controls',
  templateUrl: './referral-controls.component.html',
  styleUrls: ['./referral-controls.component.css'],
  providers: [DatePipe]
})

export class ReferralControlsComponent implements OnInit {

  @Input() title: string; @Input() editData: any; rfrlForm: FormGroup; logTnntId: number = null;
  submitted = false; minDate: Date; minRfrdToDate: Date; minRfreToDate: Date;
  stateList: Array<any> = []; districtList: Array<any> = []; companyList: any; maxNOTRD = 1000; maxNOTRE = 1000;
  
  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private datePipe: DatePipe
    , private formBuilder: FormBuilder
    , private masterService: MasterService
    , private usrSvc: UserService
    , private authSvc: AuthenticationService) {
    this.minDate = new Date(), this.minDate.setDate(this.minDate.getDate());
    // authSvc.tnntSbjct.subscribe(s => this.logTnntId = s);
  }

  get f() { return this.rfrlForm.controls; }

  ngOnInit(): void {
    this.initFormControls(); // this.getAllCompanies(), this.customerTypeValidators(), this.setLocationTypeValidators();
    if (this.editData) {
      this.rfrlForm.patchValue(this.editData),
        this.rfrlForm.get('sdRfrd').setValue(this.datePipe.transform(this.editData.referred_start_date, 'dd-MM-yyyy')),
        this.rfrlForm.get('sdRfre').setValue(this.datePipe.transform(this.editData.referree_start_date, 'dd-MM-yyyy'));
        // this.rfrlForm.get('sdRfrd').setValue(new Date(this.editData.referred_start_date)),
        // this.rfrlForm.get('sdRfre').setValue(new Date(this.editData.referree_start_date));
        setTimeout(() => {
          this.rfrlForm.patchValue({ edRfrd: new Date(this.editData.referred_end_date), edRfre: new Date(this.editData.referree_end_date) })
        }, 500);
      this.rfrlForm.get('is_referred_absolute').setValue(!(this.editData.referred_discount_absolute_amt==0)),
        this.rfrlForm.get('is_referree_absolute').setValue(!(this.editData.referree_discount_absolute_amt==0));
      if (this.editData.is_referred_absolute)
        this.rfrlForm.get('referred_discount_percentage').setValue(''), this.rfrlForm.get('referred_max_discount_amt').setValue('');
      else
        this.rfrlForm.get('referred_discount_absolute_amt').setValue('');
      if (this.editData.is_referree_absolute)
        this.rfrlForm.get('referree_discount_percentage').setValue(''), this.rfrlForm.get('referree_max_discount_amt').setValue('');
      else
        this.rfrlForm.get('referree_discount_absolute_amt').setValue('');
      this.onChngeCB();
    }
  }

  getAllCompanies() {
    this.masterService.getAllCompanies('mastercompany/getall').subscribe(res => {
      if (res.status === "OK")
        this.companyList = res.data;
    });
  }

  initFormControls() {
    this.rfrlForm = this.formBuilder.group({
      id: [''],
      organization_id: [JSON.parse(sessionStorage.getItem('OrgDetails'))?.orgnization_id.toString()],
      same_offer_referral_referree: [false],
      enable_referred: [false],
      referred_applied_type: ['Consultation'],
      is_referred_absolute: [''],
      referred_discount_percentage: [''],
      referred_discount_absolute_amt: [null], // [0],
      referred_max_discount_amt: [''],
      referred_coupon: [''],
      referred_max_utilization_count: [''],
      referred_max_coupon_episode_count: [''],
      referred_min_consultation_amt: [null], // [0],
      referred_start_date: [''],
      referred_end_date: [''],
      referred_discription: [''],
      apply_coupon_after_first_consultation_of_referree: [true],
      enable_referree: [false],
      referree_applied_type: ['Consultation'],
      is_referree_absolute: [''],
      referree_discount_percentage: [''],
      referree_discount_absolute_amt: [null], // [0],
      referree_max_discount_amt: [''],
      referree_coupon: [''],
      referree_max_utilization_count: [''],
      referree_min_consultation_amt: [null], // [0],
      referree_max_coupon_episode_count: [''],
      referree_start_date: [''],
      referree_end_date: [''],
      referree_discription: [''],
      apply_referred_coupon_after_first_consultation: [true],
      is_active: [true],

      sdRfrd: [''],
      edRfrd: [''],
      sdRfre: [''],
      edRfre: [''],
    });
  }

  onChngeCB() {
    let [isRfrd, isRfre, cmpny, sameOfr] = [this.rfrlForm.get('enable_referred').value, this.rfrlForm.get('enable_referree').value, '',
      this.rfrlForm.get('same_offer_referral_referree').value]; // this.rfrlForm.get('organization_id').value // this.initFormControls();
    if (isRfrd) { // 'referred_applied_type', 'referred_discount_percentage', 'referred_discount_absolute_amt', 'referred_max_discount_amt',
      ['referred_coupon', 'is_referred_absolute', 'referred_max_utilization_count', 'referred_max_coupon_episode_count',
        'referred_min_consultation_amt', 'sdRfrd', 'edRfrd', 'referred_discription', 'apply_coupon_after_first_consultation_of_referree'].
      forEach(e => {
        if (e == 'referred_min_consultation_amt')
          this.rfrlForm.get(e).setValidators([Validators.required, Validators.min(300), Validators.max(1000)]);
        else if (e == 'referred_max_utilization_count' || e == 'referred_max_coupon_episode_count')
          this.rfrlForm.get(e).setValidators([Validators.required, Validators.min(1) , Validators.max(this.maxNOTRD)]);
        else
          this.rfrlForm.get(e).setValidators(Validators.required);
        this.rfrlForm.get(e).updateValueAndValidity();
      });
    }
    if (isRfre) { // 'referree_applied_type', 'referree_discount_percentage', 'referree_discount_absolute_amt', 'referree_max_discount_amt',
      ['referree_coupon', 'is_referree_absolute', 'referree_max_utilization_count', 'referree_min_consultation_amt',
        'referree_max_coupon_episode_count', 'sdRfre', 'edRfre', 'referree_discription', 'apply_referred_coupon_after_first_consultation'].
      forEach(e => {
        if (e == 'referree_min_consultation_amt')
          this.rfrlForm.get(e).setValidators([Validators.required, Validators.min(300), Validators.max(1000)]);
        else if (e == 'referree_max_utilization_count' || e == 'referree_max_coupon_episode_count')
          this.rfrlForm.get(e).setValidators([Validators.required, Validators.min(1) , Validators.max(this.maxNOTRE)]);
        else
          this.rfrlForm.get(e).setValidators(Validators.required);
        this.rfrlForm.get(e).updateValueAndValidity();
      });
    }
    this.rfrlForm.get('enable_referred').setValue(isRfrd), this.rfrlForm.get('enable_referree').setValue(isRfre),
      this.rfrlForm.get('same_offer_referral_referree').setValue(sameOfr); // this.rfrlForm.get('organization_id').setValue(cmpny), 
  }

  onChngMCA(type: string, val: any) {
    if (val != null && val > 0 && val != '') {
      if (+val > 300) {
        if (type == 'rd')
          this.rfrlForm.get('referred_min_consultation_amt').setValue(300);
        else
          this.rfrlForm.get('referree_min_consultation_amt').setValue(300);
      }
    }
  }

  onFromDateChange(val: any, ctrl: string) {
    if (ctrl == 'rd') {
      this.rfrlForm.get('edRfrd').setValue(''), this.minRfrdToDate = new Date();
      if (val != null) {
        let edate = this.datePipe.transform(val, 'yyyy-MM-dd').split('-'),
          cnvDate = new Date(parseInt(edate[0]), parseInt(edate[1]) - 1, parseInt(edate[2]), 0, 0, 0, 0);
        this.minRfrdToDate = new Date(cnvDate);
      }
    }
    else {
      this.rfrlForm.get('edRfre').setValue(''), this.minRfreToDate = new Date();
      if (val != null) {
        let edate = this.datePipe.transform(val, 'yyyy-MM-dd').split('-'),
          cnvDate = new Date(parseInt(edate[0]), parseInt(edate[1]) - 1, parseInt(edate[2]), 0, 0, 0, 0);
        this.minRfreToDate = new Date(cnvDate);
      }
    }
  }

  setLocationTypeValidators() {
    const [discount_flatControl, discount_percentageControl, max_discount_amtControl] = [this.rfrlForm.get('discount_flat'),
      this.rfrlForm.get('discount_percentage'), this.rfrlForm.get('max_discount_amt')];
    this.rfrlForm.get('discount_type_id').valueChanges.subscribe(locationType => {
      if (+locationType == 1) {
        discount_flatControl.setValidators([Validators.required]);
        discount_percentageControl.setValue('');
        max_discount_amtControl.setValue('');
        discount_percentageControl.setValidators(null);
        max_discount_amtControl.setValidators(null);
      }
      else if (locationType == 2) {
        discount_flatControl.setValidators(null);
        discount_flatControl.setValue('');
        discount_percentageControl.setValidators([Validators.required, Validators.min(1), Validators.max(100)]); // 99
        max_discount_amtControl.setValidators([Validators.required]);
      }
      else {
        discount_percentageControl.setValue('');
        max_discount_amtControl.setValue('');
        discount_flatControl.setValue('');
        discount_flatControl.setValidators(null);
        discount_percentageControl.setValidators(null);
        max_discount_amtControl.setValidators(null);
      }
      discount_flatControl.updateValueAndValidity();
      discount_percentageControl.updateValueAndValidity();
      max_discount_amtControl.updateValueAndValidity();
    });
  }

  customerTypeValidators() {
    const [company_Control] = [this.rfrlForm.get('company_id')];
    this.rfrlForm.get('is_rfrd').valueChanges.subscribe(isCompany => {
      if (isCompany == true)
        company_Control.setValidators([Validators.required]);
      else
        company_Control.setValue(''), company_Control.setValidators(null);
      company_Control.updateValueAndValidity();
    });
  }

  onDiscPerChng(val: any) {
    // if (val != '') {
    //   if (+val > 100)
    //     this.rfrlForm.get('discount_percentage').setValue('100');
    // }
  }

  onChngDT(ctrl: string) {
    ['referred_discount_absolute_amt', 'referred_discount_percentage', 'referred_max_discount_amt', 'referree_discount_percentage',
      'referree_discount_absolute_amt', 'referree_max_discount_amt'].forEach(e => { this.rfrlForm.get(e).setValue(''),
      this.rfrlForm.get(e).setValidators(null), this.rfrlForm.get(e).updateValueAndValidity(); });
    if (ctrl == 'rd') {
      if (this.rfrlForm.get('is_referred_absolute').value != '') {
        if (this.rfrlForm.get('is_referred_absolute').value != 'true')
          ['referred_discount_percentage', 'referred_max_discount_amt'].forEach((e, i) => {
            if (i == 0)
              this.rfrlForm.get(e).setValidators([Validators.required, Validators.min(10), Validators.max(100)]);
            else
              this.rfrlForm.get(e).setValidators([Validators.required, Validators.min(100), Validators.max(1000)]);
            this.rfrlForm.get(e).updateValueAndValidity();
          });
        else
          this.rfrlForm.get('referred_discount_absolute_amt').setValidators([Validators.required, Validators.min(100), Validators.max(1000)]),
            this.rfrlForm.get('referred_discount_absolute_amt').updateValueAndValidity();
      }
    }
    else {
      if (this.rfrlForm.get('is_referree_absolute').value != '') {
        if (this.rfrlForm.get('is_referree_absolute').value != 'true')
          ['referree_discount_percentage', 'referree_max_discount_amt'].forEach((e, i) => {
            if (i == 0)
              this.rfrlForm.get(e).setValidators([Validators.required, Validators.min(10), Validators.max(100)]);
            else
              this.rfrlForm.get(e).setValidators([Validators.required, Validators.min(100), Validators.max(1000)]);
            this.rfrlForm.get(e).updateValueAndValidity();
          });
        else
          this.rfrlForm.get('referree_discount_absolute_amt').setValidators([Validators.required, Validators.min(100), Validators.max(1000)]),
            this.rfrlForm.get('referree_discount_absolute_amt').updateValueAndValidity();
      }
    }
  }

  onChngMUC(ctrl: string) {
    if (ctrl == 'rd') {
      let notprdVal = this.rfrlForm.get('referred_max_utilization_count').value;
      if (this.rfrlForm.get('referred_max_utilization_count').value == '0')
        this.rfrlForm.get('referred_max_utilization_count').setValue('');
      // if (this.rfrlForm.get('referred_max_coupon_episode_count').value == '0')
      //   this.rfrlForm.get('referred_max_coupon_episode_count').setValue('1');
      if (this.rfrlForm.get('referred_max_utilization_count').value != '')
        this.rfrlForm.get('referred_max_utilization_count').setValue(+this.rfrlForm.get('referred_max_utilization_count').value);
      // if (this.rfrlForm.get('referred_max_utilization_count').value != '' && this.rfrlForm.get('referred_max_coupon_episode_count').value != '') {
      //   if (+this.rfrlForm.get('referred_max_utilization_count').value < +this.rfrlForm.get('referred_max_coupon_episode_count').value)
      //     this.rfrlForm.get('referred_max_coupon_episode_count').setValue('1');
      // }
      this.maxNOTRD = (notprdVal != '') ? +notprdVal : 1000, this.rfrlForm.get('referred_max_coupon_episode_count').
        setValidators([Validators.max(this.maxNOTRD)]), this.rfrlForm.get('referred_max_coupon_episode_count').updateValueAndValidity();
    }
    else {
      let notpreVal = this.rfrlForm.get('referree_max_utilization_count').value;
      if (this.rfrlForm.get('referree_max_utilization_count').value == '0')
        this.rfrlForm.get('referree_max_utilization_count').setValue('');
      // if (this.rfrlForm.get('referree_max_coupon_episode_count').value == '0')
      //   this.rfrlForm.get('referree_max_coupon_episode_count').setValue('1');
      if (this.rfrlForm.get('referree_max_utilization_count').value != '')
        this.rfrlForm.get('referree_max_utilization_count').setValue(+this.rfrlForm.get('referree_max_utilization_count').value);
      // if (this.rfrlForm.get('referree_max_utilization_count').value != '' && this.rfrlForm.get('referree_max_coupon_episode_count').value != '') {
      //   if (+this.rfrlForm.get('referree_max_utilization_count').value < +this.rfrlForm.get('referree_max_coupon_episode_count').value)
      //     this.rfrlForm.get('referree_max_coupon_episode_count').setValue('1');
      // }
      this.maxNOTRE = (notpreVal != '') ? +notpreVal : 1000, this.rfrlForm.get('referree_max_coupon_episode_count').
        setValidators([Validators.max(this.maxNOTRE)]), this.rfrlForm.get('referree_max_coupon_episode_count').updateValueAndValidity();
    }
  }

  onKeyUp(ctrl: string) {
    let val = ctrl=='rd' ? this.rfrlForm.get('referred_max_utilization_count').value : this.rfrlForm.get('referree_max_utilization_count').value;
    if (val != null && val != undefined && val != '') {
      if (ctrl == 'rd') {
        if (val[0] == '0')
          this.rfrlForm.get('referred_max_utilization_count').setValue('');
      }
      if (ctrl == 're') {
        if (val[0] == '0')
          this.rfrlForm.get('referree_max_utilization_count').setValue('');
      }
    }
  }

  onSubmit() {
    this.submitted = true, Common.getFormValidationErrors(this.rfrlForm);
    if (this.rfrlForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    if (this.rfrlForm.get('referred_coupon').value == this.rfrlForm.get('referree_coupon').value) {
      this.toastr.info('Coupon Names for Referred & Referree should not be same');
      return;
    }
    ['referred_discount_absolute_amt', 'referred_min_consultation_amt', 'referree_discount_absolute_amt', 'referree_min_consultation_amt'].
      forEach(e => {
        if (this.rfrlForm.get(e).value == null || this.rfrlForm.get(e).value == 0 || this.rfrlForm.get(e).value == '') {
          if (e == 'referred_min_consultation_amt' || e == 'referree_min_consultation_amt')
            this.rfrlForm.get(e).setValue(300);
          else
            this.rfrlForm.get(e).setValue(0);
        }
      });
    if (this.rfrlForm.get('enable_referred').value) {
      let rfrdSD = this.rfrlForm.get('sdRfrd').value, rfrdED = this.rfrlForm.get('edRfrd').value;
      if ((typeof rfrdSD) == 'string') {
        let dt = rfrdSD.split('-'), fDt = `${dt[2]}-${dt[1]}-${dt[0]}`;
        this.rfrlForm.get('referred_start_date').setValue(fDt);
      }
      else
        this.rfrlForm.get('referred_start_date').setValue(this.datePipe.transform(rfrdSD, 'yyyy-MM-dd'));
      if ((typeof rfrdED) == 'string') {
        let dt = rfrdED.split('-'), fDt = `${dt[2]}-${dt[1]}-${dt[0]}`;
        this.rfrlForm.get('referred_end_date').setValue(fDt);
      }
      else
        this.rfrlForm.get('referred_end_date').setValue(this.datePipe.transform(rfrdED, 'yyyy-MM-dd'));
      if (this.rfrlForm.get('referred_discount_absolute_amt').value)
        this.rfrlForm.get('referred_discount_absolute_amt').setValue(+this.rfrlForm.get('referred_discount_absolute_amt').value);
      if (this.rfrlForm.get('referred_min_consultation_amt').value)
        this.rfrlForm.get('referred_min_consultation_amt').setValue(+this.rfrlForm.get('referred_min_consultation_amt').value);
      // if (this.rfrlForm.get('referred_discount_percentage').value == '')
      //   this.rfrlForm.get('referred_discount_percentage').setValue('0');
      // if (this.rfrlForm.get('referred_max_discount_amt').value == '')
      //   this.rfrlForm.get('referred_max_discount_amt').setValue('0');
    }
    
    if (this.rfrlForm.get('referred_discount_percentage').value == '')
      this.rfrlForm.get('referred_discount_percentage').setValue('0');
    if (this.rfrlForm.get('referred_max_discount_amt').value == '')
      this.rfrlForm.get('referred_max_discount_amt').setValue('0');

    if (this.rfrlForm.get('enable_referree').value) {
      let rfreSD = this.rfrlForm.get('sdRfre').value, rfreED = this.rfrlForm.get('edRfre').value;
      if ((typeof rfreSD) == 'string') {
        let dt = rfreSD.split('-'), fDt = `${dt[2]}-${dt[1]}-${dt[0]}`;
        this.rfrlForm.get('referree_start_date').setValue(fDt);
      }
      else
        this.rfrlForm.get('referree_start_date').setValue(this.datePipe.transform(rfreSD, 'yyyy-MM-dd'));
      if ((typeof rfreED) == 'string') {
        let dt = rfreED.split('-'), fDt = `${dt[2]}-${dt[1]}-${dt[0]}`;
        this.rfrlForm.get('referree_end_date').setValue(fDt);
      }
      else
        this.rfrlForm.get('referree_end_date').setValue(this.datePipe.transform(rfreED, 'yyyy-MM-dd'));
      if (this.rfrlForm.get('referree_discount_absolute_amt').value)
        this.rfrlForm.get('referree_discount_absolute_amt').setValue(+this.rfrlForm.get('referree_discount_absolute_amt').value);
      if (this.rfrlForm.get('referree_min_consultation_amt').value)
        this.rfrlForm.get('referree_min_consultation_amt').setValue(+this.rfrlForm.get('referree_min_consultation_amt').value);
      // if (this.rfrlForm.get('referree_discount_percentage').value == '')
      //   this.rfrlForm.get('referree_discount_percentage').setValue('0');
      // if (this.rfrlForm.get('referree_max_discount_amt').value == '')
      //   this.rfrlForm.get('referree_max_discount_amt').setValue('0');
    }
    
    if (this.rfrlForm.get('referree_discount_percentage').value == '')
      this.rfrlForm.get('referree_discount_percentage').setValue('0');
    if (this.rfrlForm.get('referree_max_discount_amt').value == '')
      this.rfrlForm.get('referree_max_discount_amt').setValue('0');

    let payLoad = JSON.parse(JSON.stringify(this.rfrlForm.getRawValue()));
    ['sdRfrd', 'edRfrd', 'sdRfre', 'edRfre'].forEach(e => { delete payLoad[e] });
    if (this.rfrlForm.get('id').value == '')
      delete payLoad.id;
    this.usrSvc.save(`orgnization_referral_setting/save?TENANT_ID=${this.authSvc.tenantId}`, JSON.stringify(payLoad)).subscribe(res => {
      if (res.status === 'OK')
        this.toastr.success(res.message, 'Referral Discount'), this.activeModal.close('changes saved');
      else // if (res.message == 'Duplicate record found')
        this.toastr.error(res.message, 'Referral Discount');
    });
  }

  submitForm() {
    document.getElementById('btnsubmit').click();
  }

}