import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { MasterService } from 'src/app/shared/services/master.service';
import { Common } from 'src/app/common';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserService } from 'src/app/shared/services/user.service';
import { DatePipe } from '@angular/common';
import { RegistrationService } from 'src/app/shared/services/registration.service';
declare let $: any;

@Component({
  selector: 'app-attendance-capturing',
  templateUrl: './attendance-capturing.component.html',
  styleUrls: ['./attendance-capturing.component.css']
})
export class AttendanceCapturingComponent implements OnInit {
  savedata: Array<any>=[];
  isShow = true;
  date= new Date();
  captureStartForm: FormGroup;
  captureEndForm: FormGroup;
  startTime:any;
  getAttData:any;
  submitbtn=false;
  getList=[];start_Dt:any;  endDate:any;
  minDate: Date; minToDate: Date; logUsr: any;
  userId:any; firstName:any;attendance:any;currentTime:any
  constructor(public activeModal: NgbActiveModal
    , private registrationService: RegistrationService
    , private toastr: ToastrService
    , private userServicedata: UserService
    , private datePipe: DatePipe
    , private formBuilder: FormBuilder
    , private authServ: AuthenticationService
    , private masterService: MasterService) {
    this.minDate = new Date(), this.minDate.setDate(this.minDate.getDate());
    this.minToDate = new Date(), this.minToDate.setDate(this.minToDate.getDate());
  }

  ngOnInit(): void {
    this.initFormControls();
    this.initFormControlss();
    this.userId = sessionStorage.getItem('agentUserId');
   this.firstName = sessionStorage.getItem('firstname');
    this.captureStartForm.patchValue({
      // attendance_date:this.datePipe.transform(this.date, 'dd-MM-yyyy'),
      start_time:this.datePipe.transform(new Date(), 'HH:mm:ss')
    });
    
  }
  initFormControls() {
    this.captureStartForm = this.formBuilder.group({
      attendance_date: [new Date()],
      start_time:[new Date()],
      end_time:[null],
      uk_user_type_id:[14],
      createdby:[''],
      user_id:[null],
      user_name:[this.firstName],
    });
  }
    initFormControlss(){
      this.currentTime = this.datePipe.transform(new Date(),'HH:mm:ss')
    this.captureEndForm = this.formBuilder.group({
      attendance_date: [new Date()],
      start_time:[this.currentTime],
      end_time:[this.currentTime],
      uk_user_type_id:[14],
      createdby:[''],
      user_id:[null],
      user_type: ["agent"]
      // user_name:[''],
    });
  }

captureStartTime(){ 
      let payload = this.captureStartForm.getRawValue();
      let PLoad ={
        "attendance_date":this.datePipe.transform(payload.attendance_date, 'yyyy-MM-dd '),
        "start_time": this.datePipe.transform(new Date(), 'HH:mm:ss'),
        "end_time":null,
        "uk_user_type_id":14,
        "createdby":this.firstName,
        "user_id":this.userId,
        "user_name":this.firstName
      }
      // payload.attendance_date =this.datePipe.transform( payload.attendance_date, 'yyyy-MM-dd '),

      this.registrationService.saveStartTime(PLoad).subscribe(res => {
        if(res.status === 'OK') {
         if (res.data == null || res.data.length == 0){
           this.toastr.info(res.message);
         }
         else{
           this.startTime = res.data;
           this.getAttendance();
         }
       }
      })
   }
   getAttendance(){
   this.start_Dt = this.datePipe.transform(new Date(),'yyyy-MM-dd');
   this.endDate = this.datePipe.transform(new Date(),'yyyy-MM-dd');
     let PayLoad = {
      "start_date": this.start_Dt,
        "end_date": this.endDate,
        "user_id": this.userId,
        "user_type": "agent"
     }
    this.registrationService.getattendanceReport(PayLoad).subscribe(res => {
      if(res.status === 'OK') {
       if (res.data == null || res.data.length == 0){
         this.toastr.info(res.message);
       }
       else{
         this.attendance = res.data[0];
       }
     }
    })
   }
  //  LoadDataForStartTime(){
  //    this.getAttendance();
  //  }
   LoadDataForendTime(){
    this.getAttendance();
    // this.captureEndForm.patchValue({
    //   start_time:''
    // })
    setTimeout(() => {
    if(this.attendance){
        this.captureEndForm.patchValue({
          start_time:this.attendance.start_time
        })  
     }
    }, 500);
  }
  captureEndTime(){
    let payload = this.captureEndForm.getRawValue();
    let PLoad ={
      "attendance_date":this.datePipe.transform(payload.attendance_date, 'yyyy-MM-dd '),
      "start_time": payload.start_time,
      "end_time":payload.end_time,
      "uk_user_type_id":14,
      "createdby":this.firstName,
      "user_id":this.userId,
      "user_name":this.firstName
    }
    payload.attendance_date = this.datePipe.transform(payload.attendance_date, 'yyyy-MM-dd ')
        this.registrationService.saveStartTime(PLoad).subscribe(res => {
          if(res.status === 'OK') {
               if (res.data != null || res.data.length != 0){
                this.getAttData = res.data;
               }
               else{
               this.toastr.error('something went wrong')
               }
             }
          }) 
      }

  captureendtimenew(){
    this.captureEndForm.patchValue({
      attendance_date:this.datePipe.transform(this.date, 'dd-MM-yyyy'),
      start_time:this.datePipe.transform(this.date, 'HH:mm:ss'),
      end_time:''
    })
  }

}
