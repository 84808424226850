import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/shared/services/master.service';

@Component({
  selector: 'app-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.css'],
  // providers: [NgbActiveModal]
})
export class PromotionComponent implements OnInit {
  @Input() title: string;

  companyForm: FormGroup;
  submitted = false;

  stateList: Array<any> = [];
  companyList: Array<any> = [];
  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private formBuilder: FormBuilder
    , private masterService: MasterService) { }

  ngOnInit(): void {
    this.initFormControls();
    this.stateList = [
      { id: 1, name: 'State1' },
      { id: 2, name: 'State2' }
    ];

    this.companyList = [
      { id: 1, name: 'company1' },
      { id: 2, name: 'company2' }
    ]
  }

  initFormControls() {
    this.companyForm = this.formBuilder.group({
      id: ['0'],
      stateId: ['', Validators.required],
      companyId: ['', Validators.required],

      promotionName: ['', Validators.required],
      promotionCode: ['', Validators.required],
      labDiscount: ['', Validators.required],
      pharmacyDiscount: ['', Validators.required],

      doctorDiscount: ['', Validators.required],
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
      status: ['Active']
    });
  }

  get f() { return this.companyForm.controls; }

  onSubmit(event: any) {
    event.preventDefault();
    this.submitted = true;
    this.getFormValidationErrors();
    if (this.companyForm.invalid)
      return;
    else
      this.toastr.success('Record submited Successfully.', 'Company'), this.activeModal.close();
  }

  getFormValidationErrors() {
    Object.keys(this.companyForm.controls).forEach(key => {
      const controlErrors: ValidationErrors = this.companyForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
}