import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder,Validators,FormGroup } from '@angular/forms';
import { MasterService } from 'src/app/shared/services/master.service';
import { Common } from 'src/app/common';

@Component({
  selector: 'app-lab-investigation',
  templateUrl: './lab-investigation.component.html',
  styleUrls: ['./lab-investigation.component.css']
})
export class LabInvestigationComponent implements OnInit {
  @Input() title: string;
  @Input() DataEdit: any;
  id: number = 0;
  investigation: string = '';
  submitted = false;
  labForm: FormGroup

  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private formBuilder: FormBuilder
    , private masterService: MasterService) { }

  ngOnInit(): void {
    this.initFormControls()
    if (this.DataEdit) {
      this.labForm.patchValue(this.DataEdit);
      let [data,] = [this.DataEdit, ''];
      this.labForm.patchValue(data);
      this.labForm.patchValue({
        investigation: data.investigation,
       
      });
     
    }
    this.setFocus('investigation');
  }
  initFormControls() {
  this.labForm = this.formBuilder.group({
    id: "0",
    investigation: ['', Validators.required],
    "is_active": true
 });
}
get f() { return this.labForm.controls; }

  onSubmit(event: any) {
    this.submitted = true;
    Common.getFormValidationErrors(this.labForm);
    if (this.labForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }

    const sendData = JSON.parse(JSON.stringify(this.labForm.getRawValue()));

    this.masterService.saveInvestigation(sendData).subscribe(res => {
      let apiResponse = res;
         if (apiResponse.message === "Saved successfully...."  ) {
        this.toastr.success(res.message, 'Lab Investigation');
        this.activeModal.close('changes saved');
      }
      else if (apiResponse.message === "Duplicate record found")
          this.toastr.error(res.message, 'Lab Investigation');
          this.activeModal.close('changes saved');
  })
  
}
  setFocus(elementName: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementName)));
    el.value = '';
    el.focus();
  }
  submitForm() {
    document.getElementById('btnsubmit').click();
  }
}
