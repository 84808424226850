<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Enroll Corporate Employees
                    </div>
                    <div class="card-body">
                        <h3>Corporate <span class="text-danger">*</span></h3>
                        <div class="row">
                            <div class="col-xl-2">
                                <select class="form-control form-control-sm text-uppercase" [(ngModel)]="slctCmpny"
                                    (change)=" getAllcmpany($event.target.value)" id="slctCmpny">
                                    <option value="">Select</option>
                                    <option class="text-uppercase" [value]="cL.id" *ngFor="let cL of companyList;">{{cL.corporate_name}}</option>
                                </select>
                            </div>
                            <div class="col-xl-2">
                                <button class="btn btn-sm btn-success" (click)="onBtnImport()" [attr.disabled]="slctCmpny==''?true:null"
                                    *ngIf="setPrivilege(Screen_Name.EnrollCorporateEmployee, Permission.Add)" type="button">
                                    <i class="fa fa-file-excel" aria-hidden="true"></i>&nbsp;&nbsp;IMPORT EXCEL</button>
                                <input type="file" id="xlImprt" (change)="onImport($event)" accept="xls/xlsx" hidden>
                            </div>
                            <div class="col-xl-3">
                                <a href="assets/Templates/BeneficiaryData.xlsx" role="button" target="_blank" download
                                    class="btn btn-sm btn-dark pointer"><i class="fa fa-file"></i>&nbsp;&nbsp;DOWNLOAD TEMPLATE</a>
                            </div>
                            <div class="col-md-3"  >
                                <button type="button" class="btn  btn-sm btn-secondary float-right" (click)="exportexcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                            </div>
    
                            <div class="col-xl-2" *ngIf="corpEmpList.length != 0 && rbAct == false">
                                <input type="checkbox" [(ngModel)]="isChkdAll" (click)="onChkAll()">&nbsp;&nbsp;
                                <label><strong>Select All</strong></label>
                            </div>
                            <div class="col-xl-2">
                                <button type="button" class="btn btn-sm btn-info" *ngIf="chkdCnt!=0" (click)="onApprove()">Approve</button>
                            </div>
                        </div> <br />
                        <div class="row">
                            <div class="col-xl-2">
                                <b><label class="control-label">Show records for :</label></b>
                            </div>
                            <div class="col-xl-3">
                                <div class="custom-control-inline custom-control custom-radio">
                                    <input [(ngModel)]="rbAct" [value]="true" class="custom-control-input" id="customRadio11"
                                        type="radio" name="rbAct" (change)="onChngRB()">
                                    <label class="custom-control-label" for="customRadio11">Active </label>
                                </div>
                                <div class="custom-control-inline custom-control custom-radio">
                                    <input [(ngModel)]="rbAct" [value]="false" class="custom-control-input " id="customRadio22"
                                        type="radio" name="rbAct" (change)="onChngRB()">
                                    <label class="custom-control-label" for="customRadio22">In Active </label>
                                </div>
                            </div>
                        </div> <br />
                        <div class="table-responsive">
                            <table datatable id="enrollcorporateTable"
                                class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th [hidden]="rbAct"></th>
                                        <th>S No </th>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Phone No.</th>
                                        <th>Corporate ID</th>
                                        <th>Emp.ID</th>
                                        <th>Emp.Category</th>
                                        <th>Status</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of corpEmpList; let i=index">
                                        <td [hidden]="rbAct">
                                            <input type="checkbox" [(ngModel)]="item.isChk"
                                                (change)="onRowChk(i, $event.target.checked)">
                                        </td>
                                        <td style="width: 8%;" class="text-center">{{i+1}}</td>
                                        <td>{{item.first_name}}</td>
                                        <td>{{item.last_name}}</td>
                                        <td>{{item.mobile}}</td>
                                        <td class="text-uppercase">{{item.coporateId}}</td>
                                        <td>{{item.employee_id.replace('.0','')}}</td>
                                        <td>{{item.employee_level}}</td>
                                        <td>{{item.is_active ? 'Active' : 'In Active'}}</td>
                                        <td class="singleLine">
                                            <span>
                                                <a class="badge badge-info badge-pill pointer" title="Edit"
                                                    *ngIf="setPrivilege(Screen_Name.EnrollCorporateEmployee, Permission.Edit)"
                                                    (click)="modalTitle='Employee Details';openModal(item)" >
                                                    <i class="fa fa-edit text-white"></i></a>&nbsp;
                                            </span>
                                            <span>
                                                <a class="badge badge-danger badge-pill pointer" title="Delete"
                                                    *ngIf="setPrivilege(Screen_Name.EnrollCorporateEmployee, Permission.Delete)"
                                                    (click)="deleterowById(item.id)"> <i class="fa fa-trash text-white"></i>
                                                </a>
                                            </span>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>