import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup,ValidationErrors } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import { Subject, pipe } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare let $: any;
@Component({
  selector: 'app-city',
  templateUrl: './city.component.html',
  styleUrls: ['./city.component.css']
})
export class CityComponent implements OnInit {

  @Input() pharmaData: any;
  cityForm: FormGroup; 
   submitted = false;
  modalTitle: string = '';
  dtOptions: any = {};
  dtTrigger: any = new Subject();
  mandallist:any;
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = []
  cityList: Array<any> = [];
  constructor(
    public activeModal: NgbActiveModal,
    config: NgbModalConfig, 
      private modalService: NgbModal,
      private toastr: ToastrService,
      private masterService: MasterService,
      private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.locationChanged('', 'state');
  this.initFormControls()
  this.getFormValidationErrors()

  }
  get f() { return this.cityForm.controls; }


  initFormControls() {
    this.cityForm = this.formBuilder.group({
      id:"",
      state_id: ['', Validators.required],
      district_id: ['', Validators.required],
      city_name: ['', Validators.required],
    });
  }

  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {

        this.cityList = [];
        this.cityForm.get('district_id').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.cityList = [];
        // if (+id > 0)
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      },

      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,
          'district': () => this.districtList = arr.districts,  
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
      this.districtList.sort((a,b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error));
  } 


// deleteItem(id: any) {
//   // this.masterService.deleteByStateId(id).subscribe(res => {
//   //   if (res.status == "OK") {
//   //     // this.getAllStates();
//   //     this.toastr.success('Record Deleted Successfully');
//   //   }
//   // },
//     error => { this.toastr.error('An Error Occured'); });
// }
onSubmit(event: any) {
  this.submitted = true;
  this.getFormValidationErrors();
  if (this.cityForm.invalid)
    return;
  // else {
  //         const sendData = JSON.parse(JSON.stringify(this.cityForm.getRawValue()));  
  // //  var sendData = event.value
  //   if (+sendData.id == 0)
  //     // delete sendData.id;
  //   this.masterService.saveCities(sendData).subscribe(res => {
  //     let apiResponse = res;
  //     if (apiResponse.status == "success") {
  //       let responseData: any = apiResponse.response;
  //       this.initFormControls();
  //       this.cityForm.patchValue(responseData);
  //       this.toastr.success(`Record ${(+sendData.id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Pharmacy Registration');
  //     }
  //   }, error => this.toastr.error(error));
  // }
}
  getFormValidationErrors() {
    Object.keys(this.cityForm.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.cityForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  
}
  restFormValues() {
    this.initFormControls();
  }
  submitForm() {
    document.getElementById('btnsubmit').click();
  }
}
