

<div class="container-fluid pl-0 pr-0">
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-header-actions mx-auto">
          <div class="card-header d-flex justify-content-between align-items-center">
             NDHM Notification
              <div>
                <button class="btn btn-primary btn-sm" (click)="reload()">Reload</button>&nbsp;
                <!-- <button type="button" class="btn btn-sm btn-secondary  text-center" *ngIf="excelData && excelData.length>0" (click)="downLoadExcel()" > <i class="fa fa-file-excel-o"></i>Export</button> -->
              </div>
          </div>

          <div class="card-body">
            <!-- <form [formGroup]="ndhmNotificationForm" (ngSubmit)="LoadData()">
              <div class="row">
                <div class="col-md">
                  <label class="control-label">From Date <span class="text-danger">*</span></label>
                  <div>
                       <input id="start_date" name="start_date" type="text" autocomplete="off" onpaste="return false"
                              placeholder="From" bsDatepicker #dp="bsDatepicker" (focus)="dp.show()" (focus)="dp1.hide()"
                              formControlName="start_date" [maxDate]="max_date"
                              (bsValueChange)="onFromDateChange($event)" onkeypress='return event.charCode == 8'
                              [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                              [ngClass]="{ 'is-invalid': submitted && f.start_date.errors }"
                              class="form-control form-control-sm">
                       <div *ngIf="submitted && f.start_date.errors" class="invalid-feedback">
                          <div *ngIf="f.start_date.errors.required">From Date is required</div>
                       </div>
                  </div>
                </div>
                <div class="col-md">
                  <label class="control-label">To Date <span class="text-danger">*</span></label>
                  <div>
                    <input id="end_date" name="end_date" type="text" autocomplete="off" onpaste="return false"
                      placeholder="To" bsDatepicker formControlName="end_date"  [minDate]="f.start_date.value" [maxDate]="max_date" (focus)="dp.hide()"
                      bsDatepicker #dp1="bsDatepicker" (focus)="dp1.show()" (bsValueChange)="onToDateChange($event)"
                      [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                      [ngClass]="{ 'is-invalid': submitted && f.end_date.errors }"
                      class="form-control form-control-sm" onkeypress='return event.charCode == 8'>
                    <div *ngIf="submitted && f.end_date.errors" class="invalid-feedback">
                      <div *ngIf="f.end_date.errors.required">To Date is required</div>
                    </div>
                  </div>
                </div>
              
                    
             
                <div class='col-md-3' style="margin-top: 2rem;"  >
                   <button type="submit" class="btn btn-sm btn-primary" value="Submit" (focus)="dp1.hide()">Submit</button>&nbsp;
                   <button type="button" class="btn  btn-sm btn-secondary  text-center" *ngIf="excelData && excelData.length>0" (click)="downLoadExcel()" > <i class="fa fa-file-excel-o"></i>Export</button>
                </div>
              </div>

            </form> -->

            <!-- <div class="d-flex justify-content-end mb-3">
              <span class="px-3 ml-2" style="background-color:green; border-radius: 13px;"></span><h6 class="ml-2">GRANTED</h6>
              <span class="px-3 ml-2" style="background-color:red; border-radius: 10px;"></span><h6>EXPIRED</h6>
              <span class="px-3 ml-2" style="background-color:orange; border-radius: 10px;"></span><h6>REVOKED</h6>
              <span class="px-3 ml-2" style="background-color:rgb(134, 73, 73); border-radius: 10px;"></span><h6>DENIED</h6>
            </div> -->
            <div class="table-responsive">
                 <table datatable id="networkMonthlyReport" class="table table-striped table-bordered table-sm text-center" style="width:100%">
                  <thead>
                    <tr>
                      <th>S.NO</th>
                      <th>Health Id</th>
                      <th>Concent Id</th>
                      <th>Consent Status</th>
                      <th>Created on</th>
                      <th>Deleted</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of ndhmList; let i=index;">
                      <td>{{i+1}}</td>
                      <td>{{item?.health_id}}</td>
                      <td>{{item?.concentid}}</td>
                      <td>
                        <span *ngIf="item?.consent_status == 'GRANTED'"  style="color:green; font-weight: bold;" >{{item?.consent_status}} </span>
                        <span *ngIf="item?.consent_status == 'EXPIRED'" style="color:red; font-weight: bold;">{{item?.consent_status}} </span>
                        <span *ngIf="item?.consent_status == 'REVOKED'"  style="color:orange; font-weight: bold;">{{item?.consent_status}} </span>
                        <span *ngIf="item?.consent_status == 'DENIED'" style="color: rgb(134, 73, 73); font-weight: bold;">{{item?.consent_status}} </span>
                      </td>
                      <td>{{item?.created_on | date:'dd-MM-yyyy & HH:mm'}}</td>
                      <td>
                        <span *ngIf="item?.is_deleted==true">Yes</span>
                        <span *ngIf="item?.is_deleted==false">No</span>
                      </td>
                    </tr>
            
                  </tbody>
                </table>
               </div>
            </div>
          </div>
        </div>
      </div>
    </div>




