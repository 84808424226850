import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { ChatMessageDto } from '../models/user';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ChatService {
  webSocket: WebSocket;
  chatMessages: ChatMessageDto[] = [];
  chatNames = []
  appUrl: string = '';
  flagVal: any;
  docData: any;
  chatObj = { org_id: '', doctor_id: '', beneficiary_id: null, consulation_id: '', message: '', usertype: '',patient_name:null, property: "register" };
  // count = false;
  public count: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private toastr: ToastrService) {
    this.appUrl = (location.href.includes('localhost') ? environment.applicationURL : location.origin) + '/';
    this.docData = JSON.parse(sessionStorage.getItem('DoctorDetails'));
  }
  //Changes need to done when ur poininting to training connectSocket() 04-07-2022
  public openWebSocket() {
   var uri = this.appUrl.replace('https', 'wss') + 'telecon/chat'; //Original
   this.docData = JSON.parse(sessionStorage.getItem('DoctorDetails'));
  //  var uri='wss://telemedicine-uk-qa.vmedtm.com/telecon/chat'; //Pointing to QA
  //var uri='wss://telemedicine-uk-training.vmedtm.com/telecon/chat'; // Pointing to training
  // var uri='wss://telemedicine-uk-preprod.vmedtm.com/telecon/chat'; //pre prod pointing
 // var uri='wss://doctors.uktelemedicine.in/telecon/chat';
 // var uri='wss://telemedicine-uk-dev.vmedtm.com/telecon/chat';

    this.webSocket = new WebSocket(uri);

    this.webSocket.onopen = (event) => {
       console.log('Open: ', event);
    };

    this.webSocket.onmessage = (event) => {
      if (event.data != 'ping' && event.data !='pong'){
 const chatMessageDto = JSON.parse(event.data);
      if (chatMessageDto.usertype == "Doctor"){
        this.chatMessages.push(chatMessageDto)
        return;
               }
               const result = this.chatMessages.find( ({ patient_name }) => patient_name === chatMessageDto.patient_name);
               if(result==undefined && chatMessageDto.doctor_id == this.docData.id){
                 sessionStorage.setItem('firstDocID',chatMessageDto.doctor_id)
                this.chatNames.push(chatMessageDto);
               } 
               if(sessionStorage.getItem('firstDocID') != chatMessageDto.doctor_id && result.doctor_id == chatMessageDto.doctor_id){
                this.chatNames.push(chatMessageDto);
               }
              //  else if (result !=undefined && chatMessageDto.doctor_id == this.docData.id){
              //   this.chatNames.push(chatMessageDto);
              //  }
              //  if (this.chatMessages.find(element => element.patient_name != chatMessageDto.patient_name)){
              //   this.chatNames.push(chatMessageDto);
              //          }else if(this.chatMessages.length==0){
              //           this.chatNames.push(chatMessageDto);
              //          }
      // this.count = true;\
      if (chatMessageDto.doctor_id == this.docData.id) {
        // this.chatMessages.push(chatMessageDto);
        if (chatMessageDto.usertype != "Doctor") {
          this.count.next(true);
          if (this.count.subscribe(res => res == true)) {
            this.toastr.info('New Message Arrived From'+ ' ' + chatMessageDto.patient_name )
          }
        }
//         if (chatMessageDto.usertype == "Doctor"){
//  this.chatMessages.push(chatMessageDto)
//         }
      }
      if(this.chatMessages.length==0){
        if (chatMessageDto.doctor_id == this.docData.id) {
        this.chatMessages.push(chatMessageDto);
        //return;
        }
          }else if(this.chatMessages.length!=0 && this.chatMessages.find(element => element.beneficiary_id == chatMessageDto.beneficiary_id) ){
              for(let i=0;i<this.chatMessages.length;i++){
                if(this.chatMessages[i].beneficiary_id==chatMessageDto.beneficiary_id){
                  if (chatMessageDto.doctor_id == this.docData.id) {
                    this.chatMessages.push(chatMessageDto);
                    return;
            // this.chatMessages[i].message=this.chatMessages[i].message  +','+''+  chatMessageDto.message;
                }
              }
          }
        }else if(this.chatMessages.length!=0 && this.chatMessages.find(element => element.beneficiary_id != chatMessageDto.beneficiary_id)){
          if (chatMessageDto.doctor_id == this.docData.id) {
          this.chatMessages.push(chatMessageDto);
          }
        }
      if (chatMessageDto.doctor_id == this.docData.id) {
        // this.chatMessages.push(chatMessageDto);
        if (chatMessageDto.usertype != "Doctor") {
          this.count.next(true);
          if (this.count.subscribe(res => res == true)) {
            this.toastr.info('New Message Arrived From'+ ' ' + chatMessageDto.patient_name )
          }
        }
//         if (chatMessageDto.usertype == "Doctor"){
//  this.chatMessages.push(chatMessageDto)
//         }
      }
    }
    };

    this.webSocket.onclose = (event) => {
      console.log('Close: ', event);
    };
  }
  // new openWeb Socket 27-06-2022
  public connectSocket(orgId,docId,userType,) {
      var uri = this.appUrl.replace('https', 'wss') + 'telecon/chat'; //Original
      this.docData = JSON.parse(sessionStorage.getItem('DoctorDetails'));
     //  var uri='wss://telemedicine-uk-qa.vmedtm.com/telecon/chat'; //Pointing to QA
    // var uri='wss://telemedicine-uk-training.vmedtm.com/telecon/chat'; // Pointing to training
    //var uri='wss://telemedicine-uk-preprod.vmedtm.com/telecon/chat';// pre prod pointing
    // var uri='wss://doctors.uktelemedicine.in/telecon/chat';
       this.webSocket = new WebSocket(uri);
   
       this.webSocket.onopen = (event) => {
         console.log('Open: ', event);
         this.chatObj =  { org_id: orgId, doctor_id: docId, beneficiary_id: null, consulation_id: '', message: '', usertype: userType , patient_name:null, property: "register" };
		this.webSocket.send(JSON.stringify({  org_id: orgId, doctor_id: docId, beneficiary_id: null, consulation_id: '', message: '', usertype: userType ,patient_name:null, property: "register" }));
	
       };
   
    //    this.webSocket.onmessage = (event) => {
    //     console.log('evnt data',event.data);
    //      if (event.data != 'ping' && event.data !='pong'){
    // const chatMessageDto = JSON.parse(event.data);
    // this.toastr.info('New Message Arrived')
    // this.chatMessages.push(chatMessageDto)
    // this.chatNames.push(chatMessageDto);
    // return
       
    //    }
    //    };
   
    this.webSocket.onmessage = (event) => {
      if (event.data != 'ping' && event.data !='pong'){
 const chatMessageDto = JSON.parse(event.data);
      if (chatMessageDto.usertype == "Doctor"){
        this.chatMessages.push(chatMessageDto)
        return;
               }
               const result = this.chatMessages.find( ({ patient_name }) => patient_name === chatMessageDto.patient_name);
               if(result==undefined && chatMessageDto.doctor_id == this.docData.id){
                 sessionStorage.setItem('firstDocID',chatMessageDto.doctor_id)
                this.chatNames.push(chatMessageDto);
               } 
               if(sessionStorage.getItem('firstDocID') != chatMessageDto.doctor_id && result.doctor_id == chatMessageDto.doctor_id){
                this.chatNames.push(chatMessageDto);
               }
              //  else if (result !=undefined && chatMessageDto.doctor_id == this.docData.id){
              //   this.chatNames.push(chatMessageDto);
              //  }
              //  if (this.chatMessages.find(element => element.patient_name != chatMessageDto.patient_name)){
              //   this.chatNames.push(chatMessageDto);
              //          }else if(this.chatMessages.length==0){
              //           this.chatNames.push(chatMessageDto);
              //          }
      // this.count = true;\
      if (chatMessageDto.doctor_id == this.docData.id) {
        // this.chatMessages.push(chatMessageDto);
        if (chatMessageDto.usertype != "Doctor") {
          this.count.next(true);
          if (this.count.subscribe(res => res == true)) {
            this.toastr.info('New Message Arrived From'+ ' ' + chatMessageDto.patient_name )
          }
        }
//         if (chatMessageDto.usertype == "Doctor"){
//  this.chatMessages.push(chatMessageDto)
//         }
      }
      if(this.chatMessages.length==0){
        if (chatMessageDto.doctor_id == this.docData.id) {
        this.chatMessages.push(chatMessageDto);
        //return;
        }
          }else if(this.chatMessages.length!=0 && this.chatMessages.find(element => element.beneficiary_id == chatMessageDto.beneficiary_id) ){
              for(let i=0;i<this.chatMessages.length;i++){
                if(this.chatMessages[i].beneficiary_id==chatMessageDto.beneficiary_id){
                  if (chatMessageDto.doctor_id == this.docData.id) {
                    this.chatMessages.push(chatMessageDto);
                    return;
            // this.chatMessages[i].message=this.chatMessages[i].message  +','+''+  chatMessageDto.message;
                }
              }
          }
        }else if(this.chatMessages.length!=0 && this.chatMessages.find(element => element.beneficiary_id != chatMessageDto.beneficiary_id)){
          if (chatMessageDto.doctor_id == this.docData.id) {
          this.chatMessages.push(chatMessageDto);
          }
        }
      if (chatMessageDto.doctor_id == this.docData.id) {
        // this.chatMessages.push(chatMessageDto);
        if (chatMessageDto.usertype != "Doctor") {
          this.count.next(true);
          if (this.count.subscribe(res => res == true)) {
            this.toastr.info('New Message Arrived From'+ ' ' + chatMessageDto.patient_name )
          }
        }
//         if (chatMessageDto.usertype == "Doctor"){
//  this.chatMessages.push(chatMessageDto)
//         }
      }
    }
    };
       this.webSocket.onclose = (event) => {
         console.log('Close: ', event);
       };
     }
  public sendMessage(chatMessageDto: ChatMessageDto) {
    this.webSocket.send(JSON.stringify(chatMessageDto));
  }
  public sendMessage2(name:any) {
    this.webSocket.send(name);
  }
  public closeWebSocket() {
    this.webSocket.close();
  }
}
