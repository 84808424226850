import { ExcelService } from 'src/app/shared/services/excel.service';
import { map } from 'rxjs/operators';
import { Component, OnInit,Injectable, Input } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Common } from 'src/app/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbTimeStruct, NgbTimeAdapter, NgbTimepickerModule } from '@ng-bootstrap/ng-bootstrap';
declare let $: any;
import * as uuid from 'uuid';
const pad = (i: number): string => (i < 10 ? `0${i}` : `${i}`);

/**
 * Example of a String Time adapter
 */
@Injectable()
export class NgbTimeStringAdapter extends NgbTimeAdapter<string> {
	fromModel(value: string | null): NgbTimeStruct | null {
		if (!value) {
			return null;
		}
		const split = value.split(':');
		return {
			hour: parseInt(split[0], 10),
			minute: parseInt(split[1], 10),
			second: parseInt(split[2], 10),
		};
	}

	toModel(time: NgbTimeStruct | null): string | null {
		return time != null ? `${pad(time.hour)}:${pad(time.minute)}:${pad(time.second)}` : null;
	}
}
@Component({
  selector: 'app-consent-registration',
  templateUrl: './consent-registration.component.html',
  styleUrls: ['./consent-registration.component.css']
})
export class ConsentRegistrationComponent implements OnInit {
  @Input() benId:any;
  meridian:boolean=true;
  spinners:boolean=true;
  abha_name:any='';
  time:any = {hour: new Date().getHours(), minute: new Date().getMinutes(), second: new Date().getSeconds()};
  //  time: Date = new Date();
  tempArr: any = [] ;
  consentForm: FormGroup;
  start_date: any = new Date();
  end_date: any = new Date();
  expiry_date: any = new Date();
  max_date = new Date();
  networkCentreList:any = []; 
  networkDateList: any=[];
  minDate: Date; minToDate: Date; docId = '';
  submitted = false; 
  fileName = 'consent.xlsx';
  excelData: any;
  pipe = new DatePipe('en-US');
  isNIG = environment.isNIG;
  isCentreWiseSelect: boolean = true;
  currDiv: string;
  title: string = 'Add New Consent';
  minDateEx=new Date();
  purpose: any='';
  purposeList: any=[];
  hittypeList: any=[];
  hittype: any='';
  doctorData: any;
  abha_address: any='';
  getCallBackData: any;
  doctorHospital: any = [];
  hfrCode: any;
  constructor(private regService: RegistrationService
    , public activeModal: NgbActiveModal
    , protected toastr: ToastrService
    , private formBuilder: FormBuilder
    , private datePipe: DatePipe
    , private masterService: MasterService,
    private excelDownloadService:ExcelDownloadService
    , private authSvc: AuthenticationService
    ) {
    // this.minDate = new Date();
    // this.minDate.setDate(this.minDate.getDate());

  }

  ngOnInit(): void {
    // this.time = {hour: new Date().getHours(), minute: new Date().getMinutes()};
    // this.time = new Date().getHours(), new Date().getMinutes();
    let doc = JSON.parse(sessionStorage.getItem('DoctorDetails'));
    this.doctorData=doc;
    this.abha_address= sessionStorage.getItem('benHealthId');


    this.doctorData=doc;
    this.doctorHospital=this.doctorData.doctor_hospitals;
    this.hfrCode=this.doctorHospital[0].hfr_code;
    this.abhaAddress();
    this.initFormControls(),
    // this.LoadData()

    this.getHitypes();
    this.getPurpose();
   
  }


  initFormControls() {
    this.consentForm = this.formBuilder.group({
      abha_address: [this.abha_address],
      name:[this.abha_name],
      purpose: [''],
      start_date: ['', Validators.required],
      end_date: ['', Validators.required],
      hittype: [''],
      expiry_date: [''],
      time: ['']
    });

  }
  get f() { return this.consentForm.controls; }

  LoadData() {

    if(this.consentForm.value.abha_address==='' ||  this.consentForm.value.purpose==='' || this.tempArr.length==0){
     this.toastr.error('Please enter Purpose and HiTypes');
      return;
    }
    let expDate:any = this.consentForm.value.expiry_date;

    if(this.datePipe.transform(expDate, 'yyyy-MM-dd') == this.datePipe.transform(new Date(),'yyyy-MM-dd')){
      if(this.time.hour<=new Date().getHours()){
        if(this.time.minute< new Date().getMinutes() ||  this.time.minute== new Date().getMinutes()  ){
          this.toastr.warning('Please provide future time for consent expiry date');
       return;
        }
       
      }
    }
      // let min:any=this.time.minute-30;
      
      //  let hour:any=0;
      //  if(Math.sign(min)!=1){
      //   min=-(-min)
      //    min=60-min;
      //    hour=this.time.hour-1;
      //    if(hour>5){
      //    hour=hour-5;
      //    }
      //  }else{
      //   // min=60-min;
      //    hour=this.time.hour;
      //   if(hour>5){
      //   hour=hour-5;
      //   }
      //  }
      //  this.time.hour=hour;
      //  this.time.minute=min;
       expDate.setUTCHours(this.time.hour, this.time.minute, 30);
  //      let obj: any= expDate.toUTCString();
  //  console.log('obj',obj);
  //  alert('##########');
      // expDate.setUTCHours(expDate.getHours() - 5);
      // console.log('exp date 1',expDate);
    
      // expDate.setUTCMinutes(expDate.getMinutes() - 30);
      // console.log('exp date 2',expDate);
      // var localOffset = expDate.getTimezoneOffset() * 60000;
      // var localTime = expDate.getTime() - localOffset;
      // expDate=localTime
    // console.log('Time',this.consentForm.value.expiry_date,expDate.toISOString());
    // alert('###########');
    const uuId = uuid.v4();
      let firstName = this.purposeList.find(x => x.code === this.purpose).displayName;
      let text;
      this.purposeList.find(res=>{
        if(this.purpose==res.displayName){
         text=res.displayName
        }
      })
      let hittypeNames=[];
      this.tempArr.map(res=>{
        hittypeNames.push(res.code);
      })
      let payLoad={
        "requestId": uuId,
        "timestamp": new Date(),
        "consent": {
          "purpose": {
            "text": "string",
            "code": this.purpose,
            "refUri": "string"
          },
          "patient": {
            "id": this.consentForm.value.abha_address,
          },
          "hiu": {
            "id": this.hfrCode
          },
          "requester": {
            "name": this.doctorData.first_name+this.doctorData.last_name,
            "identifier": {
              "type": "REGNO",
              "value": "MH1001",
              "system": "https://www.mciindia.org"
            }
          },
          "hiTypes": hittypeNames,
          "permission": {
            "accessMode": "VIEW",
            "dateRange": {
             
              // "from":   this.datePipe.transform(this.consentForm.value.start_date, 'yyyy-MM-dd HH:mm:ss'),
              // "to": this.datePipe.transform(this.consentForm.value.end_date, 'yyyy-MM-dd HH:mm:ss'),
              "from":   this.consentForm.value.start_date.toUTCString(),
              "to": this.end_date.toUTCString(),
            
            },
            "dataEraseAt": expDate.toUTCString(),
            "frequency": {
              "unit": "HOUR",
              "value": 1,
              "repeats": 1
            }
          }
        }
      }
    if (this.consentForm.value.agent_id == "") {
      this.toastr.error('Please Select Health Care Worker');
      return;
    }
 
  this.regService.consentNdhm(payLoad,sessionStorage.getItem('benIdConsent')).subscribe(res => {
    this.networkDateList = res.data;
    if(res.status==='OK'){
      this.getCallback(this.networkDateList,'Create Consent');

    }
   
   
  });

  }

  onFromDateChange(val: Date) {
    if (val) {
      let start_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.consentForm.get('start_date').setValue(new Date(start_date.setDate(start_date.getDate())));
      this.minDate = start_date;
    }
  }
  //new line added for on To date change 11-04-2022
  onToDateChange(val: Date) {
    if (val) {
      let end_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.consentForm.get('end_date').setValue(new Date(end_date.setDate(end_date.getDate())));
      //this.minDate = end_date;
    }
  }

  abhaAddress(){
    const uuId = uuid.v4();
    let abhaPayload={
      "requestId": uuId,
      "timestamp": new Date(),
      "query": {
        "patient": {
          "id": this.abha_address
        },
        "requester": {
          "type": "HIU",
          "id": this.hfrCode
        }
      }
    }
    this.regService.getAbhaAddress(abhaPayload,sessionStorage.getItem('benIdConsent')).subscribe(res=>{
      let anhAddress=res.data;
      // console.log('anhAddress->', anhAddress);
      if(res.status==='OK'){
        this.getCallback(anhAddress,'Validate');
      }      
    })
  }
  //Get cae context method added 04-07-2023
  getCallback(data:any,type:any){
    setTimeout(() => {
      this.regService.getCallback(data.requestId).subscribe(res=>{
       this.getCallBackData=res.data;
       if(res.status==='OK'){
         if(this.getCallBackData.response==null){
          if(type==='Validate'){
          this.abha_name = sessionStorage.getItem('benName');
          this.toastr.success('Valid Abha Address'+' '+'Patient information '+ this.abha_name);
          }
        }
        else if( this.getCallBackData.response!=null && this.getCallBackData.response.error==null){
          if(type==='Validate'){

            this.toastr.success('Valid Abha Address'+' '+'Patient information '+ res.data?.response?.patient?.name);
           this.abha_name= res.data?.response?.patient?.name;
          }else{
            this.toastr.success('Consent Initiated');
            this.activeModal.close('save');
          }
            
         }
         else {
          this.toastr.error(this.getCallBackData.response.error.message);
         }
       }
      })
    }, 3000);
  }
  getHitypes(){
    this.masterService.getallhitypes().subscribe(res=>{
     this.hittypeList=res.data;
   
      
    })
    // this.hittypeList=[
    //   { 'code':'Prescription','status_name':'Prescription'}, { 'code':'DiagnosticReport','status_name':'Diagnostic Report'}, 
    //   { 'code':'OPConsultation','status_name':'OP Consultation'}, { 'code':'DischargeSummary','status_name':'Discharge Summary'}, 
    //   { 'code':'ImmunizationRecord','status_name':'Immunization Record'}, { 'code':'HealthDocumentRecord','status_name':'Record artifact'},
    //   { 'code':'WellnessRecord','status_name':'Wellness Record'}
    //   ];
  }
  
  getPurpose(){
    this.masterService.getallpurposeofuse().subscribe(res=>{
      this.purposeList=res.data;
            
    })
    	
    // this.purposeList=[{'code':'CAREMGT','name':'Care Management'},{'code':'BTG','name':'Break the Glass'},
    // {'code':'PUBHLTH','name':'Public Health'},{'code':'HPAYMT','name':'Healthcare Payment'},
    // {'code':'DSRCH','name':'	Disease Specific Healthcare Research'},{'code':'PATRQT','name':'Self Requested'}];
  }

  onChangeCategory(event:any, item: any){ 
      if(event.currentTarget.checked){
       this.tempArr.push(item);
      }else{
       this.tempArr.splice(this.tempArr.findIndex(x => x.code == item.code), 1);
      }
  }
  onPurposeChange(){
    // this.purpose=
  }





}
