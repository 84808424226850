<div class="row">
  <div class="container-fluid pl-0 pr-0">
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-header-actions mx-auto">
          <div class="card-header">
            Appointments Scheduled
          </div>
          <div class="card-body">
            <div class="">
              <div class="custom-control-inline custom-control custom-radio">
                <input checked class="custom-control-input" id="customRadio11" type="radio" name="is_active"
                  (change)="isShow=true;LoadDataForVideo()">
                <label class="custom-control-label" for="customRadio11"><b>Appointment for video call </b></label>
              </div>
              <div class="custom-control-inline custom-control custom-radio">
                <input class="custom-control-input " id="customRadio22" type="radio" name="is_active"
                  (change)="isShow=false;LoadDataForAudio()">
                <label class="custom-control-label" for="customRadio22"><b>Appointment for Audio call</b></label>
              </div>
            </div> <br />


            <div class="card-body" [hidden]="!isShow">
              <form [formGroup]="appointmentForm" (ngSubmit)="LoadDataForVideo()">
                <div class="row ">
                  <div class="container">
                    <div class="row ">
                      <div class='col-md-3'>
                        <div class="form-group">
                          <div class='date' id='datetimepicker6'>
                            <label class="control-label">From Date <span class="text-danger">*</span> </label>
                            <div>
                              <input id="fromDate" name="fromDate" type="text" autocomplete="off" onpaste="return false"
                                placeholder="From" bsDatepicker #dp="bsDatepicker" (focus)="dp.show()"
                                [maxDate]="max_date" (focus)="dp1.hide()" formControlName="fromDate"
                                (bsValueChange)="onFromDateChange($event)" onkeypress='return event.charCode == 8'
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                                [ngClass]="{ 'is-invalid': submitted && f.fromDate.errors }"
                                class="form-control form-control-sm">
                              <div *ngIf="submitted && f.fromDate.errors" class="invalid-feedback">
                                <div *ngIf="f.fromDate.errors.required">From Date is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='col-md-3'>
                        <div class="form-group">
                          <div class='date' id='datetimepicker7'>
                            <label class="control-label">To Date <span class="text-danger">*</span> </label>
                            <div>
                              <input id="toDate" name="toDate" type="text" autocomplete="off" onpaste="return false"
                                placeholder="To" bsDatepicker formControlName="toDate" (focus)="dp.hide()"
                                [minDate]="minDate"   (bsValueChange)="onToDateChange($event)" bsDatepicker
                                #dp1="bsDatepicker" (focus)="dp1.show()"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                                [ngClass]="{ 'is-invalid': submitted && f.toDate.errors }"
                                class="form-control form-control-sm" onkeypress='return event.charCode == 8'>
                              <div *ngIf="submitted && f.toDate.errors" class="invalid-feedback">
                                <div *ngIf="f.toDate.errors.required">To Date is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='col-md-2' style="margin-top: 2rem;">
                        <button type="submit" class="btn btn-sm btn-primary" value="Submit"
                          (focus)="dp1.hide()">Submit</button>&nbsp;&nbsp;
                        <!-- <button type="button" class="btn btn-sm btn-danger" (click)="clear()">Clear</button> -->
                      </div>
                      <div class='col-md-2' style="margin-top: 2rem;">
                        <button type="button" class="btn  btn-sm btn-secondary float-right"  *ngIf="excelData && excelData.length>0" (click)="downLoadExcel('video')">
                           <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> 
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="table-responsive">
                <table datatable id="appointmentTable" class="table table-striped table-bordered table-sm"
                  style="width:100%">
                  <thead class="text-center">
                    <tr>
                      <th>S No</th>
                      <th>Appointment Date</th>
                      <!-- <th>Appointment Time</th> -->
                      <th>Doctor Name</th>
                      <th>Patient Name</th>
                      <th>Gender</th>
                      <th>Mobile Number</th>
                      <th>Video Appointment Status</th>
                      <th>Call Status</th>
                      <!-- <th>Call Type</th> -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of lstGrid;let i=index">
                      <td class="text-center">{{i+1}}</td>
                      <td class="text-center">{{item.appointment_date | date:'dd-MM-yyyy'}}</td>
                      <!-- <td class="text-center">{{item.appointment_time}}</td> -->
                      <td class="text-uppercase">{{item.doctor_firstName}} {{item.doctor_lastName}}</td>
                      <td class="singleLine text-uppercase">{{item.chart_history?.patientInformation?.isSelf == true ?
                        item.patient_name : item.chart_history?.patientInformation?.name}}</td>
                      <td class="singleLine text-uppercase">{{item.chart_history?.patientInformation?.isSelf == true ?
                        item.gender : item.chart_history?.patientInformation?.gender}}</td>
                      <td class="singleLine text-uppercase">{{item.chart_history?.patientInformation?.isSelf == true ?
                        item.mobile : ((item.chart_history?.patientInformation?.mobile != null &&
                        item.chart_history?.patientInformation?.mobile != '') ?
                        item.chart_history?.patientInformation?.mobile : item.mobile)}}</td>
                      <!-- few times we're not getting "mobile" key in patient info tag -->
                      <td *ngIf="item.status!=='success'"></td>
                      <td *ngIf="item.status==='success'">Booked</td>
                      <td *ngIf="item.pocStatus==='NOT SAVED'">Not Completed</td>
                      <td *ngIf="item.pocStatus==='SAVED'">Completed</td>
                      <!-- <td><button class="btn btn-sm btn-success">{{item.call_type}}</button></td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="card-body" [hidden]="isShow">
              <!-- <form [formGroup]="appointmentFormmm" (ngSubmit)="LoadDataForAudio()">
                <div class="row ">
                  <div class="container">
                    <div class="row ">
                      <div class='col-md-3'>
                        <div class="form-group">
                          <div class='date' id='datetimepicker6'>
                            <label class="control-label">From Date <span class="text-danger">*</span> </label>
                            <div>
                              <input id="fromDate" name="fromDate" type="text" autocomplete="off" onpaste="return false"
                                placeholder="From" bsDatepicker #dp="bsDatepicker" (focus)="dp.show()"
                                [maxDate]="max_date" (focus)="dp1.hide()" formControlName="fromDate"
                                (bsValueChange)="onFromDateChangeee($event)" onkeypress='return event.charCode == 8'
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                                [ngClass]="{ 'is-invalid': submitted && f.fromDate.errors }"
                                class="form-control form-control-sm">
                              <div *ngIf="submitted && g.fromDate.errors" class="invalid-feedback">
                                <div *ngIf="g.fromDate.errors.required">From Date is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='col-md-3'>
                        <div class="form-group">
                          <div class='date' id='datetimepicker7'>
                            <label class="control-label">To Date <span class="text-danger">*</span> </label>
                            <div>
                              <input id="toDate" name="toDate" type="text" autocomplete="off" onpaste="return false"
                                placeholder="To" bsDatepicker formControlName="toDate" (focus)="dp.hide()"
                                [minDate]="minToDate" (bsValueChange)="onFromDateChangeee($event)" bsDatepicker
                                #dp1="bsDatepicker" (focus)="dp1.show()"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                                [ngClass]="{ 'is-invalid': submitted && f.toDate.errors }"
                                class="form-control form-control-sm" onkeypress='return event.charCode == 8'>
                              <div *ngIf="submitted && g.toDate.errors" class="invalid-feedback">
                                <div *ngIf="g.toDate.errors.required">To Date is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='col-md-2' style="margin-top: 2rem;">
                        <button type="submit" class="btn btn-sm btn-primary" value="Submit"
                          (focus)="dp1.hide()">Submit</button>&nbsp;&nbsp;
                       
                      </div>
                      <div class='col-md-2' style="margin-top: 2rem;">
                        <button type="button" class="btn  btn-sm btn-secondary float-right" (click)="exporttoexcel()">
                           <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> 
                      </div>
                    </div>
                  </div>
                </div>
              </form> -->
            
              <form [formGroup]="appointmentFormmm" (ngSubmit)="LoadDataForAudio()">
                <div class="row ">
                  <div class="container">
                    <div class="row ">
                      <div class='col-md-3'>
                        <div class="form-group">
                          <div class='date' id='datetimepicker6'>
                            <label class="control-label">From Date <span class="text-danger">*</span> </label>
                            <div>
                              <input id="fromDate" name="fromDate" type="text" autocomplete="off" onpaste="return false"
                                placeholder="From" bsDatepicker #fd="bsDatepicker" (focus)="fd.show()"
                                [maxDate]="max_date"  (focus)="td.hide()" formControlName="fromDate"
                                (bsValueChange)="onFromDateChangeee($event)" onkeypress='return event.charCode == 8'
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                                [ngClass]="{ 'is-invalid': submitted && f.fromDate.errors }"
                                class="form-control form-control-sm">
                              <div *ngIf="submitted && g.fromDate.errors" class="invalid-feedback">
                                <div *ngIf="g.fromDate.errors.required">From Date is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='col-md-3'>
                        <div class="form-group">
                          <div class='date' id='datetimepicker7'>
                            <label class="control-label">To Date <span class="text-danger">*</span> </label>
                            <div>
                              <input id="toDate" name="toDate" type="text" autocomplete="off" onpaste="return false"
                                placeholder="To" bsDatepicker formControlName="toDate" (focus)="fd.hide()"
                                 (bsValueChange)="onToDateChangeee($event)" bsDatepicker
                                #td="bsDatepicker" (focus)="td.show()"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                                [ngClass]="{ 'is-invalid': submitted && f.toDate.errors }"
                                class="form-control form-control-sm" onkeypress='return event.charCode == 8'>
                              <div *ngIf="submitted && g.toDate.errors" class="invalid-feedback">
                                <div *ngIf="g.toDate.errors.required">To Date is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='col-md-2' style="margin-top: 2rem;">
                        <button type="submit" class="btn btn-sm btn-primary" value="Submit"
                          (focus)="dp1.hide()">Submit</button>&nbsp;&nbsp;
                        <!-- <button type="button" class="btn btn-sm btn-danger" (click)="clear()">Clear</button> -->
                      </div>
                      <div class='col-md-2' style="margin-top: 2rem;">
                        <button type="button" class="btn  btn-sm btn-secondary float-right" *ngIf="excelData_audio.length>0" (click)="downLoadExcel('audio')">
                          <i class="fa fa-file-excel-o"></i>&nbsp;Export</button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
              <div class="table-responsive">
                <table datatable id="appointmentTablee" class="table table-striped table-bordered table-sm"
                  style="width:100%">
                  <thead class="text-center">
                    <tr>
                      <th>S No</th>
                      <th>Appointment Date</th>
                      <!-- <th>Appointment Time</th> -->
                      <th>Doctor Name</th>
                      <th>Patient Name</th>
                      <th>Gender</th>
                      <th>Mobile Number</th>
                      <th>Audio Appointment Status</th>
                      <th>Audio Call</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of lstGriddd;let i=index">
                      <td class="text-center">{{i+1}}</td>
                      <td class="text-center">{{item.appointment_date | date:'dd-MM-yyyy'}}</td>
                      <!-- <td class="text-center">{{item.appointment_time}}</td> -->
                      <td class="text-uppercase">{{item.doctor_firstName}} {{item.doctor_lastName}}</td>
                      <td class="singleLine text-uppercase">{{item.chart_history?.patientInformation?.isSelf == true ?
                        item.patient_name : item.chart_history?.patientInformation?.name}}</td>
                      <td class="singleLine text-uppercase">{{item.chart_history?.patientInformation?.isSelf == true ?
                        item.gender : item.chart_history?.patientInformation?.gender}}</td>
                      <td class="singleLine text-uppercase">{{item.chart_history?.patientInformation?.isSelf == true ?
                        item.mobile : ((item.chart_history?.patientInformation?.mobile != null &&
                        item.chart_history?.patientInformation?.mobile != '') ?
                        item.chart_history?.patientInformation?.mobile : item.mobile)}}</td>
                      <!-- few times we're not getting "mobile" key in patient info tag -->
                      <td>{{item.status}}</td>
                      <td>
                        <!-- <button *ngIf="item.pocStatus=='NOT SAVED'" (click)="onAudioCall(item)" class="btn btn-sm btn-success">{{item.call_type}}</button> -->
                        <button *ngIf="item.pocStatus=='NOT SAVED'" (click)="onAudioCall(item)" class="btn btn-sm btn-success">Call</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>