<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="organizationForm" (ngSubmit)="onSubmit($event)">
        <div class="container-fluid">
            <div class="row small">
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="control-label">Organization Name<span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" formControlName="orgnization_name" id="orgnization_name"
                                placeholder="Organization Name" maxlength="20" autocomplete="off"
                                [ngClass]="{ 'is-invalid': submitted && f.orgnization_name.errors }"
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.orgnization_name.errors" class="invalid-feedback">
                                <div *ngIf="f.orgnization_name.errors.required">Organization Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="control-label">Tenant Code</label>
                        <div>
                            <input type="text" disabled class="form-control form-control-sm" formControlName="id"
                                id="id" placeholder="Tenant Code">
                        </div>
                    </div>
                </div>
                <div class="col-xl-12">
                    <div class="form-group">
                        <label class="control-label">Organization Address<span class="text-danger">*</span> </label>
                        <div>
                            <textarea formControlName="main_address" id="main_address"
                                placeholder="Organization Address" autocomplete="off"
                                [ngClass]="{ 'is-invalid': submitted && f.main_address.errors }"
                                class="form-control form-control-sm" rows="2">
                            </textarea>
                            <div *ngIf="submitted && f.main_address.errors" class="invalid-feedback">
                                <div *ngIf="f.main_address.errors.required">Organization Address is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="control-label">Administrative Email<span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" formControlName="email" placeholder="Administrative Email"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" id="email" maxlength="60"
                                class="form-control form-control-sm" autocomplete="off" required>
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.pattern">Sample Email address is x@yahoo.com</div>
                                <div *ngIf="f.email.errors.required">Administrative Email is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="control-label">Refund Email<span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" formControlName="refund_email" id="refund_email" autocomplete="off"
                                [ngClass]="{ 'is-invalid': submitted && f.refund_email.errors }" maxlength="60"
                                placeholder="Refund Email" class="form-control form-control-sm" required>
                            <div *ngIf="submitted && f.refund_email.errors" class="invalid-feedback">
                                <div *ngIf="f.refund_email.errors.pattern">Sample Email address is x@yahoo.com</div>
                                <div *ngIf="f.refund_email.errors.required">Refund Email is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="control-label">Support Email<span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" formControlName="support_email" id="support_email" autocomplete="off"
                                [ngClass]="{ 'is-invalid': submitted && f.support_email.errors }" maxlength="60"
                                placeholder="Support Email" class="form-control form-control-sm" required>
                            <div *ngIf="submitted && f.support_email.errors" class="invalid-feedback">
                                <div *ngIf="f.support_email.errors.pattern">Sample Email address is x@yahoo.com</div>
                                <div *ngIf="f.support_email.errors.required">Support Email is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="control-label">Support Contact Number<span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" formControlName="primary_contact_no" id="primary_contact_no"
                                placeholder="Support Contact Number" [maxlength]="maxLngth" [pattern]="mobPtrn"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && f.primary_contact_no.errors }"
                                class="form-control form-control-sm" autocomplete="off">
                            <div *ngIf="submitted && f.primary_contact_no.errors" class="invalid-feedback">
                                <div *ngIf="f.primary_contact_no.errors.required">Support Contact Number is required
                                </div>
                                <div *ngIf="f.primary_contact_no.errors.pattern">Support Contact Number {{mobErrMsg}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-6">
                    <label class="control-label">Status <span class="text-danger">*</span></label>
                    <div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input class="custom-control-input" formControlName="is_active" [value]="true"
                                id="customRadio1" type="radio">
                            <label class="custom-control-label" for="customRadio1">Active</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input class="custom-control-input" formControlName="is_active" [value]="false"
                                id="customRadio2" type="radio">
                            <label class="custom-control-label" for="customRadio2">Inactive</label>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <label class="control-label">License Plan <span class="text-danger">*</span></label>
                    <div>
                        <select class="form-control form-control-sm" formControlName="license_plan"
                            [ngClass]="{ 'is-invalid': submitted && f.license_plan.errors }"
                            (change)="onPlanChng($event.target.value)">
                            <option value="">Select</option>
                            <option *ngFor="let plan of lstPlans" [value]="plan.id">{{plan.license_plan}}</option>
                        </select>
                        <div *ngIf="submitted && f.license_plan.errors" class="invalid-feedback">
                            <div *ngIf="f.license_plan.errors.required">License Plan is required</div>
                        </div>
                        <label *ngIf="plnDtls!=''"><strong>{{plnDtls}}</strong></label>
                    </div>
                </div>
            </div> <br />
            <div class="row">
                <div class="col-xl-12">
                    <label class="control-label">Registration Amount</label>
                    <div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input class="custom-control-input" formControlName="is_prior_payment_req" [value]="false"
                                id="customRadio3" type="radio">
                            <label class="custom-control-label" for="customRadio3">No</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input class="custom-control-input" formControlName="is_prior_payment_req" [value]="true"
                                id="customRadio4" type="radio" #regamt>
                            <label class="custom-control-label" for="customRadio4">Yes</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input type="text" formControlName="patient_registration_amount"
                                class="form-control form-control-md" [attr.disabled]="regamt.checked ? null : false"
                                maxlength="5" minLength="1"
                                onkeypress='return  (event.charCode >= 48 && event.charCode <= 57)'>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <input type="submit" id="btnsubmit" style="display: none;">
    </form>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" (click)="submitForm()" value="Submit">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>