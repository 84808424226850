import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Common } from 'src/app/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import * as XLSX from 'xlsx';
declare let $: any;

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
  providers: [DatePipe]
})
export class PaymentComponent implements OnInit {
  paymentForm: FormGroup;
  from_date: any = new Date();
  to_date: any = new Date();
  max_date = new Date();
  payemtlist = []; minDate: Date; minToDate: Date; docId = '';
  submitted = false;
  gendersList: Array<any> = [];
  fileName = 'payment list.xlsx';

  constructor(private regService: RegistrationService
    , protected toastr: ToastrService
    , private formBuilder: FormBuilder
    , private datePipe: DatePipe
    , private masterService: MasterService
    , private authSvc: AuthenticationService) {
    // this.minDate = new Date();
    // this.minDate.setDate(this.minDate.getDate());
    authSvc.crntOrgIDSubject.subscribe(s => { this.payemtlist = [], Common.loadDataTable(this.payemtlist, '#paymenStatus');
      if (location.href.includes('home/payment'))
        this.ngOnInit();
    }), authSvc.isShowGlblOrg.next(true);
  }

  ngOnInit(): void {
    // this.authSvc.isShowGlblOrg.next(true), this.authSvc.tenantId = sessionStorage.getItem('defCrntTnnt'),
    // sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt')),
    this.initFormControls(), this.getAllMasters();
    let data = JSON.parse(sessionStorage.getItem('DoctorDetails'));
    // if (data) {
    //   this.docId = JSON.parse(data).id;
    //   setTimeout(() => this.LoadData());
    // }
    // else if (this.authSvc.roleId == null)
    //   this.LoadData();
    if (data)
      this.docId = JSON.parse(data).id, this.LoadData();
    else
      this.getDoctorDetails();
  }

  initFormControls() {
    this.paymentForm = this.formBuilder.group({
      from_date: [new Date(), Validators.required],
      to_date: [new Date(), Validators.required],
      doctor_id: ['']
    });
  }

  get f() { return this.paymentForm.controls; }

  LoadData() {
    this.submitted = true, this.payemtlist = [];
    let sendData = JSON.parse(JSON.stringify(this.paymentForm.value));
    [sendData.from_date, sendData.to_date, sendData.doctor_id] =
      [this.datePipe.transform(sendData.from_date, 'yyyy-MM-dd 00:00:00'),
      this.datePipe.transform(sendData.to_date, 'yyyy-MM-dd 00:00:00'), this.authSvc.roleId == 7 ? this.docId : 0];

    this.regService.saveData('getpaymentstatus', sendData).subscribe({
      next: res => {
        if (res.status === "OK") {
          if (res.data == null || res.data.length == 0)
            this.toastr.info('No records available', 'Payment Status');
          else
            this.payemtlist = res.data;
          Common.loadDataTable(this.payemtlist, '#paymenStatus');
        }
      },
      error: err => console.error('something wrong occurred: ', err),
      complete: () => { }
    });
  }

  onFromDateChange(val: Date) {
    if (val) {
      let to_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.paymentForm.get('to_date').setValue(new Date(to_date.setDate(to_date.getDate())));
      this.minDate = to_date;
    }
  }

  clear() {
    this.paymentForm.value.from_date = this.paymentForm.value.to_date = '';
  }
  getAllMasters() {
    const genders = this.masterService.getAll('getallgenders');
    // const calltypes = this.masterService.getAll('getallcalltypes');

    forkJoin([genders]).subscribe(
      {
        next: res => {
          res.forEach((list, ind) => {
            if (list.status === 'OK') {
              if (list.data == null || list.data.length == 0)
                console.log('No records available');
              //this.toastr.info('No records available', 'Pharmacy List');
              else {
                const assignList = {
                  '0': () => this.gendersList = list.data,
                  // '1': () => this.calltypesList = list.data,
                }
                assignList[ind]();
              }
            }
          });
        },
        error: err => console.error('something wrong occurred: ', err),
        complete: () => { }
      });

  }

  getGender(id: string): string {
    let result = this.gendersList.find(c => c.id == id);
    return (result) ? result.gender_name : '';
  }
  getDoctorDetails() {
    let user = this.authSvc.currentUserValue;
    this.regService.getByID('doctor/getdoctorrecordbyuserid?userId=', user.id).subscribe(res => {
      if (res.data.length > 0) {
        // sessionStorage.setItem('DoctorDetails', JSON.stringify(res.data[0]));
        this.docId = res.data[0].id;
      }
    },
      err => { this.toastr.error(err) },
      () => { this.LoadData(); });
  }

  exportexcel(): void {

    var table = $('#paymenStatus').DataTable();

    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {
      / table id is passed over here /
      let element = document.getElementById('paymenStatus');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };

      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
}

}