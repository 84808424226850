

    <div class="container-fluid pl-0 pr-0">
        <div class="row">
          <div class="col-xl-12">
            <div class="card card-header-actions mx-auto">
              <div class="card-header">
                  Network Monthly Report
              </div>
    
              <div class="card-body">
                <!-- <div class="row my-2"> -->
                    <div class="mb-3">
                        <!-- <input name="radio1"
                        class="rBtn"
                               type="radio"
                               [checked]="isCentreWiseSelect"
                               (change)="handleCentreChange($event)" (click)="ShowDiv('centreWiseTable')"/> Centre Wise

                        <input name="radio2" 
                               class="rBtn"
                               type="radio"
                               [checked]="isDateWiseSelect"
                               (change)="handleDateChange($event)" (click)="ShowDiv('dateWiseTable')"/> Date Wise -->
                              <div class="d-flex justify-content-start align-items-center">
                                <input name="radio1"
                                class=""
                                type="radio"
                                [checked]="isCentreWiseSelect"
                                (change)="handleCentreChange($event)" />
                          <label for="centreWise" style="font-size: 16px !important; font-weight: bold !important;">Centre Wise</label>
                          &nbsp; &nbsp;
                         <input name="radio2" 
                                class="rBtn"
                                type="radio"
                                [checked]="isDateWiseSelect"
                               (change)="handleDateChange($event)"/>
                          <label for="dateWise" style="font-size: 16px !important; font-weight: bold !important;">Date Wise</label>
                              </div>
                               
                  </div>
                <!-- </div> -->
                <form [formGroup]="networkMonthlyForm" (ngSubmit)="LoadData()">
                  
                  <!-- new ui start  -->
                  <div class="row">
                    <div class="col-md">
                      <label class="control-label">From Date <span class="text-danger">*</span></label>
                      <div>
                           <input id="start_date" name="start_date" type="text" autocomplete="off" onpaste="return false"
                                  placeholder="From" bsDatepicker #dp="bsDatepicker" (focus)="dp.show()" (focus)="dp1.hide()"
                                  formControlName="start_date" [maxDate]="max_date"
                                  (bsValueChange)="onFromDateChange($event)" onkeypress='return event.charCode == 8'
                                  [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                                  [ngClass]="{ 'is-invalid': submitted && f.start_date.errors }"
                                  class="form-control form-control-sm">
                           <div *ngIf="submitted && f.start_date.errors" class="invalid-feedback">
                              <div *ngIf="f.start_date.errors.required">From Date is required</div>
                           </div>
                      </div>
                    </div>
                    <div class="col-md">
                      <label class="control-label">To Date <span class="text-danger">*</span></label>
                      <div>
                        <input id="end_date" name="end_date" type="text" autocomplete="off" onpaste="return false"
                          placeholder="To" bsDatepicker formControlName="end_date"  [minDate]="f.start_date.value" [maxDate]="max_date" (focus)="dp.hide()"
                          bsDatepicker #dp1="bsDatepicker" (focus)="dp1.show()" (bsValueChange)="onToDateChange($event)"
                          [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                          [ngClass]="{ 'is-invalid': submitted && f.end_date.errors }"
                          class="form-control form-control-sm" onkeypress='return event.charCode == 8'>
                        <div *ngIf="submitted && f.end_date.errors" class="invalid-feedback">
                          <div *ngIf="f.end_date.errors.required">To Date is required</div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-md">
                      <label class="control-label">{{isNIG ? 'States' : 'State'}}  </label>
                      <div>
                          <select formControlName="state_id" id="state_id" #State
                              (change)="locationChanged(State.value,'district')"
                              [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }"
                              class="form-control form-control-sm"
                              disabled>
                              <option value="">Select</option>
                              <option *ngFor="let state of stateList" [value]="state.id">
                                  {{state.state_name}}</option>
                          </select>
                          <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                              <div *ngIf="f.state_id.errors.required">
                                  {{isNIG ? 'States' : 'State'}} is required
                              </div>
                          </div>
                      </div>
                    </div> -->
                    <div class="col-md">
                        <label for="inputEmail4" class="form-label" >Select {{isNIG ? 'State Capitals' : 'District'}} </label>
                          <!-- formControlName="district_ids" -->
                        <ng-multiselect-dropdown 
                                    [placeholder]="'Select'"
                                    [settings]="dropdownSettings_district"
                                    [data]="districtList"
                                    name="district_ids"
                                    formControlName="district_ids"
                                    [(ngModel)]="selectedDistricts"
                                    (onSelect)="onItemSelectDistrict($event)"
                                    (onDeSelect)="onItemDeSelectDistrict($event)"
                                    (onSelectAll)="onSelectAllDistrict($event)"
                                    (onDeSelectAll)="onDeSelectAllDistrict($event)">
                           <!-- [(ngModel)]="selectedDistricts" -->
                        </ng-multiselect-dropdown>
                    </div>
                    
                    <div class="col-md">
                      <label for="inputEmail4" class="form-label" >Select Centres</label>
                      <!-- <label class="control-label"></label> -->
                      <div>
                        <!-- formControlName="center_id" -->
                        <ng-multiselect-dropdown (click)="show()"
                                        [placeholder]="'Select'"
                                        [settings]="dropdownSettings_centre"
                                        [data]="centerHospitals"
                                        [(ngModel)]="selectedCentres"
                                        name="center_id" formControlName="center_id"
                                        (onSelect)="onItemSelectCentre($event)"
                                        (onSelectAll)="onSelectAllCentre($event)"
                                        (onDeSelect)="onItemDeSelectCentre($event)"
                                        (onDeSelectAll)="onDeSelectAllCentre($event)">
                        </ng-multiselect-dropdown>
                      </div>
                    </div>
                 
                    <div class='col-md-3' style="margin-top: 2rem;"  >
                       <button type="submit" class="btn btn-sm btn-primary" value="Submit" (focus)="dp1.hide()">Submit</button>&nbsp;
                       <!-- <button type="button" class="btn btn-sm btn-danger" (click)="clear()">Clear</button> &nbsp; -->
                        <button type="button" class="btn  btn-sm btn-secondary  text-center" *ngIf="excelData && excelData.length>0" (click)="downLoadExcel()" > <i class="fa fa-file-excel-o"></i>Export</button>
                    </div>
                  </div>
                   <!-- new ui end  -->
                </form>
    
                <div class="table-responsive mt-3">
                    <!-- <h2 *ngIf="options">Supply</h2>
                    <h2 *ngIf="!options">Demand</h2> -->
                  <!-- <table datatable id="networkMonthlyReport" class="table table-striped table-bordered table-sm" style="width:100%">
                    <thead class="text-center">
                      <tr>
                        <th class="singleLine">Center Wise</th>
                       

                      </tr>
                    </thead>
                    <tbody>
                      *ngFor="let item of networkStatuslist;let i= index;"
                      <tr >
                        <td class="singleLine text-center">{{i+1}}</td>
                        <td></td>
                        
                      </tr>
                    </tbody>
                  </table> -->
                

                  <!--  -->
                   <!-- date wise table start  -->
                   <div *ngIf="!isCentreWiseSelect">
                    <h3>Date Wise</h3>
                    <table datatable id="networkMonthlyReport" class="table table-striped table-bordered table-sm" style="width:100%">
                      <thead class="text-center">
                        <tr>
                          <th class="singleLine">S.NO</th>
                          <th class="singleLine">District</th>
                          <th class="singleLine">Block</th>
                          <th class="singleLine">Center</th>
                          <th class="singleLine">Pharmacist/Healthcare Professional Name</th>
                          <th class="singleLine">Pharmacist/Healthcare Professional Number</th>
                          <th class="singleLine">Date</th>
                          <th class="singleLine">UP Time</th>
                          <th class="singleLine">Down Time</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let item of networkDateList;let i= index;">
                          <td class="singleLine">{{i+1}}</td>
                        <td class="singleLine">{{item?.district_name}}</td>
                        <td class="singleLine">{{item?.mandal_name}}</td>
                        <td class="singleLine">{{item?.center_name}}</td>
                        <td class="singleLine">{{item?.health_associate_name}}</td>
                        <td class="singleLine">{{item?.health_associate_mobile}}</td>
                        <td class="singleLine">{{item?.network_date | date: 'dd-MM-yyyy hh:mm:ss'}}</td>
                        <td class="singleLine">{{item?.up_time | number: '1.0-0'}}</td>
                        <td class="singleLine">{{item?.down_time | number: '1.0-0'}}</td>
                        </tr>
                      </tbody>
                    </table>
                   </div>
                    <!-- date wise table start  -->
                  <!-- centre wise table start  -->
                  <div *ngIf="isCentreWiseSelect">
                    <h3>Center Wise</h3>
                    <table datatable id="networkMonthlyReport" class="table table-striped table-bordered table-sm" style="width:100%">
                      <thead class="text-center">
                        <tr>
                          <th class="singleLine">S.NO</th>
                          <th class="singleLine">District</th>
                          <th class="singleLine">Block</th>
                          <th class="singleLine">Center</th>
                          <th class="singleLine">Pharmacist/Healthcare Professional Name</th>
                          <th class="singleLine">Pharmacist/Healthcare Professional Number</th>
                          <th class="singleLine">UP Time</th>
                          <th class="singleLine">Down Time</th>
                        </tr>
                      </thead>
                      <tbody>
                       <tr *ngFor="let item of networkCentreList; let i=index;">
                        <td class="singleLine">{{i+1}}</td>
                        <td class="singleLine">{{item?.district_name}}</td>
                        <td class="singleLine">{{item?.mandal_name}}</td>
                        <td class="singleLine">{{item?.center_name}}</td>
                        <td class="singleLine">{{item?.health_associate_name}}</td>
                        <td class="singleLine">{{item?.health_associate_mobile}}</td>
                        <!-- <td class="singleLine">{{item?.date}}</td> -->
                        <td class="singleLine">{{item?.up_time}}</td>
                        <td class="singleLine">{{item?.down_time}}</td>
                       </tr>
                      </tbody>
                    </table>
                  </div>
                    <!-- centre wise table end  -->
                   
                  
                  <!--  -->
                </div>
    
              </div>
            </div>
          </div>
        </div>
    
      </div>
  
  
  