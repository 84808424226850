<div class="modal-header">
    <h4 class="modal-title">Hub Registration Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click');">
        <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="cntrCtrlsForm">
            <div class="row">
                <div class="col-xl-12">
                    <div class="row mt-3">
                        <div class="col-xl-3">
                            <label>Hub Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm" placeholder="Hub Name" formControlName="hub_name"  />
                        </div>
                        
                      <div class="col-xl-3">
                          <div class="form-group">
                              <label class="control-label">Location Type <span class="text-danger">*</span> </label>
                              <div>
                                  <select formControlName="location_type_id" id="location_type_id" #locationId
                                      [ngClass]="{ 'is-invalid': submitted && f.location_type_id.errors }"
                                      (change)="locationChanged('','location')" class="form-control form-control-sm"
                                      [attr.disabled]="isEditable ? true : null">
                                      <option value="">Select</option>
                                      <option *ngFor="let location of locationTypeList" [value]="location.id">
                                          {{location.location_type}}</option>
                                  </select>
                                  <div *ngIf="submitted && f.location_type_id.errors" class="invalid-feedback">
                                      <div *ngIf="f.location_type_id.errors.required">Location Type is required</div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-xl-3">
                        <div class="form-group">
                            <label class="control-label">{{isNIG ? 'States' : 'State'}} <span class="text-danger">*</span> </label>
                            <div>
                                <select formControlName="state_id" id="state_id" #State
                                    (change)="locationChanged(State.value,'district')"
                                    [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }"
                                    class="form-control form-control-sm"
                                    disabled>
                                    <option value="">Select</option>
                                    <option *ngFor="let state of stateList" [value]="state.id">
                                        {{state.state_name}}</option>
                                </select>
                                <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                    <div *ngIf="f.state_id.errors.required">
                                        {{isNIG ? 'States' : 'State'}} is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                      <div class="col-xl-3">
                          <div class="form-group">
                              <label class="control-label">{{isNIG ? 'State Capitals' : 'District'}} <span class="text-danger">*</span> </label>
                              <div>
                                  <select formControlName="district_id" id="district_id" #District
                                      (change)="locationChanged(District.value,(+locationId.value==2)?'city':'mandal')"
                                      [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }"
                                      class="form-control form-control-sm" [attr.disabled]="isEditable ? true : null">
                                      <option value="">Select</option>
                                      <option *ngFor="let district of districtList" [value]="district.id">
                                          {{district.district_name}}</option>
                                  </select>
                                  <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                                      <div *ngIf="f.district_id.errors.required">
                                          {{isNIG ? 'State Capitals' : 'District'}} is required
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-xl-3">
                          <div class="form-group">
                              <label class="control-label">
                                  {{isSAGE ? 'Location' : (isNIG ? 'Local Government Areas' : 'Block')}}
                                  <span *ngIf="+locationId?.value==1" class="text-danger">*</span> </label>
                              <div>
                                  <select formControlName="mandal_id" id="mandal_id" #Mandal
                                      [attr.disabled]="(+locationId.value==2 || isEditable)?true:null"
                                      (change)="locationChanged(Mandal.value,'village')"
                                      [ngClass]="{ 'is-invalid': submitted && f.mandal_id.errors }"
                                      class="form-control form-control-sm">
                                      <option value="">Select</option>
                                      <option *ngFor="let mandal of mandalList" [value]="mandal.id">
                                          {{mandal.mandal_name}}</option>
                                  </select>
                                  <div *ngIf="submitted && f.mandal_id.errors" class="invalid-feedback">
                                      <div *ngIf="f.mandal_id.errors.required">
                                          {{isSAGE ? 'Location' : (isNIG ? 'Local Government Areas' : 'Block')}} is required
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  <!-- </div>
                  <div class="row"> -->
                      <div class="col-xl-3" *ngIf="+locationId.value==2">
                          <div class="form-group">
                              <label class="control-label">City </label>
                              <div>
                                  <select formControlName="city_id" id="city_id"
                                      [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }"
                                      class="form-control form-control-sm"
                                      [attr.disabled]="isEditable ? true : null">
                                      <option value="">Select</option>
                                      <option *ngFor="let city of cityList" [value]="city.id">
                                          {{city.city_name}}</option>
                                  </select>
                                  <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
                                      <div *ngIf="f.city_id.errors.required">City is required</div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div class="col-xl-3" *ngIf="+locationId.value != 2 && !isNIG">
                          <div class="form-group">
                              <label class="control-label">Village <span *ngIf="+locationId.value==1" class="text-danger">*</span> </label>
                              <div>
                                  <select formControlName="village_id" id="village_id"
                                      [attr.disabled]="(+locationId.value==2 || isEditable)?true:null"
                                      [ngClass]="{ 'is-invalid': submitted && f.village_id.errors }"
                                      class="form-control form-control-sm">
                                      <option value="">Select</option>
                                      <option *ngFor="let village of villageList" [value]="village.id">
                                          {{village.village_name}}</option>
                                  </select>
                                  <div *ngIf="submitted && f.village_id.errors" class="invalid-feedback">
                                      <div *ngIf="f.village_id.errors.required">Village is required
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                        <!-- <div class="col-xl-3">
                            <div class="form-group">
                              <label class="control-label">Location Type <span class="text-danger">*</span> </label>
                              <div>
                                <select formControlName="location_type_id" id="location_type_id" #locationId
                                  (change)="locationChanged('','location')"
                                  [ngClass]="{ 'is-invalid': submitted && f.location_type_id.errors }"
                                  class="form-control form-control-sm">
                                  <option value=''>Select</option>
                                  <option *ngFor="let location of locationTypeList" [value]="location.id">
                                    {{location.location_type}}</option>
                                </select>
                                <div *ngIf="submitted && f.location_type_id.errors" class="invalid-feedback">
                                  <div *ngIf="f.location_type_id.errors.required">Location Type is required</div>
                                </div>
                              </div>
                            </div>
                          </div> -->
                        <!-- <div class="col-xl-3">
                            <div class="form-group">
                              <label class="control-label">{{isNIG ? 'States' : 'State'}} <span class="text-danger">*</span> </label>
                              <div>
                                <select formControlName="state_id" id="state_id" #State (change)="locationChanged(State.value,'district')"
                                  [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }" class="form-control form-control-sm">
                                  <option value=''>Select</option>
                                  <option *ngFor="let state of stateList" [value]="state.id">
                                    {{state.state_name}}</option>
                                </select>
                                <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                  <div *ngIf="f.state_id.errors.required">
                                    {{isNIG ? 'States' : 'State'}} is required
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> -->
                        <!-- <div class="col-xl-3 mt-3">
                            <label>Division <span class="text-danger"><b>*</b></span> </label>
                            <select class="form-control form-control-sm" id="inputEmail15" formControlName="divisionid"  >
                                <option value=0>Select</option>
                                <option *ngFor="let item of division_list" [value]="item.id">{{item.division_name}}</option>
                            </select>
                        </div> -->
  
                        <!-- <div class="col-xl-3">
                            <div class="form-group">
                              <label class="control-label">{{isNIG ? 'State Capitals' : 'District'}} <span class="text-danger">*</span>
                              </label>
                              <div>
                                <select formControlName="district_id" id="district_id" #District
                                  (change)="locationChanged(District.value,(+locationId.value==2)?'city':'mandal')"
                                  [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }" class="form-control form-control-sm">
                                  <option value=''>Select</option>
                                  <option *ngFor="let district of districtList" [value]="district.id">
                                    {{district.district_name}}</option>
                                </select>
                                <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                                  <div *ngIf="f.district_id.errors.required">
                                    {{isNIG ? 'State Capitals' : 'District'}} is required
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> -->
                            <!-- <div class="col-xl-3">
        
                            <div class="form-group">
                              <label class="control-label">
                                Block / City
                              </label>
                              <div>
                                <select formControlName="mandal_id" id="mandal_id" #Mandal
                                  (change)="locationChanged(Mandal.value,'village')"
                                  [ngClass]="{ 'is-invalid': submitted && f.mandal_id.errors }" class="form-control form-control-sm">
                                  <option value="">Select</option>
                                  <option *ngFor="let mandal of mandalList" [value]="mandal.id">
                                    {{mandal.mandal_name}}</option>
                                </select>
                                <div *ngIf="submitted && f.mandal_id.errors" class="invalid-feedback">
                                  <div *ngIf="f.mandal_id.errors.required">
                                    {{isNIG ? 'Local Government Areas ' : 'Mandal '}} is required
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div> -->
                          <!-- <div class="col-xl-3" *ngIf="+locationId.value!=2 && !isNIG">
                            <div class="form-group">
                              <label class="control-label">Village </label>
                              <div>
                                <select formControlName="village_id" id="village_id"
                                  [ngClass]="{ 'is-invalid': submitted && f.village_id.errors }" class="form-control form-control-sm">
                                  <option value="">Select</option>
                                  <option *ngFor="let village of villageList" [value]="village.id">
                                    {{village.village_name}}</option>
                                </select>
                                <div *ngIf="submitted && f.village_id.errors" class="invalid-feedback">
                                  <div *ngIf="f.village_id.errors.required">Village is required</div>
                                </div>
                              </div>
                            </div>
                          </div>        -->
                          <!-- <div class="col-xl-3  mt-3">
                            <div class="form-group">
                              <label class="control-label">Hub-Coordinators </label>
                              <div>
                                <select formControlName="hub_coordinator_id" id="hub_coordinator_id"
                                   class="form-control form-control-sm">
                                  <option value="null">Select</option>
                                  <option *ngFor="let gap of getAllgaps" [value]="gap.id">
                                    {{gap.first_name}} {{gap.last_name}}</option>
                                </select>
                                <div *ngIf="submitted && f.village_id.errors" class="invalid-feedback">
                                  <div *ngIf="f.village_id.errors.required">Village is required</div>
                                </div>
                              </div>
                            </div>
                          </div>                               -->
                        <!-- <div class="col-xl-3">
                            <label>Incharge Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': submitted && f.incharge_name.errors }" placeholder="Arogya Kendra Incharge" formControlName="incharge_name" maxlength="50"
                            onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'/>
                            <div *ngIf="submitted && f.incharge_name.errors" class="invalid-feedback">
                              <div *ngIf="f.incharge_name.errors.required">Arogya Kendra Incharge Name is required</div>
                            </div>
                        </div> -->
  
                        <!-- <div class="col-xl-3 mt-3">
                            <label> Incharge Mobile No. <span class="text-danger">*</span></label>
                            <input type="text" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': submitted && f.incharge_mobile_no.errors }" placeholder="Arogya Kendra Incharge Mobile No." formControlName="incharge_mobile_no"
                                  [maxlength]="maxLngth" (keyup)="onKeyNmbr('incharge_mobile_no', $event.target.value)"
                                  onkeypress='return event.charCode >= 48 && event.charCode <= 57' [pattern]="mobPtrn" />
                                  <div *ngIf="submitted && f.incharge_mobile_no.errors" class="invalid-feedback">
                                    <div *ngIf="f.incharge_mobile_no.errors?.required">Mobile Number is required</div>
                                    <div *ngIf="f.incharge_mobile_no.errors.pattern">Mobile Number {{mobErrMsg}}</div>
                                </div>
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                        </div> -->
                        <div class="col-xl-3">
                          <label>Pin Code</label><span class="text-danger">*</span>
                          <input type="text" autocomplete="off" formControlName="pincode" id="pincode"
                              (keyup)="keyUp('pincode')" maxlength="6" 
                              onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                              [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }"
                              class="form-control form-control-sm"> <!-- [minlength]="isSAGE?1:6" -->
                          <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                              <div *ngIf="f.pincode.errors?.required">Pin Code is required</div>
                              <div *ngIf="f.pincode.errors.pattern">Enter Valid Pin Code</div>
                          </div>
                      </div>
                        <div class="col-xl-3">
                          <label>Latitude</label>
                          <input type="number" id="latitude"  onkeydown="return event.keyCode !== 69"  class="form-control form-control-sm" placeholder="latitude" formControlName="latitude" appTwoDigitDecimaNumber />
                      </div>
                      <div class="col-xl-3">
                        <label>Longitude</label>
                        <input type="number" id="longitude"   onkeydown="return event.keyCode !== 69"    class="form-control form-control-sm" placeholder="longitude" formControlName="longitude" appTwoDigitDecimaNumber />
                    </div>
                    <div class="col-xl-3">
                      <label>Address</label>
                      <textarea class="form-control form-control-sm" placeholder="Address" formControlName="address" id="" cols="3" rows="4"></textarea>
                      <!-- <input type="text" class="form-control form-control-sm" placeholder="Latitude" formControlName="latitude"  /> -->
                  </div>
                  <!-- <div class="col-xl-3">
                    <label>Pin Code</label>
                    <input type="number" maxlength="6" minlength="6" class="form-control form-control-sm" placeholder="Pin code" formControlName="pincode"  />
                </div> -->
                
                    
                        <div class="col-xl-3 mt-6" style="margin-top:32px;">
                          <label class="control-label"> Status <span class="text-danger">*</span></label>&nbsp;&nbsp;
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" 
                                    id="inlineRadio1" value="1" formControlname="is_active" [checked]="isAct=='1'" (click)="radioclick('true')" >
                                <label class="form-check-label" for="inlineRadio1">Active</label>
                            </div>&nbsp;&nbsp;
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="radio" name="inlineRadioOptions" 
                                    id="inlineRadio2" value="0" formControlname="is_active" [checked]="isAct=='0'" (click)="radioclick('false')" >
                                <label class="form-check-label" for="inlineRadio2">InActive</label>
                            </div>
                        </div>
                        <div class="col-xl-12 mt-3 text-center " >
                            <button type="submit" class="btn btn-primary mb-2 text-center" (click)="centerregistrationSubmit()" 
                             > Submit</button>
                        </div>
                    </div>
                    
                </div>
            </div>
        </form>
           
    </div>
  </div>
  
  
  <!-- <div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" value="Submit" (click)="onSubmit($event)">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  </div> -->
  