import { PermissionListComponent } from './../permission-list/permission-list.component';
import { RoleComponent } from './../role/role.component';
import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserService } from 'src/app/shared/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Common } from 'src/app/common';
import { BaseComponent } from 'src/app/shared/base.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

declare let $: any;
@Component({
  selector: 'app-role-list',
  templateUrl: './role-list.component.html',
  styleUrls: ['./role-list.component.css']
})
export class RoleListComponent extends BaseComponent implements OnInit {
  modalTitle: string = '';
  roleList: Array<any> = [];
  constructor(config: NgbModalConfig,
    private modalService: NgbModal,
    private userServices: UserService,
    private toastr: ToastrService,
    private authSvc: AuthenticationService) {
      super();
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false; this.authSvc.isShowGlblOrg.next(true)
  }

  ngOnInit(): void {
    // this.authSvc.isShowGlblOrg.next(false), this.authSvc.tenantId = sessionStorage.getItem('defCrntTnnt'),
    //   sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt'))
    this.LoadGridData();
  }

  openRoleModal(item: any = null) {
    const modalRef = this.modalService.open(RoleComponent);
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.roleData = item;
    modalRef.result.then(result => {
      if (result == 'changes saved')
        this.LoadGridData();
    }, (reason) => { });
  }

  openPermissionModal(item: any = null) {
    const modalRef = this.modalService.open(PermissionListComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.roleId = item.id;
    modalRef.result.then(result => {
      if (result == 'saved')
        this.LoadGridData();
    }, (reason) => { });
  }

  LoadGridData() {
    this.userServices.getAll('user/getall_roles').subscribe(res => {
      if (res.status === "success") {
        if (res.response == null || res.response.length == 0)
          this.toastr.info('No records available', 'Role List');
        else
          this.roleList = res.response;
        Common.loadDataTable(this.roleList, '#roleTable');
      }
    },
      err => this.toastr.error(err));
  }
}