import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agent-video',
  templateUrl: './agent-video.component.html',
  styleUrls: ['./agent-video.component.css']
})
export class AgentVideoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
