<!-- <form [formGroup]="drugForm" (ngSubmit)="onSubmit($event)"> -->
<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
   
    <form [formGroup]="labForm" (ngSubmit)="onSubmit($event)">

    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <div class="form-group">
                    
                        <label class="control-label">Lab Investigation Name <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="investigation" id="investigation"
                               
                            onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)' maxlength="50" placeholder="lab Investigation Name"
                                [ngClass]="{ 'is-invalid': submitted && f.investigation.errors }"
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.investigation.errors" class="invalid-feedback">
                                Lab Investigation Name is Required
                                </div>
                            </div>
                        </div>
                   
                
            </div>
            <!-- <div class="col-xl-2">
            </div>
            <div class="col-xl-5">
            </div> -->
        </div>
    </div>
    <input type="submit"  id="btnsubmit" style="display: none;">
</form>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" (click)="submitForm()" value="Submit">&nbsp;&nbsp;
    <!-- <input type="submit" class="btn btn-sm btn-primary" value="Submit" (click)="onSubmit()">&nbsp;&nbsp; -->
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
<!-- </form> -->