import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";

import { RegistrationService } from 'src/app/shared/services/registration.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})

export class SupportComponent extends BaseComponent implements OnInit {

  docId = ''; html = ``; template: string = ''; suprtMailId = ''; // environment.isNIG ? ' (support@eclinic234.com)' : '';
  config: any = {
    height: 250,
    branding: false,
    statusbar: false,
    theme: 'modern',
    // powerpaste advcode toc tinymcespellchecker a11ychecker mediaembed linkchecker help
    plugins: 'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image imagetools link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists textcolor wordcount contextmenu colorpicker textpattern',
    toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
    image_advtab: true,
    imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
    templates: [{ title: 'Test template 1', content: 'Test 1' }, { title: 'Test template 2', content: 'Test 2' }],
    content_css: ['//fonts.googleapis.com/css?family=Lato:300,300i,400,400i', '//www.tinymce.com/css/codepen.min.css'],
  };

  constructor(private toastr: ToastrService
    , private ngxSpnrSvc: NgxSpinnerService
    , private regService: RegistrationService) {
    super();
  }

  ngOnInit() {
    let doc = JSON.parse(sessionStorage.getItem('DoctorDetails')); this.docId = doc?.id || 0;
    this.suprtMailId = (environment.isEclnc ? ' (support@eclinic234.com)' : (environment.isSMR ? ' (samaritan@eclinic234.com)' : (environment.isKMP ? ' (kampehospital@eclinic234.com)' : '')));
  }

  sendEmail() {
    if (this.template.length == 0) {
      this.toastr.error('Please enter email content', 'Support Team');
      return;
    }
    else {
      const postData = { doctor_id: this.docId, template: this.template };
      /* // console.log('Support started'), this.ngxSpnrSvc.show(), setTimeout(() => { this.ngxSpnrSvc.hide(), console.log('Support ended'); }, 5000);
      this.ngxSpnrSvc.show(), console.log('interval started');
      let timeLeft = 6, timeIntrvl = setInterval(() => {
        if (timeLeft > 0)
          timeLeft--;
        else
          clearInterval(timeIntrvl), this.ngxSpnrSvc.hide(), console.log('interval ended');
      }, 1000); */
      this.regService.save('supportadmin/', postData).subscribe(res => {
        if (res.status === "OK" && res.message != "Failed")
          this.template = '', this.toastr.success('Mail is being sent to Support Team', 'Support Team');
        else
          this.toastr.error(res.message);
      }, error => this.toastr.error(error)); // this.toastr.success('The email content is being send to administrator', 'Support Team');
    }
  }

}