import { Component, OnInit } from '@angular/core';
import { Common } from 'src/app/common';
import { NgbActiveModal, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DrTemplateControlsComponent } from '../dr-template-controls/dr-template-controls.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { DrModuleControlsComponent } from '../dr-module-controls/dr-module-controls.component';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { DrTmpltCreateComponent } from '../dr-tmplt-create/dr-tmplt-create.component';
import { BaseComponent } from 'src/app/shared/base.component';
import * as XLSX from 'xlsx';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
declare let $:any;
@Component({
  selector: 'app-dr-template-list',
  templateUrl: './dr-template-list.component.html',
  styleUrls: ['./dr-template-list.component.css']
})

export class DrTemplateListComponent extends BaseComponent implements OnInit {

  modalTitle: string = 'Doctor Module';  doctorlist: [];
  fileName= 'Doctor_Module.xlsx';
  excelData: any =[];
  
  constructor(private modalService: NgbModal, private authSvc: AuthenticationService
    , private registrationsrv: RegistrationService,
    private excelDownloadService:ExcelDownloadService
    , private toastr: ToastrService) {
      super();
    }
  ngOnInit(): void {
    this.authSvc.isShowGlblOrg.next(false), this.authSvc.tenantId = sessionStorage.getItem('defCrntTnnt')
    if (this.authSvc.roleId == 1) {
      sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt'));
    }
       this.getAllDoctorModules();
  }

  openModal(editData: any = null) {
    const modalRef = this.modalService.open(DrTmpltCreateComponent, { scrollable: true, size: 'xl' }); // DrTemplateControlsComponent
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.editData = editData;
    modalRef.result.then(result => {
      if (result == 'changes saved')
        this.getAllDoctorModules();
      // here call Table load API
    }, (reason) => { });
  }

  getAllDoctorModules() {
    this.registrationsrv.getAllDctrMdule().subscribe(res => {
      if(res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info(res.message, 'Doctor List');
        else
          this.doctorlist = res.data;
          this.excelData = this.doctorlist;
        Common.loadDataTable(this.doctorlist, '#DoctortemplatelistTable');
      }
      else
        this.doctorlist = [], Common.loadDataTable(this.doctorlist, "#DoctortemplatelistTable");
    })
  }

  doctorlistDeleteById(id:any) {
    if (confirm(`Are you sure you want delete record?`)) {
      this.registrationsrv.deleteDctrModuleById(id).subscribe(res => {
        if (res.status == "OK") {
          this. getAllDoctorModules();
          this.toastr.success(res.message);
        }
      },
        error => { this.toastr.error('An Error Occured'); });
    }
  }
  exportexcel(): void 
  {
     
     var table = $('#DoctortemplatelistTable').DataTable();
 
    if ( ! table.data().count() ) {
    this.toastr.error( 'No data available in table! Please select and try again' );
   }
   else{
     / table id is passed over here /  
     let element = document.getElementById('DoctortemplatelistTable'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
     

     / generate workbook and add the worksheet /
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     ws['!cols'] = [];
     ws['!cols'][10] = { hidden: true };
     

     / save to file /
     XLSX.writeFile(wb, this.fileName);
     }
    }
    downLoadExcel(){
      const fileName = 'Doctor_modules'
        var excelData = this.excelData;
        if(excelData.length>0){
          const excelHeaders = ['Module Name'];
          const internalHeaders = ['module_name'];
          this.toastr.success('Please wait excel is downloading')
          this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
        }
        else{
          this.toastr.error('No data to download')
        }
      
    }
}