import { BaseComponent } from 'src/app/shared/base.component';
import { CompanyComponent } from './../company/company.component';
import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/shared/services/master.service';
import { Common } from 'src/app/common';
import { UserService } from 'src/app/shared/services/user.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import * as XLSX from 'xlsx';
declare let $: any;
@Component({
  selector: 'app-companies-list',
  templateUrl: './companies-list.component.html',
  styleUrls: ['./companies-list.component.css'],
  // add NgbModalConfig and NgbModal to the component providers
  providers: [NgbModalConfig, NgbModal]
})
export class CompaniesListComponent extends BaseComponent implements OnInit {

  companyList: any;
  corporateList: any;
  isShow = true
  OrgId: string;
  couponfileName='coupon company list.xlsx';
  corporatefileName='corporate company list.xlsx';
  constructor(config: NgbModalConfig, private modalService: NgbModal
    , private toastr: ToastrService, private masterService: MasterService, private userservice: UserService, private authSvc: AuthenticationService) {
    super();
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    authSvc.crntOrgIDSubject.subscribe(s => {
      this.OrgId = sessionStorage.getItem('currentTenant');
      if (location.href.includes('home/companyList'))
        this.ngOnInit();
    }), authSvc.isShowGlblOrg.next(true);
  }

  ngOnInit(): void {
    // this.getAllCompanies(), this.getAllCorporte();
    if (!this.isShow)
      this.getcorporatebyorgid(this.OrgId);
    else
      this.getcompanyByorgid(this.OrgId);
  }

  openCompanyModal(DataEdit: any = null) {
    const modalRef = this.modalService.open(CompanyComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = this.isShow ? 'Coupon Company' : 'Corporate Company';
    modalRef.componentInstance.DataEdit = DataEdit;
    modalRef.componentInstance.isCorp = this.isShow;
    modalRef.result.then(result => {
      if (result == 'changes saved') {
        if (this.isShow)
          this.getcompanyByorgid(this.OrgId);
        else
          this.getcorporatebyorgid(this.OrgId);
      }
    }, (reason) => { });
  }

  // getAllCompanies() {
  //   // const drugnames = this.masterService.getAll('');
  //   this.masterService.getAll('mastercompany/getall').subscribe(res => {
  //     if (res.status === "OK") {
  //       if (res.data == null || res.data.length == 0)
  //         this.toastr.info('No records available', 'Company List');
  //       else
  //         this.companyList = res.data;
  //       Common.loadDataTable(this.companyList, '#companyTable');
  //     }
  //   });
  // }

  deleteItem(id: any, type: string) {
    if (confirm(`Are you sure you want delete record?`)) {
      if (type == 'mc') {
        this.userservice.deleteByCompany(id).subscribe(res => {
          if (res.status == "OK")
            this.getcompanyByorgid(this.OrgId), this.toastr.success('Record Deleted Successfully');
        }, error => { this.toastr.error('An Error Occured'); });
      }
      else {
        this.userservice.deleteByCorporate(id).subscribe(res => {
          if (res.status == "OK")
            this.getcorporatebyorgid(this.OrgId), this.toastr.success('Record Deleted Successfully');
        }, error => { this.toastr.error('An Error Occured'); });
      }
    }
  }

  // getAllCorporte() {
  //   // const drugnames = this.masterService.getAll('');
  //   this.userservice.getAll('corporate/getallcorporates').subscribe(res => {
  //     if (res.status === "OK") {
  //       if (res.data == null || res.data.length == 0)
  //         this.toastr.info('No records available', 'Company List');
  //       else
  //         this.corporateList = res.data;
  //       Common.loadDataTable(this.corporateList, '#corporateTable');
  //     }
  //   });
  // }

  getcorporatebyorgid(orgId: any) {
    $('.dataTable').DataTable().destroy(), $('.dataTable').DataTable({ destroy: true, searching: false });
    if (orgId != '') {
      this.corporateList = [], this.userservice.getOrgById(orgId).subscribe(res => {
        if (res) {
          if (res.status == "OK") {
            if (res.data == null || res.data.length == 0)
              this.toastr.info('No records available', 'Corporate Company List');
            else
              this.corporateList = res.data;
            Common.loadDataTable(this.corporateList, "#corporateTable");
          }
        }
      })
    }
    else
      this.corporateList = [], Common.loadDataTable(this.corporateList, "#corporateTable");
  }

  getcompanyByorgid(orgId: any) {
    $('.dataTable').DataTable().destroy(), $('.dataTable').DataTable({ destroy: true, searching: false });
    if (orgId != '') {
      this.companyList = [], this.userservice.getOrgCompyById(orgId).subscribe(res => {
        if (res) {
          if (res.status == "OK") {
            if (res.data == null || res.data.length == 0)
              this.toastr.info('No records available', 'Coupon Company List');
            else
              this.companyList = res.data;
            Common.loadDataTable(this.companyList, "#companyTable");
          }
        }
      })
    }
    else
      this.companyList = [], Common.loadDataTable(this.companyList, "#companyTable");
  }

  exportexcel(): void {

    var table = $('#companyTable').DataTable();

    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {
      / table id is passed over here /
      let element = document.getElementById('companyTable');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };

      / save to file /
      XLSX.writeFile(wb, this.couponfileName);
    }
}
exporttoexcel(): void {

  var table = $('#corporateTable').DataTable();

  if (!table.data().count()) {
    this.toastr.error('No data available in table! Please select and try again');
  }
  else {
    / table id is passed over here /
    let element = document.getElementById('corporateTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    / generate workbook and add the worksheet /
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    ws['!cols'] = [];
    ws['!cols'][10] = { hidden: true };

    / save to file /
    XLSX.writeFile(wb, this.corporatefileName);
  }
}


}