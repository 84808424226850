import { DrugComponent } from './../drug/drug.component';
import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterService } from 'src/app/shared/services/master.service';
import { Common } from 'src/app/common';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/shared/base.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

declare let $: any;
@Component({
  selector: 'app-drugs-list',
  templateUrl: './drugs-list.component.html',
  styleUrls: ['./drugs-list.component.css']
})
export class DrugsListComponent extends BaseComponent implements OnInit {
  modalTitle: string = '';
  druglist: any;
  constructor(config: NgbModalConfig, private modalService: NgbModal,
    private masterService: MasterService, private toastr: ToastrService, private authSvc: AuthenticationService) {
    super();
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.authSvc.isShowGlblOrg.next(false), this.authSvc.tenantId = sessionStorage.getItem('defCrntTnnt'),
      sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt'));
    this.getAllDrugs();
  }

  openDrugModal(DataEdit: any = null) {
    const modalRef = this.modalService.open(DrugComponent, { size: 'lg' });
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.DataEdit = DataEdit;
    modalRef.result.then(result => {
      if (result == 'changes saved')
        this.getAllDrugs();
    }, (reason) => { });
  }

  getAllDrugs() {
    this.masterService.getAll('getalldrugtypes').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Drugs List');
        else
          this.druglist = res.data;
        Common.loadDataTable(this.druglist, '#drugTable');
      }

    });
  }

  deleteItem(id: any) {
    if (confirm(`Are you sure you want delete record?`)) {
    this.masterService.getAll(`deletedrugtype?drugTypeId=${id}`).subscribe(res => {
      if (res.status == "OK") {
        this.getAllDrugs();
        this.toastr.success(res.message, 'Drug Type List');
      }
    },
      error => { this.toastr.error('An Error Occured'); });
  }
}
}