<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                       Hub Registration
                        <button 
                        (click)="modalTitle='Patient Registration Details'; openPatientModal()"
                            class="btn btn-blue btn-icon float-right"><i class="fa fa-plus"></i> <!-- openModal() -->
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-xl-12">
                                    <form [formGroup]="cntrLstForm">
                                        <div class="row mt-1">
                                            <!-- <div class="col-xl-2">
                                                <label>State <span class="text-danger"><b>*</b></span> </label>
                                                <select class="form-control form-control-sm" id="state" formControlName="state_code"
                                                   >
                                                    <option value=0>Select</option>
                                                </select>
                                            </div> -->
                                            <div class="col-md">
                                                <div class="form-group">
                                                  <label class="control-label">{{isNIG ? 'States' : 'State'}} </label>
                                                  <div>
                                                    <select formControlName="state_id" id="state_id" #State (change)="locationChanged(State.value,'district')"
                                                      [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }" class="form-control form-control-sm" disabled="true">
                                                      <option value=0>Select</option>
                                                      <option *ngFor="let state of stateList" [value]="state.id">
                                                        {{state.state_name}}</option>
                                                    </select>
                                                    <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                                      <div *ngIf="f.state_id.errors.required">
                                                        {{isNIG ? 'States' : 'State'}} is required
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            <!-- <div class="col-xl-2">
                                                <label>District <span class="text-danger"><b>*</b></span> </label>
                                                <select class="form-control form-control-sm" id="dist" formControlName="district_code"
                                                   >
                                                    <option value=0>Select</option>
                                                </select>
                                            </div> -->
                                            <div class="col-md">
                                                <div class="form-group">
                                                  <label class="control-label">{{isNIG ? 'State Capitals' : 'District'}} 
                                                  </label>
                                                  <div>
                                                    <select formControlName="district_id" id="district_id" #District
                                                      [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }" class="form-control form-control-sm">
                                                      <option value=0>Select</option>
                                                      <option *ngFor="let district of districtList" [value]="district.id">
                                                        {{district.district_name}}</option>
                                                    </select>
                                                    <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                                                      <div *ngIf="f.district_id.errors.required">
                                                        {{isNIG ? 'State Capitals' : 'District'}} is required
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                           
                                            <!-- <div class="col-xl-2">
                                                <div class="form-group">
                                                  <label class="control-label">Location Type <span class="text-danger">*</span> </label>
                                                  <div>
                                                    <select formControlName="location_type_id" id="location_type_id" #locationId
                                                      (change)="locationChanged('','location')"
                                                      [ngClass]="{ 'is-invalid': submitted && f.location_type_id.errors }"
                                                      class="form-control form-control-sm">
                                                      <option value="">Select</option>
                                                      <option *ngFor="let location of locationTypeList" [value]="location.id">
                                                        {{location.location_type}}</option>
                                                    </select>
                                                    <div *ngIf="submitted && f.location_type_id.errors" class="invalid-feedback">
                                                      <div *ngIf="f.location_type_id.errors.required">Location Type is required</div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div> -->
                                            <!-- <div class="col-xl-2">
                                                <label>Block / City</label> 
                                                <select class="form-control form-control-sm" id="blck" formControlName="block_code"
                                                 >
                                                    <option value=0>Select</option>
                                                   
                                                </select> 
                                            </div> -->
                                            <!-- <div class="col-xl-2">
                                              
                                                <div class="form-group">
                                                  <label class="control-label">{{isNIG ? 'Local Government Areas' : 'Mandal'}} </label>
                                                  <div>
                                                    <select formControlName="mandal_id" id="mandal_id" #Mandal
                                                      (change)="locationChanged(Mandal.value,'village')"
                                                      [ngClass]="{ 'is-invalid': submitted && f.mandal_id.errors }" class="form-control form-control-sm">
                                                      <option value="">Select</option>
                                                      <option *ngFor="let mandal of mandalList" [value]="mandal.id">
                                                        {{mandal.mandal_name}}</option>
                                                    </select>
                                                    <div *ngIf="submitted && f.mandal_id.errors" class="invalid-feedback">
                                                      <div *ngIf="f.mandal_id.errors.required">
                                                        {{isNIG ? 'Local Government Areas ' : 'Mandal '}} is required
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div> -->
                                            <div class="col-md-3" style="padding-top: 30px;">
                                                <button type="submit" class="btn btn-primary btn-sm mb-4 text-center" (click)="onSearch()" >Search</button>
                                                <button type="submit" class="btn btn-warning btn-sm mb-4 ml-2 text-center"(click)="onReset()">Cancel</button>
                                                <button type="button" class="btn btn-secondary btn-sm mb-4 ml-2" *ngIf ="excelData.length>0" (click)="downLoadExcel()" >
                                                  <i class="fa fa-file-excel-o"></i>Export</button> 
                                            </div>
                                            <!-- <div class="col-xl text-right" style="padding-top: 2%;">
                                                   <button type="button" class="btn btn-secondary btn-sm px-4 float-right mb-1 text-center" *ngIf ="excelData.length>0" (click)="downLoadExcel()" >
                                                  <i class="fa fa-file-excel-o"></i>&nbsp;Export</button>                                 
                                            </div> -->
                                        </div>  
                                    </form>
                                </div> 
                            </div>
                        </div> 
                        <div class="table-responsive">
                            <table datatable id="centerregTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr style="background-color: lightskyblue">
                                        <th class="text-center" style="font-size:15px;">S.No</th>
                                        <!-- <th style="font-size:15px;">Center Code</th> -->
                                        <th style="font-size:15px;">Hub Name</th>
                                        <th style="font-size:15px;">State</th>
                                        <th style="font-size:15px;">District</th>
                                        <th style="font-size:15px;">Block</th>
                                        <th style="font-size:15px;">Village</th>
                                        <th style="font-size:15px;">Address</th>
                                        <th style="font-size:15px;">Pin Code</th>
                                        <th style="font-size:15px;">Action</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of getAllcenters; let i = index">
                                        <td>{{i+1}}</td>
                                        <td>{{item.hub_name}}</td>
                                        <td>{{item.stateName}}</td>
                                        <td>{{item.districtName}}</td>
                                        <td>{{item.mandalName}}</td>
                                        <td>{{item.villageName}}</td>
                                        <td>{{item.address}}</td>
                                        <td>{{item.pincode}}</td>

                                        <td class="text-center">
                                            <span>
                                                <a class="pointer" style="cursor: pointer;" (click)="onAddEdit(item)"
                                                    title="Edit"> <i class="fa fa-edit text-primary"></i></a>
                                            </span>&nbsp;&nbsp;
                                        
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>