
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal, NgbDateStruct, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { MasterService } from 'src/app/shared/services/master.service';
import { DatePipe } from '@angular/common';
import { ReportService } from 'src/app/shared/services/report.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import * as XLSX from 'xlsx';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
import { Common } from 'src/app/common';
import { DoctorRegistrationComponent } from '../../doctor-registration/doctor-registration.component';
import { DoctorSlotModalComponent } from '../../doctor-slot-modal/doctor-slot-modal.component';
declare let $: any;
@Component({
  selector: 'app-doctor-list-new',
  templateUrl: './doctor-list-new.component.html',
  styleUrls: ['./doctor-list-new.component.css'],
  providers: [DatePipe]
})
export class DoctorListNewComponent extends BaseComponent implements OnInit {
  modalTitle: string = '';
  doctorList: Array<any> = [];
  qualificationsList: Array<any> = [];
  superSpecialityDegreeList: Array<any> = [];
  dropdownSettings = {};
  selectedItems_Monday = [];
  selectedItems_Tuesday = [];
  selectedItems_Wednesday = [];
  selectedItems_Thursday = [];
  selectedItems_Friday = [];
  selectedItems_Saturday = [];
  selectedItems_Sundday = [];
  datesSelected:NgbDateStruct[]=[]; 
  dateSlots:any=[]
  timeslotsList: any;
  week_startDate: Date = null;
  week_endDate: Date = null;
  week_dates: Array<Date> = [];
  doctor_name: string = '';
  doctor_id: number;

  editSlots: any;
  previous_data: any;
  minDate: any;
  docId: any;
  roleId = 0;
  start_minDate: Date;
  isNext_week: boolean = false;
  limitResp: any;
  orgsList = [];
  crntTentId = +sessionStorage.getItem('currentTenant');
  slotList: any[];
  model: any;
  fileName = 'Doctor list.xlsx';
  excelData: any=[];
  hoveredDate: NgbDateStruct;

  fromDate: NgbDateStruct;
  toDate: NgbDateStruct;

  _datesSelected:NgbDateStruct[]=[]; 
  
  
  constructor(config: NgbModalConfig
    , private toastr: ToastrService
    , private datePipe: DatePipe
    , private regService: RegistrationService
    , private reportService: ReportService
    , private modalService: NgbModal
    , private masterService: MasterService
    , private authSvc: AuthenticationService
    ,public activeModal: NgbActiveModal,
    public changeDetection: ChangeDetectorRef,
    private excelDownloadService:ExcelDownloadService
    ,private excelService:ExcelService) {
    super();
    config.backdrop = 'static';
    config.keyboard = false;
    this.minDate = new Date().setHours(0, 0, 0, 0), this.getMinDate();
    authSvc.crntOrgIDSubject.subscribe(s => { this.doctorList = [], Common.loadDataTable(this.doctorList, '#doctorTable');
      if (location.href.includes('home/doctorList'))
        this.ngOnInit();
    }), authSvc.isShowGlblOrg.next(true);
  }

  ngOnInit(): void {
    this.defaultLocations(), this.getAllMasters(), this.docId = JSON.parse(sessionStorage.getItem('DoctorDetails'))?.id,
      this.roleId = +sessionStorage.getItem('logUsrRoleId'); //, this.authSvc.cnfgrdOrgSubject.subscribe(s => this.orgsList = s);
  }

  defaultLocations() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'time_slot',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 10,
      allowSearchFilter: true
    };
  }

  openDoctorModal(doctor: any = null) {
    
    // this.changeDetection.detach();
    const modalRef = this.modalService.open(DoctorSlotModalComponent, { scrollable: true, size: 'md' });
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.item = doctor;
    modalRef.result.then(result => {
      this.changeDetection.reattach();
      if (result == 'changes saved')
        this.LoadGrid();
    }, (reason) => { });
  }
  openDoctorEditModal(doctor: any = null) {
    this.changeDetection.detach();
    const modalRef = this.modalService.open(DoctorRegistrationComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.doctor = doctor;
    modalRef.result.then(result => {
      this.changeDetection.reattach();
      if (result == 'changes saved')
        this.LoadGrid();
    }, (reason) => { });
  }

  onDocEdit(docId: any = null) {
    if (docId != null)
      this.regService.getByDoctorId(docId).subscribe(res => { this.openDoctorEditModal(res.data) });
  }

  getDoctorbyID() {
    this.regService.getBydoctorslots(this.docId).subscribe(res => {
      if (res.message === 'Success') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Doctor List');
        else {
          let data = res.data;
          data.qualificationName = this.qualificationsList.filter(f => f.id == data.qualification_id)[0]?.qualification,
            data.superDegree = this.superSpecialityDegreeList.filter(m => m.id == data.super_qualification_id)[0]?.qualification;
          if (this.doctorList.filter(f => f.id == data.id).length == 0)
            this.doctorList.push(data);
        }
        Common.loadDataTable(this.doctorList, '#doctorTable');
      }
    });
  }

  ChkLimit(): boolean {
    let result: boolean;
    this.regService.chkLimit('doctor').subscribe(res => {
      if (res) {
        if (res.status == "OK")
          result = true, this.openDoctorEditModal();
        else
          result = false, this.toastr.warning(res.message);
      }
    });
    return result;
  }
  closeModal() {
    const emptyDate=[]
    this.change(emptyDate)
    
  

    // alert('step1');
    // this.datesSelected=[];
    // this.change(this.datesSelected);
    // this.activeModal.close();
    // console.log('date selected',this.datesSelected);
    setTimeout(() => {
      this.LoadGrid();
    }, 3000);
   
    // console.log('date selected',this.datesSelected);
    
  }
  openDoctorSlots(item: any = null) {
    let ar = []; ar.push(item);
    const modalRef = this.modalService.open(DoctorSlotModalComponent, { scrollable: true, size: 'sl' });
    modalRef.componentInstance.item = item;
    modalRef.result.then(result => {
      // here call Table load API
      // alert('Success1');
    }, (reason) => { });
  }
 
  LoadGrid() {
    // $('.dataTable').DataTable().destroy(), $('.dataTable').DataTable({ destroy: true, searching: false });
    // this.doctorList = [], Common.loadDataTable(this.doctorList, '#doctorTable');
    if (this.roleId == 7)
      this.getDoctorbyID();
    else
      this.getAllDoctors();
  }

  getAllDoctors() {
    this.regService.getByAllDoctorsNew().subscribe(res => {
      if (res) {
        if (res.message === 'All doctor records') { // Success
          if (res.data == null || res.data.length == 0)
            this.toastr.info('No records available', 'Doctor List');
          else {
            let data = res.data.filter(f => f.account_activated == true && f.is_active == true);
            data.forEach(e => {
              e.qualificationName = this.qualificationsList.filter(f => f.id == e.qualification_id)[0]?.qualification;
              e.superDegree = this.superSpecialityDegreeList.filter(m => m.id == e.super_qualification_id)[0]?.qualification;
            });
            this.doctorList = data;
            this.excelData = this.doctorList;
          }
          Common.loadDataTable(this.doctorList, '#doctorTable');
        }
      }
    });
  }

  onDelete(id: any) {
    if (confirm(`Are you sure you want delete record?`)) {
      this.regService.deleteByDoctorId(id).subscribe(res => {
        if (res.status == "OK")
          this.toastr.success('Record Deleted Successfully'), this.LoadGrid();
        else
          this.toastr.error(res.message);
      }, error => { this.toastr.error('An Error Occured'); });
    }
  }

  getAllMasters() {
    const qualifications = this.masterService.getAll('getallqualifications');
    const superSpecialityDegree = this.masterService.getAll('getallsuperspecialityqualifications');
    // const timeslots = this.masterService.getAll('getalltimeslots');
    //new API added for time slots 15-06-2022 #chandu
    const timeslots = this.masterService.getAll('getalltimeslotsforhalfhourly');
    const weekDays = this.masterService.getAll('getallweekdays');

    forkJoin([qualifications, superSpecialityDegree, timeslots]).subscribe(
      {
        next: res => {
          res.forEach((list, ind) => {
            if (list.status === 'OK') {
              if (list.data == null || list.data.length == 0)
                this.toastr.info('No records available', 'Doctor List');
              else {
                const assignList = {
                  '0': () => this.qualificationsList = list.data,
                  '1': () => this.superSpecialityDegreeList = list.data,
                  '2': () => this.timeslotsList = list.data,
                }
                assignList[ind]();
              }
            }
          });
        },
        error: err => console.error('something wrong occurred: ', err),
        complete: () => {
          this.LoadGrid();
        }
      });

  }

  dateChange(val: any) {
    if (val) {
      this.week_dates = [];
      let [toDate, newDate] = [new Date(this.datePipe.transform(val, 'yyyy-MM-dd')), new Date(this.datePipe.transform(val, 'yyyy-MM-dd'))];
      [0, 1, 2, 3, 4, 5, 6].forEach(n => this.week_dates.push(new Date(newDate.setDate(newDate.getDate() + (n > 0 ? 1 : 0)))));
      this.week_endDate = new Date(toDate.setDate(toDate.getDate() + 6));
      this.getSlotsData();
    }
  }

  modal(doctor_id: number, doctor_name: string) {
    this.doctor_id = doctor_id, this.doctor_name = doctor_name, this.clearSlots(), this.week_dates = [], this.week_startDate = null, this.week_endDate = null;
  }

  
  submit() {
    let postData: TimeSlot = new TimeSlot();
    postData = {
      id: this.editSlots.length > 0 ? this.editSlots[0].id : 0,
      doctorId: this.doctor_id,
      isActive: true,
      fromDate: this.datePipe.transform(this.week_startDate, 'yyyy-MM-dd'),
      toDate: this.datePipe.transform(this.week_endDate, 'yyyy-MM-dd'),
      availableSlot: this.getAvailablesSlots()
    };
    this.regService.saveTimeSlots(postData).subscribe(
      {
        next: res => {
          if (res.status === 'success') {
            if (res.response == null)
              this.toastr.error('No records saved', 'Doctor Time Slot');
            else
              this.toastr.success('Record saved successfully', 'Doctor Time Slot');
          }
          document.getElementById('btnClose').click();
        },
        error: err => console.error('something wrong occurred: ', err),
        complete: () => { }
      }
    );
  }

  getAvailablesSlots(): IAvailableSlot[] {
    let sendData: IAvailableSlot[] = [];
    // const weekDayList = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    // [this.selectedItems_Monday, this.selectedItems_Tuesday, this.selectedItems_Wednesday, this.selectedItems_Thursday, this.selectedItems_Friday, this.selectedItems_Saturday, this.selectedItems_Sundday]
    
    this.datesSelected.forEach((day, ind) => {
        let monthData:string = day.month+''
        let dayData:string = day.day+''
        dayData=dayData.length==1?'0'+day.day.toString():day.day+''
        monthData=monthData.length==1?'0'+day.month.toString():day.month+'';
        sendData.push(
          {
            id: ind+1,
            weekday: '',
            is_active: true,
            date: day.year+'-'+monthData+'-'+dayData,
            slot: this.getSlot()
          }
        );
      });
    return sendData;
  }

  getSlot(){
    return [
      {"id":81,"time_slot":"08:00-08:15","is_active":true},
      {"id":82,"time_slot":"08:15-08:30","is_active":true},
      {"id":83,"time_slot":"08:30-08:45","is_active":true},
      {"id":84,"time_slot":"08:45-09:00","is_active":true},
      {"id":85,"time_slot":"09:00-09:15","is_active":true},
      {"id":86,"time_slot":"09:15-09:30","is_active":true},
      {"id":87,"time_slot":"09:30-09:45","is_active":true},
      {"id":88,"time_slot":"09:45-10:00","is_active":true},
      {"id":89,"time_slot":"10:00-10:15","is_active":true},
      {"id":90,"time_slot":"10:15-10:30","is_active":true},
      {"id":91,"time_slot":"10:30-10:45","is_active":true},
      {"id":92,"time_slot":"10:45-11:00","is_active":true},
      {"id":93,"time_slot":"11:00-11:15","is_active":true},
      {"id":94,"time_slot":"11:15-11:30","is_active":true},
      {"id":95,"time_slot":"11:30-11:45","is_active":true},
      {"id":96,"time_slot":"11:45-12:00","is_active":true},
      {"id":97,"time_slot":"12:00-12:15","is_active":true},
      {"id":98,"time_slot":"12:15-12:30","is_active":true},
      {"id":99,"time_slot":"12:30-12:45","is_active":true},
      {"id":100,"time_slot":"12:45-13:00","is_active":true},
      {"id":101,"time_slot":"13:00-13:15","is_active":true},
      {"id":102,"time_slot":"13:15-13:30","is_active":true},
      {"id":103,"time_slot":"13:30-13:45","is_active":true},
      {"id":104,"time_slot":"13:45-14:00","is_active":true},
      {"id":105,"time_slot":"14:00-14:15","is_active":true},
      {"id":106,"time_slot":"14:15-14:30","is_active":true},
      {"id":107,"time_slot":"14:30-14:45","is_active":true},
      {"id":108,"time_slot":"14:45-15:00","is_active":true},
      {"id":109,"time_slot":"15:00-15:15","is_active":true},
      {"id":110,"time_slot":"15:15-15:30","is_active":true},
      {"id":111,"time_slot":"15:30-15:45","is_active":true},
      {"id":112,"time_slot":"15:45-16:00","is_active":true},
      {"id":113,"time_slot":"16:00-16:15","is_active":true},
      {"id":114,"time_slot":"16:15-16:30","is_active":true},
      {"id":115,"time_slot":"16:30-16:45","is_active":true},
      {"id":116,"time_slot":"16:45-17:00","is_active":true}
    ];
  }
  getList(arr: any[]) {
    let arr1: ISlot[] = [];
    arr.forEach(e => arr1.push({ id: e.id, time_slot: e.time_slot, is_active: true }));
    return arr1;
  }

  getSlotsData() {
    let [startday, endDay] = [this.datePipe.transform(this.week_startDate, 'yyyy-MM-dd'), this.datePipe.transform(this.week_endDate, 'yyyy-MM-dd')]
    this.regService.getTimeSlotsByDate(this.doctor_id, startday, endDay).subscribe(res => {
      this.editSlots = [], this.editSlots = res.response;
      if (this.editSlots.length > 0) {
        this.selectedItems_Monday = this.editSlots[0].availableSlot.find(f => f.id == 1).slot;
        this.selectedItems_Tuesday = this.editSlots[0].availableSlot.find(f => f.id == 2).slot;
        this.selectedItems_Wednesday = this.editSlots[0].availableSlot.find(f => f.id == 3).slot;
        this.selectedItems_Thursday = this.editSlots[0].availableSlot.find(f => f.id == 4).slot;
        this.selectedItems_Friday = this.editSlots[0].availableSlot.find(f => f.id == 5).slot;
        this.selectedItems_Saturday = this.editSlots[0].availableSlot.find(f => f.id == 6).slot;
        this.selectedItems_Sundday = this.editSlots[0].availableSlot.find(f => f.id == 7).slot;
      }
      else
        this.clearSlots();
    },
      error => console.log(error));
  }

  getPrevious_dataSlotsData(from_date: Date, to_date: Date) {
    let [startday, endDay] = [this.datePipe.transform(from_date, 'yyyy-MM-dd'), this.datePipe.transform(to_date, 'yyyy-MM-dd')]
    this.regService.getTimeSlotsByDate(this.doctor_id, startday, endDay).subscribe(res => {
      this.previous_data = [], this.previous_data = res.response;
      if (this.previous_data.length > 0) {
        // [this.selectedItems_Monday, this.selectedItems_Tuesday, this.selectedItems_Wednesday, this.selectedItems_Thursday, this.selectedItems_Friday, this.selectedItems_Saturday, this.selectedItems_Sundday]
        //   .forEach((e, index) => {
        //     const ind = index + 1;
        //     if (!this.checkDates(this.week_dates[ind]) && e.length == 0) {
        //       e = this.previous_data[0].availableSlot.find(f => f.id == ind).slot;
        //       // console.log(e);              
        //     }
        //   });
        this.setWeekData(this.selectedItems_Monday, 1);
        this.setWeekData(this.selectedItems_Tuesday, 2);
        this.setWeekData(this.selectedItems_Wednesday, 3);
        this.setWeekData(this.selectedItems_Thursday, 4);
        this.setWeekData(this.selectedItems_Friday, 5);
        this.setWeekData(this.selectedItems_Saturday, 6);
        this.setWeekData(this.selectedItems_Sundday, 7);
      }
      else
        this.toastr.info('No Available Slots for last week', 'Doctor Available Slots');
    },
      error => console.log(error));
  }

  setWeekData(e: any[], ind: number) {
    if (!this.checkDates(this.week_dates[ind]) && e.length == 0) {
      let arr = this.previous_data[0].availableSlot.find(f => f.id == ind).slot;
      if (ind == 1)
        this.selectedItems_Monday = arr;
      else if (ind == 2)
        this.selectedItems_Tuesday = arr;
      else if (ind == 3)
        this.selectedItems_Wednesday = arr;
      else if (ind == 4)
        this.selectedItems_Thursday = arr;
      else if (ind == 5)
        this.selectedItems_Friday = arr;
      else if (ind == 6)
        this.selectedItems_Saturday = arr;
      else if (ind == 7)
        this.selectedItems_Sundday = arr;
    }
  }

  clearSlots() {
    this.editSlots = [];
    this.selectedItems_Monday = [];
    this.selectedItems_Tuesday = [];
    this.selectedItems_Wednesday = [];
    this.selectedItems_Thursday = [];
    this.selectedItems_Friday = [];
    this.selectedItems_Saturday = [];
    this.selectedItems_Sundday = [];
  }

  checkDates(val: Date) {
    return val < this.minDate;
  }

  getMinDate() {
    const day = (new Date()).getDay(), today = new Date();
    // this.start_minDate = new Date(today.setDate(today.getDate() + (8 - day)));
    this.start_minDate = new Date(today.setDate(today.getDate() - day));
  }

  assignPreviousSlots(status: boolean) {
    if (status) {
      // let [from_date, to_date] = [this.getNewDate(), this.getNewDate('To Date')];
      let [from_date, to_date] = [this.getNewDate(this.week_startDate), this.getNewDate(this.week_endDate)];
      // this.week_startDate = to_date;
      this.isNext_week = true, this.getPrevious_dataSlotsData(from_date, to_date);
    }
    else
      this.clearSlots();
  }

  getNewDate1(type: string = 'From Date') {
    let from_date = new Date(), day = (from_date.getDate() - (from_date.getDay() - 1));
    return new Date(from_date.setDate((type == 'From Date') ? (day - 7) : day));
  }

  getNewDate(newDate: Date) {
    let day = (newDate.getDate() - 7), new_date = new Date(JSON.parse(JSON.stringify(newDate)));
    return new Date(new_date.setDate(day));
  }
  getdatesincal(doctorid:any){
  // alert(doctorid)
  let month =  new Date().getMonth()+1;
  let year = new Date().getFullYear();
 this.regService.getdoctoravailableslotsinamonth(doctorid,month,year).subscribe(res=>{
  //this.slotList=res.data;
  this.slotList=[{year:2022,month:3,day:25},{year:2022,month:3,day:27},{year:2022,month:3,day:29}]
 this.model = {year:2022,month:3,day:29}
   this.datesSelected = this.slotList
});
 
 }
 
 change(value:NgbDateStruct[])
 {
   this.datesSelected=value;
  //  console.log('Date selected',this.datesSelected);
 }

  


   downLoadExcel(){
    const fileName = 'Doctor_reg'
      var excelData = this.excelData;
      if(excelData.length>0){
        excelData.map(res =>{
          res.fullName = (res.first_name +' '+ res.last_name)
        })
      const excelHeaders = ['District','Block','Hospital','Category Type','Hub/Centre/Referral Name','Doctor Name', 'Qualification','Specialization','Department', 'Mobile', 'Email', 'MCI Number', 'Experience'];
      const internalHeaders = ['district_name','mandal_name','hospital_name','center_type','center_name','fullName', 'qualificationName', 'specialization','department_name','mobile', 'email', 'mci_number', 'years_experience'];
      this.toastr.success('Please wait excel is downloading')
      this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
      }
     else{
      this.toastr.error('No data to download')
     }
  }
}

export class TimeSlot {
  id: number = 0;
  doctorId: number = 0;
  isActive: boolean = true;
  fromDate: string = '';
  toDate: string = '';
  availableSlot: IAvailableSlot[];
}

export interface IAvailableSlot {
  id: number;
  weekday: string;
  is_active: true;
  date: string;
  slot: ISlot[];
}

export interface ISlot {
  id: number,
  time_slot: string,
  is_active: boolean
}