<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        DSS Report
                        <!-- <div  class='col-md-2'>
                            <button data-toggle="modal"
                            (click)="modalTitle='Patient Registration Details'; openPatientModal()"
                                   class="btn btn-blue btn-icon float-right"> <i class="fa fa-plus"></i> 
                               </button>
                        </div> -->
                    </div>
                    <div class="card-body">
                        <div class="col-md-9 mb-3" style="margin-top: 2rem;" >
                            <button type="button" class="btn  btn-sm btn-secondary float-right" *ngIf="excelData && excelData.length>0" (click)="downLoadExcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                        </div>
                        <div class="table-responsive">
                            <table datatable id="dssr" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr style="background-color: lightskyblue">
                                        <th class="text-center" style="font-size:15px;">S.No</th>
                                        <!-- <th style="font-size:15px;">Arogya Kendra Code</th> -->
                                        <th style="font-size:15px;">Patient Name</th>
                                        <th style="font-size:15px;">Mobile Number</th>
                                        <th style="font-size:15px;">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                   
                                    <tr *ngFor="let item of dssreportdata; let i = index">
                                        <td>{{i+1}}</td>
                                        <td>{{item.name}}</td>
                                        <td>{{item.mobile}}</td>
                                     
                                        <td><a  class="badge badge-primary badge-pill" data-toggle="modal" data-target="#openReport" style="cursor: pointer;" (click)="viewdata(item)"><i class="fa fa-eye text-white"></i></a></td>
                                        
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="modal fade" data-backdrop="static" data-keyboard="false" id="openReport" tabindex="-1" role="dialog"
  aria-labelledby="openReportTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg " role="document">
    <div class="modal-content panelbg">
      <div class="modal-body">
          <h5 style="text-align: center;"><b>Patient Name: </b>{{patientname}}</h5>
        <div class="btn btn-danger" data-dismiss="modal" style="margin-left: 680px;">X</div>
        <div class="row">
          <div class="container">
              <b>Flow Type: </b>{{flowtype}}
              <div *ngIf="childdatashow">
              <div *ngIf="flowtype=='Child Flow-1'">
                 <div> <b>BreastFeedingValue :</b>{{singleValuedata?.breastFeedingValue}}</div>
                 <div><b>FromDiarrhoea :</b>{{singleValuedata?.fromDiarrhoea}}</div>
                 <div><b>FromJaundice :</b>{{singleValuedata?.fromJaundice}}</div>
                <div><b>VaccinationCompleted :</b>{{singleValuedata?.isVaccinationCompleted}}</div>
                <div><b>JaundiceAge :</b>{{singleValuedata?.jaundiceAge}}</div>
                <div><b>RepositoryRate :</b>{{singleValuedata?.repositoryRate}}</div>
                <div><b>TempType :</b>{{singleValuedata?.tempType}}</div>
                <div><b>Temparature :</b>{{singleValuedata?.temparature}}</div>
                <div><b>VaccinationStatus :</b>{{singleValuedata?.vaccinationStatus}}</div>
                <div><b>YellowPalmAndSole :</b>{{singleValuedata?.yellowPalmAndSole}}</div>
              </div>
              <div *ngIf="flowtype=='Child Flow-2'">
                  <div *ngIf="singleValuedata.ageinweeks!=null"><b>Weeks :</b>{{singleValuedata.ageinweeks}}</div>
                  <div *ngIf="singleValuedata.breath!=null"><b>Breath: </b>{{singleValuedata.breath}}</div>
                  <div *ngIf="singleValuedata.chestInDrawing!=null"><b>ChestInDrawing :</b>{{singleValuedata.chestInDrawing}}</div>
                  <div *ngIf="singleValuedata.coughDuration!=null"><b>CoughDuration :</b>{{singleValuedata.coughDuration}}</div>
                  <div *ngIf="singleValuedata.fromCough!=null"><b>fromCough: </b>{{singleValuedata.fromCough}}</div>
                  <div *ngIf="singleValuedata.fromDangerDiarrhea!=null"><b>FromDangerDiarrhea :</b>{{singleValuedata.fromDangerDiarrhea}}</div>
                <div *ngIf="singleValuedata.fromPneumonia!=null"><b>FromPneumonia :</b>{{singleValuedata.fromPneumonia}}</div>
                <div *ngIf="singleValuedata.oedema!=null"><b>oedema :</b>{{singleValuedata.oedema}}</div>
                <div *ngIf="singleValuedata.oxygenSaturation!=null"><b>oxygenSaturation :</b>{{singleValuedata.oxygenSaturation}}</div>
                <div *ngIf="singleValuedata.fromNone!=null"><b>FromNone :</b>{{singleValuedata.fromNone}}</div>
                <div *ngIf="singleValuedata.height!=null"><b>Height :</b>{{singleValuedata.height}}</div>
                <div *ngIf="singleValuedata.muacReading!=null"><b>MuacReading :</b>{{singleValuedata.muacReading}}</div>
                <div *ngIf="singleValuedata.palmer!=null"><b>Palmer :</b>{{singleValuedata.palmer}}</div>
                <div *ngIf="singleValuedata.vaccinationStatus!=null"><b>Vaccination Status :</b>{{singleValuedata.vaccinationStatus}}</div>
              </div>
              <!-- {{totaldssreport | json}} -->
              <!-- <b>Weeks: </b> {{singleValuedata.ageinweeks}} -->
            <div *ngFor="let item of totaldssreport; let i = index">
              
                <div *ngFor="let itemn of item; let i = index">
                    <!-- {{itemn }} -->
                    <!-- <b>Weeks: </b> {{itemn.ageinweeks}} -->
              <b>condition:</b> {{itemn.condition}}
               <div *ngFor="let itemn1 of itemn.advises; let i = index">
<b *ngIf="itemn1.advise !=null">Advise:</b>{{itemn1.advise}}
<b *ngIf="itemn1.adviseSpan !=null">AdviseSpan:</b>{{itemn1.adviseSpan}}
<b *ngIf="itemn1.days !=null">Days:</b>{{itemn1.days}}
<span *ngIf="itemn1.moreInfo !=null"><b>MoreInfo:</b><span>{{itemn1.moreInfo}}</span></span>
               </div>
                </div>
                <!-- <b>Advice: </b><p>{{item.advise}}</p> <br> <b *ngIf="item.adviseSpan !=null" >AdviseSpan: </b><p>{{item.adviseSpan}}</p>
                <b *ngIf="item.isDynamicDose !=null">DynamicDose: </b> <p>{{item.isDynamicDose}}</p> <br><b *ngIf="item.moreInfo !=null">MoreInfo</b> <p>{{item.moreInfo}}</p>
                 <b *ngIf="item.unit !=null">Unit: </b><p>{{item.unit}}</p> -->
            </div>
            <b *ngIf="totaldssreport2 !=''">Child Asses For Data:</b>
            <div *ngFor="let item of totaldssreport2">
                <!-- isChecked:<b>{{item.isChecked}}</b>   -->
                <!-- sno:<b>{{item.sno}}</b> -->
                <b>symptom:</b> {{item.symptom}} 
                <!-- value:<b>{{item.value}}</b> -->
            </div><br>
            <b *ngIf="totaldssreport3 !=''">DiarrhoeaResponse:</b>
            <div *ngFor="let item of totaldssreport3">
                <!-- isChecked:<b>{{item.isChecked}}</b>   -->
                <!-- sno:<b>{{item.sno}}</b> -->
                symptom:<b>{{item.symptom}}</b>  
                <!-- value:<b>{{item.value}}</b> -->
            </div>
            <b *ngIf="totaldssreport4 !=''">FeedingAssesData:</b>
            <div *ngFor="let item of totaldssreport4">
                <!-- isChecked:<b>{{item.isChecked}}</b>  -->
                 <!-- sno:<b>{{item.sno}}</b> -->
                symptom:<b>{{item.symptom}}</b>  
                <!-- value:<b>{{item.value}}</b> -->
            </div>
            <b *ngIf="totaldssreport5 !=''">Referral:</b>
            <div *ngIf="totaldssreport5 !=''">
                <b> ReferralValue:</b> {{totaldssreport5?.referralValue}}  <b>SelectedDate:</b> {{totaldssreport5?.selectedDate}}
                <b>SubReferralValue:</b>{{totaldssreport5?.subReferralValue}} 
            </div>
            <b *ngIf="totaldssreport11 !=''">Vaccine:</b>
            <div *ngFor="let item of totaldssreport11">
                <b *ngIf="item.selected==true">Title :</b> <span *ngIf="item.selected==true">{{item.title}}</span> 
                <b *ngIf="item.selected==true">Vaccine :</b> <span *ngIf="item.selected==true">{{item.vaccine}}</span> 
            </div>
          </div>
        </div>
        <div  *ngIf="motherdatashow">
          <div><b>Mother Data</b>
            <div *ngFor="let item of totaldssreport6">
            <b>Advise:</b>{{item.advise}}
            <b>More Info:</b>{{item.moreInfo}}
            <b>AdviseSpan:</b>{{item.adviseSpan}}
            </div>
        </div>
        <div><b>Ask For Data</b>
            <div *ngFor="let item of totaldssreport7">
            <!-- <b>char_limit:</b>{{item.char_limit}}
            <b>code:</b>{{item.code}} -->
            <!-- <b>field_type:</b>{{item.field_type}} -->
            <b>question_en:</b>{{item.question_en}}
            <b>question_hi:</b>{{item.question_hi}}
            <b>symptoms:</b>{{item.symptoms}}
            </div>
        </div>
        <div><b>Examinations</b>
            <div *ngFor="let item of totaldssreport8">
            <!-- <b>char_limit:</b>{{item.char_limit}}
            <b>code:</b>{{item.code}} -->
            <!-- <b>field_type:</b>{{item.field_type}} -->
            <b>examination_en:</b>{{item.examination_en}}
            <b>examination_hi:</b>{{item.examination_hi}}
            <b>symptoms:</b>{{item.symptoms}}
            </div>
        </div>
        <div><b>PastHistoryList</b>
            <div *ngFor="let item of totaldssreport9">
            <!-- <b>char_limit:</b>{{item.char_limit}}
            <b>code:</b>{{item.code}} -->
            <!-- <b>field_type:</b>{{item.field_type}} -->
            <b>history_en:</b>{{item.history_en}}
            <b>history_hi:</b>{{item.history_hi}}
            <b>symptoms:</b>{{item.symptoms}}
            </div>
        </div>
        <div><b>selectedSymptoms</b>
            <div *ngFor="let item of totaldssreport10">
            <!-- <b>char_limit:</b>{{item.char_limit}}
            <b>code:</b>{{item.code}} -->
            <!-- <b>field_type:</b>{{item.field_type}} -->
            <b>code:</b>{{item.code}}
            <b>symptom_en:</b>{{item.symptom_en}}
            <b>symptom_hi:</b>{{item.symptom_hi}}
            <!-- <b>symptoms:</b>{{item.symptoms}} -->
            </div>
        </div>
    </div>
          <div class="col-md-1 col-6 pr-0"></div>
        
        </div>
      </div>
      <div class="modal-footer">
      
        <button type="button"  class="btn btn-danger" data-dismiss="modal">
          Close<br /></button>
       
      </div>
    </div>
  </div>
</div>
</div>