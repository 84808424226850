

    <div class="container-fluid pl-0 pr-0">
      <div class="row">
        <div class="col-xl-12">
          <div class="card card-header-actions mx-auto">
            <div class="card-header">
            Hand Over form Report
            </div>
  
            <div class="card-body">
              <form [formGroup]="handOverForm" (ngSubmit)="LoadData()">
                <div class="row ">
                  <div class="container">
                    <div class="row ">
                      <div class='col-md'>
                        <div class="form-group">
                          <div class='date' id='datetimepicker6'>
                            <label class="control-label">From Date </label>
                            <div>
                              <input id="start_date" name="start_date" type="text" autocomplete="off" onpaste="return false"
                                placeholder="From" bsDatepicker #dp="bsDatepicker" (focus)="dp.show()" (focus)="dp1.hide()"
                                formControlName="start_date" [maxDate]="max_date"
                                (bsValueChange)="onFromDateChange($event)" onkeypress='return event.charCode == 8'
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                                [ngClass]="{ 'is-invalid': submitted && f.start_date.errors }"
                                class="form-control form-control-sm">
                              <div *ngIf="submitted && f.start_date.errors" class="invalid-feedback">
                                <div *ngIf="f.start_date.errors.required">From Date is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='col-md'>
                        <div class="form-group">
                          <div class='date' id='datetimepicker7'>
                            <label class="control-label">To Date</label>
                            <div>
                              <input id="end_date" name="end_date" type="text" autocomplete="off" onpaste="return false"
                                placeholder="To" bsDatepicker formControlName="end_date"  [minDate]="f.start_date.value" [maxDate]="max_date" (focus)="dp.hide()"
                                bsDatepicker #dp1="bsDatepicker" (focus)="dp1.show()" (bsValueChange)="onToDateChange($event)"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                                [ngClass]="{ 'is-invalid': submitted && f.end_date.errors }"
                                class="form-control form-control-sm" onkeypress='return event.charCode == 8'>
                              <div *ngIf="submitted && f.end_date.errors" class="invalid-feedback">
                                <div *ngIf="f.end_date.errors.required">To Date is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md">
                        <div class="form-group">
                            <!-- <span class="text-danger">*</span>  -->
                            <label class="control-label">{{isNIG ? 'States' : 'State'}}  </label>
                            <div>
                                <select formControlName="state_id" id="state_id" #State
                                    (change)="locationChanged(State.value,'district')"
                                    [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }"
                                    class="form-control form-control-sm"
                                    disabled>
                                    <option value="">Select</option>
                                    <option *ngFor="let state of stateList" [value]="state.id">
                                        {{state.state_name}}</option>
                                </select>
                                <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                    <div *ngIf="f.state_id.errors.required">
                                        {{isNIG ? 'States' : 'State'}} is required
                                    </div>
                                </div>
                            </div>
                        </div>

                         <!-- (change)="onUserChange($event)" -->
                                <!-- [ngClass]="{ 'is-invalid': submitted && f.user_type.errors }" -->
                        <!-- <div class="form-group">
                            <label class="control-label">State <span class="text-danger">*</span> </label>
                            <div>
                               
                                <select class="form-control form-control-sm" formControlName="user_type">
                                     
                                    <option value="">Select</option>
                                     <option *ngFor="let user of stateList " [value]="user.id">{{user.state_name}}</option>
    
                                </select>
                                
                            </div>
                        </div> -->
                    </div>
                    <div class="col-md">
                      <!-- <div class="form-group">
                          <label class="control-label">Distict </label>
                          <div>
                             <select class="form-control form-control-sm" formControlName="district_id"
                                  >
                                  <option value="null" selected>Select</option>
                                 
                                  
                                  <option *ngFor="let item of districtList" [value]="item.id">{{item.district_name}}</option>
                              </select>
                      
                          </div>
                      </div> -->

                      <!-- <div class="form-group">
                        <label class="control-label">{{isNIG ? 'State Capitals' : 'District'}} <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="district_id" id="district_id" #District
                                (change)="locationChanged(District.value, (+locationId.value==2)?'city':'mandal')"
                                [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }"
                                class="form-control form-control-sm">
                                <option value="null">Select</option>
                                <option *ngFor="let district of districtList" [value]="district.id">
                                    {{district.district_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                                <div *ngIf="f.district_id.errors.required">
                                    {{isNIG ? 'State Capitals' : 'District'}} is required
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <div class="form-group">
                        <!-- <span class="text-danger">*</span> -->
                        <label class="control-label">{{isNIG ? 'State Capitals' : 'District'}} </label>
                        <div>
                            <select formControlName="district_id" id="district_id" #District #locationId
                                (change)="locationChanged(District.value, (locationId.value==2) ? 'city':'mandal')"
                                [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }"
                                class="form-control form-control-sm">
                                <option value="null">Select</option>
                                <option *ngFor="let district of districtList" [value]="district.id">
                                    {{district.district_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                                <div *ngIf="f.district_id.errors.required">
                                    {{isNIG ? 'State Capitals' : 'District'}} is required
                                </div>
                            </div>
                        </div>
                    </div>
                  </div>
                  <div class="col-md">
                    <!-- <div class="form-group">
                        <label class="control-label">Agent </label>
                        <div>
                           <select class="form-control form-control-sm" formControlName="agent_id"
                                >
                                <option value="null" selected>Select</option>
                                <option *ngFor="let item of userNames" [value]="item.user_id">{{item.first_name}} {{item.last_name}}</option>
                            </select>
                    
                        </div>
                    </div> -->
                    <div class="form-group">
                        <!-- <span class="text-danger">*</span>  -->
                        <label class="control-label">Pharmacist/Healthcare Professional Details</label>
                        <div>
                          <select formControlName="agent_id" id="agent_id"  (change)="agentchange($event.target.value)"
                            [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }" class="form-control form-control-sm">
                            <option value="null">Select</option>
                            <option *ngFor="let agent of getagents" [value]="agent.id">
                              {{agent.first_name | uppercase}} {{agent.last_name | uppercase}}</option>
                          </select>
                       
                        </div>
                      </div>
                </div>
                      <div class='col-md-3' style="margin-top: 2rem;"  >
                        <button type="submit" class="btn btn-sm btn-primary" value="Submit" (focus)="dp1.hide()">Submit</button>&nbsp;
                         <button type="button" class="btn btn-sm btn-danger" (click)="clear()">Clear</button> &nbsp;
                         <button type="button" class="btn  btn-sm btn-secondary  text-center" *ngIf="excelData && excelData.length>0" (click)="downLoadExcel()" > <i class="fa fa-file-excel-o"></i>Export</button>
                      </div>
                      
                    </div>
                  </div>
                </div>
              </form>
  
              <div class="table-responsive">
                <table datatable id="handoverReport" class="table table-striped table-bordered table-sm" style="width:100%">
                  <thead class="text-center">
                    <tr>
                      <th class="singleLine">S No </th>
                      <th class="singleLine">Date</th>
                      <th class="singleLine">District Name</th>
                      <th class="singleLine">Block</th>
                      <th class="singleLine">Center Type</th>
                      <th class="singleLine">Center Name</th>
                      <th class="singleLine">Pharmacist/Healthcare Professional</th>
                      <th class="singleLine">Pharmacist/Healthcare Professional Number</th>
                      <th class="singleLine">Handover File</th>
                      <th class="singleLine">Uploaded File</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of handOverlist;let i= index;">
                      <td class="singleLine text-center">{{i+1}}</td>
                      <td class="singleLine text-uppercase">{{item.generated_date| date:'dd-MM-yyyy'}}</td>
                      <td class="singleLine text-center">{{item.district_name}}</td>
                      <td class="singleLine">{{item?.mandal_name}}</td>
                      <td class="singleLine text-center">{{item.center_type}}</td>
                      <td class="singleLine  text-center">{{item.center_name}}</td>
                      <td class="singleLine text-center">{{item.agent_name}}</td>
                      <td class="singleLine text-center">{{item.agnet_number}}</td>
                     
                      <td style="width: 15%;">
                        <span *ngIf="item.generated_file?.length>0">
                            <!-- <a click="fileView(item.generated_file)">View</a> -->
                            <a target="_blank" href={{item.generated_file}}>View </a>
                        </span>
                        <span *ngIf="item.generated_file?.length==0">
                            No File 
                        </span>
                      </td>
                      <td>
                        <ng-container *ngFor="let file of item.uploaded_file_with_sign;let i =index">
                          <span *ngIf="file">
                             <a target="_blank" href={{file}}>View {{i+1}} &nbsp;</a>
                          </span>
                        
                        <span *ngIf="!file">
                           No File 
                        </span>
                        </ng-container>
                     </td>
                     
                    </tr>
                  </tbody>
                </table>
              </div>
  
            </div>
          </div>
        </div>
      </div>
  
    </div>


