import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/shared/services/master.service';
import { Common } from 'src/app/common';

@Component({
  selector: 'app-drug-type-mapping',
  templateUrl: './drug-type-mapping.component.html',
  styleUrls: ['./drug-type-mapping.component.css']
})
export class DrugTypeMappingComponent implements OnInit {
  @Input() title: string;
  @Input() DataEdit: any;

  drugTypeMappingForm: FormGroup;
  submitted = false;

  drugTypeList: Array<any> = [];
  dropdownSettings_drugType = {};

  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private formBuilder: FormBuilder
    , private masterService: MasterService) { }

  ngOnInit(): void {
    this.dropdownSettings_drugType = {
      singleSelection: false,
      idField: 'id',
      textField: 'drug_type',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.initFormControls();
    this.getAllDrugs();
  }

  editDrugTypeMapping() {
    let arr = [];
    this.DataEdit.drug_types.forEach(drugType => {
      const dayname = this.drugTypeList.find(item => item.id == drugType.drug_type_id && drugType.is_active);
      if (dayname) {
        const item = { id: dayname.id, drug_type: dayname.drug_type };
        arr.push(item);
      }
    });
    this.drugTypeMappingForm.patchValue({
      id: this.DataEdit.id,
      drug_name: this.DataEdit.drug_name,
      drug_types: arr,
      is_active: this.DataEdit.is_active
    });

  }

  initFormControls() {
    this.drugTypeMappingForm = this.formBuilder.group({
      id: [0],
      drug_name: ['', Validators.required],
      drug_types: ['', Validators.required],
      is_active: [true]
    });
    Common.setFocus('drug_name');
  }

  getAllDrugs() {
    this.masterService.getAll('getalldrugtypes').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Drug Type List');
        else
          this.drugTypeList = res.data;
      }

    }, err => { }, () => {
      if (this.DataEdit)
        this.editDrugTypeMapping();
    });
  }

  get f() { return this.drugTypeMappingForm.controls; }

  onSubmit(event: any) {
    this.submitted = true;
    const sendData = JSON.parse(JSON.stringify(this.drugTypeMappingForm.getRawValue()));
    if (this.drugTypeMappingForm.invalid)
      return;

    if (sendData.drug_types?.length > 0)
      sendData.drug_types = sendData.drug_types.map(item => ({ drug_type_id: item.id, drug_type_name: item.drug_type, is_active: true }));

    if (this.DataEdit) {
      let data = sendData.drug_types;

      this.DataEdit.drug_types?.forEach(e => {
        if (e.is_active) {
          let item = data.find(f => f.drug_type_id == e.drug_type_id);
          if (item == null)
            data.push({ drug_type_id: e.drug_type_id, drug_type_name: e.drug_type_name, is_active: false });
          else
            item.drug_type_id = e.drug_type_id;
        }
      });
    }

    sendData.drug_name = sendData.drug_name.toUpperCase();
    delete sendData.is_active;
    this.masterService.save('drugtypemapping/saveandupdate', sendData).subscribe(res => {
      let apiResponse = res;
      if (apiResponse.status == "OK") {
        this.toastr.success(apiResponse.message, 'Drug Type Details');
        this.activeModal.close('changes saved');
      }
    })
  }
}