import { Component, OnInit } from '@angular/core';
import { Common } from 'src/app/common';
import { NgbActiveModal, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CorporateplanscontrolsComponent } from '../corporateplanscontrols/corporateplanscontrols.component';
import { UserService } from 'src/app/shared/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/shared/base.component';
import * as XLSX from 'xlsx';
declare let $: any;

@Component({
  selector: 'app-corporateplanslist',
  templateUrl: './corporateplanslist.component.html',
  styleUrls: ['./corporateplanslist.component.css']
})
export class CorporateplanslistComponent extends BaseComponent implements OnInit {
  modalTitle: string = '';
  fileName = 'Corporate plan list.xlsx';
  plansdata: []
  constructor(private modalService: NgbModal, private userservice: UserService, private toastr: ToastrService) {
    super();
  }
  ngOnInit(): void {
    this.loadTableData();
    this.getAllplans()
  }
  cmm = []
  loadTableData() {
    this.cmm = [];
    Common.loadDataTable(this.cmm, '#corporateTable');
  }
  openModal(item: any = null) {
    const modalRef = this.modalService.open(CorporateplanscontrolsComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.editData = item;
    modalRef.result.then(result => {
      if (result == 'changes saved')
        this.getAllplans();
      // here call Table load API
    }, (reason) => { });
  }
  getAllplans() {
    this.userservice.getAll('mastersubscriptionplan/getall').subscribe(res => {
      if (res.status === "OK") {
        if (res.data == null || res.data.length == 0)
          this.plansdata = [], this.toastr.info('No records available', 'Company List');
        else
          this.plansdata = res.data;
        Common.loadDataTable(this.plansdata, '#corporateTable');
      }
      else
        this.plansdata = [], Common.loadDataTable(this.plansdata, "#corporateTable");
    });
  }

  exportexcel(): void {

    var table = $('#corporateTable').DataTable();

    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {
      / table id is passed over here /
      let element = document.getElementById('corporateTable');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };

      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }

  }

}
