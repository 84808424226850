import { Common } from 'src/app/common';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { RegistrationService } from 'src/app/shared/services/registration.service';

@Component({
  selector: 'app-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css'],
  providers: [DatePipe]
})

export class UserDetailsComponent implements OnInit {

  @Input() title: string;
  @Input() userData: any;

  userForm: FormGroup;
  submitted = false;
  showcorporatename = false;
  isDisabled: boolean = true;
  roleList: Array<any> = [];
  sourceTypeList: Array<any> = [];
  organizationList: Array<any> = [];
  branchList: Array<any> = [];
  approved_by: number = 0;
  approved_on: string = '';
  isAltMobNoInvalid = false;
  companyList;
  // mobPtrn = location.href.includes('sage') ? environment.mobPtrnSAGE : environment.mobPtrnIND;
  // mobErrMsg = location.href.includes('sage') ? environment.mobErrMsgSAGE : environment.mobErrMsgIND;
  // maxLngth = location.href.includes('sage') ? environment.maxLngthSAGE : environment.maxLngthIND;
  mobPtrn = ''; mobErrMsg = ''; maxLngth = '';
  
  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private formBuilder: FormBuilder
    , private userService: UserService
    , private datePipe: DatePipe
    , private authServ: AuthenticationService
    , private masterService: MasterService
    , private registrationService: RegistrationService) { }

  ngOnInit(): void {
    this.authServ.mobPtrnSubject.subscribe(s => this.mobPtrn = s),
      this.authServ.mobErrMsgSubject.subscribe(s => this.mobErrMsg = s),
      this.authServ.maxLngthSubject.subscribe(s => this.maxLngth = s);
    let res = this.authServ.currentUserValue;
    // console.log(res);
    if (res) {
      //orgnization_branch
      this.approved_by = res.id;
      this.approved_on = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
      this.branchList.push(res.orgnization_branch);
      // this.organizationList.push(res.orgnization_registration);
    }
    this.initFormControls(), Common.setFocus('first_name'), this.getAllMasters(), this.getAllCorporates();
  }

  initFormControls() {
    this.userForm = this.formBuilder.group({

      id: [0],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.email],

      mobile_no: ['', Validators.required],
      alternate_mobile_no: ['', [Validators.pattern(this.mobPtrn)]],
      registration_source_id: ['', Validators.required],

      login_name: [{ value: '', disabled: true }, Validators.required],
      password: ['', Validators.required],
      organization_id: ['', Validators.required],
      branch_id: [0],
      user_types: [[]],      
      user_type_id: ['', Validators.required],
      corporate_id: [0],
      otp: [''],
      approved_by: [this.approved_by],
      approved_on: [this.approved_on],
      account_activated: [true],
      is_active: [true],
    });
  }

  get f() { return this.userForm.controls; }

  onAltMob(val: any) {
    let altMobNo = this.userForm.get('alternate_mobile_no').value;
    this.isAltMobNoInvalid = altMobNo != '' ? (altMobNo.length < this.maxLngth) : false;
  }

  onKeyNmbr(type: string, val: string) {
    if (val != '') {
      if (+this.maxLngth == 9) {
        if (+val[0] == environment.mobValdValSAGE)
          this.userForm.get(type).setValue('');
      }
      if (+this.maxLngth == 10) {
        if (+val[0] < environment.mobValdValIND)
          this.userForm.get(type).setValue('');
      }
    }
  }
//new method added for active and in active 25-02-2023
ativeUser(isActive:any){
  this.userForm.patchValue({ is_active: isActive })
}
  onSubmit(event: any) {
    // let altMobNo = this.userForm.get('alternate_mobile_no').value;
    this.submitted = true, this.userForm.patchValue({ login_name: this.userForm.value.email });
    event.preventDefault(), Common.getFormValidationErrors( this.userForm);
    // this.isAltMobNoInvalid = altMobNo != '' ? (altMobNo.length < this.maxLngth) : false;
    //this.getFormValidationErrors();
    if (this.userForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields', 'User Details')
      return;
    }
    else {
      const postData = JSON.parse(JSON.stringify(this.userForm.getRawValue()));
      postData.user_types[0] = { user_type_id: postData.user_type_id };
      postData.approved_on = this.datePipe.transform(postData.approved_on, 'yyyy-MM-dd HH:mm:ss');
      postData.corporate_id = +postData.corporate_id;
      delete postData.user_type_id;
      this.userService.save('user/register', postData).subscribe(res => {
        if(res.data==null){
          this.toastr.error(res.message);
        }else{
          this.toastr.success('Record submited Successfully.', 'User'); 
        }
       
        this.activeModal.close('changes saved');
      }, error => { this.toastr.error(error.message) });
    }
  }

  getAllMasters() {
    const sourceType = this.masterService.getAll('getallsourcetype');
    const roles = this.userService.getAll('user/getall_roles');
    const organizations = this.userService.getAll('organization/getorganizationrecords');

    forkJoin([sourceType, roles, organizations]).subscribe({
        next: res => {
          res.forEach((list, ind) => {
            if (list.status === 'success' || list.status === 'OK') {
              const resp = list?.response ?? list?.data ?? [];
              if (resp == null || resp.length == 0)
                this.toastr.info('No records available', 'User Details');
              else {
                const assignList = {
                  '0': () => this.sourceTypeList = resp,
                  '1': () => this.roleList = resp.filter(f => f.userType != 'Super Admin'),
                  '2': () => this.organizationList = list.data,
                }
                assignList[ind]();
              }
            }
          });
        },
        error: err => console.error('something wrong occurred: ', err),
        complete: () => {
          this.roleList=this.roleList.sort((a,b)=>a.userType.toLowerCase()>b.userType.toLowerCase()?1:-1); 
        this.editUser() }
    });
  }

  submitForm() {
    document.getElementById('btnsubmit').click();
  }

  editUser() {
    if (this.userData) {
      this.userForm.get('user_type_id').disable(), this.userForm.get('mobile_no').disable(), this.userForm.patchValue(this.userData),
      this.userForm.patchValue({ organization_id: this.userData.orgnization_registration.id,
        password:this.userData.password_org,
        branch_id: this.userData.orgnization_branch?.id ?? 0, 
        registration_source_id: this.userData.master_registration_source.id,
        user_type_id: this.userData.user_role_mapping ? this.userData.user_role_mapping[0]?.user_type_id.id ?? '' : '' });
      if (this.userData?.user_role_mapping.filter(f => f.is_active == true)[0]?.user_type_id?.id > 0)
        this.selectedrole(this.userData?.user_role_mapping.filter(f => f.is_active == true)[0]?.user_type_id?.id);
    }
    else
      this.userForm.get('mobile_no').enable();
  }

  getAllCorporates() {
    this.userService.getAll('corporate/getallcorporates').subscribe(res => { this.companyList = res.data; });
  }

  selectedrole(sltedrole: string) {
    this.showcorporatename = sltedrole != '' ? this.roleList.filter(f => f.id == sltedrole)[0]?.userType == 'Corporate User' : false,
      this.userForm.get('corporate_id').setValue('');
    if (this.userData)
      this.userForm.get('corporate_id').setValue(this.userData.corporate_id);
    if (this.showcorporatename)
      this.userForm.get('corporate_id').setValidators([Validators.required]);
    else
      this.userForm.get('corporate_id').setValidators(null);
    this.userForm.get('corporate_id').updateValueAndValidity();
  }

}