import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Common } from 'src/app/common';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms'
import { MasterService } from 'src/app/shared/services/master.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { RouterModule, Routes, ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { DrugsToAgentmappingComponent } from '../drugs-to-agentmapping/drugs-to-agentmapping.component';
import { BaseComponent } from 'src/app/shared/base.component';
import { ThrowStmt } from '@angular/compiler';
import * as XLSX from 'xlsx';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
declare let $: any;


@Component({
  selector: 'app-drugs-to-agentmapping-list',
  templateUrl: './drugs-to-agentmapping-list.component.html',
  styleUrls: ['./drugs-to-agentmapping-list.component.css']
})
export class DrugsToAgentmappingListComponent extends BaseComponent implements OnInit {
  from_date = ''; to_date = ''
  start_date_App: any = new Date(); end_date_App: any = new Date(); tdDt: Date; minToDt: Date; public drugAgentForm: FormGroup;
  modalTitle: string = ''; DrugAgents: Array<any> = []; lstAgents: Array<any> = []; lstAgents2: Array<any> = []; agents = '';agentslist = '';
  params: any; stateList: Array<any> = []; slctCmpny = ''; slctState = ''; drugsByAgents: Array<any> = []; drugsByAgents2: Array<any> = [];   isShow = true;
  fileName = 'drugs to Pharmacist/Healthcare Professional shipment list.xlsx';
  stockfileName='drugs to Pharmacist/Healthcare Professional stock list.xlsx';
  max_date = new Date();
  pipe = new DatePipe('en-US');
  excelData: any =[];
  excelDataAvail: any[];
  constructor(private modalService: NgbModal, private route: ActivatedRoute,
    private router: Router
    , private MasterService: MasterService
    , private registration: RegistrationService
    , private toastr: ToastrService,
    private excelDownloadService:ExcelDownloadService
    , private dtPipe: DatePipe,
    public changeDetection: ChangeDetectorRef
    ,private formBuilder: FormBuilder) {
    super();
    // this.tdDt = new Date(), this.minToDt = new Date(), this.tdDt.setDate(this.tdDt.getDate()), this.minToDt.setDate(this.minToDt.getDate());
    // setTimeout(() => { this.drugAgentForm.get('toDate').setValue(this.tdDt); }, 100);
  }

  ngOnInit(): void {
    this.getmasters(); this.LoadGrid(); this.LoadGrid2();
  }
  LoadGrid() {
    Common.loadDataTable(this.drugsByAgents, '#DrugAgentTable');
  }
  LoadGrid2() {
    Common.loadDataTable(this.drugsByAgents2, '#DrugAgentTablee');
  }
  openDrugAgentModal() {
    this.changeDetection.detach();
    const modalRef = this.modalService.open(DrugsToAgentmappingComponent, { scrollable: true, size: 'xl' });
    this.changeDetection.reattach();
    modalRef.componentInstance.title = this.modalTitle;
    this.changeDetection.reattach();
    
  }
  // initGridForm() {
  //   this.drugAgentForm = this.formBuilder.group({
  //     fromDate: ['', Validators.required],
  //     toDate: ['', Validators.required],
  //     agent: ['', Validators.required],
  //     states: ['',Validators.required]
      
  //   });
  // }

  getmasters() {
    this.MasterService.getAll('getallstates').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available');
        else
      
          this.stateList = res.data;
          this.slctCmpny = this.stateList[0].id
          this.getAllstates(this.slctCmpny)
      }
    });
  }

  Submitform() {
    try {
      this.drugsByAgents=[];
      $('.dataTable').DataTable().destroy(),
      $('.dataTable').DataTable({ destroy: true, searching: false });
      
    } catch (error) {
      
    }
  
    let data = {
      "agentId": this.agents,
      "receviedDate": this.dtPipe.transform(this.from_date, 'yyyy-MM-dd'),
      "recevieDate": this.dtPipe.transform(this.to_date, 'yyyy-MM-dd')
    }
    if(this.agents == '' || data.recevieDate == null || data.receviedDate == null){
      this.toastr.error('Please Select All Mandatory Fields');
      return;
    }
    if(this.to_date < this.from_date){
      this.toastr.error('ToDate Should be Greater Than FromDate');
      return;
    }
    this.drugsByAgents = []; this.registration.getDataByAgent(data).subscribe(res => {
      if (res) {
        if (res.status == "OK") {
          if (res.data == null || res.data.length == 0)
            this.toastr.info('No records available');
          else
            this.drugsByAgents = res.data
            this.excelData = this.drugsByAgents
        }
        else
          this.drugsByAgents = []; Common.loadDataTable(this.drugsByAgents, '#DrugAgentTable');
      }
      else
        this.drugsByAgents = []; Common.loadDataTable(this.drugsByAgents, '#DrugAgentTable');
    })
  }
 
  getAllstates(stateId: string) {
    if (stateId != '') {
      this.lstAgents = []; this.drugsByAgents = []; this.start_date_App = ''; this.registration.getAgentByStateId(+stateId).subscribe(res => {
        // getAll('agent/getagentrecords')
        if (res) {
          if (res.status == "OK") {
            if (res.data == null || res.data.length == 0)
              this.toastr.info('No records available');
            else
              this.lstAgents = res.data
          }
        }
        else
          this.lstAgents = []; this.drugsByAgents = []; Common.loadDataTable(this.drugsByAgents, '#DrugAgentTable');
      });
    }
    else
          this.lstAgents = []; this.drugsByAgents = []; Common.loadDataTable(this.drugsByAgents, '#DrugAgentTable');
  }
  
  getDrugByAgent(Id:string){
    $('.dataTable').DataTable().destroy(), $('.dataTable').DataTable({ destroy: true, searching: false });
    this.registration.getAgentByStateId2(+Id).subscribe(res => {
      if (res) {
        if (res.status == "OK") {
          if (res.data == null || res.data.length == 0)
            this.toastr.info('No records available');
          else
            this.drugsByAgents2 = res.data;
            this.excelDataAvail = this.drugsByAgents2;
        }
        else
          this.drugsByAgents2 = []; Common.loadDataTable(this.drugsByAgents2, '#DrugAgentTablee');
      }
      else
        this.drugsByAgents2 = []; Common.loadDataTable(this.drugsByAgents2, '#DrugAgentTablee');
   

    });
   
  }
  getAllstates2(stateId: string) {
    if (stateId != '') {
      this.lstAgents2 = []; this.drugsByAgents2 = [];  this.registration.getAgentByStateId(+stateId).subscribe(res => {
        // getAll('agent/getagentrecords')
        if (res) {
          if (res.status == "OK") {
            if (res.data == null || res.data.length == 0)
              this.toastr.info('No records available');
            else
              this.lstAgents2 = res.data
          }
        }
        else
          this.lstAgents2 = []; this.drugsByAgents2 = []; Common.loadDataTable(this.drugsByAgents2, '#DrugAgentTablee');
      });
    }
    else
          this.lstAgents2 = []; this.drugsByAgents2 = []; Common.loadDataTable(this.drugsByAgents2, '#DrugAgentTablee');
  }
  exportexcel(): void {

    var table = $('#DrugAgentTable').DataTable();

    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {
      / table id is passed over here /
      let element = document.getElementById('DrugAgentTable');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };

      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
}
exporttoexcel(): void {

  var table = $('#DrugAgentTablee').DataTable();

  if (!table.data().count()) {
    this.toastr.error('No data available in table! Please select and try again');
  }
  else {
    / table id is passed over here /
    let element = document.getElementById('DrugAgentTablee');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    / generate workbook and add the worksheet /
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    ws['!cols'] = [];
    ws['!cols'][10] = { hidden: true };

    / save to file /
    XLSX.writeFile(wb, this.stockfileName);
  }
}


downLoadExcel(type){
  if(type=='shipment'){
    const fileName = 'Health_care_worker_shippment_stock'
    var excelData = this.excelData;
    if(excelData.length>0){
      excelData.map(res =>{
        res.formated_dispatched_date= this.pipe.transform(res.created_on, 'dd-MM-yyyy');
        res.formated_expiry_date= this.pipe.transform(res.expiry_date, 'dd-MM-yyyy');
        res.formated_manufactured_date= this.pipe.transform(res.manufactured_date, 'dd-MM-yyyy');
      })
      const excelHeaders = ['Drug Type', 'Drug Name','Dispatched Date','Batch No','QTY','Expiry Date','MFG'];
      const internalHeaders = ['drug_type', 'drug_name','formated_dispatched_date','batch_no','stock','formated_expiry_date','formated_manufactured_date'];
      this.toastr.success('Please wait excel is downloading')
      this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
    }
    else{
      this.toastr.error('No data to download')
    }
  }
  if(type=='availability'){
    const fileName = 'Health_care_worker_stock_availability'
    var excelDataAvail = this.excelDataAvail;
    if(excelDataAvail.length>0){
      excelDataAvail.map(res =>{
        res.formated_expiry_date= this.pipe.transform(res.expiry_date, 'dd-MM-yyyy');
        res.formated_manufactured_date= this.pipe.transform(res.manufactured_date, 'dd-MM-yyyy');
      })
      const excelHeaders = ['Drug Type', 'Drug Name','Batch No','Available Stock','Expiry Date','MFG'];
      const internalHeaders = ['drug_type', 'drug_name','batch_no','stock','formated_expiry_date','formated_manufactured_date'];
      this.toastr.success('Please wait excel is downloading')
      this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelDataAvail)
    }
    else{
      this.toastr.error('No data to download')
    }

  }
  
}

}