<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Coupon Details
                        <button class="btn btn-blue btn-icon float-right"
                            (click)="modalTitle='Coupon Details';openCouponModal()"
                            *ngIf="setPrivilege(Screen_Name.CouponManagement, Permission.Add)">
                            <i class="fa fa-plus"></i>
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="col-md-9 mb-3" style="margin-top: 2rem;" >
                            <button type="button" class="btn  btn-sm btn-secondary float-right" (click)="exportexcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                        </div>
                        <div class="table-responsive">
                            <table datatable id="couponTable" class="table table-striped table-bordered table-sm"
                                style="width:100%">
                                <thead>
                                    <tr>
                                        <th>S No</th>
                                        <th>Coupon Code</th>
                                        <!-- <th>Coupon Code Name</th> -->
                                        <th>Discount Percentage</th>
                                        <!-- <th>Max Discount Amount </th> -->
                                        <th>Flat Rate</th>
                                        <th>Min Amount</th>
                                        <th>Start Date</th>
                                        <th>End Date</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of coupenlist;let i=index">
                                        <td>{{i+1}}</td>
                                        <td class="text-uppercase">{{item.coupon_code}}</td>
                                        <!-- <td>{{item.coupon_name}}</td> -->
                                        <td>{{item.discount_percentage}}</td>
                                        <!-- <td>{{item.max_discount_amt}}</td> -->
                                        <td>{{item.discount_flat}}</td>
                                        <td>{{item.min_payment_applicable}}</td>
                                        <td>{{item.start_date | date:'dd-MM-yyyy'}}</td>
                                        <td>{{item.expiry_date | date:'dd-MM-yyyy'}}</td>
                                        <td>
                                            <div class="badge badge-info badge-pill pointer"
                                                *ngIf="setPrivilege(Screen_Name.CouponManagement, Permission.Edit)">
                                                <a (click)="modalTitle='Coupon Details';openCouponModal(item)"> <i
                                                        class="fa fa-edit text-white"></i></a>
                                            </div>&nbsp;&nbsp;&nbsp;
                                            <div class="badge badge-danger badge-pill pointer"
                                                *ngIf="setPrivilege(Screen_Name.CouponManagement, Permission.Delete)">
                                                <a (click)="deleteItem(item.id)"><i
                                                        class="fa fa-trash text-white"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>