<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
<div class="container-fluid">

        
        
            <!-- <div class="card">
                <div class="card-header bg-grey d-flex align-items-center justify-content-between py-2">
                    <h5 class="mb-0">DOCUMENT: {{fileListNew[0]?.document_title}}</h5>
                    <div class="text-dark">
                        <strong>Date:</strong>&nbsp;&nbsp;{{fileListNew[0]?.created_on}}<br/><strong>Authors:</strong>&nbsp;&nbsp;{{fileListNew[0]?.doc_name | uppercase}}<br/><strong>Status:</strong>&nbsp;&nbsp;{{fileListNew[0]?.status | uppercase}}
                    </div>
                </div>
                <div class="card-body">
                    <h6 class="fw-bold">Encounter:</h6>
                    <ul>
                        <li>{{fileListNew[0]?.encounter?.encounterName | uppercase}}, {{fileListNew[0]?.encounter?.status | uppercase}}, {{fileListNew[0]?.encounter?.date | date: 'dd-MM-yyyy & HH:mm:ss'}}</li>
                        <li>Diagnosis: undefined</li>
                    </ul>
 
                    <h6 class="mt-4 mb-3">#{{fileListNew[0]?.section[0]?.code.coding[0]?.display | uppercase}}: {{fileListNew[0]?.section[0]?.title | uppercase}}</h6>

                    <div class="card shadow-sm">
                        <div class="card-header bg-grey">
                            <h6 class="fw-bold text-uppercase mb-0">Enclosed Clinical Document :</h6>
                        </div>
                        <div class="card-body">
                            <ul>
                                <li>Author:</li>
                                <li>Status: current</li>
                            </ul>
                        <p>Attachments : </p>
                            <ul>
                                <li><a (click)="domSanitize(fileListNew[0])" style="cursor: pointer;"><u>PRESCRIPTION</u></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div> -->
            <div class="card">
                
                <div class="card-header bg-grey d-flex-row align-items-center justify-content-between py-2">
                   <div> <h5 class="mb-0"><strong>FACILITY NAME:</strong> {{item.hip_name | uppercase}}</h5><br/>
                    <h5 class="mb-0"><strong>DOCUMENT:</strong> {{fileListNew[0]?.document_title | uppercase}}</h5></div>
                    <div class="text-dark">
                        <strong>Date:</strong>&nbsp;&nbsp;{{item?.decryptedDocument?.timestamp | date:'dd-MM-yyyy HH:mm:ss'}}<br/><strong *ngIf="fileListNew[0]?.doc_name">Authors:</strong>&nbsp;&nbsp;{{fileListNew[0]?.doc_name | uppercase}}<br/><strong *ngIf="item.decryptedDocument?.entry[0]?.resource?.status">Status:</strong>&nbsp;&nbsp;{{item.decryptedDocument?.entry[0]?.resource?.status | uppercase}}
                    </div>
                </div>
                <div class="card-body">
                    <div *ngIf="fileListNew[0]?.encounter">
                        <h6 class="fw-bold">Encounter:</h6>
                        <ul>
                            <li *ngIf="fileListNew[0]?.encounter?.encounterName">{{fileListNew[0]?.encounter?.encounterName | uppercase}}, {{fileListNew[0]?.encounter?.status | uppercase}}, {{fileListNew[0]?.encounter?.date | date: 'dd-MM-yyyy & HH:mm:ss'}}</li>
                            <!-- <li>Diagnosis: undefined</li> -->
                        </ul>
                     </div>
                    <div *ngIf="isOrgDetailsAvail && fileListNew[0]?.orgDetails!==undefined">
                    <h6 class="fw-bold">Organization:</h6>
                    <ul>
                        <li>{{fileListNew[0]?.orgDetails?.orgName | uppercase}} </li>
                        <ng-container *ngIf="fileListNew[0]?.orgDetails?.address">
                            <li *ngIf="fileListNew[0]?.orgDetails?.address[0]?.line[0]">Address : {{fileListNew[0]?.orgDetails?.address[0]?.line[0]}}</li>
                            <li *ngIf="fileListNew[0]?.orgDetails?.address[0]?.city">City : {{fileListNew[0]?.orgDetails?.address[0]?.city}}</li>
                            <li *ngIf="fileListNew[0]?.orgDetails?.address[0]?.state">State : {{fileListNew[0]?.orgDetails?.address[0]?.state}}</li>
                            <li *ngIf="fileListNew[0]?.orgDetails?.address[0]?.postalCode">Postal Code : {{fileListNew[0]?.orgDetails?.address[0]?.postalCode}}</li>
                            <li *ngIf="fileListNew[0]?.orgDetails?.address[0]?.country">Country : {{fileListNew[0]?.orgDetails?.address[0]?.country}}</li>
                        </ng-container>
                        <!-- <li *ngFor="let ob of fileListNew[0].orgDetails?.telecom">{{ob.system}} : {{ob.value}}</li> -->
                        <ng-container *ngIf="fileListNew[0]?.orgDetails?.Website">
                            <li *ngIf="fileListNew[0]?.orgDetails?.Website[0]?.reference">Website : {{fileListNew[0]?.orgDetails?.Website[0]?.reference}}</li>
                    
                        </ng-container>
                       
                    </ul>
                  </div>
                    <h6 class="mt-4 mb-3" *ngIf="fileListNew[0]?.section">#{{fileListNew[0]?.section[0]?.code.coding[0]?.display | uppercase}}: {{fileListNew[0]?.section[0]?.title | uppercase}}</h6>
                    <ul>
                        <li *ngIf="fileListNew[0]?.doc_name">Author: {{fileListNew[0]?.doc_name | uppercase}}</li>
                        <li *ngIf="item.decryptedDocument?.entry[0]?.resource?.status">Status: {{item.decryptedDocument?.entry[0]?.resource?.status}}</li>
                    </ul>
                      <p *ngIf="fileListNew[0]?.file || fileListNew[0]?.url">Attachments : </p>
                    <ul>
                        <li *ngIf="fileListNew[0]?.file || fileListNew[0]?.url"><a (click)="downloadAsPDF(fileListNew[0])" style="cursor: pointer;"><u>{{fileListNew[0]?.fileName}}</u></a></li>
                    </ul>
                    <div class="card shadow-sm" *ngIf="documentReferenceList.length>0">
                        <div class="card-header bg-grey">
                            <h6 class="fw-bold text-uppercase mb-0">Enclosed Clinical Document :</h6>
                        </div>
                        <div class="card-body">
                           
                            <div class="card-body">
                                <div class="table-responsive">
                                    <!-- <h3>Medical Records</h3> -->
                                    <table class="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>File Name</th>
                                                <th>Status</th>
                                                <th>File</th>
                                            
                                            </tr>
                                        </thead>
                                            <tbody>
                                                <tr *ngFor="let ob of documentReferenceList">
                                                    <td>{{ob?.fileName}}</td>
                                                    <td>{{ob?.status}}</td>
                                                    <td><a (click)="downloadAsPDF(ob)" style="cursor: pointer;"><u>{{ob?.fileName}}</u></a></td>   
                                                </tr>
                                            </tbody>
                                       
                                    </table>
                                </div>
                            </div>

                        </div>
                    </div>
                    <!-- Diagnosis Report 14-07-2023-->
                    <div *ngIf="fileListNew[0]?.diagRprt!==undefined">
                        <h6 class="fw-bold">Diagnostic Report:</h6>
                        <ul>
                            <li>{{fileListNew[0]?.diagRprt?.reportName | uppercase}} </li>
                            <!-- <li *ngFor="let ob of fileListNew[0].orgDetails?.telecom">{{ob.system}} : {{ob.value}}</li> -->
                            <li>patient Name : {{fileListNew[0]?.diagRprt?.patName}}</li>
                            <li>Effective DateTime : {{fileListNew[0]?.diagRprt?.effectiveDateTime}}</li>
                            <li>Issued Date : {{fileListNew[0]?.diagRprt?.issuedDate}}</li>
                            <li>satus : {{fileListNew[0]?.diagRprt?.satus}}</li>
                        
                        
                        </ul>
                      </div>
                    <!-- end Diagnosis Report -->
                    <!-- Care Plan 14-07-2023 -->
                    <div class="card shadow-sm" *ngIf="carePlanList.length>0">
                        <div class="card-header bg-grey">
                            <h6 class="fw-bold text-uppercase mb-0">Care Plan :</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <!-- <h3>Medical Records</h3> -->
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Title</th>
                                            <th>Description</th>
                                            <th>Status</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>Remarks</th>
                                        
                                        </tr>
                                    </thead>
                                        <tbody>
                                            <tr *ngFor="let ob of fileListNew[0]?.carePlanList">
                                                <td>{{ob?.title}}</td>
                                                <td>{{ob?.description}}</td>
                                                <td>{{ob?.status}}</td>
                                                <td>{{ob?.enddate}}</td>
                                                <td>{{ob?.description}}</td>
                                                <td><span *ngFor="let obj of ob?.remarks">{{obj.text}}<br></span></td>
                                                
                                            </tr>
                                        </tbody>
                                   
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- end Care Plan -->
                    <!-- Procedure 14-07-2023 -->
                    <div class="card shadow-sm" *ngIf="ProcedureList.length>0">
                        <div class="card-header bg-grey">
                            <h6 class="fw-bold text-uppercase mb-0">Procedure :</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <!-- <h3>Medical Records</h3> -->
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Performed DateTime</th>
                                            <th>Status</th>
                                            <th>Patient Name</th>
                                            <th>Complication</th>
                                            
                                        
                                        </tr>
                                    </thead>
                                        <tbody>
                                            <tr *ngFor="let ob of ProcedureList">
                                                <td>{{ob?.name}}</td>
                                                <td>{{ob?.performedDateTime}}</td>
                                                <td>{{ob?.status}}</td>
                                                <td>{{ob?.patName}}</td>
                                                <td>{{ob?.complication}}</td>
                                               
                                                
                                            </tr>
                                        </tbody>
                                   
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- End of Procedure -->
                    <!-- Appointment 14-07-2023 -->
                    <div class="card shadow-sm" *ngIf="appointmentList.length>0">
                        <div class="card-header bg-grey">
                            <h6 class="fw-bold text-uppercase mb-0">Appointment :</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <!-- <h3>Medical Records</h3> -->
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Doctore Name</th>
                                            <th>Patient Name</th>
                                            <th>Start Date</th>
                                            <th>End Date</th>
                                            <th>satus</th>
                                            <th>Accept Status</th>
                                            <th>Description</th>
                                            
                                        
                                        </tr>
                                    </thead>
                                        <tbody>
                                            <tr *ngFor="let ob of fileListNew[0]?.appointmentList">
                                                <td>{{ob?.docName}}</td>
                                                <td>{{ob?.patName}}</td>
                                                <td>{{ob?.startDate}}</td>
                                                <td>{{ob?.endDate}}</td>
                                                 <td>{{ob?.satus}}</td>
                                                 <td>{{ob?.accept}}</td>
                                                 <td>{{ob?.description}}</td>
                                               
                                                
                                            </tr>
                                        </tbody>
                                   
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- End of Procedure -->
                    <div class="card shadow-sm" *ngIf="obersvationsList.length>0">
                        <div class="card-header bg-grey">
                            <h6 class="fw-bold text-uppercase mb-0">Observations :</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <!-- <h3>Medical Records</h3> -->
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Code</th>
                                            <th>Value</th>
                                            <th>Unit</th>
                                        </tr>
                                    </thead>
                                        <tbody>
                                            <tr *ngFor="let ob of obersvationsList">
                                                <td>{{ob?.code}}</td>
                                                <td>{{ob?.value}}</td>
                                                <td>{{ob?.unit}}</td>
                                            </tr>
                                        </tbody>
                                   
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- medications -->
                    <div class="card shadow-sm" *ngIf="fileListNew[0]?.medicationList!==undefined && medicationList.length>0">
                        <div class="card-header bg-grey">
                            <h6 class="fw-bold text-uppercase mb-0">Medications :</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <!-- <h3>Medical Records</h3> -->
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Instruction</th>
                                        
                                        </tr>
                                    </thead>
                                        <tbody>
                                            <tr *ngFor="let ob of fileListNew[0]?.medicationList">
                                                <td>{{ob?.name}}</td>
                                                <td>{{ob?.instruction}}</td>
                                                
                                            </tr>
                                        </tbody>
                                   
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- Condition 13-07-2023 -->
                    <div class="card shadow-sm" *ngIf="fileListNew[0]?.conditionList!==undefined && conditionList.length>0">
                        <div class="card-header bg-grey">
                            <h6 class="fw-bold text-uppercase mb-0">Condition :</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <!-- <h3>Medical Records</h3> -->
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Condition</th>
                                            <th>Severity</th>
                                            <th>Clinical Status</th>
                                            <th>Instruction</th>
                                        
                                        </tr>
                                    </thead>
                                        <tbody>
                                            <tr *ngFor="let ob of fileListNew[0]?.conditionList">
                                                <td>{{ob?.name}}</td>
                                                <td>{{ob?.severity}}</td>
                                                <td>{{ob?.clinicalStatus}}</td>
                                                <td>{{ob?.instruction}}</td>
                                                
                                            </tr>
                                        </tbody>
                                   
                                </table>
                            </div>
                        </div>
                    </div>
                    <!-- Allergy List 14-07-2023 -->
                    <div class="card shadow-sm" *ngIf="fileListNew[0]?.allrgyList!==undefined && allrgyList.length>0">
                        <div class="card-header bg-grey">
                            <h6 class="fw-bold text-uppercase mb-0">AllergyIntolerance :</h6>
                        </div>
                        <div class="card-body">
                            <div class="table-responsive">
                                <!-- <h3>Medical Records</h3> -->
                                <table class="table table-bordered">
                                    <thead>
                                        <tr>
                                            <!-- <th>Allergy Type</th> -->
                                            <th>Category</th>
                                            <th>Criticality</th>
                                            <th>Duration</th>
                                            <th>Consulted Doctor</th>
                                            <th>Remarks</th>
                                        
                                        </tr>
                                    </thead>
                                        <tbody>
                                            <tr *ngFor="let ob of fileListNew[0]?.allrgyList">
                                                <!-- <td>{{ob?.allrgyType}}</td> -->
                                                <td>{{ob?.category}}</td>
                                                <td>{{ob?.criticality}}</td>
                                                <td>{{ob?.duration}}</td>
                                                <td>{{ob?.consultedDoctor}}</td>
                                                <td>{{ob?.remarks}}</td>
                                                
                                            </tr>
                                        </tbody>
                                   
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
 </div>


</div>

<div class="modal-footer">
    <button class="btn btn-primary btn-sm me-md-2" type="submit" (click)="activeModal.dismiss('Cross click')">Close</button>
    <!-- <pre>Selected time: {{ time }}</pre> -->
    <!-- <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
</div> 
<div class="modal fade t10" id="appModal">
    <div class="modal-dialog">
        <div class="modal-content modalpopborder" style="width: 51rem;margin-left: -16rem;">
            <div class="modal-header headerbg modalpopborderhead p-2">
                <h6 class="modal-title text-white">File</h6>
                <button type="button" id="closeid" class="close text-white" (click)="close()">&times;</button>
            </div>
            <div class="modal-body modalbodybg">
                <img [src]="myBase64" alt="Image Source" style="width: 50rem;">
            </div>
        </div>
    </div>
</div>