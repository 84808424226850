import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
// const httpOptions = {
//     headers: new HttpHeaders({ 'Content-Type': 'application/json' })
// };
@Injectable({ providedIn: 'root' })

export class RegistrationService {

  appUrl: string = '';
  registrationAPI: string = '';
  regUpdtAPI: string = '';
  pocAPI: string = '';
  reportApi: string = '';
  userId: any;
  viewFilePath: string = '';
  orgFilePath: string = '';
  TENANT_ID: string = '';
  downloadPath: string = '';
  loginAPI: string = '';
  presAPI: string = '';
  logTnntId: number = null;
  centerregeditobj:any;
  hubregeditobj:any;
  dwnldUrl:any
  centersID:any;
  familyDetails:any;
  genderList:Array<any> = [];
  relationsList:Array<any> = [];
  publicKey:any;
  constructor(private api: ApiService, private authSvc: AuthenticationService) {
    this.appUrl = (location.href.includes('localhost') ? environment.applicationURL : location.origin) + '/';
    this.registrationAPI = environment.registrationAPI;
    this.pocAPI = environment.pocAPI;
    this.loginAPI = environment.loginAPI;
    this.reportApi = environment.reportApi;
    this.viewFilePath = `${this.appUrl}${environment.viewFilePath}`;
    this.orgFilePath = `${this.appUrl}${environment.organizationFilePath}`;
    this.downloadPath = `${this.appUrl}${environment.downloadPath}`;
    this.TENANT_ID = sessionStorage.getItem('currentTenant')
    this.userId = sessionStorage.getItem('session_id')
    this.presAPI = environment.presAPI;
    this.regUpdtAPI = environment.regUpdtAPI;
    authSvc.tnntSbjct.subscribe(s => this.logTnntId = s);
    this.dwnldUrl =this.appUrl;
  }

  chkLimit(type: string): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/licenceplan/validatelicenseplan/${type}?TENANT_ID=${tntId}`); }
  // generate jasper prescription
  genPres(pocId: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`poc-emr/jwt/getpresctiptionreport/${pocId}/true?TENANT_ID=${tntId}`); }
  getUserPermissionByOrgId(organizationId: number): Observable<any> {
    return this.api.get(`user/jwt/getuserpermissionbyorgid/${organizationId}`);
  }

  // pocapis
  postChieflist(doctrId: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`poc-emr/jwt/getcheifcomplaints?TENANT_ID=${tntId}&doctorId=${doctrId}`, {}); }
  postPDlist(doctrId: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`poc-emr/jwt/getalldiagonsis?TENANT_ID=${tntId}&doctorId=${doctrId}`, {}); }
  postIVglist(doctrId: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`poc-emr/jwt/getallinvestigations?TENANT_ID=${tntId}&doctorId=${doctrId}`, {}); }
  postDruglist(doctrId: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`poc-emr/jwt/getalldrugtypes?TENANT_ID=${tntId}&doctorId=${doctrId}`, {}); }

  // 22-07-22
  gethandoverReport(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`poc-emr/gethandoverreport?TENANT_ID=${tntId}`, data); }

  getAPI(apiMethod: string, id: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}${apiMethod}?TENANT_ID=${tntId}${id}`); }
  postAPI(apiMethod: string, data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
     return this.api.post(`${this.registrationAPI}${apiMethod}?TENANT_ID=${tntId}`, data); }
  postAPI2(apiMethod: string, data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${this.regUpdtAPI}${apiMethod}?TENANT_ID=${tntId}`, data); }
  getAllRefHosp(hospId:any,hubId:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/jwt/beneficiary/getreferralhospitals?hospitalTypeId=${hospId}&hubId=${hubId}&TENANT_ID=${tntId}`); }
  //Doctor API's
  saveDoctor(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${this.registrationAPI}doctor/registration?TENANT_ID=${tntId}`, data); }
  getByDoctorId(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}doctor/getdoctorrecordbyid?doctorId=${id}&TENANT_ID=${tntId}`); }
  getByAllDoctors(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
     return this.api.get(`${this.registrationAPI}doctor/getdoctorrecords?TENANT_ID=${tntId}`); } //doctor/getdoctorrecords
  getByAllDoctorsNew(): Observable<any> { 
     let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${this.registrationAPI}doctor/web_list?TENANT_ID=${tntId}`, null); }
  deleteByDoctorId(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}doctor/deletedoctorbyid?doctorId=${id}&TENANT_ID=${tntId}`); }
  getMisCals(dId: any, fD: string, tD: string): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}missedcalllog/getmissedcalllogs?doctorId=${dId}&fromDate=${fD}&toDate=${tD}&TENANT_ID=${tntId}`); }
  getAllAgents(): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}doctor/getdoctorrecords?TENANT_ID=${tntId}`); }
  delByAgentId(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}agent/deleteagentbyid?agentId=${id}&TENANT_ID=${tntId}`); }
  saveHubCoord(data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
     return this.api.post(`${this.registrationAPI}hubcoordinator/registration?TENANT_ID=${tntId}`, data); }
  deleteCenter(id:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/center/deletebycenterid/`+id+`?TENANT_ID=${tntId}`); }
  getAllHubCoord(data:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${this.registrationAPI}hubcoordinator/getallregistrations?TENANT_ID=${tntId}`,data); }
  getAPI2(apiMethod: string): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}${apiMethod}?TENANT_ID=${tntId}`); }
  getAPI3(apiMethod: string): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/${apiMethod}?TENANT_ID=${tntId}`); }
  // Doctor Module API's
  saveDctrMdule(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${this.registrationAPI}saveandupdatedefaultmodule?TENANT_ID=${tntId}`, data); }
  DctrMduleById(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}getdefaultmodulebyid?id=${id}&TENANT_ID=${tntId}`); }
  getAllDctrMdule(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
     return this.api.get(`${this.registrationAPI}getalldefaultmodules?TENANT_ID=${tntId}`); }
  deleteDctrModuleById(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}deletedefaultmodule?id=${id}&TENANT_ID=${tntId}`); }

  // Hospital API's
  saveHospital(apiMethod: string, data: any): Observable<any> { return this.api.post(`${this.registrationAPI}${apiMethod}`, data); }
  gethospitalref(stateid: any,districtId:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/jwt/hospital/gethospitalrecordsbyhierarchy?stateId=`+stateid+`&districtId=`+districtId+`&TENANT_ID=${tntId}`); }
  //Lab API's
  save(apiMethod: string, data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${this.registrationAPI}${apiMethod}?TENANT_ID=${tntId}`, data); }
  getTransaction(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
     return this.api.get(`${this.registrationAPI}${apiMethod}&TENANT_ID=${tntId}`); }
  getAll(apiMethod: string): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}${apiMethod}?TENANT_ID=${tntId}`); }
  getAll2(apiMethod: string): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}${apiMethod}&TENANT_ID=${tntId}`); }
  getRefund(apiMethod: string): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}${apiMethod}&TENANT_ID=${tntId}`); }
  getviewFile(apiMethod: string): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}${apiMethod}&TENANT_ID=${tntId}`); }
  //below line commented for API chang for View files 14-06-2022
  // getviewFileNew(apiMethod: string): Observable<any> { return this.api.get(`${this.registrationAPI}${apiMethod}&TENANT_ID=${tntId}`); }
  getviewFileNew(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
     return this.api.get(`${this.registrationAPI}${apiMethod}&TENANT_ID=${tntId}`); }
  getdepartments(apiMethod: string): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
     return this.api.get(`${this.registrationAPI}${apiMethod}&TENANT_ID=${tntId}`); }
  deleteById(apiMethod: string, id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}${apiMethod}${id}&TENANT_ID=${tntId}`); }
  //Chandu
  getByID(apiMethod: string, id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}${apiMethod}${id}&TENANT_ID=${tntId}`);
  }
  getByLabID(apiMethod: string, id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}${apiMethod}${id}&TENANT_ID=${tntId}`); }

  //new API added for the lab registration edit 08-07-2022
  getLabByLabId(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}lab/getlabregistrationsbyid?labId=${id}&TENANT_ID=${tntId}`); }
  //new API added for the het Hospital by id 11-07-2022
  getHospitalByhospId(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}hospital/gethospitalrecordbyid?hospitalId=${id}&TENANT_ID=${tntId}`); }
 
  //HA SESSION Api's
  getagentByuserId(useragentId: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}agent/getagentrecordbyuserid?userId=${useragentId}&TENANT_ID=${tntId}`) }
  getAgentId(agentid: number): Observable<any> { return this.api.get(`telecon/findbyagentid?agentId=${agentid}`) };
  //POC API's
  saveData(apiMethod: string, data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${this.pocAPI}${apiMethod}?TENANT_ID=${tntId}`, data); }
  getConsByDates(apiMethod: string, data: any): Observable<any> {
    // return this.api.post(`${this.pocAPI}${apiMethod}?TENANT_ID=${tntId}`, data);
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`${this.pocAPI}${apiMethod}?TENANT_ID=${tntId}`, data);
  }
  getConsultationData(apiMethod: string, data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${this.pocAPI}${apiMethod}?TENANT_ID=${tntId}`, data); }
  getDataById(apiMethod: string, id: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.pocAPI}${apiMethod}${id}&TENANT_ID=${tntId}`); }
  getAyushDataById(apiMethod: string): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${environment.pocAyushAPI}${apiMethod}?TENANT_ID=${tntId}`); }
  saveAyushPOC(apiMethod: string, data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post2(`${environment.pocAyushAPI}${apiMethod}?TENANT_ID=${tntId}`, data); }
  
  //Pharma API's

  deleteBynodalId(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}nodalofficer/deletebyid?id=${id}&TENANT_ID=${tntId}`); }

  savePharma(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${this.registrationAPI}pharmacy/registerandupdate?TENANT_ID=${tntId}`, data); }
  getByPharmaId(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}pharmacy/getrecordbybypharmacyid?pharmacyId=${id}&TENANT_ID=${tntId}`); }
  getByAllPharmas(): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}pharmacy/getallrecords?TENANT_ID=${tntId}`); } //pharmacy/getallrecords
 //get All Pharma list new API 07-07-2022
  getByAllPharmasNew(): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}pharmacy/newgetallrecords?TENANT_ID=${tntId}`); } //pharmacy/getallrecords
 
  deleteByPharmaId(apiMethod: string, id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}${apiMethod}${id}&TENANT_ID=${tntId}`); }
  // AgentDruglist(data:any): Observable<any> { return this.api.post(`registration/getagentcheckdrugsList?TENANT_ID=${tntId}`,data); }
  AgentDruglist(agentId:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/agentinventory/getagentdrugs/${agentId}?TENANT_ID=${tntId}`); }
  getAllPatients(): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
     return this.api.get(`${this.registrationAPI}beneficiary/getbeneficiaryrecords?TENANT_ID=${tntId}`); }
  getAllPatientsNew(): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${this.registrationAPI}beneficiary/web_list?TENANT_ID=${tntId}`, null); }
  getByAllVitals(bdId: number, is_self: boolean): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}patient/getvitalbybeneficiaryid?TENANT_ID=${tntId}&${is_self ? 'beneficiaryid' : 'beneficiarymemberid'}=${bdId}`); } //beneficiarymemberid pharmacy/getallrecords
  getBydoctorslots(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}doctor/getdoctorrecordbyid?doctorId=${id}&TENANT_ID=${tntId}`); }
  getAppointment(apiMethod: string): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.reportApi}${apiMethod}&TENANT_ID=${tntId}`); }
  getrefund(apiMethod: string, data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${this.registrationAPI}${apiMethod}?TENANT_ID=${tntId}`, data); }
  generateConsulation(apiMethod: string, data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${this.registrationAPI}generateconsulation?TENANT_ID=${tntId}`, data); }
  getPatientsBySearch(apiMethod: string, data: any, isAgent = false, agentId = 0,doctorcenterid): Observable<any> {
    if(doctorcenterid){
      let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
      if (tntId != null)
      var url = `${this.registrationAPI}beneficiary/web_list?TENANT_ID=${tntId}&centerId=${doctorcenterid}`;
      }else{
        let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
        if (tntId != null)
    var url = `${this.registrationAPI}beneficiary/web_list?TENANT_ID=${tntId}`;
      }
    if (isAgent)
      url += `&agentId=${agentId}`;
    return this.api.post(url, data);
  }
  // new changes done for patient list 16-02-2023
  getPatientsBySearchNew(apiMethod: string, data: any, isAgent = false, agentId = 0,doctorcenterid,docId): Observable<any> {
    if(doctorcenterid){
      let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
      if (tntId != null)
      var url = `${this.registrationAPI}beneficiary/web_list?TENANT_ID=${tntId}&centerId=${doctorcenterid}`;
      }else{
        let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
        if (tntId != null)
    var url = `${this.registrationAPI}beneficiary/web_list?TENANT_ID=${tntId}&doctorId=${docId}`;
      }
    if (isAgent)
      url += `&agentId=${agentId}`;
    return this.api.post(url, data);
  }
  getAgentsBySearch(apiMethod: string, data: any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
     return this.api.post(`${this.registrationAPI}agent/web_list?TENANT_ID=${tntId}`, data); }

  initiateAudiocall(doctorMobile: string, patientMobile: string): Observable<any> {
    // https://vidmedplus.dhanushinfotech.com/registration/jwt/initiateaudiocall?doctorMobile=8520078507&patientMobile=7989132075&TENANT_ID=2
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null){
      let url = `${this.registrationAPI}initiateaudiocall?doctorMobile=${doctorMobile}&patientMobile=${patientMobile}&TENANT_ID=${tntId}`;
      return this.api.post(url, null);
    }

  }

  ActiveBenf(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post2(`${this.registrationAPI}beneficiary/activebeneficiary?TENANT_ID=${tntId}`, data); }
  GetBenfbyComp(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}beneficiary/getbeneficiarycorporatebyid?corporateId=${id}&TENANT_ID=${tntId}`); }
  saveBenfbyCompy(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post2(`${this.registrationAPI.replace('jwt/', '')}beneficiary/registration?TENANT_ID=${tntId}`, data); }
  deleteBybeneficiaryId(beneficiaryId: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.delete(`${this.registrationAPI}beneficiary/deletebybeneficiaryid?beneficiaryId=${beneficiaryId}&TENANT_ID=${tntId}`); }

  saveTimeSlots(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${this.reportApi}doctor/save_slot?TENANT_ID=${tntId}`, data); }
  getTimeSlotsByDate(doctorId, fromDate, toDate): Observable<any> {
    // let apiMethod: string=`doctor/slots/findByid_withdate_range?doctorid=${doctorId}&fromDate=${fromDate}&toDate=${toDate}`;
    let apiMethod: string = `doctor/slots/findByid_withdate_range?doctorid=${doctorId}&fromDate=${fromDate}&toDate=${toDate}`;
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.reportApi}${apiMethod}&TENANT_ID=${tntId}`);
  }
  //Service Masters API'S
  saveTypeservice(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${this.registrationAPI}servicetype/saveandupdate?TENANT_ID=${tntId}`, data); }
  getallTypeofservice(): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}servicetype/getall?TENANT_ID=${tntId}`); }
  getTypeofServiceById(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}servicetype/getbyid=${id}&TENANT_ID=${tntId}`); }
  deleteTypeofServiceById(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.delete(`${this.registrationAPI}servicetype/deletebyid?TENANT_ID=${tntId}&id=${id}`); }
  
    session(userId: any): Observable<any> {
    if (userId != null && userId != undefined && userId != 0 && userId != '')
      return this.api.post(`${this.loginAPI}user/logout?userId=${this.authSvc.userId == '' ? userId : this.authSvc.userId}`, {});
  }
  newSession(userId: any, plUD: any): Observable<any> {
    if (userId != null && userId != undefined && userId != 0 && userId != '' && plUD)
      return this.api.post(`${this.loginAPI}user/logout?userId=${this.authSvc.userId == '' ? userId : this.authSvc.userId}`, plUD);
  }
  //Drugs State Apis
  saveDrugDetails(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/stateinventorysave?TENANT_ID=${tntId}`, data); }
  getstatebydrugs(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/getstatedrugs/${id}?TENANT_ID=${tntId}`); }
  AgentDrugDetails(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/agentreceivedrugchecksave?TENANT_ID=${tntId}`, data); }
  patientRegistration(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/beneficiary/registration?TENANT_ID=${tntId}`, data); }
  patientRegistrationNew(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/beneficiary/userregistration?TENANT_ID=${tntId}`, data); }
  getAgentByStateId(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}agent/getagentrecordbystateid?TENANT_ID=${tntId}&stateId=${id}`); }
  getAgentByStateId2(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI.replace('jwt/', '')}agentinventory/getagentdrugs/${id}?TENANT_ID=${tntId}`); }
  getDataByAgent(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/getagentcheckdrugsList?TENANT_ID=${tntId}`, data); }
  getdrugsbystate(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/getstatedrugs/${id}?TENANT_ID=${tntId}`); }
  getcheckdrug(id: number, drugmpid: number, btno: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/getcheckstatedrug/${id}?drugMappingId=${drugmpid}&batchNo=${btno}&TENANT_ID=${tntId}`); }
  getagentcheckdrug(id: number, drugmpid: number, btno: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/agentinventory/getcheckagentdrug/${id}?drugMappingId=${drugmpid}&batchNo=${btno}&TENANT_ID=${tntId}`); }

  // Disconnect Call Api's
  disconnectCallApi(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${this.registrationAPI}disconcalllog/save?TENANT_ID=${tntId}`, data); }


  //apartment approval api
  getSocietySearch(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/society/societysearch?TENANT_ID=${tntId}&isWeb=true`, data); }
  saveApartment(data: any):  Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/society/societyadminstatus?TENANT_ID=${tntId}`, data); }
  //Reports apis
  getDiagnosticReports(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/beneficiaryinventorytransaction/fetchtestreports?TENANT_ID=${tntId}`, data); }
  getotcReports(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/beneficiaryinventorytransaction/drugdispensingreposrt?TENANT_ID=${tntId}`, data); }
  
  
  //Vendor Api's
  getVendorList(): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/vendor/web_list?TENANT_ID=${tntId}`); }
  saveVendorRegistration(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/vendor/registration?TENANT_ID=${tntId}`, data);}
  getAllhubs(data:any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
     return this.api.post(`registration/hubregistration/getallhubs?TENANT_ID=${tntId}`,data); }
  saveHubRegistration(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/hubregistration/registration?TENANT_ID=${tntId}`, data);}
  getAllCenters(data:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/center/getallcenters?TENANT_ID=${tntId}`,data); }
  //new APY for get centers list 07-07-2022
  getAllCentersNew(data:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/center/getallcentersNew?TENANT_ID=${tntId}`,data); }
 
  saveCenterRegistration(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/center/registration?TENANT_ID=${tntId}`, data);}
  activeDoctorsList(): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`telecon/doctorstatus/getall?TENANT_ID=${tntId}`) };
  agenttypes(): Observable<any> { return this.api.get(`masters/getallactivecenters`) };
  getallhubs(data:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/hubregistration/getallhubs?TENANT_ID=${tntId}`,data); }
  getreferalpatientList(): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/referral/getallreferralhospitals?TENANT_ID=${tntId}`); }
  savereferal(data:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/referral/savereferral?TENANT_ID=${tntId}`,data); }
  statusrefer(): Observable<any> { return this.api.get(`masters/getallreferraltypes`) };
  getalldsssummary(): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/dss/getallsummary?TENANT_ID=${tntId}`) };
  checkpharmacy(agentid:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/pharmacy/getpharmacybyagentid?agentId=`+agentid+`&TENANT_ID=${tntId}`);}
  getallagentdatabyid(agentid:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/jwt/agent/getagentrecordbyid?agentId=`+agentid+`&TENANT_ID=${tntId}`);}
  getallagentspharmacy(): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/jwt/agent/getagentrecords?TENANT_ID=${tntId}`);}
  checklab(agentid:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/lab/getlabbyagentid?agentId=`+agentid+`&TENANT_ID=${tntId}`);}
  getbyCenterId(id: number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/center/getbycenterid/${id}?TENANT_ID=${tntId}`); }
  getallUserTypes(): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`masters/getallukusertypes?TENANT_ID=${tntId}`); }
  getAllUsers(user_type:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/jwt/uk/getusersbytype?user_type=${user_type}&TENANT_ID=${tntId}`);}
  getattendanceReport(data:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${this.registrationAPI}uk/getattendancereport?TENANT_ID=${tntId}`,data); }
  getassessementReports(data:any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
     return this.api.post(`registration/getreportforassessmentchecklist?TENANT_ID=${tntId}`,data); }
  getallrefhospbydocid(doctorid:any,fromdate:any,todate:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/referral/getreferralhospitalsbydoctorid?doctorId=${doctorid}&fromDate=${fromdate}&toDate=${todate}&TENANT_ID=${tntId}`);}
  recentdoctor(pid:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/getdoctorrecordbybeneficiaryid?benficiaryId=${pid}&TENANT_ID=${tntId}`);}
  getCenterByMemberID(memId:any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
     return this.api.get(`registration/getcenterbymemberid?Id=${memId}&TENANT_ID=${tntId}`);}
  postvitals(data:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/jwt/patient/savevitals?TENANT_ID=${tntId}`,data); }
  getBeneficiaryFamilyList(benID:number ): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`${this.registrationAPI}beneficiarymemberdetails/getallbybeneficiaryid?beneficiaryId=${benID}&TENANT_ID=${tntId}`); }
  saveFamilyMem(data: any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`${this.registrationAPI}beneficiarymemberdetails/saveandupdate?TENANT_ID=${tntId}`, data); }
  getMemID(benID:number): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/getmemberhealthcard?memberId=${benID}&TENANT_ID=${tntId}`); }
  getagentdrugs(): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/agentinventory/getagentdrugs/${sessionStorage.agentId}?TENANT_ID=${tntId}`); }
  saveagentdrugs(data:any,abenId:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/beneficiaryinventorytransaction/beneficiarytransaction/${abenId}?TENANT_ID=${tntId}`,data); }
  getagentPatients(): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/jwt/beneficiary/getbeneficiaryrecordsbyagentid?agentId=${sessionStorage.agentId}&TENANT_ID=${tntId}`);}
  getprevconsultations(benid:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`poc-emr/jwt/getpreviousconsultation?TENANT_ID=${tntId}&beneficiaryId=${benid}`);}
  getprevconsultationsprescrp(constid:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`poc-emr/jwt/getpocbyconsultationid?TENANT_ID=${tntId}&consultationId=${constid}`);}
  //NDHM API's
  getOTP(aadhar:any): Observable<any> { return this.api.get(`registration/jwt/apis/generateaadharotp?aadharnumber=${aadhar}`);}
  verifyOTP(data:any): Observable<any> { return this.api.post(`registration/jwt/apis/verifyotp`,data)}
  encryptOTP(otp:any): Observable<any> { return this.api.get(`ndhm/jwt/apis/generateencryptecode?otp=${otp}`);}
  generateOTP(data:any): Observable<any> { return this.api.post(`registration/jwt/apis/generatemobileotp`,data)}
  confirmAadhar(data:any): Observable<any> { return this.api.post(`registration/jwt/apis/confirmaadhar`,data)}
  verifyMobOtp(data:any): Observable<any> { return this.api.post(`registration/jwt/apis/verifymobileotp`,data)}
  createHealthID(data:any): Observable<any> { return this.api.post(`registration/jwt/apis/createhealthidwithpreverified`,data)}
  getassessment():Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/getallassessments?user_type=spoke&TENANT_ID=${tntId}`);}
  registerAsset(data:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/saveassessmentmapping?TENANT_ID=${tntId}`,data);}
  getassetmntchecklist():Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/getassessmentchecklistbyagentidanddates?agent_id=${sessionStorage.agentId}&TENANT_ID=${tntId}`);}
  saveviewregisterAsset(data:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/saveassessmentchecklist?TENANT_ID=${tntId}`,data);}
  //savereferal(data:any): Observable<any> { return this.api.post(`registration/referral/savereferral?TENANT_ID=${tntId}`,data); }
  downloadHealthCard(data:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/jwt/apis/getPngCard?TENANT_ID=${tntId}`,data)}
  existHID(hid:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/jwt/apis/existsbyhealthid?healthId=${hid}&TENANT_ID=${tntId}`);}
  searchWithHID(hid:any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
     return this.api.get(`registration/jwt/apis/searchbyhealthid?healthId=${hid}&TENANT_ID=${tntId}`);}
  authenticate(mtd:any,hid:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/jwt/apis/authinit?authMethod=${mtd}&healthId=${hid}&TENANT_ID=${tntId}`);}
  cnfrmAadharOtp(data:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/jwt/apis/confirmwithaadharotp?TENANT_ID=${tntId}`,data)}
  cnfrmMobOtp(data:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/jwt/apis/confirmwithmobileotp?TENANT_ID=${tntId}`,data)}
  password(data:any): Observable<any> {
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
     return this.api.post(`registration/jwt/apis/loginviapassword?TENANT_ID=${tntId}`,data)}
  getAcntProfile(data:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/jwt/apis/getaccountprofile?TENANT_ID=${tntId}`,data)}
  forgotHidAadhr(adhr:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/jwt/apis/forgothealthidgenerateaadharotps?aadharnumber=${adhr}&TENANT_ID=${tntId}`); }
  frgtvrfyotp(data:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/jwt/apis/forgotverifyaadharotps?TENANT_ID=${tntId}`,data); }
  forgotHidmob(mob:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/jwt/apis/forgothealthidgeneratemobileotp?mobilenumber=${mob}&TENANT_ID=${tntId}`); }
frgtvrfyotpmob(data:any): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.post(`registration/jwt/apis/forgotverifymobileotp?TENANT_ID=${tntId}`,data); }
  verifyDemographics(data:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/jwt/apis/verifythroughdemographicdetails??TENANT_ID=${tntId}`,data); }
  //Inventory
 getDrugsList(data: any): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.post(`registration/getagentcheckdrugsList?TENANT_ID=${tntId}`, data); }
 viewStock(id:any): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.get(`registration/agentinventory/getagentdrugs/${id}?TENANT_ID=${tntId}`);}
updateStock(data: any): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.post(`registration/agentdrugcheck?TENANT_ID=${tntId}`, data); }
saveStartTime(payload:any): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.post(`registration/jwt/uk/saveattendancereport?TENANT_ID=${tntId}`,payload);}
getAttendanceCapture(data:any): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.post(`registration/jwt/uk/getattendancereport?TENANT_ID=${tntId}`,data); }
//Doctor Availability slots in a month 30-03-2022
getdoctoravailableslotsinamonth(doctorId,month,year): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.get(`registration/jwt/doctor/getdoctoravailableslotsinamonth?month=${month}&year=${year}&doctor_id=${doctorId}&TENANT_ID=${tntId}`);}
// Get EHR Report 31-03-2022
getehr(beneficinayId): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.get(`registration/getehrreports?organizationId=${tntId}&beneficirayId=${beneficinayId}&TENANT_ID=${tntId}`);}
// Get Hospital types New API 04-04-2022
// get Ehr details for family member #chandu 25-05-2022
getmemberehr(dependentId): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.get(`registration/getehrreports?organizationId=${tntId}&dependentId=${dependentId}&TENANT_ID=${tntId}`);}
// end line of family member ehr 25-05-2022
getallhospitaltype(): Observable<any> {
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
   return this.api.get(`registration/getallhospitaltype?TENANT_ID=${tntId}`);}
getallhospitaltypes(): Observable<any> { return this.api.get(`masters/getallahospitaltypes`);}
//Get All Center Hospital API integration 05-04-2022
getallcenterhospitals(center): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.get(`registration/hospital/gethospitaltype?TENANT_ID=${tntId}&centerType=${center}`);}
gethospitalrefNew(referral): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.get(`registration/hospital/gethospitaltype?TENANT_ID=${tntId}&centerType=${referral}`);}

//Doctor Registration new API integrations 05-04-2022
getdoctorreghubs(hub): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.get(`registration/hospital/gethospitaltype?TENANT_ID=${tntId}&centerType=${hub}`);}
getallhospitalcentertypes(): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.get(`registration/getallhospitalcentertype?TENANT_ID=${tntId}`);}
getreferralHospitals(referral,hospitalId): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.get(`registration/hospital/gethospitaltype?TENANT_ID=${tntId}&centerType=${referral}&hospitalTypeId=${hospitalId}`);}

//POC new API for referral hospital drop down 06-04-2022
getPOCAllRefHosp(hospId:any,centerId:any): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.get(`registration/jwt/beneficiary/getreferralhospitals?hospitalTypeId=${hospId}&centerId=${centerId}&TENANT_ID=${tntId}`); }
  
//New lines added for vitals API 10-04-2022
getVitals(beneficinayId): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.get(`registration/getvitals?organizationId=${tntId}&beneficinayId=${beneficinayId}&TENANT_ID=${tntId}`);}
//New API added for interaction for update 18-07-2022
 updatePatientInteraction(data:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  //savepatientinteraction updatepatentinteraction
    if (tntId != null)
    return this.api.post(`registration/jwt/savepatientinteraction?TENANT_ID=${tntId}`,data); }

// new line added for network status report service 29-08-2022
networkStatusReport(data: any): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.post(`vidmed-dashboard/dashboard/networkstatusreport?TENANT_ID=${tntId}`, data); }
// center 
getCenterReport(data:any): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.post(`registration/jwt/updatepatentinteraction?TENANT_ID=${tntId}`,data); }

//Get district wise centres load 29-08-2022
getcenterrecordsbymandalids(payload:any): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.post(`registration/center/getcenterrecordsbymandalids?TENANT_ID=${tntId}`,payload);}
 
 //get centres based on district IDs 08-09-2022
 getcenterrecordsbyDistrictIds(payload:any): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
  return this.api.post(`registration/center/getcenterrecordsbydistrictids?TENANT_ID=${tntId}`,payload);}
  //savereferal(data:any): Observable<any> { return this.api.post(`registration/referral/savereferral?TENANT_ID=${tntId}`,data); }
  // searchAppartments(data: any): Observable<any> {
  //   this.TENANT_ID = JSON.parse(sessionStorage.tenantDtls).data.id || '';
  //   return this.api.post2(`${this.registrationAPI}society/searchbyfileds?TENANT_ID=${this.TENANT_ID}`, data);
  // }
  // updateByTENANT_ID(url: string): string {
  //   let id = JSON.parse(sessionStorage.getItem('TENANT_ID'));
  //   const ind = url.includes('?') ? url.indexOf('?') : -1;
  //   this.TENANT_ID = id; 
  //   // console.log('Index:=', ind);
  //   if (ind > -1)
  //     return url.replace('?', `?TENANT_ID=${this.TENANT_ID}&`);
  //   else
  //     return `${url}?TENANT_ID=${this.TENANT_ID}`;
  // }
  // New API intergration for Open vidu session close with doc ID 22-09-2022
  deleteSessionbydocId(docId: number): Observable<any> { return this.api.delete(`telecon/activesessiondeletebydoctorid?doctorId=${docId}`) };
  getNetworkMonthlyReport(reportName, data:any): Observable<any> { 

    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    if(reportName==='Centre'){
      return this.api.post(`registration/center/getcenterwisereportsbylistofcenterids?TENANT_ID=${tntId}`, data);
    //return;
    } else{
      return this.api.post(`registration/center/getdatewisenetworkreportbydates?TENANT_ID=${tntId}`, data);
     //return;

    }
   
  }


  getAllConsents(): Observable<any> { 
    // 10/04/23 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.get(`registration/milestone2/getAllConsents?TENANT_ID=${tntId}`); 
  }
  
  verifyAbha(payload:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    return this.api.post(`registration/m2/v0.5/users/auth/fetch-modes?TENANT_ID=${tntId}`,payload);}
 
    //get modes from response data 19-10-2022
    fetchNodes(requestId:any): Observable<any> { 
      let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`registration/milestone2/getCallBack?requestId=${requestId}&api-type=FETCH&TENANT_ID=${tntId}`);}
 
    //get init after mode select 19-10-2022
    initMode(payload:any): Observable<any> { 
      let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`registration/m2/v0.5/users/auth/init?TENANT_ID=${tntId}`,payload);}
 
    //Call back init 19-10-2022
    callbackInit(requestId:any): Observable<any> { 
      let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`registration/milestone2/getCallBack?requestId=${requestId}&api-type=INIT&TENANT_ID=${tntId}`);}
 
    //Confirm Auth 19-10-2022
    authConfirm(payload:any): Observable<any> { 
      let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
    return this.api.post(`registration/m2/v0.5/users/auth/confirm?TENANT_ID=${tntId}`,payload);}
  //Call back Confirm 19-10-2022
  confirmCallback(requestId:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    return this.api.get(`registration/milestone2/getCallBack?requestId=${requestId}&api-type=CONFIRM&TENANT_ID=${tntId}`);}
//getlatestcarecontext API integration 20-10-2022
 getLatestCareContext(benID:any,interactionId:any): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
if (tntId != null)
  return this.api.get(`registration/milestone2/getlatestcarecontext?beneficiaryId=${benID}&interactionId=${interactionId}&TENANT_ID=${tntId}`);}
 
  addCareContext(payload:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    return this.api.post(`registration/m2/v0.5/links/link/add-contexts?TENANT_ID=${tntId}`,payload);}
    //Demographic integration 02-12-2022

     initDemographicMode(payload:any): Observable<any> { 
      let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.post(`registration/m2/v0.5/users/auth/confirm?TENANT_ID=${tntId}`,payload);}
 //Direct 05-12-2022
 directCallbackInit(healthId:any): Observable<any> { 
  let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
if (tntId != null)
  return this.api.get(`registration/milestone2/getCallBack?healthId=${healthId}&api-type=DIRECT-AUTH,ON-DIRECT-AUTH&TENANT_ID=${tntId}`);}
  careContextCallback(requestId:any): Observable<any> { 
    let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
  if (tntId != null)
    return this.api.get(`registration/milestone2/getCallBack?requestId=${requestId}&api-type=ADD-CONTEXT&TENANT_ID=${tntId}`);}

    // New API added for fetch demography 29-12-2022
    getDemograpyDtls(benID:any): Observable<any> { 
      let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
    if (tntId != null)
      return this.api.get(`registration/get-demography-object?beneficiaryId=${benID}&TENANT_ID=${tntId}`);}
     
      // new API added for get mem details with from patient list mem to POC 22-02-2023
      getBeneficiaryFamilyMember(benID:number,memId:number ): Observable<any> { 
        let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
        if (tntId != null)
        return this.api.get(`${this.registrationAPI}beneficiarymemberdetails/getallbybeneficiaryid?beneficiaryId=${benID}&memberId=${memId}&TENANT_ID=${tntId}`); }
     
        // new method added for available slots 23-02-2023
        getTimeSlotsByDateAndDocId(docId:any): Observable<any> { 
          let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
          if (tntId != null)
          // return this.api.get(`${this.reportApi}doctor/available_slots?TENANT_ID=${tntId}&doctor_id=${docId}&slot_for_day=${true}`); }
          return this.api.get(`${this.reportApi}doctor/available_dates?TENANT_ID=${tntId}&doctor_id=${docId}`); }
        
          getAbhaAddress(payload:any,caseId:any): Observable<any> { 
            let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
            if (tntId != null)
             return this.api.post(`registration/m3/v0.5/patients/find?caseId=${caseId}&TENANT_ID=${tntId}`,payload);}
     
             getConsentsList(docId:any,benId:any): Observable<any> { 
               let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
               if (tntId != null)
               return this.api.get(`registration/milestone2/get-consents-list-by-doctor?doctorId=${docId}&beneficiary_id=${benId}&TENANT_ID=${tntId}`); 
             }
             // new method added for the get callback m3 04-07-2023
             getCallback(request:any):Observable<any>{
               let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
               if (tntId != null)
               return this.api.get(`registration/milestone3/get-m3-call-back?requestId=${request}&TENANT_ID=${tntId}`); 
            
             }
             // new method added for get consent view files 05-07-2023
             getConsentViewFiles(consentId:any):Observable<any>{
               let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
               if (tntId != null)
               return this.api.get(`registration/milestone3/get-document-by-consent-id?consentId=${consentId}&TENANT_ID=${tntId}`); 
            
             }
              // new APi Added for consent list 12-06-2023
      consentNdhm(payload:any,benId:any): Observable<any> { 
        let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
      if (tntId != null)
        return this.api.post(`registration/m3/v0.5/consent-requests/init?beneficiaryId=${benId}&TENANT_ID=${tntId}`,payload);}

    
}