import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { Common } from 'src/app/common';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-organization',
  templateUrl: './organization.component.html',
  styleUrls: ['./organization.component.css']
})

export class OrganizationComponent implements OnInit {
  @Input() title: string;
  @Input() editData: any;
  lstPlans = [];
  submitted = false;
  organizationForm: FormGroup;
  plnDtls = ''; editPlnId = '';
  mobPtrn = ''; mobErrMsg = ''; maxLngth = '';

  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private formBuilder: FormBuilder
    , private authServ: AuthenticationService
    , private userService: UserService) { }

  ngOnInit(): void {
    this.authServ.mobPtrnSubject.subscribe(s => this.mobPtrn = s), this.authServ.mobErrMsgSubject.subscribe(s => this.mobErrMsg = s),
      this.authServ.maxLngthSubject.subscribe(s => this.maxLngth = s), Common.setFocus('orgnization_name'), this.initFormControls(), this.getPlans();
    if (this.editData)
      this.organizationForm.patchValue(this.editData), this.SetOrgPlan(); //, this.GetOrgStngsDatabyOrgId(this.editData.id);
  }

  SetOrgPlan() {
    this.editPlnId = this.editData?.license_plan?.id ?? '', this.organizationForm.get('license_plan').setValue(this.editPlnId);
    setTimeout(() => { this.onPlanChng(this.editPlnId); }, 200);
  }

  GetOrgStngsDatabyOrgId(orgId: number) {
    this.userService.getById(`organization/orgSettings/${orgId}`).subscribe(res => {
      if (res) {
        if (res.data?.license_plan_id != null) {
          this.editPlnId = res.data.license_plan_id?.id, this.organizationForm.get('license_plan').setValue(this.editPlnId);
          setTimeout(() => { this.onPlanChng(this.editPlnId); }, 200);
        }
        else
          this.organizationForm.get('license_plan').setValue('');
      }
    });
  }

  onPlanChng(val: any) {
    this.plnDtls = '';
    if (val != '') {
      if (this.editPlnId != '') {
        if (+this.editPlnId > +val) {
          this.organizationForm.get('license_plan').setValue(this.editPlnId); // Organization Plan should not be downgraded
          if (+this.editPlnId == 2)
            this.toastr.warning('You can change your plan to Platinum only.');
          if (+this.editPlnId == 3)
            this.toastr.warning('You cannot change your plan. Please contact Admin.');
          this.GetPlanName(+this.editPlnId);
          return;
        }
      }
      this.GetPlanName(val);    
    }
  }

  GetPlanName(val: any) {
    let dtls = this.lstPlans.filter(f => f.id == val)[0];
    this.plnDtls = `${dtls.license_plan}: Hospitals - ${dtls.hospital_count}, Doctors - ${dtls.doctor_count}, Patients - ${dtls.patient_count == null ? 'Unlimited' : dtls.patient_count}, Labs - ${dtls.lab_count}, Pharmacies - ${dtls.pharmacy_count} & Pharmacist/Healthcare Professional - ${dtls.agent_count}`;
  }

  initFormControls() {
    this.organizationForm = this.formBuilder.group({
      id: [''],
      orgnization_name: ['', Validators.required],
      main_address: ['', Validators.required],
      email: ['', [ Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      // alternate_contact_no: [''],
      // fax: [''],
      refund_email: ['', [ Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      support_email: ['', [ Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      // primary_contact_name: ['', Validators.required],
      primary_contact_no: ['', [Validators.required, Validators.minLength(+this.maxLngth), Validators.pattern(this.mobPtrn)]],
      // tenant_id:[''],
      is_active: [false],
      is_prior_payment_req: [false],
      is_patient_registration_absolute_split: [false],
      patient_registration_amount:[''],
      license_plan: ['', Validators.required],
    });
  }

  getPlans() {
    this.userService.getAll('licenseplan/getall').subscribe(res => {
      if (res) {
        if (res.status == "OK")
          this.lstPlans = res.data;
        else
          this.toastr.error(res.message);
      }
    });
  }

  get f() { return this.organizationForm.controls; }

  onSubmit(event: any) {
    this.submitted = true, Common.getFormValidationErrors(this.organizationForm);
    if (this.organizationForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else {
      if (this.organizationForm.get('license_plan').value != '') {
        let lcnsPlan = Object.assign({}, this.lstPlans.filter(f => f.id == this.organizationForm.get('license_plan').value)[0]);
        this.organizationForm.get('license_plan').setValue(lcnsPlan);
      }
      // const postData = JSON.parse(JSON.stringify(this.organizationForm.getRawValue()));
      this.userService.save('organization/registration', this.organizationForm.getRawValue()).subscribe(res => {
        if (res.status === 'OK') {
          this.toastr.success(res.message, 'Organization Details');
          this.activeModal.close('changes saved');
        }
        else {

        }
        //  this.activeModal.close('changes saved');
      }, error => { this.toastr.error(error.message) });
    }
  }

  submitForm() {
    document.getElementById('btnsubmit').click();
  }

}