import { CouponDetailsComponent } from './../coupon-details/coupon-details.component';
import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterService } from 'src/app/shared/services/master.service';
import { Common } from 'src/app/common';
import { ToastrService } from 'ngx-toastr';
import { BaseComponent } from 'src/app/shared/base.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserService } from 'src/app/shared/services/user.service';
import * as XLSX from 'xlsx';
declare let $: any;
@Component({
  selector: 'app-coupon-list',
  templateUrl: './coupon-list.component.html',
  styleUrls: ['./coupon-list.component.css']
})
export class CouponListComponent extends BaseComponent implements OnInit {
  modalTitle: string = '';
  coupenlist: any;
  fileName = 'coupon list.xlsx';
  constructor(config: NgbModalConfig, private modalService: NgbModal,
    private masterService: MasterService, private toastr: ToastrService,
    private authSvc: AuthenticationService, private userservice: UserService) {
      super();
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.authSvc.isShowGlblOrg.next(false), this.authSvc.tenantId = sessionStorage.getItem('defCrntTnnt')
    if (this.authSvc.roleId == 1) {
      sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt'));
    }
    this.getAllCoupans();
  }

  getAllCoupans() {
    this.userservice.getAll('getallcouponlist').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Coupon List');
        else
          this.coupenlist = res.data;
        Common.loadDataTable(this.coupenlist, '#couponTable');
      }

    });
  }

  openCouponModal(DataEdit: any = null) {
    const modalRef = this.modalService.open(CouponDetailsComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.DataEdit = DataEdit;
    modalRef.result.then(result => {
      if (result == 'changes saved')
        this.getAllCoupans();
    }, (reason) => { });
  }

  deleteItem(id: any) {
    if (confirm(`Are you sure you want delete record?`)) {
    this.userservice.deletecouponId(id).subscribe(res => {
      if (res.status == "OK") {
        this.getAllCoupans();
        this.toastr.success('Record Deleted Successfully');
      }
    },
      error => { this.toastr.error('An Error Occured'); });
  }
}

exportexcel(): void {
  var table = $('#couponTable').DataTable();
  if (!table.data().count()) {
    this.toastr.error('No data available in table! Please select and try again');
  }
  else {	
    / table id is passed over here /
    let element = document.getElementById('couponTable');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    / generate workbook and add the worksheet /
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    ws['!cols'] = [];
    ws['!cols'][10] = { hidden: true };

    / save to file /
    XLSX.writeFile(wb, this.fileName);
  }
}


}