<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="rfrlForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Customer Eligibility <span class="text-danger">*</span> </label>
                        <div>
                            <div class="custom-control-inline custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" id="enable_referred"
                                    formControlName="enable_referred" (change)="onChngeCB()">
                                <label class="custom-control-label" for="enable_referred">Referred</label>
                            </div>
                            <div class="custom-control-inline custom-control custom-checkbox">
                                <input class="custom-control-input" type="checkbox" id="enable_referree"
                                    formControlName="enable_referree" (change)="onChngeCB()">
                                <label class="custom-control-label" for="enable_referree">Referree</label>
                            </div>
                            <div *ngIf="submitted && f.enable_referred.errors" class="invalid-feedback">
                                <div *ngIf="f.enable_referred.errors.required">Customer Eligibility is required</div>
                            </div>
                            <div *ngIf="submitted && f.enable_referree.errors" class="invalid-feedback">
                                <div *ngIf="f.enable_referree.errors.required">Customer Eligibility is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Company Name <span class="text-danger">*</span></label>
                        <div>
                            <select formControlName="organization_id" class="form-control form-control-sm" id="organization_id"
                                [ngClass]="{ 'is-invalid': submitted && f.organization_id.errors }" maxlength="30">
                                <option value="">Select</option>
                                <option *ngFor="let state of companyList" [value]="state.id">{{state.company_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.organization_id.errors" class="invalid-feedback">
                                <div *ngIf="f.organization_id.errors.required">Company Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Apply same offer to Referral & Referree</label>
                        <div>
                            <div class="custom-control-inline custom-control custom-radio">
                                <input formControlName="same_offer_referral_referree" class="custom-control-input" 
                                    id="rbSameOfrY" type="radio" name="same_offer_referral_referree" [value]="true">
                                <label class="custom-control-label" for="rbSameOfrY">Yes </label>
                            </div>
                            <div class="custom-control-inline custom-control custom-radio">
                                <input formControlName="same_offer_referral_referree" class="custom-control-input" 
                                    id="rbSameOfrN" type="radio" name="same_offer_referral_referree" [value]="false">
                                <label class="custom-control-label" for="rbSameOfrN">No </label>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="row" *ngIf="rfrlForm.get('enable_referred').value">
                <div class="col-xl-12"><h4>To Referred</h4></div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Referred Coupon Name <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="referred_coupon"
                                [ngClass]="{ 'is-invalid': submitted && f.referred_coupon.errors }"
                                id="referred_coupon" maxlength="25" placeholder="Referred Coupon Name"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97
                                    && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57))'
                                oncopy="return false" class="form-control form-control-sm text-uppercase">
                            <div *ngIf="submitted && f.referred_coupon.errors" class="invalid-feedback">
                                <div *ngIf="f.referred_coupon.errors.required">Referred Coupon Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Apply To <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="referred_applied_type" id="referred_applied_type"
                                [ngClass]="{ 'is-invalid': submitted && f.referred_applied_type.errors }"
                                class="form-control form-control-sm" disabled>
                                <option value="">Select</option>
                                <option value="Consultation">Consultation</option>
                                <!-- <option value="Lab">Lab</option>
                                <option value="Pharmacy">Pharmacy</option> -->
                            </select>
                            <div *ngIf="submitted && f.referred_applied_type.errors" class="invalid-feedback">
                                <div *ngIf="f.referred_applied_type.errors.required">Apply To is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Discount Type <span class="text-danger">*</span></label>
                        <div>
                            <select formControlName="is_referred_absolute" id="is_referred_absolute"
                                [ngClass]="{ 'is-invalid': submitted && f.is_referred_absolute.errors }"
                                class="form-control form-control-sm" (change)="onChngDT('rd')" #RfrdDT>
                                <option value="">Select</option>
                                <option value="true">Flat(Rate)</option>
                                <option value="false">Percentage</option>
                            </select>
                            <div *ngIf="submitted && f.is_referred_absolute.errors" class="invalid-feedback">
                                <div *ngIf="f.is_referred_absolute.errors.required">Discount Type is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Discount Rate <span class="text-danger" *ngIf="RfrdDT.value=='true'">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="referred_discount_absolute_amt"
                                [ngClass]="{ 'is-invalid': submitted && f.referred_discount_absolute_amt.errors }"
                                [attr.disabled]="(RfrdDT.value=='true')?null:true" id="referred_discount_absolute_amt"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' maxlength="4"
                                class="form-control form-control-sm" placeholder="Discount Rate">
                            <div *ngIf="submitted && f.referred_discount_absolute_amt.errors" class="invalid-feedback">
                                <div *ngIf="f.referred_discount_absolute_amt.errors.required">Discount Rate is required</div>
                                <div *ngIf="f.referred_discount_absolute_amt.errors.min">Minimum Discount Rate is 100</div>
                                <div *ngIf="f.referred_discount_absolute_amt.errors.max">Maximum Discount Rate is 1000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Discount Percentage<span class="text-danger" *ngIf="RfrdDT.value=='false'">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="referred_discount_percentage"
                                [attr.disabled]="(RfrdDT.value=='false')?null:true" id="referred_discount_percentage"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' placeholder="Discount Percentage"
                                maxlength="3" [ngClass]="{ 'is-invalid': submitted && f.referred_discount_percentage.errors }"
                                class="form-control form-control-sm" minlength="1" (change)="onDiscPerChng($event.target.value)">
                            <div *ngIf="submitted && f.referred_discount_percentage.errors" class="invalid-feedback">
                                <div *ngIf="f.referred_discount_percentage.errors.required">Discount Percentage is required</div>
                                <div *ngIf="f.referred_discount_percentage.errors.min">Minimum Discount Percentage is 10</div>
                                <div *ngIf="f.referred_discount_percentage.errors.max">Maximum Discount Percentage is 100</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Maximum Discount Amount <span class="text-danger"
                            *ngIf="RfrdDT.value=='false'">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="referred_max_discount_amt"
                                [attr.disabled]="(RfrdDT.value=='false')?null:true" id="referred_max_discount_amt"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' maxlength="4"
                                [ngClass]="{ 'is-invalid': submitted && f.referred_max_discount_amt.errors }"
                                class="form-control form-control-sm" placeholder="Maximum Discount Amount">
                            <div *ngIf="submitted && f.referred_max_discount_amt.errors" class="invalid-feedback">
                                <div *ngIf="f.referred_max_discount_amt.errors.required">Maximum Discount Amount is required</div>
                                <div *ngIf="f.referred_max_discount_amt.errors.min">Min. Maximum Discount Amount is 100</div>
                                <div *ngIf="f.referred_max_discount_amt.errors.max">Max. Maximum Discount Amount is 1000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Coupon to generate</label>
                        <div>
                            <div class="custom-control-inline custom-control custom-radio">
                                <input formControlName="apply_referred_coupon_after_first_consultation"
                                    [value]="false" class="custom-control-input" type="radio"
                                    id="rfrdCpnGen1" name="apply_referred_coupon_after_first_consultation">
                                <label class="custom-control-label" for="rfrdCpnGen1">Before 1st Consult </label>
                            </div>
                            <div class="custom-control-inline custom-control custom-radio">
                                <input formControlName="apply_referred_coupon_after_first_consultation"
                                    [value]="true" class="custom-control-input" type="radio"
                                    id="rfrdCpnGen2" name="apply_referred_coupon_after_first_consultation">
                                <label class="custom-control-label" for="rfrdCpnGen2">After 1st Consult </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Maximum Utilisation Count<span class="text-danger">*</span> </label>
                        <div>
                            <input autocomplete="off" formControlName="referred_max_utilization_count" (change)="onChngMUC('rd')"
                                id="referred_max_utilization_count" maxlength="4" placeholder="Maximum Utilisation Count" type="text"
                                class="form-control form-control-sm" onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                [ngClass]="{ 'is-invalid': submitted && f.referred_max_utilization_count.errors }" /> <!-- (keyup)="onKeyUp('rd')" -->
                            <div *ngIf="submitted && f.referred_max_utilization_count.errors" class="invalid-feedback">
                                <div *ngIf="f.referred_max_utilization_count.errors.required">Maximum Utilisation Count is required</div>
                                <div *ngIf="f.referred_max_utilization_count.errors.min">Min. Maximum Utilisation Count is 1</div>
                                <div *ngIf="f.referred_max_utilization_count.errors.max">Max. Maximum Utilisation Count is 1000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Minimum Consultation Amount <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" class="form-control form-control-sm" maxlength="4"
                                formControlName="referred_min_consultation_amt" id="referred_min_consultation_amt"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' (focus)="dp.hide()"
                                [ngClass]="{ 'is-invalid': submitted && f.referred_min_consultation_amt.errors }"
                                placeholder="Minimum Consultation Amount"> <!-- (change)="onChngMCA('rd', $event.target.value)" -->
                            <div *ngIf="submitted && f.referred_min_consultation_amt.errors" class="invalid-feedback">
                                <div *ngIf="f.referred_min_consultation_amt.errors.required">Minimum Consultation Amount is required</div>
                                <div *ngIf="f.referred_min_consultation_amt.errors.min">Min. Minimum Consultation Amount is 300</div>
                                <div *ngIf="f.referred_min_consultation_amt.errors.max">Max. Minimum Consultation Amount is 1000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Start Date <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="sdRfrd" (focus)="dp.show()"
                                placeholder="Start Date" bsDatepicker #dp="bsDatepicker" id="sdRfrd"
                                (focus)="dp1.hide()" [minDate]="minDate" (bsValueChange)="onFromDateChange($event, 'rd')"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                [ngClass]="{ 'is-invalid': submitted && f.sdRfrd.errors }" class="form-control form-control-sm" />
                            <div *ngIf="submitted && f.sdRfrd.errors" class="invalid-feedback">
                                <div *ngIf="f.sdRfrd.errors.required">Start Date is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">End Date <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="edRfrd" class="form-control form-control-sm"
                                placeholder="End Date" bsDatepicker [minDate]="minRfrdToDate" (focus)="dp.hide()" id="edRfrd"
                                #dp1="bsDatepicker" (focus)="dp1.show()" [ngClass]="{ 'is-invalid': submitted && f.edRfrd.errors }"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }">
                            <div *ngIf="submitted && f.edRfrd.errors" class="invalid-feedback">
                                <div *ngIf="f.edRfrd.errors.required">End Date is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <label class="control-label">Number of times per Referred <span class="text-danger">*</span></label>
                    <div>
                        <input type="text" autocomplete="off" formControlName="referred_max_coupon_episode_count"
                            id="referred_max_coupon_episode_count" placeholder="Number of times per Referred"
                            onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' maxlength="4"
                            [ngClass]="{ 'is-invalid': submitted && f.referred_max_coupon_episode_count.errors }"
                            class="form-control form-control-sm" (change)="onChngMUC('rd')">
                        <div *ngIf="submitted && f.referred_max_coupon_episode_count.errors" class="invalid-feedback">
                            <div *ngIf="f.referred_max_coupon_episode_count.errors.required">Number of times per Referred is required</div>
                            <div *ngIf="f.referred_max_coupon_episode_count.errors.min">Minimum Number of times per Referred is 1</div>
                            <div *ngIf="f.referred_max_coupon_episode_count.errors.max">Maximum Number of times per Referred is {{maxNOTRD}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Description <span class="text-danger">*</span></label>
                        <div>
                            <textarea (focus)="dp1.hide()" formControlName="referred_discription" id="referred_discription"
                                [ngClass]="{ 'is-invalid': submitted && f.referred_discription.errors }" placeholder="Description"
                                class="form-control form-control-sm" rows="3" maxlength="500"></textarea>
                                <!-- onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57)||
                                (event.charCode == 32 || event.charCode == 46) ||(event.charCode == 46) || (event.charCode == 44))' -->
                            <div *ngIf="submitted && f.referred_discription.errors" class="invalid-feedback">
                                <div *ngIf="f.referred_discription.errors.required">Description is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="rfrlForm.get('enable_referree').value">
                <div class="col-xl-12"><h4>To Referree</h4></div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Referree Coupon Name <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="referree_coupon"
                                [ngClass]="{ 'is-invalid': submitted && f.referree_coupon.errors }"
                                id="referree_coupon" maxlength="25" placeholder="Referree Coupon Name"
                                oncopy="return false" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 &&
                                    event.charCode <= 57))' class="form-control form-control-sm text-uppercase">
                            <div *ngIf="submitted && f.referree_coupon.errors" class="invalid-feedback">
                                <div *ngIf="f.referree_coupon.errors.required">Referree Coupon Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Apply To <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="referree_applied_type" id="referree_applied_type"
                                [ngClass]="{ 'is-invalid': submitted && f.referree_applied_type.errors }"
                                class="form-control form-control-sm" disabled>
                                <option value="">Select</option>
                                <option value="Consultation">Consultation</option>
                                <!-- <option value="Lab">Lab</option>
                                <option value="Pharmacy">Pharmacy</option> -->
                            </select>
                            <div *ngIf="submitted && f.referree_applied_type.errors" class="invalid-feedback">
                                <div *ngIf="f.referree_applied_type.errors.required">Apply To is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Discount Type <span class="text-danger">*</span></label>
                        <div>
                            <select formControlName="is_referree_absolute" id="is_referree_absolute"
                                [ngClass]="{ 'is-invalid': submitted && f.is_referree_absolute.errors }"
                                class="form-control form-control-sm" (change)="onChngDT('re')" #RfreDT>
                                <option value="">Select</option>
                                <option value="true">Flat(Rate)</option>
                                <option value="false">Percentage</option>
                            </select>
                            <div *ngIf="submitted && f.is_referree_absolute.errors" class="invalid-feedback">
                                <div *ngIf="f.is_referree_absolute.errors.required">Discount Type is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Discount Rate<span class="text-danger" *ngIf="RfreDT.value=='true'">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="referree_discount_absolute_amt"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                [ngClass]="{ 'is-invalid': submitted && f.referree_discount_absolute_amt.errors }"
                                class="form-control form-control-sm" id="referree_discount_absolute_amt"
                                [attr.disabled]="(RfreDT.value=='true')?null:true" placeholder="Discount Rate"
                                (change)="onDiscPerChng($event.target.value)" maxlength="4">
                            <div *ngIf="submitted && f.referree_discount_absolute_amt.errors" class="invalid-feedback">
                                <div *ngIf="f.referree_discount_absolute_amt.errors.required">Discount Rate is required</div>
                                <div *ngIf="f.referree_discount_absolute_amt.errors.min">Minimum Discount Rate is 100</div>
                                <div *ngIf="f.referree_discount_absolute_amt.errors.max">Maximum Discount Rate is 1000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Discount Percentage <span class="text-danger"
                            *ngIf="RfreDT.value=='false'">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="referree_discount_percentage"
                                id="referree_discount_percentage" placeholder="Discount Percentage"
                                [ngClass]="{ 'is-invalid': submitted && f.referree_discount_percentage.errors }"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' maxlength="3"
                                [attr.disabled]="(RfreDT.value=='false')?null:true" class="form-control form-control-sm">
                            <div *ngIf="submitted && f.referree_discount_percentage.errors" class="invalid-feedback">
                                <div *ngIf="f.referree_discount_percentage.errors.required">Discount Percentage is required</div>
                                <div *ngIf="f.referree_discount_percentage.errors.min">Minimum Discount Percentage is 10</div>
                                <div *ngIf="f.referree_discount_percentage.errors.max">Maximum Discount Percentage is 100</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Maximum Discount Amount <span class="text-danger"
                            *ngIf="RfreDT.value=='false'">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="referree_max_discount_amt"
                                [attr.disabled]="(RfreDT.value=='false')?null:true" id="referree_max_discount_amt"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' maxlength="4"
                                [ngClass]="{ 'is-invalid': submitted && f.referree_max_discount_amt.errors }"
                                class="form-control form-control-sm" placeholder="Maximum Discount Amount">
                            <div *ngIf="submitted && f.referree_max_discount_amt.errors" class="invalid-feedback">
                                <div *ngIf="f.referree_max_discount_amt.errors.required">Maximum Discount Amount is required</div>
                                <div *ngIf="f.referree_max_discount_amt.errors.min">Min. Maximum Discount Amount is 100</div>
                                <div *ngIf="f.referree_max_discount_amt.errors.max">Max. Maximum Discount Amount is 1000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Coupon to generate</label>
                        <div>
                            <div class="custom-control-inline custom-control custom-radio">
                                <input formControlName="apply_coupon_after_first_consultation_of_referree"
                                    [value]="true" class="custom-control-input" type="radio"
                                    id="rfreCpnGen1" name="apply_coupon_after_first_consultation_of_referree">
                                <label class="custom-control-label" for="rfreCpnGen1">After 1st Consult from Referred </label>
                            </div>
                            <div class="custom-control-inline custom-control custom-radio">
                                <input formControlName="apply_coupon_after_first_consultation_of_referree"
                                    [value]="false" class="custom-control-input" type="radio"
                                    id="rfreCpnGen2" name="apply_coupon_after_first_consultation_of_referree">
                                <label class="custom-control-label" for="rfreCpnGen2">Before 1st Consult from Referred </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Maximum Utilisation Count<span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="referree_max_utilization_count"
                                id="referree_max_utilization_count" (change)="onChngMUC('re')" maxlength="4"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                [ngClass]="{ 'is-invalid': submitted && f.referree_max_utilization_count.errors }"
                                class="form-control form-control-sm" placeholder="Maximum Utilisation Count" /> <!-- (keyup)="onKeyUp('re')" -->
                            <div *ngIf="submitted && f.referree_max_utilization_count.errors" class="invalid-feedback">
                                <div *ngIf="f.referree_max_utilization_count.errors.required">Maximum Utilisation Count is required</div>
                                <div *ngIf="f.referree_max_utilization_count.errors.min">Min. Maximum Utilisation Count is 1</div>
                                <div *ngIf="f.referree_max_utilization_count.errors.max">Max. Maximum Utilisation Count is 1000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Minimum Consultation Amount <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" class="form-control form-control-sm"
                                formControlName="referree_min_consultation_amt" id="referree_min_consultation_amt"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' (focus)="dp.hide()"
                                [ngClass]="{ 'is-invalid': submitted && f.referree_min_consultation_amt.errors }" maxlength="4"
                                placeholder="Minimum Consultation Amount"> <!-- (change)="onChngMCA('re', $event.target.value)" -->
                            <div *ngIf="submitted && f.referree_min_consultation_amt.errors" class="invalid-feedback">
                                <div *ngIf="f.referree_min_consultation_amt.errors.required">Minimum Consultation Amount is required</div>
                                <div *ngIf="f.referree_min_consultation_amt.errors.min">Min. Minimum Consultation Amount is 300</div>
                                <div *ngIf="f.referree_min_consultation_amt.errors.max">Max. Minimum Consultation Amount is 1000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Start Date <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="sdRfre"
                                placeholder="Start Date" bsDatepicker #dp="bsDatepicker" id="sdRfre" (focus)="dp.show()"
                                (focus)="dp1.hide()" [minDate]="minDate" (bsValueChange)="onFromDateChange($event, 're')"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                [ngClass]="{ 'is-invalid': submitted && f.sdRfre.errors }" class="form-control form-control-sm" />
                            <div *ngIf="submitted && f.sdRfre.errors" class="invalid-feedback">
                                <div *ngIf="f.sdRfre.errors.required">Start Date is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">End Date <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="edRfre" id="edRfre" (focus)="dp1.show()"
                                bsDatepicker #dp1="bsDatepicker" [minDate]="minRfrdToDate" placeholder="End Date" (focus)="dp.hide()"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false, adaptivePosition: true,isAnimated: true }"
                                [ngClass]="{ 'is-invalid': submitted && f.edRfre.errors }" class="form-control form-control-sm">
                            <div *ngIf="submitted && f.edRfre.errors" class="invalid-feedback">
                                <div *ngIf="f.edRfre.errors.required">End Date is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <label class="control-label">Number of times per Referree <span class="text-danger">*</span></label>
                    <div>
                        <input type="text" autocomplete="off" formControlName="referree_max_coupon_episode_count"
                            id="referree_max_coupon_episode_count" placeholder="Number of times per Referree"
                            onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' maxlength="4"
                            [ngClass]="{ 'is-invalid': submitted && f.referree_max_coupon_episode_count.errors }"
                            class="form-control form-control-sm" (change)="onChngMUC('re')">
                        <div *ngIf="submitted && f.referree_max_coupon_episode_count.errors" class="invalid-feedback">
                            <div *ngIf="f.referree_max_coupon_episode_count.errors.required">Number of times per Referree is required</div>
                            <div *ngIf="f.referree_max_coupon_episode_count.errors.min">Minimum Number of times per Referree is 1</div>
                            <div *ngIf="f.referree_max_coupon_episode_count.errors.max">Maximum Number of times per Referree is {{maxNOTRE}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Description <span class="text-danger">*</span></label>
                        <div>
                            <textarea (focus)="dp1.hide()" formControlName="referree_discription" id="referree_discription"
                                [ngClass]="{ 'is-invalid': submitted && f.referree_discription.errors }" placeholder="Description"
                                class="form-control form-control-sm" rows="3" maxlength="500"></textarea>
                                <!-- onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57)||
                                (event.charCode == 32 || event.charCode == 46) ||(event.charCode == 46) || (event.charCode == 44))' -->
                            <div *ngIf="submitted && f.referree_discription.errors" class="invalid-feedback">
                                <div *ngIf="f.referree_discription.errors.required">Description is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <input type="submit" id="btnsubmit" style="display: none;">
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" (click)="submitForm()" value="Submit"
        *ngIf="rfrlForm.get('enable_referred').value || rfrlForm.get('enable_referree').value">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>