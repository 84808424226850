import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Common } from 'src/app/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import * as XLSX from 'xlsx';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
declare let $: any;
@Component({
  selector: 'app-attendance-report',
  templateUrl: './attendance-report.component.html',
  styleUrls: ['./attendance-report.component.css']
})
export class AttendanceReportComponent implements OnInit {
  attendanceForm: FormGroup;
  start_date: any = new Date();
  end_date: any = new Date();
  max_date = new Date();
  attendancelist = []; minDate: Date; minToDate: Date; docId = '';
  submitted = false; userNames = [];
  userList: Array<any> = [];
  showExport: boolean = true;
  fileName = 'Attendance Report.xlsx';
  excelData: any;
  pipe = new DatePipe('en-US');


  constructor(private regService: RegistrationService
    , protected toastr: ToastrService
    , private formBuilder: FormBuilder
    , private datePipe: DatePipe
    , private masterService: MasterService,
    private excelDownloadService:ExcelDownloadService
    , private authSvc: AuthenticationService) {
    // this.minDate = new Date();
    // this.minDate.setDate(this.minDate.getDate());

  }

  ngOnInit(): void {
    this.initFormControls(), this.getallUsers();
    this.attendancelist = [];
    Common.loadDataTable(this.attendancelist, '#attendanceReport');
  }

  initFormControls() {
    this.attendanceForm = this.formBuilder.group({
      start_date: [new Date(), Validators.required],
      end_date: [new Date(), Validators.required],
      user_type: ['', Validators.required],
      user_id: [null]
    });
  }

  get f() { return this.attendanceForm.controls; }

  getallUsers() {
    this.regService.getallUserTypes().subscribe(res => {
      if (res.status == 'OK') {
        this.userList = res.data
      } else {
        this.userList = [];
        this.toastr.error(res.err)
      }
    })
  }
  onUserChange(e: any) {
    this.userNames = [];
    this.attendanceForm.patchValue({'user_id':null});
    this.getAllUserNames();
  }
  getAllUserNames() {
    let UserType = this.attendanceForm.value.user_type;
    this.regService.getAllUsers(UserType).subscribe(res => {
      if (res.status == 'OK') {
        if(res.data.length > 0){
          this.userNames = res.data;
        } 
        else {
          this.userNames = [];
          this.toastr.info('No Records Found');
        }
      } 
    })
  }
  LoadData() {
    let sendData = JSON.parse(JSON.stringify(this.attendanceForm.value));
    [sendData.start_date, sendData.end_date] =
      [this.datePipe.transform(sendData.start_date, 'yyyy-MM-dd '),
      this.datePipe.transform(sendData.end_date, 'yyyy-MM-dd ')];
    if (this.attendanceForm.value.user_type == "") {
      this.toastr.error('Please Select UserType');
      return;
    }
    this.regService.getattendanceReport(sendData).subscribe(res => {
      if (res.data == null || res.data.length == 0) {
        this.attendancelist = [];
        this.toastr.info('No records available', 'Attendance Reports List');
        Common.loadDataTable(this.attendancelist, '#attendanceReport');
        this.showExport = false;
      }
      else {
        this.attendancelist = res.data;
        this.excelData = this.attendancelist;
        Common.loadDataTable(this.attendancelist, '#attendanceReport');
        this.showExport = true;
      }
    });
  }

  onFromDateChange(val: Date) {
    if (val) {
      let start_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.attendanceForm.get('end_date').setValue(new Date(start_date.setDate(start_date.getDate())));
      this.minDate = start_date;
    }
  }
  //new line added for on To date change 11-04-2022
  onToDateChange(val: Date) {
    if (val) {
      let end_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.attendanceForm.get('end_date').setValue(new Date(end_date.setDate(end_date.getDate())));
      //this.minDate = end_date;
    }
  }

  clear() {
    this.initFormControls();
    this.attendancelist = [];
    Common.loadDataTable(this.attendancelist, '#attendanceReport');
  }
  exportexcel(): void {

    var table = $('#attendanceReport').DataTable();

    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {
      / table id is passed over here /
      let element = document.getElementById('attendanceReport');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);


      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };


      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
  }
  downLoadExcel(){
    const fileName = 'Attendance_Report'
      var excelData = this.excelData;
      if(excelData.length>0){
        excelData.map(res =>{
          res.formated_attendance_date = this.pipe.transform(res.attendance_date, 'dd-MM-yyyy');
        })
        const excelHeaders = ['User Name', 'Mobile','Email','Attendance Date','Start Time','End Time'];
        const internalHeaders = ['user_name', 'mobile','email','formated_attendance_date','start_time','end_time'];
        this.toastr.success('Please wait excel is downloading')
        this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
      }
      else{
        this.toastr.error('No data to download')
      }
    
  }
}
