import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

import { UserService } from 'src/app/shared/services/user.service';
import { Common } from 'src/app/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { environment } from 'src/environments/environment';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-nodal-officer-detail',
  templateUrl: './nodal-officer-detail.component.html',
  styleUrls: ['./nodal-officer-detail.component.css']
})

export class NodalOfficerDetailComponent implements OnInit {

  @Input() title: string;
  @Input() DataEdit: any;
  // @Input() editData: any;
  organizationList: Array<any> = [];
  submitted = false;
  lstGrid: Array<any> = [];
  ndlOfcrForm: FormGroup;
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  mandalList: Array<any> = [];
  cityList: Array<any> = [];
  villageList: Array<any> = [];

  mobPtrn = ''; mobErrMsg = ''; maxLngth = ''; isECLINIC = environment.isNIG;

  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private formBuilder: FormBuilder
    , private authServ: AuthenticationService
    , private userServices: UserService
    , private masterService: MasterService
    , private registrationService: RegistrationService) { }

  ngOnInit(): void {
    this.authServ.mobPtrnSubject.subscribe(s => this.mobPtrn = s), this.authServ.mobErrMsgSubject.subscribe(s => this.mobErrMsg = s),
      this.authServ.maxLngthSubject.subscribe(s => this.maxLngth = s), this.loadTableData(), this.initFormControls();
    // Common.setFocus('orgnization_name');
    // if (this.editData)
    //   this.ndlOfcrForm.patchValue(this.editData);
    const vlgCntrl = this.ndlOfcrForm.get('village_id');
    vlgCntrl.setValue('');
    if (this.isECLINIC)
      vlgCntrl.setValidators(null);
    else
      vlgCntrl.setValidators([Validators.required]);
    vlgCntrl.updateValueAndValidity(), this.locationChanged('', 'state'), this.getAllMasters(), this.LoadHospitals();
  }

  editNodal() {
    if (this.DataEdit) {
      // this.getInvestigations(this.DataEdit.id);
      Promise.all([this.locationChanged(this.DataEdit.state_id, 'district'),
      (this.locationChanged(this.DataEdit.district_id, 'mandal'),
        this.locationChanged(this.DataEdit.mandal_id, 'village'))
      ])
        .then(() => {
          let [data,] = [this.DataEdit, ''];
          this.ndlOfcrForm.patchValue(data);
          this.ndlOfcrForm.patchValue({
            state_id: data.state_id,
            district_id: data.district_id,
            mandal_id: data.mandal_id,
            village_id: data.village_id,
          });
        });
    }
  }

  initFormControls() {
    this.ndlOfcrForm = this.formBuilder.group({
      id: [0],
      hospital_id: ['', Validators.required],
      full_name: ['', Validators.required],
      state_id: ['', Validators.required],
      district_id: ['', Validators.required],
      mandal_id: ['', Validators.required],
      village_id: [''],
      email: ['', Validators.required],
      pincode: ['', Validators.required],
      address: ['', Validators.required],
      mobile: ['', Validators.required],
      is_active: [true],
    });
  }

  get f() { return this.ndlOfcrForm.controls; }

  onSubmit(event: any) {
    this.submitted = true;
    Common.getFormValidationErrors(this.ndlOfcrForm);
    if (this.ndlOfcrForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else {
      // const postData = JSON.parse(JSON.stringify(this.ndlOfcrForm.getRawValue()));
      this.registrationService.save('nodalofficer/saveandupdate', this.ndlOfcrForm.getRawValue()).subscribe(res => {
        if (res.status === 'OK') {
          this.toastr.success(res.message, 'Nodal Details');
          this.activeModal.close('changes saved');
        }
        else {

        }
      },
        error => { this.toastr.error(error.message) });
    }
  }

  onKeyNmbr(type: string, val: string) {
    if (val != '') {
      if (+this.maxLngth == 9) {
        if (+val[0] == environment.mobValdValSAGE)
          this.ndlOfcrForm.get(type).setValue('');
      }
      if (+this.maxLngth == 10) {
        if (+val[0] < environment.mobValdValIND)
          this.ndlOfcrForm.get(type).setValue('');
      }
    }
  }

  getAllMasters() {
    const locationType = this.masterService.getAll('getalllocationtypes');
    forkJoin([locationType]).subscribe(
      {
        next: res => {
          res.forEach((list, ind) => {
            if (list.status === 'OK') {
              if (list.data == null || list.data.length == 0)
                this.toastr.info('No records available', 'Nodal Officer Details');
              else {
                const assignList = {
                  '0': () => this.locationTypeList = list.data,
                  // '1': () => this.hospitalList = list.data,
                  // '2': () => this.labList = list.data,
                }
                assignList[ind]();
              }
            }
          });
        },
        error: err => console.error('something wrong occurred: ', err),
        complete: () => {
          this.editNodal();
        }
      });

  }

  LoadHospitals() {
    this.registrationService.getAll('hospital/getallhospitalrecords').subscribe(res => { //hospital/getallhospitalrecords
      if (res.status === "OK") {
        this.lstGrid = res.data;
      }
    });
  }

  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        // this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];

        // this.labForm.get('state_id').setValue('');
        this.ndlOfcrForm.get('district_id').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        // if (+id > 0)
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      },
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
      this.districtList.sort((a,b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error));
  }

  submitForm() {
    document.getElementById('btnsubmit').click();
  }

  loadTableData() {
    this.userServices.getAll('organization/getorganizationrecords').subscribe(res => {  this.organizationList = res.data; });
  }

}