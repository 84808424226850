import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-favorite',
  templateUrl: './favorite.component.html',
  styleUrls: ['./favorite.component.css']
})
export class FavoriteComponent implements OnInit {

  chiefComplaintList: Array<any> = [];
  dropdownSettings_cheifcomplaint = {};

  investigationsList: Array<any> = [];
  dropdownSettings_investigation = {};
  favoriteForm: FormGroup;

  docId: number = 0;

  constructor(
    private registrationService: RegistrationService
    , private toastr: ToastrService
    , private masterService: MasterService
    , private formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    let data = sessionStorage.getItem('DoctorDetails');
    if (data)
      this.docId = JSON.parse(data).id ?? 0;
    this.getAllMaster();
    this.initFormControls()
    this.dropdownSettings_cheifcomplaint = {
      singleSelection: false,
      idField: 'id',
      textField: 'chief_complaint',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: true
    };

    this.dropdownSettings_investigation = {
      singleSelection: false,
      idField: 'id',
      textField: 'investigation',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 10,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.loadExisting();
  }

  initFormControls() {
    this.favoriteForm = this.formBuilder.group({
      id: [0],
      doctor_id: [this.docId],
      chief_complaint: [''],
      investigation: [''],
    })
  }

  getAllMaster() {
    const chiefComplaint = this.masterService.getAll('getallchiefcomplaints');
    const investigations = this.masterService.getAll('getallinvestigations');
    // const diagnosis = this.masterService.getAll('getalldiagnosis');    

    forkJoin([chiefComplaint, investigations]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              this.toastr.info('No records available', 'Favorites');
            else {
              const assignList = {
                '0': () => this.chiefComplaintList = list.data,
                '1': () => this.investigationsList = list.data,
              }
              assignList[ind]();
            }
          }
        });
      },
      error: err => console.error('something wrong occurred: ', err),
      complete: () => { },
    });
  }

  onSubmit(event) {
    this.registrationService.save('doctor/savedoctorpreferredmasterdata', this.favoriteForm.getRawValue())
      .subscribe(res => {
        if (res.status == 'OK') {
          // this.favoriteForm.get('id').Setvalue(res.data.id);
          this.favoriteForm.patchValue({ id: res.data.id });
        }
      }, error => { }, () => { });
  }

  loadExisting() {
    this.registrationService.getAll(`doctor/getdoctorpreferredmasterbyid?id=${this.docId}`)
      .subscribe(res => {
        if (res.status == 'OK') {
          if (res.data)
            this.favoriteForm.patchValue(res.data);
        }
      }, error => { }, () => { });
  }

}