import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Common } from 'src/app/common';
import { BaseComponent } from 'src/app/shared/base.component';
import { MasterService } from 'src/app/shared/services/master.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { DrugsToStatemappingComponent } from '../drugs-to-statemapping/drugs-to-statemapping.component';
import * as XLSX from 'xlsx';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
import { DatePipe } from '@angular/common';
declare let $: any;

@Component({
  selector: 'app-drugs-to-statemapping-list',
  templateUrl: './drugs-to-statemapping-list.component.html',
  styleUrls: ['./drugs-to-statemapping-list.component.css']
})
export class DrugsToStatemappingListComponent extends BaseComponent implements OnInit {
  modalTitle: string = '';DrugState: Array<any> = []; slctCmpny = '';
  params: any; stateList: Array<any> = []; statedrugList:  Array<any> = [];
  fileName = 'Drugs to statemapping list.xlsx';
  excelData: any =[];
  pipe = new DatePipe('en-US');
  constructor(private modalService:NgbModal
    , private MasterService: MasterService,
    private excelDownloadService:ExcelDownloadService
    , private registration: RegistrationService
    , private toastr: ToastrService) {
    super();
  
   }

  ngOnInit(): void {
    this.LoadGrid(); this.getmasters();
  }
  openDrugStateModal() {
    const modalRef:any = this.modalService.open(DrugsToStatemappingComponent, { scrollable: true, size: 'xl' }); 
    modalRef.componentInstance.title= this.modalTitle;
    modalRef.result.then((data) => {
       if(data==='changes saved'){
        this.getmasters();
       }
    },
    (error) => {
      // on error/dismiss
    });
      
 
  }

  getmasters() {
    this.MasterService.getAll('getallstates').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available');
        else
          this.stateList = res.data;
           this.slctCmpny = this.stateList[0].id
           this.getAllstates(this.slctCmpny)
          
      }
    });
  }
  LoadGrid() {
   
    Common.loadDataTable(this.DrugState, '#DrugStateTable');
  }
  getAllstates(stateId: string) {
    $('.dataTable').DataTable().destroy(), $('.dataTable').DataTable({ destroy: true, searching: false });
    if (stateId != '') {
      this.statedrugList = []; 
      this.registration.getdrugsbystate(+stateId).subscribe(res => {
        if (res) {
          if (res.status == "OK") {
            if (res.data == null || res.data.length == 0)
              this.toastr.info('No records available');
            else
             this.statedrugList = res.data;
             this.excelData = this.statedrugList;
          }
        }
        else
        this.statedrugList = []; Common.loadDataTable(this.statedrugList, "#DrugStateTable");
      });
    }
    else
    this.statedrugList = []; Common.loadDataTable(this.statedrugList, "#DrugStateTable");
  }


  exportexcel(): void {

    var table = $('#DrugStateTable').DataTable();

    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {
      / table id is passed over here /
      let element = document.getElementById('DrugStateTable');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };

      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
}

downLoadExcel(){
  const fileName = 'Drug_inventory_state'
    var excelData = this.excelData;
    if(excelData.length>0){
      excelData.map(res =>{
        res.formated_Expiry_date = this.pipe.transform(res.expiry_date, 'dd-MM-yyyy');
        res.formated_manufactured_date =this.pipe.transform(res.manufactured_date, 'dd-MM-yyyy');
      })
      const excelHeaders = ['Drug Type', 'Drug Name','Batch No','QTY' ,'Expiry Date' ,'MFG'];
      const internalHeaders = ['drug_type', 'drug_name','batch_no','current_stock','formated_Expiry_date','formated_manufactured_date'];
      this.toastr.success('Please wait excel is downloading')
      this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
    }
    else{
      this.toastr.error('No data to download')
    }
  
}


}
