<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <!-- <div class="card-header h-auto">
                        Configuration
                        <div>
                            <div class="custom-control custom-checkbox  custom-control-inline">
                                <input class="custom-control-input" id="customCheckSolid1" type="checkbox" [(ngModel)]="uploads">
                                <label class="custom-control-label" for="customCheckSolid1">Upload Logo</label>
                            </div>
                            <div class="custom-control custom-checkbox  custom-control-inline">
                                <input class="custom-control-input" id="customCheckSolid2" type="checkbox" [(ngModel)]="payment">
                                <label class="custom-control-label" for="customCheckSolid2">Payment Gateway</label>
                            </div>
                            <div class="custom-control custom-checkbox  custom-control-inline">
                                <input class="custom-control-input" id="customCheckSolid3" type="checkbox" [(ngModel)]="sms">
                                <label class="custom-control-label" for="customCheckSolid3">SMS</label>
                            </div>
                            <div class="custom-control custom-checkbox  custom-control-inline">
                                <input class="custom-control-input" id="customCheckSolid4" type="checkbox" [(ngModel)]="smtp">
                                <label class="custom-control-label" for="customCheckSolid4">SMTP</label>
                            </div>
                        </div>
                    </div> -->
                    <div class="card-header d-block h-auto">
                        <div class="row">
                            <div class="col-xl-4 col-lg-4"> <span class=""> Configuration</span></div>
                            <div class="col-xl-8 col-lg-8 float-right"> <span class="d-block float-lg-right">
                                    <span class="">
                                        <div class="custom-control custom-checkbox custom-control-inline">
                                            <input class="custom-control-input" id="customCheckSolid1" type="checkbox"
                                                [(ngModel)]="uploads">
                                            <label class="custom-control-label" for="customCheckSolid1">Upload
                                                Logo</label>
                                        </div>
                                        <div class="custom-control custom-checkbox custom-control-inline">
                                            <input class="custom-control-input" id="customCheckSolid2" type="checkbox"
                                                [(ngModel)]="payment">
                                            <label class="custom-control-label" for="customCheckSolid2">Payment
                                                Gateway</label>
                                        </div>
                                        <div class="custom-control custom-checkbox custom-control-inline">
                                            <input class="custom-control-input" id="customCheckSolid3" type="checkbox"
                                                [(ngModel)]="sms">
                                            <label class="custom-control-label" for="customCheckSolid3">SMS</label>
                                        </div>
                                        <div class="custom-control custom-checkbox custom-control-inline">
                                            <input class="custom-control-input" id="customCheckSolid4" type="checkbox"
                                                [(ngModel)]="smtp">
                                            <label class="custom-control-label" for="customCheckSolid4">SMTP</label>
                                        </div>
                                    </span>
                                </span></div>
                        </div>
                        <div class="">
                        </div>
                    </div>
                    <form [formGroup]="uploadForm">
                        <div class="card-body">
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="form-group ">
                                        <label for="inputPassword" class="col-sm-2 col-form-label">Organization
                                            Name<span class="text-danger">*</span>
                                        </label>
                                        <div class="col-sm-3 pt-2">
                                            <select class="form-control form-control-sm"
                                                formControlName="orgnization_id"
                                                [ngClass]="{ 'is-invalid': submitted && f.orgnization_id.errors }"
                                                class="form-control form-control-sm"
                                                (change)="organizationsOnChange($event.target.value)">
                                                <option value="">Select</option>
                                                <option *ngFor="let item of organizationList" [value]="item.id">
                                                    {{item.orgnization_name}}</option>
                                            </select>
                                            <div *ngIf="submitted && f.orgnization_id.errors" class="invalid-feedback">
                                                <div *ngIf="f.orgnization_id.errors.required">Organization is required
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-12">
                                    <div class="accordion" id="accordionExample">
                                        <div class="card" *ngIf="uploads">
                                            <div class="card-header" id="headingOne">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link" type="button" data-toggle="collapse"
                                                        data-target="#collapseOne" aria-expanded="true"
                                                        aria-controls="collapseOne">
                                                        Upload Logo
                                                    </button>
                                                </h2>
                                            </div>

                                            <div id="collapseOne" class="collapse show" aria-labelledby="headingOne"
                                                data-parent="#accordionExample">
                                                <div class="card-body">
                                                    <h3>Web</h3>

                                                    <div class="row">
                                                        <div class="col-xl-4 mb-2">
                                                            <div class="custom-file">
                                                                <input type="file" class="custom-file-input"
                                                                    id="weborganisationlogo" #weborganisationlogo
                                                                    [ngClass]="{ 'is-invalid': submitted && f.weborganisationlogo.errors }"
                                                                    formControlName="weborganisationlogo"
                                                                    (change)="singleFileUpload($event,0,'1', 182, 55)">
                                                                <label class="custom-file-label form-control-sm"
                                                                    for="weborganisationlogo">Upload logo
                                                                </label>
                                                                <span class="text-primary small">Dimensions should be
                                                                    182(w) * 55(h)</span>&nbsp;
                                                                <div> <span> <a href="javascript:void(0)"
                                                                            class="fa fa-eye" title="Upload File"
                                                                            *ngIf="uploadFileList[0].File"
                                                                            (click)="viewFile(1)">
                                                                            <i class=""></i></a>
                                                                    </span></div>

                                                                <div *ngIf="submitted && f.weborganisationlogo.errors"
                                                                    class="invalid-feedback">
                                                                    <div *ngIf="f.weborganisationlogo.errors.required">
                                                                        Upload logo is required
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 mb-2">
                                                            <div class="custom-file">
                                                                <input type="file" class="custom-file-input"
                                                                    id="webwatermarklogo" #webwatermarklogo
                                                                    [ngClass]="{ 'is-invalid': submitted && f.weborganisationlogo.errors }"
                                                                    formControlName="webwatermarklogo"
                                                                    (change)="singleFileUpload($event,1,'2', 300, 80)">
                                                                <label class="custom-file-label form-control-sm"
                                                                    for="webwatermarklogo">Upload Watermark</label>
                                                                <span class="text-primary small">Dimensions should be
                                                                    300(w) * 80(h)</span>&nbsp;
                                                                <div>
                                                                    <span>
                                                                        <a href="javascript:void(0)" class="fa fa-eye"
                                                                            title="Upload File"
                                                                            *ngIf="uploadFileList[1].File"
                                                                            (click)="viewFile(2)">
                                                                            <i class=""></i>
                                                                        </a>
                                                                    </span>
                                                                </div>
                                                                <div *ngIf="submitted && f.webwatermarklogo.errors"
                                                                    class="invalid-feedback">
                                                                    <div *ngIf="f.webwatermarklogo.errors.required">
                                                                        Upload Watermark is required</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 mb-2">
                                                            <div class="custom-file">
                                                                <input type="file" class="custom-file-input"
                                                                    id="webheaderlogo" #webheaderlogo
                                                                    [ngClass]="{ 'is-invalid': submitted && f.webheaderlogo.errors }"
                                                                    formControlName="webheaderlogo"
                                                                    (change)="singleFileUpload($event,2,'3',1366,768)">
                                                                <label class="custom-file-label form-control-sm"
                                                                    for="webheaderlogo">Upload Banner</label>
                                                                <span class="text-primary small">Dimensions should be
                                                                    1366(w) * 768(h)</span>
                                                                &nbsp;
                                                                <span> <a href="javascript:void(0)" class="fa fa-eye"
                                                                        title="Upload File"
                                                                        *ngIf="uploadFileList[2].File"
                                                                        (click)="viewFile(3)">
                                                                        <i class=""></i></a>
                                                                </span>
                                                                <div *ngIf="submitted && f.webheaderlogo.errors"
                                                                    class="invalid-feedback">
                                                                    <div *ngIf="f.webheaderlogo.errors.required">Upload
                                                                        Banner is required</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 mb-2">
                                                            <div class="custom-file">
                                                                <input type="file" class="custom-file-input"
                                                                    id="webprescriptionlogo" #webprescriptionlogo
                                                                    [ngClass]="{ 'is-invalid': submitted && f.webprescriptionlogo.errors }"
                                                                    formControlName="webprescriptionlogo"
                                                                    (change)="singleFileUpload($event,3,'4',200,53)">
                                                                <label class="custom-file-label form-control-sm"
                                                                    for="webprescriptionlogo">Prescription
                                                                </label>
                                                                <span class="text-primary small">Dimensions should be
                                                                    200(w) * 53(h)</span>&nbsp;
                                                                <span> <a href="javascript:void(0)" class="fa fa-eye"
                                                                        title="Upload File"
                                                                        *ngIf="uploadFileList[3].File"
                                                                        (click)="viewFile(4)">
                                                                        <i class=""></i></a>
                                                                </span>
                                                                <div *ngIf="submitted && f.webprescriptionlogo.errors"
                                                                    class="invalid-feedback">
                                                                    <div *ngIf="f.webprescriptionlogo.errors.required">
                                                                        prescription is required</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 mb-2">
                                                            <div class="custom-file">
                                                                <input type="file" class="custom-file-input"
                                                                    id="webloginlogo" #webloginlogo
                                                                    [ngClass]="{ 'is-invalid': submitted && f.webloginlogo.errors }"
                                                                    formControlName="webloginlogo"
                                                                    (change)="singleFileUpload($event,4,'5',300,80)">
                                                                <label class="custom-file-label form-control-sm"
                                                                    for="webloginlogo">Background
                                                                </label>
                                                                <span class="text-primary small">Dimensions should be
                                                                    300(w) * 80(h)</span>
                                                                &nbsp;
                                                                <span> <a href="javascript:void(0)" class="fa fa-eye"
                                                                        title="Upload File"
                                                                        *ngIf="uploadFileList[4].File"
                                                                        (click)="viewFile(5)">
                                                                        <i class=""></i></a>
                                                                </span>
                                                                <div *ngIf="submitted && f.webloginlogo.errors"
                                                                    class="invalid-feedback">
                                                                    <div *ngIf="f.webloginlogo.errors.required">
                                                                        Background is required</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <h3>Mobile</h3>

                                                    <div class="row">
                                                        <div class="col-xl-4 mb-2">
                                                            <div class="custom-file">
                                                                <input type="file" class="custom-file-input"
                                                                    id="mobileapplogo" #mobileapplogo
                                                                    [ngClass]="{ 'is-invalid': submitted && f.mobileapplogo.errors }"
                                                                    formControlName="mobileapplogo"
                                                                    (change)="singleFileUpload($event,5,'6',1024,1024)">
                                                                <label class="custom-file-label form-control-sm"
                                                                    for="mobileapplogo">App Logo
                                                                </label>
                                                                <span class="text-primary small">Dimensions should be
                                                                    1024(w) * 1024(h)</span> &nbsp;
                                                                <span> <a href="javascript:void(0)" class="fa fa-eye"
                                                                        title="Upload File"
                                                                        *ngIf="uploadFileList[5].File"
                                                                        (click)="viewFile(6)">
                                                                        <i class=""></i></a>
                                                                </span>
                                                                <div *ngIf="submitted && f.mobileapplogo.errors"
                                                                    class="invalid-feedback">
                                                                    <div *ngIf="f.mobileapplogo.errors.required">App
                                                                        Logo is required</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 mb-2">
                                                            <div class="custom-file">
                                                                <input type="file" class="custom-file-input"
                                                                    id="mobileorganisationbanner"
                                                                    #mobileorganisationbanner
                                                                    [ngClass]="{ 'is-invalid': submitted && f.mobileorganisationbanner.errors }"
                                                                    formControlName="mobileorganisationbanner"
                                                                    (change)="singleFileUpload($event,6,'7',445,115)">
                                                                <label class="custom-file-label form-control-sm"
                                                                    for="mobileorganisationbanne r">Organization Banner
                                                                </label>
                                                                <span class="text-primary small">Dimensions should be
                                                                    445(w) * 115(h)</span>&nbsp;
                                                                <span> <a href="javascript:void(0)" class="fa fa-eye"
                                                                        title="Upload File"
                                                                        *ngIf="uploadFileList[6].File"
                                                                        (click)="viewFile(7)">
                                                                        <i class=""></i></a>
                                                                </span>
                                                                <div *ngIf="submitted && f.mobileorganisationbanner.errors"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="f.mobileorganisationbanner.errors.required">
                                                                        Organization Banner is required</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 mb-2">
                                                            <div class="custom-file">
                                                                <input type="file" class="custom-file-input"
                                                                    id="mobilebackgroundimage" #mobilebackgroundimage
                                                                    [ngClass]="{ 'is-invalid': submitted && f.mobilebackgroundimage.errors }"
                                                                    formControlName="mobilebackgroundimage"
                                                                    (change)="singleFileUpload($event,7,'8',800,1496)">
                                                                <label class="custom-file-label form-control-sm"
                                                                    for="mobilebackgroundimage">Background Image</label>
                                                                <span class="text-primary small">Dimensions should be
                                                                    800(w) * 1496(h)</span> &nbsp;
                                                                <span> <a href="javascript:void(0)" class="fa fa-eye"
                                                                        title="Upload File"
                                                                        *ngIf="uploadFileList[7].File"
                                                                        (click)="viewFile(8)">
                                                                        <i class=""></i></a>
                                                                </span>
                                                                <div *ngIf="submitted && f.mobilebackgroundimage.errors"
                                                                    class="invalid-feedback">
                                                                    <div
                                                                        *ngIf="f.mobilebackgroundimage.errors.required">
                                                                        Background Image is required</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4 mb-2">
                                                            <div class="custom-file">
                                                                <input type="file" class="custom-file-input"
                                                                    id="mobilelogoimage" #mobilelogoimage
                                                                    [ngClass]="{ 'is-invalid': submitted && f.mobilelogoimage.errors }"
                                                                    formControlName="mobilelogoimage"
                                                                    (change)="singleFileUpload($event,8,'9',800,1496)">
                                                                <label class="custom-file-label form-control-sm"
                                                                    for="mobilelogoimage">Login Image</label>
                                                                <span class="text-primary small"> Dimensions should be
                                                                    800(w) * 1496(h)</span>&nbsp;
                                                                <span> <a href="javascript:void(0)" class="fa fa-eye"
                                                                        title="Upload File"
                                                                        *ngIf="uploadFileList[8].File"
                                                                        (click)="viewFile(9)">
                                                                        <i class=""></i></a>
                                                                </span>
                                                                <div *ngIf="submitted && f.mobilelogoimage.errors"
                                                                    class="invalid-feedback">
                                                                    <div *ngIf="f.mobilelogoimage.errors.required">Login
                                                                        Image is required</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                </div>
                                            </div>
                                        </div>


                                        <div class="card" *ngIf="payment">
                                            <div class="card-header" id="headingTwo">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link collapsed" type="button"
                                                        data-toggle="collapse" data-target="#collapseTwo"
                                                        aria-expanded="false" aria-controls="collapseTwo">
                                                        Payment Gateway
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo"
                                                data-parent="#accordionExample">
                                                <div class="card-body">


                                                    <div class="row small">
                                                        <div class="col-xl-12 videoWrapper">
                                                            <iframe src="https://txncdn.payubiz.in/login"
                                                                style="border:0px !important; "></iframe>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="card" *ngIf="sms">
                                            <div class="card-header" id="headingThree">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link collapsed" type="button"
                                                        data-toggle="collapse" data-target="#collapseThree"
                                                        aria-expanded="false" aria-controls="collapseThree">
                                                        SMS
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapseThree" class="collapse" aria-labelledby="headingThree"
                                                data-parent="#accordionExample" formGroupName="sms_gateway">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-xl-6">
                                                            <div class="form-group">
                                                                <label for="inputPassword"
                                                                    class="col-form-label">User</label>
                                                                <div class="">
                                                                    <input type="text" formControlName="user"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6">
                                                            <div class="form-group">
                                                                <label for="inputPassword"
                                                                    class="col-form-label">Password</label>
                                                                <div class="">
                                                                    <input type="text" formControlName="password"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6">
                                                            <div class="form-group">
                                                                <label for="inputPassword" class="col-form-label">Sender
                                                                    Id
                                                                </label>
                                                                <div class="">
                                                                    <input type="text" formControlName="senderid"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6">
                                                            <div class="form-group">
                                                                <label for="inputPassword" class="col-form-label">Secure
                                                                    Key
                                                                </label>
                                                                <div class="">
                                                                    <input type="text" formControlName="securekey"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-6">
                                                            <div class="form-group">
                                                                <label for="inputPassword" class="col-form-label">SMS
                                                                    Gateway
                                                                </label>
                                                                <div class="">
                                                                    <input type="text" formControlName="gateway"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card" *ngIf="smtp">
                                            <div class="card-header" id="headingfour">
                                                <h2 class="mb-0">
                                                    <button class="btn btn-link collapsed" type="button"
                                                        data-toggle="collapse" data-target="#collapsefour"
                                                        aria-expanded="false" aria-controls="collapsefour">
                                                        SMTP
                                                    </button>
                                                </h2>
                                            </div>
                                            <div id="collapsefour" class="collapse" aria-labelledby="headingfour"
                                                data-parent="#accordionExample" formGroupName="smtp_gateway">
                                                <div class="card-body">
                                                    <div class="row">
                                                        <div class="col-xl-4">
                                                            <div class="form-group">
                                                                <label for="inputPassword" class="col-form-label">SMTP
                                                                    Host</label>
                                                                <div class="">
                                                                    <input type="text" formControlName="host"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4">
                                                            <div class="form-group">
                                                                <label for="inputPassword" class="col-form-label">SMTP
                                                                    Port</label>
                                                                <div class="">
                                                                    <input type="text" formControlName="port"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4">
                                                            <div class="form-group">
                                                                <label for="inputPassword" class="col-form-label">SMTP
                                                                    Username</label>
                                                                <div class="">
                                                                    <input type="text" formControlName="username"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4">
                                                            <div class="form-group">
                                                                <label for="inputPassword" class="col-form-label">SMTP
                                                                    Password</label>
                                                                <div class="">
                                                                    <input type="Password" formControlName="password"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-xl-4">
                                                            <div class="form-group">
                                                                <label for="inputPassword" class="col-form-label">SMTP
                                                                    Encryption</label>
                                                                <div class="">
                                                                    <input type="text" formControlName="encryption"
                                                                        class="form-control form-control-sm">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-xl-10 offset-xl-1">
                                    <div class="row">
                                        <div class="col-xl-12 text-center">
                                            <a class="btn btn-sm btn-success text-white pl-4 pr-4" type="submit"
                                                (click)="onSubmit()"
                                                *ngIf="setPrivilege(Screen_Name.RoleManagement, Permission.Add)">Save</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>
<!-- Modal -->