<div class="row">
  <div class="container-fluid pl-0 pr-0">
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-header-actions mx-auto">
          <div class="card-header">
            Centre Registration
            <button (click)="modalTitle='Patient Registration Details'; openPatientModal()"
              class="btn btn-blue btn-icon float-right"><i class="fa fa-plus"></i> <!-- openModal() -->
            </button>
          </div>
          <div class="card-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col-xl-12">
                  <form [formGroup]="cntrLstForm">
                    <div class="row mt-1">
                      <!-- <div class="col-xl-2">
                                              <label>State <span class="text-danger"><b>*</b></span> </label>
                                              <select class="form-control form-control-sm" id="state" formControlName="state_code"
                                                 >
                                                  <option value=0>Select</option>
                                              </select>
                                          </div> -->
                      <div class="col-md">
                        <div class="form-group">
                          <label class="control-label">Location Type </label>
                          <div>
                            <select formControlName="location_type_id" id="location_type_id" #locationId
                              (change)="locationChanged('','location')"
                              [ngClass]="{ 'is-invalid': submitted && f.location_type_id.errors }"
                              class="form-control form-control-sm">
                              <option value="0">Select</option>
                              <option *ngFor="let location of locationTypeList" [value]="location.id">
                                {{location.location_type}}</option>
                            </select>
                            <div *ngIf="submitted && f.location_type_id.errors" class="invalid-feedback">
                              <div *ngIf="f.location_type_id.errors.required">Location Type is required</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md">
                        <div class="form-group">
                          <!-- below line commented on 19-04-2022 -->
                          <!-- <label class="control-label">{{isNIG ? 'States' : 'State'}} <span class="text-danger">*</span> </label> -->
                          <label class="control-label">{{isNIG ? 'States' : 'State'}} </label>

                          <div>
                            <select formControlName="state_id" id="state_id" #State
                              (change)="locationChanged(State.value,'district')"
                              [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }"
                              class="form-control form-control-sm" disabled="true">
                              <option value="0">Select</option>
                              <option *ngFor="let state of stateList" [value]="state.id">
                                {{state.state_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                              <div *ngIf="f.state_id.errors.required">
                                {{isNIG ? 'States' : 'State'}} is required
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-xl-2">
                                              <label>District <span class="text-danger"><b>*</b></span> </label>
                                              <select class="form-control form-control-sm" id="dist" formControlName="district_code"
                                                 >
                                                  <option value=0>Select</option>
                                              </select>
                                          </div> -->
                      <div class="col-md">
                        <div class="form-group">
                          <!-- below line commented on 19-04-2022 -->
                          <!-- <label class="control-label">{{isNIG ? 'State Capitals' : 'District'}} <span class="text-danger">*</span> -->
                          <label class="control-label">{{isNIG ? 'State Capitals' : 'District'}}

                          </label>
                          <div>
                            <select formControlName="district_id" id="district_id" #District
                              (change)="locationChanged(District.value,(+locationId.value==2)?'city':'mandal')"
                              [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }"
                              class="form-control form-control-sm">
                              <option value="0">Select</option>
                              <option *ngFor="let district of districtList" [value]="district.id">
                                {{district.district_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                              <div *ngIf="f.district_id.errors.required">
                                {{isNIG ? 'State Capitals' : 'District'}} is required
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-xl-2">
                                              <label>Location Type <span class="text-danger"><b>*</b></span> </label>
                                              <select class="form-control form-control-sm" id="location" formControlName="locationtypeid" 
                                          >
                                                  <option value=0>Select</option>
                                                
                                              </select>
                                          </div> -->

                      <!-- <div class="col-xl-2">
                                              <label>Block / City</label> 
                                              <select class="form-control form-control-sm" id="blck" formControlName="block_code"
                                               >
                                                  <option value=0>Select</option>
                                                 
                                              </select> 
                                          </div> -->
                      <!-- <div class="col-xl-2">
                                             
                                              <div class="form-group">
                                             
                                                <label class="control-label">Block / City</label>
                                                <div>
                                                  <select formControlName="mandal_id" id="mandal_id" #Mandal
                                                    (change)="locationChanged(Mandal.value,'village')"
                                                    [ngClass]="{ 'is-invalid': submitted && f.mandal_id.errors }" class="form-control form-control-sm">
                                                    <option value="0">Select</option>
                                                    <option *ngFor="let mandal of mandalList" [value]="mandal.id">
                                                      {{mandal.mandal_name}}</option>
                                                  </select>
                                                  <div *ngIf="submitted && f.mandal_id.errors" class="invalid-feedback">
                                                    <div *ngIf="f.mandal_id.errors.required">
                                                      {{isNIG ? 'Local Government Areas ' : 'Mandal '}} is required
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div> -->
                      <div class="col-md-3" style="padding-top: 30px;">
                        <button type="submit" class="btn btn-primary btn-sm text-center"
                          (click)="onSearch()">Search</button>
                        <button type="submit" class="btn btn-warning btn-sm ml-2 text-center"
                          (click)="onReset()">Cancel</button>
                          <button type="button" class="btn btn-secondary btn-sm ml-2 text-center" *ngIf="excelData.length>0"
                            (click)="downLoadExcel()"> <i class="fa fa-file-excel-o"></i>Export</button> 
                      </div>
                      <div class="col-xl-1 text-right" style="padding-top: 2%;">
                        <!-- <button type="submit" (click)="onAddEdit()" class="btn btn-info mb-2 mr-1 text-center"><i class="fa fa-plus"></i> Add</button> -->
                        <!-- <button type="button" class="btn btn-secondary mb-2 mr-1 text-center" *ngIf="excelData.length>0"
                          (click)="downLoadExcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp;
                        &nbsp; -->
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table datatable id="centerregTable" class="table table-striped table-bordered table-sm">
                <thead>
                  <tr style="background-color: lightskyblue">
                    <th class="text-center" style="font-size:15px;">S.No</th>
                    <!-- <th style="font-size:15px;">Arogya Kendra Code</th> -->
                    <th style="font-size:15px;">State</th>
                    <th style="font-size:15px;">District</th>
                    <th style="font-size:15px;">Centre Name</th>
                   
                    <th style="font-size:15px;">Incharge Name</th>
                    <th style="font-size:15px;">Incharge Mobile</th>
                    <th style="font-size:15px;">Actions</th>
                    <!-- <th style="font-size:15px;">Status</th>
                                      <th style="font-size:15px;">Actions</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of getAllcenters; let i = index">
                    <td>{{i+1}}</td>
                    <td>{{item.stateName}}</td>
                    <td>{{item.districtName}}</td>
                    <td>{{item.center_name}}</td>
                   
                    <td>{{item.incharge_name}}</td>
                    <td>{{item.incharge_mobile_no}}</td>
                    <!-- <td>
                                       
                                          <div *ngFor="let itemv of item.centermapping; let i = index">
                                              {{itemv.paymentType}} / {{itemv.payment_per_head}}
                                          </div>
                                         
                                      </td> -->


                    <!-- <td>{{item.is_active == true ? 'Active' : 'InActive'}}</td> -->

                    <td class="text-center">
                      <span>
                        <a class="pointer" style="cursor: pointer;" (click)="onAddEdit(item)" title="Edit"> <i
                            class="fa fa-edit text-primary"></i></a>
                      </span>&nbsp;&nbsp;
                      <!-- <div class="badge badge-danger badge-pill pointer" title="Delete" (click)="deleteItem(item.id)">
                                          <a><i class="fa fa-trash text-white"></i></a></div> -->
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>