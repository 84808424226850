<div class="modal-header">
    <h4 class="modal-title">Doctor Modules</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss('Cross click')"> <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row mt-2">
        <div class="col-md-6">
            <label for="textName">Template Name</label>
            <input type="text" class="form-control" id="textName" [(ngModel)]="tmplName">
        </div>
        <div class="col-md-5">
            <label>POC Options</label>
            <select class="form-control" (change)="onChngPO($event.target.value)" [(ngModel)]="pocOptns">
                <option value="">Select</option>
                <option *ngFor="let item of pocOptionsData" [value]="item.id">{{item.module_type}}</option>
                <!-- <option value="">Select</option>
                <option value="cc">Chief Complaints</option>
                <option value="pd">Provisional Diagnosis </option>
                <option value="ig">Investigations</option>
                <option value="mc">Medications</option> -->
            </select>
        </div>
    </div>
    <div class="row mt-5" *ngIf="pocOptns!=''">
        <div class="col-sm-6">
            <h4>{{(pocOptns == '1' ? 'Chief Complaints' : (pocOptns == '2' ? 'Provisional Diagnosis' : (pocOptns ==
                '3' ? 'Investigations' : 'Drug Type')))}}</h4>
        </div>
        <div class="col-sm-6" *ngIf="lstChsn.length != 0">
            <h4>Selected Items</h4>
        </div>
    </div>
    <div class="row mt-2" *ngIf="pocOptns!=''">
        <div class="col-sm-6">
            <div class="card">
                <div class="card-body">
                    <div class="scroll">
                        <div *ngFor="let m of lstMstr">
                            <p>
                                <input type="checkbox" (change)="onChkMstr($event.target.checked, m)"
                                    [checked]="m.isChkd">&nbsp;<b>{{m.name}}</b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <button type="submit" class="btn btn-primary btn-sm mt-2" (click)="onAdd()"
                [attr.disabled]="chsnCnt == 0 ? true : null">
                Add<i class="fa fa-arrow-right ml-2" aria-hidden="true"></i>
            </button>
        </div>
        <div class="col-sm-6" *ngIf="lstChsn.length != 0">
            <div class="card">
                <div class="card-body">
                    <div class="scroll">
                        <div *ngFor="let c of lstChsn">
                            <h5 (click)="onChsnSlct(c, $event)">{{c.name}}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn btn-primary btn-sm mt-2" (click)="onDel()"
                [attr.disabled]="lstDel.length == 0 ? true : null">
                <i class="fa fa-arrow-left mr-2" aria-hidden="true"></i>Remove
            </button>
            <button class="btn ml-2 mt-2 uparcolor" (click)="onSrtng('a')" title="Ascending">
                <i class="fa fa-arrow-up upcolor1" aria-hidden="true"></i></button>
            <button class="btn ml-2 mt-2 uparcolor" (click)="onSrtng('d')" title="Descending">
                <i class="fa fa-arrow-down upcolor1" aria-hidden="true"></i></button>
        </div>
    </div>
    <div class="row mt-2" *ngIf="pocOptns == '4'">
        <div class="col-sm-6 mt-3" *ngIf="lstChsn.length != 0">
            <select class="form-control" [(ngModel)]="drgTyp" (change)="onChngDT($event.target.value)">
                <option value="">Select</option>
                <option [value]="c.id" *ngFor="let c of lstChsn">{{c.name}}</option>
            </select>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-sm-6 mt-2" *ngIf="lstDN.length != 0">
            <h4>Drug Name</h4>
        </div>
        <div class="col-sm-6 mt-2" *ngIf="lstChsnDN.length != 0">
            <h4>Selected Items</h4>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-6 mt-2" *ngIf="lstDN.length != 0">
            <div class="card">
                <div class="card-body">
                    <div class="scroll">
                        <div *ngFor="let dn of lstDN">
                            <p>
                                <input type="checkbox" (change)="onChkDN($event.target.checked, dn)"
                                    [checked]="dn.isChkd">&nbsp;<b>{{dn.name}}</b>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <button type="submit" class="btn btn-primary btn-sm mt-2" (click)="onDNAdd()"
                [attr.disabled]="dnCnt == 0 ? true : null">
                Add<i class="fa fa-arrow-right ml-2" aria-hidden="true"></i>
            </button>
        </div>
        <div class="col-sm-6 mt-2" *ngIf="lstChsnDN.length != 0">
            <div class="card">
                <div class="card-body">
                    <div class="scroll">
                        <div *ngFor="let c of lstChsnDN">
                            <h5 (click)="onChsnDNSlct(c, $event)">{{c.name}}</h5>
                        </div>
                    </div>
                </div>
            </div>
            <button class="btn btn-primary btn-sm mt-2" (click)="onDelDN()"
                [attr.disabled]="lstDelDN.length == 0 ? true : null">
                <i class="fa fa-arrow-left mr-2" aria-hidden="true"></i>Remove
            </button>
            <button class="btn ml-2 mt-2 uparcolor" (click)="onSrtngDN('a')" title="Ascending">
                <i class="fa fa-arrow-up upcolor1" aria-hidden="true"></i></button>
            <button class="btn ml-2 mt-2 uparcolor" (click)="onSrtngDN('d')" title="Descending">
                <i class="fa fa-arrow-down upcolor1" aria-hidden="true"></i></button>
        </div>
    </div>
</div>
<div class="modal-footer">
    <!-- <input type="submit" [hidden]="!isShow" class="btn btn-sm btn-primary" value="Submit"
        [disabled]="tmplName == '' || pocOptns == '' || lstChsn.length == 0 || lstChsnDN.length == 0" (click)="onSave()">
    <input type="submit"  [hidden]="isShow" class="btn btn-sm btn-primary" value="Submit"
        [disabled]="tmplName == '' || pocOptns == '' || lstChsnDN.length == 0" (click)="onSave()"> -->
    <div *ngIf="pocOptns !== '4'">
        <input type="submit" class="btn btn-sm btn-primary" value="Submit"
            [disabled]="tmplName == '' ||  lstChsn.length == 0" (click)="onSave()">
    </div>
    <div *ngIf="pocOptns == '4'">
        <input type="submit" class="btn btn-sm btn-primary" value="Submit"
            [disabled]="tmplName == '' || lstChsnDN.length == 0" (click)="onSave()">
    </div>
    <button type="button" class="btn btn-sm btn-outline-dark" data-dismiss="modal"
        (click)="activeModal.close('Close click')">Close</button>
</div>