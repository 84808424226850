import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { forkJoin } from 'rxjs';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { ReportService } from 'src/app/shared/services/report.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { Common } from 'src/app/common';
import { AgentService } from 'src/app/shared/services/agent.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import {HubCoordinatorRegistrationComponent} from 'src/app/modules/registration/hub-coordinator-registration/hub-coordinator-registration.component'
import { environment } from 'src/environments/environment';
declare let $:any;
@Component({
  selector: 'app-hub-coordinator-list',
  templateUrl: './hub-coordinator-list.component.html',
  styleUrls: ['./hub-coordinator-list.component.css']
})
export class HubCoordinatorListComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject(); minDt = new Date(); minToDt = new Date(); maxDt = new Date(); maxDtDOB = new Date();
  cntrLstForm: FormGroup; cntrCtrlsForm: FormGroup; district_code = '';locationtypeid=''; block_code = ''; grmpcht_code = ''; isAdd = 'add'; isAct = '0';
  submitted = false; locationType_list; lstgrid = []; state_list = []; division_list = []; district_list = []; block_list = []; grampanchayat_list = [];
  paymentlist = []; userlist = []; role_list = []; lstData = []; panchayat_list = []; village_list = []; fileLst = [];state_code = 1;fileName= 'Centers Report.xlsx';
  
  modalTitle: string = ''; lstGrid = [];
  getAllcenters:[];
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  mandalList: Array<any> = [];
  cityList: Array<any> = [];
  villageList: Array<any> = [];
  isNIG = environment.isNIG;
  constructor(config: NgbModalConfig
    , private toastr: ToastrService
    , private datePipe: DatePipe
    , private regService: RegistrationService
    , private agentSvc: AgentService
    , private reportService: ReportService
    , private formBuilder: FormBuilder
    , private modalService: NgbModal
    , private authSvc: AuthenticationService
    , private masterService: MasterService) { 
    
    }

  // openAgentModal(agent: any = null) {
  //   const modalRef = this.modalService.open(CoordinatorRegistrationComponent, { scrollable: true, size: 'xl' }); // AgentControlsComponent
  //   // modalRef.componentInstance.title = this.modalTitle;
  //   // modalRef.componentInstance.agent = agent;
  //   // modalRef.result.then(result => {
  //   //   if (result == 'changes saved')
  //   //     this.getAgentsData;
  //   // }, (reason) => { });
  // }
  ngOnInit(): void {
    this.initGridForm();
    this.locationChanged('', 'state');
    this.getAllMasters();
   this.getallcentersGrid();
  }
  get f() { return this.cntrLstForm.controls; }
  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        this.cntrLstForm.get('district_id').setValue(0);
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      },
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }
  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
      this.districtList.sort((a,b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error));
  }
  getAllMasters() {
    this.masterService.getAll('getalllocationtypes').subscribe(res=>{
      if(res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info(res.message);
        else
          this.locationTypeList = res.data;
      }
    });
   // locationType.subscribe({})
    }
  initGridForm() {
    this.cntrLstForm = this.formBuilder.group({
      // state_code:[0, Validators.required],
      // district_code:[0, Validators.required],
      // locationtypeid:[0, Validators.required],
      // block_code: [0]
      state_id: [0, Validators.required],
       district_id: [0, Validators.required],
       mandal_id: [0, Validators.required],
       location_type_id:[0, Validators.required]
       //village_id: [''],
      
    });
 
  }
  exportexcel(): void 
  {
     
     var table = $('#tblCntr').DataTable();
 
    if ( ! table.data().count() ) {
    this.toastr.error( 'No data available in table! Please select and try again' );
   }
   else{
     / table id is passed over here /  
     let element = document.getElementById('tblCntr'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
     

     / generate workbook and add the worksheet /
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     ws['!cols'] = [];
     ws['!cols'][10] = { hidden: true };
     

     / save to file /
     XLSX.writeFile(wb, this.fileName);
     }
    }
    // openPatientModal() {
    //   const modalRef = this.modalService.open(CenterRegistrationFormComponent, { scrollable: true, size: 'xl' });
    //   modalRef.componentInstance.title = this.modalTitle;
    // }
    
  
    onGapEdit(id: any) {
      if (id)
        this.regService.getAPI3(`hubregistration/getbyhubid/${id}`).subscribe(res => { this.openAgentModal(res.data) });
    }
  
  openAgentModal(agent: any = null) {
    const modalRef = this.modalService.open(HubCoordinatorRegistrationComponent, { scrollable: true, size: 'xl' }); // AgentControlsComponent
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.agent = agent;
    modalRef.result.then(result => {
      if (result == 'changes saved')
        this.getallcentersGrid();
    }, (reason) => { });
  }
    onSearch() {
      $('.dataTable').DataTable().destroy(), $('.dataTable').DataTable({ searching: false });
      this.getallcentersGrid();
      
    }

    onReset() {
      this.cntrLstForm.patchValue({state_id:0,district_id:0, mandal_id:0,location_type_id:0  })
      //this.district_code = "", this.submitted = true;
      //$('.dataTable').DataTable().clear(),$('.dataTable').DataTable().destroy() ;
      this.getallcentersGrid();
    }
  getallcentersGrid(){
  let payLoad ={
    state_id:parseInt(this.cntrLstForm.value.state_id), 
    district_id:parseInt(this.cntrLstForm.value.district_id), 
    mandal_id:parseInt(this.cntrLstForm.value.mandal_id), 
    location_type_id: parseInt(this.cntrLstForm.value.location_type_id)
  } 
    this.regService.getAllHubCoord(payLoad).subscribe(res => {
     // this.getAllcenters=data.data;
     if(res.status === 'OK') {
      if (res.data == null || res.data.length == 0){
      this.getAllcenters=[]
        this.toastr.info(res.message);
      }
      else
        this.getAllcenters = res.data;
      Common.loadDataTable(this.getAllcenters, '#centerregTable');
    }
    else
      this.getAllcenters = [], Common.loadDataTable(this.getAllcenters, "#centerregTable");
    })
  }
  deleteItem(id:any){
    this.regService.deleteCenter(id).subscribe(res => {
      // this.getAllcenters=data.data;
      if(res.status === 'OK') {
         this.toastr.info(res.message);
        //  this.getallcentersGrid();
     }
     })
  }
  

}
