<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="form-group">
                            <label class="control-label"> Doctor Name <span class="text-danger">*</span> </label>
                            <div>
                                <input type="text" autocomplete="off" [ngModel]="doctor_name" id="first_name"
                                    disabled maxlength="50" placeholder="Name"
                                    class="form-control form-control-sm text-uppercase">
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="text-center mb-2" *ngIf="dateSlots && dateSlots.length>0">
                
                    <ngb-multi-date-picker [datesSelected]="datesSelected" [dateSlots]="dateSlots" (datesSelectedChange)="change($event)"  class="shadow">
                    </ngb-multi-date-picker>
                </div>
                <div class="text-center mb-2" *ngIf="dateSlots.length==0">
                
                    <ngb-multi-date-picker [datesSelected]="datesSelected" [dateSlots]="dateSlots" (datesSelectedChange)="change($event)"  class="shadow">
                    </ngb-multi-date-picker>
                </div>

<hr />
                <!-- class="float-right" -->
                <div class="text-center">
                    <input type="submit" class="btn btn-sm btn-primary px-4" (click)="submit()"
                        value="Submit">&nbsp;&nbsp;
                    <button type="button" id="btnClose" class="btn btn-sm btn-outline-dark px-4" (click)="closeModal()"
                        data-dismiss="modal">Close</button>
                </div>
            </div>
        
 