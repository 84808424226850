<div class="modal-header">
    <h4 class="modal-title">Hub-Coordinator Registration Details</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click');modalclose();">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="coordinatorForm" (ngSubmit)="onSubmit($event)">
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <div>
                            <label class="control-label"> Name <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" maxlength="50" formControlName="first_name"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"
                                class="form-control form-control-sm text-uppercase">
                            <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                <div *ngIf="f.first_name.errors.required">Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <div>
                            <label class="control-label"> Surname <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" maxlength="50" formControlName="last_name" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }"
                                class="form-control form-control-sm text-uppercase">
                            <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                <div *ngIf="f.last_name.errors.required">Surname is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Date of Birth <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="birth_date" id="birth_date"
                                [ngClass]="{ 'is-invalid': submitted && f.birth_date.errors }" (focus)="dob.show()" (click)="dateclick();"
                                bsDatepicker #dob="bsDatepicker" class="form-control form-control-sm" [maxDate]="maxDOB"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }">
                            <div *ngIf="submitted && f.birth_date.errors" class="invalid-feedback">
                                <div *ngIf="f.birth_date.errors.required">
                                    Date of Birth is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Gender <span class="text-danger">*</span> </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="gender_id"
                                [ngClass]="{ 'is-invalid': submitted && f.gender_id.errors }" (focus)="dob.hide()"
                                (change)="onChngDD('gender_id', $event.target.value)" id="gender_id">
                                <option value="null">Select</option>
                                <option *ngFor="let gen of genderList" [value]="gen.id">{{gen.gender_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.gender_id.errors" class="invalid-feedback">
                                <div *ngIf="f.gender_id.errors.required">Gender is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Mobile Number <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="mobile" id="mobile"
                                [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" [maxlength]="maxLngth"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' [pattern]="mobPtrn"
                                class="form-control form-control-sm" [attr.disabled]="isEdit==true?true:null">
                            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                <div *ngIf="f.mobile.errors?.required">Mobile Number is required</div>
                                <div *ngIf="f.mobile.errors.pattern">Mobile Number {{mobErrMsg}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Email </label>
                        <div>
                            <input type="text" autocomplete="off" maxlength="60" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email"
                                [attr.disabled]="isEdit==true?true:null">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.pattern">Enter Valid Email</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Local Languages Preferred <span class="text-danger">*</span> </label>
                        <div>                            
                            <div tabindex="0" class="">
                                <ng-multiselect-dropdown [settings]="ddLangStngs" [placeholder]="'All'"
                                    [ngClass]="{ 'is-invalid': submitted && f.agent_languages.errors }"
                                    [data]="languagesList" formControlName="agent_languages"></ng-multiselect-dropdown>
                                <div *ngIf="submitted && f.agent_languages.errors" class="invalid-feedback" >
                                    <div *ngIf="f.agent_languages.errors.required">Local Languages Preferred is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> {{isNIG ? 'States' : 'State'}} <span class="text-danger">*</span>
                        </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="state_id"
                                [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }" id="state" #State
                                (change)="onChngDD('state_id', $event.target.value);locationChanged($event.target.value, 'district')">
                                <option value="">Select</option>
                                <option *ngFor="let state of stateList" [value]="state.id">{{state.state_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                <div *ngIf="f.state_id.errors.required">{{isNIG ? 'States' : 'State'}} is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Location Type <span class="text-danger">*</span> </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="location_type_id"
                                [ngClass]="{ 'is-invalid': submitted && f.location_type_id.errors }"
                                (change)="onChngDD('location_type_id', $event.target.value)">
                                <option value="">Select</option>
                                <option *ngFor="let loc of locationTypeList" [value]="loc.id">{{loc.location_type}}
                                </option>
                            </select>
                            <div *ngIf="submitted && f.location_type_id.errors" class="invalid-feedback">
                                <div *ngIf="f.location_type_id.errors.required">Location Type is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> {{isNIG ? 'State Capitals' : 'District'}} <span
                                class="text-danger">*</span> </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="district_id"
                                [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }"
                                (change)="onChngDD('district_id', $event.target.value);locationChanged(District.value, 'mandal')"
                                #District>
                                <option value="">Select</option>
                                <option *ngFor="let dist of districtList" [value]="dist.id">{{dist.district_name}}
                                </option>
                            </select>
                            <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                                <div *ngIf="f.district_id.errors.required">
                                    {{isNIG ? 'State Capitals' : 'District'}} is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <div>
                            <label class="control-label"> {{isNIG ? 'Local Government Areas' : 'Mandal'}} </label>
                            <select class="form-control form-control-sm" formControlName="mandal_id"
                                (change)="locationChanged(Mandal.value, 'village')" #Mandal>
                                <option value="0">Select</option>
                                <option *ngFor="let mndl of mandalList" [value]="mndl.id">{{mndl.mandal_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!isNIG">
                    <div class="form-group">
                        <div>
                            <label class="control-label"> Village </label>
                            <select class="form-control form-control-sm" formControlName="village_id">
                                <option value="0">Select</option>
                                <option *ngFor="let vlg of villageList" [value]="vlg.id">{{vlg.village_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Pin Code <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" class="form-control form-control-sm"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && f.pin_code.errors }" maxlength="6" minlength="6"
                                formControlName="pin_code">
                            <div *ngIf="submitted && f.pin_code.errors" class="invalid-feedback">
                                <div *ngIf="f.pin_code.errors.required">Pin Code is required</div>
                                <div *ngIf="f.pin_code.errors.minlength">Pin Code should be 6 characters</div>
                                <div *ngIf="f.pin_code.errors.maxlength">Pin Code should be 6 characters</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Alternate Mobile Number </label>
                            <div>
                                <input type="text" formControlName="alternate_mobile_no"
                                    [ngClass]="{ 'is-invalid': submitted && f.alternate_mobile_no.errors?.pattern }"
                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                    [maxlength]="maxLngth" id="alternate_mobile_no" class="form-control form-control-sm"
                                    [pattern]="mobPtrn">
                                <div *ngIf="submitted && f.alternate_mobile_no.errors?.pattern"
                                    class="invalid-feedback">
                                    <div>Alternate Mobile Number {{mobErrMsg}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" >
                    <div class="form-group">
                      <label class="control-label">Bank Account Number
                      </label>
                      <div>
                        <input type="text" autocomplete="off" formControlName="bank_account_number" id="bank_account_number"
                          (keyup)="keyUp('bank_account_number')" onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                          maxlength="20" [ngClass]="{ 'is-invalid': submitted && f.bank_account_number.errors }"
                          class="form-control form-control-sm">
                        <div *ngIf="submitted && f.bank_account_number.errors" class="invalid-feedback">
                          <div *ngIf="f.bank_account_number.errors.required">
                            Lab Bank Account Number is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <div class="form-group">
                      <label class="control-label">Bank Name
                      </label>
                      <div>
                        <select formControlName="bank_id" id="branch_name" class="form-control form-control-sm"
                          [ngClass]="{ 'is-invalid': submitted && f.bank_id.errors }">
                          <option value="">Select</option>
                          <option *ngFor="let item of bankNamesList" [value]="item.id">
                            {{item.bank_name}}</option>
                        </select>
                        <!-- <input type="text" autocomplete="off" formControlName="branch_name" id="branch_name"
                          (keyup)="keyUp('branch_name')" maxlength="10"
                          [ngClass]="{ 'is-invalid': submitted && f.branch_name.errors }" class="form-control form-control-sm"> -->
                        <div *ngIf="submitted && f.bank_id.errors" class="invalid-feedback">
                          <div *ngIf="f.bank_id.errors.required">
                            Bank Name is
                            required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3" >
                    <div class="form-group">
                      <label class="control-label">Bank Location
                      </label>
                      <div>
                        <input type="text" autocomplete="off" formControlName="branch_location" id="branch_location"
                          (keyup)="keyUp('branch_location')"
                          onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                          maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.branch_location.errors }"
                          class="form-control form-control-sm text-uppercase">
                        <div *ngIf="submitted && f.branch_location.errors" class="invalid-feedback">
                          <div *ngIf="f.branch_location.errors.required">
                            Bank Location is
                            required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 
                  <div class="col-xl-3" >
                    <div class="form-group">
                      <label class="control-label">IFSC Code</label>
                      <div>
                        <input type="text" autocomplete="off" formControlName="ifsc_code" id="ifsc_code" maxlength="11"
                          [ngClass]="{ 'is-invalid': submitted && f.ifsc_code.errors }" class="form-control form-control-sm  text-uppercase ">
                        <div *ngIf="submitted && f.ifsc_code.errors" class="invalid-feedback">
                          <div *ngIf="f.ifsc_code.errors.pattern">
                            Enter Valid IFSC, ex: SBIN0001050
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <div class="form-group">
                        <div>
                            <label class="control-label"> Name As Per Bank </label>
                            <input type="text" autocomplete="off" maxlength="50" formControlName="name_as_per_bank" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm ">
                    
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <div>
                            <label class="control-label"> PAN Number </label>
                            <input type="text" autocomplete="off" maxlength="10" formControlName="pan_no" 
                            [ngClass]="{ 'is-invalid': submitted && f.pan_no.errors }"   class="form-control form-control-sm  text-uppercase  ">
                            <div *ngIf="submitted && f.pan_no.errors" class="invalid-feedback">
                                <div *ngIf="f.pan_no.errors.pattern">
                                  Enter Valid PAN No, ex: AAAPZ1234C
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Status <span class="text-danger">*</span> </label>
                        <div>
                            <!-- <input type="radio" formControlName="account_activated" value="1" [checked]="isAct=='1'" (click)="radioclick('true')">Active
                            <input type="radio" formControlName="account_activated" value="0" [checked]="isAct=='0'" (click)="radioclick('false')">In Active -->
                            <input type="radio" formControlName="is_active" [value]="true">Active
                            <input type="radio" formControlName="is_active" [value]="false">In Active
                        </div>
                    </div>
                </div>
               
            </div>
            <!-- <div class="row">
                <div class="col-xl-12">
                    <div class="table-responsive">
                        <table class="table table-bordered table-sm">
                            <tbody>
                                <tr [hidden]="isNIG">
                                    <td>
                                        <input type="file" class="d-none" id="aadhaar" #aAdhar
                                            formControlName="aadharFile"
                                            (change)="onFileUpload($event,1,'12','aadharFile')"
                                            >
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="aAdhar.click()"> {{isSAGE ? 'National ID' : 'Aadhaar'}} </p>
                                        <span class="text-danger">*</span>
                                        <p class="small mb-1">jpg or pdf format only</p>
                                        <div *ngIf="adhErr" style="color: #e81500;">
                                            <div>{{isSAGE ? 'National ID' :
                                                'Aadhaar'}} is required</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill pointer" title="Cancel File"
                                                *ngIf="uploadFileList[0].File || isShwPic" (click)="cancelFile(1)">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)"
                                            (click)="viewFile(1)">{{uploadFileList[0].file_name}}</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div> -->
            <input type="submit" id="btnsubmit" style="display: none;" (click)="onSubmit($event)">
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" [disabled]="sbmtDsbld?true:null" class="btn btn-sm btn-primary" (click)="submitForm()"
        value="Submit">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>

