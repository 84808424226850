import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Common } from 'src/app/common';
import { UserService } from 'src/app/shared/services/user.service';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';


declare let $: any;
declare let JqueryReady: any;
declare const answerCall: any;
@Component({
  selector: 'app-appointment',
  templateUrl: './appointment.component.html',
  styleUrls: ['./appointment.component.css'],
  providers: [DatePipe]
})
export class AppointmentComponent implements OnInit {
  pipe = new DatePipe('en-US');
  appointmentForm: FormGroup; appointmentFormmm: FormGroup;
  fromDate: any = new Date();
  max_date = new Date();
  toDate: any = new Date();
  lstGrid = []; minDate: Date; minToDate: Date; docId = ''; lstGriddd = [];
  submitted = false;
  TENANT_ID: string = '';
  isShow = true;
  videoList: any;
  audioList: any;
  OrgId: string;

  audiofileName = 'appointment audio list.xlsx';
  videofileName = 'appointment video list.xlsx';
  excelData: any =[];
  audioType: string;
  videoType: string;
  excelData_audio: any=[];
  constructor(private regService: RegistrationService,
    private authSvc: AuthenticationService
    , protected toastr: ToastrService
    , private formBuilder: FormBuilder
    , private datePipe: DatePipe
    , private router: Router,
    private excelDownloadService:ExcelDownloadService
    , private userservice: UserService) {
    // this.minDate = new Date();
    // this.minDate.setDate(this.minDate.getDate());
    authSvc.crntOrgIDSubject.subscribe(s => {
      this.lstGrid = [], Common.loadDataTable(this.lstGrid, '#appointmentTable');
      this.lstGriddd = [], Common.loadDataTable(this.lstGriddd, '#appointmentTablee');
      if (location.href.includes('home/appointment'))
        this.ngOnInit();
    }), authSvc.isShowGlblOrg.next(true);
    this.TENANT_ID = sessionStorage.getItem('currentTenant')
  }

  ngOnInit(): void {
    this.initFormControls(); this.initFormControlsss();

    let data = JSON.parse(sessionStorage.getItem('DoctorDetails'));
    if (data)
      this.docId = data.id, this.LoadDataForVideo()//, this.LoadDataForAudio();
    else
      this.getDoctorDetails();
    // setTimeout(() => {}, 1000);


  }
  // videoform

  initFormControls() {
    this.appointmentForm = this.formBuilder.group({
      fromDate: [new Date(), Validators.required],
      toDate: [new Date(), Validators.required],
      doctorId: [''],
      call_type_id: ['1']
    });
  }


  get f() { return this.appointmentForm.controls; }

  // audioform
  initFormControlsss() {
    this.appointmentFormmm = this.formBuilder.group({
      fromDate: [new Date(), Validators.required],
      toDate: [new Date(), Validators.required],
      doctorId: [''],
      call_type_id: ['9']
    });
  }

  get g() { return this.appointmentFormmm.controls; }

  LoadDataForVideo() {
    this.audioType= '';
    this.videoType='video'
    this.submitted = true;
    let sendData = this.appointmentForm.value;
    let user = this.authSvc.currentUserValue;
    [sendData.fromDate, sendData.toDate, sendData.doctorId, sendData.call_type_id] =
      [this.datePipe.transform(sendData.fromDate, 'yyyy-MM-dd'),
      this.datePipe.transform(sendData.toDate, 'yyyy-MM-dd'), user.user_role_mapping[0].user_type_id.id == 7 ? this.docId : 0, sendData.call_type_id];
    this.regService.getAppointment(`appointment/get_appointments?fromDate=${sendData.fromDate}&toDate=${sendData.toDate}&doctorId=${sendData.doctorId}&callTypeId=${sendData.call_type_id}`).subscribe(res => {

      if (res.status === "success") {
        if (res.response == null || res.response == "No appointments found") {
          this.toastr.info('No appointments presently', 'Appointments Scheduled');
          this.lstGrid = [];
          Common.loadDataTable(this.lstGrid, '#appointmentTable');
        }
        else
          this.lstGrid = res.response == "No appointments found" ? [] : res.response;
          this.excelData=this.lstGrid;
        Common.loadDataTable(this.lstGrid, '#appointmentTable');
      }

    });
  }

  LoadDataForAudio() {
    this.audioType= 'audio';
    this.videoType=''
  
    this.submitted = true;
    let sendData = this.appointmentFormmm.value;
    let user = this.authSvc.currentUserValue;
    [sendData.fromDate, sendData.toDate, sendData.doctorId, sendData.call_type_id] =
      [this.datePipe.transform(sendData.fromDate, 'yyyy-MM-dd'),
      this.datePipe.transform(sendData.toDate, 'yyyy-MM-dd'), user.user_role_mapping[0].user_type_id.id == 7 ? this.docId : 0, sendData.call_type_id];
    this.regService.getAppointment(`appointment/get_appointments?fromDate=${sendData.fromDate}&toDate=${sendData.toDate}&doctorId=${sendData.doctorId}&callTypeId=${sendData.call_type_id}`).subscribe(res => {

      if (res.status === "success") {
        if (res.response == null || res.response == "No appointments found") {
          this.toastr.info('No appointments presently', 'Appointments Scheduled');
          this.lstGriddd = [];
          Common.loadDataTable(this.lstGriddd, '#appointmentTablee');
        }
        else
          this.lstGriddd = res.response == "No appointments found" ? [] : res.response;
          this.excelData_audio=this.lstGriddd;
        Common.loadDataTable(this.lstGriddd, '#appointmentTablee');
      }

    });

  }

  onFromDateChange(val: Date) {
    if (val) {
      let toDate: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.appointmentForm.get('fromDate').setValue(new Date(toDate.setDate(toDate.getDate())));
      this.minDate = toDate; 
      // this.minToDate = val;
    }
  }
  onToDateChange(val: Date) {
    if (val) {
      let toDate: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.appointmentForm.get('toDate').setValue(new Date(toDate.setDate(toDate.getDate())));
      // this.minDate = toDate, this.minToDate = val;
    }
  }



  onFromDateChangeee(val: Date) {
    if (val) {
      let toDate: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.appointmentFormmm.get('fromDate').setValue(new Date(toDate.setDate(toDate.getDate())));
      this.minDate = toDate;
      // this.minToDate = val;
    }
  }
  onToDateChangeee(val: Date) {
    if (val) {
      let toDate: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.appointmentFormmm.get('toDate').setValue(new Date(toDate.setDate(toDate.getDate())));
      // this.minDate = toDate;
      // this.minToDate = val;
      this.max_date=toDate;
    }
  }

  clear() {
    this.appointmentForm.value.fromDate = this.appointmentForm.value.toDate = '';
  }


  clearrr() {
    this.appointmentFormmm.value.fromDate = this.appointmentFormmm.value.toDate = '';
  }

  getDoctorDetails() {
    let user = this.authSvc.currentUserValue;
    this.regService.getByID('doctor/getdoctorrecordbyuserid?userId=', user?.id).subscribe(res => {
      if (res.data.length > 0) {
        // sessionStorage.setItem('DoctorDetails', JSON.stringify(res.data[0]));
        this.docId = res.data[0].id;
      }
    },
      err => { this.toastr.error(err) },
      () => { this.LoadDataForVideo(); this.LoadDataForVideo(); });

  }

  onAudioCall(data: any) {
    const [doctor_mobileNo, patient_mobileNo] = [JSON.parse(sessionStorage.DoctorDetails).mobile, data.mobile];
    const is_self = data.chart_history.patientInformation.isSelf;
    const patientObj = data.chart_history.patientInformation;
  sessionStorage.setItem('pocEditFmember',patientObj);
    const memberInfo: any = {
      address: null,
      age: patientObj.age,
      first_name: patientObj.name,
      gender_id: patientObj.genderId,
      last_name: "",
      member_mobile: patientObj.mobile,
      mobile: patientObj.mobile
    }

    const patientInfo: any = {
      familyMemberId: patientObj.familyMemberId || null,
      hospital_id: "",
      is_self: patientObj.isSelf,
      order_no: data.orderNo,
      patient_interaction_id: data.consultationId,
      symptoms: patientObj.Symptoms,
    }

    this.regService.initiateAudiocall(doctor_mobileNo, patient_mobileNo).subscribe(res12 => {
      if (res12.status == 'OK' && res12.message == "Call Initiated") {
        sessionStorage.setItem('call_type','Audio');
        sessionStorage.setItem('call_type_id',''+9);
        let fromId = (JSON.parse(sessionStorage.DoctorDetails).id).toString();
        let toId = (data.id).toString();
        $("#symptoms").val(data.chart_history.symptoms);
        $("#fromId").val("doc_" + fromId);
        $("#toId").val("pat_" + toId);
        $("#order_no").val(data.orderNo);
        $("#is_self").val(is_self);
        $("#patient_name").val(data.patient_name);
        $("#patient_interaction_id").val(data.consultationId);
        $("#patient_info").val(JSON.stringify({ "patient_interaction_id": data.consultationId, "order_no": data.orderNo, "hospital_id": "", "symptoms": data.chart_history.symptoms, "is_self": is_self }));
        $("#patient_info").val(JSON.stringify(patientInfo));
        $("#family_member").val(JSON.stringify(memberInfo));
        sessionStorage.isPatient = true;
        this.router.navigate(['home/poc'])
          .then((e) => {
            let ws = JSON.parse(sessionStorage.getItem('webSkt'));
            Promise.all([JqueryReady()]).then(res => { answerCall(fromId, toId, ws.expn, ws.qlfnName, ws.grCode, ws.irCode, ws.erCode); });
            if (e)
              console.log("Navigation is successful!");
            else
              console.log("Navigation has failed!");
          });
      }
    },
      err => { this.toastr.error(err) },
      () => { });
  }

  exportexcel(): void {

    var table = $('#appointmentTable').DataTable();

    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {
      / table id is passed over here /
      let element = document.getElementById('appointmentTable');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };

      / save to file /
      XLSX.writeFile(wb, this.audiofileName);
    }
}
exporttoexcel(): void {

  var table = $('#appointmentTablee').DataTable();

  if (!table.data().count()) {
    this.toastr.error('No data available in table! Please select and try again');
  }
  else {
    / table id is passed over here /
    let element = document.getElementById('appointmentTablee');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    / generate workbook and add the worksheet /
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    ws['!cols'] = [];
    ws['!cols'][10] = { hidden: true };

    / save to file /
    XLSX.writeFile(wb, this.videofileName);
  }
}
downLoadExcel(audioType){
  if(audioType=='audio'){
    const fileName = 'audio_appointment_schedule'
    var excelDataAudio = this.excelData_audio;
    if(excelDataAudio.length>0){
      excelDataAudio.map(res =>{
        res.formatedappointment_date = this.pipe.transform(res.appointment_date, 'dd-MM-yyyy');
        res.formated_doctor_fullName = (res.doctor_firstName+ ' ' + res.doctor_lastName).toUpperCase();
        res.formated_patient_name= (res.chart_history?.patientInformation?.isSelf == true ? res.patient_name : res.chart_history?.patientInformation?.name).toUpperCase();
        res.formated_gender=(res.chart_history?.patientInformation?.isSelf == true ? res.gender : res.chart_history?.patientInformation?.gender).toUpperCase();
        res.formated_mobile=(res.chart_history?.patientInformation?.isSelf == true ?
          res.mobile : ((res.chart_history?.patientInformation?.mobile != null &&
          res.chart_history?.patientInformation?.mobile != '') ?
          res.chart_history?.patientInformation?.mobile : res.mobile))
         
         
        
      })
      const excelHeaders = ['Appointment Date', 'Appointment Time','Doctor Name','Patient Name','Gender','Mobile Number','Audio Appoint Status'];
      const internalHeaders = ['formatedappointment_date', 'appointment_time','formated_doctor_fullName','formated_patient_name','formated_gender','formated_mobile','status'];
      this.toastr.success('Please wait excel is downloading')
      this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelDataAudio)
    }
    else{
      this.toastr.error('No data to download')
    }
  }
  if(audioType=='video'){
    const fileName = 'video_appointment_schedule'
    var excelData = this.excelData;
    if(excelData.length>0){
      excelData.map(res =>{
        res.formatedappointment_date = this.pipe.transform(res.appointment_date, 'dd-MM-yyyy');
        res.formated_doctor_fullName = (res.doctor_firstName+ ' ' + res.doctor_lastName).toUpperCase();
        res.formated_patient_name= (res.chart_history?.patientInformation?.isSelf == true ? res.patient_name : res.chart_history?.patientInformation?.name).toUpperCase();
        res.formated_gender=(res.chart_history?.patientInformation?.isSelf == true ? res.gender : res.chart_history?.patientInformation?.gender).toUpperCase();
        res.formated_mobile=(res.chart_history?.patientInformation?.isSelf == true ?
          res.mobile : ((res.chart_history?.patientInformation?.mobile != null &&
          res.chart_history?.patientInformation?.mobile != '') ?
          res.chart_history?.patientInformation?.mobile : res.mobile))
         
         
        
      })
      const excelHeaders = ['Appointment Date', 'Appointment Time','Doctor Name','Patient Name','Gender','Mobile Number','Video Appoint Status'];
      const internalHeaders = ['formatedappointment_date', 'appointment_time','formated_doctor_fullName','formated_patient_name','formated_gender','formated_mobile','status'];
      this.toastr.success('Please wait excel is downloading')
      this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
    }
    else{
      this.toastr.error('No data to download')
    }
  }
   
 }

}


