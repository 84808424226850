<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>

<div class="modal-body">
    <div class="container-fluid">
       
            <!-- <h4>Care Context Consultation</h4> -->
           
            <div class="row mb-3" *ngIf="!isDemoGraphic">
                <div class="col-2"><label class="control-label">ABHA Address: </label></div>
                <div class="col-6">
                    <input type="text" autocomplete="off" maxlength="20" [(ngModel)]="abhaNumber"
                    class="form-control form-control-sm"  [readonly]="true">
                    
                </div>
                <div class="col-2 ml-0" *ngIf="!isDemoGraphic">
                    <button class="btn btn-sm btn-success" type="submit" value="submit" (click)="existhealthID()"
                    id="ver"  [disabled]="true">Verify</button>
                </div>
                <div class="col-2" *ngIf="modesData.length==0">
                    <button class="btn btn-sm btn-success" type="submit" value="submit" (click)="getModes()"
                    id="calBack">Retry</button>
                </div>
           </div>
            <div class="row mb-3" *ngIf="modesData.length>0 && !isDemoGraphic">
                <div class="col-2"> <label class="control-label">Modes</label></div>
                <div class="col-6">
                    <select class="form-control form-control-sm" [(ngModel)]="selectedMode"
                    (change)="onMethodChange($event.target.value)">
                    <option value="" selected hidden>Select</option>
                    <option *ngFor="let mthd of modesData" [value]="mthd">{{mthd}}
                    </option>
                </select>
                </div>
                <div class="col-2" *ngIf="!isCallbackRespAvail && modesData.length>0">
                    <button class="btn btn-sm btn-success" type="submit" value="submit" (click)="initCallback()"
                    id="calBack">Retry</button>
                </div>
            </div>
            <div class="row" *ngIf="isForgotMob && !isDemoGraphic">
                <div class="col-2"><label class="control-label">Enter OTP </label></div>
                <div class="col-6">
                    <input type="text" autocomplete="off" maxlength="6"  [(ngModel)]="verifyotp"
                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                    class="form-control form-control-sm">
                    
                </div>
                <div class="col-2" *ngIf="!isDemoGraphic && isCallbackRespAvail">
                    <button class="btn btn-sm btn-success" type="submit" value="submit" (click)="verifyOtp()"
                        id="verNew">Submit</button>
                </div>
                <div class="col-2" *ngIf="!isCallbackRespAvail">
                    <button class="btn btn-sm btn-success" type="submit" value="submit" (click)="confirmCallback()"
                    id="calBack">Retry</button>
                </div>
            </div>
            <div class="row" *ngIf="isAdhaarOtp && !isDemoGraphic">
                <div class="col-2"><label class="control-label">Enter OTP </label></div>
                <div class="col-6">
                    <input type="text" autocomplete="off" maxlength="6"  [(ngModel)]="verifyotp"
                    onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                    class="form-control form-control-sm">
                    
                </div>
                <div class="col-2" *ngIf="!isDemoGraphic && isCallbackRespAvail">
                    <button class="btn btn-sm btn-success" type="submit" value="submit" (click)="verifyOtp()"
                        id="verNew">Submit</button>
                </div>
                <div class="col-2" *ngIf="!isCallbackRespAvail">
                    <button class="btn btn-sm btn-success" type="submit" value="submit" (click)="confirmCallback()"
                    id="calBack">Retry</button>
                </div>
            </div>
            <div class="row" *ngIf="isDemoGraphic">
                <form [formGroup]="demographicForm" class="container-fluid">
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label"> Name <span class="text-danger">*</span> </label>
                        <div class="col-sm-8">
                            <input type="text" autocomplete="off" formControlName="name" id="name" [(ngModel)]="dgName" [readonly]="demographyData?.name!==''&& demographyData?.name!==null?true:false"
                                maxlength="50" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" 
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                class="form-control form-control-sm text-uppercase">
                            <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                <div *ngIf="f.name.errors.required">Name is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Gender <span class="text-danger">*</span> </label>
                        <div class="col-sm-8" *ngIf="dgGender!==''&& dgGender!==null">
                            <select formControlName="gender_id" id="gender_id" (focus)="dp.hide()" [(ngModel)]="dgGender" disabled="true"
                                [ngClass]="{ 'is-invalid': submitted && f.gender_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let gender of genderList" [value]="gender.gender_id">{{gender.gender_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.gender_id.errors" class="invalid-feedback">
                                <div *ngIf="f.gender_id.errors.required">Gender is required</div>
                            </div>
                        </div>
                        <div class="col-sm-8" *ngIf="dgGender==='' || dgGender==null">
                            <select formControlName="gender_id" id="gender_id" (focus)="dp.hide()" [(ngModel)]="dgGender" 
                                [ngClass]="{ 'is-invalid': submitted && f.gender_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let gender of genderList" [value]="gender.gender_id">{{gender.gender_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.gender_id.errors" class="invalid-feedback">
                                <div *ngIf="f.gender_id.errors.required">Gender is required</div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Date of Birth <span class="text-danger">*</span> </label>
                        <div class="col-sm-8">
                            <input type="text" autocomplete="off" formControlName="birth_date" id="birth_date" [(ngModel)]="dgDob" [readonly]="demographyData?.dateOfBirth!==''&& demographyData?.dateOfBirth!==null?true:false"
                                bsDatepicker  #dp="bsDatepicker" class="form-control form-control-sm" [maxDate]="max_date"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                [ngClass]="{ 'is-invalid': submitted && f.birth_date.errors }" (focus)="dp.show()">
                            <div *ngIf="submitted && f.birth_date.errors" class="invalid-feedback">
                                <div *ngIf="f.birth_date.errors.required">
                                    Date of Birth is required
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label">Mobile No. <span class="text-danger">*</span> </label>
                        <div class="col-sm-8">
                            <input type="text" autocomplete="off" formControlName="mobile" id="mobile" [(ngModel)]="dgMobile" [readonly]="demographyData?.identifier?.value!==''&& demographyData?.identifier?.value!==null?true:false"
                                [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" [pattern]="mobPtrn"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm" [maxlength]="maxLngth"
                                (keyup)="onKeyNmbr('mobile', $event.target.value)">
                            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                <div *ngIf="f.mobile.errors?.required">Mobile Number is required</div>
                                <div *ngIf="f.mobile.errors.pattern">Mobile Number {{mobErrMsg}}</div>
                                <!-- <div *ngIf="f.mobile.errors.required">Mobile Number {{mobErrMsg}}</div>
                                <div *ngIf="f.mobile.errors.minLength">Mobile is 10 digits</div> -->
                            </div>
                        </div>
                    </div>
                    
                       
                        <div>
                            <label class="form-check-label pointer">
                                <input type="checkbox" class="form-check-input chk" [checked]="legacy"
                                (change)="onChk($event.target.checked, 'legacy')"> I consent to usage of my ABHA address and ABHA number for linking of my legacy (past) government health records and those which will be generated during this encounter.
                              
                            </label>
                        </div>
                        <div>
                            <label class="form-check-label pointer">
                                <input type="checkbox" class="form-check-input chk" [checked]="service"
                                (change)="onChk($event.target.checked, 'service')">  I authorize the sharing of all my health records with healthcare provider(s) for the purpose of providing healthcare services to me during this encounter.
                            </label>
                        </div>
                  

                    <div *ngIf="isDemoGraphic && legacy && service">
                        <button class="btn btn-success btn-sm" type="submit" value="submit" (click)="demographySubmit()"
                            id="verNew">Submit</button>
                    </div><br>
                    <div class="col-4" *ngIf="!isCallbackRespAvail">
                        <button class="btn btn-sm btn-success" type="submit" value="submit" (click)="confirmCallback()"
                        id="calBack">Retry</button>
                    </div>
                </form>
            </div>

            <div class="row" *ngIf="isPassword && !isDemoGraphic">
                <div class="col-2"><label class="control-label">Enter Password</label></div>
                <div class="col-6">
                    <!-- <input type="password" autocomplete="off"   [(ngModel)]="verifyPswd"
                    class="form-control form-control-sm"> -->
                    <form>
                        <div class="form-group inner-addon right-addon">
                          <div class="input-group">
                            <input name="password" [(ngModel)]="verifyPswd"
                                [type]="showPasswordOnPress ? 'text' : 'password'"
                                class="form-control form-control-sm  pwd-control"
                                id="password-field" placeholder="Password">
                            <div 
                            (mousedown)="showPasswordOnPress = !showPasswordOnPress" 
                            (mouseup)="showPasswordOnPress = !showPasswordOnPress">
                              <i alt="show" class="fas fa-eye eye-show m-0 p-2" 
                              
                              [class.hide]="showPasswordOnPress"></i>
                              <i alt="hide" class="fas fa-eye-slash eye-hide m-0 p-2" 
                              
                              [class.hide]="!showPasswordOnPress"></i>
                            </div>
                          </div>
                        </div>
                      </form>
                    
                </div>
                <div class="col-2" *ngIf="!isDemoGraphic">
                    <button class="btn btn-sm btn-success" type="submit" value="submit" (click)="verifyPassword()"
                        id="verNew">Submit</button>
                </div>
                <div class="col-2" *ngIf="!isCallbackRespAvail && isPassword">
                    <button class="btn btn-sm btn-success" type="submit" value="submit" (click)="confirmCallback()"
                    id="calBack">Retry</button>
                </div>
            </div>
           
         
    </div>
</div>