import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import * as XLSX from 'xlsx';

import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { Common } from 'src/app/common';

declare let $: any;

@Component({
  selector: 'app-transaction-status',
  templateUrl: './transaction-status.component.html',
  styleUrls: ['./transaction-status.component.css'],
  providers: [DatePipe]
})

export class TransactionStatusComponent implements OnInit {

  appointmentForm: FormGroup; fromDate: any = new Date(); max_date = new Date(); toDate: any = new Date(); docId = ''; submitted = false;
  lstGrid = []; minDate: Date; minToDate: Date; TENANT_ID = sessionStorage.getItem('currentTenant'); // @ViewChild('table') table: ElementRef;

  constructor(private regService: RegistrationService
    , private authSvc: AuthenticationService
    , protected toastr: ToastrService
    , private formBuilder: FormBuilder
    , private dtPipe: DatePipe
    , private authServ: AuthenticationService) { }

  get f() { return this.appointmentForm.controls; }

  ngOnInit(): void {
    this.initFormControls(), this.docId = JSON.parse(sessionStorage.getItem('DoctorDetails'))  ? JSON.parse(sessionStorage.getItem('DoctorDetails')).id : '',
      this.LoadGrid();
    /* let data = sessionStorage.getItem('DoctorDetails');
    if (data)
      this.docId = JSON.parse(data).id, this.LoadData();
    else
      this.getDoctorDetails(); */
  }

  initFormControls() {
    this.appointmentForm = this.formBuilder.group({
      fromDate: [new Date(), Validators.required], toDate: [new Date(), Validators.required], doctorId: ['']
    });
  }

  LoadGrid() {
    let sendData = this.appointmentForm.value, user = this.authServ.currentUserValue, drPL = this.docId != '' ? `&doctorId=${this.docId}` : '';
    [this.submitted, sendData.fromDate, sendData.toDate] = [true, this.dtPipe.transform(sendData.fromDate, 'yyyy-MM-dd'),
      this.dtPipe.transform(sendData.toDate, 'yyyy-MM-dd')];
    this.regService.getTransaction(`gettransactionbydates/?fromDate=${sendData.fromDate}&toDate=${sendData.toDate}${drPL}`).subscribe(data => {
      if (data.status === "OK") {
        if (data.data == null || data.data.length == 0)
          this.toastr.info('No records available', 'Transaction Status Report'), this.lstGrid = [];
        else
          this.lstGrid = data.data;
        Common.loadDataTable(this.lstGrid, '#table');
      }
    });
  }

  LoadData() {
    let sendData = this.appointmentForm.value, user = this.authServ.currentUserValue;
    [this.submitted, sendData.fromDate, sendData.toDate, sendData.doctorId] =
      [true, this.dtPipe.transform(sendData.fromDate, 'yyyy-MM-dd'),
        this.dtPipe.transform(sendData.toDate, 'yyyy-MM-dd'), user.user_role_mapping[0].user_type_id.id == 7 ? this.docId : 0];
    this.regService.getTransaction(`gettransactionbydates/?fromDate=${sendData.fromDate}&toDate=${sendData.toDate}`).subscribe(data => {
      if (data.status === "OK") {
        if (data.data == null || data.data.length == 0)
          this.toastr.info('No records available', 'Transaction Status Report'), this.lstGrid = [];
        else
          this.lstGrid = data.data;
        Common.loadDataTable(this.lstGrid, '#table');
        // $(document).ready(function () {
        //   $('#table').DataTable({
        //     dom: 'lBfrtip',
        //     text: 'Export Excel',
        //     buttons: [{
        //       extend: 'excelHtml5',
        //       text: 'Export Excel',
        //       filename: `TSR_${((new Date()).getTime())}`,
        //       title: 'Transaction Status Report',
        //     }],
        //   }, this.lstGrid);
        // });
      }
    });
  }

  onFromDateChange(val: Date) {
    if (val) {
      let toDate: Date = new Date(this.dtPipe.transform(val, 'yyyy-MM-dd'));
      this.appointmentForm.get('toDate').setValue(new Date(toDate.setDate(toDate.getDate()))), this.minDate = toDate;
    }
  }

  clear() {
    this.appointmentForm.value.fromDate = '', this.appointmentForm.value.toDate = '';
  }

  getDoctorDetails() {
    let user = this.authServ.currentUserValue;
    this.regService.getByID('doctor/getdoctorrecordbyuserid?userId=', user.id).subscribe(res => {
      if (res.data.length > 0)
        this.docId = res.data[0].id;
    }, err => { this.toastr.error(err) }, () => { this.LoadGrid() }); // this.LoadData();
  }

  xportXL(): void {
    let lstXL = [];
    this.lstGrid.forEach((e, i) => {
      lstXL.push({
        'S No': i + 1, 'Patient Name': e.patient_name, 'Patient Registration Number': e.patient_registration_number,
        'Mobile Number': e.mobile_number.toString(), 'Payment Date': this.dtPipe.transform(e.transaction_date, 'dd-MM-yyyy'), 'Amount': e.amount,
        'Order No.': e.order_no.toString(), 'Call Status': e.call_status, 'Transaction Status': e.payment_status ? 'True' : 'False'
      });
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(lstXL), wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, `Transaction Status Report`), XLSX.writeFile(wb, `TSR_${((new Date()).getTime())}.xlsx`);
  }

}