import { LabInvestigationComponent } from './../lab-investigation/lab-investigation.component';
import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterService } from 'src/app/shared/services/master.service';
import { ToastrService } from 'ngx-toastr';
import { Common } from 'src/app/common';
import { BaseComponent } from 'src/app/shared/base.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import * as XLSX from 'xlsx';
declare let $: any;
@Component({
  selector: 'app-lab-investigation-list',
  templateUrl: './lab-investigation-list.component.html',
  styleUrls: ['./lab-investigation-list.component.css'],
  providers: [NgbModalConfig, NgbModal]

})
export class LabInvestigationListComponent extends BaseComponent implements OnInit {
  modalTitle: string = '';
  investigationlist: any;
  fileName = 'lab Investigation list.xlsx';
  constructor(config: NgbModalConfig, private modalService: NgbModal,
    private masterService: MasterService, private toastr: ToastrService, private authSvc: AuthenticationService) {
      super();
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.authSvc.isShowGlblOrg.next(false), this.authSvc.tenantId = sessionStorage.getItem('defCrntTnnt')
    if (this.authSvc.roleId == 1) {
      sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt'));
    }
    this.getAllInvestigations()
  }

  openLabInvestigationModal(DataEdit: any = null) {
    const modalRef = this.modalService.open(LabInvestigationComponent);
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.DataEdit = DataEdit;
    modalRef.result.then(result => {
      if (result == 'changes saved')
        this.getAllInvestigations();
    }, (reason) => { });
  }

  getAllInvestigations() {
    this.masterService.getAll('getallinvestigations').subscribe(res => {
      if (res.status === "OK") {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Lab Investigation List');
        else
          this.investigationlist = res.data;
        Common.loadDataTable(this.investigationlist, '#LabInvestigation');
      }
    });
  }
  deleteItem(id: any) {
    if (confirm(`Are you sure you want delete record?`)) {
    this.masterService.deleteByinvestId(id).subscribe(res => {
      if (res.status == "OK") {
        // this.getAllStates();
        this.toastr.success('Record Deleted Successfully');
      }
    },
      error => { this.toastr.error('An Error Occured'); });
  }
}
exportexcel(): void {
  var table = $('#LabInvestigation').DataTable();
  if (!table.data().count()) {
    this.toastr.error('No data available in table! Please select and try again');
  }
  else {	
    / table id is passed over here /
    let element = document.getElementById('LabInvestigation');
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    / generate workbook and add the worksheet /
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    ws['!cols'] = [];
    ws['!cols'][10] = { hidden: true };

    / save to file /
    XLSX.writeFile(wb, this.fileName);
  }
}

}