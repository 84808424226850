import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder,Validators,ValidationErrors } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import { Subject, pipe } from 'rxjs';
import { VillageComponent } from '../village/village.component';
declare let $: any;
@Component({
  selector: 'app-village-list',
  templateUrl: './village-list.component.html',
  styleUrls: ['./village-list.component.css']
})
export class VillageListComponent implements OnInit {

  @Input() pharmaData: any;
  villageListForm: FormGroup; 
   submitted = false;
  modalTitle: string = '';
  dtOptions: any = {};
  dtTrigger: any = new Subject();
  villagelist:any;
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = []
  mandalList: Array<any> = [];
  villageList: Array<any> = [];

  constructor(
    config: NgbModalConfig, 
      private modalService: NgbModal,
      private toastr: ToastrService,
      private masterService: MasterService,
      private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.locationChanged('', 'state');
    this.initFormControls()

 
  }
  initFormControls() {
    this.villageListForm = this.formBuilder.group({
      state_id: ['', Validators.required],
      district_id: ['', Validators.required],
      mandal_id: ['', Validators.required],
    });
  }
  get f() { return this.villageListForm.controls; }

  openVillageModal() {
    const modalRef = this.modalService.open(VillageComponent,{scrollable:true,size:'lg'});
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.result.then(result => {
   
    }, (reason) => { });
  }

  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        this.mandalList = [];
        this.villageList = [];
        this.villageListForm.get('district_id').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      },
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,
          
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
        };
        assign_locations[type]();
      }
      this.districtList.sort((a,b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error));
  } 

  loadVillages() {

    this.submitted = true;
    if (this.villageListForm.invalid)
      return;
    else {
    var id = $('#state_id').val()
$('.dataTable').DataTable().destroy();
$('.dataTable').DataTable({ searching: false });
this.masterService.searchbyIdMasters(`getvillagebymandalid?mandalId=${id}`).subscribe((data: any) => {
  if (data.status === 'OK')
  this.villagelist = data.data;
this.dtTrigger.next();

});
}
  }
  setFocus(elementName: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementName)));
    el.value = '';
    el.focus();
  }
deleteItem(id: any) {
  // this.masterService.deleteByStateId(id).subscribe(res => {
  //   if (res.status == "OK") {
  //     this.toastr.success('Record Deleted Successfully');
  //   }
  // },
    // error => { this.toastr.error('An Error Occured'); });
}



}
