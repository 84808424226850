<div class="modal-header">
    <h4 class="modal-title">Enroll Corporate Employees</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="enrollform" (ngSubmit)="onSubmit($event)">
        <div class="row mt-1">
            <div class="col-xl-4">
                <div class="form-group">
                    <label>First Name</label>
                    <input type="text" class="form-control form-control-sm" formControlName="first_name" readonly>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="form-group">
                    <label>Last Name</label>
                    <input type="text"class="form-control form-control-sm" formControlName="last_name" readonly>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="form-group">
                    <label>Email</label>
                    <input type="text" class="form-control form-control-sm" formControlName="email" readonly>
                </div>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-xl-4">
                <div class="form-group">
                    <label>Mobile Number</label>
                    <input type="text" class="form-control form-control-sm" formControlName="mobile" readonly>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="form-group">
                    <label>Corporate ID</label>
                    <input type="text"class="form-control form-control-sm" formControlName="coporateId" readonly>
                </div>
            </div>
             <div class="col-xl-4">
                <div class="form-group">
                    <label>Employee.ID</label>
                    <input type="text" class="form-control form-control-sm" formControlName="employee_id" readonly>
                </div>
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-xl-4">
                <div class="form-group">
                    <label>Employee Category</label>
                    <select class="form-control form-control-sm" formControlName="employee_level_id" readonly>
                        <option value="">Select</option>
                        <option [value]="lvl.id" *ngFor="let lvl of employeeList">{{lvl.employee_level}}</option>
                    </select>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="form-group">
                    <label>Gender</label>
                    <select class="form-control form-control-sm" formControlName="gender_id" readonly>
                        <option value="">Select</option>
                        <option [value]="gender.id" *ngFor="let gender of genderList">{{gender.gender_name}}</option>
                    </select>
                </div>
            </div>
            <div class="col-xl-4">
                <div class="form-group">
                    <label>Address</label>
                    <textarea class="form-control form-control-sm" formControlName="address" rows="1" readonly></textarea>
                </div>
            </div>
        </div>
        <div class="col-xl-3">
            <label class="control-label">Status <span class="text-danger">*</span></label> <br />
            <div class="">
                <div class="custom-control-inline custom-control custom-radio">
                    <input formControlName="is_active" [value]="true" class="custom-control-input" id="customRadio1"
                        type="radio" name="is_active">
                    <label class="custom-control-label" for="customRadio1">Active </label>
                </div>
                <div class="custom-control-inline custom-control custom-radio">
                    <input formControlName="is_active" [value]="false" class="custom-control-input " id="customRadio2"
                        type="radio" name="is_active">
                    <label class="custom-control-label" for="customRadio2">In Active </label>
                </div>
            </div>
        </div>
        <input type="submit" id="btnsubmit" style="display: none;">
    </form>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" (click)="SubmitForm()" value="Submit">
    <button type="button" class="btn btn-sm btn-outline-dark" data-dismiss="modal"
        (click)="activeModal.close('Close click')">Close</button>
</div>