<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Add Role
                        <button class="btn btn-blue btn-icon float-right"
                            (click)="modalTitle='Create Role Details';openRoleModal()" *ngIf="setPrivilege(Screen_Name.RoleManagement, Permission.Add)">

                            <i class="fa fa-plus"></i>
                        </button>
                    </div>
                    <!-- <div class="card-header">
                        Add Role
                        <button class="btn btn-blue btn-icon float-right" >

                            <i class="fa fa-plus"></i>
                        </button>
                    </div> -->

                    <div class="card-body">
                        <div class="table-responsive">

                            <table datatable id="roleTable" class="table table-striped table-bordered table-sm"
                                style="width:100%">
                                <thead>
                                    <tr>
                                        <th>S No</th>
                                        <th>Role Name</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let role of roleList;let ind=index;">
                                        <td style="width:6%;" class="text-center">{{(ind+1)}}</td>
                                        <td>{{role.userType}}</td>
                                        <td style="width:15%;" class="text-center">
                                            <div (click)="modalTitle='Assign Permissions';openPermissionModal(role)"
                                                class="badge badge-secondary"><i class="fa fa-lock text-white"></i>
                                            </div>&nbsp; &nbsp;
                                            <div (click)="modalTitle='Update Role';openRoleModal(role)"
                                                class="badge badge-info" *ngIf="setPrivilege(Screen_Name.RoleManagement, Permission.Edit)"><i class="fa fa-edit text-white"></i></div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>

    </div>
</div>