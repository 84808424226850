<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                       Referral Registration
                        <button 
                        (click)="modalTitle='Patient Registration Details'; openPatientModal()"
                            class="btn btn-blue btn-icon float-right"><i class="fa fa-plus"></i> <!-- openModal() -->
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-xl-12">
                                </div> 
                            </div>
                        </div> 
                        <div class="table-responsive">
                            <table datatable id="centerregTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr style="background-color: lightskyblue">
                                        <th class="text-center" style="font-size:15px;">S.No</th>
                                        <th style="font-size:15px;">Beneficiary Id</th>
                                        <th style="font-size:15px;">Beneficiary Name</th>
                                        <th style="font-size:15px;">Date of Birth</th>
                                        <th style="font-size:15px;">Age</th>
                                        <th style="font-size:15px;">Gender</th>
                                        <th style="font-size:15px;">Schedule Date</th>
                                        <!-- <th style="font-size:15px;">Payment per Head </th> -->
                                        <!-- <th style="font-size:15px;">Mobile</th> -->
                                        <!-- <th style="font-size:15px;">Agent Name</th>
                                        <th style="font-size:15px;">Pharmacist</th> -->
                                        <th style="font-size:15px;">Status</th>
                                        <th style="font-size:15px;">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <!-- <tr *ngFor="let item of getAllcenters; let i = index">
                                        <td>{{i+1}}</td>
                                        <td>{{item.center_code}}</td>
                                        <td>{{item.center_name}}</td>
                                        <td>{{item.districtName}}</td>
                                        <td>{{item.mandalName}}</td>
                                        <td>{{item.villageName}}</td>
                                        <td>
                                     
                                            <div *ngFor="let itemv of item.centermapping; let i = index">
                                                {{itemv.paymentType}} / {{itemv.payment_per_head}}
                                            </div>
                                           
                                        </td>
                                       
                                    
                                        <td>{{item.is_active == true ? 'Active' : 'InActive'}}</td>

                                        <td class="text-center">
                                            <span>
                                                <a class="pointer" style="cursor: pointer;" (click)="onAddEdit(item)"
                                                    title="Edit"> <i class="fa fa-edit text-primary"></i></a>
                                            </span>&nbsp;&nbsp;
                                        
                                        </td>
                                    </tr> -->
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>