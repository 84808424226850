import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Common } from 'src/app/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';

declare let $: any;

@Component({
  selector: 'app-handoverform-report',
  templateUrl: './handoverform-report.component.html',
  styleUrls: ['./handoverform-report.component.css']
})
export class HandoverformReportComponent implements OnInit {
  
  handOverForm: FormGroup;
  start_date: any = new Date();
  end_date: any = new Date();
  max_date = new Date();
  handOverlist:any = []; 
  uploaded_file_with_signArray = new Array();
  minDate: Date; minToDate: Date; docId = '';
  submitted = false; userNames = [];
  userList: Array<any> = [];
  showExport: boolean = true;
  fileName = 'HandOver Report.xlsx';
  excelData: any;
  pipe = new DatePipe('en-US');
  mandalList: any[];
  villageList: any[];
  cityList: any[];
  districtList: any[];
  stateList: any;
  hospitalList: any;
  departmentList: any[];
  isNIG = environment.isNIG;
  getagents:any
  constructor(private regService: RegistrationService
    , protected toastr: ToastrService
    , private formBuilder: FormBuilder
    , private datePipe: DatePipe
    , private masterService: MasterService,
    private excelDownloadService:ExcelDownloadService
     , private registrationService: RegistrationService
    , private authSvc: AuthenticationService) {
    // this.minDate = new Date();
    // this.minDate.setDate(this.minDate.getDate());

  }

  ngOnInit(): void {
    this.initFormControls(), this.getallUsers();
    this.handOverlist = [];
    Common.loadDataTable(this.handOverlist, '#handoverReport');
    this.locationChanged('', 'state');
    this.locationChanged('5', 'district');//Training
    // this.locationChanged('', 'state'),
    // this.locationChanged('5', 'district');
    this.registrationService.getallagentspharmacy().subscribe(data => {
      this.getagents=data.data;
    })
    // this.LoadData()
  }

  initFormControls() {
    this.handOverForm = this.formBuilder.group({
      start_date: [new Date(), Validators.required],
      end_date: [new Date(), Validators.required],
      // state_id: [36, Validators.required],//QA pointing
      state_id: ['5', Validators.required],
      district_id: [null],
      agent_id:[null]

    });
  }

  get f() { return this.handOverForm.controls; }

  getallUsers() {
    this.regService.getallUserTypes().subscribe(res => {
      if (res.status == 'OK') {
        this.userList = res.data
      } else {
        this.userList = [];
        this.toastr.error(res.err)
      }
    })
  }
  onUserChange(e: any) {
    this.userNames = [];
    this.handOverForm.patchValue({'user_id':null});
    this.getAllUserNames();
  }
  getAllUserNames() {
    let UserType = this.handOverForm.value.user_type;
    this.regService.getAllUsers(UserType).subscribe(res => {
      if (res.status == 'OK') {
        if(res.data.length > 0){
          this.userNames = res.data;
        } 
        else {
          this.userNames = [];
          this.toastr.info('No Records Found');
        }
      } 
    })
  }
  LoadData() {
    let sendData = JSON.parse(JSON.stringify(this.handOverForm.value));
    [sendData.from_date, sendData.to_date] =
      [this.datePipe.transform(sendData.start_date, 'yyyy-MM-dd '),
      this.datePipe.transform(sendData.end_date, 'yyyy-MM-dd ')];
      delete sendData.start_date;
      delete sendData.end_date;
    if (this.handOverForm.value.agent_id == "") {
      this.toastr.error('Please Select Pharmacist/Healthcare Professional');
      return;
    }
    // console.log('handover send data',sendData);
    // console.log('handOverlist1',this.handOverlist);
    this.regService.gethandoverReport(sendData).subscribe(res => {
      this.handOverlist = res;

      this.handOverlist.map(res =>{
           var temp = res.uploaded_file_with_sign;
           if(temp && temp!=null ){
             temp= temp.split(',');
             res.uploaded_file_with_sign=temp;
           }
         
           Common.loadDataTable(this.handOverlist, '#handoverReport');  
      })
      // uploaded_file_with_sign

      // for (const iterator of  this.handOverlist) {
      //   console.log('iterator',iterator.uploaded_file_with_sign.split(','));
        
      // }

      // for (let i = 0; i < this.handOverlist.length; i++) {
      //   var  element = this.handOverlist[i];
      //      element.map(res =>{
      //       res.someArrary = res.uploaded_file_with_sign.split(',');
      //      })
      //      console.log('element...',element);
      // }

    
 
       //console.log('uploaded_file_with_signArray',this.handOverlist);
      
    
        this.excelData = this.handOverlist;
        Common.loadDataTable(this.handOverlist, '#handoverReport');
     
    });
   
  }

  onFromDateChange(val: Date) {
    if (val) {
      let start_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.handOverForm.get('end_date').setValue(new Date(start_date.setDate(start_date.getDate())));
      this.minDate = start_date;
    }
  }
  //new line added for on To date change 11-04-2022
  onToDateChange(val: Date) {
    if (val) {
      let end_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.handOverForm.get('end_date').setValue(new Date(end_date.setDate(end_date.getDate())));
      //this.minDate = end_date;
    }
  }

  clear() {
    this.initFormControls();
    this.handOverlist = [];
    Common.loadDataTable(this.handOverlist, '#handoverReport');
  }
 
 

  // 22-07-22
  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        // this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];

        // this.labForm.get('state_id').setValue('');
        this.handOverForm.get('district_id').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        // if (+id > 0)
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        // if (+id > 0)
        //   query = `getstatebyid?stateId=36`;
        query = `getstatebyid?stateId=5`;
      },
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }
  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        // console.log('stateList', this.stateList);
        
        
        
        assign_locations[type]();
      }
      this.districtList.sort((a, b) => 0 - (a.district_name > b.district_name ? -1 : 1));
      // console.log('districtList', this.districtList);
    }, error => this.toastr.error(error));
  }


  // Agent List
  agentchange(agentid){
    if(agentid!='null'){
      this.handOverForm.value.agent_id=agentid;
    }
  
    
  }
  // exportexcel
  exportexcel(): void {

    var table = $('#handoverReport').DataTable();

    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {
      / table id is passed over here /
      let element = document.getElementById('handoverReport');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);


      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };


      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
  }

  downLoadExcel(){
    const fileName = 'Handover_Report'
      var excelData = this.excelData;
      if(excelData.length>0){
        excelData.map(res =>{
          res.formated_handoverReport = this.pipe.transform(res.handoverReport, 'dd-MM-yyyy');
        })
          const excelHeaders = ['Date', 'District Name', 'Pharmacist/Healthcare Professional','Pharmacist/Healthcare Professional Number','Center Type','Center Name','Handover File', 'Uploaded File'];
          const internalHeaders = ['generated_date', 'district_name', 'agent_name','agnet_number','center_type','center_name','generated_file', 'uploaded_file_with_sign'];
      //  const excelHeaders = ['User Name', 'Mobile','Email','Attendance Date','Start Time','End Time'];
      //  const internalHeaders = ['user_name', 'mobile','email','formated_attendance_date','start_time','end_time'];
        this.toastr.success('Please wait excel is downloading')
        this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
      }
      else{
        this.toastr.error('No data to download')
      }
    
  }
  
}
