<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Patient List 
                        <div  class='col-md-2' *ngIf="paitientRegDisabledDocFlag || paitientRegDisabledAdminFlag">
                            <button data-toggle="modal"
                            (click)="modalTitle='Patient Registration Details'; openPatientModal()"
                                   class="btn btn-blue btn-icon float-right"> <i class="fa fa-plus"></i> 
                               </button>
                        </div>
                    </div>
                    <div class="card-body">
                        <button type="button" style="display: none;" id="pocforopennew" (click)="showHideVideo2()"></button>
                        <button type="button" style="display: none;" id="navigatepoc" (click)="navigatepocclick()"></button>
                       
                        <form [formGroup]="patientSearchForm" method="POST" (ngSubmit)="getPatientsData(0)">
                            <div class="row " *ngIf="showpatientlistdiv">
                                <div class="container">
                                    <div class="row ">
                                        <div class="col-md-1">
                                            <label class="control-label" style="line-height:3"><strong>Keyword:</strong></label>
                                        </div>
                                        <div class='col-md-5'>
                                            <div class="form-group">
                                                <input id="search_string" name="search_string" type="text" autocomplete="off" formControlName="search_string" 
                                                    class="form-control" placeholder="Search by : Name / Email / Mobile Number"  maxlength="50" ngMaxlength=50 value="{{search_string}}">
                                                    <small class="text-warning text-center" style="margin-left:30px;">( Search by : Name / Email / Mobile Number )</small> 
                                            </div>
                                        </div>
                                        <div class='col-md-2'>
                                            <button style="margin-top:4px;" type="submit" class="btn btn-sm btn-primary" value="Submit"><i class="fa fa-search"></i> Search</button>
                                        </div>
                                        <div class="col-md-1" >
                                          <button type="button" style="margin-top:4px;" class="btn  btn-sm btn-secondary float-right" (click)="downLoadExcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                                      </div>
                                       
                                    </div>                                    
                                    </div>
                                </div>
                            </form>
                            <div *ngIf="showpatientlistdiv">
                            <div class="table-responsive" *ngIf="patientList.length > 0 && submitted;else empty_tbl_response">
                            <table datatable id="patientTable" class="table table-striped table-bordered table-hover table-sm"
                                style="width:100%">
                                <thead class="text-center">
                                    <tr>
                                        <th>S.No</th>
                                        <th>Patient Name </th>
                                        <th>Mobile Number</th>
                                        <th>Email</th>
                                        <th>Date of Birth</th>
                                        <th>Age</th>
                                        <th *ngIf="hideLinkWithAbha">ABHA Number</th>
                                        <th *ngIf="hideLinkWithAbha">ABHA Address</th>
                                        <th *ngIf="hideLinkWithAbha">Add Consent</th>
                                        <th colspan="4">Action</th>
                                        <!-- <th *ngIf="+roleId==14" colspan="2">Action</th> -->
                                        <!-- <th>DSS</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of patientList;let ind=index;">
                                        <td class="text-center">{{(rowIndex+1+ind)}}</td>
                                        <!-- <td class="singleLine text-uppercase">{{item.first_name}} {{item.middle_name}} {{item.last_name}}</td> -->
                                        <td class="singleLine text-uppercase">{{item.fullname}}</td>
                                       
                                        <td>{{item.mobile}}</td>
                                        <td>{{item.email}}</td>
                                        <td>{{item.birth_date | date:'dd-MM-yyyy'}}</td>
                                        <td>{{item.age}}</td>
                                        <td *ngIf ="item.health_id_number && hideLinkWithAbha">{{item.health_id_number}}</td>
                                        <td *ngIf ="item.health_id_number == null && hideLinkWithAbha"  style="text-align: center !important;"><button class="btn btn-sm btn-primary"  (click)="modalTitle='Patient Registration Details';onHealthID(item)">Link With ABHA</button></td>
                                        <td *ngIf ="hideLinkWithAbha">{{item.health_id}}</td>
                                        <td *ngIf ="(item.health_id_number == '' || item.health_id_number == null) && hideLinkWithAbha" style="text-align: center !important;">No ABHA Address</td>
                    
                                        <td *ngIf ="(item.health_id_number!== '' && item.health_id_number!== null) && hideLinkWithAbha"  style="text-align: center !important;"  style="text-align: center !important;"><button class="btn btn-sm btn-primary" (click)="addConsent(item)">Add Consent</button></td>
                                       
                                        <td style= "display: flex !important;justify-content: center;align-items: end !important; " >
                                      
                                          <button *ngIf="+roleId==7" class="btn btn-sm btn-success ml-1" (click)="OfflinePocSubmit(item)">POC</button>
                                          <button *ngIf="+roleId!=14" class="btn btn-sm btn-success ml-1" (click)="getpatientid(item)">EHR</button>
                                         <!-- below line commented for mom changes 15-02-2023 -->
                                          <!-- <button *ngIf="+roleId==7 || +roleId==2" class="btn btn-sm btn-success ml-1 mt-1 btn-responsive" data-toggle="modal"
                                            (click)="modalTitle='Patient Registration Details';familyList(item)">Family Members</button> -->
                                            <button *ngIf="familyMemberButtonHide" class="btn btn-sm btn-success ml-1 mt-1 btn-responsive" data-toggle="modal"
                                            (click)="modalTitle='Patient Registration Details';familyList(item)">Family Members</button>
                                           
                                            <!-- <button *ngIf="+roleId==14" class="btn btn-sm btn-success ml-1" (click)="opendoclist(item)">Call</button> -->
                                            <button *ngIf="+roleId==14" class="btn btn-sm btn-success ml-1" (click)="getpid(item)"  data-toggle="modal" data-target="#vitalscapture">Capture Vitals</button>
                                            <button *ngIf="+roleId==14" style="width: 130px;" class="badge badge-dark ml-1 mt-1" type="button" (click)="openVitalFileModal(item.id)">
                                              <i class="" aria-hidden="true"></i> <strong> View Vitals History</strong>
                                            </button>
                                          
                                            <!-- <div class="badge badge-primary badge-pill ml-1" title="Health Card" style="cursor: pointer;"
                                            *ngIf="+roleId!=14">
                                            <a (click)="onviewhealthcard(item.id)"> <i class="fa fa-eye text-white"></i></a> 
                                            
                                          </div> -->
                                          <!-- <div class="badge badge-info badge-pill ml-2" title="Download Health Card" style="cursor: pointer;"
                                          *ngIf="+roleId!=14"   >
                                          <a (click)="downloadfile(item.id)"> <i
                                            
                                              class="fa fa-download text-white"></i></a>
                                        </div> -->
                                        </td>
                                          <!-- <td ><button *ngIf="+roleId==7 || +roleId==14" class="btn btn-sm btn-success ml-3 btn-responsive" data-toggle="modal"
                                            (click)="modalTitle='Patient Registration Details';familyList(item)">Family Members</button></td> -->
                                        <!-- <div class="row">
                                            <td ><button *ngIf="+roleId==14" class="btn btn-sm btn-success" (click)="opendoclist(item)">Call</button></td>
                                        <td ><button *ngIf="+roleId==14" class="btn btn-sm btn-success" (click)="getpid(item)"  data-toggle="modal" data-target="#vitalscapture">Capture Vitals</button></td>
                                        
                                        <td > <button *ngIf="+roleId==14" class="badge badge-dark" type="button" (click)="openVitalFileModal(item.id)">
                                            <i class="" aria-hidden="true"></i> <strong> View Vitals History</strong>
                                          </button></td>
                                    </div> -->
                                        <!-- <td><button class="btn btn-sm btn-dark" (click)="openDSSForm()">DSS</button></td> -->
                                    </tr>
                                </tbody>
                            </table>
                            <div class="row">
                            <div class="col-sm-12 col-md-5">
                                <div >
                                    Showing {{(rowIndex+1)}} to {{(rowIndex+patientList.length)}} of {{totalElements}} entries.
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-7">
                            <div >
                                <nav aria-label="Page navigation example">
                                    <ul class="pagination justify-content-end">
                                    <li class="page-item" *ngIf="PrevpageNumber >= 0" ><a  class="page-link" (click)="getPatientsData(PrevpageNumber)" style="cursor:pointer"><span>Previous</span></a></li>
                                    <li  class="page-item active" aria-current="page" *ngIf="NextpageNumber > 0"><a class="page-link " (click)="getPatientsData(NextpageNumber)" style="cursor:pointer"><span>Next</span></a></li>
                                    </ul>
                                </nav>
                            </div>
                            </div>
                            </div>
                        </div>
                      </div>
                        <ng-template #empty_tbl_response><div class="text-danger">No matching records found</div></ng-template>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>
<div class="modal fade" data-backdrop="static" data-keyboard="false" style="z-index: 99999 !important;"
  id="vitalscapture" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Patient History</h5> -->
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="patvitals" >
        <div>
            <div class="card mt-1 shadow cardrounded h-100">
              <div class="card-header"><strong> Vitals </strong></div>
              <div class="card-body">
                <div class="form-row small">
                 
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Temperature</label>
                    <div class="input_wrapper">
                      <input type="text" formControlName="temperature" id="temperature"
                        (change)="onTempChng($event.target.value)" maxlength="5"
                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46'
                        [ngClass]="{ 'is-invalid': submitted && f.temperature.errors }"
                        class="form-control form-control-sm text-uppercase" (keyup)="onTempChng($event.target.value)" />
                      <div class="unit">&deg; F</div>
                      <div *ngIf="patvitals.get('temperature').errors" class="invalid-feedback">
                        <div>Accept value from 70 to 120</div>
                      </div>
                      <div *ngIf="tempErrMsg" class="invalid-feedback">
                        <div> Enter Valid Decimal format. Eg.: 98.7 or 100.2 </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Sugar(mg/dl)</label>
                    <div class="input_wrapper">
                      <input type="text" autocomplete="off" formControlName="diabetic_value" id="diabetic_value"
                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57) ' maxlength="3"
                        class="form-control form-control-sm text-uppercase"
                        [ngClass]="{ 'is-invalid': submitted && f.diabetic_value.errors }">
                        <div *ngIf="patvitals.get('diabetic_value').errors" class="invalid-feedback">
                            <div *ngIf="patvitals.get('diabetic_value').errors.min">Accept value from 35 to 380
                            </div>
                            <div *ngIf="patvitals.get('diabetic_value').errors.max">Accept value from 35 to 380
                            </div>
                            <div *ngIf="patvitals.get('diabetic_value').errors.required">Sugar is required</div>
                          </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-row small">
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Systolic</label>
                    <div class="input_wrapper">
                      <input type="text" autocomplete="off" formControlName="systolic" id="systolic"
                        (keyup)="keyUp('systolic')" (change)="onSysDia()"
                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' maxlength="3"
                        [ngClass]="{ 'is-invalid': submitted && f.systolic.errors }"
                        class="form-control form-control-sm text-uppercase">
                      <div class="unit">mmHg</div>
                      <div *ngIf=" patvitals.get('systolic').errors" class="invalid-feedback">
                        <div *ngIf="patvitals.get('systolic').errors.min">Accept value from 50 to 400</div>
                        <div *ngIf="patvitals.get('systolic').errors.max">Accept value from 50 to 400</div>
                        <div *ngIf="patvitals.get('systolic').errors.sys">Enter Valid Value</div>
                        <div *ngIf="patvitals.get('systolic').errors.required">Systolic is required</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Diastolic</label>
                    <div class="input_wrapper">
                      <input type="text" autocomplete="off" formControlName="diastolic" id="diastolic"
                        (keyup)="keyUp('diastolic')" (change)="onSysDia()"
                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57) ' maxlength="3"
                        [ngClass]="{ 'is-invalid': submitted && f.diastolic.errors }"
                        class="form-control form-control-sm text-uppercase">
                      <div class="unit">mmHg</div>
                      <div *ngIf=" patvitals.get('diastolic').errors" class="invalid-feedback">
                        <div *ngIf="patvitals.get('diastolic').errors.min"> Accept value from 30 to 200
                        </div>
                        <div *ngIf="patvitals.get('diastolic').errors.max"> Accept value from 30 to 200
                        </div>
                        <div *ngIf="patvitals.get('diastolic').errors.dia">Diastolic should be less than
                          Systolic</div>
                        <div *ngIf="patvitals.get('diastolic').errors.required">Diastolic is required</div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="form-row small">
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Pulse</label>
                    <div class="input_wrapper">
                      <input type="text" autocomplete="off" formControlName="pulse" id="pulse" (keyup)="keyUp('pulse')"
                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' maxlength="3" max="200"
                        [ngClass]="{ 'is-invalid': submitted && f.pulse.errors }"
                        class="form-control form-control-sm text-uppercase">
                      <div class="unit">bpm</div>
                      <div *ngIf=" patvitals.get('pulse').errors" class="invalid-feedback">
                        <div *ngIf="patvitals.get('pulse').errors.min">Accept value from is 30 to 200</div>
                        <div *ngIf="patvitals.get('pulse').errors.max">Accept value from is 30 to 200</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">SPO2%</label>
                    <div class="input_wrapper">
                      <input type="text" autocomplete="off" formControlName="oxigen_count" id="oxigen_count"
                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57) ' maxlength="3"
                        [ngClass]="{ 'is-invalid': submitted && f.oxigen_count.errors }"
                        class="form-control form-control-sm text-uppercase"
                        >
                      <div *ngIf="patvitals.get('oxigen_count').errors" class="invalid-feedback">
                        <div *ngIf="patvitals.get('oxigen_count').errors.min">Accept value from 40 to 205
                        </div>
                        <div *ngIf="patvitals.get('oxigen_count').errors.max">Accept value from 40 to 205
                        </div>
                        <div *ngIf="patvitals.get('oxigen_count').errors.required">SPO2% is required</div>
                      </div>
                    </div>
                  </div>
              </div>
              <div class="form-row small">
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Height(cm)</label>
                    <div class="input_wrapper">
                      <input type="text" autocomplete="off" formControlName="height" id="height"
                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57) ' maxlength="6"
                        [ngClass]="{ 'is-invalid': submitted && f.height.errors }"
                        (keyup)="onTempChng($event.target.value)" class="form-control form-control-sm text-uppercase"
                        >
                      <div class="unit">cm</div>
                      <div *ngIf="patvitals.get('height').errors" class="invalid-feedback">
                        <div *ngIf="patvitals.get('height').errors.min">Accept value from 30 to 220.99</div>
                        <div *ngIf="patvitals.get('height').errors.max">Accept value from 30 to 220.99</div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-md-6">
                    <label for="inputEmail4">Weight</label>
                    <div class="input_wrapper">
                      <input type="text" autocomplete="off" formControlName="weight" id="weight"
                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57) ' maxlength="6"
                        [ngClass]="{ 'is-invalid': submitted && f.weight.errors }"
                        class="form-control form-control-sm text-uppercase"
                        >
                      <div class="unit">kg</div>
                      <div *ngIf="patvitals.get('weight').errors" class="invalid-feedback">
                        <div *ngIf="patvitals.get('weight').errors.min">Accept value from 1 to 200.99</div>
                        <div *ngIf="patvitals.get('weight').errors.max">Accept value from 1 to 200.99</div>
                      </div>
                    </div>
                  </div>
              </div>
              <button type="button" class="btn btn-sm btn-secondary" (click)="submitvitals()">Submit</button>
                  <!-- <div class="form-group col-md-6">
                    <button class="badge badge-dark" type="button" (click)="openVitalFileModal()">
                      <i class="" aria-hidden="true"></i> <strong> View Vitals History</strong>
                    </button>
                  </div> -->
              
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button> -->
        <!-- <button class="badge badge-dark" type="button" (click)="openVitalFileModal()">
            <i class="" aria-hidden="true"></i> <strong> View Vitals History</strong>
          </button> -->
      </div>
    </div>
  </div>
</div>
<div class="modal fade" data-backdrop="static" data-keyboard="false" style="z-index: 99999 !important;"
  id="videofrshow" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-md" style="width: 850px !important" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Patient History</h5> -->
        <!-- <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> -->
      </div>
      <div class="modal-body">
        <div>
          <div class="">
            <div class="drag" id="drogVideoN">
              <div class="card videobg shadow videoalign">
                <div class="card-header pb-1 pt-1 text-white mx-auto">Video Consultation</div>
                <div class="card-body pt-1">
                  <div id="conference_div">
                    <div id="container" style="height:285px !important;">
                      <!-- <video id="localStream" autoplay controls
              class='localStream doc_video'></video>
          <video muted autoplay controls class='remoteStream pat_video'></video> -->
                      <!-- <openvidu-webcomponent style="display: none;"></openvidu-webcomponent> -->
                    </div>
                    <div>
                      <input id="convId" type="hidden" />
                    </div>
                    <div>
                      <ul id="log" style="display: none;"></ul>
                      <!-- <button onclick="window.app.leaveConversation()"
                            class="btn btn-sm btn-danger">Hang Up</button>&nbsp;
                        <button class="btn btn-sm btn-success">Confirm</button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="card videobg shadow videoposition videoalign">
            <div class="card-header pb-1 pt-1 text-white mx-auto">Video Consultation</div>
            <div class="card-body p-4"></div>
          </div> -->
        </div>
      </div>
      <div class="modal-footer">
        <!-- <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal">Close</button> -->
        <!-- <button class="badge badge-dark" type="button" (click)="openVitalFileModal()">
            <i class="" aria-hidden="true"></i> <strong> View Vitals History</strong>
          </button> -->
      </div>
    </div>
  </div>
</div>