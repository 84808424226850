import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

import { MasterService } from 'src/app/shared/services/master.service';
import { Common } from 'src/app/common';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-coupon-details',
  templateUrl: './coupon-details.component.html',
  styleUrls: ['./coupon-details.component.css'],
  providers: [DatePipe]
})

export class CouponDetailsComponent implements OnInit {

  @Input() title: string;
  @Input() DataEdit: any;

  couponForm: FormGroup;
  submitted = false;
  minDate: Date; minToDate: Date;
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  companyList: any;
  maxNOTPU = 1000;
  
  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private datePipe: DatePipe
    , private formBuilder: FormBuilder
    , private masterService: MasterService
    , private userservice: UserService) {
    this.minDate = new Date(), this.minDate.setDate(this.minDate.getDate());
  }

  ngOnInit(): void {
    this.getAllCompanies(), this.initFormControls(), this.customerTypeValidators(), this.setLocationTypeValidators();
    if (this.DataEdit) {
      let [sd, ed, fsd, fed, cId] = [this.DataEdit.start_date, this.DataEdit.expiry_date, '', '', this.DataEdit.company?.id ?? ''];
      [fsd, fed] = [this.datePipe.transform(sd, "dd-MM-yyyy"), this.datePipe.transform(ed, "dd-MM-yyyy")]
      this.couponForm.patchValue(this.DataEdit), this.couponForm.patchValue({ orgnization_company_id: cId, start_date: fsd, expiry_date: fed,
        coupon_apply_id : this.DataEdit.coupon_apply_id.id }), setTimeout(() => { this.couponForm.patchValue({ expiry_date: fed }); }, 100);
    }
    this.couponForm.get('orgnization_company_id').setValidators(null); // this.couponForm.get('orgnization_orgnization_company_id').setValue('');
  }

  initFormControls() {
    this.couponForm = this.formBuilder.group({
      id: [0],
      coupon_code: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(10)]], // ['', [Validators.required, Validators.pattern('[A-Z|a-z]{4}[a-zA-Z0-9]{5}$')]],
      coupon_apply_id: ['1', Validators.required],
      discount_flat: [''],
      discount_percentage: [''],
      max_discount_amt: [''],
      max_utilization_per_user: ['', [Validators.required, Validators.min(1), Validators.max(this.maxNOTPU)]],
      is_corporate: [false],
      orgnization_company_id: ['', Validators.required],
      max_utilization_count: ['', [Validators.required, Validators.min(1), Validators.max(1000)]],
      min_payment_applicable: ['', [Validators.required, Validators.min(300), Validators.max(1000)]],
      start_date: ['', Validators.required],
      expiry_date: ['', Validators.required],
      coupon_name: ['', Validators.required],
      utilized_count: [0],
      is_active: [true],
      is_absolute: [false]
    });
  }

  get f() { return this.couponForm.controls; }

  onFromDateChange(val) {
    this.minToDate = new Date(), this.couponForm.get('expiry_date').setValue('');
    if (val != null) {
      let edate = this.datePipe.transform(val, 'yyyy-MM-dd').split('-'), cnvDate = new Date(parseInt(edate[0]), parseInt(edate[1]) - 1, parseInt(edate[2]), 0, 0, 0, 0);
      this.minToDate = new Date(cnvDate);
    }
  }

  setLocationTypeValidators() {
    const [discount_flatControl, discount_percentageControl, max_discount_amtControl] = [this.couponForm.get('discount_flat'), this.couponForm.get('discount_percentage'), this.couponForm.get('max_discount_amt')];
    this.couponForm.get('is_absolute').valueChanges
      .subscribe(is_absolute => {
        if (is_absolute == true) {
          discount_flatControl.setValidators([Validators.required, Validators.min(100), Validators.max(1000)]);
          discount_percentageControl.setValue('');
          max_discount_amtControl.setValue('');
          discount_percentageControl.setValidators(null);
          max_discount_amtControl.setValidators(null);
        }
        else if (is_absolute == false) {
          discount_flatControl.setValidators(null);
          discount_flatControl.setValue('');
          discount_percentageControl.setValidators([Validators.required, Validators.min(10), Validators.max(100)]); // 99
          max_discount_amtControl.setValidators([Validators.required, Validators.min(100), Validators.max(1000)]);
        }
        else {
          discount_percentageControl.setValue('');
          max_discount_amtControl.setValue('');
          discount_flatControl.setValue('');
          discount_flatControl.setValidators(null);
          discount_percentageControl.setValidators(null);
          max_discount_amtControl.setValidators(null);
        }
        discount_flatControl.updateValueAndValidity();
        discount_percentageControl.updateValueAndValidity();
        max_discount_amtControl.updateValueAndValidity();
      });
  }

  customerTypeValidators() {
    const [company_Control] = [this.couponForm.get('orgnization_company_id')];
    this.couponForm.get('is_corporate').valueChanges.subscribe(isCompany => {
      if (isCompany)
        company_Control.setValidators([Validators.required]);
      // else if (isCompany == false) {
      //   company_Control.setValidators(null);
      //   company_Control.setValue('');
      // }
      else
        company_Control.setValue(''), company_Control.setValidators(null);
      company_Control.updateValueAndValidity();
    });
  }

  onDiscPerChng(val: any) {
    if (val != '') {
      if (+val > 100)
        this.couponForm.get('discount_percentage').setValue('100');
    }
  }

  onSubmit(event) {
    this.submitted = true, Common.getFormValidationErrors(this.couponForm);
    if (this.couponForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    const postData = JSON.parse(JSON.stringify(this.couponForm.getRawValue()));
    postData.coupon_code = postData.coupon_code.toUpperCase();
    if (typeof postData.start_date == "string") {
      if (!postData.start_date.toString().includes('000Z')) {
        let sDt = postData.start_date.split('-'), stDt = `${sDt[2]}-${sDt[1]}-${sDt[0]}`;
        postData.start_date = stDt + this.datePipe.transform((new Date()), ' HH:mm:ss');
      }
      else
        postData.start_date = this.datePipe.transform(postData.start_date, 'yyyy-MM-dd HH:mm:ss');
    }
    if (typeof postData.expiry_date == "string") {
      if (!postData.expiry_date.toString().includes('000Z')) {
        let sDt = postData.expiry_date.split('-'), stDt = `${sDt[2]}-${sDt[1]}-${sDt[0]}`;
        postData.expiry_date = stDt + this.datePipe.transform((new Date()), ' HH:mm:ss');
      }
      else
        postData.expiry_date = this.datePipe.transform(postData.expiry_date, 'yyyy-MM-dd HH:mm:ss');
    }
    // postData.start_date = this.datePipe.transform(postData.start_date, 'yyyy-MM-dd HH:mm:ss');
    // postData.expiry_date = this.datePipe.transform(postData.expiry_date, 'yyyy-MM-dd HH:mm:ss');
    this.userservice.save('saveandupdatecoupon', postData).subscribe(
      res => {
        if (res.status === 'OK') {
          this.toastr.success(res.message, 'Coupon Details');
          this.activeModal.close('changes saved');
        }
        else if (res.message == 'Duplicate record found')
          this.toastr.error(res.message, 'Coupon Details');
      }
    );
  }

  submitForm() {
    document.getElementById('btnsubmit').click();
  }

  getAllCompanies() {
    this.userservice.getAll('organization/allorganizationrecords').subscribe(res => {
      if (res.status === "OK")
        this.companyList = res.data;
    });
  }

  onChngMUC(val: any) {
    this.maxNOTPU = (val != '') ? +val : 1000, this.couponForm.get('max_utilization_per_user').setValidators([Validators.max(this.maxNOTPU)]),
      this.couponForm.get('max_utilization_per_user').updateValueAndValidity();
  }

}