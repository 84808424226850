import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { RegistrationService } from 'src/app/shared/services/registration.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { PharmacyRegistrationComponent } from '../pharmacy-registration/pharmacy-registration.component';
import { Common } from 'src/app/common';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import * as XLSX from 'xlsx';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';

declare let $: any;

@Component({
  selector: 'app-pharmacy-list',
  templateUrl: './pharmacy-list.component.html',
  styleUrls: ['./pharmacy-list.component.css']
})

export class PharmacyListComponent extends BaseComponent implements OnInit {

  modalTitle: string = ''; pharmacyList: Array<any> = [];
  fileName = 'Pharmacy list.xlsx';
  excelData: any=[];

  constructor(config: NgbModalConfig, private modalService: NgbModal,private registrationService: RegistrationService
    , private toastr: ToastrService
    , private authSvc: AuthenticationService,
    private excelDownloadService:ExcelDownloadService
    , private regService: RegistrationService) {
    super();
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    authSvc.crntOrgIDSubject.subscribe(s => {
      this.pharmacyList = [], Common.loadDataTable(this.pharmacyList, '#pharmacyTable');
      if (location.href.includes('home/pharmacyList'))
        this.ngOnInit();
    }), authSvc.isShowGlblOrg.next(true);
  }

  ngOnInit(): void {
    this.getAllPharmacy();
  }

  // openPharmacyModal() {
  //   const modalRef = this.modalService.open(PharmacyRegistrationComponent, { scrollable: true, size: 'xl' });
  //   modalRef.componentInstance.title = this.modalTitle;
  //   modalRef.result.then(result => {
  //     this.getAllPharmacy();
  //   }, (reason) => { });
  // }

  ChkLimit(): boolean {
    let result: boolean;
    this.regService.chkLimit('pharmacy').subscribe(res => {
      if (res) {
        if (res.status === "OK")
          result = true, this.openPharmacyModal(null);
        else
          result = false, this.toastr.warning(res.message);
      }
    });
    return result;
  }

  getAllPharmacy() {
    // if (this.pharmacyList.length > 0) {
    //   $('.dataTable').DataTable().destroy();;
    //   $('.dataTable').DataTable({ destroy: true, searching: false });
    // }
    // this.regService.getByAllPharmas().subscribe(res => { #this line is commented due to performance 07-07-2022
    this.regService.getByAllPharmasNew().subscribe(res => {
      // console.log('Get all Pharmacy',JSON.stringify(res));
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Pharmacy List');
        else
          this.pharmacyList = res.data,
          this.excelData = this.pharmacyList;
          Common.loadDataTable(this.pharmacyList, '#pharmacyTable'); // setTimeout(() => $(`#pharmacyTable`).DataTable({ destroy: true }));
      }
    });
  }

  openPharmacyModal(pharma: any) {
    //console.log('pharma result',pharmaNew);
   if(pharma!==null){
    const val = this.registrationService.getByPharmaId(pharma.pharmacy_id).toPromise().then(res=>{
      if (res.status == "OK") {
          
        pharma= res.data;
        const modalRef = this.modalService.open(PharmacyRegistrationComponent, { scrollable: true, size: 'xl' });
        modalRef.componentInstance.title = this.modalTitle;
        modalRef.componentInstance.pharmaData = pharma;
        modalRef.result.then(result => {
          if (result == 'changes saved' || result=='Close click'){
            this.getAllPharmacy();
          } 
        }, (reason) => { });
     }
    });
   
   } else {
    pharma= null;
    const modalRef = this.modalService.open(PharmacyRegistrationComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.pharmaData = pharma;
    modalRef.result.then(result => {
      if (result == 'changes saved'|| result=='Close click')
        this.getAllPharmacy();
    }, (reason) => { });

   }  error => this.toastr.error(error.message);
  
    // this.registrationService.savePharma(pharmaNew.id).then(res => {
    
    // }, 
  }


  deleteItem(id: any) {
    if (confirm(`Are you sure you want delete record?`)) {
      this.regService.deleteById('pharmacy/deleterecordbypharmacyid?pharmacyId=', id).subscribe(res => {
        if (res.status == "OK")
          this.getAllPharmacy(), this.toastr.success('Record Deleted Successfully');
      }, error => { this.toastr.error('An Error Occured'); });
    }
  }

  downLoadExcel(){
    const fileName = 'Pharmacy_reg'
      var excelData = this.excelData;
      if(excelData.length>0){
        excelData.map(res =>{
          res.contact_person_fullname = (res.contact_person_name +' '+ res.contact_person_last_name);
          res.location_type_name= (res.location_type_id == 2 ? 'Urban' : 'Rural')
        })
      const excelHeaders = ['Pharmacy Name', 'Pharmacy Contact', 'Contact Person Name', 'Contact Person Mobile No', 'Registration No', 'Location Type'];
      const internalHeaders = ['pharmacy_name', 'pharmacy_conatct_no', 'contact_person_fullname', 'contact_person_mobile', 'registration_no', 'location_type_name'];
      this.toastr.success('Please wait excel is downloading')
      this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
      }
      else{
        this.toastr.error('No data to download')
       }
  }



}