import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { shareReplay } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Common } from 'src/app/common';
import { NgbActiveModal, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder } from '@angular/forms'
import { Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalService } from 'ngx-bootstrap';
import * as XLSX from 'xlsx';
import { TenantFeaturesComponent } from '../tenant-features/tenant-features.component';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
declare let $: any;
@Component({
  selector: 'app-web-mobile-settings',
  templateUrl: './web-mobile-settings.component.html',
  styleUrls: ['./web-mobile-settings.component.css']
})
export class WebMobileSettingsComponent implements OnInit {
  Flagslist: Array<any> = [];
  FeatureList: Array<any> = []; featurenamelist: any;
  featureForm: FormGroup;
  checkedData: any;
  payLoadArr: Array<any> = [];
  totalItems: any;
  isShowModal: boolean = true;
  flagName = false; isHc: any; hcFlag: any
  fileName = 'TENANT CONFIGURATIONS.xlsx';
  crntTentId = +sessionStorage.getItem('currentTenant');
  excelData: any =[];
  constructor(public userServices: UserService, private toastr: ToastrService,
    private authSvc: AuthenticationService, private formBuilder: FormBuilder,

    private excelDownloadService: ExcelDownloadService,

    private router: Router, private modalService: NgbModal, private regService: RegistrationService) { }

  ngOnInit(): void {
    this.initFormControls();
    this.authSvc.crntOrgIDSubject.subscribe(org => this.getUserPermission(org));
    this.authSvc.isShowGlblOrg.next(true);

    // Common.loadDataTable(this.doctorlist, '#DoctortemplatelistTable');
  }
  getUserPermission(orgId: number) {
    let orgIdValue = JSON.parse(sessionStorage.getItem('currentTenant'));
    this.userServices.getUserPermissionByOrgId(orgIdValue).subscribe((res: any) => {
      if (res.status == 'OK') {
        this.Flagslist = res.data;
        this.excelData = this.Flagslist;
        Common.loadDataTable(this.Flagslist, '#permissionTable');
      }
    });
  }
  initFormControls() {
    this.featureForm = this.formBuilder.group({
      feature_name: ['', Validators.required],
      flag_name: ['', Validators.required],
      reason: ['', Validators.required],
      id: [null]
    });
  }
  openModal() {
    const modalRef = this.modalService.open(TenantFeaturesComponent, { scrollable: true, size: 'xl' }); // DrTemplateControlsComponen
  }
  close() {
    $("#appModal").modal("hide");
  }
  savePermission() {
    let orgIdValue = JSON.parse(sessionStorage.getItem('orgid'));
    let payLoad = {
      "feature_name": this.featureForm.value.feature_name,
      "flag_name": this.featureForm.value.flag_name,
      "reason": this.featureForm.value.reason,
      "is_active": true,
      "id": null
    }
    if (this.featureForm.value.id != null) {
      payLoad.id = this.featureForm.value.id;
    }
    if (this.featureForm.value.feature_name == "" || this.featureForm.value.flag_name == "" || this.featureForm.value.reason == "") {
      this.toastr.warning('Please Enter All Mandatory Fields')
    } else

      this.userServices.savePermissions('savemasterfeature', payLoad).subscribe((res: any) => {
        if (res.status == 'OK') {
          this.toastr.success('Feature Added Succesfully');
          this.featureForm.reset();
          this.close();
          this.authSvc.crntOrgIDSubject.subscribe(org => this.getUserPermission(org));
        } else {
          this.toastr.error(res.message);
        }
      });
  }
  onFeatureEdit(item: any) {
    $("#appModal").modal("show");
    this.isShowModal = true;
    document.getElementById('feature_name').setAttribute('disabled', 'true');
    this.featureForm.patchValue({
      feature_name: item.feature_id.feature_name,
      flag_name: item.feature_id.flag_name,
      reason: item.feature_id.reason,
      id: item.feature_id.id

    })
    // if (id)
    //   this.regService.getAPI(`agent/getagentrecordbyid`, `&agentId=${id}`).subscribe(res => { this.openAgentModal(res.data) });
  }

  updateFeature() {
    let orgIdValue = JSON.parse(sessionStorage.getItem('currentTenant'));

    this.userServices.UpdatePermissions('updatebyorgid/' + orgIdValue + '', this.payLoadArr).subscribe((res: any) => {
      if (res.status == 'OK') {
        this.toastr.success('Updated Succesfully');
        this.payLoadArr = [];
        Common.loadDataTable(this.Flagslist, '#permissionTable');
      } else {
        this.toastr.error(res.message);
      }
    })
  }
  chkbxclick(item: any) {
    this.checkedData = item
    this.payLoadArr.push(item)

  }

  openform() {
    document.getElementById('feature_name').removeAttribute('disabled');
    this.initFormControls();

  }
  exportexcel(): void {

    var table = $('#permissionTable').DataTable();

    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {
      / table id is passed over here /
      let element = document.getElementById('permissionTable');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);


      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][5] = { hidden: true };


      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
  }
  downLoadExcel() {
    const fileName = 'Tenant_config'
    var excelData = this.excelData;
    if (excelData.length > 0) {
      excelData.map(res =>{
        res.feature_name = res.feature_id.feature_name;
        res.flag_name = res.feature_id.flag_name;
        res.reason = res.feature_id.reason;
      

      });

      const excelHeaders = ['Flag Name', 'Feature Name','Reason'];
      const internalHeaders = ['feature_name', 'flag_name','reason'];
      this.toastr.success('Please wait excel is downloading')
      this.excelDownloadService.downloadExcel(excelHeaders, internalHeaders, fileName, excelData)
    }
    else {
      this.toastr.error('No data to download')
    }

  }
}
