<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <div class="table-responsive">
            <table class="table table-bordered table-sm" style="min-width: 900px;">
                <tbody>
                    <tr>
                        <th scope="row" class="bg-light">Reference ID: </th>
                        <td>{{DataEdit?.registration_no}}</td>
                        <th scope="row" class="bg-light">Full Name:</th>
                        <td>{{DataEdit?.name}}</td>
                        <th scope="row" class="bg-light">Email:</th>
                        <td>{{DataEdit?.email}}</td>
                    </tr>
                    <tr>
                        <th scope="row" class="bg-light">Phone No:</th>
                        <td>{{DataEdit?.mobile_no}}</td>
                        <th scope="row" class="bg-light">State:</th>
                        <td colspan="3">{{DataEdit?.state_name}}</td>
                    </tr>
                    <tr>
                        <th scope="row" class="bg-light">District:</th>
                        <td colspan="3">{{DataEdit?.district_name}}</td>
                        <th scope="row" class="bg-light">Block:</th>
                        <td>{{DataEdit?.mandal_name}}</td>
                    </tr>
                    <tr>

                        <th scope="row" class="bg-light">City:</th>
                        <td colspan="2">{{DataEdit?.city}}</td>
                        <th scope="row" class="bg-light">Area</th>
                        <td colspan="2">{{DataEdit?.location}}</td>
                    </tr>
                    <tr>
                        <th scope="row" class="bg-light">Apartment/Society Name:</th>
                        <td colspan="3">{{DataEdit?.society_name}}</td>
                        <th scope="row" class="bg-light">Pin code:</th>
                        <td>{{DataEdit?.pin_code}}</td>

                    </tr>
                    <tr>
                        <th scope="row" class="bg-light">Address:</th>
                        <td colspan="2">{{DataEdit?.address}}</td>
                        <th scope="row" class="bg-light">No. of Members to be vaccinated:</th>
                        <td colspan="3">{{DataEdit?.no_of_members}}</td>
                    </tr>
                    <tr>
                        <th scope="row" class="bg-light">Number of People Registered:</th>
                        <td colspan="2">{{DataEdit?.beneficiary_count}}</td>
                        <th scope="row" class="bg-light">Number of People Pending:</th>
                        <td colspan="3">{{(DataEdit?.no_of_members-DataEdit?.beneficiary_count)}}</td>
                        <!-- //no_of_members minus beneficiary_count -->
                    </tr>
                </tbody>
            </table>
        </div>
        <form [formGroup]="appointmentStatusForm" (ngSubmit)="onSubmit($event)">
            <div class="row mt-3">
                <div class="form-group col-xl-3 col-sm-6">
                    <label class="control-label">Status<span class="text-danger">*</span></label>
                    <select class="form-control form-control-sm" formControlName="status_id" id="status_id"
                        [ngClass]="{ 'is-invalid': submitted && f.status_id.errors }">
                        <option value="">Select</option>
                        <option *ngFor="let sts of statusList" [value]="sts.id">{{sts.status_name}}</option>
                    </select>
                    <div *ngIf="submitted && f.status_id.errors" class="invalid-feedback">
                        <div *ngIf="f.status_id.errors.required">Status is required</div>
                    </div>
                </div>
                <div class="form-group col-xl-3 col-sm-6">
                    <label class="control-label">Reason<span class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.reason.errors }" formControlName="reason" id="reason"
                        placeholder="Reason">
                    <div *ngIf="submitted && f.reason.errors" class="invalid-feedback">
                        <div *ngIf="f.reason.errors.required">Reason is required</div>
                    </div>
                </div>
                <div class="form-group col-xl-3 col-sm-6">
                    <label class="control-label">Contact Name<span class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.name.errors }" formControlName="name" id="name"
                        placeholder="Contact Name">
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">Contact Name is required</div>
                    </div>
                </div>
                <div class="form-group col-xl-3 col-sm-6">
                    <label class="control-label">Contact Mobile No</label>
                    <input type="text" class="form-control form-control-sm" autocomplete="off"
                        onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                        pattern="^((\\+91-?)|0)?[0-9]{10}$" maxlength="10"
                        [ngClass]="{ 'is-invalid': submitted && f.mobile_no.errors }" formControlName="mobile_no"
                        id="mobile_no" placeholder="Contact Mobile No">
                    <div *ngIf="submitted && f.mobile_no.errors" class="invalid-feedback">
                        <div *ngIf="f.mobile_no.errors.required">Contact Mobile No is required</div>
                        <div *ngIf="f.mobile_no.errors.pattern">Please Enter 10 digit Mobile Number</div>
                    </div>
                </div>
                <div class="form-group col-xl-3 col-sm-6">
                    <label class="control-label">Date of Camp<span class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm" autocomplete="off"
                        formControlName="camp_date" id="camp_date" bsDatepicker #dp="bsDatepicker" [minDate]="min_date"
                        onpaste="return false" onkeypress='return event.charCode == 8'
                        [ngClass]="{ 'is-invalid': submitted && f.camp_date.errors }"
                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                        (focus)="dp.show()" class="form-control form-control-sm">
                    <div *ngIf="submitted && f.camp_date.errors" class="invalid-feedback">
                        <div *ngIf="f.camp_date.errors.required">Date of Camp is required</div>
                    </div>
                </div>
                <div class="form-group col-xl-3 col-sm-6">
                    <label class="control-label">Email<span class="text-danger">*</span></label>
                    <input type="text" class="form-control form-control-sm" formControlName="email" id="email"
                        class="form-control form-control-sm" autocomplete="off" maxlength="40"
                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                        onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode == 64) ||(event.charCode == 46) ||
                                (event.charCode >= 97 && event.charCode <= 122)) || (event.charCode >= 48 && event.charCode <= 57)'>
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-xl-6 col-12">
                    <div class="table-responsive">
                        <table class="table table-bordered table-sm small" formArrayName="vaccines_mapping">
                            <thead>
                                <tr>
                                    <th scope="col" class="bg-light">Vaccine Name</th>
                                    <th scope="col" class="bg-light text-center">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let control of vaccinations().controls; let i=index" [formGroupName]="i">
                                    <td>{{control.get('vaccine_name').value}}</td>
                                    <td>
                                        <div class="form-group mb-0">
                                            <input type="text" class="form-control form-control-sm"
                                                formControlName="price" id="price" autocomplete="off" maxlength="6"
                                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                                [ngClass]="{ 'is-invalid': submitted && control.get('price').errors }"
                                                placeholder="Amount">
                                            <div *ngIf="submitted && control.get('price').errors"
                                                class="invalid-feedback d-block small">
                                                <div *ngIf="control.get('price').errors.required">price is required
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <!-- <tr>
                                    <td>COVAXIN</td>
                                    <td>
                                        <div class="form-group mb-0">
                                            <input type="text" class="form-control form-control-sm"
                                                id="exampleFormControlInput1" placeholder="Amount">
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>SPUTNIK V</td>
                                    <td>
                                        <div class="form-group mb-0">
                                            <input type="text" class="form-control form-control-sm"
                                                id="exampleFormControlInput1" placeholder="Amount">
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Other</td>
                                    <td>
                                        <div class="form-group mb-0">
                                            <input type="text" class="form-control form-control-sm"
                                                id="exampleFormControlInput1" placeholder="Amount">
                                        </div>
                                    </td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <input type="submit" id="btnsubmit" style="display: none;">
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" value="Submit" (click)="submitForm()">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>