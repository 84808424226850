<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <!-- (ngSubmit)="LoadData()" -->
        <form [formGroup]="consentForm" class="px-4">
            <div class="input-group mb-3">
                <div class="col-sm-3 m-0 p-0">
                    <span class="">ABHA Address</span>
                </div>
                <div class="input-group col-sm-9 p-1">
                    <input type="text" class="form-control"  [readonly]="true" [(ngModel)]="abha_address" formControlName="abha_address">
                    <div class="input-group-append">
                        <button class="btn btn-secondary px-3" type="button"   (click)="abhaAddress()">
                            <i class="fa fa-search" aria-hidden="true"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label for="adbaName" class="col-sm-3 col-form-label">Name</label>
                <div class="col-sm-9">
                    <input type="text" class="form-control"  [readonly]="true" [(ngModel)]="abha_name" formControlName="name">
                   
                </div>
            </div>
            <div class="row mb-3">
                <label for="purposeofrequest" class="col-sm-3 col-form-label">Purpose Of Request</label>
                <div class="col-sm-9">
                    <select id="purpose" name="requestType" class="form-control form-control-sm" [(ngModel)]="purpose"
                        formControlName="purpose" (change)="onPurposeChange()">
                        <option value="">Select</option>
                        <option *ngFor="let item of purposeList" [value]="item?.code">{{item?.status_name}}</option>
                    </select>
                </div>
            </div>
            <div class="row mb-3">
                <label for="healthinfofrom" class="col-sm-3 col-form-label">Health Info From</label>
                <div class="col-sm-9">
                    <input id="start_date" name="start_date" type="text" autocomplete="off" onpaste="return false"
                        placeholder="From" bsDatepicker #dp="bsDatepicker" (focus)="dp.show()" (focus)="dp1.hide()"
                        formControlName="start_date" [maxDate]="max_date" (bsValueChange)="onFromDateChange($event)"
                        onkeypress='return event.charCode == 8'
                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                        [ngClass]="{ 'is-invalid': submitted && f.start_date.errors }"
                        class="form-control form-control-sm" formControlName="start_date" [(ngModel)]="start_date">
                    <div *ngIf="submitted && f.start_date.errors" class="invalid-feedback">
                        <div *ngIf="f.start_date.errors.required">From Date is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label for="healtinfoto" class="col-sm-3 col-form-label">Health Info To</label>
                <div class="col-sm-9">
                    <input id="end_date" name="end_date" type="text" autocomplete="off" onpaste="return false"
                        placeholder="To" formControlName="end_date" [minDate]="f.start_date.value"
                        [maxDate]="max_date" (focus)="dp.hide()" bsDatepicker #dp1="bsDatepicker" (focus)="dp1.show()"
                        (bsValueChange)="onToDateChange($event)"
                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                        [ngClass]="{ 'is-invalid': submitted && f.end_date.errors }"
                        class="form-control form-control-sm" onkeypress='return event.charCode == 8'
                        formControlName="end_date" [(ngModel)]="end_date">
                    <div *ngIf="submitted && f.end_date.errors" class="invalid-feedback">
                        <div *ngIf="f.end_date.errors.required">To Date is required</div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-sm-3"><label for="healthinfotype" class="col-form-label">Health Info Type</label></div>
                <div class="col-sm-9">
                    <div class="form-check" *ngFor="let item of hittypeList">
                        <input class="form-check-input" formControlName="hittype" name="{{ item.status_name }}"
                            type="checkbox" id="{{item.code}}" (change)="onChangeCategory($event, item)">
                        <label class="form-check-label" for="{{item?.status_name}}">
                            {{item?.status_name}}
                        </label>
                    </div>
                </div>
            </div>
            <div class="row">
                <label for="consentexpiry" class="col-sm-3 col-form-label">Consent Expiry</label>
                <div class="col-sm-5">
                    <input id="expiry_date" name="expiry_date" type="text" autocomplete="off" onpaste="return false"
                        placeholder="expiry_date" bsDatepicker formControlName="expiry_date" bsDatepicker
                        #dp1="bsDatepicker" [placement]="'top'"
                        [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                        [ngClass]="{ 'is-invalid': submitted && f.expiry_date.errors }"
                        class="form-control form-control-sm" onkeypress='return event.charCode == 8'
                        [minDate]="minDateEx" [(ngModel)]="expiry_date">
                    <div *ngIf="submitted && f.expiry_date.errors" class="invalid-feedback">
                        <div *ngIf="f.expiry_date.errors.required">To Date is required</div>
                    </div>
                </div>
                <div class="col-sm-4" style="margin-top: -46px !important;">
                    <!-- <ngb-timepicker [(ngModel)]="time" formControlName="time"></ngb-timepicker> -->
                    <ngb-timepicker [(ngModel)]="time" formControlName="time" [meridian]="meridian" [spinners]="spinners"></ngb-timepicker>
                </div>
            </div>
        </form>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-primary btn-sm me-md-2" type="submit" (click)="LoadData()">Request Consent</button>
    <!-- <pre>Selected time: {{ time }}</pre> -->
    <!-- <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
</div>