import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

import { UserDetailsComponent } from './../user-details/user-details.component';
import { UserService } from 'src/app/shared/services/user.service';
import { ApiService } from 'src/app/shared/services/api.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { Common } from 'src/app/common';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import * as XLSX from 'xlsx';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';

declare let $: any;

@Component({
  selector: 'app-user-detail-list',
  templateUrl: './user-detail-list.component.html',
  styleUrls: ['./user-detail-list.component.css']
})

export class UserDetailListComponent extends BaseComponent implements OnInit {
  
  userList: Array<any> = [];
  roleList: Array<any> = [];
  orgList: Array<any> = [];
  modalTitle: string = '';
  logUsrOrgName = '';
  tenantId: any;
  roleId = +sessionStorage.getItem('logUsrRoleId');
  fileName = 'User Management list.xlsx';
  excelData: any =[];
  constructor(config: NgbModalConfig,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private apiServ: ApiService,
    private userServices: UserService,
    private authSvc: AuthenticationService,
    public changeDetection: ChangeDetectorRef,
    private excelDownloadService:ExcelDownloadService
    ) {
      super();
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    this.authSvc.isShowGlblOrg.next(true);
  }

  ngOnInit(): void {
    // this.authSvc.tenantId = sessionStorage.getItem('defCrntTnnt'),
    // sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt'));
    if (sessionStorage.getItem('currentUser') != null)
      this.logUsrOrgName = JSON.parse(sessionStorage.getItem('currentUser'))?.orgnization_registration?.orgnization_name;
    // this.getAllUsers()
    // if (sessionStorage.getItem('TENANT_ID') != null)
    //   this.getAllMasters();
    if (sessionStorage.getItem('TENANT_ID') != null)
      this.tenantId = sessionStorage.getItem('TENANT_ID');
    else if (sessionStorage.getItem('tenantDtls') != null)
      this.tenantId = JSON.parse(sessionStorage.getItem('tenantDtls')).data?.id ?? null;
    else
      this.tenantId = sessionStorage.getItem('currentTenant');
    this.getAllMasters();
  }

  getAllUsers() {
    if (this.tenantId) {
      // this.userServices.getAllNew(`user/web_list?organizationId=${sessionStorage.getItem('TENANT_ID')}`).subscribe(res => {
      this.userServices.getAllNew(`user/web_list?organizationId=${this.tenantId}`).subscribe(res => {
        if (res.status === 'ACCEPTED') { // OK
          if (res.data == null || res.data.length == 0)
            this.userList = [], this.toastr.info('No records available', 'User Details List');
          else
            this.userList = res.data; // this.logUsrOrgName != '' ? res.data.filter(f => f.orgnization_name.trim() === this.logUsrOrgName.trim()) : res.data;
             this.excelData= this.userList;
            Common.loadDataTable(this.userList, '#userTable');
        }
      });
    }
    else
      this.userList = [], Common.loadDataTable(this.userList, '#userTable');
  }

  getUserbyId(usrId: number) {
    this.userServices.getById(`user/getuserbyid?userId=${usrId}`).subscribe(res => { this.openModal(res.data); });
  }

  // openModal(item: any = null) {
  //   const modalRef = this.modalService.open(UserDetailsComponent, { scrollable: true, size: 'xl' });
  //   modalRef.componentInstance.title = this.modalTitle;
  //   modalRef.componentInstance.userData = item;
  //   modalRef.result.then(result => {
  //     if (result == 'changes saved')
  //       this.getAllUsers();
  //   }, (reason) => { });
  // }
  openModal(item: any = null) {
    this.changeDetection.detach();
    const modalRef = this.modalService.open(UserDetailsComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.userData = item;
    modalRef.result.then(result => {
      this.changeDetection.reattach();
      if (result == 'changes saved')
        this.getAllUsers();
    }, (reason) => { });
  }
  getAllMasters() {
    const roles = this.userServices.getAll('user/getall_roles');
    const organizations = this.userServices.getAll('organization/getorganizationrecords');
    
    forkJoin([roles, organizations]).subscribe({ // res => console.log(res)
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'success' || list.status === 'OK') {
            const resp = list?.response ?? list?.data ?? [];
            if (resp == null || resp.length == 0)
              this.toastr.info('No records available', 'User Details List');
            else {
              const assignList = {
                '0': () => this.roleList = resp,
                '1': () => this.orgList = list.data,
              }
              assignList[ind]();
            }
          }
        });
      },
      error: err => console.error('something wrong occurred: ', err),
      complete: () => {
        if (this.roleId != 1)
          this.getAllUsers();
      }
    });
  }

  getRole(item): string {
    let roleName;
    if (item && item.length > 0 && item != undefined)
      roleName = this.roleList.filter(f => f.id == +item[0].user_type_id.id)[0]?.userType;
    else
      roleName = '';
    return roleName ? roleName.toString() : '';
  }

  exportexcel(): void {
    var table = $('#userTable').DataTable();
    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {	
      / table id is passed over here /
      let element = document.getElementById('userTable');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };

      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
  }

  downLoadExcel(){
    const fileName = 'User_list'
      var excelData = this.excelData;
      if(excelData.length>0){
        excelData.map(res =>{
          res.fullName = res.first_name+' '+res.last_name
         
        });
        const excelHeaders = ['Full Name', 'Mobile No','Login Name','Organisation','Role'];
        const internalHeaders = ['fullName', 'mobile_no','login_name','orgnization_name','m_usertype'];
        this.toastr.success('Please wait excel is downloading')
        this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
      }
      else{
        this.toastr.error('No data to download')
      }
    
  }

}