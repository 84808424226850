<!-- <form [formGroup]="drugForm" (ngSubmit)="onSubmit($event)"> -->
<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="roleForm">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-12">
                <div class="form-group">
                    <label class="control-label">Role Name <span class="text-danger">*</span> </label>
                    <div>
                        <input type="text" autocomplete="off" id="roleName" placeholder="Role Name" class="form-control form-control-sm" formControlName="userType"
                        [ngClass]="{ 'is-invalid': submitted && roleForm.get('userType').errors }"
                            onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'>
                            <div *ngIf="submitted && roleForm.get('userType').errors" class="invalid-feedback">
                                <div *ngIf="roleForm.get('userType').errors">Role Name is required
                                </div>
                            </div>
                        </div>
                </div>
            </div> 
        </div>
        <div class="row">           
            <div class="form-group col-md-12">
                <div class="form-group">
                    <label class="control-label"> Status
                    </label>
                    <div>
                        <input type="radio" formControlName="active" [value]="true">Active
                        <input type="radio" formControlName="active" [value]="false">In Active
                    </div>
                </div>
            </div>            
        </div>
    </div>
</form>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" value="Submit" (click)="onSubmit()">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
<!-- </form> -->