<div class="modal-header">
  <h4 class="modal-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body heightScroll" >
  <div class="container-fluid">
    <form [formGroup]="covidForm" (ngSubmit)="onSubmit($event)">
      <div class="row small">

        <div class="col-xl-12 mt-3">

          <label class="font-weight-bold" for="exampleFormControlInput1">Temperature</label>

        </div>
        <div class="col-xl-12">
          <div class="custom-control custom-radio custom-control-inline ">
            <input class="custom-control-input" id="customRadioSolid1" type="radio" value="Normal (96F-98.6F)" formControlName="temperature"
              value="normal">
            <label class="custom-control-label" for="customRadioSolid1"> Normal (96F-98.6F)</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input class="custom-control-input" id="customRadioSolid2" type="radio" value="Fever (98.6F-102F)" formControlName="temperature"
              value="fever">
            <label class="custom-control-label" for="customRadioSolid2">Fever (98.6F-102F)</label>
          </div>
          <div class="custom-control custom-radio custom-control-inline">
            <input class="custom-control-input" id="customRadioSolid3" type="radio" value="High Fever (>102F)" formControlName="temperature"
              value="highFever">
            <label class="custom-control-label" for="customRadioSolid3">High Fever (>102F)</label>
          </div>
        </div>
        <div class="col-xl-12 mt-3">
          <label class="font-weight-bold" for="exampleFormControlInput1">Symptoms</label>
        </div>
        <div class="col-xl-12" formGroupName="symptoms">
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck101" type="checkbox" (change)="onChange($event.target,'symptoms.dryCough')" value="Dry Cough" formControlName="dryCough">
            <label class="custom-control-label" for="customCheck101">Dry Cough </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck103" type="checkbox" (change)="onChange($event.target,'symptoms.sneezing')" value="Sneezing" formControlName="sneezing">
            <label class="custom-control-label" for="customCheck103">Sneezing</label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck104" type="checkbox" value="Sore Throat" (change)="onChange($event.target,'symptoms.soreThroat')" formControlName="soreThroat">
            <label class="custom-control-label" for="customCheck104">Sore Throat</label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck102" type="checkbox" value="Moderate to Severe Cough" (change)="onChange($event.target,'symptoms.severeCough')" formControlName="severeCough">
            <label class="custom-control-label" for="customCheck102">Moderate to Severe Cough</label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck105" type="checkbox" value="Weakness" (change)="onChange($event.target,'symptoms.weakness')" formControlName="weakness">
            <label class="custom-control-label" for="customCheck105">Weakness </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck106" type="checkbox" value="Drowsiness" (change)="onChange($event.target,'symptoms.drowsiness')" formControlName="drowsiness">
            <label class="custom-control-label" for="customCheck106">Drowsiness </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck107" type="checkbox" value="Feeling Breathless" (change)="onChange($event.target,'symptoms.feelingBreathless')" formControlName="feelingBreathless">
            <label class="custom-control-label" for="customCheck107">Feeling Breathless </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck108" type="checkbox" value="Severe Weakness" (change)="onChange($event.target,'symptoms.severeWeakness')" formControlName="severeWeakness">
            <label class="custom-control-label" for="customCheck108"> Severe Weakness </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck109" type="checkbox" value="Diﬃculty in Breathing" (change)="onChange($event.target,'symptoms.breathDifficulty')" formControlName="breathDifficulty">
            <label class="custom-control-label" for="customCheck109"> Diﬃculty in Breathing </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck110" type="checkbox" value="Persistent Pain and Pressure in Chest" (change)="onChange($event.target,'symptoms.pressureInChest')" formControlName="pressureInChest">
            <label class="custom-control-label" for="customCheck110">Persistent Pain and Pressure in Chest </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck112" type="checkbox" value="Loss of Taste" (change)="onChange($event.target,'symptoms.lossofTaste')" formControlName="lossofTaste">
            <label class="custom-control-label" for="customCheck112">Loss of Taste
            </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck113" type="checkbox" value="Loss of SmellLoss" (change)="onChange($event.target,'symptoms.lossofSmellLoss')" formControlName="lossofSmellLoss">
            <label class="custom-control-label" for="customCheck113">Loss of SmellLoss
            </label>
          </div>
        </div>

        <div class="col-xl-12 mt-3">
          <label class="font-weight-bold" for="exampleFormControlInput1">Travel History</label>
          <div>
            <div class="custom-control custom-radio custom-control-inline ">
              <input class="custom-control-input" id="customRadioSolid11" type="radio" value="No Travel History"
                formControlName="travelHistory">
              <label class="custom-control-label" for="customRadioSolid11"> No Travel History</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input class="custom-control-input" id="customRadioSolid12" type="radio" value="History of travel or meeting in aﬀected geographical area in last 14 days"
                formControlName="travelHistory">
              <label class="custom-control-label" for="customRadioSolid12">History of travel or meeting in aﬀected
                geographical area in last 14 days </label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input class="custom-control-input" id="customRadioSolid13" type="radio" value="No Contact with anyone with Symptoms"
                formControlName="travelHistory">
              <label class="custom-control-label" for="customRadioSolid13">No Contact with anyone with Symptoms </label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input class="custom-control-input" id="customRadioSolid14" type="radio" value="Close Contact with conﬁrmed COVID in last 14 days"
                formControlName="travelHistory">
              <label class="custom-control-label" for="customRadioSolid14">Close Contact with conﬁrmed COVID in last 14
                days </label>
            </div>
          </div>
        </div>

        <div class="col-xl-12 mt-3">
          <label class="font-weight-bold" for="exampleFormControlInput1">Co-morbidities</label>
        </div>
        <div class="col-xl-12" formGroupName="co_morbidities">
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck1021" type="checkbox" value="Diabetes" (change)="onChange($event.target,'co_morbidities.diabetes')" formControlName="diabetes">
            <label class="custom-control-label" for="customCheck1021">Diabetes</label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck1031" type="checkbox" value="High Blood Pressure" (change)="onChange($event.target,'co_morbidities.highbp')" formControlName="highbp">
            <label class="custom-control-label" for="customCheck1031">High Blood Pressure </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck1041" type="checkbox" value="Heart Disease" (change)="onChange($event.target,'co_morbidities.heartDisease')" formControlName="heartDisease">
            <label class="custom-control-label" for="customCheck1041">Heart Disease</label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck1051" type="checkbox" value="KidneyDisease" (change)="onChange($event.target,'co_morbidities.kidneyDisease')" formControlName="kidneyDisease">
            <label class="custom-control-label" for="customCheck1051">KidneyDisease </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck1061" type="checkbox" value="LungDisease" (change)="onChange($event.target,'co_morbidities.lungDisease')" formControlName="lungDisease">
            <label class="custom-control-label" for="customCheck1061">LungDisease </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck1071" type="checkbox" value="Stroke" (change)="onChange($event.target,'co_morbidities.stroke')" formControlName="stroke">
            <label class="custom-control-label" for="customCheck1071">Stroke </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck1081" type="checkbox" value="Reduced Immunity" (change)="onChange($event.target,'co_morbidities.reducedImmunity')" formControlName="reducedImmunity">
            <label class="custom-control-label" for="customCheck1081"> Reduced Immunity </label>
          </div>
        </div>

        <div class="col-xl-12 mt-3">
          <label class="font-weight-bold" for="exampleFormControlInput1">How have your symptoms progressed over last 48
            hours? </label>
          <div>
            <div class="custom-control custom-radio custom-control-inline">
              <input class="custom-control-input" id="customRadioSolid112" type="radio" value="Improved"
                formControlName="symptomsProgressed">
              <label class="custom-control-label" for="customRadioSolid112"> Improved</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input class="custom-control-input" id="customRadioSolid123" type="radio" value="No Change"
                formControlName="symptomsProgressed">
              <label class="custom-control-label" for="customRadioSolid123">No Change </label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input class="custom-control-input" id="customRadioSolid134" type="radio" value="Worsened"
                formControlName="symptomsProgressed">
              <label class="custom-control-label" for="customRadioSolid134">Worsened </label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input class="custom-control-input" id="customRadioSolid145" type="radio" value="Worsened Considerably"
                formControlName="symptomsProgressed">
              <label class="custom-control-label" for="customRadioSolid145">Worsened Considerably </label>
            </div>
          </div>
        </div>
        <div class="col-xl-12 mt-3">
          <label class="font-weight-bold" for="exampleFormControlInput1">Precautions</label>
        </div>
        <div class="col-xl-12" formGroupName="precautions">
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck10211" type="checkbox"  value="Airborne" (change)="onChange($event.target,'precautions.airborne')" formControlName="airborne">
            <label class="custom-control-label" for="customCheck10211">Airborne</label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck10311" type="checkbox" value="Contact" (change)="onChange($event.target,'precautions.contact')" formControlName="contact">
            <label class="custom-control-label" for="customCheck10311">Contact </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck10411" type="checkbox" value="Droplet" (change)="onChange($event.target,'precautions.droplet')" formControlName="droplet">
            <label class="custom-control-label" for="customCheck10411">Droplet</label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck10511" type="checkbox" value="Standard" (change)="onChange($event.target,'precautions.standard')" formControlName="standard">
            <label class="custom-control-label" for="customCheck10511">Standard </label>
          </div>
        </div>


        <div class="col-xl-12 mt-3">
          <label class="font-weight-bold" for="exampleFormControlInput1">Point of Care Testing</label>
        </div>
        <div class="col-xl-12" formGroupName="pointOfCare">
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck102111" type="checkbox" value="Fingerstick Glucose" (change)="onChange($event.target,'pointOfCare.fingerstickGlucose')"
              formControlName="fingerstickGlucose">
            <label class="custom-control-label" for="customCheck102111"> Fingerstick Glucose </label>
          </div>
        </div>
        <div class="col-xl-12 mt-3" formGroupName="chemistry">
          <label class="font-weight-bold" for="exampleFormControlInput1">Chemistry</label>
          <br>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck1" type="checkbox" value="Basic Metabolic Proﬁle"
            (change)="onChange($event.target,'chemistry.basicMetabolicProfile')" formControlName="basicMetabolicProfile">
            <label class="custom-control-label" for="customCheck1">Basic Metabolic Proﬁle</label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck2" type="checkbox" value="Comprehensive Metabolic Panel"
              formControlName="comprehensiveMetabolic" (change)="onChange($event.target,'chemistry.comprehensiveMetabolic')">
            <label class="custom-control-label" for="customCheck2">Comprehensive Metabolic Panel</label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck3" type="checkbox" value="C - Reactive Protein" 
            formControlName="c_reactiveProtein" (change)="onChange($event.target,'chemistry.c_reactiveProtein')">
            <label class="custom-control-label" for="customCheck3">C - Reactive Protein</label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck4" type="checkbox" value="D-Dimer, Quantitative" 
            formControlName="d_dimer" (change)="onChange($event.target,'chemistry.d_dimer')">
            <label class="custom-control-label" for="customCheck4">D-Dimer, Quantitative </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck5" type="checkbox" value="Ferritin" 
            formControlName="ferritin" (change)="onChange($event.target,'chemistry.ferritin')">
            <label class="custom-control-label" for="customCheck5">Ferritin </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck6" type="checkbox" 
            value="Fibrinogen" formControlName="fibrinogen"  (change)="onChange($event.target,'chemistry.fibrinogen')">
            <label class="custom-control-label" for="customCheck6">Fibrinogen </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck7" type="checkbox" value="Lactic Acid, Venous " 
            formControlName="lacticAcid" (change)="onChange($event.target,'chemistry.lacticAcid')">
            <label class="custom-control-label" for="customCheck7">Lactic Acid, Venous </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline"> 
            <input class="custom-control-input" id="customCheck8" type="checkbox" value="Hepatic Function Panel"
              formControlName="hepaticFunctionPanel" (change)="onChange($event.target,'chemistry.hepaticFunctionPanel')">
            <label class="custom-control-label" for="customCheck8"> Hepatic Function Panel </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck9" type="checkbox" value="Lipid Panel" 
            formControlName="lipidPanel" (change)="onChange($event.target,'chemistry.lipidPanel')">
            <label class="custom-control-label" for="customCheck9">Lipid Panel </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck10" type="checkbox"value="Procalcitonin"
             formControlName="procalcitonin" (change)="onChange($event.target,'chemistry.procalcitonin')">
            <label class="custom-control-label" for="customCheck10">Procalcitonin
            </label>
          </div>

        </div>
        <div class="col-xl-12 mt-3" formGroupName="hematology">
          <label class="font-weight-bold" for="exampleFormControlInput1">Hematology</label>
          <br>

          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck11" type="checkbox" value="Complete Blood Count (CBC)" 
            formControlName="cbc" (change)="onChange($event.target,'hematology.cbc')">
            <label class="custom-control-label" for="customCheck11">Complete Blood Count (CBC)</label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck12" type="checkbox"
             value="Erythrocyte sedimentation rate (ESR)" formControlName="esr" (change)="onChange($event.target,'hematology.esr')">
            <label class="custom-control-label" for="customCheck12"> Erythrocyte sedimentation rate (ESR) </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck13" type="checkbox" value="Prothrombin time (PT) with INR"
             formControlName="pt" (change)="onChange($event.target,'hematology.pt')">
            <label class="custom-control-label" for="customCheck13">Prothrombin time (PT) with INR</label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck14" type="checkbox" value="Partial Thromboplastin Time" 
            formControlName="partial"  (change)="onChange($event.target,'hematology.partial')">
            <label class="custom-control-label" for="customCheck14">Partial Thromboplastin Time</label>
          </div>
        </div>

        <div class="col-xl-12 mt-3" formGroupName="influenza_pcr">
          <label class="font-weight-bold" for="exampleFormControlInput1">Microbiology</label>
          <br>
          <label for="exampleFormControlInput1"> Inﬂuenza A/B PCR</label>
          <br>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck116" type="checkbox" value="Nose" (change)="onChange($event.target,'influenza_pcr.nose')" formControlName="nose">
            <label class="custom-control-label" for="customCheck116">Nose </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck18" type="checkbox" value="Sputum" formControlName="sputum" (change)="onChange($event.target,'influenza_pcr.sputum')">
            <label class="custom-control-label" for="customCheck18"> Sputum </label>
          </div>
          <br>
        </div>
        <br>
        <div class="col-xl-12 mt-3" formGroupName="sars_cov2">
          <label for="exampleFormControlInput1"> Real-Time Polymerase chain reaction for SARS-CoV-2 </label>
          <br>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck19" type="checkbox" value="Real-Time Polymerase chain reaction for SARS-CoV-2" 
            formControlName="realTimePolymerase" (change)="onChange($event.target,'sars_cov2.realTimePolymerase')">
            <label class="custom-control-label" for="customCheck19"> Real-Time Polymerase chain reaction for SARS-CoV-2
            </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck20" type="checkbox" value="Nasopharyngeal swab" 
            formControlName="nasopharyngeal" (change)="onChange($event.target,'sars_cov2.nasopharyngeal')">
            <label class="custom-control-label" for="customCheck20"> Nasopharyngeal swab </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck21" type="checkbox" value="Oropharyngeal swab" 
            formControlName="oropharyngeal" (change)="onChange($event.target,'sars_cov2.oropharyngeal')">
            <label class="custom-control-label" for="customCheck21"> Oropharyngeal swab </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck22" type="checkbox" value="Sputum" 
            formControlName="sputum" (change)="onChange($event.target,'sars_cov2.sputum')">
            <label class="custom-control-label" for="customCheck22"> Sputum </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck23" type="checkbox" 
            value="Respiratory Syncytial Virus (RSV), Antigen, Nose" formControlName="rsv" (change)="onChange($event.target,'sars_cov2.rsv')">
            <label class="custom-control-label" for="customCheck23"> Respiratory Syncytial Virus (RSV), Antigen, Nose
            </label>
          </div>
        </div>
        <div class="col-xl-12 mt-3" formGroupName="urine">
          <label class="font-weight-bold" for="exampleFormControlInput1">Urine</label>
          <br>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck24" type="checkbox" value="Urinalysis" formControlName="urinalysis" 
             (change)="onChange($event.target,'urine.urinalysis')">
            <label class="custom-control-label" for="customCheck24">Urinalysis </label>
          </div>
        </div>
        <div class="col-xl-12 mt-3" formGroupName="plainFilms">
          <label class="font-weight-bold" for="exampleFormControlInput1">RADIOLOGY</label>
          <br>
          <label for="exampleFormControlInput1">Plain Films: </label>
          <br>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck26" type="checkbox" value="X-ray Chest" 
            formControlName="xRay" (change)="onChange($event.target,'plainFilms.xRay')">
            <label class="custom-control-label" for="customCheck26">X-ray Chest </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck27" type="checkbox"
             value="PA" formControlName="pa" (change)="onChange($event.target,'plainFilms.pa')">
            <label class="custom-control-label" for="customCheck27">PA </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck28" type="checkbox" value="Lateral" 
            formControlName="lateral" (change)="onChange($event.target,'plainFilms.lateral')">
            <label class="custom-control-label" for="customCheck28">Lateral</label>
          </div>
        </div>

        <div class="col-xl-12 mt-3" formGroupName="ctScan">
          <label class="font-weight-bold" for="exampleFormControlInput1">CT Scan</label>
          <br>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck29" type="checkbox" 
            value="CT Chest without IV contrast" formControlName="withoutIvContrast" (change)="onChange($event.target,'ctScan.withoutIvContrast')">
            <label class="custom-control-label" for="customCheck29">CT Chest without IV contrast
            </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck30" type="checkbox" value="CT Chest with IV contrast" formControlName="withIvContrast" 
            (change)="onChange($event.target,'ctScan.withIvContrast')">
            <label class="custom-control-label" for="customCheck30">CT Chest with IV contrast
            </label>
          </div>
        </div>

        <div class="col-xl-12 mt-3" formGroupName="instructions">
          <label class="font-weight-bold" for="exampleFormControlInput1">INSTRUCTIONS </label>
          <br>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck33" type="checkbox" value="Smoking Cessation/Tobacco Withdrawal - Ambulatory Module"
             formControlName="smokingCessation" (change)="onChange($event.target,'instructions.smokingCessation')">
            <label class="custom-control-label" for="customCheck33">Smoking Cessation/Tobacco Withdrawal - Ambulatory
              Module
            </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck34" type="checkbox" value="Ambulatory Core Orders/Routine Screening - Adult - Module" 
            formControlName="routineScreening" (change)="onChange($event.target,'instructions.routineScreening')">
            <label class="custom-control-label" for="customCheck34"> Ambulatory Core Orders/Routine Screening - Adult -
              Module
            </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck35" type="checkbox" value="Contact Local Public Health Department for Positive
            SARS-CoV-2 Polymerase Chain Reaction results" formControlName="contactLocal" (change)="onChange($event.target,'instructions.contactLocal')">
            <label class="custom-control-label" for="customCheck35"> Contact Local Public Health Department for Positive
              SARS-CoV-2 Polymerase Chain Reaction results
            </label>
          </div>
        </div>

        <div class="col-xl-12 mt-3">
          <label class="font-weight-bold" for="exampleFormControlInput1">Patient Education</label>
        </div>
        <div class="col-xl-12" formGroupName="patienteducation">
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck333" type="checkbox" value="Infection Education" 
            (change)="onChange($event.target,'patienteducation.infection')" formControlName="infection">
            <label class="custom-control-label" for="customCheck333"> Infection Education
            </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck3334" type="checkbox" value=" Monitoring body temperature ; Check temperature
            at least once per day" formControlName="bodyTemperature" (change)="onChange($event.target,'patienteducation.bodyTemperature')">
            <label class="custom-control-label" for="customCheck3334"> Monitoring body temperature ; Check temperature
              at least once per day
            </label>
          </div>
          <div class="custom-control custom-checkbox  custom-control-inline">
            <input class="custom-control-input" id="customCheck3335" type="checkbox" value="Responsibilities of provider concerning
            notiﬁcation, including parent, guardian, public" formControlName="responsibilities" (change)="onChange($event.target,'patienteducation.responsibilities')">
            <label class="custom-control-label" for="customCheck3335"> Responsibilities of provider concerning
              notiﬁcation, including parent, guardian, public
            </label>
          </div>
        </div>
        <!-- <div class="col-xl-4">
          <div class="form-group">
            <label class="control-label">Nodal Officer </label>
            <div>
              <select formControlName="nodal_id" id="nodal_id" class="form-control form-control-sm">
                <option value="">Select</option>
                <option *ngFor="let nodal of nodalList" [value]="nodal.id">
                  {{nodal.full_name}}</option>
              </select>
             
            </div>
          </div>
        </div> -->
      </div>
    </form>
  </div>
</div>
<div class="modal-footer">
  <input type="submit" class="btn btn-sm btn-primary" (click)="onSubmit($event)" *ngIf="showSubmit" value="Submit">&nbsp;&nbsp;
  <button id="btnCovid19Close" type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Exit</button>
  <button type="button" id="btnCovidModal" data-toggle="modal" data-target="#covid" hidden></button>
</div>

<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" style="z-index: 99999 !important;" id="covid" role="dialog"
  aria-labelledby="edit" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"> Please Confirm if the patient is Covid Positive ?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm  btn-primary" data-toggle="modal" data-target="#notify" data-dismiss="modal">Yes</button>
        <button type="button" class="btn btn-sm btn-danger" data-dismiss="modal" (click)="closeModal()">No</button>
      </div>
    </div>
  </div>
</div>
<div class="modal fade" data-backdrop="static" data-keyboard="false" tabindex="-1" style="z-index: 99999 !important;" id="notify" role="dialog"
  aria-labelledby="edit" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title"> The details are being sent across to relevant government authorities ?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-sm  btn-primary" (click)="sendNotification()" data-dismiss="modal">Yes</button>
        <button type="button" class="btn btn-sm btn-danger" data-dismiss="modal" (click)="closeModal()">No</button>
      </div>
    </div>
  </div>
</div>
