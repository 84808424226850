  <!-- <form [formGroup]="stateForm" (ngSubmit)="onSubmit($event)"> -->
    <div class="modal-header">
        <h4 class="modal-title">{{title}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
               <form [formGroup]="districtForm" (ngSubmit)="onSubmit($event)">
            <div class="row">
              <div class="col-xl-5">
                <div class="form-group">
                  <label class="control-label">State <span class="text-danger">*</span> </label>
                  <div>
                    <select formControlName="state_id" id="state_id" #State 
                     class="form-control form-control-sm"
                     [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }">
                      <option value="">Select</option>
                      <option *ngFor="let state of stateList" [value]="state.id">
                        {{state.state_name}}</option>
                    </select>
                    <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                      <div *ngIf="f.state_id.errors.required">State Name is required</div>
                    </div>
                  </div>
                </div>
              </div>
                   <div class="col-xl-13">
                    <div class="form-group">
                      <label class="control-label">District Name <span class="text-danger">*</span> </label>
                      <div>
                        <input type="text" autocomplete="off" formControlName="district_name" id="district_name"
                          placeholder="District Name" maxlength="50"
                          class="form-control form-control-sm "
                           [ngClass]="{ 'is-invalid': submitted && f.district_name.errors }"
                          onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'> 
                      </div>
                      <div *ngIf="submitted && f.district_name.errors" class="invalid-feedback">
                        <div *ngIf="f.district_name.errors.required">District Name is required</div>
                      </div>
                    </div>
                  </div>
            </div> 
            <input type="submit"  id="btnsubmit" style="display: none;">
           </form>
        </div>
    </div>
    <div class="modal-footer">
      <input type="submit" class="btn btn-sm btn-primary" (click)="submitForm()" value="Submit">&nbsp;&nbsp;
      <button type="button" class="btn btn-sm btn-outline-dark"
          (click)="activeModal.close('Close click')">Close</button>
  </div>
  