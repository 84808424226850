<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Add Drug Inventory to State
                        <button data-toggle="modal" (click)="modalTitle='Add Drugs to State'; openDrugStateModal()"
                            *ngIf="setPrivilege(Screen_Name.AddDrugsToState, Permission.Add)"
                            class="btn btn-blue btn-icon float-right"> <i class="fa fa-plus"></i>
                        </button>
                    </div>
                    <div class="card-body">
                        <h3>State <span class="text-danger">*</span></h3>
                        <div class="row">
                            <div class="col-xl-2">
                                <select class="form-control form-control-sm text-uppercase" [(ngModel)]="slctCmpny"
                                    (change)=" getAllstates($event.target.value)" id="slctCmpny"  disabled="true">
                                    <option value="" selected hidden>Select</option>
                                    <option *ngFor="let state of stateList" [value]="state.id" >{{state.state_name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <br>
                        <div class="table-responsive">
                            <div class="col-md-9 mb-3" style="margin-top: 2rem;" >
                                <button type="button" class="btn  btn-sm btn-secondary float-right"  *ngIf="excelData && excelData.length>0" (click)="downLoadExcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                            </div>    
                            <table datatable id="DrugStateTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th style="width:7%;" class="singleLine">S No </th>
                                        <th>Drug Type</th>
                                        <th>Drug Name</th>
                                        <th>Batch No.</th>
                                        <th>Qty</th>
                                        <th>Expiry Date</th>
                                        <th>MFG</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of statedrugList; let i=index">
                                        <td>{{i+1}}</td>
                                        <td>{{item.drug_type}}</td>
                                        <td>{{item.drug_name}}</td>
                                        <td>{{item.batch_no}}</td>
                                        <td>{{item.current_stock}}</td>
                                        <td>{{item.expiry_date | date:'dd-MM-yyyy'}}</td>
                                        <td>{{item.manufactured_date | date:'dd-MM-yyyy'}}</td>
                                        <td><a class="badge badge-danger badge-pill pointer" title="Delete"> <i
                                                    class="fa fa-trash text-white"></i>
                                            </a></td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>