<div class="row">
  <div class="container-fluid pl-0 pr-0">
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-header-actions mx-auto">
          <div class="card-header">
            Payment Status
            <!-- <button class="btn btn-blue btn-icon float-right"
                          (click)="modalTitle='Doctor Registration Details';openDoctorModal()">

                          <i class="fa fa-plus"></i>
                      </button> -->
          </div>

          <div class="card-body">
            <form [formGroup]="paymentForm" (ngSubmit)="LoadData()">
              <div class="row ">
                <div class="container">
                  <div class="row ">
                    <div class='col-md-3'>
                      <div class="form-group">
                        <div class='date' id='datetimepicker6'>
                          <label class="control-label">From Date <span class="text-danger">*</span> </label>
                          <div>
                            <input id="from_date" name="from_date" type="text" autocomplete="off" onpaste="return false"
                              placeholder="From" bsDatepicker #dp="bsDatepicker" (focus)="dp.show()" (focus)="dp1.hide()"
                              formControlName="from_date" [maxDate]="max_date"
                              (bsValueChange)="onFromDateChange($event)" onkeypress='return event.charCode == 8'
                              [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                              [ngClass]="{ 'is-invalid': submitted && f.from_date.errors }"
                              class="form-control form-control-sm">
                            <div *ngIf="submitted && f.from_date.errors" class="invalid-feedback">
                              <div *ngIf="f.from_date.errors.required">From Date is required</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='col-md-3'>
                      <div class="form-group">
                        <div class='date' id='datetimepicker7'>
                          <label class="control-label">To Date <span class="text-danger">*</span> </label>
                          <div>
                            <input id="to_date" name="to_date" type="text" autocomplete="off" onpaste="return false"
                              placeholder="To" bsDatepicker formControlName="to_date"  [minDate]="f.from_date.value" (focus)="dp.hide()"
                              bsDatepicker #dp1="bsDatepicker" (focus)="dp1.show()" (bsValueChange)="onFromDateChange($event)"
                              [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                              [ngClass]="{ 'is-invalid': submitted && f.to_date.errors }"
                              class="form-control form-control-sm" onkeypress='return event.charCode == 8'>
                            <div *ngIf="submitted && f.to_date.errors" class="invalid-feedback">
                              <div *ngIf="f.to_date.errors.required">To Date is required</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='col-md-2' style="margin-top: 2rem;"  >
                      <button type="submit" class="btn btn-sm btn-primary" value="Submit" (focus)="dp1.hide()">Submit</button>&nbsp;&nbsp;
                      <!-- <button type="button" class="btn btn-sm btn-danger" (click)="clear()">Clear</button> -->
                    </div>
                    <div class='col-md-2' style="margin-top: 2rem;"  >
                      <button type="button" class="btn  btn-sm btn-secondary float-right" (click)="exportexcel()">
                         <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> 
                    </div>
                  </div>
                </div>
              </div>
            </form>

            <div class="table-responsive">


              <table datatable id="paymenStatus" class="table table-striped table-bordered table-sm" style="width:100%">
                <thead class="text-center">
                  <tr>
                    <th class="singleLine">S No </th>
                    <th class="singleLine">Visit Date</th>
                    <th class="singleLine">Payment Date</th>
                    <th class="singleLine">Patient Name</th>
                    <th class="singleLine">Gender</th>
                    <th class="singleLine">Mobile</th>
                    <th class="singleLine">Registration No</th>
                    <th class="singleLine">Consultation No</th>
                    <th class="singleLine">Doctor Name </th>
                    <th class="singleLine">Amount</th>
                    <th class="singleLine">Status</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of payemtlist;let i= index;">
                    <td class="singleLine text-center">{{i+1}}</td>
                    <td class="singleLine">{{item.visit_date | date:'dd-MM-yyyy'}}</td>
                    <td class="singleLine">{{item.payment_date | date:'dd-MM-yyyy'}}</td>
                    <!-- {{item.advice?.is_self?item.beneficiary_id.patient_name:item.advice?.family_member.patient_name}}
                    <td class="singleLine">{{+item.gender == 1 ? 'Male':'Female'}}</td> -->
                    <td class="singleLine text-uppercase">{{(item.advice?.is_self == true || item.advice == null) ? item.patient_name : (item.advice?.family_member?.first_name + ' ' + item.advice?.family_member?.last_name)}}</td>
                    <td class="singleLine text-uppercase">{{(item.advice?.is_self == true || item.advice == null) ? getGender(item.gender) : getGender(item.advice?.family_member?.gender_id)}}</td>
                    <td class="singleLine text-uppercase">{{(item.advice?.is_self == true || item.advice == null) ? item.mobile_number : item.advice?.family_member?.member_mobile}}</td> <!-- mobile -->
                    <!-- <td class="singleLine text-uppercase">{{item.advice?.family_member?item.advice?.family_member.first_name:item.patient_name}}</td>
                    <td class="singleLine">{{item.advice != null ? getGender(item.advice?.family_member?.gender_id):getGender(item.gender)}}</td>                  
                    <td class="singleLine">{{item.advice != null ?item.advice?.family_member?.mobile:item.mobile_number}}</td> -->
                    <td class="singleLine">{{item.registration_no}}</td>
                    <td class="singleLine">{{item.consulation_no}}</td>
                    <td class="singleLine text-uppercase">{{item.doctor_name}}</td>
                    <td class="singleLine" class="text-left">{{item.amount}}</td>
                    <td class="singleLine">Credited</td>
                    <!-- <td>{{item.status}}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</div>