import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-userprofile',
  templateUrl: './userprofile.component.html',
  styleUrls: ['./userprofile.component.css'],
  providers: [DatePipe]
})

export class UserprofileComponent implements OnInit {

  user: any; docData: any; isSAGE = environment.isSAGE;
  roleId = 0; langs = ''; date = ''; qlf = ''; spl = ''; lstQ = []; lstS = []; lstL = [];

  constructor(private authSvc: AuthenticationService
    , private dtPipe: DatePipe
    , private mstrSvc: MasterService) { this.roleId = this.authSvc.roleId; }

  ngOnInit() {
    if (this.roleId == 1)
      this.user = this.authSvc.currentUserValue;
    else
      this.user = JSON.parse(sessionStorage.getItem('currentUser')), this.getMasters();
  }

  getMasters() {
    const qualifications = this.mstrSvc.getAll('getallqualifications'), specialization = this.mstrSvc.getAll('getallspecialization'),
       languages = this.mstrSvc.getAll('getalllanguages');    
    forkJoin([qualifications, specialization, languages]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              console.log(`No records available for ${ind == 0 ? 'Qualification' : 'Specialization'}`);
            else {
              const assignList = {
                '0': () => this.lstQ = list.data,
                '1': () => this.lstS = list.data,
                '2': () => this.lstL = list.data,
              }
              assignList[ind]();
            }
          }
        });
      },
      error: err => console.error('something wrong occurred: ', err),
      complete: () => {
        if (this.roleId == 7) {
          this.docData = JSON.parse(sessionStorage.getItem('DoctorDetails'));
          if (this.docData) {
            [this.date, this.qlf, this.spl, this.langs] = [this.dtPipe.transform(this.docData?.reg_expiry_date, 'dd-MM-yyyy'),
              this.lstQ.filter(f => f.id == this.docData?.qualification_id)[0]?.qualification ?? '',
              this.lstS.filter(f => f.id == this.docData?.specialization_id)[0]?.specialization ?? '', ''];
            this.docData?.doctor_languages.forEach((e, i) => {
              let lang = this.lstL.filter(f => f.id == e.language_id)[0]?.language_name ?? '';
              this.langs += i==0 ? lang : `, ${lang}`;
            });
          }
        }
      }
    });
  }

}