import { OrganizationComponent } from './../organization/organization.component';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal, NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/shared/services/master.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { Common } from 'src/app/common';
import { ApiService } from 'src/app/shared/services/api.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UserDetailsComponent } from '../../admin/user-details/user-details.component';
import { BaseComponent } from 'src/app/shared/base.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import * as XLSX from 'xlsx';
declare let $: any;

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.css']
})
export class OrganizationListComponent  extends BaseComponent implements OnInit {
  organizationList: Array<any> = [];
  modalTitle: string = ''; lstPlans = [];
  fileName = 'Organization list.xlsx';

  constructor(config: NgbModalConfig,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private apiServ: ApiService,
    private userServices: UserService,
    private authSvc: AuthenticationService) {
      super();
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.authSvc.isShowGlblOrg.next(false), this.authSvc.tenantId = sessionStorage.getItem('defCrntTnnt'),
    sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt'));
    this.lstPlans = [], this.loadTableData();
  }

  getPlans() {
    this.userServices.getAll('licenseplan/getall').subscribe(res => {
      if (res) {
        if (res.status == "OK")
          this.lstPlans = res.data;
      }
    });
  }

  loadTableData() {
    this.userServices.getAll('organization/allorganizationrecords').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Organization List Details');
        else {
          this.organizationList = res.data.filter(f => f.id != -1);
          Common.loadDataTable(this.organizationList, '#organizationTable');
        }
      }
      this.getPlans();
    });
  }

  openModal(item: any = null) {
    const modalRef = this.modalService.open(OrganizationComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.editData = item;
    
    
    modalRef.result.then(result => {
      if (result == 'changes saved')
        this.loadTableData();
      // here call Table load API
    }, (reason) => { });
    
  }

  exportexcel(): void {
    var table = $('#organizationTable').DataTable();
    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {	
      / table id is passed over here /
      let element = document.getElementById('organizationTable');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };

      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
  }

}