<!-- <div class="modal fade" id="exampleModal_1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
  aria-hidden="true">
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content"> -->
<div class="modal-header">
  <h5 class="modal-title" id="exampleModalLabel">Prescription</h5>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body p-0">
  <table border="0" style="background-color:#f8f6f6;width:100%;">
    <tr>
      <td style="padding: 20px;">
        <table class="" border="0" cellpadding="4" cellspacing="4"
          style="width: 100%; font-size:14px; background-color: #f8f6f6; padding: 4px;"
          [ngStyle]="{ 'background': 'url(' + bg + ')', 'background-repeat': 'no-repeat', 'background-position': 'center', 'background-attachment': 'cover' }">
          <tr style="background-color:#f2f2f2;">
            <td colspan="6">
              <table border="0" style="width:100%;">
                <tr>
                  <td></td>
                  <!-- <img src="../../../assets/images/prescription.png"> -->
                  <td valign="middle">
                    <address style="margin-bottom: 0px !important;"><small>
                        <strong>Name  :</strong> <strong class="text-uppercase">
                          {{doc.first_name+ ' ' + doc.last_name}}</strong>
                        {{doc.qualification + ((doc.superspecialiy != undefined) ? ', ' + doc.superspecialiy : '' )}}<br />
                        <!-- <strong>Mobile Number  :</strong>{{doc.mobile}}<br /> -->
                        <strong>MCI Number  :</strong>{{doc.mci}}<br />
                        <strong>Address  :</strong>{{doc.address}}<br />
                        <strong>Pin Code  :</strong>{{doc.pinCode}}<br />
                        <strong>Date & Time  :</strong>{{prescriptionData.visit_date|date:'dd-MM-yyyy'}}
                        {{prescriptionData.visit_start_time}}<br />
                      </small>
                    </address>
                  </td>
                  <td align="right"><img [src]="presLogo"></td>
                </tr>
              </table>
            </td>
          </tr>
          <tr style="height:2px;">
            <td colspan="6"></td>
          </tr>
          <tr>
            <td><strong>Patient Name :</strong> </td>
            <!-- <td>{{benData?.first_name?.toUpperCase()+ benData?.last_name?.toUpperCase()}}</td> -->
            <td  *ngIf="depDtls == null">{{(benData?.first_name?.toUpperCase()||'') + ' ' + (benData?.middle_name?.toUpperCase()||'')  + ' ' + (benData?.last_name?.toUpperCase()||'')}}</td>
            <td  *ngIf="memData == null && depDtls ">{{(depDtls?.first_name?.toUpperCase()||'') + ' ' + (depDtls?.middle_name?.toUpperCase()||'')  + ' ' + (depDtls?.last_name?.toUpperCase()||'')}}</td>
            <td *ngIf="memData != null">{{memData.member_name?.toUpperCase()|| ''}}</td>
            <td><strong>Age :</strong> </td>
            <td *ngIf="depDtls == null">{{benData.age}}</td>
            <td *ngIf="memData == null && depDtls ">{{depDtls.age}}</td>
            <td *ngIf="memData !=null">{{memData.age}}</td>
            <td><strong>Gender :</strong> </td>
            <td *ngIf="depDtls == null">{{getGender(benData.gender_id)}}</td>
            <td *ngIf="memData == null && depDtls">{{getGender(depDtls.gender_id)}}</td>
            <td *ngIf="memData !=null">{{getGender(memData.gender_id)}}</td>
          </tr>
          <tr>
            <td><strong>Reg Number :</strong> </td>
            <td *ngIf="memData == null">{{regNo}}</td> <!-- <td>{{benData.id}}</td> -->
            <td *ngIf="memData !=null">{{benId}}</td>
            <td><strong>Mobile Number :</strong></td>
            <!-- <td>{{member_mobile==null?benData.mobile:member_mobile}}</td> -->
              <td>{{mobNum}}</td>
            <td><strong>Address :</strong> </td>
            <td>{{benData.address}}</td>
          </tr>
          <tr>
            <td><strong>ABHA Address :</strong> </td>
            <td><span *ngIf="healthidaddress=='null'"> </span><span *ngIf="healthidaddress!='null'"> {{healthidaddress}}</span></td>
            <td><strong>ABHA Number :</strong> </td>
            <td><span *ngIf="healthidnumber=='null'"> </span><span *ngIf="healthidnumber!='null'"> {{healthidnumber}}</span></td>
          </tr>
          <tr *ngIf="registered_name.length>0">
            <td><strong>Registered Name :</strong> </td>
            <td colspan="5">{{registered_name}}</td>
          </tr>
         
          <tr>
            <td colspan="6">
              <hr style="border:1px solid #17c5bb">
            </td>
          </tr>
          <tr>
            <h6>Covid 19 - {{ isCovidExists() ? '' : 'NA'}}</h6>
          </tr>
          <div *ngIf="isCovidExists()">
          <tr>
            <h6>Temperature:</h6><td>{{this.trmprt}}</td>
          </tr>
          <tr>
            <h6>Symptoms:</h6><td>{{this.covid19.symptomsdata}}</td>
          </tr>
          <tr>
            <h6>Travel History:</h6><td>{{this.trvlhistry}}</td>
          </tr>
          <tr>
            <h6>Co-morbidities:</h6><td>{{this.covid19.co_morbiditiesdata}}</td>
          </tr>
          <tr>
            <h6>How have your symptoms progressed over last 48 hours? :</h6><td>{{this.symptomsProgresseddata}}</td>
          </tr>
          <tr>
            <h6>Precautions:</h6><td>{{this.covid19.precautionsdata}}</td>
          </tr>
          <tr>
            <h6>Point of Care Testing:</h6><td>{{this.covid19.pointOfCaredata}}</td>
          </tr>
          <tr>
            <h6>Chemistry:</h6><td>{{this.covid19.chemistrydata}}</td>
          </tr>
          <tr>
            <h6>Hematology:</h6><td>{{this.covid19.hematologydata}}</td>
          </tr>
          <tr>
            <h6>Microbiology:</h6><td>{{this.covid19.inuenza_pcrdata}}</td>
          </tr>
          <tr>
            <h6>Real-Time Polymerase chain reaction for SARS-CoV-2:</h6><td>{{this.covid19.sars_cov2data}}</td>
          </tr>
          <tr>
            <h6>Urine:</h6><td>{{this.covid19.urinedata }}</td>
          </tr>
          <tr>
            <h6>Radiology:</h6><td>{{this.covid19.plainFilmsdata}}</td>
          </tr>
          <tr>
            <h6>CT Scan:</h6><td>{{this.covid19.ctScandata}}</td>
          </tr>
          <tr>
            <h6>INnstruction:</h6><td>{{this.covid19.instructionsdata}}</td>
          </tr>
          <tr>
            <h6>Patient Education:</h6><td>{{this.covid19.patienteducationdata}}</td>
          </tr>
        </div>
          <tr>
            <td colspan="6">
              <table cellpadding="2">
                <tbody>
                  <tr>
                    <td>
                      <h6 class="mb-0">Chief Complaints/ History of Present Illness: </h6>
                    </td>
                  </tr>
                  <tr>
                    <td>{{pocCc}}</td>
                  </tr>
                  <!-- <tr>
                    <td>
                      <h6 class="mb-0">Others if any:</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>{{otherIfany}} </td>
                  </tr> -->
                  <tr style="height: 5px;">
                    <td></td>
                  </tr>
                  <tr *ngIf="pocVitals_exists != ''">
                    <td>
                      <h6 class="mb-0">Vitals:</h6>
                    </td>
                  </tr>
                  <tr *ngIf="pocVitals_exists != ''">
                    <td [innerHTML]="pocVitals"> </td>
                  </tr>
                  <tr style="height: 5px;">
                    <td></td>
                  </tr>
                  <tr *ngIf="pocHistory != ''">
                    <td>
                      <!-- <h6 class="mb-0">Past History &amp; Allergies:</h6> -->
                      <h6 class="mb-0">Allergies:</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>{{pocHistory}} </td>
                  </tr>
                    <!-- New lines added for findings #chandu 06-05-2022 -->
                    <tr *ngIf="prescriptionData.findings?.length>0">
                      <td>
                        <h6 class="mb-0">Findings:</h6>
                      </td>
                    </tr>
                    <tr>
                      <td>{{prescriptionData.findings}} </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 class="mb-0">Instructions:</h6>
                      </td>
                    </tr>
                    <tr>
                      <!-- <td>{{prescriptionData?.drugs?.instruction}} </td> -->
                      <td>{{prescriptionData?.instructions}} </td>
                    </tr>
                    <!-- end of line finding -->
                  <tr style="height: 5px;">
                    <td></td>
                  </tr>
                  <tr *ngIf="prescriptionData.diagnosis.provisionalDiagnosis != ''">
                    <td>
                      <h6 class="mb-0">Provisional Diagnosis:</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>{{provDgns}}{{provDiagnosis}}</td> <!-- <td> {{prescriptionData.diagnosis.provisionalDiagnosis}} </td> -->
                  </tr>
                  <!-- Advice 21-02-2023 -->
                  <tr *ngIf="prescriptionData.advice.restExercise != ''">
                    <td>
                      <h6 class="mb-0">Advise:</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>{{prescriptionData.advice.restExercise}}</td> 
                  </tr>
                  <!-- <tr *ngIf="prescriptionData.advice.followupDate != ''">
                    <td>
                      <h6 class="mb-0">Follow Up Date:</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>{{prescriptionData.advice.followupDate}}</td> 
                  </tr> -->
                  
                  <!-- <tr>
                    <td>
                      <h6>Others if any:</h6>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      {{provDiagnosis}}
                    </td>
                  </tr> -->
                  <!-- <tr style="height: 5px;">
                    <td></td>
                  </tr> -->
                  <!-- <tr><td><h6 class="mb-0">Investigations </h6></td></tr>
                                <tr><td>  CBP, SMEAR FOR MP, WIDAL</td></tr>
                                <tr style="height: 5px;"><td></td></tr> -->
                                <!-- below lines commented 21-02-2023-->
                  <!-- <tr *ngIf="pocAdvice_exists != '' || pocComment != ''">
                    <td>
                      <h6 class="mb-0">Advice:</h6>
                    </td>
                  </tr>
                  <tr *ngIf="pocAdvice_exists != '' || pocComment != ''">
                    <td [innerHTML]="pocAdvice"></td>
                  </tr> -->
                  <!-- <tr>
                          <td>{{pocComment}}</td>
                        </tr> -->
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <table border="0" style="width:100%;" cellpadding="2">
                <tbody>
                  <tr style="background-color: #17c5bb; color: #ffffff;">
                    <th>S.No</th>
                    <th>Drug Type</th>
                    <th>Drug Name</th>
                    <th>Dosage</th>
                    <th>Direction</th>
                    <th>Drug Instructions</th>
                    <th>Period</th>
                    <th>Remarks</th>
                    <!-- <th>Agent Stock</th> -->
                  </tr>
                  <!-- <tr> -->
                  <tr *ngFor="let item of prescriptionData.prescribed_medications;let i=index;">
                    <td>{{(i+1)}}</td>
                    <td>{{item.type}}</td>
                    <td>{{item.name}}</td>
                    <td>{{item.dosage}}</td>
                    <td>
                      {{(item.mrng== true ? 'O' : 'X') + ' - ' + (item.aftr== true ? 'O' : 'X') + ' - ' + (item.evng== true ? 'O' : 'X') +  ' - ' + (item.night == true ? 'O' : 'X')}}
                    </td>
                    <td>{{item.relfood != 'Anytime' ? (item.relfood + ' Food') : item.relfood}}</td>
                    <td>{{item.count}}&nbsp;{{item.period}}</td>
                    <td>{{item.remarks}}</td>
                    <!-- <td>{{item.agentStock}}</td> -->
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <!-- <td><strong>Note</strong> </td> -->
            <!-- <td *ngFor = "let item of nAD" >{{nAD}}</td> -->
          </tr>
          <tr>
            <td colspan="6">
              <hr style="border:1px solid #17c5bb">
            </td>
          </tr>
          <tr *ngIf="pharm!=''">
            <td><strong>Pharmacy Details</strong> </td>
            <td colspan="5">
              {{pharm}}
            </td>
          </tr>
          <tr *ngIf="pharm!=''">
            <td><strong>Discount (%):</strong> </td>
            <td colspan="5">{{phrmDisc}}&nbsp;&nbsp;(Upon showing this prescription).</td>
          </tr>
          <tr>
            <td colspan="6">
              <table cellpadding="2">
                <tbody>
                  <tr *ngIf="instruction!=''">
                    <td>
                      <h5>Instructions:</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>{{instruction}} </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <table cellpadding="2">
                <tbody>
                  <tr *ngIf="prescriptionData.investigation.master.length > 0">
                    <td colspan="2">
                      <h5>Investigations:</h5>
                    </td>
                  </tr>
                  <!-- <tr> -->
                  <tr *ngFor="let item of prescriptionData.investigation.master;let i = index">
                    <td>{{i+1}}</td>
                    <td>{{item.investigation}} </td>
                  </tr>
                  <tr>
                    <td>
                      {{prescriptionData.investigation.otherInvestigation != '' ? ((prescriptionData.investigation.master) != '' ? ((prescriptionData.investigation.master).length + 1) : '1') : ''}}
                    </td>
                    <td>{{prescriptionData.investigation.otherInvestigation}}</td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr *ngIf="lab!=''">
            <td><strong>Lab Details</strong> </td>
            <td colspan="5">
              {{lab}}
            </td>
          </tr>
          <tr *ngIf="lab!=''">
            <td><strong>Discount (%):</strong> </td>
            <td colspan="5">{{labDisc}}&nbsp;&nbsp;(Upon showing this prescription).</td>
          </tr>
          <tr>
            <td colspan="6">
              <table cellpadding="2">
                <tbody>
                  <tr *ngIf="prescriptionData.advice.followupDate != ''">
                    <td>
                      <h5>Followup Date:</h5>
                    </td>
                  </tr>
                  <tr>
                    <td>{{prescriptionData.advice.followupDate | date :'dd-MM-yyyy'}} </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr *ngIf="isUkt">
            <td colspan="6">
              <table cellpadding="2">
                <tbody>
                  <tr>
                    <td>
                      <h6> Physical Consultation : </h6> <span>{{isPcChecked == true? "Required" : "Not Required"}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr *ngIf="isUkt">
            <td colspan="6">
              <table cellpadding="2">
                <tbody>
                  <tr>
                    <td *ngIf="referral_hospital">
                      <h6> Referral Hospital : </h6> <span>{{referral_hospital}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr *ngIf="isUkt">
            <td colspan="6">
              <table cellpadding="2">
                <tbody>
                  <tr>
                    <td *ngIf="procedurevalue">
                      <h6> Procedure : </h6> <span>{{procedurevalue}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colspan="6" align="center">
              <hr style="border:1px solid #17c5bb; margin-bottom:0px;">
              <small style="font-size: 10px; text-align: center;">
                <!-- Substitute medicine brand if above unavailable unless Doctor Says not to -->
              </small>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <table border="0" style="width:100%; padding: 20px;">
                <tr>
                  <td align="left" style="padding:0px 20px;"><img src="../../../assets/images/qrcode.png"
                      style="width:50px;"> <br />
                    For support please call {{clientName}}{{tollFree}} <br />
                  </td>
                  <td align="right">
                    <img [src]='imgSignurl' width="100px">
                    <address><small>
                        <!-- <strong class="text-uppercase"> Dr {{doc.first_name+ ' ' + doc.last_name}}
                                {{doc.superspecialiy != undefined) ? ', ' + doc.superspecialiy : ''}}</strong> -->
                        <strong class="text-uppercase"> Dr {{doc.first_name+ ' ' + doc.last_name}}</strong><br />
                        {{doc.qualification + ((doc.superspecialiy != undefined) ? ', ' + doc.superspecialiy : '' )}}<br />
                      </small>
                    </address>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr style="background-color:#f2f2f2;">
      <td colspan="6" align="center">
        <hr style="border:1px solid #17c5bb; margin-bottom:0px;">
        <!-- <small style="font-size: 14px; text-align: center;">Vidmed Pharmacy Pharmacy, Ameerpet, Hyderabad,
                            Telangana, Contact : 9849012835 </small> -->
      </td>
    </tr>
  </table>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-secondary" id="prescriptionModalClose"
    (click)="activeModal.close('Close click')">Close</button>
  <button type="button" class="btn  btn-sm btn-primary" (click)="onSubmit()" *ngIf="show">Submit</button>
  <!-- activeModal.close('SubmitEvent') -->
</div>
<!-- </div>
              </div>
            </div> -->