<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Hub-Coordinator List
                        <button (click)="openAgentModal()" class="btn btn-blue btn-icon float-right"> <i
                                class="fa fa-plus"></i>
                            <!-- openAgentModal() -->
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-xl-12">
                                    <form [formGroup]="cntrLstForm">
                                        <div class="row mt-1">
                                            <!-- <div class="col-xl-2">
                                                <label>State <span class="text-danger"><b>*</b></span> </label>
                                                <select class="form-control form-control-sm" id="state" formControlName="state_code"
                                                   >
                                                    <option value=0>Select</option>
                                                </select>
                                            </div> -->
                                            <div class="col-xl-2">
                                                <div class="form-group">
                                                    <label class="control-label">Location Type </label>
                                                    <div>
                                                        <select formControlName="location_type_id" id="location_type_id"
                                                            #locationId (change)="locationChanged('','location')"
                                                            [ngClass]="{ 'is-invalid': submitted && f.location_type_id.errors }"
                                                            class="form-control form-control-sm">
                                                            <option value="0">Select</option>
                                                            <option *ngFor="let location of locationTypeList"
                                                                [value]="location.id">
                                                                {{location.location_type}}</option>
                                                        </select>
                                                        <div *ngIf="submitted && f.location_type_id.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.location_type_id.errors.required">Location
                                                                Type is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-2">
                                                <div class="form-group">
                                                    <label class="control-label">{{isNIG ? 'States' : 'State'}} </label>
                                                    <div>
                                                        <select formControlName="state_id" id="state_id" #State
                                                            (change)="locationChanged(State.value,'district')"
                                                            [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }"
                                                            class="form-control form-control-sm">
                                                            <option value="0">Select</option>
                                                            <option *ngFor="let state of stateList" [value]="state.id">
                                                                {{state.state_name}}</option>
                                                        </select>
                                                        <div *ngIf="submitted && f.state_id.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.state_id.errors.required">
                                                                {{isNIG ? 'States' : 'State'}} is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-xl-2">
                                                <label>District <span class="text-danger"><b>*</b></span> </label>
                                                <select class="form-control form-control-sm" id="dist" formControlName="district_code"
                                                   >
                                                    <option value=0>Select</option>
                                                </select>
                                            </div> -->
                                            <div class="col-xl-2">
                                                <div class="form-group">
                                                    <label class="control-label">{{isNIG ? 'State Capitals' :
                                                        'District'}}
                                                    </label>
                                                    <div>
                                                        <select formControlName="district_id" id="district_id" #District
                                                            (change)="locationChanged(District.value,(+locationId.value==2)?'city':'mandal')"
                                                            [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }"
                                                            class="form-control form-control-sm">
                                                            <option value="0" selected>Select</option>
                                                            <option *ngFor="let district of districtList"
                                                                [value]="district.id">
                                                                {{district.district_name}}</option>
                                                        </select>
                                                        <div *ngIf="submitted && f.district_id.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.district_id.errors.required">
                                                                {{isNIG ? 'State Capitals' : 'District'}} is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- <div class="col-xl-2">
                                                <label>Location Type <span class="text-danger"><b>*</b></span> </label>
                                                <select class="form-control form-control-sm" id="location" formControlName="locationtypeid" 
                                            >
                                                    <option value=0>Select</option>
                                                  
                                                </select>
                                            </div> -->

                                            <!-- <div class="col-xl-2">
                                                <label>Block / City</label> 
                                                <select class="form-control form-control-sm" id="blck" formControlName="block_code"
                                                 >
                                                    <option value=0>Select</option>
                                                   
                                                </select> 
                                            </div> -->
                                            <div class="col-xl-2">
                                                <!-- Mandal -->
                                                <div class="form-group">
                                                    <label class="control-label">{{isNIG ? 'Local Government Areas' :
                                                        'Mandal'}} </label>
                                                    <div>
                                                        <select formControlName="mandal_id" id="mandal_id" #Mandal
                                                            (change)="locationChanged(Mandal.value,'village')"
                                                            [ngClass]="{ 'is-invalid': submitted && f.mandal_id.errors }"
                                                            class="form-control form-control-sm">
                                                            <option value="0">Select</option>
                                                            <option *ngFor="let mandal of mandalList"
                                                                [value]="mandal.id">
                                                                {{mandal.mandal_name}}</option>
                                                        </select>
                                                        <div *ngIf="submitted && f.mandal_id.errors"
                                                            class="invalid-feedback">
                                                            <div *ngIf="f.mandal_id.errors.required">
                                                                {{isNIG ? 'Local Government Areas ' : 'Mandal '}} is
                                                                required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-xl-4" style="padding-top: 2%;">
                                                <button type="submit" class="btn btn-primary mb-4 text-center"
                                                    (click)="onSearch()">Search</button>
                                                <button type="submit" class="btn btn-warning mb-4 ml-2 text-center"
                                                    (click)="onReset()">Cancel</button>
                                            </div>
                                            <div class="col-xl-1 text-right">
                                                <!-- <button type="submit" (click)="onAddEdit()" class="btn btn-info mb-2 mr-1 text-center"><i class="fa fa-plus"></i> Add</button> -->
                                                <!-- <button type="button" class="btn btn-secondary mb-2 mr-1 text-center"(click)="exportexcel()" > <i class="fa fa-file-excel-o"></i>&nbsp;Export As Excel</button> &nbsp; &nbsp; -->

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive">
                            <table datatable id="centerregTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr style="background-color: lightskyblue">
                                        <th class="text-center" style="font-size:15px;">S.No</th>
                                        <th style="font-size:15px;">Coordinator Name</th>
                                        <th style="font-size:15px;">State</th>
                                        <th style="font-size:15px;">District</th>
                                        <th style="font-size:15px;">Mobile</th>
                                        <th style="font-size:15px;">Email</th>
                                        <th style="font-size:15px;">DOB</th>
                                        <th style="font-size:15px;">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of getAllcenters; let i = index">
                                        <td>{{i+1}}</td>
                                        <td>{{item.first_name | uppercase}} {{item.last_name | uppercase}}</td>
                                        <td>{{item.stateName}}</td>
                                        <td>{{item.districtName}}</td>
                                        <td>{{item.mobile}}</td>
                                        <td>{{item.email}}</td>
                                        <td>{{item.birth_date | date:'dd-MM-yyyy'}}</td>


                                        <td class="text-center">
                                            <span>
                                                <a title="Edit"
                                                    (click)="modalTitle='Pharmacist/Healthcare Professional Registration Details';onGapEdit(item?.id)"
                                                    class="badge badge-info badge-pill"><i
                                                        class="fa fa-edit text-white pointer"></i></a>&nbsp;
                                            </span>

                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>



                </div>
            </div>

        </div>
    </div>
</div>