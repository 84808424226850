import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AgentService {

  constructor(private api: ApiService) { }

  callSvc(apiMthd: string, mthdType: string = 'get', payLoadData: any = null): Observable<any> {
    if (mthdType == 'get')
      return this.api.get(`${environment.agentApi}${apiMthd}`);
    else
      return this.api.post(`${environment.agentApi}${apiMthd}`, payLoadData);
  }

}