
<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
<!-- this creates a google map on the page with the given lat/lng from -->
<!-- the component as the initial center of the map: -->

<div class="modal-body heightScroll" >
    <div class="container-fluid">
        <!-- <agm-map [latitude]="lat" [longitude]="lng"> -->
            <!-- <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker> -->
            <!-- <agm-marker  *ngFor="let data of dataList; let i = index" [latitude]="data.lat" [longitude]="data.lng">
              <agm-info-window>Hyderabad</agm-info-window>
            </agm-marker>
          </agm-map> -->
    </div>
  </div>
  <div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" value="Submit">&nbsp;&nbsp;
    <button id="btnCovid19Close" type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Exit</button>
    <button type="button" id="btnCovidModal" data-toggle="modal" data-target="#covid" hidden></button>
  </div>





