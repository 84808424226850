import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { PatientRegistrationComponent } from '../patient-registration/patient-registration.component';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { DatePipe } from '@angular/common';
import { FamilyMemberRegistrationComponent } from '../family-member-registration/family-member-registration.component';
import { DssFlowComponent } from '../dss-flow/dss-flow.component';
import * as XLSX from 'xlsx';
declare let $: any;
declare let JqueryReady: any;
declare const answerCall: any;
declare let joinSession: any;
declare let register: any;
declare let statusRegister: any;
declare let updateStatus: any;
declare let stopVideo2: any;
declare let agenttodoctorcalldisconnct: any;
import { Common } from 'src/app/common';
import { VitalsComponent } from '../../modals/vitals/vitals.component';
import { ActiveDoctorsListComponent } from '../../consultation/active-doctors-list/active-doctors-list.component';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import { ExcelService } from 'src/app/shared/services/excel.service';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
import { JsonpClientBackend } from '@angular/common/http';
import { UserService } from 'src/app/shared/services/user.service';
@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.css'],
  providers: [DatePipe],
})

export class PatientListComponent implements OnInit {
  today: Date = new Date();
  pipe = new DatePipe('en-US');
  todayWithPipe = null;
  modalTitle: string = '';
  patientList: Array<any> = [];
  doctorDetails: any;
  patientSearchForm: FormGroup;
  search_string: any = '';
  submitted = false;
  CurpageNumber: any = 0;
  NextpageNumber: any = 0;
  PrevpageNumber: any = 0;
  totalElements: any = 0;
  rowIndex: any = 0;
  pageSize: any = 10
  is_agent: boolean = sessionStorage.logUsrRoleId == '14';
  roleId: any; patvitals: FormGroup; tempErrMsg: boolean; systolic = ''; diastolic = ''; patientid: any; openvideotag = false; showframe = false;
  showpatientlistdiv = true;
  benID: any;
  fileName = 'Patient list.xlsx';
  benDetails = []; healthcardArray = []; imagePath = ''; doctorcenterid: any;
  excelData: any = [];
  Flagslist: any;
  paitientRegDisabledDocFlag: any;
  paitientRegDisabledAdminFlag:any;
  hideLinkWithAbha: any;
  familyMemberButtonHide: boolean;
  constructor(config: NgbModalConfig, private userServ: UserService,
    public changeDetection: ChangeDetectorRef
    , private modalService: NgbModal
    , private toastr: ToastrService
    , private router: Router
    , private formBuilder: FormBuilder
    , private authSvc: AuthenticationService
    , private regService: RegistrationService
    , private datePipe: DatePipe,
    private excelDownloadService: ExcelDownloadService,
    private excelService: ExcelService
    , private masterService: MasterService) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
    this.roleId = this.authSvc.roleId;
    if (this.roleId == null) {
      this.roleId = JSON.parse(sessionStorage.getItem('currentUser')).user_role_mapping[0].user_type_id.id;
    }
    this.patientList = []
    // Common.loadDataTable(this.patientList, '#patientTable')

  }

  ngOnInit(): void {
    this.patientSearchForm = this.formBuilder.group({ search_string: ['', Validators.required] });
    this.authSvc.isShowGlblOrg.next(false), this.authSvc.tenantId = sessionStorage.getItem('defCrntTnnt') || sessionStorage.getItem('TENANT_ID'),
      sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt') || sessionStorage.getItem('TENANT_ID')),
      this.doctorDetails = JSON.parse(sessionStorage.getItem('DoctorDetails'));
    this.doctorcenterid = this.doctorDetails?.center_id;
    this.getPatientsData(this.CurpageNumber);
    sessionStorage.setItem('fromdocid', null);
    sessionStorage.setItem('myId', null);
    // this.getAllPatients()
    // $(document).ready(function () {
    //   var table = $('#pharmacyTable').DataTable({
    //     lengthChange: false,
    //     destory: true,
    //     // buttons: ['copy', 'excel', 'pdf', 'colvis']
    //   });

    //   table.buttons().container()
    //     .appendTo('#example1_wrapper .col-md-6:eq(0)');
    // });
    this.isOffLinePOC();
    this.initFormControls();
    this.frameshow();
    if (this.roleId == 14) {
      register('', 'pat_' + sessionStorage.agentId, sessionStorage.getItem('logUsrName'), '', '', '', '');
      //statusRegister('', '', '', '', '', '');
      //updateStatus(item.id,"7",item.status,"call",'',item.numberOfYrsExperience,item.qualification,item.groupReferenceCode,item.individualReferenceCode,item.exclusiveReferenceCode)
      //statusRegister(sessionStorage.agentId, 'patient', "AVAILABLE", "register", '', '', '', '', '', '')
    }
    this.getUserPermission();
  }
  getUserPermission() {
    let orgIdValue = JSON.parse(sessionStorage.getItem('currentTenant'));
    this.userServ.getUserPermissionByOrgId(orgIdValue).subscribe((res: any) => {
      if (res.status == 'OK') {
        this.Flagslist = res.data;
        for (let i = 0; i < res.data.length; i++) {
          if (res.data[i].feature_id.feature_name === "PatientRegistrationDisabled") {
            this.paitientRegDisabledDocFlag = res.data[i].patient
          }
          if (res.data[i].feature_id.feature_name === "PatientRegistrationDisabled") {
            this.paitientRegDisabledAdminFlag = res.data[i].patient
          }
          if (res.data[i].feature_id.feature_name === "hideLinkWithAbha") {
            if(!res.data[i].patient && this.roleId==7){
              this.hideLinkWithAbha = false;
            } else{
              this.hideLinkWithAbha = true;
            }
          }
          if (res.data[i].feature_id.feature_name === "familyMemberButtonHide") {
            if(!res.data[i].patient && (this.roleId==7 || this.roleId==2)){
              this.familyMemberButtonHide = false;
            } else{
              this.familyMemberButtonHide = true;
            }
          }
         
          
       
        
        }
      }
    });
  }
  openPharmacyModal() {
    const modalRef = this.modalService.open(PatientRegistrationComponent, { size: 'xl' });
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.result.then(result => {
      // here call Table load API
    }, (reason) => { });
  }
  openPatientModal() {
    // this.changeDetection.detach();

    const modalRef = this.modalService.open(PatientRegistrationComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = this.modalTitle;
    // this.changeDetection.reattach();
  }

  getPatientsData(page_number) {
    // alert(typeof(page_number) + page_number)
    const [isAgent, agentId] = [(sessionStorage.logUsrRoleId == '14'), (sessionStorage.agentId || 0)];
    this.submitted = true;
    this.search_string = this.patientSearchForm.value.search_string.toString().trim();
    let payload = { "page": page_number, "search_string": this.search_string, "size": this.pageSize };
    // if(sessionStorage.getItem('TENANT_ID')==50+''){
      this.regService.getPatientsBySearchNew('POST', payload, isAgent, agentId, this.doctorcenterid,this.doctorDetails.id).subscribe(res => {
        if (res.status == 'ACCEPTED') {
          if (res.data['content'].length > 0) {
            this.totalElements = res['data']['totalElements'];
  
            this.patientList = res.data['content'];
            if (this.patientList) {
              // Common.loadDataTable(this.patientList, '#patientTable')
            }
  
            if ((res.data["totalPages"] == 1) || (this.patientList.length <= this.pageSize && res.data["totalPages"] == this.NextpageNumber + 1)) {
              // console.log('ttl Page Size..',res.data["totalPages"])
              this.CurpageNumber = res.data["number"];
              this.NextpageNumber = 0;
              this.PrevpageNumber = res.data["number"] - 1;
  
            } else {
              this.CurpageNumber = res.data["number"];
              this.NextpageNumber = res.data["number"] + 1;
              this.PrevpageNumber = res.data["number"] - 1;
              this.rowIndex = 0;
            }
            this.rowIndex = this.CurpageNumber * this.pageSize;
  
          }
          else {
            this.toastr.warning("No matching records found", "Patient List");
            this.CurpageNumber = 0;
            this.NextpageNumber = 0;
            this.PrevpageNumber = 0;
            this.totalElements = 0;
            this.patientList = [];
            // Common.loadDataTable(this.patientList, '#patientTable')
          }
        }
        else {
          this.patientList = [];
          // Common.loadDataTable(this.patientList, '#patientTable')
          this.toastr.warning(res.message, 'Patient List');
          this.CurpageNumber = 0;
          this.NextpageNumber = 0;
          this.PrevpageNumber = 0;
          this.totalElements = 0;
        }
      });
  
    // } else{
    //     this.regService.getPatientsBySearch('POST', payload, isAgent, agentId, this.doctorcenterid).subscribe(res => {
    //   if (res.status == 'ACCEPTED') {
    //     if (res.data['content'].length > 0) {
    //       this.totalElements = res['data']['totalElements'];

    //       this.patientList = res.data['content'];
    //       if (this.patientList) {
    //         // Common.loadDataTable(this.patientList, '#patientTable')
    //       }

    //       if ((res.data["totalPages"] == 1) || (this.patientList.length <= this.pageSize && res.data["totalPages"] == this.NextpageNumber + 1)) {
    //         // console.log('ttl Page Size..',res.data["totalPages"])
    //         this.CurpageNumber = res.data["number"];
    //         this.NextpageNumber = 0;
    //         this.PrevpageNumber = res.data["number"] - 1;

    //       } else {
    //         this.CurpageNumber = res.data["number"];
    //         this.NextpageNumber = res.data["number"] + 1;
    //         this.PrevpageNumber = res.data["number"] - 1;
    //         this.rowIndex = 0;
    //       }
    //       this.rowIndex = this.CurpageNumber * this.pageSize;

    //     }
    //     else {
    //       this.toastr.warning("No matching records found", "Patient List");
    //       this.CurpageNumber = 0;
    //       this.NextpageNumber = 0;
    //       this.PrevpageNumber = 0;
    //       this.totalElements = 0;
    //       this.patientList = [];
    //       // Common.loadDataTable(this.patientList, '#patientTable')
    //     }
    //   }
    //   else {
    //     this.patientList = [];
    //     // Common.loadDataTable(this.patientList, '#patientTable')
    //     this.toastr.warning(res.message, 'Patient List');
    //     this.CurpageNumber = 0;
    //     this.NextpageNumber = 0;
    //     this.PrevpageNumber = 0;
    //     this.totalElements = 0;
    //   }
    // });

    // }
  
  }

  OfflinePocSubmit(patient_data: any) {
    if(patient_data.relation!=='self'){
      this.regService.getBeneficiaryFamilyMember(patient_data.head_id,patient_data.member_id).subscribe((res:any) => {
            if (res.status == 'OK') {
            let data = res.data[0];
            this.OfflinePocOfFamilyMemberSubmit(data);
          }
          else{
            this.toastr.error(res.error);
             
          }  
          })
    
     return;
    } 
    sessionStorage.setItem('fromdocid', null);
    sessionStorage.setItem('myId', null);
    sessionStorage.setItem('fromrefpat', '');
    sessionStorage.setItem('patientidchat', patient_data.head_id);
    sessionStorage.setItem('mem_type', 'true');
    sessionStorage.setItem('callingpatient_id', patient_data.head_id)
    let payload = {
      "patient_id": patient_data.head_id,
      "doctor_id": this.doctorDetails.head_id,
      "patient_name": patient_data.first_name + patient_data.last_name,
      "patient_email": patient_data.email
    }
    this.regService.generateConsulation('POST', payload).subscribe(res => {
      if (res.status == 'OK') {
        let fromId = (this.doctorDetails.id).toString();
        let toId = (patient_data.head_id).toString();
        $("#fromId").val("doc_" + fromId);
        $("#toId").val("pat_" + toId);
        $("#order_no").val(res.data.order_no);
        $("#is_self").val(true);
        $("#patient_name").val(patient_data.first_name + patient_data.last_name);
        $("#patient_interaction_id").val(res.data.consultation_id);
        sessionStorage.setItem('chat_consultationid', res.data.consultation_id);
        $("#patient_info").val(JSON.stringify({ "patient_interaction_id": res.data.consultation_id, "order_no": res.data.order_no, "hospital_id": "", "symptoms": "", "is_self": true }));
        sessionStorage.isPatient = true;
        this.router.navigate(['home/poc'])
          .then((e) => {
            let ws = JSON.parse(sessionStorage.getItem('webSkt')); // JqueryReady(); // answerCall(fromId, toId);
            Promise.all([JqueryReady()]).then(res => { answerCall(fromId, toId, ws.expn, ws.qlfnName, ws.grCode, ws.irCode, ws.erCode); });
            if (e)
              console.log("Navigation is successful!");
            else
              console.log("Navigation has failed!");
          });

      }
      else {
        this.toastr.warning(res.message, 'Generate Consulation');
      }
    });


  }
  // new method added for family member offline poc 22-02-2023
  OfflinePocOfFamilyMemberSubmit(patient_data: any) {
    sessionStorage.setItem('pocEditFmember',JSON.stringify(patient_data))
    sessionStorage.setItem('familymemberscreenT','familymemberscreen');
    sessionStorage.setItem('patientidchat',patient_data.id);
    //new changes
    sessionStorage.setItem('callingpatient_id_fm',patient_data.id)
    sessionStorage.setItem('callingpatient_id',patient_data.beneficiary_id)
    sessionStorage.setItem('patientidchat_fm',patient_data.id);
    sessionStorage.setItem('agentcallpname',patient_data.member_name);
    sessionStorage.setItem('isfmlymembr','familymember');
    sessionStorage.setItem('fm_age',patient_data.age)
    sessionStorage.setItem('fm_email',patient_data.email)
    sessionStorage.setItem('fm_gender_id',patient_data.gender_id)
    sessionStorage.setItem('fm_mobile',patient_data.mobile)
    //console.log(patient_data);
    // let payload = {
    //   "patient_id": patient_data.id,
    //   "doctor_id": this.doctorDetails.id,
    //   "patient_name": patient_data.first_name + patient_data.last_name,
    //   "patient_email": patient_data.email
    // }
    // let benId = this.regService.familyDetails.id;
    // let benName = this.regService.familyDetails.first_name+''+this.regService.familyDetails.last_name;
    // let benAge = this.regService.familyDetails.age;
    // let benEmail = this.regService.familyDetails.email
    // let payload2 = {
    //   "patient_id": patient_data.id,
    //   "doctor_id": this.doctorDetails.id,
    //   "patient_name": benName,
    //   "patient_email": benEmail,
    //   "chart_history": {
    //     "patientName":benName,
    //     "age": benAge,
    //     "symptoms": "",
    //     "temperature": "",
    //     "shivering": "",
    //     "anyOtherProblem": "",
    //     "anyHealthIssues": "",
    //     "allergies": "no",
    //     "patientInformation": {
    //       "id": patient_data.id,
    //       "name": patient_data.member_name,
    //       "genderId": patient_data.gender_id,
    //       "gender": "",
    //       "age":patient_data.age,
    //       "mobile": patient_data.mobile,
    //       "familyMemberId":patient_data.id,
    //       "isSelf": false,
    //       "Symptoms": "",
    //       "symptonsEng": ""
    //     }
    //   }
    // }
    let payload = {
      "patient_id": patient_data.id,
      "doctor_id": this.doctorDetails.id,
      "patient_name": patient_data.member_name,
      "patient_email": ''
    }
    this.regService.generateConsulation('POST', payload).subscribe(res => {
      if (res.status == 'OK') {
        let fromId = (this.doctorDetails.id).toString();
        let toId = (patient_data.id).toString();
        $("#fromId").val("doc_" + fromId);
        $("#toId").val("pat_" + toId);
        $("#order_no").val(res.data.order_no);
        $("#is_self").val(false);
        $("#patient_name").val(patient_data.first_name + patient_data.last_name);
        $("#patient_interaction_id").val(res.data.consultation_id);
        $("#patient_info").val(JSON.stringify({ "patient_interaction_id": res.data.consultation_id, "order_no": res.data.order_no, "hospital_id": "", "symptoms": "", "is_self": false }));
        sessionStorage.isPatient = true;
        // this.activeModal.dismiss('Cross click')
        this.router.navigate(['home/poc'])
          .then((e) => {
            let ws = JSON.parse(sessionStorage.getItem('webSkt')); // JqueryReady(); // answerCall(fromId, toId);
            Promise.all([JqueryReady()]).then(res => { answerCall(fromId, toId, ws.expn, ws.qlfnName, ws.grCode, ws.irCode, ws.erCode); });
            if (e)
              console.log("Navigation is successful!");
            else
              console.log("Navigation has failed!");
          });

      }
      else {
        this.toastr.warning(res.message, 'Generate Consulation');
      }
    });


  }

  deleteItem(id: any) {
    this.regService.deleteById('pharmacy/deleterecordbypharmacyid?pharmacyId=', id).subscribe(res => {
      if (res.status == "OK")
        this.getPatientsData, this.toastr.success('Record Deleted Successfully');
    }, error => { this.toastr.error('An Error Occured'); });
  }
  initFormControls() {
    this.patvitals = this.formBuilder.group({
      temperature: ['', [Validators.min(70), Validators.max(120)]],
      pulse: ['', [Validators.min(30), Validators.max(200)]],
      systolic: ['', [Validators.min(50), Validators.max(400)]],
      diastolic: ['', [Validators.min(30), Validators.max(200)]],
      hemoglobin: [''],
      rbsk: [''],
      oxigen_count: ['', [Validators.min(40), Validators.max(205), Validators.maxLength(3)]],
      diabetic_value: ['', [Validators.min(35), Validators.max(380), Validators.maxLength(3)]],
      height: ['', [Validators.min(30), Validators.max(220.99), Validators.maxLength(6)]],
      weight: ['', [Validators.min(1), Validators.max(200.99), Validators.maxLength(6)]],
    })
  }
  get f() { return this.patvitals.controls; }
  onTempChng(val: string) {
    this.keyUp('temperature'), this.tempErrMsg = false, sessionStorage.setItem('tempVldtn', '0');
    if (val != '') {
      if (val.includes('.')) {
        let [dotCnt, dcmlCnt] = [val.split('.'), val.split('.')];
        if (dotCnt.length > 0 || dcmlCnt.length > 0) {
          if ((dotCnt.length > 2 || dcmlCnt[1].length > 1) || (dotCnt.length > 0 && dcmlCnt[1].length == 0)) {
            this.tempErrMsg = true, sessionStorage.setItem('tempVldtn', '1');
            return;
          }
        }
      }
    }
  }
  keyUp(elementId: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementId)));
    el.value = el.value.replace(/^(0*)/, "");
  }
  onSysDia() {
    this.systolic = this.patvitals.value.systolic;
    this.diastolic = this.patvitals.value.diastolic;

    if (this.systolic != '' && this.diastolic == '') {
      this.patvitals.get('diastolic').setValidators([Validators.required, Validators.min(30), Validators.max(200)]);
      this.patvitals.get('diastolic').updateValueAndValidity();
    }
    else if (this.systolic == '' && this.diastolic != '') {
      this.patvitals.get('systolic').setValidators([Validators.required, Validators.min(50), Validators.max(400)]);
      this.patvitals.get('systolic').updateValueAndValidity();
    }
    else {
      if (+this.diastolic > 0 && +this.systolic > 0) {
        if (+this.diastolic >= +this.systolic) {
          this.patvitals.get('diastolic').setErrors({ 'dia': true });
          this.toastr.error('Systolic should be greater than Diastolic');
        }
      }
      else if ((this.diastolic == '' && this.systolic == '') || (+this.systolic >= +this.diastolic)) {
        this.patvitals.get('diastolic').setErrors(null);
        // this.pocForm.get('vital_history.systolic').clearValidators();
        // this.pocForm.get('vital_history.diastolic').clearValidators();
        this.patvitals.get('systolic').setValidators([Validators.min(50), Validators.max(400)]);
        this.patvitals.get('diastolic').setValidators([Validators.min(30), Validators.max(200)]);
        this.patvitals.get('systolic').updateValueAndValidity();
        this.patvitals.get('diastolic').updateValueAndValidity();
      }
    }
  }
  submitvitals() {
    event.preventDefault(), this.submitted = true, Common.getFormValidationErrors(this.patvitals);
    var daten = this.datePipe.transform(new Date(), "yyyy-MM-dd HH:mm:ss");
    var payload = {
      id: 0,
      beneficiary_id: this.patientid,
      bp_value: this.patvitals.value.systolic + '/' + this.patvitals.value.diastolic,
      diabetic_value: this.patvitals.value.diabetic_value,
      oxigen_count: this.patvitals.value.oxigen_count,
      temperature: this.patvitals.value.temperature,
      capture_date: daten,
      is_active: true,
      pulse_rate: this.patvitals.value.pulse,
      height: this.patvitals.value.height,
      weight: this.patvitals.value.weight,
      beneficiary_member_id: null
    }
    this.regService.postvitals(payload).subscribe(res => {
      if (res.status == 'OK') {
        this.toastr.success(res.message);
        $("#vitalscapture").modal("hide");
      }
    })
  }
  getpid(pid: any) {
    this.patientid = pid.id;
  }
  openVitalFileModal(id) {
    $("#vitalscapture").modal("hide");
    this.patientid = id;
    const modalRef = this.modalService.open(VitalsComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.is_self = true;
    modalRef.componentInstance.bdId = this.patientid;
    modalRef.result.then(result => {
      // here call Table load API
      // alert('Success1');
    }, (reason) => { });
  }
  opendoclist(item: any) {
    sessionStorage.setItem('patlistA', 'patlistagent');
    //  register('', 'pat_'+item.id,item.first_name, '', '', '', '');
    //  //statusRegister('', '', '', '', '', '');
    //  //updateStatus(item.id,"7",item.status,"call",'',item.numberOfYrsExperience,item.qualification,item.groupReferenceCode,item.individualReferenceCode,item.exclusiveReferenceCode)
    //  statusRegister(item.id, 'patient', "AVAILABLE", "register", '', '', '', '', '', '')
    sessionStorage.setItem('callingpatient_id', item.id)
    sessionStorage.setItem('patientidchat', item.id);
    sessionStorage.setItem('agentcallpname', item.first_name + ' ' + item.last_name);
    this.openvideotag = true;
    const modalRef = this.modalService.open(ActiveDoctorsListComponent, { scrollable: true, size: 'xl' });
    // modalRef.componentInstance.title = this.modalTitle;
  }
  isOffLinePOC() {
    return JSON.parse(sessionStorage.isPatient || 'false')
  }

  showHideVideo2() {
    // const fromPatient = JSON.parse(sessionStorage.isPatient || 'false');
    $("#videofrshow").modal("show");
    //if(this.openvideotag==true){
    // if (this.isOffLinePOC() == true) {
    this.showframe = true;
    this.showpatientlistdiv = false;
    let dragElement: any = (<HTMLDivElement>document.getElementById('drogVideoN'));
    dragElement.style.display = (dragElement.style.display == 'none') ? 'block' : 'block';
    sessionStorage.isPatient = false;
    //}
    //}
  }
  frameshow() {
    let dragElement: any = (<HTMLDivElement>document.getElementById('drogVideoN'));
    dragElement.style.display = (dragElement.style.display == 'none') ? 'none' : 'none';
  }
  navigatepocclick() {
    $("#videofrshow").modal("hide");
    setTimeout(() => {
      let dragElement: any = (<HTMLDivElement>document.getElementById('drogVideoN'));
      dragElement.style.display = 'none';
      //stopVideo2();
      //this.router.navigate(['home/agentlistcall'])
      //agenttodoctorcalldisconnct('doc_'+sessionStorage.getItem('agentdoctorid'),'pat_'+sessionStorage.getItem('callingpatient_id_fm'),sessionStorage.getItem('agentcallpname'),'Consulting')
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/home/patientList']);
      });
    }, 2000);
    // document.getElementById('prescriptionModalClose').click(), this.submitted = false, stopVideo(),
    //    document.getElementById('prescriptionModalClose').click(), this.changeMenu(), this.submitted = false, stopVideo(),
    //    this.initFormControls(),
    //    this.clearData(), 
    //    setTimeout(() => {
    //     this.router.navigate(['home/consultation'])
    // },2000);
  }
  openFamilyModal() {
    const modalRef = this.modalService.open(FamilyMemberRegistrationComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = this.modalTitle;
    this.getAllMasters();
  }
  familyList(ben) {
    this.regService.familyDetails = ben;
    sessionStorage.setItem('ben_id', ben.id);
    // this.regService.headName = ben.first_name + '' + ben.last_name
    this.openFamilyModal();
  }
  getAllMasters() {
    const gender = this.masterService.getAll('getallgenders');
    const relation = this.masterService.getAll('relation/getall');
    forkJoin([gender, relation]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              console.log('No records available');
            else {
              const assignList = {
                '0': () => this.regService.genderList = list.data,
                '1': () => this.regService.relationsList = list.data
              }
              assignList[ind]();
            }
          }
        });
      },
      error: err => console.error('something wrong occurred: ', err),
    });
  }
  openDSSForm() {
    const modalRef = this.modalService.open(DssFlowComponent, { scrollable: true, size: 'xl' });
  }
  onHealthID(item: any) {
    if(item.relation==='self'){
    this.benID=item.head_id;
    }else{
      this.benID=item.member_id;
    }
    // this.benID = item.id;
    sessionStorage.setItem('linkWithAbha', 1 + "");
    this.getBenDetails(this.benID);
  }
  getBenDetails(id) {
      this.regService.getByID('beneficiary/getbeneficiaryrecordbyid?beneficiaryId=', this.benID).subscribe(res => {
        if (res.status == "OK") {
          this.benDetails = res.data
          const modalRef = this.modalService.open(PatientRegistrationComponent, { scrollable: true, size: 'xl' });
          modalRef.componentInstance.benDetails = this.benDetails;
          modalRef.componentInstance.title = this.modalTitle;
          modalRef.componentInstance.screentitle = 'Edit';
        }
      }, err => { this.toastr.error(err) });
  }

  // new method added for Add consent 27-07-2023
  addConsent(item:any){
    if(item.relation==='self'){
      sessionStorage.setItem('benIdConsent',item.head_id);
      sessionStorage.setItem('benHealthId',item.health_id)
      sessionStorage.setItem('benName',item.fullname)
    }else{
      sessionStorage.setItem('benIdConsent',item.member_id);
      sessionStorage.setItem('benHealthId',item.health_id)
      sessionStorage.setItem('benName',item.fullname)
    }
   
    this.router.navigate([`home/consentlist`])
  }
  getpatientid(item: any) {
  
    if(item.relation==='self'){
      sessionStorage.setItem('callingpatient_id', item.head_id);
      sessionStorage.setItem('is_self_ehr',1+"");
    } else{
      sessionStorage.setItem('callingpatient_id', item.member_id);
      sessionStorage.setItem('is_self_ehr',0+"");
    }
    
    //sushma 08-07-2022 hide back button
    let val=true;
    localStorage.setItem('ehrback',JSON.stringify(val));
    this.router.navigate(['home/ehr']);
  }
  onviewhealthcard(id) {
    this.regService.getByID('beneficiary/getbeneficiaryrecordbyid?beneficiaryId=', id).subscribe(res => {
      if (res.status == "OK") {
        this.benDetails = res.data.beneficiary_documents;
      }
    }, err => { this.toastr.error(err) });
    setTimeout(() => {
      if (this.benDetails.length != 0) {
        this.benDetails.map(e => {
          if (e.file_type_id == 26) {
            this.healthcardArray.push(e);
          }
        })
      }
      if (this.healthcardArray.length == 0) {
        this.toastr.info('No Files Found');
        return;
      }
      var healthcardArraynew = this.healthcardArray.pop();
      this.imagePath = `${this.regService.viewFilePath}BENEFICIARY/${id}/NDHMHealthCard/${healthcardArraynew.document_path}`;
      healthcardArraynew = null;
      //window.open(this.imagePath, '_blank', 'left=300,top=50,width=700,height=600,toolbar=1,resizable=0');
      window.open(this.imagePath, 'winname', 'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=400,height=350,top=100,left=500');
    }, 500);

  }
  downloadfile(id) {
    this.regService.getByID('beneficiary/getbeneficiaryrecordbyid?beneficiaryId=', id).subscribe(res => {
      if (res.status == "OK") {
        this.benDetails = res.data.beneficiary_documents;
      }
    }, err => { this.toastr.error(err) });
    setTimeout(() => {
      if (this.benDetails.length != 0) {
        this.benDetails.map(e => {
          if (e.file_type_id == 26) {
            this.healthcardArray.push(e);
          }
        })
      }
      if (this.healthcardArray.length == 0) {
        this.toastr.info('No Files Found');
        return;
      }
      var healthcardArraynew = this.healthcardArray.pop();
      var downloadpath = `${sessionStorage.getItem('tenantUrl')}/file-service/file/download/BENEFICIARY/${id}/26/${healthcardArraynew.document_path}`;
      healthcardArraynew = null;
      window.open(downloadpath, '_blank');
    }, 500);
  }

  // exportexcel(): void {

  //   var table = $('#patientTable').DataTable();

  //     / table id is passed over here /
  //     let element = document.getElementById('patientTable');
  //     const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

  //     / generate workbook and add the worksheet /
  //     const wb: XLSX.WorkBook = XLSX.utils.book_new();
  //     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //     ws['!cols'][6] = { hidden: true };
  //     ws['!cols'][7] = { hidden: true };

  //     / save to file /
  //     XLSX.writeFile(wb, this.fileName);
  //   // }
  //  }

  downLoadExcel() {
    const [isAgent, agentId] = [(sessionStorage.logUsrRoleId == '14'), (sessionStorage.agentId || 0)];
    this.search_string = this.patientSearchForm.value.search_string.toString().trim();
    let payload = { "page": 0, "search_string": "", "size": this.totalElements }
    // below lines commented for new patient list changes 16-02-2023

   
    
    if(sessionStorage.getItem('TENANT_ID')==50+''){
      this.regService.getPatientsBySearchNew('POST', payload, isAgent, agentId, this.doctorcenterid,this.doctorDetails.id).subscribe(res => {
        if (res.status == 'ACCEPTED') {
          if (res.data['content'].length > 0) {
            this.totalElements = res['data']['totalElements'];
            this.excelData = res.data['content'];
            const fileName = 'Patient_List'
            var excelData = this.excelData;
            if (excelData.length > 0) {
              excelData.map(res => {
                // res.fullName = (res.first_name + ' ' + res.middle_name + ' ' + res.last_name).toUpperCase();
                res.fullName = (res.fullname).toUpperCase();
             
                res.formatedDateofBirth = this.pipe.transform(res.birth_date, 'dd-MM-yyyy')
              })
              let excelHeaders:any;
              let internalHeaders:any;
              if(this.hideLinkWithAbha){
                    excelHeaders = ['Patient Name', 'Mobile Number', 'Email', 'Date Of Birth', 'Age','ABHA Number'];
                    internalHeaders = ['fullName', 'mobile', 'email', 'formatedDateofBirth', 'age','health_id_number'];
             
              } else{
                excelHeaders = ['Patient Name', 'Mobile Number', 'Email', 'Date Of Birth', 'Age']; 
               internalHeaders = ['fullName', 'mobile', 'email', 'formatedDateofBirth', 'age'];
              }
             
              
              this.toastr.success('Please wait excel is downloading')
              this.excelDownloadService.downloadExcel(excelHeaders, internalHeaders, fileName, excelData)
            }
            else {
              this.toastr.error('No data to download')
            }
  
          }
        }
      });
    } else{
 this.regService.getPatientsBySearch('POST', payload, isAgent, agentId, this.doctorcenterid).subscribe(res => {
      if (res.status == 'ACCEPTED') {
        if (res.data['content'].length > 0) {
          this.totalElements = res['data']['totalElements'];
          this.excelData = res.data['content'];
          const fileName = 'Patient_List'
          var excelData = this.excelData;
          if (excelData.length > 0) {
            excelData.map(res => {
              res.fullName = (res.first_name + ' ' + res.middle_name + ' ' + res.last_name).toUpperCase();
              res.formatedDateofBirth = this.pipe.transform(res.birth_date, 'dd-MM-yyyy')
            })
            const excelHeaders = ['Patient Name', 'Mobile Number', 'Email', 'Date Of Birth', 'Age','ABHA Number'];
            const internalHeaders = ['fullName', 'mobile', 'email', 'formatedDateofBirth', 'age','health_id_number'];
            this.toastr.success('Please wait excel is downloading')
            this.excelDownloadService.downloadExcel(excelHeaders, internalHeaders, fileName, excelData)
          }
          else {
            this.toastr.error('No data to download')
          }

        }
      }
    });
    }
  

  }


}