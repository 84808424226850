import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ToastrService } from 'ngx-toastr';
import { Common } from 'src/app/common';
import { DatePipe } from '@angular/common';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';

@Component({
  selector: 'app-missedcall',
  templateUrl: './missedcall.component.html',
  styleUrls: ['./missedcall.component.css'],
  providers: [DatePipe]
})

export class MissedcallComponent implements OnInit {

  frmDt = ''; toDt = ''; dt = ''; maxDt = (new Date()); minDt = (new Date());
  msdCalVal = 0; lstGrid = []; isLcl = location.href.includes('localhost'); drData: any;

  constructor(public activeModal: NgbActiveModal
    , private regSvc: RegistrationService
    , private tstr: ToastrService
    , private authSvc: AuthenticationService
    , private dtPipe: DatePipe) { }

  ngOnInit(): void {
    this.dt = this.dtPipe.transform((new Date()), 'dd-MM-yyyy'), [this.frmDt, this.toDt, this.drData] = [this.dt, this.dt, JSON.parse(sessionStorage.getItem('DoctorDetails'))];
    this.authSvc.msdCalSubject.subscribe(s => { this.lstGrid = s, this.msdCalVal = s.length, Common.loadDataTable(this.lstGrid, '#tblMsdCal'); })
  }

  LoadGrid() {
    let [dtFrm, dtTo] = [this.frmDt.toString().includes('Standard') ? this.dtPipe.transform(this.frmDt, 'yyyy-MM-dd') : this.frmDt,
      this.toDt.toString().includes('Standard') ? this.dtPipe.transform(this.toDt, 'yyyy-MM-dd') : this.toDt];
    this.regSvc.getMisCals(this.drData?.id, dtFrm, dtTo).toPromise().then((mcData: any) => {
      if (mcData.status == 'OK')
        this.lstGrid = mcData.data, this.msdCalVal = mcData.data.length, Common.loadDataTable(this.lstGrid, '#tblMsdCal');
    });
  }

  onFromDateChange(val: Date) {
    if (val) {
      this.minDt = val;
      if (this.dt != this.dtPipe.transform(val, 'dd-MM-yyyy'))
        this.toDt = '';
    }
  }

}