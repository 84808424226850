<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Add Drugs to Pharmacist/Healthcare Professional Worker
                        <button data-toggle="modal" (click)="modalTitle='Add Drugs to Pharmacist/Healthcare Professional'; openDrugAgentModal()"
                            *ngIf="setPrivilege(Screen_Name.AddDrugsToAgent, Permission.Add)"
                            class="btn btn-blue btn-icon float-right"> <i class="fa fa-plus"></i>
                        </button>
                    </div>
                    <div class="" style="padding-left: 10px !important;">
                        <div class="custom-control-inline custom-control custom-radio">
                          <input checked class="custom-control-input" id="customRadio11" type="radio" name="is_active"
                            (change)="isShow=true; ">
                          <label class="custom-control-label" for="customRadio11"><b>Pharmacist/Healthcare Professional Shipment Stock </b></label>
                        </div>
                        <div class="custom-control-inline custom-control custom-radio">
                          <input class="custom-control-input " id="customRadio22" type="radio" name="is_active"
                            (change)="isShow=false;">
                          <label class="custom-control-label" for="customRadio22"><b>Pharmacist/Healthcare Professional Stock Availability</b></label>
                        </div>
                      </div> <br />
                    <div class="card-body" [hidden]="!isShow">
                        <label class="control-label">State <span class="text-danger">*</span></label>
                        <div class="row">
                            <div class="col-xl-2">
                                <select class="form-control form-control-sm" [(ngModel)]="slctCmpny"
                                    (change)=" getAllstates($event.target.value)" id="slctCmpny" disabled="true">
                                    <option value="" selected hidden>Select</option>
                                    <option *ngFor="let state of stateList" [value]="state.id">{{state.state_name}}
                                    </option>
                                </select>
                            </div>
                            <label class="control-label">Pharmacist/Healthcare Professional<span class="text-danger">*</span></label>
                            <div class="col-xl-2">
                                <select class="form-control form-control-sm" [(ngModel)]="agents" id="agents">
                                    <option value="" selected hidden>Select</option>
                                    <option *ngFor="let agent of lstAgents;" [value]="agent.id">{{agent.first_name}}
                                        {{agent.last_name}}</option>
                                </select>
                            </div>
                            <label class="control-label">From Date <span class="text-danger">*</span></label>
                            <div class="col-xl-2">
                                <input type="text" autocomplete="off" id="from_date" [(ngModel)]="from_date"
                                    bsDatepicker #dp="bsDatepicker"  class="form-control form-control-sm"
                                    placeholder="Select From Date"
                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                    (focus)="dp.show()"  [maxDate]="max_date">
                            </div>
                            <label class="control-label">To Date <span class="text-danger">*</span></label>
                            <div class="col-xl-2">
                                <input type="text" autocomplete="off" id="to_date" [(ngModel)]="to_date"
                                    bsDatepicker #dp1="bsDatepicker" class="form-control form-control-sm"
                                    placeholder="Select To Date"
                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                    (focus)="dp1.show()"  [maxDate]="max_date">
                            </div>
                            <!-- <label class="control-label ml-3"> From Date </label>
                            <div class="col-xl-2">
                                <input type="text" autocomplete="off" id="start_date_App" bsDatepicker placement="top"
                                class="form-control form-control-sm" formControlName="fromDate" [maxDate]="tdDt" [(ngModel)]="start_date_App"
                                [bsConfig]="{dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue',
                                  showWeekNumbers: false, adaptivePosition: true }" (bsValueChange)="onFrmDtChng($event)">
                            </div>
                            <label class="control-label"> To Date </label>
                            <div class="col-xl-2">
                                <input type="text" autocomplete="off" id="end_date_App" bsDatepicker placement="top"
        class="form-control form-control-sm" formControlName="toDate" [minDate]="minToDt" [(ngModel)]="start_date_App"
        [maxDate]="tdDt" [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', containerClass: 'theme-dark-blue',
          showWeekNumbers: false, adaptivePosition: true }">
                            </div> -->
                            <div class="col-xl-2 " style="margin:15px 0px !important;">
                                <button type="submit" class="btn btn-sm btn-primary" value="Submit"
                                    (click)="Submitform()">Submit</button>
                                <button type="button" class="btn  btn-sm btn-secondary float-right"  *ngIf="excelData && excelData.length>0" (click)="downLoadExcel('shipment')"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                            
                            </div>
                            <!-- <div class="col-xl-1" >
                                </div>     -->
                            <br>
                            <br>
                            <div class="table-responsive">
                                <table datatable id="DrugAgentTable"
                                    class="table table-striped table-bordered table-sm">
                                    <thead>
                                        <tr>
                                            <th style="width:7%;" class="singleLine">S No </th>
                                            <th>Drug Type</th>
                                            <th>Drug Name</th>
                                            <th>Dispatched Date</th>
                                            <th>Batch No.</th>
                                            <th>Qty</th>
                                            <th>Expiry Date</th>
                                            <th>MFG</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of drugsByAgents; let i=index">
                                            <td>{{i+1}}</td>
                                            <td>{{item.drug_type}}</td>
                                            <td>{{item.drug_name}}</td>
                                            <td>{{item.created_on | date:'dd-MM-yyyy'}}</td>
                                            <td>{{item.batch_no}}</td>
                                            <td>{{item.stock}}</td>
                                            <td>{{item.expiry_date | date:'dd-MM-yyyy'}}</td>
                                            <td>{{item.manufactured_date | date:'dd-MM-yyyy'}}</td>
                                            <td><a class="badge badge-danger badge-pill pointer" title="Delete"> <i
                                                        class="fa fa-trash text-white"></i>
                                                </a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" [hidden]="isShow">
                        <div class="row">
                            <label class="control-label">State<span class="text-danger">*</span></label>
                            <div class="col-xl-2">
                                <select class="form-control form-control-sm" [(ngModel)]="slctState"
                                    (change)=" getAllstates2($event.target.value)" id="slctState">
                                    <option value="" selected>Select</option>
                                    <option *ngFor="let state of stateList" [value]="state.id">{{state.state_name}}
                                    </option>
                                </select>
                            </div>
                            <label class="control-label">Pharmacist/Healthcare Professional<span class="text-danger">*</span></label>
                            <div class="col-xl-2">
                                <select class="form-control form-control-sm" [(ngModel)]="agentslist" id="agentslist"  (change)="getDrugByAgent($event.target.value)">
                                    <option value="" selected >Select</option>
                                    <option *ngFor="let agent of lstAgents2;" [value]="agent.id">{{agent.first_name}}
                                        {{agent.last_name}}</option>
                                </select>
                            </div>
                            <div class="col-xl-4">
                                <button type="button" class="btn  btn-sm btn-secondary float-right" *ngIf="excelDataAvail && excelDataAvail.length>0" (click)="downLoadExcel('availability')"> 
                                    <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                            </div>
                            <!-- <label class="control-label">Date</label>
                            <div class="col-xl-2">
                                <input type="text" autocomplete="off" id="expiry_date" [(ngModel)]="expiry_date"
                                    bsDatepicker #dp1="bsDatepicker" class="form-control form-control-sm"
                                    placeholder="Select"
                                    [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                    (focus)="dp1.show()">
                            </div> -->
                            <!-- <div class="col-xl-2">
                                <button type="submit" class="btn btn-sm btn-primary" value="Submit"
                                    (click)="Submitform()">Submit</button>&nbsp;&nbsp;
                            </div> -->
                            <br>
                            <br>
                            <div class="table-responsive">
                                <table datatable id="DrugAgentTablee"
                                    class="table table-striped table-bordered table-sm">
                                    <thead>
                                        <tr>
                                            <th style="width:7%;" class="singleLine">S No </th>
                                            <th>Drug Type</th>
                                            <th>Drug Name</th>
                                            <th>Batch No.</th>
                                            <th>Available Stock</th>
                                            <th>Expiry Date</th>
                                            <th>MFG</th>
                                            <!-- <th>Action</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let item of drugsByAgents2; let i=index">
                                            <td>{{i+1}}</td>
                                            <td>{{item.drug_type}}</td>
                                            <td>{{item.drug_name}}</td>
                                            <td>{{item.batch_no}}</td>
                                            <td>{{item.stock}}</td>
                                            <td>{{item.expiry_date | date:'dd-MM-yyyy'}}</td>
                                            <td>{{item.manufactured_date | date:'dd-MM-yyyy'}}</td>
                                            <!-- <td><a class="badge badge-danger badge-pill pointer" title="Delete"> <i
                                                        class="fa fa-trash text-white"></i>
                                                </a></td> -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>