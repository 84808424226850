import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Common } from 'src/app/common';
import { CenterRegistrationFormComponent } from 'src/app/modules/registration/center-registration-form/center-registration-form.component';
import { BaseComponent } from 'src/app/shared/base.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { environment } from 'src/environments/environment';
declare let $:any;
@Component({
  selector: 'app-center-registration',
  templateUrl: './center-registration.component.html',
  styleUrls: ['./center-registration.component.css']
})
export class CenterRegistrationComponent  extends BaseComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject(); minDt = new Date(); minToDt = new Date(); maxDt = new Date(); maxDtDOB = new Date();
  cntrLstForm: FormGroup; cntrCtrlsForm: FormGroup; district_code = '';locationtypeid=''; block_code = ''; grmpcht_code = ''; isAdd = 'add'; isAct = '0';
  submitted = false; locationType_list; lstgrid = []; state_list = []; division_list = []; district_list = []; block_list = []; grampanchayat_list = [];
  paymentlist = []; userlist = []; role_list = []; lstData = []; panchayat_list = []; village_list = []; fileLst = [];state_code = 1;
  fileName= 'Centers Report.xlsx';
  
  modalTitle: string = ''; lstGrid = [];
  getAllcenters:[];
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  mandalList: Array<any> = [];
  cityList: Array<any> = [];
  villageList: Array<any> = [];
  isNIG = environment.isNIG;
  excelData: any =[];

  constructor(config: NgbModalConfig
    , public modalService: NgbModal
    , private regService: RegistrationService
    , private authSvc: AuthenticationService
    , protected toastr: ToastrService
    , private router:Router
    , private formBuilder:FormBuilder,
    private excelDownloadService:ExcelDownloadService
    , private masterService: MasterService) {
      super(); // customize default values of modals used by this component tree
   
  }


  ngOnInit(): void {
    this.initGridForm();
    this.locationChanged('', 'state');
    this.locationChanged('5', 'district');
       
 
    this.getAllMasters();
   this.getallcentersGrid();
  }
  get f() { return this.cntrLstForm.controls; }
  locationChanged(id: string, locationType: string) {
    if(locationType=='district'){
      this.cntrLstForm.patchValue({
        district_id:0
      })
    }
    let query: string = '';
    let locations = {
      'location': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        this.cntrLstForm.get('district_id').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      },
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }
  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
      
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
      this.districtList.sort((a,b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error));
  }
  getAllMasters() {
    this.masterService.getAll('getalllocationtypes').subscribe(res=>{
      if(res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info(res.message);
        else
          this.locationTypeList = res.data;
      }
    });
   // locationType.subscribe({})
    }
  initGridForm() {
    this.cntrLstForm = this.formBuilder.group({
      // state_code:[0, Validators.required],
      // district_code:[0, Validators.required],
      // locationtypeid:[0, Validators.required],
      // block_code: [0]
      state_id: [5, Validators.required],
       district_id: [0, Validators.required],
       mandal_id: [0],
       location_type_id:[0]
       //village_id: [''],
      
    });
 
  }
  
    // openPatientModal() {
    //   const modalRef = this.modalService.open(CenterRegistrationFormComponent, { scrollable: true, size: 'xl' });
    //   modalRef.componentInstance.title = this.modalTitle;
    // }
    openPatientModal() {
      this.regService.centerregeditobj=undefined;
      const modalRef = this.modalService.open(CenterRegistrationFormComponent, { scrollable: true, size: 'xl' });
      modalRef.componentInstance.title = this.modalTitle;
    }
    onAddEdit(item: any) {
      //item['payment_type']=item['paymentType']
      // for(let i=0;i<item.centermapping.length;i++){
      //   item.centermapping[i]['payment_type']=item.centermapping[i]['paymentType']
      // }
      // this.regService.centerregeditobj=item;
      // var distid;
//       if(this.regService.centerregeditobj.is_state==true){
// distid=0;
//       }
//       else{
//         distid=this.regService.centerregeditobj.district_id
//       }
//distid=0;
      // this.regService.gethospitalref(this.regService.centerregeditobj.state_id,distid).subscribe(res => {
      //   if(res.status === 'OK') {
      //     if (res.data == null || res.data.length == 0){
      //       //this.toastr.info('Referral Hospitals Not Available');
      //       //this.hospitalrefdata=[];
      //     }
      //     else
      //     for (let i = 0; i < res.data.length; i++) {
      //       res.data[i]['hospital_id'] = res.data[i]['id']
      //      }
      //       var hospitalrefdata = res.data;
      //       //setTimeout(() => {
      //       if(this.regService.centerregeditobj!=undefined){
      //         if(this.regService.centerregeditobj.center_hospital_mapping !=null){
      //         for (let i = 0; i < this.regService.centerregeditobj.center_hospital_mapping.length; i++) {
      //           if(hospitalrefdata.length !=0){
      //       let name=hospitalrefdata.find(a=>a.hospital_id==this.regService.centerregeditobj.center_hospital_mapping[i].hospital_id).hospital_name;
      //       this.regService.centerregeditobj.center_hospital_mapping[i]['hospital_name']=name;
      //           }
      //         }
      //       }
      //         const modalRef = this.modalService.open(CenterRegistrationFormComponent, { scrollable: true, size: 'xl' });
      //         modalRef.componentInstance.title = this.modalTitle;
      //       }
         
      //   }
      // })
      this.regService.getbyCenterId(item.center_id).subscribe(res => {
        if (res.status == 'OK') {
          this.regService.centerregeditobj=res.data;
          const modalRef = this.modalService.open(CenterRegistrationFormComponent, { scrollable: true, size: 'xl' });
          modalRef.componentInstance.title = this.modalTitle;
        } else {
          this.toastr.error(res.err);
        }
      })
     
   }
 
    onSearch() {
      // if (this.cntrLstForm.value.state_id==0 || this.cntrLstForm.value.district_id==0 ) {
      //   this.toastr.error('Please Select All Mandatory Fields');
      //   return;
      // }
      $('.dataTable').DataTable().destroy(), $('.dataTable').DataTable({ searching: false });
      this.getallcentersGrid();
      
    }

    onReset() {
      this.cntrLstForm.patchValue({state_id:5,district_id:0, mandal_id:0,location_type_id:0  }), 
      //this.district_code = "", this.submitted = true;
      //$('.dataTable').DataTable().clear(),$('.dataTable').DataTable().destroy() ;
      this.getallcentersGrid();
    }
  getallcentersGrid(){
    // this.regService.getAllCenters(this.cntrLstForm.value).subscribe(res => { #this line is commented due to performance 07-07-2022
      this.regService.getAllCentersNew(this.cntrLstForm.value).subscribe(res => {
     // this.getAllcenters=data.data;
     if(res.status === 'OK') {
      if (res.data == null || res.data.length == 0){
      this.getAllcenters=[]
        this.toastr.info(res.message);
      }
      else
        this.getAllcenters = res.data;
        this.excelData= this.getAllcenters

      Common.loadDataTable(this.getAllcenters, '#centerregTable');
    }
    else
      this.getAllcenters = [], Common.loadDataTable(this.getAllcenters, "#centerregTable");
    })
  }
  deleteItem(id:any){
    this.regService.deleteCenter(id).subscribe(res => {
      // this.getAllcenters=data.data;
      if(res.status === 'OK') {
         this.toastr.info(res.message);
         this.getallcentersGrid();
     }
     })
  }
  downLoadExcel(){
  const fileName = 'Center_reg'
    var excelData = this.excelData;
    if(excelData.length>0){
      const excelHeaders = ['State','District','Center Name' ,'Incharge Name','Incharge Mobile'];
      const internalHeaders = [ 'stateName','districtName','center_name','incharge_name','incharge_mobile_no'];
      this.toastr.success('Please wait excel is downloading')
      this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
    }
    else{
      this.toastr.error('No data to download')
    }
  
}
}
