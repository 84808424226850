import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { CenterRegistrationFormComponent } from 'src/app/modules/registration/center-registration-form/center-registration-form.component';
import { environment } from 'src/environments/environment';

import { Common } from 'src/app/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { ReferralComponent } from '../referral/referral.component';
declare let $:any;
@Component({
  selector: 'app-referrals-list',
  templateUrl: './referrals-list.component.html',
  styleUrls: ['./referrals-list.component.css']
})
export class ReferralsListComponent implements OnInit {
  dtTrigger: Subject<any> = new Subject(); minDt = new Date(); minToDt = new Date(); maxDt = new Date(); maxDtDOB = new Date();
  cntrLstForm: FormGroup; cntrCtrlsForm: FormGroup; district_code = '';locationtypeid=''; block_code = ''; grmpcht_code = ''; isAdd = 'add'; isAct = '0';
  submitted = false; locationType_list; lstgrid = []; state_list = []; division_list = []; district_list = []; block_list = []; grampanchayat_list = [];
  paymentlist = []; userlist = []; role_list = []; lstData = []; panchayat_list = []; village_list = []; fileLst = [];state_code = 1;fileName= 'Centers Report.xlsx';
  
  modalTitle: string = ''; lstGrid = [];
  getAllcenters:[];
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  mandalList: Array<any> = [];
  cityList: Array<any> = [];
  villageList: Array<any> = [];
  isNIG = environment.isNIG;

  constructor(config: NgbModalConfig
    , public modalService: NgbModal
    , private regService: RegistrationService
    , private authSvc: AuthenticationService
    , protected toastr: ToastrService
    , private router:Router
    , private formBuilder:FormBuilder
    , private masterService: MasterService) {
   
  }


  ngOnInit(): void {
    this.initGridForm();
    this.locationChanged('', 'state');
    this.getAllMasters();
   this.getallcentersGrid();
  }
  get f() { return this.cntrCtrlsForm.controls; }
  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        this.cntrCtrlsForm.get('districtid').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      },
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }
  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
      this.districtList.sort((a,b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error.message));
  }
  getAllMasters() {
    this.masterService.getAll('getalllocationtypes').subscribe(res=>{
      if(res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info(res.message);
        else
          this.locationTypeList = res.data;
      }
    });
   // locationType.subscribe({})
    }
  initGridForm() {
    this.cntrLstForm = this.formBuilder.group({
      // state_code:[0, Validators.required],
      // district_code:[0, Validators.required],
      // locationtypeid:[0, Validators.required],
      // block_code: [0]
      state_id: [0, Validators.required],
       district_id: [0, Validators.required],
       mandal_id: [0],
       location_type_id:[0, Validators.required]
       //village_id: [''],
      
    });
 
  }
  exportexcel(): void 
  {
     
     var table = $('#tblCntr').DataTable();
 
    if ( ! table.data().count() ) {
    this.toastr.error( 'No data available in table! Please select and try again' );
   }
   else{
     / table id is passed over here /  
     let element = document.getElementById('tblCntr'); 
     const ws: XLSX.WorkSheet =XLSX.utils.table_to_sheet(element);
     

     / generate workbook and add the worksheet /
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
     ws['!cols'] = [];
     ws['!cols'][10] = { hidden: true };
     

     / save to file /
     XLSX.writeFile(wb, this.fileName);
     }
    }
    // openPatientModal() {
    //   const modalRef = this.modalService.open(CenterRegistrationFormComponent, { scrollable: true, size: 'xl' });
    //   modalRef.componentInstance.title = this.modalTitle;
    // }
    openPatientModal() {
      const modalRef = this.modalService.open(ReferralComponent, { scrollable: true, size: 'xl' });
      modalRef.componentInstance.title = this.modalTitle;
    }
    onAddEdit(item: any) {
      this.regService.centerregeditobj=item;
      const modalRef = this.modalService.open(CenterRegistrationFormComponent, { scrollable: true, size: 'xl' });
      modalRef.componentInstance.title = this.modalTitle;
   }
 
    onSearch() {
      $('.dataTable').DataTable().destroy(), $('.dataTable').DataTable({ searching: false });
      this.getallcentersGrid();
      // this.srvcSvc.Get(`agent/center?locationtypeid=${this.locationtypeid}&districtcode=${this.district_code==''?'-1':this.district_code}&cityorblockcode=${this.block_code==''?'-1':this.block_code}&areaorpanchayatisamiticode=${this.grmpcht_code==''?'-1':this.grmpcht_code}`).
      //   then((res: any) => {
      //     this.lstgrid = res.response, setTimeout(() => $('#tblCntr').DataTable());
      //     console.log(res.response);
      //   });
    }

    onReset() {
      this.cntrLstForm.patchValue({district_code:'',locationtypeid:'', block_code:'',grmpcht_code:''  }), this.district_code = "", this.submitted = true;
      $('.dataTable').DataTable().clear(),$('.dataTable').DataTable().destroy() ;
    }
  getallcentersGrid(){
    this.regService.getAllhubs(this.cntrLstForm.value).subscribe(res => {
     // this.getAllcenters=data.data;
     if(res.status === 'OK') {
      if (res.data == null || res.data.length == 0){
      this.getAllcenters=[]
        this.toastr.info(res.message);
      }
      else
        this.getAllcenters = res.data;
      Common.loadDataTable(this.getAllcenters, '#centerregTable');
    }
    else
      this.getAllcenters = [], Common.loadDataTable(this.getAllcenters, "#centerregTable");
    })
  }
  
}
