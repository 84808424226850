<div class="modal-header">
  <h4 class="modal-title">Centre Registration Details</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click');">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="container-fluid">
    <form [formGroup]="cntrCtrlsForm" (ngSubmit)="centerregistrationSubmit($event)">
      <div class="row">
        <div class="col-xl-12">
          <div class="row mt-3">
            <!-- <div class="col-xl-3">
                          <label>Center Kendra Code</label>
                          <input type="text" class="form-control form-control-sm" placeholder="Arogya Kendra code" formControlName="center_code"  readonly />
                      </div> -->
            <div class="col-xl-3">
              <label>Centre Name <span class="text-danger"><b>*</b></span> </label>
              <input type="text" [ngClass]="{ 'is-invalid': submitted && f.center_name.errors }"
                class="form-control  form-control-sm text-uppercase"
                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                placeholder="Center Name" formControlName="center_name" maxlength="50" />
              <div *ngIf="submitted && f.center_name.errors" class="invalid-feedback">
                <div *ngIf="f.center_name.errors.required">Arogya Kendra Name is required</div>
              </div>
            </div>

            <!-- <div class="col-xl-3">
                          <label>Location Type <span class="text-danger"><b>*</b></span> </label>
                          
                          <select class="form-control  form-control-sm" id="inputEmail4" placeholder="location type" formControlName="locationtypeid" >
                              <option value=0>Select</option>
                             
                          </select>
                      </div> -->
            <!-- <div class="col-xl-3 ">
                          <label>State <span class="text-danger"><b>*</b></span> </label>
                          <select class="form-control form-control-sm" id="inputEmail14" formControlName="stateid" >
                              <option value="" selected hidden>Select</option>
                              <option *ngFor="let state of stateList" [value]="state.id">{{state.state_name}}</option>
                          </select>
                      </div> -->
            
            <div class="col-xl-3">
              <div class="form-group">
                <label class="control-label">Location Type <span class="text-danger">*</span> </label>
                <div>
                  <select formControlName="location_type_id" id="location_type_id" #locationId
                    (change)="locationChanged('','location')"
                    [ngClass]="{ 'is-invalid': submitted && f.location_type_id.errors }"
                    class="form-control form-control-sm">
                    <option value="">Select</option>
                    <option *ngFor="let location of locationTypeList" [value]="location.id">
                      {{location.location_type}}</option>
                  </select>
                  <div *ngIf="submitted && f.location_type_id.errors" class="invalid-feedback">
                    <div *ngIf="f.location_type_id.errors.required">Location Type is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <label class="control-label">{{isNIG ? 'States' : 'State'}} <span class="text-danger">*</span> </label>
                <div>
                  <select formControlName="state_id" id="state_id" #State
                    (change)="locationChanged(State.value,'district')"
                    [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }" class="form-control form-control-sm" disabled>
                    <option value="">Select</option>
                    <option *ngFor="let state of stateList" [value]="state.id">
                      {{state.state_name}}</option>
                  </select>
                  <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                    <div *ngIf="f.state_id.errors.required">
                      {{isNIG ? 'States' : 'State'}} is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <label class="control-label">{{isNIG ? 'State Capitals' : 'District'}} <span
                    class="text-danger">*</span>
                </label>
                <div>
                  <select formControlName="district_id" id="district_id" #District
                    (change)="locationChanged(District.value,(+locationId.value==2)?'city':'mandal')"
                    [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }"
                    class="form-control form-control-sm">
                    <option value="">Select</option>
                    <option *ngFor="let district of districtList" [value]="district.id">
                      {{district.district_name}}</option>
                  </select>
                  <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                    <div *ngIf="f.district_id.errors.required">
                      {{isNIG ? 'State Capitals' : 'District'}} is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <label class="control-label">
                  {{isSAGE ? 'Location' : (isNIG ? 'Local Government Areas' : 'Block')}}
                  <span *ngIf="+locationId?.value==1" class="text-danger">*</span> </label>
                <div>
                  <select formControlName="mandal_id" id="mandal_id" #Mandal
                    [attr.disabled]="(+locationId.value==2 || isEditable)?true:null"
                    (change)="locationChanged(Mandal.value,'village')"
                    [ngClass]="{ 'is-invalid': submitted && f.mandal_id.errors }" class="form-control form-control-sm">
                    <option value="">Select</option>
                    <option *ngFor="let mandal of mandalList" [value]="mandal.id">
                      {{mandal.mandal_name}}</option>
                  </select>
                  <div *ngIf="submitted && f.mandal_id.errors" class="invalid-feedback">
                    <div *ngIf="f.mandal_id.errors.required">
                      {{isSAGE ? 'Location' : (isNIG ? 'Local Government Areas' : 'Block')}} is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3" *ngIf="+locationId.value==2">
              <div class="form-group">
                <label class="control-label">City </label>
                <div>
                  <select formControlName="city_id" id="city_id"
                    [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }" class="form-control form-control-sm"
                    [attr.disabled]="isEditable ? true : null">
                    <option value="">Select</option>
                    <option *ngFor="let city of cityList" [value]="city.id">
                      {{city.city_name}}</option>
                  </select>
                  <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
                    <div *ngIf="f.city_id.errors.required">City is required</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-3" *ngIf="+locationId.value != 2 && !isNIG">
              <div class="form-group">
                <label class="control-label">Village <span *ngIf="+locationId.value==1" class="text-danger">*</span>
                </label>
                <div>
                  <select formControlName="village_id" id="village_id"
                    [attr.disabled]="(+locationId.value==2 || isEditable)?true:null"
                    [ngClass]="{ 'is-invalid': submitted && f.village_id.errors }" class="form-control form-control-sm">
                    <option value="">Select</option>
                    <option *ngFor="let village of villageList" [value]="village.id">
                      {{village.village_name}}</option>
                  </select>
                  <div *ngIf="submitted && f.village_id.errors" class="invalid-feedback">
                    <div *ngIf="f.village_id.errors.required">Village is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- nw lines added for village -->
            <div class="col-xl-3">
              <label>Centre Incharge Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control form-control-sm"
                [ngClass]="{ 'is-invalid': submitted && f.incharge_name.errors }" placeholder="Center Incharge"
                formControlName="incharge_name" maxlength="50"
                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)' />
              <div *ngIf="submitted && f.incharge_name.errors" class="invalid-feedback">
                <div *ngIf="f.incharge_name.errors.required">Center Incharge Name is required</div>
              </div>
            </div>
            <div class="col-xl-3">
              <label>Centre Incharge Mobile No. <span class="text-danger">*</span></label>
              <input type="text" class="form-control form-control-sm"
                [ngClass]="{ 'is-invalid': submitted && f.incharge_mobile_no.errors }"
                placeholder="CenterIncharge Mobile No." formControlName="incharge_mobile_no" [maxlength]="maxLngth"
                (keyup)="onKeyNmbr('incharge_mobile_no', $event.target.value)"
                onkeypress='return event.charCode >= 48 && event.charCode <= 57' [pattern]="mobPtrn" />
              <div *ngIf="submitted && f.incharge_mobile_no.errors" class="invalid-feedback">
                <div *ngIf="f.incharge_mobile_no.errors?.required">Mobile Number is required</div>
                <div *ngIf="f.incharge_mobile_no.errors.pattern">Mobile Number {{mobErrMsg}}</div>
              </div>
            </div>
            <div class="col-xl-3">
              <label>Latitude</label>
              <input type="text" class="form-control form-control-sm" placeholder="latitude"
                formControlName="latitude" />
            </div>
            <div class="col-xl-3">
              <label>Longitude</label>
              <input type="text" class="form-control form-control-sm" placeholder="longitude"
                formControlName="longitude" />
            </div>
            <!-- <div class="col-xl-3 mt-3">
                          <label>Payment per head</label>
                          <input type="text" class="form-control form-control-sm" placeholder="Payment per head" formControlName="paymentperhead" maxlength="4" onkeypress='return event.charCode >= 48 && event.charCode <= 57' />
                      </div> -->
            <!-- <div class="col-xl-3  mt-3">
                        <div class="form-group">
                          <label class="control-label">Hospital Type </label>
                          <div>
                            <select formControlName="hospital_type_id" id="hospital_type_id"
                               class="form-control form-control-sm">
                              <option value="">Select</option>
                              <option *ngFor="let Hosp of HospTypeList" [value]="Hosp.id">
                                {{Hosp.hospital_type}}</option>
                            </select>
    
                          </div>
                        </div>
                      </div> -->
            <div class="col-xl-3">
              <div class="form-group">
                <label class="control-label">Centre Hospital <span class="text-danger"><b>*</b></span></label>
                <div>
                  <select formControlName="center_id" id="center_id" class="form-control form-control-sm">
                    <option value="">Select</option>
                    <option *ngFor="let center of centerHospitals" [value]="center.id">
                      {{center.hospital_name}}</option>
                  </select>
                </div>
                <div *ngIf="submitted && f.center_id.errors" class="invalid-feedback">
                  <div *ngIf="f.center_id.errors.required">Centre Hospital is required</div>
                </div>
              </div>
            </div>
            <div class="col-xl-3">
              <div class="form-group">
                <label class="control-label">Hub Hospital <span class="text-danger"><b>*</b></span></label>
                <div>
                  <select formControlName="hub_id" id="hub_id" class="form-control form-control-sm">
                    <option value="">Select</option>
                    <option *ngFor="let item of hubsList" [value]="item.id">
                      {{item.hub_name}}</option>
                  </select>
                </div>
                <div *ngIf="submitted && f.hub_id.errors" class="invalid-feedback">
                  <div *ngIf="f.hub_id.errors.required">Hub Hospital is required</div>
                </div>
              </div>
            </div>
            <!-- <div class="col-xl-3  mt-5">
                        <div class="form-group">
                          <div>
                          Is Referral <input type="checkbox" name="checkbox" formControlName="is_referral"  [(ngModel)]="isrefChecked" (click)="onRef($event)">
                          </div>
                        </div>
                      </div> -->
            <div class="col-xl-3">
              <div class="form-group">
                <label class="control-label">Referral Hospital</label>
                <!-- <div> -->
                <!-- <select multiple formControlName="center_hospital_mapping" id="center_hospital_mapping"
                               class="form-control form-control-sm" [(ngModel)]="hospid" (change)="onItemSelectreferal(hospid)">
                              <option value="">Select</option>
                              <option *ngFor="let hsp of hospitalrefdataNew" [value]="hsp.id">
                                {{hsp.hospital_name_concat}}</option>
                            </select> -->
                <ng-multiselect-dropdown [settings]="dropdownSettingsreferalNew" [data]="hospitalrefdataNew"
                  (onSelect)="onItemSelectreferalNew($event)" (onDeSelect)="onItemSelectreferalD($event)"
                  [placeholder]="'Select'" [(ngModel)]="selectedItemsref" [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>
                <!-- </div> -->
              </div>
            </div>
            <!-- <div class="col-xl-3 mt-3">
                        <label>Referral Hospitals  </label>&nbsp;
                      
                      
                        <ng-multiselect-dropdown [settings]="dropdownSettingsreferal" [data]="hospitalrefdata" (onSelect)="onItemSelectreferal($event)" (onDeSelect)="onItemSelectreferalD($event)"
                        [placeholder]="'Select'"  [(ngModel)]="selectedItemsref" [ngModelOptions]="{standalone: true}"></ng-multiselect-dropdown>
                    </div> -->
                    <div class="col-xl-2">
                      <div class="form-group">
                        <label class="control-label"> Status <span class="text-danger">*</span> </label>
                        <div class="mt-0">
                          <input type="radio" formControlName="is_active" [value]="true">Active
                          <input type="radio" formControlName="is_active" [value]="false">In Active
                        </div>
                      </div>
                    </div>
            <div class="col-xl-12 mt-3 text-center ">
              <button type="submit" class="btn btn-primary mb-2 text-center"> Submit</button>
              <button class="btn btn-warning mb-2 ml-2 text-center"
                (click)="activeModal.dismiss('Cross click');cancelform()">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
<!-- <div class="modal-footer">
  <input type="submit" class="btn btn-sm btn-primary" value="Submit" (click)="onSubmit($event)">&nbsp;&nbsp;
  <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div> -->