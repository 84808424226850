import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';

import { MasterService } from 'src/app/shared/services/master.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { FileService } from 'src/app/shared/services/file.service';
import { environment } from 'src/environments/environment';
import { Common } from 'src/app/common';
import { UserService } from 'src/app/shared/services/user.service';

declare let $: any;

@Component({
  selector: 'app-agent-controls',
  templateUrl: './agent-controls.component.html',
  styleUrls: ['./agent-controls.component.css'],
  providers: [DatePipe],
})

export class AgentControlsComponent implements OnInit {

  @Input() title: string; @Input() agent: any; @Input() sbmtDsbld: boolean = false; isNIG = environment.isNIG;
  agentForm: FormGroup; mobPtrn = ''; mobErrMsg = ''; maxLngth = ''; tentId = ''; submitted = false; isSAGE = environment.isSAGE;
  locationTypeList = []; stateList = []; districtList = []; mandalList = []; villageList = []; labList = []; pharmacyList = []; bankList = [];
  uploadFileList: Array<{ id: number, reg_id: number, name: string, File: any, extensions: string, file_name: string, fileType: string, document_path: string }> = []
  pharmacyDropdown: any;
  labDropdown: any;

  constructor(public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , private toastr: ToastrService
    , private datePipe: DatePipe
    , private formBuilder: FormBuilder
    , private fileService: FileService
    , private registrationService: RegistrationService
    , private authServ: AuthenticationService
    , private notificationService: NotificationService
    , private userServ: UserService
    , private masterService: MasterService) {
    this.tentId = sessionStorage.getItem('currentTenant');
  }

  ngOnInit(): void {
    this.authServ.mobPtrnSubject.subscribe(s => this.mobPtrn = s), this.authServ.mobErrMsgSubject.subscribe(s => this.mobErrMsg = s),
      this.authServ.maxLngthSubject.subscribe(s => this.maxLngth = s), this.getAllMasters(), this.initFormControls(),
      this.locationChanged('', 'state') //, Common.setFocus('doctor_type_id');
     this.getUserPermission();
    }

  initFormControls() {
    this.agentForm = this.formBuilder.group({
      location_type_id: [null, Validators.required],
      title_id: [0],
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      gender_id: [1],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      address: ['', Validators.required],
      bank_account_number: [''],
      branch_name: [''],
      branch_location: [''],
      ifsc_code: [''],
      state_id: [null, Validators.required],
      district_id: [null, Validators.required],
      city_id: [null],
      mandal_id: [null],
      village_id: [null],
      pin_code: [null, Validators.required],
      user_types: [{ user_type_id: null }],
      preferred_lab_id: [null],
      preferred_pharmacy_id: [null],
      login_name: [''],
      password: [''],
      alternate_mobile_no: [''],
      registration_source_id: [0],
      branch_id: [0],
      is_active: [false],
      organization_id: [0],
      aadharFile: ['', Validators.required],
      digitalFile: ['', Validators.required],
    });
  }

  get f() { return this.agentForm.controls; }

  getAllMasters() {
    const lab = this.registrationService.getAll('lab/getalllabregistrations');
    const pharmacy = this.registrationService.getAll('pharmacy/getallrecords');
    const locationType = this.masterService.getAll('getalllocationtypes');
    const banks = this.masterService.getAll('getallmasterbankdetails');

    forkJoin([lab, pharmacy, locationType, banks]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              console.log('No records available');
            else {
              const assignList = {
                '0': () => this.labList = list.data,
                '1': () => this.pharmacyList = list.data,
                '2': () => this.locationTypeList = list.data,
                '3': () => this.bankList = list.data,
              }
              assignList[ind]();
            }
          }
        });
      },
      error: err => console.error('something wrong occurred: ', err),
      complete: () => {
        // this.editDoctor();
      }
    });
  }
  getUserPermission() {
    let orgIdValue = JSON.parse(sessionStorage.getItem('currentTenant'));
    this.userServ.getUserPermissionByOrgId(orgIdValue).subscribe((res: any) => {
      if (res.status == 'OK') {
        for (let i = 0; i < res.data.length; i++) {
        
          if (res.data[i].feature_id.feature_name ==="pharmacyDropdown") {
            if(res.data[i].patient){
              this.pharmacyDropdown = true ;
            } else{
              this.pharmacyDropdown = false;
            }
           
          } if (res.data[i].feature_id.feature_name === "labRegistrationDropdown") {
            if(res.data[i].patient){
              this.labDropdown = true;
            }else{
              this.labDropdown = false;
            }
            
          }
        
        
        }
      }
    });
  }
 
  locationChanged(id: string, locationType: string) {
    let query: string = '', locations = {
      'location': () => {
        this.mandalList = [], this.villageList = [] //, this.doctorForm.get('district_id').setValue('');
      },
      'state': () => {
        this.districtList = [], this.mandalList = [], this.villageList = [];
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [], this.mandalList = [], this.villageList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      },
      'mandal': () => {
        this.mandalList = [], this.villageList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
    };
    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
        };
        assign_locations[type]();
      }
      this.districtList.sort((a,b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error.message));
  }

  onFileUpload(event: any, fileNumber: number, fileType: string, frmCntrl: string = '') {

  }

  onSubmit(event: any) {
    event.preventDefault(), this.submitted = true, Common.getFormValidationErrors(this.agentForm);
    if (this.agentForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else
      this.showMessage();
  }

  submitForm() {
    $('#btnsubmit').click();
  }

  showMessage(id: number = 0) {
    this.activeModal.close('changes saved'), this.toastr.success(`Record ${(id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Agent Registration');
  }

}