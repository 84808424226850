import { Component,Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { MasterService } from 'src/app/shared/services/master.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { FileService } from 'src/app/shared/services/file.service';
import { Common } from 'src/app/common';
import { ImagePreviewComponent } from '../../modals/image-preview/image-preview.component';
import { environment } from 'src/environments/environment';
declare let $:any;

@Component({
  selector: 'app-hub-coordinator-registration',
  templateUrl: './hub-coordinator-registration.component.html',
  styleUrls: ['./hub-coordinator-registration.component.css'],
  providers: [DatePipe]
})
export class HubCoordinatorRegistrationComponent implements OnInit {
  @Input() title: string; @Input() agent: any; @Input() sbmtDsbld: boolean = false; coordinatorForm: FormGroup; logUsr: any; isNIG = environment.isNIG;
  mobPtrn = ''; mobErrMsg = ''; maxLngth = ''; tentId = sessionStorage.getItem('currentTenant'); isSAGE = environment.isSAGE;
  locationTypeList = []; stateList = []; districtList = []; mandalList = []; villageList = []; labList = []; pharmacyList = [];
  uploadFileList: Array<{ id: number, reg_id: number, name: string, File: any, extensions: string, file_name: string, fileType: string,
    document_path: string }> = []; bankList = []; genderList = []; languagesList = []; selectedItems_language = []; ddLangStngs = {};
  maxDOB = new Date(new Date().setFullYear(+`${new Date().getFullYear() - 18}`)); submitted = false; isShwPic = false; isEdit = false;
  getAllcenters;dob='';dobvalue:any;isAct = '1';bankNamesList:Array<any> = [];adhErr = false;
  constructor(public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , private toastr: ToastrService
    , private datePipe: DatePipe
    , private formBuilder: FormBuilder
    , private fileService: FileService
    , private registrationService: RegistrationService
    , private authServ: AuthenticationService
    , private notificationService: NotificationService
    , private masterService: MasterService) {
  }

  get f() { return this.coordinatorForm.controls; }

  ngOnInit(): void {
    ['AadharCard'].forEach((e, i) => this.uploadFileList.push({ id: 0, reg_id: i+1, name: e, File: null, extensions: '', file_name: '',
      fileType: ['12'][i], document_path: '' })), ['jpg,jpeg,pdf'].forEach((e, i) => this.uploadFileList[i].extensions = e);
    this.logUsr = this.authServ.currentUserValue, this.ddLangStngs = { singleSelection: false, idField: 'id', textField: 'language_name',
      itemsShowLimit: 3, allowSearchFilter: true, selectAllText: 'Select All', unSelectAllText: 'Deselect All', },
      this.authServ.mobPtrnSubject.subscribe(s => this.mobPtrn = s), this.authServ.mobErrMsgSubject.subscribe(s => this.mobErrMsg = s),
      this.authServ.maxLngthSubject.subscribe(s => this.maxLngth = s), this.getAllMasters(), this.initFormControls(), this.locationChanged('', 'state') //, Common.setFocus('doctor_type_id');
      if (this.isNIG)    this.radioclick('true');
        this.coordinatorForm.get('aadharFile').setValidators(null), this.coordinatorForm.get('aadharFile').updateValueAndValidity();
  }
  initFormControls() {
    this.coordinatorForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      gender_id: [null, Validators.required],
      email: ['',  [Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      mobile: ['', [Validators.required, Validators.pattern(this.mobPtrn)]],
      address: [''],
      location_type_id: ['', Validators.required],
      birth_date: ['', Validators.required],
      state_id: ['', Validators.required],
      district_id: ['', Validators.required],
      user_types: [[{ user_type_id: 17 }]],
      mandal_id: [0],
      village_id: [0],
      login_name: [''],
      password: ['123456'],
      pin_code: [null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)]],
      alternate_mobile_no: ['', [Validators.maxLength(+this.maxLngth), Validators.pattern(this.mobPtrn)]],
      bank_account_number: [''],
      bank_id: [''],
      branch_location: [''],
      name_as_per_bank:[''],
      pan_no:['',Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')],
      ifsc_code: ['', Validators.pattern('[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$')],
      registration_source_id: [0],
      is_active: [true],
      account_activated: [false],
      created_by: [''],
      created_on: [''],
      updated_by:[''],
      updated_on: [''],
        user_id: null,
      organization_id: [+sessionStorage.getItem('logUsrRoleId') == 1 ? +sessionStorage.getItem('currentTenant') : (this.logUsr?.orgnization_registration?.id ?? 1)],
      id:[0]
    });
  }

  getAllMasters() {
    const locationType = this.masterService.getAll('getalllocationtypes');
    const gender = this.masterService.getAll('getallgenders');
    const bankName = this.masterService.getAll('getallmasterbankdetails');
    forkJoin([ locationType, gender,bankName]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              console.log('No records available');
            else {
              const assignList = {
                '0': () => this.locationTypeList = list.data,
                '1': () => this.genderList = list.data,
                '2': () => this.bankNamesList = list.data,
              }
              assignList[ind]();
            }
          }
        });
      },
      error: err => console.error('something wrong occurred: ', err),
      complete: () => { this.isShwPic = false, this.editAgent(); }
    });
  }

  locationChanged(id: string, locationType: string) {
    let query: string = '', locations = {
      'location': () => { this.mandalList = [], this.villageList = []; },
      'state': () => { this.districtList = [], this.mandalList = [], this.villageList = [], query = 'getallstates'; },
      'district': () => {
        this.districtList = [], this.mandalList = [], this.villageList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      },
      'mandal': () => {
        this.mandalList = [], this.villageList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
    };
    locations[locationType]();
    if (query.length > 0)
      this.getLocations(query, locationType);
  }

  onChngDD(ctrl: string, val: any) {
    if (val == 'null')
      this.coordinatorForm.get(ctrl).setValue(null);
  }

  getLocations(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
        };
        assign_locations[type]();
      }
    }, error => this.toastr.error(error));
  }

 

  editAgent() {
    if (this.agent) {
      let isFile = false;
      this.isEdit = true, this.isShwPic = true, this.coordinatorForm.patchValue(this.agent), this.agent.gap_documents.forEach(f => {
        const docu = this.uploadFileList.find(e => e.fileType == f.file_type_id);
        if (docu) {
          docu.id = f.id;
          docu.document_path = f.document_path;
          docu.file_name = f.file_name;
          isFile = true;
        }
      });
      if (isFile)
      this.dobvalue=this.coordinatorForm.value.birth_date;
        this.coordinatorForm.get('aadharFile').setValidators(null), this.coordinatorForm.get('aadharFile').updateValueAndValidity();
      Promise.all([this.locationChanged(this.coordinatorForm.get('state_id').value, 'district'),
          this.locationChanged(this.coordinatorForm.get('district_id').value, 'mandal'),
          this.locationChanged(this.coordinatorForm.get('mandal_id').value, 'village')]).then(() => {  this.coordinatorForm.patchValue({
          birth_date: this.datePipe.transform(this.coordinatorForm.get('birth_date').value, "dd-MM-yyyy"), });
      });
    }
  }
  keyUp(elementId: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementId)));
    el.value = el.value.replace(/^(0*)/, "");
  }



  onSubmit(event: any) {
    this.adhErr = false;
    event.preventDefault(), this.submitted = true, Common.getFormValidationErrors(this.coordinatorForm);
    if (this.coordinatorForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else {
      let payLoad = this.coordinatorForm.getRawValue();
      if(this.coordinatorForm.get('id').value == 0){
        payLoad.birth_date = this.datePipe.transform(payLoad.birth_date, "yyyy-MM-dd")
        delete payLoad.id;
      }
      else{
        //payLoad.birth_date=this.dob;
        //this.dob=new Date(payLoad.birth_date)
        if(this.dob !=''){
        payLoad.birth_date = this.datePipe.transform(payLoad.birth_date, "yyyy-MM-dd")
        }else{
          payLoad.birth_date=this.dobvalue;
        }
      }
      if(this.coordinatorForm.valid){

      }
      delete payLoad.aadharFile;  
      //  delete payLoad.id;
      payLoad.birth_date = this.datePipe.transform(payLoad.birth_date, "yyyy-MM-dd"), payLoad.login_name = payLoad.mobile + '@gmail.com';
    
      // if(payLoad.account_activated == true){
      //  payLoad.is_active == true
      // }else{
      //   payLoad.is_active == false
      // }
      this.registrationService.saveHubCoord(payLoad).subscribe(res => {
        if (res.status == "OK") {
          let id: number = +(payLoad.id ?? 0);
          // if (id == 0) {
          //   const notificationData = { email: payLoad.email, mobile_no: +payLoad.mobile, login_name: payLoad.login_name,
          //     name: `${payLoad.first_name} ${payLoad.last_name}` };
          //   this.send_Notification(notificationData);
          // }
          if (this.uploadFileList.some(f => f.File != null))
            this.saveFile(res.data.id, id);
          else
            this.showMessage(id);
        }
        else
          this.toastr.error(res.message);
      }, error => this.toastr.error(error.message));
    }
  }

  saveFile(id: string, record_id: number) {
    let sendAPIfiles = [];
    this.uploadFileList.forEach(item => {
      if (item.File)
        sendAPIfiles.push(this.fileService.uploadFile(Common.form_Data(item.id.toString(), item.File, id, 'HUB', item.fileType)));
    });

    forkJoin([...sendAPIfiles]).subscribe(res => console.log, err => console.log, () => { this.showMessage(record_id); });
  }

  send_Notification(payload: { email: string, mobile_no: number, name: string,login_name:string }) {
    const postData = {
      "email": payload.email,
      "login_name": payload.login_name,
      "mobile_no": payload.mobile_no,
      "user_id": 13,
      "user_type": "GAP",
      "name": payload.name.toUpperCase()
    }
    this.notificationService.sendNotification(postData).subscribe();
  }

  submitForm() {
    $('#btnsubmit').click();
  }

  onChngPM(val: any) {
    if (val != '') {
      if (val != 'self') {
        // this.agentForm.get('agent_fee').setValue(''), this.agentForm.get('agent_fee').setValidators([Validators.required]),
        //   this.agentForm.get('agent_fee').updateValueAndValidity();
      }
      else {
        ['payment_type', 'max_payment_capacity', 'bank_account_number', 'bank_id', 'branch_name', 'ifsc_code'].  // ['payment_type', 'max_payment_capacity', 'bank_account_number', 'account_holder_name', 'bank_id', 'branch_name', 'ifsc_code'].
          forEach(e => {
            this.coordinatorForm.get(e).setValue('');
            if (this.isNIG && e == 'ifsc_code')
              this.coordinatorForm.get(e).setValidators([Validators.required]);
            else
              this.coordinatorForm.get(e).setValidators(null);
            this.coordinatorForm.get(e).updateValueAndValidity();
          });
      }
    }
  }

  showMessage(id: number = 0) {
    this.activeModal.close('changes saved'), this.toastr.success(`Record ${(id > 0) ? 'Updated' : 'Saved'} Successfully`, 'GAP Registration');
  }
  radioclick(item:any){
    if(item=='true'){
      this.isAct='1';
      this.coordinatorForm.patchValue({
        account_activated:true
      })
    }else{
      this.isAct='0';
      this.coordinatorForm.patchValue({
        account_activated:false
      })
    }
      }
  dateclick(){
    this.dob='date';
      }
      modalclose(){
        this.dob='';
      }

}
