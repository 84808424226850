import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/shared/services/master.service';
import { Subject, pipe } from 'rxjs';
import { MandalComponent } from '../mandal/mandal.component';
import { forkJoin } from 'rxjs';
import { Common } from 'src/app/common';
declare let $: any;

@Component({
  selector: 'app-mandal-list',
  templateUrl: './mandal-list.component.html',
  styleUrls: ['./mandal-list.component.css']
})
export class MandalListComponent implements OnInit {


modalTitle:string;
// @Input() pharmaData: any;
mandalListForm: FormGroup;
submitted = false;

dtOptions: any = {};
dtTrigger: any = new Subject();
mandallist:any;
locationTypeList: Array<any> = [];
stateList: Array<any> = [];
districtList: Array<any> = []
mandalList: Array<any> = [];
state_id: number = 0;
district_id: number =0;
constructor(
  config: NgbModalConfig, 
    private modalService: NgbModal,
    private toastr: ToastrService,
    private masterService: MasterService,
    private formBuilder: FormBuilder,
) { }

ngOnInit(): void {
  this.locationChanged('', 'state');
  this.getAllMasters();
  this.initFormControls()

}

openMandalModal(editData:any = null) {
  const modalRef = this.modalService.open(MandalComponent);
  modalRef.componentInstance.title = this.modalTitle;
  modalRef.componentInstance.editData = editData;
       
  modalRef.result.then(result => {
  }, (reason) => { });;
}

initFormControls() {
  this.mandalListForm = this.formBuilder.group({
    state_id: ['', Validators.required],
    district_id: ['', Validators.required],
  });
}
locationChanged(id: string, locationType: string) {
      let query: string = '';
      let locations = {
        'location': () => {
          this.mandalList = [];
                this.mandalListForm.get('district_id').setValue('');
        },
        'state': () => {
          this.districtList = [];
          this.mandalList = [];
          query = 'getallstates';
        },
        'district': () => {
          this.districtList = [];
          this.mandalList = [];
          if (+id > 0)
            query = `getstatebyid?stateId=${id}`;
        },
        'mandal': () => {
          this.mandalList = [];
          if (+id > 0)
            query = `getdistrictbyid?districtId=${id}`;
        }, 
      };
      locations[locationType]();
      if (query.length > 0)
        this.getMasters(query, locationType);
    }

getMasters(queryString: string, type: string) {
  this.masterService.getLocations(queryString).subscribe(data => {
    let result = data;
    if (result.status == "OK") {
      let arr: any = result.data;
      let assign_locations = {
        'state': () => this.stateList = arr,
        
        'district': () => this.districtList = arr.districts,
       'mandal': () => this.mandalList = arr.mandals,
      };
      assign_locations[type]();
    }
    this.districtList.sort((a,b) => 0 - (a.district_name > b.district_name ? -1 : 1));
  }, error => this.toastr.error(error));
} 
getAllMasters() {
  const locationType = this.masterService.getAll('getalllocationtypes');

  forkJoin([locationType]).subscribe(res => {
    res.forEach((list, ind) => {
      if (list.status === 'OK') {
        if (list.data == null || list.data.length == 0)
          this.toastr.info('No records available', 'Mandal List');
        else {
          const assignList = {
            '0': () => this.locationTypeList = list.data,
          }
          assignList[ind]();
        }
      }
    });
  });

}

    loadMandals() {
      // if (this.state_id == 0) {
      //   this.toastr.error('Please Select State');
      //   Common.setFocus('state_id');
      //   return;
      // }
      // if (this.district_id == 0) {
      //   this.toastr.error('Please Select District');
      //   Common.setFocus('district_id');
      //   return;
      // }
   
    var id = $('#state_id').val()
$('.dataTable').DataTable().destroy();
$('.dataTable').DataTable({ searching: false });
    this.masterService.searchbyIdMasters(`getmandalbydistrictid?districtId=${id}`).subscribe((data: any) => {
this.mandallist = data.data;
this.dtTrigger.next();
});
}

  get f() { return this.mandalListForm.controls; }

deleteItem(id: any) {
  this.masterService.deleteById(`deletestate?stateId=${id}`).subscribe(res => {
    if (res.status == "OK") {
      // this.getAllStates();
      this.toastr.success('Record Deleted Successfully');
    }
  },
    error => { this.toastr.error('An Error Occured'); });
}
setFocus(elementName: string) {
  let el: any = (<HTMLTextAreaElement>(document.getElementById(elementName)));
  el.value = '';
  el.focus();
}
}
