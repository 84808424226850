import { Component, Input, OnInit } from '@angular/core';
import { ConsentFileDetailsComponent } from '../consent-file-details/consent-file-details.component';
import { Common } from 'src/app/common';
declare let $: any;
import * as uuid from 'uuid';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-consent-view-files',
  templateUrl: './consent-view-files.component.html',
  styleUrls: ['./consent-view-files.component.css']
})
export class ConsentViewFilesComponent implements OnInit {
  @Input() title;
  @Input() consentId;
    fileList: any=[];
    fileListNew: any=[];
    myBase64: any;
    constructor(private regService: RegistrationService
      , public activeModal: NgbActiveModal
      , private modalService: NgbModal
      , protected toastr: ToastrService
      , private sanitizer: DomSanitizer) { }
  
    ngOnInit(): void {
      this.loadFiles();
    }
    loadFiles(){
      this.regService.getConsentViewFiles(this.consentId).subscribe(res=>{
      console.log('Consent files',JSON.stringify(res));
      // let Resp = res.data;
      let fileListTemp = [];
      fileListTemp=res.data;

      fileListTemp.map(re=>{
        if(re?.decryptedDocument?.entry && re?.decryptedDocument?.entry.length>0){
        this.fileList.push(re);
        }
      })

    
      });
     
    
    }
    openViewModal(item:any) {
      // this.modalService.open(content, { scrollable: true , size: 'lg', centered: true });
      // const modalRef = this.modalService.open(ConsentViewFilesComponent, { scrollable: true, size: 'xl' });
      // modalRef.componentInstance.title = 'Consent Files';
      // modalRef.componentInstance.consentId= item.consentid;
      const modalRef = this.modalService.open(ConsentFileDetailsComponent, { scrollable: true, size: 'xl' });
      modalRef.componentInstance.title = 'Consent Files';
      modalRef.componentInstance.item = item;
      modalRef.componentInstance.consentId= item.consentid;
    }
    domSanitize(item:any) {
      // let base64 = sessionStorage.getItem('ndhmbase')
      setTimeout(() => {
        this.myBase64 = this.sanitizer.bypassSecurityTrustResourceUrl(
          `data:image/png/pdf;base64, ${item.file}`
        );
        $("#appModal").modal("show");
        Common.showPDF_File(URL.createObjectURL(this.myBase64))
      }, 1500);
    
      // this.isShowModal=true;
      // $("#appModal").modal("show");
      // console.log(dwnldCards);
    }
    close() {
      $("#appModal").modal("hide");
    }
  }
  