<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Support Team {{suprtMailId}}
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-12">
                                <tinymce [(ngModel)]="template" [config]="config"></tinymce>
                            </div>
                            <div class="col-xl-12 text-center">
                                <button class="btn btn-primary btn-sm mt-1" (click)="sendEmail()" type="button"
                                    *ngIf="setPrivilege(Screen_Name.Support, Permission.Add)">Submit</button>
                                    <!-- [attr.disabled]="template==''?true:null" -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>