<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="agentForm" (ngSubmit)="onSubmit($event)">
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Type of Pharmacist/Healthcare Professional <span class="text-danger">*</span>
                        </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="agent_type"
                                [ngClass]="{ 'is-invalid': submitted && f.agent_type.errors }" id="agent_type">
                                <option value="">Select</option>
                                <option value="Independent">Independent</option>
                                <option value="Lab">Lab</option>
                                <option value="Pharmacy">Pharmacy</option>
                                <option value="Hospital">Hospital</option>
                            </select>
                            <div *ngIf="submitted && f.agent_type.errors" class="invalid-feedback">
                                <div *ngIf="f.agent_type.errors.required">Type of Pharmacist/Healthcare Professional is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Pharmacist/Healthcare Professional Type </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="center_type" #AgentType>
                                <option value="">Select</option>
                                <option *ngFor="let agenttype of getagenttypes" [value]="agenttype.type_name">{{agenttype.type_name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="AgentType.value=='Spoke' || AgentType.value=='Asha'">
                    <div class="form-group">
                        <label class="control-label">Centers</label>
                        <div>
                          <select class="form-control form-control-sm" id="center"
                          formControlName="center_id">
                              <option value="null">Select</option>
                              <option *ngFor="let center of getAllcenters" [value]="center.id">
                                {{center.center_name}}</option>
                          </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="AgentType.value=='Hub'">
                    <div class="form-group">
                        <label class="control-label">Hub</label>
                        <div>
                          <select class="form-control form-control-sm" id="center"
                          formControlName="hub_id">
                              <option value="null">Select</option>
                              <option *ngFor="let hub of getallhubslist" [value]="hub.id">
                                {{hub.hub_name}}</option>
                          </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <div>
                            <label class="control-label"> Name <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" maxlength="50" formControlName="first_name"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"
                                class="form-control form-control-sm text-uppercase">
                            <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                <div *ngIf="f.first_name.errors.required">Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <div>
                            <label class="control-label"> Surname <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" maxlength="50" formControlName="last_name" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }"
                                class="form-control form-control-sm text-uppercase">
                            <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                <div *ngIf="f.last_name.errors.required">Surname is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Date of Birth <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="birth_date" id="birth_date"
                                [ngClass]="{ 'is-invalid': submitted && f.birth_date.errors }" (focus)="dob.show()"
                                (bsValueChange)="onFromDateChange()"
                                bsDatepicker #dob="bsDatepicker" class="form-control form-control-sm" [maxDate]="maxDOB"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }">
                            <div *ngIf="submitted && f.birth_date.errors" class="invalid-feedback">
                                <div *ngIf="f.birth_date.errors.required">
                                    Date of Birth is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Gender <span class="text-danger">*</span> </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="gender_id"
                                [ngClass]="{ 'is-invalid': submitted && f.gender_id.errors }" (focus)="dob.hide()"
                                (change)="onChngDD('gender_id', $event.target.value)" id="gender_id">
                                <option value="null">Select</option>
                                <option *ngFor="let gen of genderList" [value]="gen.id">{{gen.gender_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.gender_id.errors" class="invalid-feedback">
                                <div *ngIf="f.gender_id.errors.required">Gender is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Mobile Number <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="mobile" id="mobile"
                                [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" [maxlength]="maxLngth"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' [pattern]="mobPtrn"
                                class="form-control form-control-sm" [attr.disabled]="isEdit==true?true:null">
                            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                <div *ngIf="f.mobile.errors?.required">Mobile Number is required</div>
                                <div *ngIf="f.mobile.errors.pattern">Mobile Number {{mobErrMsg}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Alternate Mobile Number </label>
                            <div>
                                <input type="text" formControlName="alternate_mobile_no"
                                    [ngClass]="{ 'is-invalid': submitted && f.alternate_mobile_no.errors?.pattern }"
                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                    [maxlength]="maxLngth" id="alternate_mobile_no" class="form-control form-control-sm"
                                    [pattern]="mobPtrn">
                                <div *ngIf="submitted && f.alternate_mobile_no.errors?.pattern"
                                    class="invalid-feedback">
                                    <div>Alternate Mobile Number {{mobErrMsg}}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Email <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" maxlength="60" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email"
                                [attr.enabled]="isEdit==true?true:null" onkeypress='return ((event.charCode == 64) ||(event.charCode == 46)  || (event.charCode == 95) || (event.charCode == 45) ||
                                (event.charCode >= 97 && event.charCode <= 122)) || (event.charCode >= 48 && event.charCode <= 57)'>
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                                <div *ngIf="f.email.errors.pattern">Enter Valid Email</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Local Languages Preferred <span class="text-danger">*</span> </label>
                        <div>                            
                            <div tabindex="0" class="">
                                <ng-multiselect-dropdown [settings]="ddLangStngs" [placeholder]="'All'"
                                    [ngClass]="{ 'is-invalid': submitted && f.agent_languages.errors }"
                                    [data]="languagesList" formControlName="agent_languages"></ng-multiselect-dropdown>
                                <div *ngIf="submitted && f.agent_languages.errors" class="invalid-feedback" >
                                    <div *ngIf="f.agent_languages.errors.required">Local Languages Preferred is required</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-3">
                    <div class="form-group">
                      <label class="control-label">Location Type <span class="text-danger">*</span> </label>
                      <div>
                        <select formControlName="location_type_id" id="location_type_id" #locationId
                          (change)="locationChanged('','location')"
                          [ngClass]="{ 'is-invalid': submitted && f.location_type_id.errors }"
                          class="form-control form-control-sm">
                          <option value="">Select</option>
                          <option *ngFor="let location of locationTypeList" [value]="location.id">
                            {{location.location_type}}</option>
                        </select>
                        <div *ngIf="submitted && f.location_type_id.errors" class="invalid-feedback">
                          <div *ngIf="f.location_type_id.errors.required">Location Type is required</div>
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> {{isNIG ? 'States' : 'State'}} <span class="text-danger">*</span>
                        </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="state_id"
                                [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }" id="state" #State
                                (change)="onChngDD('state_id', $event.target.value);locationChanged($event.target.value, 'district')" disabled>
                                <option value="null">Select</option>
                                <option *ngFor="let state of stateList" [value]="state.id">{{state.state_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                <div *ngIf="f.state_id.errors.required">{{isNIG ? 'States' : 'State'}} is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                
                  <div class="col-xl-3">
                    <div class="form-group">
                      <label class="control-label">{{isNIG ? 'State Capitals' : 'District'}} <span
                          class="text-danger">*</span>
                      </label>
                      <div>
                        <select formControlName="district_id" id="district_id" #District
                          (change)="locationChanged(District.value,(+locationId.value==2)?'city':'mandal')"
                          [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }"
                          class="form-control form-control-sm">
                          <option value="">Select</option>
                          <option *ngFor="let district of districtList" [value]="district.id">
                            {{district.district_name}}</option>
                        </select>
                        <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                          <div *ngIf="f.district_id.errors.required">
                            {{isNIG ? 'State Capitals' : 'District'}} is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <div class="form-group">
                      <label class="control-label">
                        {{isSAGE ? 'Location' : (isNIG ? 'Local Government Areas' : 'Block')}}
                        <span *ngIf="+locationId?.value==1" class="text-danger">*</span> </label>
                      <div>
                        <select formControlName="mandal_id" id="mandal_id" #Mandal
                          [attr.disabled]="(+locationId.value==2 || isEditable)?true:null"
                          (change)="locationChanged(Mandal.value,'village')"
                          [ngClass]="{ 'is-invalid': submitted && f.mandal_id.errors }" class="form-control form-control-sm">
                          <option value="">Select</option>
                          <option *ngFor="let mandal of mandalList" [value]="mandal.id">
                            {{mandal.mandal_name}}</option>
                        </select>
                        <div *ngIf="submitted && f.mandal_id.errors" class="invalid-feedback">
                          <div *ngIf="f.mandal_id.errors.required">
                            {{isSAGE ? 'Location' : (isNIG ? 'Local Government Areas' : 'Block')}} is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3" *ngIf="+locationId.value==2">
                    <div class="form-group">
                      <label class="control-label">City </label>
                      <div>
                        <select formControlName="city_id" id="city_id"
                          [ngClass]="{ 'is-invalid': submitted && f.city_id.errors }" class="form-control form-control-sm"
                          [attr.disabled]="isEditable ? true : null">
                          <option value="">Select</option>
                          <option *ngFor="let city of cityList" [value]="city.id">
                            {{city.city_name}}</option>
                        </select>
                        <div *ngIf="submitted && f.city_id.errors" class="invalid-feedback">
                          <div *ngIf="f.city_id.errors.required">City is required</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3" *ngIf="+locationId.value != 2 && !isNIG">
                    <div class="form-group">
                      <label class="control-label">Village <span *ngIf="+locationId.value==1" class="text-danger">*</span>
                      </label>
                      <div>
                        <select formControlName="village_id" id="village_id"
                          [attr.disabled]="(+locationId.value==2 || isEditable)?true:null"
                          [ngClass]="{ 'is-invalid': submitted && f.village_id.errors }" class="form-control form-control-sm">
                          <option value="">Select</option>
                          <option *ngFor="let village of villageList" [value]="village.id">
                            {{village.village_name}}</option>
                        </select>
                        <div *ngIf="submitted && f.village_id.errors" class="invalid-feedback">
                          <div *ngIf="f.village_id.errors.required">Village is required
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Pin Code <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" class="form-control form-control-sm"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && f.pin_code.errors }" maxlength="6" minlength="6"
                                formControlName="pin_code">
                            <div *ngIf="submitted && f.pin_code.errors" class="invalid-feedback">
                                <div *ngIf="f.pin_code.errors.required">Pin Code is required</div>
                                <div *ngIf="f.pin_code.errors.minlength">Pin Code should be 6 characters</div>
                                <div *ngIf="f.pin_code.errors.maxlength">Pin Code should be 6 characters</div>
                            </div>
                        </div>
                    </div>
                </div> 
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Pharmacist/Healthcare Professional Address <span class="text-danger">*</span>
                        </label>
                        <div>
                            <textarea rows="2" class="form-control form-control-sm" style="resize: none; height: 50px;"
                                maxlength="150"
                                [ngClass]="{ 'is-invalid': submitted && f.address.errors }" formControlName="address">
                            </textarea>
                            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                <div *ngIf="f.address.errors.required">Pharmacist/Healthcare Professional Address is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Group Name </label>
                        <div>
                            <input type="text" class="form-control form-control-sm" maxlength="8" minlength="6">
                        </div>
                    </div>
                </div>
               
            </div>
            <div class="row">
                <div class="col-xl-3" style="display: none;">
                    <div class="form-group">
                        <label class="control-label"> Payment Module <span class="text-danger">*</span> </label>
                        <div>
                            <select class="form-control form-control-sm" id="payment_by_agent" #Payment
                                formControlName="payment_by_agent"
                                (change)="onChngDD('payment_by_agent', $event.target.value);onChngPM($event.target.value)"
                                [ngClass]="{ 'is-invalid': submitted && f.payment_by_agent.errors }">
                                <option value="null">Select</option>
                                <option [value]="true">Self</option>
                                <option [value]="false">Patient</option>
                            </select>
                            <div *ngIf="submitted && f.payment_by_agent.errors" class="invalid-feedback">
                                <div *ngIf="f.payment_by_agent.errors.required">Payment Module is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="Payment.value=='self'">
                    <div class="form-group">
                        <label class="control-label"> Payment Type <span class="text-danger">*</span> </label>
                        <div>
                            <select class="form-control form-control-sm" id="payment_type"
                                formControlName="payment_type"
                                [ngClass]="{ 'is-invalid': submitted && f.payment_type.errors }">
                                <option value="">Select</option>
                                <option value="Pre Paid">Pre Paid</option>
                                <option value="Post Paid">Post Paid</option>
                                <option value="Direct">Direct</option>
                            </select>
                            <!-- <div *ngIf="submitted && f.payment_type.errors" class="invalid-feedback">
                                <div *ngIf="f.payment_type.errors.required">Payment Type is required</div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="Payment.value=='self'">
                    <div class="form-group">
                        <label class="control-label"> Max. cap. of Payment <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" class="form-control form-control-sm"
                                [pattern]="mobPtrn" formControlName="max_payment_capacity"
                                [ngClass]="{ 'is-invalid': submitted && f.max_payment_capacity.errors }"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                            <div *ngIf="submitted && f.max_payment_capacity.errors" class="invalid-feedback">
                                <div *ngIf="f.max_payment_capacity.errors.required">
                                    Max. cap. of Payment is required
                                </div>
                                <!-- <div *ngIf="f.contact_person_mobile.errors.pattern">
                                    Contact Person No. {{mobErrMsg}}
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Center Mapping With Agent </label>
                        <div>
                          <select class="form-control form-control-sm" id="payment_type"
                          formControlName="center_id">
                              <option value=0>Select</option>
                              <option *ngFor="let center of getAllcenters" [value]="center.id">
                                {{center.center_name}}</option>
                          </select>
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-3" *ngIf="Payment.value=='self'">
                    <div class="form-group">
                        <label class="control-label"> Account Number <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" class="form-control form-control-sm" maxlength="20"
                                formControlName="bank_account_number"
                                [ngClass]="{ 'is-invalid': submitted && f.bank_account_number.errors }"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'>
                            <div *ngIf="submitted && f.bank_account_number.errors" class="invalid-feedback">
                                <div *ngIf="f.bank_account_number.errors.required">Account Number is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class="col-xl-3" *ngIf="Payment.value=='self'">
                    <div class="form-group">
                        <label class="control-label"> Bank Name <span class="text-danger">*</span> </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="bank_id"
                                [ngClass]="{ 'is-invalid': submitted && f.bank_id.errors }">
                                <option value="">Select</option>
                                <option *ngFor="let bank of bankList" [value]="bank.id">{{bank.bank_id}}</option>
                            </select>
                            <div *ngIf="submitted && f.bank_id.errors" class="invalid-feedback">
                                <div *ngIf="f.bank_id.errors.required">Bank Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="Payment.value=='self'">
                    <div class="form-group">
                        <label class="control-label"> Branch Location <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" class="form-control form-control-sm" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))'
                                [ngClass]="{ 'is-invalid': submitted && f.branch_name.errors }" maxlength="50"
                                formControlName="branch_name">
                            <div *ngIf="submitted && f.branch_name.errors" class="invalid-feedback">
                                <div *ngIf="f.branch_name.errors.required">Branch Location is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="Payment.value=='self'">
                    <div class="form-group">
                        <label class="control-label"> IFSC Code <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" class="form-control form-control-sm" maxlength="11"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32)) ||
                                    (event.charCode >= 48 && event.charCode <= 57)' formControlName="ifsc_code"
                                [ngClass]="{ 'is-invalid': submitted && f.ifsc_code.errors }">
                            <div *ngIf="submitted && f.ifsc_code.errors" class="invalid-feedback">
                                <div *ngIf="f.ifsc_code.errors.required">
                                    {{isSAGE ? 'SWIFT' : 'IFSC'}} Code is required</div>
                                <div *ngIf="f.ifsc_code.errors.pattern">
                                    Enter Valid {{isSAGE ? 'SWIFT' : 'IFSC'}} Code, ex: SBIN0001050</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3" *ngIf="Payment.value=='patient'">
                    <div class="form-group">
                        <label class="control-label"> Agent Consultation Fee <span class="text-danger">*</span> </label>
                        <div class="input_wrapper">
                            <input type="text" autocomplete="off" class="form-control form-control-sm"
                                [pattern]="mobPtrn" formControlName="agent_fee" maxlength="2"
                                [ngClass]="{ 'is-invalid': submitted && f.agent_fee.errors }"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57' onpaste="return false">
                            <div [ngClass]="submitted && f.agent_fee.errors ? 'units':'unit'"> % </div>
                            <div *ngIf="submitted && f.agent_fee.errors" class="invalid-feedback">
                                <div *ngIf="f.agent_fee.errors.required">
                                    Agent Consultation Fee is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
            <div class="row">
                 <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Status <span class="text-danger">*</span> </label>
                        <div>
                            <input type="radio" formControlName="is_active" [value]="true">Active
                            <input type="radio" formControlName="is_active" [value]="false">In Active
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="labDropdown">
                    <div class="form-group">
                        <label class="control-label"> Lab Preferred </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="preferred_lab_id">
                                <option value="null">Select</option>
                                <option *ngFor="let lab of labList" [value]="lab.id">{{lab.lab_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="pharmacyDropdown">
                    <div class="form-group">
                        <label class="control-label"> Pharmacy Preferred </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="preferred_pharmacy_id">
                                <option value="null">Select</option>
                                <option *ngFor="let phrm of pharmacyList" [value]="phrm.id">{{phrm.pharmacy_name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <!-- <div class="form-group">
                        <label class="control-label"> Send Notification</label>
                        <div>
                            <input type="checkbox" formControlName="send_notification">
                        </div>
                    </div> -->
                    <div class="form-check form-check-inline">
                        <input type="checkbox" formControlName="send_notification">
                       &nbsp;&nbsp; <label class="form-check-label" for="inlineCheckbox22">Send Notification</label>
                      </div>
                </div>
                <div class="col-xl-12">
                    <div class="table-responsive">
                        <table class="table table-bordered table-sm">
                            <tbody>
                                <tr [hidden]="isNIG">
                                    <td>
                                        <input type="file" class="d-none" id="aadhaar" #aAdhar
                                            formControlName="aadharFile"
                                            (change)="onFileUpload($event,1,'12','aadharFile')"
                                            [ngClass]="{'is-invalid': submitted && f.aadharFile.invalid }">
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="aAdhar.click()"> {{isSAGE ? 'National ID' : 'Aadhaar'}} </p>
                                        <span class="text-danger">*</span>
                                        <p class="small mb-1">jpg or pdf format only</p>
                                        <div *ngIf="submitted && f.aadharFile.errors" class="invalid-feedback">
                                            <div *ngIf="f.aadharFile.errors.required">{{isSAGE ? 'National ID' :
                                                'Aadhaar'}} is required</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <!-- <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill" title="Cancel File">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span> <a href="javascript:void(0)"></a>
                                    </td> -->
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill pointer" title="Cancel File"
                                                *ngIf="uploadFileList[0].File || isShwPic" (click)="cancelFile(1)">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span>
                                        <a href="javascript:void(0)"
                                            (click)="viewFile(1)">{{uploadFileList[0].file_name}}</a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <input type="submit" id="btnsubmit" style="display: none;" (click)="onSubmit($event)">
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" [disabled]="sbmtDsbld?true:null" class="btn btn-sm btn-primary" (click)="submitForm()"
        value="Submit">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>