<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="agentForm" (ngSubmit)="onSubmit($event)">
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> {{isNIG ? 'States' : 'State'}} <span class="text-danger">*</span> </label>
                        <div>
                            <select class="form-control form-control-sm" id="state" formControlName="state_id"
                                [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }"
                                (change)="locationChanged(State.value, 'district')" #State>
                                <option value="null">Select</option>
                                <option *ngFor="let state of stateList" [value]="state.id">{{state.state_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                <div *ngIf="f.state_id.errors.required">{{isNIG ? 'States' : 'State'}} is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Location Type <span class="text-danger">*</span> </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="location_type_id"
                                [ngClass]="{ 'is-invalid': submitted && f.location_type_id.errors }">
                                <option value="null">Select</option>
                                <option *ngFor="let loc of locationTypeList" [value]="loc.id">{{loc.location_type}}</option>
                            </select>
                            <div *ngIf="submitted && f.location_type_id.errors" class="invalid-feedback">
                                <div *ngIf="f.location_type_id.errors.required">Location Type is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3"> 
                    <div class="form-group">
                        <label class="control-label"> {{isNIG ? 'State Capitals' : 'District'}} <span class="text-danger">*</span> </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="district_id"
                                [ngClass]="{ 'is-invalid': submitted && f.district_id.errors }"
                                (change)="locationChanged(District.value, 'mandal')" #District>
                                <option value="null">Select</option>
                                <option *ngFor="let dist of districtList" [value]="dist.id">{{dist.district_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                                <div *ngIf="f.district_id.errors.required">
                                    {{isNIG ? 'State Capitals' : 'District'}} is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" >
                    <div class="form-group">
                        <div>
                            <label class="control-label"> {{isNIG ? 'Local Government Areas' : 'Mandal'}} </label>
                            <select class="form-control form-control-sm" formControlName="mandal_id"
                                (change)="locationChanged(Mandal.value, 'village')" #Mandal>
                                <option value="null">Select</option>
                                <option *ngFor="let mndl of mandalList" [value]="mndl.id">{{mndl.mandal_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="!isNIG">
                    <div class="form-group">
                        <div>
                            <label class="control-label"> Village </label>
                            <select class="form-control form-control-sm" formControlName="village_id">
                                <option value="null">Select</option>
                                <option *ngFor="let vlg of villageList" [value]="vlg.id">{{vlg.village_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" >
                    <div class="form-group">
                        <div>
                            <label class="control-label"> Pharmacist/Healthcare Professional Name <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" maxlength="50" formControlName="first_name"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"
                                class="form-control form-control-sm text-uppercase">
                            <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                <div *ngIf="f.first_name.errors.required">Pharmacist/Healthcare Professional Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Address <span class="text-danger">*</span> </label>
                        <div>
                            <textarea rows="2" class="form-control form-control-sm" style="resize: none; height: 100px;"
                                maxlength="150" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32) || (event.charCode == 44) ||
                                (event.charCode == 45)|| (event.charCode == 47)) || (event.charCode >= 48 && event.charCode <= 57)'
                                [ngClass]="{ 'is-invalid': submitted && f.address.errors }" formControlName="address">
                            </textarea>
                            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                <div *ngIf="f.address.errors.required">Address is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" >
                    <div class="form-group">
                        <div>
                            <label class="control-label"> Pharmacist/Healthcare Professional Contact No. <span class="text-danger">*</span> </label>
                            <input type="text" autocomplete="off" maxlength="50" formControlName="mobile"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"
                                class="form-control form-control-sm text-uppercase">
                            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                <div *ngIf="f.mobile.errors.required">Mobile is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Pin Code <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" class="form-control form-control-sm"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && f.pin_code.errors }"
                                maxlength="6" formControlName="pin_code">
                            <div *ngIf="submitted && f.pin_code.errors" class="invalid-feedback">
                                <div *ngIf="f.pin_code.errors.required">Pin Code is required</div>
                                <div *ngIf="f.pin_code.errors.min">Pin Code is required</div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="pharmacyDropdown">
                    <div class="form-group">
                        <label class="control-label"> Preferred Pharmacy </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="preferred_pharmacy_id">
                                <option value="null">Select</option>
                                <option *ngFor="let phrm of pharmacyList" [value]="phrm.id">{{phrm.pharmacy_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3" *ngIf="labDropdown">
                    <div class="form-group">
                        <label class="control-label"> Preferred Lab </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="preferred_lab_id">
                                <option value="null">Select</option>
                                <option *ngFor="let lab of labList" [value]="lab.id">{{lab.lab_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Contact Person Name <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" class="form-control form-control-sm text-uppercase"
                                maxlength="50" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" formControlName="last_name">
                                <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                    <div *ngIf="f.last_name.errors.required">Contact Person Name is required</div>
                                </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Contact Person Mobile No. <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" class="form-control form-control-sm"
                                [pattern]="mobPtrn" formControlName="last_name"
                                [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                            <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                <div *ngIf="f.last_name.errors.required">
                                    Contact Person Mobile No. is required
                                </div>
                                <!-- <div *ngIf="f.contact_person_mobile.errors.pattern">
                                    Contact Person No. {{mobErrMsg}}
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Whatsapp Number</label>
                        <div>
                            <input type="text" autocomplete="off" class="form-control form-control-sm"
                                [pattern]="mobPtrn" formControlName="last_name"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                            <!-- <div *ngIf="submitted && f.whatsapp_number.errors" class="invalid-feedback">
                                <div *ngIf="f.whatsapp_number.errors.required">Mobile Number should contain 10 digits</div>
                                <div *ngIf="f.whatsapp_number.errors.pattern">Lab Whatsapp Number {{mobErrMsg}}</div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Email <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" maxlength="30" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" formControlName="email">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.required">Email is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Bank Account Number </label>
                        <div>
                            <input type="text" autocomplete="off" class="form-control form-control-sm"
                                maxlength="20" formControlName="bank_account_number"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Bank Name </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="last_name">
                                <option value="">Select</option>
                                <option *ngFor="let bank of bankList" [value]="bank.id">{{bank.bank_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Branch Location </label>
                        <div>
                            <input type="text" autocomplete="off" class="form-control form-control-sm"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32))'
                                maxlength="50" formControlName="branch_name">
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> IFSC Code </label>
                        <div>
                            <input type="text" autocomplete="off" class="form-control form-control-sm"
                                maxlength="11" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122) || (event.charCode == 32)) ||
                                    (event.charCode >= 48 && event.charCode <= 57)' formControlName="ifsc_code">
                            <!-- <div *ngIf="submitted && doctorForm.get('ifsc_code').invalid" class="invalid-feedback">
                                <div *ngIf="doctorForm.get('ifsc_code').errors.required">IFSC Code is
                                    required</div>
                                <div *ngIf="doctorForm.get('ifsc_code').errors.pattern">Enter Valid IFSC, ex:
                                    SBIN0001050</div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Is HeadOffice</label>
                        <div>
                            <input type="checkbox">
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label"> Status <span class="text-danger">*</span> </label>
                        <div>
                            <input type="radio" formControlName="is_active" [value]="true">Active
                            <input type="radio" formControlName="is_active" [value]="false">In Active
                        </div>
                    </div>
                </div>
                <div class="col-xl-12">
                    <div class="table-responsive">
                        <table class="table table-bordered table-sm">
                            <tbody>
                                <tr>
                                    <td>
                                        <input type="file" class="d-none" id="aadhaar" #aAdhar
                                            formControlName="aadharFile" (change)="onFileUpload($event,6,'12')"
                                            [ngClass]="{'is-invalid': submitted && f.aadharFile.invalid }">
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="aAdhar.click()"> {{isSAGE ? 'National ID' : 'Aadhaar'}} </p>
                                        <span class="text-danger">*</span> <p class="small mb-1">jpg or pdf format only</p>
                                        <div *ngIf="submitted && f.aadharFile.errors" class="invalid-feedback">
                                            <div *ngIf="f.aadharFile.errors.required">{{isSAGE ? 'National ID' : 'Aadhaar'}} is required</div>
                                        </div>
                                    </td>
                                    <td>
                                        <input type="file" class="d-none" id="digitalCertificate" #digitalCertificate
                                            formControlName="digitalFile" (change)="onFileUpload($event,1,'8','digitalFile')"
                                            [ngClass]="{'is-invalid': submitted && f.digitalFile.invalid }">
                                        <p class="btn badge badge-success badge-pill text-white"
                                            (click)="digitalCertificate.click()">Digital Signature</p>
                                        <span class="text-danger">*</span> <p class="small mb-1">Png format only</p>
                                        <div *ngIf="submitted && f.digitalFile.errors" class="invalid-feedback">
                                            <div *ngIf="f.digitalFile.errors.required">Digital Signature is required</div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill" title="Cancel File">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span> <a href="javascript:void(0)"></a>
                                    </td>
                                    <td>
                                        <span>
                                            <a class="badge badge-danger badge-pill" title="Cancel File">
                                                <i class="fa fa-trash text-white"></i></a>&nbsp;
                                        </span> <a href="javascript:void(0)"></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <input type="submit" id="btnsubmit" style="display: none;" (click)="onSubmit($event)">
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" [disabled]="sbmtDsbld?true:null" class="btn btn-sm btn-primary" (click)="submitForm()"
        value="Submit">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>