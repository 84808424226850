
<div class="row">
    <div class="container-fluid pl-0 pr-0">
      <div class="row">
        <div class="col-xl-12">
          <div class="card card-header-actions mx-auto">
            <div class="card-header">
            Diagnostics Report
              <!-- <button class="btn btn-blue btn-icon float-right"
                            (click)="modalTitle='Doctor Registration Details';openDoctorModal()">
  
                            <i class="fa fa-plus"></i>
                        </button> -->
            </div>
  
            <div class="card-body">
              <form [formGroup]="diagnosticsForm" (ngSubmit)="LoadData()">
                <div class="row ">
                  <div class="container">
                    <div class="row ">
                      <div class='col-md-3'>
                        <div class="form-group">
                          <div class='date' id='datetimepicker6'>
                            <label class="control-label">From Date <span class="text-danger">*</span> </label>
                            <div>
                              <input id="from_date" name="from_date" type="text" autocomplete="off" onpaste="return false"
                                placeholder="From" bsDatepicker #dp="bsDatepicker" (focus)="dp.show()" (focus)="dp1.hide()"
                                formControlName="from_date" [maxDate]="max_date"
                                (bsValueChange)="onFromDateChange($event)" onkeypress='return event.charCode == 8'
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                                [ngClass]="{ 'is-invalid': submitted && f.from_date.errors }"
                                class="form-control form-control-sm">
                              <div *ngIf="submitted && f.from_date.errors" class="invalid-feedback">
                                <div *ngIf="f.from_date.errors.required">From Date is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='col-md-3'>
                        <div class="form-group">
                          <div class='date' id='datetimepicker7'>
                            <label class="control-label">To Date <span class="text-danger">*</span> </label>
                            <div>
                              <input id="to_date" name="to_date" type="text" autocomplete="off" onpaste="return false"
                                placeholder="To" bsDatepicker formControlName="to_date"  [minDate]="f.from_date.value" (focus)="dp.hide()"
                                bsDatepicker #dp1="bsDatepicker" (focus)="dp1.show()" (bsValueChange)="onFromDateChange($event)"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                                [ngClass]="{ 'is-invalid': submitted && f.to_date.errors }"
                                class="form-control form-control-sm" onkeypress='return event.charCode == 8'>
                              <div *ngIf="submitted && f.to_date.errors" class="invalid-feedback">
                                <div *ngIf="f.to_date.errors.required">To Date is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='col-md-3' style="margin-top: 2rem;"  >
                        <button type="submit" class="btn btn-sm btn-primary" value="Submit" (focus)="dp1.hide()">Submit</button>&nbsp;&nbsp;
                        <!-- <button type="button" class="btn btn-sm btn-danger" (click)="clear()">Clear</button> -->
                      </div>
                      <div class="col-md-3" style="margin-top: 2rem;" *ngIf = "showExport">
                        <button type="button" class="btn  btn-sm btn-secondary  text-center"(click)="exportexcel()" > <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                      </div>
                    </div>
                  </div>
                </div>
              </form>
  
              <div class="table-responsive">
                <table datatable id="diagnosticReport" class="table table-striped table-bordered table-sm" style="width:100%">
                  <thead class="text-center">
                    <tr>
                      <th class="singleLine">S No </th>
                      <th class="singleLine">ANM Name</th>
                      <th class="singleLine">State</th>
                      <th class="singleLine">Ditrict</th>
                      <th class="singleLine">NandGhar</th>
                      <th class="singleLine">Date</th>
                      <th class="singleLine">ECG</th>
                      <th class="singleLine">Pregnancy Test</th>
                      <th class="singleLine">Urine Routine Test</th>
                      <th class="singleLine">Sugar Test</th>
                      <th class="singleLine">Other Tests</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of diagnosticlist;let i= index;">
                      <td class="singleLine text-center">{{i+1}}</td>
                      <td class="singleLine">{{item.agent_name}}</td>
                      <td class="singleLine">{{item.statename}}</td>
                      <td class="singleLine text-uppercase">{{item.districtname}}</td>
                      <td class="singleLine">{{item.nandghar}}</td>
                      <td style="width: 15%;">{{item.date}}</td>
                      <td class="text-center" >{{item.ecg}}</td>
                      <td class="text-center" >{{item.pregnancy}}</td>
                      <td class="singleLine text-center">{{item.urine}}</td>
                      <td class="singleLine text-center">{{item.sugar}}</td>
                      <td class="singleLine text-center">{{item.others}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
  
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </div>
