import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

const httpOptions = { 
    headers: new HttpHeaders({ 'Content-Type': 'application/json',
    //sushma 02-08-2022 cors error resolve
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods":"DELETE, POST, GET, OPTIONS",
    "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
    },
    ) 
};

@Injectable({ providedIn: 'root' })

export class ApiService {
    
    constructor(private http: HttpClient) { }

    get(url: string) {
        return this.http.get(url, httpOptions);
    }
    
    get2(url: string) {
        return this.http.get(url, httpOptions);
    }

    post(url: string, postData: Object) {
        return this.http.post(url, postData);
    }

    post2(url: string, postData: Object) {
        return this.http.post(url, postData, httpOptions);
    }

    post3(url: string, postData: Object, httpOptns: any) {
        return this.http.post(url, postData, httpOptns);
    }

    put(url: string, postData: Object) {
        return this.http.put(url, postData);
    }

    patch(url: string, postData: Object) {
        return this.http.patch(url, postData);
    }

    delete(url: string) {
        return this.http.delete(url);
    }

}