import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { ToastrService } from 'ngx-toastr';
import { FileService } from 'src/app/shared/services/file.service';
import { Common } from 'src/app/common';
import { forkJoin } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImagePreviewComponent } from '../../modals/image-preview/image-preview.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-sms-smtp',
  templateUrl: './sms-smtp.component.html',
  styleUrls: ['./sms-smtp.component.css']
})

export class SMSSMTPComponent extends BaseComponent implements OnInit {

  lstGrid = []; hospitalList = []; organizationList = []; imageList = [];
  uploadForm: FormGroup;
  imageForm: FormGroup;
  image: any;
  submitted: boolean = false;
  logoUrl = ''; wmUrl = ''; bannerUrl = '';
  document_path: string

  uploadFileList: Array<{ id: number, reg_id: number, name: string, File: any, extensions: string, file_name: string, fileType: string, }> = []
  uploads: boolean = true;
  mobile: boolean = true;
  payment: boolean = true;
  sms: boolean = true;
  smtp: boolean = true;

  constructor(private regService: RegistrationService
    , private toastr: ToastrService
    , private fb: FormBuilder
    , private registrationService: RegistrationService
    , private fileService: FileService
    , private userServices: UserService
    , private modalService: NgbModal
    , private authSvc: AuthenticationService) {
    super();
  }

  ngOnInit(): void {
    this.authSvc.isShowGlblOrg.next(false), this.authSvc.tenantId = sessionStorage.getItem('defCrntTnnt')
    if (this.authSvc.roleId == 1) {
      sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt'));
    }
    this.initFormControls();
    this.defaultLocations();
    this.LoadOrganizations();
    this.getAllImages();
  }

  initFormControls() {
    // this.uploadForm = this.fb.group({
    //   id:[0],
    //   orgnization_id: ['',Validators.required],
    //   weborganisationlogo:['',Validators.required],
    //   webwatermarklogo:[''],
    //   webloginlogo:[''],
    //   webheaderlogo:[''],
    //   webprescriptionlogo:[''],
    //   mobileapplogo:[''],
    //   mobileorganisationbanner:[''],
    //   mobilebackgroundimage:[''],
    //   mobilelogoimage:[''],
    // });

    // logoFile: [''],
    // wmFile: [''],
    // bgFile:[''],
    // bannerFile: [''],
    // prescriptionFile:[''],

    // organizationBanner[''],?
    // backgrounddImage[''],
    // loginImage[''],
    // mobilelogos: this.fb.group({ 
    //   bgFile:[''],
    //   appLogo:[''],
    //   orgbanner:[''],
    //   backgrdImage:[''],
    //   lgnImage:[''],
    // }),
    this.uploadForm = this.fb.group({
      id: [0],
      orgnization_id: ['', Validators.required],
      weborganisationlogo: ['', Validators.required],
      webwatermarklogo: ['', Validators.required],
      webloginlogo: ['', Validators.required],
      webheaderlogo: ['', Validators.required],
      webprescriptionlogo: ['', Validators.required],
      mobileapplogo: ['', Validators.required],
      mobileorganisationbanner: ['', Validators.required],
      mobilebackgroundimage: ['', Validators.required],
      mobilelogoimage: ['', Validators.required],
      //  orgnization_id:['',Validators.required],
      payment_gateway: this.fb.group({}),
      sms_gateway: this.fb.group({
        user: [''],
        password: [''],
        senderid: [''],
        securekey: [''],
        gateway: [''],
      }),
      smtp_gateway: this.fb.group({
        host: [''],
        port: [''],
        username: [''],
        password: [''],
        encryption: ['']
      }),
    })


  }

  onAdd() {
    this.initFormControls();
    this.logoUrl = this.wmUrl = this.bannerUrl = '';
    this.submitted = false;
  }

  get f() { return this.uploadForm.controls }

  defaultLocations() {
    ['weborganisationlogo', 'webwatermarklogo', 'webloginlogo', 'webheaderlogo', 'webprescriptionlogo', 'mobileapplogo', 'mobileorganisationbanner', 'mobilebackgroundimage', 'mobilelogoimage'].forEach((element, ind) => this.uploadFileList.push({ id: 0, reg_id: ind, name: element, File: null, file_name: '', extensions: '', fileType: '' }));
    ['png,jpg,jpeg', 'png,jpg,jpeg', 'png,jpg,jpeg', 'png,jpg,jpeg', 'png,jpg,jpeg', 'png,jpg,jpeg', 'png,jpg,jpeg', 'png,jpg,jpeg', 'png,jpg,jpeg'].forEach((item, i) => this.uploadFileList[i].extensions = item);

  }

  LoadGridData() {
    this.regService.getAll('').subscribe(res => {
      if (res.status === "OK") {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Upload List');
        else
          this.lstGrid = res.data;
        Common.loadDataTable(this.lstGrid, '#uploadTable');
      }
    });
  }

  singleFileUpload(event: any, fileNumber: number, fileType: string, wdth: number = 0, hght: number = 0) {
    let fileExt: string = this.uploadFileList[fileNumber].extensions;
    // Check if any file is selected.
    if (event.target.files.length > 0) {
      const fileList = event.target, fsize = fileList.files.item(0).size, currentfile = Math.round((fsize / (1024 * 5))), eFile = fileList.files[0];
      // Checking type of file
      if (eFile) {
        const fileExtension: string = eFile.name.split('.').pop().toLowerCase();
        fileExt = fileExtension;
        if (!this.uploadFileList[fileNumber].extensions.split(',').includes(fileExtension)) {
          this.toastr.warning(`Please upload JPEG or JPG or PNG  files only`);
          return false;
        }
        // if (fileList[currentfile]){
        //   this.toastr.error('plz select another file')
        //   return false
        // }
        if (currentfile >= (1024 * 5)) {
          this.toastr.error(eFile.name + ' too Big, please select a file less than 5MB', 'Files');
          return;
        }
        if (wdth > 0 && hght > 0) {
          var eImg = new Image();
          eImg.src = window.URL.createObjectURL(eFile);
          eImg.onload = function() { sessionStorage.setItem('picDmnsns', `${eImg.naturalWidth}:${eImg.naturalHeight}`); };
          setTimeout(() => {
            if (sessionStorage.getItem('picDmnsns') != null) {
              let picDtls = sessionStorage.getItem('picDmnsns').split(':');
              if (+picDtls[0] > wdth || +picDtls[1] > hght)
                this.toastr.warning(`File dimensions should not exceed ${wdth} (w) & ${hght} (h)`);
              else {
                this.dataUrlofImage(fileList, fileNumber);
                this.uploadFileList[fileNumber].File = fileList.files.item(0);
                this.uploadFileList[fileNumber].file_name = eFile.name;
                this.uploadFileList[fileNumber].fileType = fileType;
              }
            }
          }, 100);
        }
        else {
          this.toastr.error('Invalid file');
          return;
        }
      }
      // The size of the file.
      /* if (currentfile >= (1024 * 5)) {
        this.toastr.error(eFile.name + ' too Big, please select a file less than 5MB', 'Files');
        return;
      }
      else {
        this.dataUrlofImage(fileList, fileNumber);
        this.uploadFileList[fileNumber].File = fileList.files.item(0);
        this.uploadFileList[fileNumber].file_name = eFile.name;
        this.uploadFileList[fileNumber].fileType = fileType;
      } */

      // if(this.uploadForm.value.orgnization_id){
      //  if(event.target.files.length) {

      //  }
      //   if(this.uploadForm.value.orgnization_id!=undefined || this.uploadForm.value.orgnization_id!=null){

      //     this.saveFile(this.uploadForm.value.orgnization_id)
      //     this.toastr.success('file upload successfully')  

      //   } 
      // } else{
      //   return this.toastr.warning(`Please select Orgainization`)
      // }

    }
  }

  saveFile(id: string) {
    let sendAPIfiles = [];
    this.uploadFileList.forEach(item => {
      if (item.File)

        sendAPIfiles.push(this.fileService.uploadOrgFile(Common.form_Data(item.id.toString(), item.File, id, 'ORGANIATION', item.fileType)));


    });

    forkJoin([...sendAPIfiles]).subscribe(res => console.log, err => console.log);
    // this.uploadFileList.forEach(each=>{if(each.File){each.File=null}else{}})
  }

  onSubmit() {
    this.submitted = true;
    if (this.uploadForm.invalid) {
      this.toastr.warning('Please select  all Mandatory Fields');
      return false;
    }
    else {
      let payload = this.uploadForm.getRawValue();
      ["weborganisationlogo", "webwatermarklogo", "webloginlogo", "webheaderlogo", "webprescriptionlogo", "mobileapplogo", "mobileorganisationbanner", "mobilebackgroundimage", "mobilelogoimage"].forEach(el => { delete payload[el]; })
      this.userServices.save('organization/setting', payload).subscribe(res => {
        this.saveFile(this.uploadForm.value.orgnization_id);
        this.toastr.success('Saved Successfully');
        // this.initFormControls();
        this.defaultLocations();
        this.LoadOrganizations();
        this.uploadFileList.forEach(each => { each.File = '' })
      },
        err => this.toastr.error(err.message));
    }
  }

  dataUrlofImage(inputValue: any, fileNumber) {
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();

    myReader.onloadend = (e) => {
      this.image = myReader.result;

      // let image_type = {
      //   '0': () => this.logoUrl = this.image,
      //   '1': () => this.wmUrl = this.image,
      //   '2': () => this.bannerUrl = this.image,

      // };
      // image_type[fileNumber]();
    }
    myReader.readAsDataURL(file);
  }

  LoadOrganizations() {
    this.userServices.getAll('organization/getorganizationrecords').subscribe(res => {
      if (res.status === 'OK') {
        this.organizationList = res.data;
      }
    });
  }

  getAllImages() {
    this.userServices.getAll('orgnizationimage/getall').subscribe(res => {
      if (res.status === 'OK') {
        this.imageList = res.data;
      }
    });
  }

  organizationsOnChange(id: any) {
    this.logoUrl = this.wmUrl = this.bannerUrl = '';
    this.initFormControls();
    this.uploadForm.patchValue({ orgnization_id: id });
    this.userServices.getById(`organization/getorganizationsettingbyorgid?orgId=${+id}`).subscribe(res => {
      let response = res.data;
      if (response) {
        this.uploadForm.patchValue(response);

        let [imglogourl, imgwmurl, imgbannerurl] = ['', '', ''];
        imglogourl = response.image_files.filter(f => f.image_type.id == 1)[0]?.image_path;
        imgwmurl = response.image_files.filter(f => f.image_type.id == 2)[0]?.image_path;
        imgbannerurl = response.image_files.filter(f => f.image_type.id == 3)[0]?.image_path;
        //  imgbagurl = response.image_files.filter(f => f.image_type.id == 3)[0]?.image_path;
        //  imgpresurl = response.image_files.filter(f => f.image_type.id == 3)[0]?.image_path;

        this.logoUrl = imglogourl ? `${this.regService.orgFilePath}${response.orgnization_id}/logo/${imglogourl}` : '';
        this.wmUrl = imgwmurl ? `${this.regService.orgFilePath}${response.orgnization_id}/watermark/${imgwmurl}` : '';
        this.bannerUrl = imgbannerurl ? `${this.regService.orgFilePath}${response.orgnization_id}/banner/${imgbannerurl}` : '';
        //  this.presUrl = imgpresurl ? `${this.regService.orgFilePath}${response.orgnization_id}/prescreption/${imgpresurl}` : '';
        //  this.bagUrl = imgbagurl ? `${this.regService.orgFilePath}${response.orgnization_id}/background/${imgbagurl}` : '';
      }
    },
      err => { this.toastr.error(err.message) });
  }

  submitForm() {
    document.getElementById('btnsubmit').click();
  }

  cancelFile(ind: number) {
    let view_File = this.uploadFileList[ind - 1];
    view_File.File = null;
    view_File.file_name = '';
  }

  viewFile(ind: number) {
    let view_File = this.uploadFileList[ind - 1], imagePath = '';
    // const doctor_id = +this.uploadForm.get('id').value;
    const fileExtension: string = view_File.file_name.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'bmp', 'png'].includes(fileExtension)) {
      const modalRef = this.modalService.open(ImagePreviewComponent, { size: 'lg', scrollable: true });
      if (view_File.File) {
        const reader = new FileReader();
        reader.readAsDataURL(view_File.File); // read file as data url
        // called once readAsDataURL is completed
        reader.onload = (event1: any) => {
          imagePath = event1.target.result;
          modalRef.componentInstance.image = imagePath;
        };
      }
      // else {
      //   imagePath = `${this.registrationService.viewFilePath}DOCTOR/${doctor_id}/${view_File.name}/${view_File.document_path}`;
      //   modalRef.componentInstance.image = imagePath;
      // }
    }
  }

}