import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';



@Injectable({ providedIn: 'root' })
export class NotificationService {
    notificationApi: string = '';
    loginAPI: string = '';
    logTnntId: number = null;
    // TENANT_ID: string = '';
    headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });

    constructor(private api: ApiService,private authSvc: AuthenticationService) {
        this.notificationApi = environment.notificationApi;
        this.loginAPI = environment.loginAPI;
        // this.TENANT_ID = sessionStorage.getItem('currentTenant')
        authSvc.tnntSbjct.subscribe(s => this.logTnntId = s);
        // console.log('log tnntId', this.logTnntId);
    }
    
    // sendNotification(data: any): Observable<any> { return this.api.post(`${this.notificationApi}sendnotification?TENANT_ID=${this.authSvc.tenantId}`, data); }
    sendNotification(data: any): Observable<any> { 
        let oldTntId: any = this.authSvc.tenantId == null ? this.logTnntId : this.authSvc.tenantId, tntId: any = oldTntId == null ? sessionStorage.getItem('TENANT_ID') : oldTntId;
        if (tntId != null)
        return this.api.post2(`${this.notificationApi}sendnotification?TENANT_ID=${tntId}`, data); }
    // validateOTP(data: any): Observable<any> { return this.api.post2(`${this.loginAPI}validateOTP`, data); }
    validateOTP(data: any, tnt: any): Observable<any> { return this.api.post2(`${this.notificationApi}validateOTP${tnt}`, data); }
    // send_OTP_Notification(queryString: string): Observable<any> { return this.api.get(`${this.loginAPI}sendotpnotification?${queryString}`); }
    send_OTP_Notification(queryString: string): Observable<any> { return this.api.get(`${this.notificationApi}sendotpnotification?${queryString}`); }

    // updateByTENANT_ID(url: string): string {
    //     let id = JSON.parse(sessionStorage.getItem('TENANT_ID'));
    //     const ind = url.includes('?') ? url.indexOf('?') : -1;
    //     this.TENANT_ID = id;
    //     // console.log('Index:=', ind);
    //     if (ind > -1)
    //         return url.replace('?', `?TENANT_ID=${this.TENANT_ID}&`);
    //     else
    //         return `${url}?TENANT_ID=${this.TENANT_ID}`;
    // }
}