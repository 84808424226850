<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="serviceForm" (ngSubmit)="onSubmit($event)">
            <div class="row">
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="control-label">Type of Service <span class="text-danger">*</span> </label>
                        <input type="text" formControlName="service_name" id="service_name" autocomplete="off" 
                            class="form-control form-control-sm" pattern="^(?!-)(?!.*--)[A-Za-z0-9-A Z ]+(?!-)$"
                            [ngClass]="{ 'is-invalid': submitted && f.service_name.errors }" (onchange)="onchange($event.target.value)"
                            placeholder="Type of Service" (keyup)="onKey1Up($event.target.value)"
                            onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97
                        && event.charCode <= 122) || (event.charCode == 32) || (event.charCode == 45)) || (event.charCode >= 48 && event.charCode <= 57)' minlength="5" maxlength="20">
                        <div *ngIf="submitted && f.service_name.errors" class="invalid-feedback">
                            <div *ngIf="f.service_name.errors?.required">Type of Service is required</div>
                            <div *ngIf="f.service_name.errors.minlength">Minlength of Type of Service is 5</div>
                            <div *ngIf="f.service_name.errors.maxlength">Maxlength of Type of Service is 20</div>
                            <div *ngIf="f.service_name.errors.pattern">Allows only one ('-')</div>
                        </div>
                        <div *ngIf="submitted && istmpltError" class="invalid-feedback">
                            {{ErrorMsg}}
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <div class="form-group">
                        <label class="control-label">Cost <span class="text-danger">*</span> </label>
                        <input type="text" formControlName="price" id="price" (keyup)="onKeyUp($event.target.value)"
                            autocomplete="off" class="form-control form-control-sm"
                            (change)="onKeyUp($event.target.value)" maxlength="5"
                            onkeypress='return ((event.charCode >= 48 && event.charCode <= 57) || event.charCode == 46)'
                            [ngClass]="{ 'is-invalid': submitted && f.price.errors || errMsg}" placeholder="Cost">
                        <div *ngIf="submitted && f.price.errors" class="invalid-feedback">
                            <div *ngIf="f.price.errors.required">Cost is required</div>
                            <div *ngIf="f.price.errors.min">Min. Cost is 1</div>
                            <div *ngIf="f.price.errors.max">Max. Cost 1000</div>
                        </div>
                        <div *ngIf="errMsg" class="invalid-feedback">
                            <div> Enter Valid Decimal format. Eg.: 10.5 or 100.1 </div>
                        </div>
                    </div>
                </div>
            </div>
            <input type="submit" id="btnsubmit" style="display: none;">
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" (click)="submitForm()" value="Submit">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>