import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MasterService } from 'src/app/shared/services/master.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-role',
  templateUrl: './role.component.html',
  styleUrls: ['./role.component.css']
})
export class RoleComponent implements OnInit {
  @Input() title: string;  
  @Input() roleData: any;  

  id: number = 0;
  roleName: string = '';
  roleForm : FormGroup;
  submitted: boolean = false;

  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private formBuilder: FormBuilder
    , private masterService: MasterService
    , private userService: UserService) { }

  ngOnInit(): void {
    this.initFormControls(), this.setFocus('roleName');
    if (this.roleData)
      this.roleForm.patchValue(this.roleData)
  }

  initFormControls() {
    this.roleForm = this.formBuilder.group({
      id : [0],
      userType : ['', Validators.required],
      active:[true]
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.roleForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields', 'Role');
      return;
    }
    let sendData = this.roleForm.getRawValue();
    this.userService.save('user/save_role', sendData).subscribe(res => {
      this.toastr.success(`Record ${sendData.id == 0 ? 'Submited' : 'Updated'} Successfully`, 'Role'), this.activeModal.close('changes saved');
    }, error => {this.toastr.error(error.message)});
  }

  setFocus(elementName: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementName)));
    el.value = '';
    el.focus();
  }

}