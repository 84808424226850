import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup,ValidationErrors } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import { Subject, pipe } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
declare let $: any;
@Component({
  selector: 'app-mandal',
  templateUrl: './mandal.component.html',
  styleUrls: ['./mandal.component.css']
})
export class MandalComponent implements OnInit {

  @Input() editData: any;
  mandalForm: FormGroup; 
   submitted = false;
  modalTitle: string = '';
  dtOptions: any = {};
  dtTrigger: any = new Subject();
  mandallist:any;
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = []
  mandalList: Array<any> = [];

  constructor(
    public activeModal: NgbActiveModal,
    config: NgbModalConfig, 
      private modalService: NgbModal,
      private toastr: ToastrService,
      private masterService: MasterService,
      private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.locationChanged('', 'state');
  this.initFormControls()
  this.getFormValidationErrors()

  if (this.editData) {
      
    this.mandalForm.patchValue(this.editData);
    let [data,] = [this.editData, ''];
    this.mandalForm.patchValue(data);
    this.mandalForm.patchValue({
      state_id: data.state_id,
      district_id: data.district_id,
      mandal_name:data.mandal_name
    });
  }


//  if (this.mandalData) {
//       Promise.all([this.locationChanged(this.mandalData.state_id, 'district')])
//         .then(() => {
//           this.mandalForm.patchValue(this.mandalData);
//         });
//     }

  }
            get f() { return this.mandalForm.controls; }

  initFormControls() {
    this.mandalForm = this.formBuilder.group({
      id:['0 '],
      state_id: ['', Validators.required],
      district_id: ['', Validators.required],
      mandal_name: ['', Validators.required],
    });
  }
  openMandalModal() {
    const modalRef = this.modalService.open(MandalComponent);
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.result.then(result => {
      // here call Table load API
      // alert('Success1');
    }, (reason) => { });
  }

  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        this.mandalList = [];
        this.mandalForm.get('district_id').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
  
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      },
      'mandal': () => {
        this.mandalList = [];
   
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          
        };
        assign_locations[type]();
      }
      this.districtList.sort((a,b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error));
  } 



onSubmit(event: any) {
  this.submitted = true;
    this.getFormValidationErrors();
    if (this.mandalForm.invalid)
      return;
    else {
            const sendData = JSON.parse(JSON.stringify(this.mandalForm.getRawValue()));  
      // delete sendData.id;
      this.masterService.save('saveandupdatemandal', JSON.stringify(sendData)).subscribe(
        res => {      
          let apiResponse = res;
          if (apiResponse.status === "OK") {
            this.toastr.success(res.message, 'Mandal');
            this.activeModal.close();
            this.initFormControls();
           
          }
    }, error => this.toastr.error(error));
  }
}
  getFormValidationErrors() {
    Object.keys(this.mandalForm.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.mandalForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
  restFormValues() {
    this.initFormControls();
  }
  submitDataForm() {
    document.getElementById('btnsubmit').click();
  }
}
