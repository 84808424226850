<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Organization Details
                        <button class="btn btn-blue btn-icon float-right"
                            (click)="modalTitle='Organization Detail';openModal()"
                            *ngIf="setPrivilege(Screen_Name.Orgnization, Permission.Add)">
                            <i class="fa fa-plus"></i>
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="col-md-9 mb-3" style="margin-top: 2rem;" >
                            <button type="button" class="btn  btn-sm btn-secondary float-right" (click)="exportexcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                        </div>
                        <div class="table-responsive">
                            <table datatable id="organizationTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>S No </th>
                                        <th>Orgnization</th>
                                        <th>Support Contact No</th>
                                        <th>Tenant Code</th>
                                        <th>License Plan</th>
                                        <th>Administrator Email</th>
                                        <th>Refund Email</th>
                                        <th>Support Email</th>
                                        <th>Is Configured</th>
                                        <th>Status</th>
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of organizationList;let i = index;">
                                        <td style="width: 8%;" class="text-center">{{i+1}}</td>
                                        <td class="text-uppercase">{{item.orgnization_name}}</td>
                                        <td style="width: 12%;">{{item.primary_contact_no}}</td>
                                        <td>{{item.id}}</td>
                                        <td>{{item?.license_plan?.license_plan}}</td>
                                        <td>{{item.email}}</td>
                                        <td>{{item.refund_email}}</td>
                                        <td>{{item.support_email}}</td>
                                        <td>{{item.configered === true ? 'Yes' : 'No'}}</td>
                                        <!-- <td>{{item.is_active}}</td> -->
                                        <td>{{(item.is_active===true)?'Active':'InActive'}}
                                        <td>
                                            <div (click)="modalTitle='Organization Detail';openModal(item)" class="badge badge-info pointer"
                                                *ngIf="setPrivilege(Screen_Name.Orgnization, Permission.Edit)"> <i class="fa fa-edit text-white"></i>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>