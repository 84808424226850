import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/shared/services/user.service';
import { ToastrService } from 'ngx-toastr';
import { Common } from 'src/app/common';
import { BaseComponent } from 'src/app/shared/base.component';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
declare let $: any;


@Component({
  selector: 'app-stock-management',
  templateUrl: './stock-management.component.html',
  styleUrls: ['./stock-management.component.css']
})
export class StockManagementComponent implements OnInit {
  isShow = true;
  companyList: any;
  drugsList: any;
  OrgId: string;
  receivedDt:any
  userId:any;
  agentID:any;
  viewStock:any;
  availableStck:any;
  receivedStock = null;
  remarks ='';drugName = ''
  constructor(private userservice: UserService
    ,private toastr: ToastrService
    ,private regService: RegistrationService
    , private datePipe:DatePipe
   , private router:Router ) {
      this.userId = sessionStorage.getItem('agentUserId');
   }

  ngOnInit(): void {
    this.getagentdata();
    setTimeout(() => {
      this.getdrugList();
    },300);

  }
 
  getdrugList() {
    this.receivedDt = this.datePipe.transform(new Date(),'yyyy-MM-dd');
    let payLoad ={"agentId":this.agentID,"receviedDate":this.receivedDt}

    this.regService.getDrugsList(payLoad).subscribe(res => {
      if (res.data == null || res.data.length == 0) {
        this.drugsList = [];
        this.toastr.info('No records available', 'Drug List');
        Common.loadDataTable(this.drugsList, '#drugsTable');
      }
      else {
        this.drugsList = res.data;
        Common.loadDataTable(this.drugsList, '#drugsTable');
      }
    });
  }


  getcompanyByorgid(orgId: any) {
    
  }
  getviewStock(){
    this.regService.viewStock(this.agentID).subscribe(res => {
      if (res.data == null || res.data.length == 0) {
        this.viewStock = [];
        this.toastr.info('No records available', 'Drugs List');
        Common.loadDataTable(this.viewStock, '#viewTable');
      }
      else {
        this.viewStock = res.data;
        Common.loadDataTable(this.viewStock, '#viewTable');
      }
    });
  }
  getagentdata() {
    this.regService.getagentByuserId(this.userId).subscribe(a => {
      if (a) {
        let res: any = a
        this.agentID = res.data[0].id
        // this.regService.getAgentId(res.data[0].id).subscribe(l => { this.agentID = l; });
      }
    });
  }
  close(){
    $("#myModal").modal("hide");
  }
  updateData(item:any){
this.availableStck = item;
this.drugName = item.drug_name
  }
  updateStock(){
    let payload = [{
      id: this.availableStck.id,
      stock:  this.availableStck.stock,
      created_on:  this.availableStck.created_on,
      drug_mapping_id:  this.availableStck.drug_mapping_id,
      agent_id:  this.availableStck.agent_id,
      is_active:  this.availableStck.is_active,
      manufactured_date:  this.availableStck.manufactured_date,
      expiry_date:  this.availableStck.expiry_date,
      batch_no:  this.availableStck.batch_no,
      bar_code:  this.availableStck.bar_code,
      drug_name:  this.availableStck.drug_name,
      drug_type:  this.availableStck.drug_type,
      material_form_id:  this.availableStck.material_form_id,
      material_id:  this.availableStck.material_id,
      mrp_price: this.availableStck.mrp_price,
      purchase_price:  this.availableStck.purchase_price,
      sale_price:  this.availableStck.sale_price,
      requrest_type: this.availableStck.requrest_type,
      agent_purchase_price:  this.availableStck.agent_purchase_price,
      agent_sale_price:  this.availableStck.agent_sale_price,
       received_stock:this.receivedStock,
       remarks:this.remarks}]
      if(payload[0].received_stock == null){
        this.toastr.error("please enter received stock");
        return;
      }
      if(payload[0].received_stock > payload[0].stock){
        this.toastr.error("Received stock should be lessthan or equal to delivered qty");
        return;
      }
    this.regService.updateStock(payload).subscribe(res => {
      if (res.status == 'OK') {
        this.toastr.info('Pharmacist/Healthcare Professional Drugs Updated Succesfully');
           this.close();
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                this.router.navigate(['home/viewUpdateStock']);
              });
      }
      else {
        this.toastr.error(res.error);
      }
    });
  }
}
