import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { CenterRegistrationFormComponent } from 'src/app/modules/registration/center-registration-form/center-registration-form.component';
import { environment } from 'src/environments/environment';
import { Common } from 'src/app/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { HttpClient } from '@angular/common/http';
declare let $:any;

@Component({
  selector: 'app-drug-dispense',
  templateUrl: './drug-dispense.component.html',
  styleUrls: ['./drug-dispense.component.css']
})
export class DrugDispenseComponent implements OnInit {
  druglist=[];drugname: Array<any> = [];tabledrugList=[];quantity:any;dropdownSettingsdrugs = {};dropdownSettingspatient={};tableshow=false;drugpayload=[];
  agentbenId:any;patientList:any;patientname: Array<any> = [];isShow = true;prvconstdata:any;prvconstlist=false;prvconstprecrplist=[];prvconstprecrplistNew:any;
  getprescribeddrugs:any; drugSettings = {};dT: Array<any> = [];drugTypelist:any;drgtyphow=false;userselecteddrugs:any;
  url='';wopbtn=true;drgnshow=false;
  constructor(config: NgbModalConfig
    , public modalService: NgbModal
    , private regService: RegistrationService
    , private authSvc: AuthenticationService
    , protected toastr: ToastrService
    , private router:Router
    , private formBuilder:FormBuilder
    , private masterService: MasterService
    ,private http: HttpClient) { }

  ngOnInit(): void {
    this.drugSettings = {
      singleSelection: true,
      idField: 'drug_type',
      textField: 'drug_type',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      enableCheckAll: false,
      allowSearchFilter: true
    };
    this.dropdownSettingsdrugs = {
      singleSelection: false,
      idField: 'id',
      textField: 'drug_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableCheckAll:false,
      itemsShowLimit: 4,
      allowSearchFilter: true
    };
    this.dropdownSettingspatient = {
      singleSelection: true,
      idField: 'id',
      textField: 'first_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableCheckAll:false,
      itemsShowLimit: 4,
      allowSearchFilter: true
    };
  
    this.getpatientdata();
    this.getdrugnames();
  }
getdrugnames(){
  this.regService.getagentdrugs().subscribe(res => {
//this.druglist=res.data;
this.drugTypelist=res.data;
  })
}
// drugchange(id:any){
//   this.tabledrugList=[];
//   var data = this.druglist.find(item => item.id == id);
//   this.tabledrugList.push(data)
// }
onItemSelectreferal(list: any){
this.tableshow=true;
this.userselecteddrugs=list;
//this.tabledrugList=[];
  var data = this.druglist.find(item => item.id == list.id);
  for (let i = 0; i < data.length; i++) {
    data[i]['qty'] = '';
   }
  this.tabledrugList.push(data)
}
onItemSelectreferalD(item){
  this.tabledrugList = this.tabledrugList.filter(f => f.id != item.id);
}
dispensesubmit(){
  for (let i = 0; i < this.tabledrugList.length; i++) {
   
    this.drugpayload.push({
      quantity: this.tabledrugList[i].qty,
      drug_name: this.tabledrugList[i].drug_name,
      drug_type:this.tabledrugList[i].drug_type,
      agent_id:sessionStorage.agentId,
      drug_mapping_id:this.tabledrugList[i].drug_mapping_id,
      batch_no:this.tabledrugList[i].batch_no,
      is_active:true,
      survey_id:0
    })
  }
  this.regService.saveagentdrugs(this.drugpayload,this.agentbenId).subscribe(res => {
    if(res.status === 'OK') {
      this.toastr.success(res.message);
      this.getdrugnames();
      (<HTMLInputElement>document.getElementById('qtyv')).value='';
      //this.onItemSelectreferal(this.userselecteddrugs);
      // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      //   this.router.navigate(['/home/drugdispense']);
      // });
    }
  })
}
getpatientdata(){
  this.regService.getagentPatients().subscribe(res => {
this.patientList=res.data;
  })
}
onItemSelectpatient(item:any){
  this.drgtyphow=true;
  this.agentbenId=item.id;
}
onItemSelectpatientD(item:any){
  this.patientname = this.patientname.filter(f => f.id != item.id);
}
onItemSelectpatientwp(item:any){
  this.agentbenId=item.id;
  this.prvconstlist=true;
  this.regService.getprevconsultations(this.agentbenId).subscribe(res => {
    this.prvconstdata=res.data;
      })
}
getconsultationid(contid:any){
  var mrngCount = 0;
    var evngCount = 0;
    var nightCount = 0;
    var anyCount = 0;
    var totalDir = 0;
    var totalCost:any;
  this.regService.getprevconsultationsprescrp(contid).subscribe(res => {
    this.prvconstprecrplist=res.data;
    for (let i = 0; i <  this.prvconstprecrplist.length; i++) {
      this.prvconstprecrplistNew=this.prvconstprecrplist[i].prescribed_medications
    }
    // $("#dspmedlistM").modal("show");
   // console.log(this.prvconstprecrplistNew,'prescrp')
    for (let i = 0; i <  this.drugTypelist.length; i++) {
      this.drugTypelist[i].drug_name=this.drugTypelist[i].drug_name.toUpperCase();
      //  this.prescriptionData.prescribed_medications[i]['cost'] = '';
       for (let j = 0; j < this.prvconstprecrplistNew.length; j++){
        if(this.drugTypelist[i].drug_type==this.prvconstprecrplistNew[j].type){
  if(this.drugTypelist[i].drug_name==this.prvconstprecrplistNew[j].name){
    this.prvconstprecrplistNew[j]['batch_no']=this.drugTypelist[i].batch_no;
    this.prvconstprecrplistNew[j]['drug_mapping_id']=this.drugTypelist[i].drug_mapping_id;
    this.prvconstprecrplistNew[j]['exp_date']=this.drugTypelist[i].expiry_date;
    this.prvconstprecrplistNew[j]['agentStock']=this.drugTypelist[i].stock;
          if(this.prvconstprecrplistNew[j].mrng == true){
            mrngCount = 1
          }
          if(this.prvconstprecrplistNew[j].evng == true){
            evngCount = 1
          }
          if(this.prvconstprecrplistNew[j].night == true){
            nightCount = 1
          }
          if(this.prvconstprecrplistNew[j].aftr == true){
            anyCount = 1
          }
          totalDir = mrngCount+evngCount+nightCount+anyCount
          if(this.prvconstprecrplistNew[j].period=='Days'){
       totalCost=   this.prvconstprecrplistNew[j].count*totalDir;
          }
          if(this.prvconstprecrplistNew[j].period=='Weeks'){

            totalCost=   this.prvconstprecrplistNew[j].count*7*totalDir;
               }
               if(this.prvconstprecrplistNew[j].period=='Months'){
                totalCost=   this.prvconstprecrplistNew[j].count*30*totalDir;
                   }
                   if(this.prvconstprecrplistNew[j].period=='Years'){
                    totalCost=   this.prvconstprecrplistNew[j].count*365*totalDir;
                       }
    this.prvconstprecrplistNew[j]['doctor_qty']=totalCost;
    this.prvconstprecrplistNew[j]['disp_qty']='';
  }
        }else{
          //this.toastr.info("Selected medicine not available at agent")
          this.prvconstprecrplistNew[j]['agentStock']=0;
        }
  
       }
       }
       $("#dspmedlistM").modal("show");
      })
}
getconsultationid2(contid:any){
  this.url='http://tmukqaapi.dhanushinfotech.com/api/VidmedPharmacy/GetMedicincesStockList?case_number='+contid+'&tenentId='+this.authSvc.tenantId;
  this.http.get<any>(this.url).subscribe( res => {
    if(res.Data.length == 0){
//   this.toastr.info('Cost is not available for Selected Drugs')
// this.drugsCostList = [];
    } else{
      this.getprescribeddrugs = res.Data;
      $("#dspmedlistM").modal("show");
    }
      }) 
}
onItemSelectreferaldrug(item:any){
  for (let i = 0; i <  this.druglist.length; i++) {
    
    //  this.prescriptionData.prescribed_medications[i]['cost'] = '';
     for (let j = 0; j < this.prvconstprecrplistNew.length; j++){
      if(this.druglist[i].drug_type==this.prvconstprecrplistNew[j].type){

      }else{
        this.toastr.info("Selected medicine not available at agent")
      }

     }
     }
}
dispensemsubmitown(){
 // console.log(this.getprescribeddrugs,'own')
  for (let i = 0; i < this.prvconstprecrplistNew.length; i++) {
   if(this.prvconstprecrplistNew[i].agentStock !=0){
    this.drugpayload.push({
      quantity: this.prvconstprecrplistNew[i].disp_qty,
      drug_name: this.prvconstprecrplistNew[i].name,
      drug_type:this.prvconstprecrplistNew[i].type,
      agent_id:sessionStorage.agentId,
      drug_mapping_id:this.prvconstprecrplistNew[i].drug_mapping_id,
      batch_no:this.prvconstprecrplistNew[i].batch_no,
      is_active:true,
      survey_id:0
    })
  }
  }
  this.drugpayload;
  this.regService.saveagentdrugs(this.drugpayload,this.agentbenId).subscribe(res => {
    if(res.status === 'OK') {
      this.toastr.success(res.message);
      $("#dspmedlistM").modal("hide");
      // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      //   this.router.navigate(['/home/drugdispense']);
      // });
    }
  })
}
validationcheck(item){
  //this.druglist.forEach(dt => {
if(item.disp_qty > item.doctor_qty ){
  this.toastr.error("Dispensing qty should not exceed prescribed qty")
  return;
}
if(item.disp_qty > item.agentStock ){
  this.toastr.error("Your Entered qty is greaterthan Your available qty.You have"+' '+item.agentStock+ 'only')
}
// if()
 // })
  // this.druglist.forEach(day => {
  //   const dayname = this.prvconstprecrplistNew.find(item => item.type == day.drug_type && item.name == day.drug_name);
  //   if (dayname) {
  //     // const item = { id: dayname.id, language_name: dayname.language_name };
  //     // this.selectedItems_language.push(item);
  //   }
  // });
}
validationcheckwop(item:any){
  this.wopbtn=false;
  if(item.qty>item.stock){
    this.toastr.error("Your Entered qty is greaterthan Your available qty.You have"+' '+item.stock+' '+ 'only');
    this.wopbtn=true;
  }
  if(item.stock > 0 && item.qty==0){
    this.toastr.error("Please Enter valid qty");
    this.wopbtn=true;
  }
}
radioclick2(){
  this.patientname=[];this.drugname=[];this.tableshow=false;
}
radioclick(){
  this.patientname=[];this.prvconstlist=false;
}
onDrgSlct(itemn: any, ctrl: string) {
  this.drugname=[];
  this.tableshow=false;
  this.tabledrugList=[];
  this.druglist=[];
  // this.dropdownSettingsdrugs = {
  //   singleSelection: false,
  //   idField: 'id',
  //   textField: 'drug_name',
  //   selectAllText: 'Select All',
  //   unSelectAllText: 'Deselect All',
  //   enableCheckAll:false,
  //   itemsShowLimit: 4,
  //   allowSearchFilter: true
  // };

//this.druglist = this.drugTypelist.find(item => item.drug_type == itemn.drug_type);
for (let i = 0; i < this.drugTypelist.length; i++) {
  if(this.drugTypelist[i].drug_type==itemn.drug_type){
    this.druglist.push(this.drugTypelist[i])
    this.drgnshow=true;
  }
}
}
onDrgDeSlct(ctrl: string) {
  this.drgnshow=false;
  this.drugname=[];
  this.tableshow=false;
  this.druglist=[];
  this.tabledrugList=[];
}
}
