<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="couponForm" (ngSubmit)="onSubmit($event)">
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Coupon Code <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="coupon_code" id="coupon_code"
                                [ngClass]="{ 'is-invalid': submitted && f.coupon_code.errors }" maxlength="10"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97
                                    && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57))'
                                class="form-control form-control-sm text-uppercase" placeholder="Coupon Code">
                            <div *ngIf="submitted && f.coupon_code.errors" class="invalid-feedback">
                                <div *ngIf="f.coupon_code.errors?.required">Coupon Code is required</div>
                                <!-- <div *ngIf="f.coupon_code.errors.pattern">Coupon Code should be Alpha Numeric</div> -->
                                <div *ngIf="f.coupon_code.errors.minlength">Coupon Code should have 6 characters</div>
                                <div *ngIf="f.coupon_code.errors.maxlength">Coupon Code should have 10 characters</div> <!-- <div *ngIf="f.coupon_code.errors.pattern">Coupon code should be in the following format "DHAN12345"</div> -->
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Apply to <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="coupon_apply_id" id="coupon_apply_id" disabled
                                [ngClass]="{ 'is-invalid': submitted && f.coupon_apply_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option value="1">Consultation</option>
                                <option value="2">Lab</option>
                                <option value="3">Pharmacy</option>
                            </select>
                            <div *ngIf="submitted && f.coupon_apply_id.errors" class="invalid-feedback">
                                <div *ngIf="f.coupon_apply_id.errors.required">Apply To is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <label class="control-label">Is Absolute </label>
                    <div class="form-group">
                        <div>
                            <div>
                                <input type="checkbox" formControlName="is_absolute" [value]="false" name="is_absolute">
                              </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Discount Rate <span class="text-danger"  *ngIf="couponForm.get('is_absolute').value==true">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="discount_flat" id="type"
                                 maxlength="4"  [attr.disabled]="couponForm.get('is_absolute').value==true?null:true"
                                [ngClass]="{ 'is-invalid': submitted && f.discount_flat.errors }"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm" placeholder="Discount Rate">
                            <div *ngIf="submitted && f.discount_flat.errors" class="invalid-feedback">
                                <div *ngIf="f.discount_flat.errors.required">Discount Rate is required</div>
                                <div *ngIf="f.discount_flat.errors.min">Minimum Discount Rate is 100</div>
                                <div *ngIf="f.discount_flat.errors.max">Maximum Discount Rate is 1000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Discount Percentage<span class="text-danger" *ngIf="couponForm.get('is_absolute').value==false">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="discount_percentage"
                                id="discount_percentage" [attr.disabled]="couponForm.get('is_absolute').value==false?null:false"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' minlength="1"
                                maxlength="3" [ngClass]="{ 'is-invalid': submitted && f.discount_percentage.errors }"
                                class="form-control form-control-sm" placeholder="Discount Percentage">
                            
                            <div *ngIf="submitted && f.discount_percentage.errors" class="invalid-feedback">
                                <div *ngIf="f.discount_percentage.errors.required">Discount Percentage is required</div>
                                <div *ngIf="f.discount_percentage.errors.min">Minimum Discount Percentage is 10</div>
                                <div *ngIf="f.discount_percentage.errors.max">Maximum Discount Percentage is 100</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Maximum Discount Amount <span class="text-danger" *ngIf="couponForm.get('is_absolute').value==false">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="max_discount_amt" maxlength="4"
                                id="max_discount_amt" [attr.disabled]="couponForm.get('is_absolute').value==false?null:false"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                [ngClass]="{ 'is-invalid': submitted && f.max_discount_amt.errors }"
                                class="form-control form-control-sm" placeholder="Maximum Discount Amount ">
                            <div *ngIf="submitted && f.max_discount_amt.errors" class="invalid-feedback">
                                <div *ngIf="f.max_discount_amt.errors.required">Maximum Discount Amount is required</div>
                                <div *ngIf="f.max_discount_amt.errors.min">Min. Maximum Discount Percentage is 100</div>
                                <div *ngIf="f.max_discount_amt.errors.max">Max. Maximum Discount Percentage is 1000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <label class="control-label">Number of times per user <span class="text-danger">*</span></label>
                    <div>
                        <input type="text" autocomplete="off" formControlName="max_utilization_per_user" minlength="1"
                            onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' id="max_utilization_per_user"
                            maxlength="4" [ngClass]="{ 'is-invalid': submitted && f.max_utilization_per_user.errors }"
                            class="form-control form-control-sm" placeholder="Number of times per user">
                        <div *ngIf="submitted && f.max_utilization_per_user.errors" class="invalid-feedback">
                            <div *ngIf="f.max_utilization_per_user.errors.required">Number of times per user is required</div>
                            <div *ngIf="f.max_utilization_per_user.errors.min">Minimum Number of times per user is 1</div>
                            <div *ngIf="f.max_utilization_per_user.errors.max">Maximum Number of times per user is {{maxNOTPU}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <label class="control-label">Customer Eligibility </label> <br />
                    <div class="">
                        <div class="custom-control-inline custom-control custom-radio">
                            <input formControlName="is_corporate" [value]="true" class="custom-control-input" 
                                #isCompany id="customRadio11" type="radio" name="is_corporate">
                            <label class="custom-control-label" for="customRadio11">Corporate </label>
                        </div>
                        <div class="custom-control-inline custom-control custom-radio">
                            <input formControlName="is_corporate" [value]="false" class="custom-control-input" 
                                #isCompany id="customRadio22" type="radio" name="is_corporate">
                            <label class="custom-control-label" for="customRadio22">Patients </label>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Company Name <span class="text-danger"
                            *ngIf="couponForm.get('is_corporate').value==true">*</span></label>
                        <div>
                            <select formControlName="orgnization_company_id" id="orgnization_company_id"
                                [attr.disabled]="couponForm.get('is_corporate').value==true?null:true"
                                [ngClass]="{ 'is-invalid': submitted && f.orgnization_company_id.errors }"
                                class="form-control form-control-sm" maxlength="30">
                                <option value="">Select</option>
                                <option *ngFor="let state of companyList" [value]="state.id">{{state.orgnization_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.orgnization_company_id.errors" class="invalid-feedback">
                                <div *ngIf="f.orgnization_company_id.errors.required">Company Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Maximum Utilisation Count<span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="max_utilization_count"
                                id="max_utilization_count" maxlength="4" (change)="onChngMUC($event.target.value)"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                [ngClass]="{ 'is-invalid': submitted && f.max_utilization_count.errors }"
                                class="form-control form-control-sm" placeholder="Max Utilisation Count" />
                            <div *ngIf="submitted && f.max_utilization_count.errors" class="invalid-feedback">
                                <div *ngIf="f.max_utilization_count.errors.required">Maximum Utilisation Count is required</div>
                                <div *ngIf="f.max_utilization_count.errors.min">Min. Maximum Utilisation Count is 1</div>
                                <div *ngIf="f.max_utilization_count.errors.max">Max. Maximum Utilisation Count is 1000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Minimum Consultation amount <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" class="form-control form-control-sm"
                                formControlName="min_payment_applicable" id="min_payment_applicable" maxlength="4"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)' (focus)="dp.hide()"
                                [ngClass]="{ 'is-invalid': submitted && f.min_payment_applicable.errors }"
                                placeholder="Minimum Consultation Amount ">
                            <div *ngIf="submitted && f.min_payment_applicable.errors" class="invalid-feedback">
                                <div *ngIf="f.min_payment_applicable.errors.required">Minimum Consultation amount is required</div>
                                <div *ngIf="f.min_payment_applicable.errors.min">Min. Minimum Consultation amount is 300</div>
                                <div *ngIf="f.min_payment_applicable.errors.max">Max. Minimum Consultation amount is 1000</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Start Date <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="start_date" id="start_date"
                                placeholder="Start Date" bsDatepicker #dp="bsDatepicker" (focus)="dp.show()" 
                                (focus)="dp1.hide()" [minDate]="minDate" (bsValueChange)="onFromDateChange($event)"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                [ngClass]="{ 'is-invalid': submitted && f.start_date.errors }"
                                class="form-control form-control-sm" placeholder="Start Date" />
                            <div *ngIf="submitted && f.start_date.errors" class="invalid-feedback">
                                <div *ngIf="f.start_date.errors.required">Start Date is required </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">End Date <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="expiry_date" id="expiry_date"
                                placeholder="Date of Birth" bsDatepicker [minDate]="minToDate" 
                                #dp1="bsDatepicker" (focus)="dp1.show()" (focus)="dp.hide()"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                [ngClass]="{ 'is-invalid': submitted && f.expiry_date.errors }"
                                class="form-control form-control-sm" placeholder="End Date">
                            <div *ngIf="submitted && f.expiry_date.errors" class="invalid-feedback">
                                <div *ngIf="f.expiry_date.errors.required">End Date is required </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Description <span class="text-danger">*</span></label>
                        <div>
                            <textarea formControlName="coupon_name" id="coupon_name"
                                [ngClass]="{ 'is-invalid': submitted && f.coupon_name.errors }" (focus)="dp1.hide()"
                                class="form-control form-control-sm" rows="2" maxlength="500"></textarea>
                                <!-- onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57)||
                                    (event.charCode == 32 || event.charCode == 46) ||(event.charCode == 46) || (event.charCode == 44))' -->
                            <div *ngIf="submitted && f.coupon_name.errors" class="invalid-feedback">
                                <div *ngIf="f.coupon_name.errors.required">Description is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-xl-3">
                    <label class="control-label">Status </label>
                    <br />
                    <div class="">
                        <div class="custom-control-inline custom-control custom-radio">
                            <input formControlName="is_active" [value]="true" class="custom-control-input"
                                id="customRadio11" type="radio" name="is_active">
                            <label class="custom-control-label" for="customRadio11">Active </label>
                        </div>
                        <div class="custom-control-inline custom-control custom-radio">
                            <input formControlName="is_active" [value]="false" class="custom-control-input "
                                id="customRadio22" type="radio" name="is_active">
                            <label class="custom-control-label" for="customRadio22">In Active </label>
                        </div>
                    </div>
                </div> -->
            </div>
            <input type="submit" id="btnsubmit" style="display: none;">
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" (click)="submitForm()" value="Submit">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>