<div class="row mt-10">
    <div class="col-xl-1"></div>
    <div class="col-xl-10">
        <div class="">
            <div class="drag">
                <div class="card videobg shadow  videoalign">
                    <div class="card-header pb-1 pt-1 text-white mx-auto">Video Consultation</div>
                    <div class="card-body pt-1">
                        <div id="conference_div">
                            <div id="container" style="height:285px !important;">
                                <openvidu-webcomponent style="display: none;"></openvidu-webcomponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-xl-1"></div>
</div>