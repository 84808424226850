<div class="row">
  <div class="container-fluid pl-0 pr-0">
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-header-actions mx-auto">
          <div class="card-header">
            Refund Request Details
            <!-- <button class="btn btn-blue btn-icon float-right"
                (click)="modalTitle='Doctor Registration Details';openDoctorModal()">
                <i class="fa fa-plus"></i>
              </button> -->
          </div>
          <div class="card-body">
            <form [formGroup]="refundForm" (ngSubmit)="LoadData()">
              <div class="row ">
                <div class="container">
                  <div class="row ">
                    <div class='col-md-3'>
                      <div class="form-group">
                        <div class='date' id='datetimepicker6'>
                          <label class="control-label">From Date <span class="text-danger">*</span> </label>
                          <div>
                            <input id="from_date" name="from_date" type="text" autocomplete="off" onpaste="return false"
                              placeholder="From" bsDatepicker formControlName="from_date" [maxDate]="max_date"
                              (bsValueChange)="onFromDateChange($event)" onkeypress='return event.charCode == 8'
                              [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                              [ngClass]="{ 'is-invalid': submitted && f.from_date.errors }"
                              class="form-control form-control-sm" #dp="bsDatepicker" (focus)="dp.show()"
                              (focus)="dp1.hide()">
                            <div *ngIf="submitted && f.from_date.errors" class="invalid-feedback">
                              <div *ngIf="f.from_date.errors.required">From Date is required</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='col-md-3'>
                      <div class="form-group">
                        <div class='date' id='datetimepicker7'>
                          <label class="control-label">To Date <span class="text-danger">*</span> </label>
                          <div>
                            <input id="to_date" name="to_date" type="text" autocomplete="off" onpaste="return false"
                              placeholder="To" bsDatepicker #dp1="bsDatepicker"
                              (bsValueChange)="onFromDateChange($event)" (focus)="dp1.show()" (focus)="dp.hide()"
                              formControlName="to_date" [minDate]="f.from_date.value"
                              [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                              [ngClass]="{ 'is-invalid': submitted && f.to_date.errors }"
                              class="form-control form-control-sm" onkeypress='return event.charCode == 8'>
                            <div *ngIf="submitted && f.to_date.errors" class="invalid-feedback">
                              <div *ngIf="f.to_date.errors.required">To Date is required</div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='col-md-2' style="margin-top: 2rem;">
                      <button type="submit" class="btn btn-sm btn-primary" value="Submit"
                        (focus)="dp1.hide()">Submit</button>&nbsp;&nbsp;
                    </div>
                    <div class='col-md-2' style="margin-top: 2rem;">
                      <button type="button" class="btn  btn-sm btn-secondary float-right" (click)="exportexcel()">
                         <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <div class="table-responsive">
              <table datatable id="refundTable" class="table table-striped table-bordered table-sm" style="width:100%">
                <thead class="text-center">
                  <tr>
                    <th style="width:7%;" class="singleLine">S No</th>
                    <th>Action</th>
                    <th>Transaction Id</th>
                    <th>Doctor Name</th>
                    <th>Consultation Date & Time</th>
                    <th>Transaction Amount</th>
                    <th>Payment Type</th>
                    <th>Patient Name</th>
                    <th>Mobile Number</th>
                    <th>Age</th>
                    <!-- <th>Invoice</th> -->
                    <th>Status</th>
                    <th>Updated Date</th>
                    <th>Reason for Refund</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of lstGrid;let i=index">
                    <td class="singleLine text-center">{{i+1}}</td>
                    <td class="singleLine text-center">
                      <div class="badge badge-success badge-pill pointer" title="Edit Record">
                        <a (click)="modalTitle='Refund Request Detail';openRefundModal(item)"
                          *ngIf="setPrivilege(Screen_Name.RefundRequest, Permission.Edit)">
                          <i class="fa fa-edit text-white"></i></a>
                      </div>&nbsp;
                      <div class="badge badge-warning badge-pill pointer" title="Download Invoice">
                        <a (click)="downloadFile(item.invoice_url)"
                            *ngIf="setPrivilege(Screen_Name.RefundRequest, Permission.Edit)">
                          <i class="fa fa-download text-white"></i>
                        </a>
                        <!-- <a target="_blank" [href]="regService.downloadPath+item.invoice_url" download>
                          <i class="fa fa-download text-white"></i></a> -->
                      </div>
                    </td>
                    <td class="text-center">{{item.transaction_id}}</td>
                    <td class="singleLine text-uppercase">{{item.doctor_name}}</td>
                    <td class="text-center">
                      {{(item.consultation_date==null)?'Not Done':(item.consultation_date | date:'dd-MM-yyyy & HH:mm')}}
                    </td>
                    <td class="text-center">{{item.transaction_amount}}</td>
                    <td class="text-center">{{item.payment_type}}</td>
                    <td class="singleLine text-uppercase">{{item.patient_name}}</td>
                    <td class="text-center">{{item.mobileNo}}</td>
                    <td class="text-center">{{item.age}}</td>
                    <!-- <td class="text-center">{{item.invoice_url}}</td> -->
                    <td class="singleLine text-center">
                      {{(item.admin_approval==false)?'Rejected':['','In Progress','Initiated','Refunded','Refund not Accepted'][+item.status]}}
                    </td>
                    <td class="singleLine text-center">{{item.status_updated | date:'dd-MM-yyyy'}}</td>
                    <td class="singleLine">{{item.reason_for_refund}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>