import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })

export class UserService {

    userAPI = ''; tntAPI = ''; appUrl = (location.href.includes('localhost') ? environment.applicationURL : location.origin) + '/';
    
    constructor(private api: ApiService) {
        this.userAPI = environment.userApi, this.tntAPI = (location.href.includes('localhost') ? environment.tenantURL : location.origin + '/');
    }

    getAll(queryString: string): Observable<any> { return this.api.get(`${this.userAPI}${queryString}`); }
    getAllNew(queryString: string): Observable<any> { return this.api.post(`${this.userAPI}${queryString}`, null); }
    save(apimethod: string, data: any): Observable<any> { return this.api.post(`${this.userAPI}${apimethod}`, data); }
    save2(apimethod: string, data: any): Observable<any> { return this.api.post2(`${this.userAPI}${apimethod}`, data); }
    getById(queryString: string): Observable<any> { return this.api.get(`${this.userAPI}${queryString}`); }
    deleteByCorporate(id: number): Observable<any> { return this.api.get(`${this.userAPI}corporate/deletebyid?corporateId=${id}`); }
    deleteByCompany(id: number): Observable<any> { return this.api.get(`${this.userAPI}orgnizationcompany/deletebyid?id=${id}`); }
    getOrgById(orgId: number): Observable<any> { return this.api.get(`${this.userAPI}corporate/getcorporatebyorgid?orgnizationId=${orgId}`);}
    getUserPermissionByOrgId(orgId: number): Observable<any> {
        return this.api.get(`user/jwt/getuserpermissionbyorgid/${orgId}`);
      }
      UpdatePermissions(queryString: string, data:any): Observable<any> {
        return this.api.post(`${this.userAPI}${queryString}`, data);
      }
      savePermissions(queryString: string, data:any): Observable<any> {
        return this.api.post(`${this.userAPI}${queryString}`, data);
      }
      submitFeaturesByOrgId(orgId: number): Observable<any> {
        return this.api.get(`user/getuserpermissioncomparebyorgId/${orgId}`);
      }
      getAllActiveDocs(orgId: number): Observable<any> {
        return this.api.get(`telecon/doctorstatus/getall?TENANT_ID=${orgId}`);
      }
      getAllfeatures(): Observable<any> { return this.api.get(`user/getallmasterfeature`); }
    getOrgCompyById(orgId: number): Observable<any> { return this.api.get(`${this.userAPI}orgnizationcompany/getcompanyByorgid?orgnizationId=${orgId}`);}
    // getOpenViduDtls(orgId: number) { return this.api.get(`${this.userAPI}openvidu/getopenvidudetailsbyorgid?organizationId=${orgId}`); }
    getOpenViduDtls(orgId: number) { return this.api.get(`${this.userAPI}openvidu/getopenvidudetailbyorgid?organizationId=${orgId}`); }
    getTentantDtls(tntUrl: string) { return this.api.get(`${this.tntAPI}user/organization/getorganizationbydomain?domainName=${tntUrl}`); }
    deletecouponId(id: number): Observable<any> { return this.api.get(`${this.userAPI}deletecoupon?couponId=${id}`); }
    getOrgFTs(apimethod: string): Observable<any> { return this.api.get(`${this.userAPI}${apimethod}`); }
    setRfrshTkn(apimethod: string): Observable<any> { return this.api.get(`${this.userAPI}${apimethod}`); }
}