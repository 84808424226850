import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { PreviousHistoryComponent } from '../modals/previous-history/previous-history.component';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { forkJoin } from 'rxjs';
import { MasterService } from 'src/app/shared/services/master.service';

declare let $: any;
@Component({
  selector: 'app-plan-of-care',
  templateUrl: './plan-of-care.component.html',
  styleUrls: ['./plan-of-care.component.css']
})
export class PlanOfCareComponent implements OnInit {

  pocForm: FormGroup;
  submitted = false;

  subtypeList: Array<any> = [];
  investigationMaster: Array<any> = [];
  dropdownSettings = {};
  selectedItems = []; benHistory = []; prevItem = {}; docData: any; benId = 0;
  benData = { first_name: '', last_name: '', mobile: null, age: null, address: null, gender_id: null };
  chiefComplaintList: Array<any> = [];
  diagnosislList: Array<any> = [];
  drugNameLst: Array<any> = [];
  directionLst: Array<any> = [];
  dosageLst: Array<any> = [];
  drugtypesList: any;
  drugnamesList: any;
  investigationsList: any;
  directionsList: any;
  instructionList: any;

  pocCc = ''; pocVitals = ''; pocHistory = ''; pocPd = ''; pocIn = '';
  pocDrug = { drug_type: '', drug_name: '', dsg: '', dir: '', ins: '', noofdays: '' };

  constructor(private toastr: ToastrService
    , private formBuilder: FormBuilder
    , private registrationService: RegistrationService
    , private masterService: MasterService
    , config: NgbModalConfig
    , private modalService: NgbModal
    , private authServ: AuthenticationService) {
    config.backdrop = 'static';
    config.keyboard = false;
    // if (!customElements.get('openvidu')) {
    //   customElements.define('openvidu', HTMLElement);
    // }

  }

  ngOnInit(): void {
    $("#fullbtn").click(function () {
      $('.colchnage').toggleClass('col- col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-auto')
      if ($("#smallscreen").hasClass('col- col-sm-12 col-lg-8 col-xl-8')) {
        $("#smallscreen").removeClass('col- col-sm-12 col-lg-8 col-xl-8');
        $("#smallscreen").addClass('col- col-sm-12 col-lg-4 col-xl-4')
      }
      else {
        $("#smallscreen").removeClass('col- col-sm-12 col-lg-4 col-xl-4');
        $("#smallscreen").addClass('col- col-sm-12 col-lg-8 col-xl-8')
      }
      $("#smallvideo").toggleClass("col- col-sm-12 col-lg-8 col-xl-8");

    }

    );
    $('#opener').on('click', function () {
      var panel = $('#slide-panel');
      if (panel.hasClass("visible")) {
        panel.removeClass('visible').animate({ 'margin-left': '-250px' });
      } else {
        panel.addClass('visible').animate({ 'margin-left': '0px' });
      }
      return false;
    });
    this.initFormControls();

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'investigation',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.LoadDefaultData();
    // this.benId = 2;
    let no = (<HTMLInputElement>document.getElementById('toId')).value;
    this.benId = +no;
    this.getBenDetails(this.benId);

    this.docData = this.authServ.currentUserValue;
    this.getAllMaster();
  }

  LoadDefaultData() {
    this.drugNameLst = [{ id: 1, name: 'Drug 1' }, { id: 2, name: 'Drug 2' }, { id: 3, name: 'Drug 3' }, { id: 4, name: 'Drug 4' }]
    this.directionLst = [{ id: 1, name: 'Direction 1' }, { id: 2, name: 'Direction 2' }, { id: 3, name: 'Direction 3' }, { id: 4, name: 'Direction 4' }]
    this.dosageLst = [{ id: 1, name: 'X-0-0' }, { id: 2, name: '0-X-0' }, { id: 3, name: '0-0-X' }, { id: 4, name: 'X-X-0' }, { id: 5, name: '0-X-X' }, { id: 6, name: ' X-X-X' }, { id: 7, name: 'X-0-X' }]
  }

  initFormControls() {
    this.pocForm = this.formBuilder.group({
      // id: ['0'],
      beneficiary_id: [''],
      doctor_id: [''],
      "visit_date": "2020-03-15 16:00:00",
      "visit_start_time": "2020-03-15 16:00:00",
      "visit_end_time": "2020-03-15 22:00:00",

      cc_brief_history: this.formBuilder.group({
        chiefComplaints: ['', Validators.required],
        otherComplaints: [''],
        subtype: [''],
        otherSubtype: [''],
        doctorComment: [''],
        presentIllness: [''],
      }),
      vital_history: this.formBuilder.group({
        temperature: [''],
        pulse: ['', Validators.max(100)],
        systolic: ['', [Validators.min(50), Validators.max(400)]],
        diastolic: ['', [Validators.min(30), Validators.max(200)]],
      }),
      medical_history: this.formBuilder.group({
        pastIllness: [''],
        surgicalHistory: [''],
      }),
      other_history: this.formBuilder.group({
        familyHistory: [''],
        personalHistory: [''],
        dietaryHistory: [''],
        history: [''],
      }),
      allergy: this.formBuilder.group({
        drugsSensitivity: [''],
        allergies: ['', Validators.required],
      }),
      diagnosis: this.formBuilder.group({
        provisionalDiagnosis: [''],
        other: ['']
      }),
      investigation: this.formBuilder.group({
        master: [''],
        otherInvestigation: ['']
      }),
      prescribed_medications: this.formBuilder.group({
        drugType: [''],
        drugName: ['', Validators.required],
        otherDrug: [''],
        dosage: ['', Validators.required],
        direction: ['', Validators.required],
        instruction: [''],
        noofdays: ['', Validators.required],
        quantity: [''],
      }),
      advice: this.formBuilder.group({
        restExercise: [''],
        diet: [''],
        followAdvice: [''],
        referral: [''],
      }),
      remarks: this.formBuilder.group({}), //Not there in UI
      covid19: this.formBuilder.group({}), //Not there in UI
      "call_type_id": "1",
      "visit_no": "1",
      "teleconference_file_path": "/home",
      "referred_visit_id": "2",
      "beneficiary_visit_logs": ['']

    });
  }

  get f() { return this.pocForm.controls; }

  keyUp(elementId: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementId)));
    el.value = el.value.replace(/^(0*)/, "");
  }

  onSubmit(event) {
    // event.preventDefault();
    // this.submitted = true;
    // // this.getFormValidationErrors();
    // if (this.pocForm.invalid) {
    //   this.toastr.info('Please Enter All Mandatory Fields');
    //   return;
    // }
    // else {
    this.pocForm.patchValue({ beneficiary_id: this.benId.toString(), doctor_id: "33" }); //this.docData.id.toString()
    const sendData = JSON.parse(JSON.stringify(this.pocForm.getRawValue()));
    let ar = [{
      "visit_status_id": 1,
      "visit_end_time": "2020-03-12 12:00:00"
    }]
    sendData.beneficiary_visit_logs = ar;
    this.registrationService.saveData('savedoctorpoc', sendData).subscribe(res => {
      let apiResponse = res;
      if (apiResponse.status == "OK") {
        //  if(this.labForm.get('regFile').value != '')
        // this.saveFile(this.labForm.get('regFile').value, apiResponse.data.id);
        this.submitted = false;
        this.initFormControls();
        this.toastr.success(`Record ${(+sendData.id > 0) ? 'Updated' : 'Saved'} Successfully`, 'Laboratory Registration');
      }
    }, error => this.toastr.error(error.message));
    // }
  }

  getBenDetails(id) {
    this.registrationService.getDataById('findbybeneficiaryid?beneficiaryId=', id).subscribe(res => {
      this.benHistory = res.data;
      this.benData = res.data[0].beneficiary_id;
    },
      err => { this.toastr.error(err) });
  }

  openModal(item: any = null) {
    let ar = []; ar.push(item);
    const modalRef = this.modalService.open(PreviousHistoryComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.history = ar;
    modalRef.result.then(result => {
      // here call Table load API
      // alert('Success1');
    }, (reason) => { });
  }

  getAllMaster() {
    const chiefComplaint = this.masterService.getAll('getallchiefcomplaints');
    const diagnosis = this.masterService.getAll('getalldiagnosis');
    const drugtypes = this.masterService.getAll('getalldrugtypes');
    const drugnames = this.masterService.getAll('getalldrugs');
    const investigations = this.masterService.getAll('getallinvestigations');
    const directions = this.masterService.getAll('getalldirections');
    const instruction = this.masterService.getAll('getallinstruction');

    forkJoin([chiefComplaint, diagnosis, drugtypes, drugnames, investigations, directions, instruction]).subscribe(res => {
      res.forEach((list, ind) => {
        if (list.status === 'OK') {
          if (list.data == null || list.data.length == 0)
            this.toastr.info('No records available', 'Plan of Care');
          else {
            const assignList = {
              '0': () => this.chiefComplaintList = list.data,
              '1': () => this.diagnosislList = list.data,
              '2': () => this.drugtypesList = list.data,
              '3': () => this.drugnamesList = list.data,
              '4': () => this.investigationsList = list.data,
              '5': () => this.directionsList = list.data,
              '6': () => this.instructionList = list.data,
            }
            assignList[ind]();
          }
        }
      });
    });

  }

  onPreview(event) {
    event.preventDefault();
    this.submitted = true;
    // this.getFormValidationErrors();
    if (this.pocForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else {

      this.pocCc = this.pocVitals = this.pocHistory = this.pocPd = this.pocIn = '';
      this.pocDrug = { drug_type: '', drug_name: '', dsg: '', dir: '', ins: '', noofdays: '' };

      let f = this.pocForm.value;

      this.pocCc = f.cc_brief_history.chiefComplaints != '' ? this.chiefComplaintList.filter(e => e.id == f.cc_brief_history.chiefComplaints)[0].chief_complaint : '';

      this.pocVitals = f.vital_history.temperature + (f.vital_history.pulse != '' ? (',' + f.vital_history.pulse) : '')
        + (f.vital_history.systolic != '' ? (',' + f.vital_history.systolic) : '') + (f.vital_history.diastolic != '' ? (',' + f.vital_history.diastolic) : '');

      this.pocHistory = f.other_history.familyHistory + (f.other_history.personalHistory != '' ? (',' + f.other_history.personalHistory) : '')
        + (f.other_history.dietaryHistory != '' ? (',' + f.other_history.dietaryHistory) : '') + (f.other_history.history != '' ? (',' + f.other_history.history) : '')
        + (f.allergy.drugsSensitivity != '' ? (',' + f.allergy.drugsSensitivity) : '') + (f.allergy.allergies != '' ? (',' + f.allergy.allergies) : '')
        + (f.medical_history.pastIllness != '' ? (',' + f.medical_history.pastIllness) : '') + (f.medical_history.surgicalHistory != '' ? (',' + f.medical_history.surgicalHistory) : '');

      this.pocPd = f.diagnosis.provisionalDiagnosis != '' ? this.diagnosislList.filter(e => e.id == f.diagnosis.provisionalDiagnosis)[0].diagnosis_name : ''
        + f.diagnosis.other != '' ? (',' + f.diagnosis.other) : '';

      this.pocIn = f.investigation.master != '' ? this.investigationsList.filter(e => e.id == f.investigation.master)[0].investigation : '' +
        f.investigation.otherInvestigation != '' ? (',' + f.investigation.otherInvestigation) : '';

      let [drugType, drugName, dosage, direction, instr, days] = ['', '', '', '', '', ''];

      drugType = f.prescribed_medications.drugType != '' ? this.drugtypesList.filter(e => e.id == f.prescribed_medications.drugType)[0].drug_type : '';
      drugName = f.prescribed_medications.drugName != '' ? this.drugnamesList.filter(e => e.id == f.prescribed_medications.drugName)[0].drug_name : '';
      dosage = f.prescribed_medications.dosage != '' ? this.dosageLst.filter(e => e.id == f.prescribed_medications.dosage)[0].name : '';
      direction = f.prescribed_medications.direction != '' ? this.directionsList.filter(e => e.id == f.prescribed_medications.direction)[0].direction : '';
      instr = f.prescribed_medications.instruction != '' ? this.instructionList.filter(e => e.id == f.prescribed_medications.instruction)[0].instruction : '';
      days = f.prescribed_medications.noofdays;

      this.pocDrug = { drug_type: drugType, drug_name: drugName, dsg: dosage, dir: direction, ins: instr, noofdays: days };
      this.pocVitals = this.pocVitals.replace(/^,/, '');
      this.pocHistory = this.pocHistory.replace(/^,/, '');
      this.pocPd = this.pocPd.replace(/^,/, '');
      this.pocIn = this.pocIn.replace(/^,/, '');
      // $('#exampleModal').show();
      document.getElementById('btnPreviewModal').click();
    }

  }
}