<div class="row" *ngIf="roleId!=7">
  <div class="container-fluid pl-0 pr-0">
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-header-actions mx-auto">
          <div class="card-header">User Profile</div>
          <div class="container-fluid">
            <div class="row justify-content-center">
              <div class="col-xl-7">
                <form>
                  <br />
                  <div class="form-group row font-weight-bold">
                    <label for="inputPassword" class="col-sm-2 col-form-label">Name :</label>
                    <div class="col-sm-10 text-uppercase mt-2">{{user?.first_name + ' ' + user?.last_name}}</div>
                  </div>
                  <div class="form-group row font-weight-bold mt-2">
                    <label for="staticEmail" class="col-sm-2 col-form-label font-weight-bold">Email :</label>
                    <div class="col-sm-10 mt-2">{{user?.email}}</div>
                  </div>
                  <div class="form-group row font-weight-bold">
                    <label for="inputPassword" class="col-sm-2 col-form-label">Mobile :</label>
                    <div class="col-sm-10 mt-2">{{user?.mobile_no}}</div>
                  </div>
                  <!-- <div class="text-center"></div> -->
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row" *ngIf="roleId==7">
  <div class="container-fluid pl-0 pr-0">
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-header-actions mx-auto">
          <div class="card-header">User Profile</div>
          <div class="container-fluid"><br />
            <div class="row mt-2">
              <div class="col-xl-3"><label class="control-label"><strong>Name :</strong></label></div>
              <div class="col-xl-3">
                <input type="text" class="form-control form-control-sm text-uppercase"
                  value="{{user?.first_name + ' ' + user?.last_name}}" readonly>
              </div>
              <div class="col-xl-3"><label class="control-label"><strong>Email :</strong></label></div>
              <div class="col-xl-3">
                <input type="text" class="form-control form-control-sm" value="{{user?.email}}" readonly>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-xl-3"><label class="control-label"><strong>Mobile :</strong></label></div>
              <div class="col-xl-3">
                <input type="text" class="form-control form-control-sm text-uppercase" value="{{user?.mobile_no}}" readonly>
              </div>
              <div class="col-xl-3" *ngIf="docData"><label class="control-label"><strong>Department :</strong></label></div>
              <div class="col-xl-3" *ngIf="docData">
                <input type="text" class="form-control form-control-sm text-uppercase" value="{{docData?.department_name}}" readonly>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-xl-3" *ngIf="docData"><label class="control-label"><strong>Qualification :</strong></label></div>
              <div class="col-xl-3" *ngIf="docData">
                <input type="text" class="form-control form-control-sm text-uppercase" value="{{qlf}}" readonly>
              </div>
              <div class="col-xl-3" *ngIf="docData"><label class="control-label"><strong>Specialization :</strong></label></div>
              <div class="col-xl-3" *ngIf="docData">
                <input type="text" class="form-control form-control-sm text-uppercase" value="{{spl}}" readonly>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-xl-3" *ngIf="docData"><label class="control-label"><strong>Experience (Years) :</strong></label></div>
              <div class="col-xl-3" *ngIf="docData">
                <input type="text" class="form-control form-control-sm text-uppercase" value="{{docData?.years_experience}}" readonly>
              </div>
              <div class="col-xl-3" *ngIf="docData"><label class="control-label"><strong>Registration Expiry Date :</strong></label></div>
              <div class="col-xl-3" *ngIf="docData">
                <input type="text" class="form-control form-control-sm text-uppercase" value="{{date}}" readonly>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-xl-3" *ngIf="docData"><label class="control-label"><strong>Audio Consultation Rate :</strong></label></div>
              <div class="col-xl-3" *ngIf="docData">
                <input type="text" class="form-control form-control-sm text-uppercase" value="{{docData?.audio_consultation_price}}" readonly>
              </div>
              <div class="col-xl-3" *ngIf="docData"><label class="control-label"><strong>Video Consultation Rate :</strong></label></div>
              <div class="col-xl-3" *ngIf="docData">
                <input type="text" class="form-control form-control-sm text-uppercase" value="{{docData?.video_consultation_price}}" readonly>
              </div>
            </div>
            <div class="row mt-2 mb-5">
              <div class="col-xl-3" *ngIf="docData"><label class="control-label"><strong>Languages Preferred :</strong></label></div>
              <div class="col-xl-3" *ngIf="docData">
                <!-- <input type="text" class="form-control form-control-sm text-uppercase" value="{{langs}}" readonly> -->
                <textarea class="form-control text-uppercase txtArea" name="langs" id="lang" rows="10" readonly>{{langs}}</textarea>
              </div>
              <div class="col-xl-3" *ngIf="docData"><label class="control-label"><strong>{{isSAGE?'KMPDU':'MCI'}} Number :</strong></label></div>
              <div class="col-xl-3" *ngIf="docData">
                <input type="text" class="form-control form-control-sm text-uppercase" value="{{docData?.mci_number}}" readonly>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>