import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

import { RegistrationService } from 'src/app/shared/services/registration.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RefundListComponent } from '../refund-list/refund-list.component';
import { Common } from 'src/app/common';
import { BaseComponent } from 'src/app/shared/base.component';
import * as XLSX from 'xlsx';
declare let $: any;

@Component({
  selector: 'app-refund-request',
  templateUrl: './refund-request.component.html',
  styleUrls: ['./refund-request.component.css'],
  providers: [DatePipe]
})

export class RefundRequestComponent extends BaseComponent implements OnInit {

  modalTitle: string = '';
  refundForm: FormGroup;
  from_date: any = new Date();
  max_date = new Date();
  to_date: any = new Date();
  lstGrid = []; minDate: Date; minToDate: Date; docId = '';
  submitted = false;
  fileName = 'Refund list.xlsx';
  constructor(public regService: RegistrationService
    , protected toastr: ToastrService
    , private formBuilder: FormBuilder
    , private datePipe: DatePipe
    , private authServ: AuthenticationService,
    private modalService: NgbModal) { super(); }

  ngOnInit() {
    this.initFormControls(), this.LoadData();
  }

  initFormControls() {
    this.refundForm = this.formBuilder.group({ from_date: [new Date(), Validators.required], to_date: [new Date(), Validators.required] });
  }

  get f() { return this.refundForm.controls; }

  onFromDateChange(val: Date) {
    if (val) {
      let to_date: Date = new Date(this.datePipe.transform(val, 'yyyy-MM-dd'));
      this.refundForm.get('to_date').setValue(new Date(to_date.setDate(to_date.getDate()))), this.minDate = to_date;
    }
  }

  openRefundModal(item: any) {
    const modalRef = this.modalService.open(RefundListComponent, { scrollable: true, size: 'lg' });
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.DataEdit = item;
    modalRef.result.then(result => {
      if (result == 'changes saved')
        this.LoadData();
    }, (reason) => { });
  }

  LoadData() {
    let sendData = this.refundForm.value;
    this.submitted = true;
    [sendData.from_date, sendData.to_date] = [this.datePipe.transform(sendData.from_date, 'yyyy-MM-dd'), this.datePipe.transform(sendData.to_date, 'yyyy-MM-dd')];
    this.regService.getrefund('refund/getallbydate', sendData).subscribe(res => {
      if (res.status === "OK") {
        if (res.data == null || res.data == "No refund found") {
          this.toastr.info('No refund presently', 'Refund Request Details');
          this.lstGrid = [];
          Common.loadDataTable(this.lstGrid, '#refundTable');
        }
        else {
          this.lstGrid = res.data == "No refund found" ? [] : res.data;
          Common.loadDataTable(this.lstGrid, '#refundTable');
        }
      }
      else {
        this.toastr.info('No refund presently', 'Refund Request Details'), this.lstGrid = [];
        Common.loadDataTable(this.lstGrid, '#refundTable');
      }
    });
  }

  downloadFile(file_path: string) {
    if (file_path == 'Invoice Not Genrated') {
      this.toastr.info(file_path);
      return;
    }
    let link = document.createElement('a');
    link.target = "_blank";
    document.body.appendChild(link);
    link.href = `${this.regService.downloadPath}${file_path}`;
    link.click();
    window.onbeforeunload = null;
  }

  exportexcel(): void {
    var table = $('#refundTable').DataTable();
    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {	
      / table id is passed over here /
      let element = document.getElementById('refundTable');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };

      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
  }

  
}