<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Company
                        <button class="btn btn-blue btn-icon float-right"
                            *ngIf="setPrivilege(Screen_Name.CompanyRegistration, Permission.Add)"
                            (click)="openCompanyModal()"> <i class="fa fa-plus"></i>
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="">
                            <div class="custom-control-inline custom-control custom-radio">
                                <input  checked class="custom-control-input" id="customRadio11"
                                    type="radio" name="is_active" (change)="isShow=true;getcompanyByorgid(this.OrgId)"> <!-- getAllCompanies() -->
                                <label class="custom-control-label" for="customRadio11"><b>Coupon Company</b></label>
                            </div>
                            <div class="custom-control-inline custom-control custom-radio">
                                <input  class="custom-control-input " id="customRadio22"
                                    type="radio" name="is_active" (change)="isShow=false;getcorporatebyorgid(this.OrgId)"> <!-- getAllCorporte() -->
                                <label class="custom-control-label" for="customRadio22"><b>Corporate Company</b></label>
                            </div>
                        </div> <br />
                        <div class="table-responsive" [hidden]="!isShow">
                            <div class="col-md-9 mb-3" style="margin-top: 2rem;" >
                                <button type="button" class="btn  btn-sm btn-secondary float-right" (click)="exportexcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                            </div>    
                            <table datatable id="companyTable" class="table table-striped table-bordered table-sm"
                                style="width:100%">
                                <thead>
                                    <tr>
                                        <th>S No</th>
                                        <th>Company Name </th>
                                        <th>Registration Number</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of companyList;let i = index">
                                        <td>{{i+1}}</td>
                                        <td class="text-uppercase">{{item.company_name}}</td>
                                        <td>{{item.registration_no}}</td>
                                        <td>
                                            <div class="badge badge-info badge-pill pointer"
                                                *ngIf="setPrivilege(Screen_Name.CompanyRegistration, Permission.Edit)">
                                                <a (click)="openCompanyModal(item)"> <i
                                                        class="fa fa-edit text-white"></i></a>
                                            </div>&nbsp;&nbsp;&nbsp;
                                            <div class="badge badge-danger badge-pill pointer"
                                                *ngIf="setPrivilege(Screen_Name.CompanyRegistration, Permission.Delete)">
                                                <a (click)="deleteItem(item.id, 'mc')"><i class="fa fa-trash text-white"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                       
                        <div class="table-responsive" [hidden]="isShow">
                            <div class="col-md-9 mb-3" style="margin-top: 2rem;" >
                                <button type="button" class="btn  btn-sm btn-secondary float-right" (click)="exporttoexcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                            </div> 
                            <table datatable id="corporateTable" class="table table-striped table-bordered table-sm"
                                style="width:100%">
                                <thead>
                                    <tr>
                                        <th>S No</th>
                                        <th>Corporate Name </th>
                                        <th>Corporate ID</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of corporateList;let i = index">
                                        <td>{{i+1}}</td>
                                        <td class="text-uppercase">{{item.corporate_name}}</td>
                                        <td class="text-uppercase">{{item.corporate_code}}</td>
                                        <td>
                                            <div class="badge badge-info badge-pill pointer"
                                                *ngIf="setPrivilege(Screen_Name.CompanyRegistration, Permission.Edit)">
                                                <a (click)="openCompanyModal(item)"> <i
                                                        class="fa fa-edit text-white"></i></a>
                                            </div>&nbsp;&nbsp;&nbsp;
                                            <div class="badge badge-danger badge-pill pointer"
                                                *ngIf="setPrivilege(Screen_Name.CompanyRegistration, Permission.Delete)">
                                                <a (click)="deleteItem(item.id, 'cc')"><i class="fa fa-trash text-white"></i></a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>