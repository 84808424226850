<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="userForm" (ngSubmit)="onSubmit($event)">
        <div class="container-fluid">
            <div class="row">
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">First Name<span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" formControlName="first_name" id="first_name" placeholder="First Name"
                                onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'
                                [ngClass]="{ 'is-invalid': submitted && f.first_name.errors }"
                                class="form-control form-control-sm text-uppercase" maxlength="50">
                            <div *ngIf="submitted && f.first_name.errors" class="invalid-feedback">
                                <div *ngIf="f.first_name.errors.required">First Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Last Name <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" id="last_name" formControlName="last_name"
                                [ngClass]="{ 'is-invalid': submitted && f.last_name.errors }" placeholder="Last Name"
                                class="form-control form-control-sm text-uppercase" maxlength="50" onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) ||
                                    (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'>
                            <div *ngIf="submitted && f.last_name.errors" class="invalid-feedback">
                                <div *ngIf="f.last_name.errors.required">Last Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Email <span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" id="email" formControlName="email"
                                [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email"
                                class="form-control form-control-sm" required>
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.email">Sample Email address is x@yahoo.com</div>
                                <div *ngIf="f.email.errors.required">Email is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label"> Mobile Number<span class="text-danger">*</span></label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="mobile_no" id="mobile_no"
                                [ngClass]="{ 'is-invalid': submitted && f.mobile_no.errors }" [pattern]="mobPtrn"
                                onkeypress='return (event.charCode >= 48 && event.charCode <= 57)'
                                class="form-control form-control-sm" [maxlength]="maxLngth"
                                (keyup)="onKeyNmbr('mobile_no', $event.target.value)">
                            <div *ngIf="submitted && f.mobile_no.errors" class="invalid-feedback">
                                <div *ngIf="f.mobile_no.errors.required">Mobile Number is required</div>
                                <div *ngIf="f.mobile_no.errors.pattern">Mobile Number {{mobErrMsg}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Alternate Mobile Number</label>
                        <div>
                            <input type="text" formControlName="alternate_mobile_no"
                                [ngClass]="{ 'is-invalid': submitted && f.alternate_mobile_no.errors }"
                                [pattern]="mobPtrn" (keyup)="onKeyNmbr('alternate_mobile_no', $event.target.value)"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                placeholder="Alternate Mobile Number" [maxlength]="maxLngth"
                                class="form-control form-control-sm" id="alternate_mobile_no">
                            <!-- [ngClass]="{ 'is-invalid': isAltMobNoInvalid }" (keyup)="onAltMob($event.target.value)" -->
                            <!-- submitted={{submitted}}, f.alternate_mobile_no.errors={{f.alternate_mobile_no.errors}}, 
                                f.alternate_mobile_no.errors.pattern = {{f.alternate_mobile_no.errors?.pattern}} -->
                            <div *ngIf="submitted && f.alternate_mobile_no.errors" class="invalid-feedback">
                                <div *ngIf="f.alternate_mobile_no.errors.pattern">Alternate Mobile Number {{mobErrMsg}}
                                </div>
                            </div>
                            <!-- <div *ngIf="isAltMobNoInvalid" class="invalid-feedback">
                                <div>Alternate Mobile Number {{mobErrMsg}}</div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Role <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="user_type_id" id="user_type_id"
                                [ngClass]="{ 'is-invalid': submitted && f.user_type_id.errors }"
                                class="form-control form-control-sm" (change)="selectedrole($event.target.value)">
                                <option value="">Select</option>
                                <option *ngFor="let role of roleList" [value]="role.id">{{role.userType}}</option>
                            </select>
                            <div *ngIf="submitted && f.user_type_id.errors" class="invalid-feedback">
                                <div *ngIf="f.user_type_id.errors.required">Role is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Username<span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" formControlName="login_name" id="login_name"
                                [ngClass]="{ 'is-invalid': submitted && f.login_name.errors }"
                                class="form-control form-control-sm" maxlength="50">
                            <div *ngIf="submitted && f.login_name.errors" class="invalid-feedback">
                                <div *ngIf="f.login_name.errors.required">Username is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Password<span class="text-danger">*</span></label>
                        <div>
                            <input type="password" formControlName="password" id="password"
                                [ngClass]="{ 'is-invalid': submitted && f.password.errors }"
                                class="form-control form-control-sm" maxlength="30">
                            <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
                                <div *ngIf="f.password.errors.required">Password is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Organization Name<span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="organization_id" id="organization_id"
                                class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && f.organization_id.errors }">
                                <option value="">Select</option>
                                <option *ngFor="let item of organizationList" [value]="item.id">
                                    {{item.orgnization_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.organization_id.errors" class="invalid-feedback">
                                <div *ngIf="f.organization_id.errors.required">Organization Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <!-- <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Branch Name </label>
                        <div>
                            <select formControlName="branch_id" id="branch_id"
                                [ngClass]="{ 'is-invalid': submitted && f.branch_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let role of branchList" [value]="role.id">
                                    {{role.branch_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.branch_id.errors" class="invalid-feedback">
                                <div *ngIf="f.branch_id.errors.required">Branch Name is required</div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Source Type<span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="registration_source_id" id="registration_source_id"
                                [ngClass]="{ 'is-invalid': submitted && f.registration_source_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let item of sourceTypeList" [value]="item.id">{{item.source_type}}
                                </option>
                            </select>
                            <div *ngIf="submitted && f.registration_source_id.errors" class="invalid-feedback">
                                <div *ngIf="f.registration_source_id.errors.required">Source Type is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Status <span class="text-danger">*</span></label>
                        <div>
                            <input type="radio" formControlName="account_activated" (click)="ativeUser(true)" [value]="true">Active
                            <input type="radio" formControlName="account_activated" (click)="ativeUser(false)" [value]="false">In Active
                        </div>
                    </div>
                </div>
                <div class="col-xl-4" *ngIf="showcorporatename">
                    <div class="form-group">
                        <label class="control-label">Corporate Name <span class="text-danger">*</span></label>
                        <select class="form-control form-control-sm" formControlName="corporate_id"
                            [ngClass]="{ 'is-invalid': submitted && f.corporate_id.errors }">
                            <option value="">Select</option>
                            <option *ngFor="let item of companyList;" [value]="item.id">{{item.corporate_name}}</option>
                        </select>
                        <div *ngIf="submitted && f.corporate_id.errors" class="invalid-feedback">
                            <div *ngIf="f.corporate_id.errors.required">Corporate Name is required</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <input type="submit" id="btnsubmit" style="display: none;">
    </form>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" (click)="submitForm()" value="Submit">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>