import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormGroup, Validators, FormBuilder, FormArray, ValidationErrors } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import * as jquery from 'jquery';
declare let $: any;
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Common } from 'src/app/common';
import { forkJoin } from 'rxjs';
import { RegistrationService } from 'src/app/shared/services/registration.service'
import { environment } from 'src/environments/environment';
import { MasterService } from 'src/app/shared/services/master.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { Router } from '@angular/router';
import { promise } from 'protractor';


@Component({
  selector: 'app-hub-registration',
  templateUrl: './hub-registration.component.html',
  styleUrls: ['./hub-registration.component.css']
})
export class HubRegistrationComponent implements OnInit {
  isEditable: any = false;
  dropdownSettings_language = {};
  isGNM = environment.isGNM; isSAGE = environment.isSAGE; isNIG = environment.isNIG;
  cntrCtrlsForm: FormGroup;; minDt = new Date(); minToDt = new Date(); maxDt = new Date(); maxDtDOB = new Date();
  submitted = false; cntrItem: any; isAgntShow = false; cntrCode = ''; vcId = 0; isAct = '1';
  lstgrid = []; locationType_list; pincode_list = []; area_list = []; city_list = []; division_list = []; district_list = []; block_list = []; grampanchayat_list = [];
  paymentlist = []; userlist = []; role_list = []; lstData = []; panchayat_list = []; village_list = []; fileLst = []; centr: any;
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = [];
  mandalList: Array<any> = [];
  cityList: Array<any> = [];
  villageList: Array<any> = [];

  paymenttypes: any; dropdownSettings = {}; selectedItems: Array<any> = [];;
  paymentArr: Array<{ created_by: string, is_active: true, payment_per_head: null, payment_type_id: null }> = [];
  firstname: any; lastname: any; username;
  constructor(private formBuilder: FormBuilder
    , public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , private toastr: ToastrService
    , private masterService: MasterService
    , private authServ: AuthenticationService
    , private registrationService: RegistrationService
    , private router: Router
    , private authSvc: AuthenticationService
    , public cdr: ChangeDetectorRef
  ) {
    this.minToDt.setDate(this.minToDt.getDate());
    this.firstname = JSON.parse(sessionStorage.getItem('firstname'))
    this.lastname = JSON.parse(sessionStorage.getItem('lastname'))
    this.username = this.firstname + ' ' + this.lastname;
  }

  ngOnInit(): void {
    this.initFormControls(); this.defaultLocations();
    if (this.registrationService.hubregeditobj == null || this.registrationService.hubregeditobj == undefined) {
      this.locationChanged('', 'state');
      this.locationChanged('5', 'district')
    }

    this.getAllMasters();
    this.masterService.getAllpaymenttypes().subscribe(data => {
      this.paymenttypes = data.data;
    })
    this.radioclick('true');
    if (this.registrationService.hubregeditobj) {
      this.locationChanged('', 'state');
      this.locationChanged(this.registrationService.hubregeditobj.state_id, 'district')
      this.locationChanged(this.registrationService.hubregeditobj.district_id, 'mandal')
      this.locationChanged(this.registrationService.hubregeditobj.mandal_id, 'village')
      this.locationChanged(this.registrationService.hubregeditobj.district_id, 'city')
      this.cntrCtrlsForm.patchValue({
        hub_name: this.registrationService.hubregeditobj.hub_name,
        location_type_id: this.registrationService.hubregeditobj.location_type_id,
        state_id: this.registrationService.hubregeditobj.state_id,
        district_id: this.registrationService.hubregeditobj.district_id,
        mandal_id: this.registrationService.hubregeditobj.mandal_id,
        village_id: this.registrationService.hubregeditobj.village_id,
        city_id: this.registrationService.hubregeditobj.city_id,
        latitude: this.registrationService.hubregeditobj.latitude,
        longitude: this.registrationService.hubregeditobj.longitude,
        address: this.registrationService.hubregeditobj.address,
        pincode: this.registrationService.hubregeditobj.pincode,
        is_active: this.registrationService.hubregeditobj.is_active
      });
    }

  }
  defaultLocations() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'payment_type',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 4,
      allowSearchFilter: true
    };
  }
  initFormControls() {
    this.cntrCtrlsForm = this.formBuilder.group({
      id: [0],
      hub_name: ['', Validators.required],
      location_type_id: ['', Validators.required],
      hub_coordinator_id: [null],
      //  state_id: [36, Validators.required],//QA Pointing
      state_id: [5, Validators.required],//Training
      district_id: ['', Validators.required],
      mandal_id: [''],
      //village_id: ['', Validators.required],
      village_id: [''],
      city_id: [''],
      incharge_name: [null],
      incharge_mobile_no: [null],
      latitude: [''],
      longitude: [''],
      is_active: [true],
      is_state: [false],
      created_by: [this.username],
      address: [''],
      pincode: ['', [Validators.required, this.isSAGE ? Validators.pattern('^([1-9]){1,6}$') : Validators.pattern('^([1-9]){1}([0-9]){5}?$')]],
    });
  }

  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        this.cntrCtrlsForm.get('district_id').setValue('');
        this.cntrCtrlsForm.get('mandal_id').setValue('');
        this.cntrCtrlsForm.get('village_id').setValue('');
        this.cntrCtrlsForm.get('city_id').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [];
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=5`;
        this.cntrCtrlsForm.get('mandal_id').setValue('');
        this.cntrCtrlsForm.get('city_id').setValue('');
      },
      'mandal': () => {
        this.mandalList = [];
        this.villageList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
        this.cntrCtrlsForm.get('village_id').setValue('');
      },
      'village': () => {
        this.villageList = [];
        if (+id > 0)
          query = `getmandalbyid?mandalId=${id}`;
      },
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,//.map(row => this.pick(row, ['id', 'name', 'scheduleCasteType', 'castettype'])),
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
          'village': () => this.villageList = arr.villages,
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
      this.districtList.sort((a, b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error));
  }
  // getAllMasters() {
  //   const locationType = this.masterService.getAll('getalllocationtypes');
  //   const hospital = this.registrationService.getAll('hospital/getallhospitalrecords');
  //   const pharmacy = this.registrationService.getAll('pharmacy/getallrecords');
  //   const bankName = this.masterService.getAll('getallmasterbankdetails');
  //   forkJoin([locationType, hospital, pharmacy, bankName]).subscribe({
  //     next: res => {
  //       res.forEach((list, ind) => {
  //         if (list.status === 'OK') {
  //           if (list.data == null || list.data.length == 0)
  //             console.log('No records available');
  //           else {
  //             const assignList = {
  //               '0': () => this.locationTypeList = list.data,
  //               // '1': () => this.hospitalList = list.data,
  //               // '2': () => this.pharmacyList = list.data,
  //               // '3': () => this.bankNamesList = list.data,
  //             }
  //             assignList[ind]();
  //           }
  //         }
  //       });
  //     },
  //     error: err => console.error('something wrong occurred: ', err),
  //    // complete: () => { this.editPharmacy(); }
  //   });
  // }
  keyUp(elementId: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementId)));
    el.value = el.value.replace(/^(0*)/, "");
  }

  getAllMasters() {
    this.masterService.getAll('getalllocationtypes').subscribe(res => {
     // this.cdr.detectChanges();
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info(res.message);
        else
          this.locationTypeList = res.data;
       //   this.cdr.detectChanges();
      }
    });
    // locationType.subscribe({})
  }

  get f() { return this.cntrCtrlsForm.controls; }
  onItemSelect(item: any, type: string) {
    // this.addpayment();
  }

  onSelectAll(items: any, type: string) {
    this.selectedItems = items;
    // this.addpayment();
  }

  newamount(item: any): FormGroup {
    return this.formBuilder.group({
      created_by: [this.username],
      is_active: [true],
      payment_per_head: [''],
      payment_type_id: [item.id],
    });
  }

  centerregistrationSubmit() {
    Common.getFormValidationErrors(this.cntrCtrlsForm);
  //  this.cdr.detectChanges();
    if (this.cntrCtrlsForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    if (this.registrationService.hubregeditobj != undefined) {
    //  this.cdr.detectChanges();
      this.cntrCtrlsForm.value.id = this.registrationService.hubregeditobj.id;
    }
    this.registrationService.saveHubRegistration(this.cntrCtrlsForm.value).subscribe(res => {
    //  this.cdr.detectChanges();
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info(res.message);
        else
          this.toastr.info(res.message);
       //   this.cdr.detectChanges();
        this.router.navigate(['/home/hubs'])
        this.activeModal.close('changes saved');
      }
    })
  }
  // getFormValidationErrors() {
  //   Object.keys(this.cntrCtrlsForm.controls).forEach(key => {
  //     const controlErrors: ValidationErrors = this.cntrCtrlsForm.get(key).errors;
  //     if (controlErrors != null) {
  //       Object.keys(controlErrors).forEach(keyError => {
  //         console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
  //       });
  //     }
  //   });
  // }
  radioclick(item: any) {
    if (item == 'true') {
      this.isAct = '1';
      this.cntrCtrlsForm.patchValue({
        is_active: true
      })
    } else {
      this.isAct = '0';
      this.cntrCtrlsForm.patchValue({
        is_active: false
      })
    }
  }
}
function Input() {
  throw new Error('Function not implemented.');
}

