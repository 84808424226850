<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Nodal Details
                        <button class="btn btn-blue btn-icon float-right"
                            *ngIf="setPrivilege(Screen_Name.NodalOfficer, Permission.View)"
                            (click)="modalTitle='Nodal Detail';openModal()">
                            <i class="fa fa-plus"></i>
                        </button>
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table datatable id="organizationTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>S No </th>
                                        <th>Full Name</th>
                                        <th>Contact No</th>
                                        <th>Address</th>                                       
                                        <th class="text-center">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of organizationList;let i = index;">
                                        <td style="width: 8%;" class="text-center">{{i+1}}</td>
                                        <td class="text-uppercase">{{item.full_name}}</td>
                                        <td style="width: 12%;">{{item.mobile}}</td>
                                        <td>{{item.address}}</td>
                                        <td class="text-center">
                                            <div (click)="modalTitle='Nodal Detail';openModal(item)"
                                                *ngIf="setPrivilege(Screen_Name.NodalOfficer, Permission.Edit)"
                                                class="badge badge-info" ><i class="fa fa-edit text-white"></i></div>
                                            &nbsp;&nbsp;&nbsp;
                                            <div class="badge badge-danger badge-pill">
                                                <a (click)="deleteItem(item.id)"
                                                    *ngIf="setPrivilege(Screen_Name.NodalOfficer, Permission.Delete)">
                                                    <i class="fa fa-trash text-white"></i>
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>