<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="drugStateForm" (ngSubmit)="onSubmit($event)">
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">State <span class="text-danger">*</span> </label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="state_id" [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }">
                                <option value="" selected hidden>Select</option>
                                <option *ngFor="let state of stateList" [value]="state.id">{{state.state_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                <div *ngIf="f.state_id.errors.required">
                                  {{isNIG ? 'States' : 'State'}} is required
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-2">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Drug Type <span class="text-danger">*</span></label>
                            <select class="form-control form-control-sm" formControlName="drugtype_id"
                                (change)="getDrug()" [ngClass]="{ 'is-invalid': submitted && f.drugtype_id.errors }">

                                <option value="" selected hidden>Select</option>
                                <option *ngFor="let drugType of drug_type_list" [value]="drugType.id">
                                    {{drugType.drug_type}}</option>
                            </select>
                            <div *ngIf="submitted && f.drugtype_id.errors" class="invalid-feedback">
                                <div *ngIf="f.drugtype_id.errors.required">
                                 Drug Type is required
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Drug Name <span class="text-danger">*</span> </label>
                            <select class="form-control form-control-sm" formControlName="drugName_id"  [ngClass]="{ 'is-invalid': submitted && f.drugName_id.errors }">
                                <option value="" selected hidden>Select</option>
                                <option *ngFor="let drug of drug_type_name" [value]="drug.drug_id">
                                    {{drug.drug_Name.drug_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.drugName_id.errors" class="invalid-feedback">
                                <div *ngIf="f.drugName_id.errors.required">
                                 Drug Name is required
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Batch No <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" class="form-control form-control-sm" formControlName="batchNo_id" [ngClass]="{ 'is-invalid': submitted && f.batchNo_id.errors }" placeholder="Batch No"
                            (change)="getcheckdrug()">
                            <div *ngIf="submitted && f.batchNo_id.errors" class="invalid-feedback">
                                <div *ngIf="f.batchNo_id.errors.required">
                                 Batch No is required
                                </div>
                              </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Available Stock
                        </label>
                        <div>
                            <input type="text" class="form-control form-control-sm" disabled formControlName="available_stock" placeholder="Available Stock">
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Qty <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text" class="form-control form-control-sm" formControlName="qty_id" [ngClass]="{ 'is-invalid': submitted && f.qty_id.errors }" onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))" placeholder="Qty">
                            <div *ngIf="submitted && f.qty_id.errors" class="invalid-feedback">
                                <div *ngIf="f.qty_id.errors.required">
                                Quantity is required
                                </div>
                              </div>
                             
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Expiry Date </label>
                        <div>
                            <input type="text" autocomplete="off" formControlName="expiry_date" id="expiry_date"
                                bsDatepicker #dp="bsDatepicker" (focus)="dp.show()" class="form-control form-control-sm"
                                [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showWeekNumbers: false}"
                               >
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">MFG </label>
                        <div> <input type="text" autocomplete="off" formControlName="mfg_date" id="mfg_date"
                                bsDatepicker #dp1="bsDatepicker" class="form-control form-control-sm"
                                [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                (focus)="dp1.show()">
                        </div>
                    </div>
                </div>
            </div>
            <!-- <input type="submit" id="btnsubmit" style="display: none;" (click)="onSubmit($event)"> -->
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" value="Submit" (click)="onSubmit($event)">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>