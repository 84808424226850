import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Common } from 'src/app/common';
import { RegistrationService } from 'src/app/shared/services/registration.service';
declare let $: any;
@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css']
})
export class ServicesComponent implements OnInit {

  @Input() title: string;
  @Input() DataEdit: any; submitted = false; istmpltError = false;
  serviceForm: FormGroup; errMsg: boolean; ErrorMsg = '';
  constructor(public activeModal: NgbActiveModal, private formbuilder: FormBuilder, private registrationserv: RegistrationService
    , private toastr: ToastrService) { }

  ngOnInit(): void {
    this.initformcontrols(); this.patchingData()
  }

  patchingData() {
    if (this.DataEdit) {
      this.serviceForm.patchValue(this.DataEdit);
    }
  }
  
  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control && control.value && control.value.toString() ||  '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { 'whitespace': true};
  }

  initformcontrols() {
    this.serviceForm = this.formbuilder.group({
      id: [0],
      service_name: ['', [Validators.required, Validators.minLength(5), Validators.min(1), Validators.max(20), Validators.maxLength(20),this.noWhitespaceValidator,this.onchange]],
      price: ['', [Validators.required, Validators.min(1), Validators.max(1000)]],
      is_active: [true]
    })
  }
  get f() { return this.serviceForm.controls; }

  keyUp(elementId: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementId)));
    el.value = el.value.replace(/^(0*)/, "");
  }

  onKeyUp(val: string) {
    this.keyUp('price'), this.errMsg = false;
    if (val != '') {
      if (val.includes('.')) {
        let [dotCnt, dcmlCnt] = [val.split('.'), val.split('.')];
        if (dotCnt.length > 0 || dcmlCnt.length > 0) {
          if ((dotCnt.length > 2 || dcmlCnt[1].length > 1) || (dotCnt.length > 0 && dcmlCnt[1].length == 0)) {
            this.errMsg = true;
            return;
          }
        }
      }
    }
  }

  onchange(e:any){
    function trim_text(e) {
      e.value = e.value.
      replace(/(^\s*)|(\s*$)/gi, ""). // removes leading and trailing spaces
      replace(/[ ]{2,}/gi, " "). // replaces multiple spaces with one space
      replace(/\n +/, "\n"); // Removes spaces after newlines
      return;
  }
  $(function(){
    $("textarea").change(function() {
      trim_text(this);
    });
  
    $("input").change(function() {
      trim_text(this);
    });
  });
  }

  onKey1Up(e: any) {
    this.istmpltError =  e.length >= 5 && +e == 0 ;
    if (e.length >= 5  && +e == 0)
    this.ErrorMsg = 'Enter Valid Format Eg:X-Ray, Vital BP, First AID minor';
    }

  onSubmit(event) {
    this.submitted = true, Common.getFormValidationErrors(this.serviceForm);
    if (this.serviceForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    const postData = JSON.parse(JSON.stringify(this.serviceForm.getRawValue()));
    this.registrationserv.saveTypeservice(postData).subscribe(res => {
      if (res.status === 'OK') {
        this.toastr.success(res.message);
        this.activeModal.close('changes saved');
      }
      else
        this.toastr.error(res.message);
    })
  }

  submitForm() {
    document.getElementById('btnsubmit').click();
  }
}
