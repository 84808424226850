import { Component, OnInit, Input } from '@angular/core';
import { FormGroup,FormBuilder,Validators,ValidationErrors } from '@angular/forms';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/shared/services/master.service';
import { forkJoin } from 'rxjs';
import { Subject, pipe } from 'rxjs';
import { CityComponent } from '../city/city.component';
declare let $: any;

@Component({
  selector: 'app-city-list',
  templateUrl: './city-list.component.html',
  styleUrls: ['./city-list.component.css']
})
export class CityListComponent implements OnInit {
  modalTitle:string;
  @Input() pharmaData: any;
  cityForm: FormGroup;
  submitted = false;

  dtOptions: any = {};
  dtTrigger: any = new Subject();
  citylist:any;
  locationTypeList: Array<any> = [];
  stateList: Array<any> = [];
  districtList: Array<any> = []
  cityList: Array<any> = [];


  constructor(
    config: NgbModalConfig, 
      private modalService: NgbModal,
      private toastr: ToastrService,
      private masterService: MasterService,
      private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.locationChanged('', 'state');
    this.getAllMasters();
    this.initFormControls()
 
  }

  openCityModal() {
    const modalRef = this.modalService.open(CityComponent);
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.result.then(result => {
    }, (reason) => { });
  }

  initFormControls() {
    this.cityForm = this.formBuilder.group({
      state_id: ['', Validators.required],
      district_id: ['', Validators.required],
    });
  }
  locationChanged(id: string, locationType: string) {
    let query: string = '';
    let locations = {
      'location': () => {
        this.cityList = [];
        this.cityForm.get('district_id').setValue('');
      },
      'state': () => {
        this.districtList = [];
        this.cityList = [];
        query = 'getallstates';
      },
      'district': () => {
        this.districtList = [];
        this.cityList = [];
        if (+id > 0)
          query = `getstatebyid?stateId=${id}`;
      },
   
      'city': () => {
        this.cityList = [];
        if (+id > 0)
          query = `getdistrictbyid?districtId=${id}`;
      }
    };

    locations[locationType]();
    if (query.length > 0)
      this.getMasters(query, locationType);
  }

  getMasters(queryString: string, type: string) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,
          
          'district': () => this.districtList = arr.districts,
          'city': () => this.cityList = arr.cities
        };
        assign_locations[type]();
      }
      this.districtList.sort((a,b) => 0 - (a.district_name > b.district_name ? -1 : 1));
    }, error => this.toastr.error(error));
  } 
  getAllMasters() {
    const locationType = this.masterService.getAll('getalllocationtypes');

    forkJoin([locationType]).subscribe(res => {
      res.forEach((list, ind) => {
        if (list.status === 'OK') {
          if (list.data == null || list.data.length == 0)
            this.toastr.info('No records available', 'City List');
          else {
            const assignList = {
              '0': () => this.locationTypeList = list.data,
            }
            assignList[ind]();
          }
        }
      });
    });

  }
  loadcities() {
   
  

    var id = $('#state_id').val()
// this.loading = true;
$('.dataTable').DataTable().destroy();
$('.dataTable').DataTable({ searching: false });
this.masterService.searchbyIdMasters(`getcitybydistrictid?districtId=${id}`).subscribe((data: any) => {
// this.loading = false;
this.citylist = data.data;
this.dtTrigger.next();

});
// this.loading = true;
    }
  
    get f() { return this.cityForm.controls; }

  deleteItem(id: any) {
      this.masterService.deleteById(`deletestate?stateId=${id}`).subscribe(res => {
      if (res.status == "OK") {
        // this.getAllStates();
        this.toastr.success('Record Deleted Successfully');
      }
    },
      error => { this.toastr.error('An Error Occured'); });
  }
  setFocus(elementName: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementName)));
    el.value = '';
    el.focus();
  }
}
