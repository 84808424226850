import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { MasterService } from 'src/app/shared/services/master.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { FileService } from 'src/app/shared/services/file.service';
import { Common } from 'src/app/common';
import { ImagePreviewComponent } from '../../modals/image-preview/image-preview.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-dss-flow',
  templateUrl: './dss-flow.component.html',
  styleUrls: ['./dss-flow.component.css'],
  providers: [DatePipe]
})
export class DssFlowComponent implements OnInit {
dssForm:FormGroup;isShow=false;
  constructor(public activeModal: NgbActiveModal
    , private modalService: NgbModal
    , private toastr: ToastrService
    , private datePipe: DatePipe
    , private formBuilder: FormBuilder
    , private fileService: FileService
    , private registrationService: RegistrationService
    , private authServ: AuthenticationService
    , private notificationService: NotificationService
    , private masterService: MasterService) { }

  ngOnInit(): void {
    this.initFormControls();
  }
  initFormControls() {
    this.dssForm = this.formBuilder.group({
      is_active: [true],
      is_symptoms:[null],
      gestational_age:[''],
      pulse_in_one_min:[''],
      diastolic:[''],
      systolic:[''],
      haemoglobin:[''],
      urine_albunim:[null],
      hiv:[null],
      syphilis:[null],
      ogt_sugar:[null],
      fhs_in_one_min:[''],
      spo2:[''],
      blood_sugar:[''],
      malaria:[null],
      weight_just_before_pregnancy:[''],
      current_weight:[''],
      height:[''],
      urine_Sugar:[''],
      fundal_height:[''],
    });
  }
}
