import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { NgbModalConfig, NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/shared/services/master.service';
declare let $: any;

@Component({
  selector: 'app-district',
  templateUrl: './district.component.html',
  styleUrls: ['./district.component.css']
})
export class DistrictComponent implements OnInit {
  @Input() title: string;
  @Input() editData: any;
  submitted = false;
  stateList: Array<any> = [];
  districtForm: FormGroup;
  constructor(
    public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private formBuilder: FormBuilder
    , private masterService: MasterService
  ) { }

  ngOnInit(): void {
    if (this.editData) {
      this.districtForm.patchValue(this.editData);
      let [data] = [this.editData, ''];
      this.districtForm.patchValue(data);
      this.districtForm.patchValue({
        state_id: data.state_id,
        district_name: data.district_name
      });
    }
    this.getAllStates()
    this.initFormControls();
    this.getFormValidationErrors();

  }
  get f() { return this.districtForm.controls; }

  initFormControls() {
    this.districtForm = this.formBuilder.group({
      id: ['0'],
      state_id: ['', Validators.required],
      district_name: ['', Validators.required],
    });
  }
  getAllStates() {
    this.masterService.getAll('getallstates').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'District List');
        else
          this.stateList = res.data;
      }
    });
  }

  onSubmit(event: any) {
    event.preventDefault(), this.submitted = true, this.getFormValidationErrors();
    if (this.districtForm.invalid)
      return;
    else {
      const sendData = JSON.parse(JSON.stringify(this.districtForm.getRawValue()));
      this.masterService.save('saveandupdatedistrict', JSON.stringify(sendData)).subscribe(
        res => {
          let apiResponse = res;
          if (apiResponse.status === "OK") {
            this.toastr.success(res.message, 'District');
            this.activeModal.close();
            this.initFormControls();

          }
        }, error => this.toastr.error(error));
    }
  }
  setFocus(elementName: string) {
    let el: any = (<HTMLTextAreaElement>(document.getElementById(elementName)));
    el.value = '';
    el.focus();
  }

  getFormValidationErrors() {
    Object.keys(this.districtForm.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.districtForm.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
          console.log('Key control: ' + key + ', keyError: ' + keyError + ', err value: ', controlErrors[keyError]);
        });
      }
    });
  }
  restFormValues() {
    this.initFormControls();
  }
  submitForm() {
    document.getElementById('btnsubmit').click();
  }

}
