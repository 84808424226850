<!-- <p>dr-template-controls works!</p> -->


<form [formGroup]="drTemplateForm">

 <div class="modal-header">
    <h4 class="modal-title">Doctor Modules</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
        (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <div class="row mt-2">
        <div class="col-md-4">
            <label for="textName">Template Name</label>
            <input type="text" class="form-control" id="textName" [(ngModel)]="tmplName" formControlName="module_name">
        </div>
        <div class="col-md-4">
            <label>Chief Complaints</label>
            <div>                            
                <div tabindex="0" class="" >
                    <ng-multiselect-dropdown [settings]="dropdownSettings_CC" [placeholder]="'Select'"
                        [data]="chiefComplaintList" [(ngModel)]="cc" formControlName="ChiefComplaints"></ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <label>Provisional Diagnosis</label>
            <div>                            
                <div tabindex="0" class="" >
                    <ng-multiselect-dropdown [settings]="dropdownSettings_PD" [placeholder]="'Select'"
                        [data]="diagnosysgroupList" [(ngModel)]="pd" formControlName="ProvisionalDiagnosis"></ng-multiselect-dropdown>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-4">
            <label>Investigations</label>
            <div>                            
                <div tabindex="0" class="" >
                    <ng-multiselect-dropdown [settings]="dropdownSettings_INV" [placeholder]="'Select'"
                        [data]="investigationsList" [(ngModel)]="inv" formControlName="Investigations"></ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <label>Drug Type</label>
            <div>                            
                <div tabindex="0" class="" >
                    <ng-multiselect-dropdown [settings]="dropdownSettings_DGN" [placeholder]="'Select'"
                        [data]="drugtypesList" (onSelect)="onDrgSlct($event, 'dt')" (onDeSelect)="onDrgDeSlct('dt')"
                        [(ngModel)]="dgn" formControlName="DrugTypes"></ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-md-4">
            <label>Drug Name</label>
            <div>
              <ng-multiselect-dropdown [settings]="drugNameSettings" [placeholder]="'Select'"
                formControlName="dN" (onSelect)="onDrgSlct($event, 'dn')" [data]="drug_list"
                (onDeSelect)="onDrgDeSlct('dn')" [(ngModel)]="dN"
                >
              </ng-multiselect-dropdown>
              
            </div>
          </div>
    </div>
    
</div>
<div class="modal-footer">
    <input type="button" class="btn btn-sm btn-primary" (click)="OnSave()" value="Submit" [disabled]="tmplName == '' || cc == '' || pd =='' || inv ==''|| dgn ==''">
    <button type="button" class="btn btn-sm btn-outline-dark" data-dismiss="modal"
        (click)="activeModal.close('Close click')">Close</button>
</div> 
</form>