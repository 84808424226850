import { Common } from './../../../common';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators, ValidationErrors } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MasterService } from 'src/app/shared/services/master.service';

@Component({
  selector: 'app-drug',
  templateUrl: './drug.component.html',
  styleUrls: ['./drug.component.css']
})
export class DrugComponent implements OnInit {
  @Input() title: string;
  @Input() DataEdit: any;

  drugForm: FormGroup;
  submitted = false;

  constructor(public activeModal: NgbActiveModal
    , private toastr: ToastrService
    , private formBuilder: FormBuilder
    , private masterService: MasterService) { }

  ngOnInit(): void {
    this.initFormControls();
    if (this.DataEdit)
      this.drugForm.patchValue(this.DataEdit);
  }

  initFormControls() {
    this.drugForm = this.formBuilder.group({
      id: [0],
      drug_type: ['', Validators.required],
      is_active: [true]
    });
    Common.setFocus('drug_type');
  }

  get f() { return this.drugForm.controls; }

  onSubmit(event: any) {
    this.submitted = true;
    const sendData = JSON.parse(JSON.stringify(this.drugForm.getRawValue()));
    sendData.drug_type = sendData.drug_type.toUpperCase();
    this.masterService.save('saveandupdatedrugtype', sendData).subscribe(res => {
      let apiResponse = res;
      if (apiResponse.status == "OK") {
        this.toastr.success(apiResponse.message, 'Drug Type Details');
        this.activeModal.close('changes saved');
      }
    })
  }
}