<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="permissionForm" (ngSubmit)="onSubmit()">
            <div class="row">
                <div class="col-xl-12" formArrayName="permissions">
                    <table datatable id="permissionTable" class="table table-striped table-bordered table-sm"
                        style="width:100%">
                        <thead>
                            <tr>
                                <th>S No</th>
                                <th>Screen Name</th>
                                <th>Module Name</th>
                                <th class="text-center">Add</th>
                                <th class="text-center">Edit</th>
                                <th class="text-center">Delete</th>
                                <th class="text-center">View</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let group of getRowFormControls.controls ; let i=index" [formGroupName]="i">
                                <td style="width:6%;" class="text-center">{{i+1}}</td>
                                <td style="width:20%;">{{group.get('screen_label').value}}</td>
                                <td style="width:15%;">{{group.get('module_name').value}}</td>
                                <td style="width:5%;" class="text-center">
                                    <div class="custom-control-inline custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" [id]="'icheckbox'+(i+1)"
                                            formControlName="canInsert">
                                        <label class="custom-control-label" [for]="'icheckbox'+(i+1)"></label>
                                    </div>
                                </td>
                                <td style="width:5%;" class="text-center">
                                    <div class="custom-control-inline custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" [id]="'ucheckbox'+(i+1)"
                                            formControlName="canUpdate">
                                        <label class="custom-control-label" [for]="'ucheckbox'+(i+1)"></label>
                                    </div>
                                </td>
                                <td style="width:5%;" class="text-center">
                                    <div class="custom-control-inline custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" [id]="'dcheckbox'+(i+1)"
                                            formControlName="canDelete">
                                        <label class="custom-control-label" [for]="'dcheckbox'+(i+1)"></label>
                                    </div>
                                </td>
                                <td style="width:5%;" class="text-center">
                                    <div class="custom-control-inline custom-control custom-checkbox">
                                        <input class="custom-control-input" type="checkbox" [id]="'vcheckbox'+(i+1)"
                                            formControlName="canView">
                                        <label class="custom-control-label" [for]="'vcheckbox'+(i+1)"></label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <input type="submit" id="btnsubmit" style="display: none;">
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" (click)="submitForm()" value="Submit">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>