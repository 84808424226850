import { Component, OnInit } from '@angular/core';
import { PromotionComponent } from '../promotion/promotion.component';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
declare let $: any;
@Component({
  selector: 'app-promotion-list',
  templateUrl: './promotion-list.component.html',
  styleUrls: ['./promotion-list.component.css']
})
export class PromotionListComponent implements OnInit {
  modalTitle: string = '';
  constructor(config: NgbModalConfig, private modalService: NgbModal) {
    // customize default values of modals used by this component tree
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    $(document).ready(function () {
      var table = $('#promotionList').DataTable({
        lengthChange: false,
        destory: true,
        // buttons: ['copy', 'excel', 'pdf', 'colvis']
      });

      table.buttons().container()
        .appendTo('#example1_wrapper .col-md-6:eq(0)');
    });
  }

  openPromotionModal() {
    const modalRef = this.modalService.open(PromotionComponent, { size: 'lg' });
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.result.then(result => {
      // here call Table load API
    }, (reason) => { });
  }

}