import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { IAvailableSlot, TimeSlot } from '../doctor-list/doctor-list.component';

@Component({
  selector: 'app-doctor-slot-modal',
  templateUrl: './doctor-slot-modal.component.html',
  styleUrls: ['./doctor-slot-modal.component.css']
})
export class DoctorSlotModalComponent implements OnInit {

  @Input() item: any; 
  title = 'Doctor Slots';
  datesSelected:NgbDateStruct[]=[]; 
  dateSlots:any=[];
  doctor_name: string = '';
  doctor_id: any='';
    constructor(public activeModal: NgbActiveModal,private toastr: ToastrService
    , private datePipe: DatePipe,private regService: RegistrationService) { }

 async ngOnInit() {
  // console.log('doctor',this.item);
  this.doctor_name = this.item?.first_name;
  this.doctor_id = this.item?.id;
 
   this.doctor_name = this.doctor_name+' '+ (this.item?.middle_name ? this.item?.middle_name:'');
   this.doctor_name = this.doctor_name+' '+ (this.item?.last_name ? this.item.last_name: '');
   this.getActiveSlots(this.doctor_id);
 
  
  }
  // Date emit
  change(value:NgbDateStruct[])
  {
    this.datesSelected=value;
    // console.log('Date selected',this.datesSelected);
  }
 
  getActiveSlots(doctor_id:any){
  
    this.regService.getTimeSlotsByDateAndDocId(doctor_id).subscribe(res=>{
        if(res.status=='success'){
          this.dateSlots= res.response;
        }
        else{
          this.toastr.error('Something Went Wrong')
        }
    })
    
    // .subscribe(
    //   {
    //     next: res => {
    //       console.log('New changes done new API',res);
    //       if (res.status === 'success') {
    //         this.dateSlots= res.response;
    //       }
         
    //     },
    //     error: err => console.error('something wrong occurred: ', err),
    //     complete: () => { }
    //   }
    // );
    
  }
  
  submit() {
    let postData: TimeSlot = new TimeSlot();
    postData = {
      id:0,
      doctorId: this.doctor_id,
      isActive: true,
      fromDate: null,
      toDate: null,
      availableSlot: this.getAvailablesSlots()
    };
    this.regService.saveTimeSlots(postData).subscribe(
      {
        next: res => {
          if (res.status === 'success') {
            if (res.response == null)
              this.toastr.error('No records saved', 'Doctor Time Slot');
            else
              this.toastr.success('Record saved successfully', 'Doctor Time Slot');
          }
          document.getElementById('btnClose').click();
        },
        error: err => console.error('something wrong occurred: ', err),
        complete: () => { }
      }
    );
    this.activeModal.close();
  }
  getAvailablesSlots(): IAvailableSlot[] {
    let sendData: IAvailableSlot[] = [];
    // const weekDayList = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    // [this.selectedItems_Monday, this.selectedItems_Tuesday, this.selectedItems_Wednesday, this.selectedItems_Thursday, this.selectedItems_Friday, this.selectedItems_Saturday, this.selectedItems_Sundday]
    
    this.datesSelected.forEach((day, ind) => {
        let dayData:string = day.month+''
        dayData=dayData.length==1?'0'+day.month.toString():day.month+''
        sendData.push(
          {
            id: ind+1,
            weekday: '',
            is_active: true,
            date: day.year+'-'+dayData+'-'+day.day,
            slot: this.getSlot()
          }
        );
      });
    return sendData;
  }

  getSlot(){
    return [
      {"id":81,"time_slot":"08:00-08:15","is_active":true},
      {"id":82,"time_slot":"08:15-08:30","is_active":true},
      {"id":83,"time_slot":"08:30-08:45","is_active":true},
      {"id":84,"time_slot":"08:45-09:00","is_active":true},
      {"id":85,"time_slot":"09:00-09:15","is_active":true},
      {"id":86,"time_slot":"09:15-09:30","is_active":true},
      {"id":87,"time_slot":"09:30-09:45","is_active":true},
      {"id":88,"time_slot":"09:45-10:00","is_active":true},
      {"id":89,"time_slot":"10:00-10:15","is_active":true},
      {"id":90,"time_slot":"10:15-10:30","is_active":true},
      {"id":91,"time_slot":"10:30-10:45","is_active":true},
      {"id":92,"time_slot":"10:45-11:00","is_active":true},
      {"id":93,"time_slot":"11:00-11:15","is_active":true},
      {"id":94,"time_slot":"11:15-11:30","is_active":true},
      {"id":95,"time_slot":"11:30-11:45","is_active":true},
      {"id":96,"time_slot":"11:45-12:00","is_active":true},
      {"id":97,"time_slot":"12:00-12:15","is_active":true},
      {"id":98,"time_slot":"12:15-12:30","is_active":true},
      {"id":99,"time_slot":"12:30-12:45","is_active":true},
      {"id":100,"time_slot":"12:45-13:00","is_active":true},
      {"id":101,"time_slot":"13:00-13:15","is_active":true},
      {"id":102,"time_slot":"13:15-13:30","is_active":true},
      {"id":103,"time_slot":"13:30-13:45","is_active":true},
      {"id":104,"time_slot":"13:45-14:00","is_active":true},
      {"id":105,"time_slot":"14:00-14:15","is_active":true},
      {"id":106,"time_slot":"14:15-14:30","is_active":true},
      {"id":107,"time_slot":"14:30-14:45","is_active":true},
      {"id":108,"time_slot":"14:45-15:00","is_active":true},
      {"id":109,"time_slot":"15:00-15:15","is_active":true},
      {"id":110,"time_slot":"15:15-15:30","is_active":true},
      {"id":111,"time_slot":"15:30-15:45","is_active":true},
      {"id":112,"time_slot":"15:45-16:00","is_active":true},
      {"id":113,"time_slot":"16:00-16:15","is_active":true},
      {"id":114,"time_slot":"16:15-16:30","is_active":true},
      {"id":115,"time_slot":"16:30-16:45","is_active":true},
      {"id":116,"time_slot":"16:45-17:00","is_active":true}
    ];
  }
  closeModal(){
    this.activeModal.close();
  }


}
