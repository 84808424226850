import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/services/user.service';
import { Common } from 'src/app/common';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { BaseComponent } from 'src/app/shared/base.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';

@Component({
  selector: 'app-upload-consent-forms',
  templateUrl: './upload-consent-forms.component.html',
  styleUrls: ['./upload-consent-forms.component.css']
})
export class UploadConsentFormsComponent extends BaseComponent implements OnInit {
  organizationList: Array<any> = [];
  formtypeList: Array<any> = [];
  html = ``;
  config: any = {
    height: 250,
    branding: false,
    statusbar: false,
    theme: 'modern',
    // powerpaste advcode toc tinymcespellchecker a11ychecker mediaembed linkchecker help
    plugins: 'print preview fullpage searchreplace autolink directionality visualblocks visualchars fullscreen image imagetools link media template codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists textcolor wordcount contextmenu colorpicker textpattern',
    toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat',
    image_advtab: true,
    imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
    templates: [
      { title: 'Test template 1', content: 'Test 1' },
      { title: 'Test template 2', content: 'Test 2' }
    ],
    content_css: [
      '//fonts.googleapis.com/css?family=Lato:300,300i,400,400i',
      '//www.tinymce.com/css/codepen.min.css'
    ]
  };

  submitted = false;
  templateForm: FormGroup;
  constructor(
    private toastr: ToastrService
    , private formBuilder: FormBuilder
    , private userServices: UserService
    , private authSvc: AuthenticationService
  ) {
    super();
   }

  ngOnInit(): void {
    this.authSvc.isShowGlblOrg.next(false), this.authSvc.tenantId = sessionStorage.getItem('defCrntTnnt')
    if (this.authSvc.roleId == 1) {
      sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt'));
    }
    this.loadTableData();
    this.initFormControls();
  }

  initFormControls() {
    this.templateForm = this.formBuilder.group({
      id: [''],
      orgnization_id: ['', Validators.required],
      form_type_id: ['', Validators.required],
      title: ['', Validators.required],
      body: ['', Validators.required],
      is_active: [true]
    });
  }

  get f() { return this.templateForm.controls; }

  onSubmit(event: any) {
    this.submitted = true;
    Common.getFormValidationErrors(this.templateForm);
    if (this.templateForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else {
      // const postData = JSON.parse(JSON.stringify(this.templateForm.getRawValue()));
      this.userServices.save('organization/saveform', this.templateForm.getRawValue()).subscribe(res => {
        if (res.status === 'OK') {
          this.toastr.success('Record submited Successfully.', 'Form Templates');
        }
      },
        error => { this.toastr.error(error.message) });
    }
  }

  loadTableData() {
    const organization = this.userServices.getAll('organization/getorganizationrecords');
    const formtype = this.userServices.getAll('formtype/getall');

    forkJoin([organization, formtype]).subscribe(
      {
        next: res => {
          res.forEach((list, ind) => {
            if (list.status === 'OK') {
              if (list.data == null || list.data.length == 0)
                this.toastr.info('No records available', 'Form Templates');
              else {
                const assignList = {
                  '0': () => this.organizationList = list.data,
                  '1': () => this.formtypeList = list.data,
                }
                assignList[ind]();
              }
            }
          });
        },
        error: err => console.error('something wrong occurred: ', err),
        complete: () => { }
      });

  }

  organizationChanged(id) {
    this.userServices.getAll(`organization/getorganizationformsbyorgid?orgId=${id}`).subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0) {
          this.toastr.info('No records available', 'Form Templates');
          this.templateForm.patchValue({
            id: 0,
            form_type_id: '',
            title: '',
            body: '',
            is_active: true
          });
        }
        else {
          this.templateForm.patchValue(res.data[0]);
        }
      }
    });
  }
}
