<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                     DSS Report
                      </div>
                    <div class="col-md-12 mb-3" style="margin-top: 2rem;" >
                        <button type="button" class="btn  btn-sm btn-secondary float-right" (click)="exportexcel()"> <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                    </div>
                    <div class="table-responsive">
                        <table datatable id="dssr" class="table table-striped table-bordered table-sm">
                            <thead>
                                <tr style="background-color: lightskyblue">
                                    <th class="text-center" style="font-size:15px;">S.No</th>
                                    <!-- <th style="font-size:15px;">Arogya Kendra Code</th> -->
                                    <th style="font-size:15px;">Patient Name</th>
                                    <th style="font-size:15px;">Gender</th>
                                    <th style="font-size:15px;">Cheif Complaints-Prov</th>
                                    <th style="font-size:15px;">Investigation</th>
                                    <th style="font-size:15px;">Test Name</th>
                                    <th style="font-size:15px;">Drugs</th>
                                    <!-- <th style="font-size:15px;">Action</th> -->
                                </tr>
                            </thead>
                            <tbody>
                               
                                <tr *ngFor="let item of dssReportCustomData; let i = index">
                                    <td>{{i+1}}</td>
                                    <td>{{item.patientName}}</td>
                                    <td>{{item.gender}}</td>
                                    <td>{{item.cheifComplaints}}</td>
                                    <td>{{item.investigation}}</td>
                                    <td>{{item.testName}}</td>
                                    <td>{{item.drugs}}</td>
                                 
                                    <!-- <td><a  class="badge badge-primary badge-pill" data-toggle="modal" data-target="#openReport" style="cursor: pointer;" (click)="viewdata(item)"><i class="fa fa-eye text-white"></i></a></td> -->
                                    
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
 </div>      