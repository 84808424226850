import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { Common } from 'src/app/common';
import { BaseComponent } from 'src/app/shared/base.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { UserService } from 'src/app/shared/services/user.service';
import { ReferralControlsComponent } from './../referral-controls/referral-controls.component';
import * as XLSX from 'xlsx';
declare let $: any;


@Component({
  selector: 'app-referral-list',
  templateUrl: './referral-list.component.html',
  styleUrls: ['./referral-list.component.css']
})

export class ReferralListComponent extends BaseComponent implements OnInit {

  modalTitle: string = ''; rfrlList: any;
  fileName = 'Referal list.xlsx';
  constructor(config: NgbModalConfig
    , private modalService: NgbModal
    , private usrSvc: UserService
    , private toastr: ToastrService
    , private authSvc: AuthenticationService) {
    super();
    config.backdrop = 'static';
    config.keyboard = false;
  }

  ngOnInit(): void {
    this.authSvc.isShowGlblOrg.next(false), this.authSvc.tenantId = sessionStorage.getItem('defCrntTnnt'),
      sessionStorage.setItem('currentTenant', sessionStorage.getItem('defCrntTnnt'));
    this.LoadGrid();
  }

  LoadGrid() {
    $('.dataTable').DataTable().destroy(), $('.dataTable').DataTable({ destroy: true, searching: false });
    this.rfrlList = [], this.usrSvc.getAll('orgnization_referral_setting/findall').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'Referral List');
        else
          this.rfrlList = res.data;
        Common.loadDataTable(this.rfrlList, '#rfrlTable');
      }
    });
  }

  openModal(editData: any = null) {
    const modalRef = this.modalService.open(ReferralControlsComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.editData = editData;
    modalRef.result.then(result => {
      if (result == 'changes saved')
        this.LoadGrid();
    }, (reason) => { });
  }

  deleteItem(id: any) {
    if (confirm(`Are you sure you want delete record?`)) {
      this.usrSvc.getById(`orgnization_referral_setting/delete/${id}`).subscribe(res => {
        if (res.status == "OK")
          this.LoadGrid(), this.toastr.success('Record Deleted Successfully');
      }, error => { this.toastr.error('An Error Occured'); });
    }
  }
  exportexcel(): void {
    var table = $('#rfrlTable').DataTable();
    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {	
      / table id is passed over here /
      let element = document.getElementById('rfrlTable');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };

      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
  }


}