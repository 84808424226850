import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { StateComponent } from '../state/state.component';
import { MasterService } from 'src/app/shared/services/master.service';
import { ApiService } from 'src/app/shared/services/api.service';

declare let $: any;

@Component({
  selector: 'app-states-list',
  templateUrl: './states-list.component.html',
  styleUrls: ['./states-list.component.css']
})

export class StatesListComponent implements OnInit {

  modalTitle: string = ''; stateList: Array<any> = [];
  
  constructor(config: NgbModalConfig,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private apiServ: ApiService,
    private masterService: MasterService) {
  }

  ngOnInit(): void {
    this.getAllStates()
  }

  openStateModal(stateData: any = null) {
    const modalRef = this.modalService.open(StateComponent);
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.stateData = stateData;
    modalRef.result.then(result => {
      this.getAllStates()
    }, (reason) => { });
  }

  getAllStates() {
    if (this.stateList.length > 0) {
      $('.dataTable').DataTable().destroy();;
      $('.dataTable').DataTable({ destroy: true, searching: false });
    }
    this.masterService.getAll('getallstates').subscribe(res => {
      if (res.status === 'OK') {
        if (res.data == null || res.data.length == 0)
          this.toastr.info('No records available', 'State List');
        else {
          this.stateList = res.data;
          setTimeout(() => $(`#stateTable`).DataTable({ destroy: true }));
        }
      }
    });
  }

  deleteItem(id: any) {
    this.masterService.deleteById(`deletestate?stateId=${id}`).subscribe(res => {
      if (res.status == "OK")
        this.getAllStates(), this.toastr.success('Record Deleted Successfully');
    },
    error => { this.toastr.error('An Error Occured'); });
  }

}