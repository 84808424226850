import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import * as XLSX from 'xlsx';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { CenterRegistrationFormComponent } from 'src/app/modules/registration/center-registration-form/center-registration-form.component';
import { environment } from 'src/environments/environment';
import { Common } from 'src/app/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { HttpClient } from '@angular/common/http';
declare let $:any;
@Component({
  selector: 'app-medicines-details',
  templateUrl: './medicines-details.component.html',
  styleUrls: ['./medicines-details.component.css']
})
export class MedicinesDetailsComponent implements OnInit {
  dropdownSettingspatient={};patientname: Array<any> = [];patientList:any;agentbenId:any;prvconstdata:any;url='';getprescribeddrugs;
  url2='';
  constructor(config: NgbModalConfig
    , public modalService: NgbModal
    , private regService: RegistrationService
    , private authSvc: AuthenticationService
    , protected toastr: ToastrService
    , private router:Router
    , private formBuilder:FormBuilder
    , private masterService: MasterService
    ,private http: HttpClient) { }

  ngOnInit(): void {
    this.dropdownSettingspatient = {
      singleSelection: true,
      idField: 'id',
      textField: 'first_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      enableCheckAll:false,
      itemsShowLimit: 4,
      allowSearchFilter: true
    };
    this.getpatientdata();
  }
  getpatientdata(){
    this.regService.getagentPatients().subscribe(res => {
  this.patientList=res.data;
    })
  }
  onItemSelectpatient(item:any){
    this.agentbenId=item.id;
    this.regService.getprevconsultations(this.agentbenId).subscribe(res => {
      this.prvconstdata=res.data;
        })
  }
  onItemSelectpatientD(item:any){
    this.patientname = this.patientname.filter(f => f.id != item.id);
  }
  meddispense(consultationid){
    this.url='http://tmukqaapi.dhanushinfotech.com/api/VidmedPharmacy/GetMedicincesStockList?case_number='+consultationid+'&tenentId='+this.authSvc.tenantId;
    this.http.get<any>(this.url).subscribe( res => {
      if(res.Data.length == 0){
//   this.toastr.info('Cost is not available for Selected Drugs')
// this.drugsCostList = [];
      } else{
        this.getprescribeddrugs = res.Data;
        $("#dspmedlistM").modal("show");
      }
        }) 
  }
  dispensemsubmit(){
    // this.url2='http://tmukqaapi.dhanushinfotech.com/api/VidmedPharmacy/VidmedSagePharmacyCreate';
    // this.http.post<any>(this.url2,this.getprescribeddrugs).subscribe( res => {
    //   console.log(res);
    // })
    this.regService.saveagentdrugs(this.getprescribeddrugs,this.agentbenId).subscribe(res => {
      if(res.status === 'OK') {
        this.toastr.success(res.message);
        // this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        //   this.router.navigate(['/home/drugdispense']);
        // });
      }
    })
    
  }
}
