<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Update & View Stocks
                    </div>
                    
                    <div class="card-body">
                        <div class="">
                            <div class="custom-control-inline custom-control custom-radio">
                                <input  checked class="custom-control-input" id="customRadio11"
                                    type="radio" name="is_active" (change)="isShow=true;getdrugList()">
                                <label class="custom-control-label" for="customRadio11"><b>Update Stock</b></label>
                            </div>
                            <div class="custom-control-inline custom-control custom-radio">
                                <input  class="custom-control-input " id="customRadio22"
                                    type="radio" name="is_active" (change)="isShow=false;getviewStock()"> 
                                <label class="custom-control-label" for="customRadio22"><b>View Stock</b></label>
                            </div>
                        </div> <br />
                        <div class="table-responsive"  [hidden]="!isShow">
                            <table datatable id="drugsTable" class="table table-striped table-bordered table-sm" style="width:100%">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Drug Name</th>
                                        <th>Drug Type </th>
                                        <th>Delivery Qty</th>
                                        <th>Received Qty</th>
                                        <th>Remarks</th>
                                        <th>Batch Code</th>
                                        <th>Manufactured Date</th>
                                        <th>Expiry Date</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of drugsList;let i= index;">
                                        <td>{{i+1}}</td>
                                        <td>{{item.drug_name}}</td>
                                        <td>{{item.drug_type}}</td>
                                        <td>{{item.stock}}</td>
                                        <td>{{item.received_stock}}</td>
                                        <td>{{item.remarks}}</td>
                                        <td>{{item.batch_no}}</td>
                                        <td>{{item.manufactured_date | date:'dd-MM-yyyy'}}</td>
                                        <td>{{item.expiry_date | date:'dd-MM-yyyy' }}</td>
                                        <td>
                                            <button type="button" class="btn btn-success btn-sm" data-toggle="modal" data-target="#myModal" (click)="updateData(item)">Update Stock</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                       
                        <div class="table-responsive" [hidden]="isShow">
                            <table datatable id="viewTable" class="table table-striped table-bordered table-sm"
                                style="width:100%">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Drug Name</th>
                                        <th>Drug Type </th>
                                        <th>In House Total Qty</th>
                                        <th>Batch Code</th>
                                        <th>Manufactured Date</th>
                                        <th>Expiry Date</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of viewStock;let i= index;">
                                        <td>{{i+1}}</td>
                                        <td>{{item.drug_name}}</td>
                                        <td>{{item.drug_type}}</td>
                                        <td>{{item.stock}}</td>
                                        <td>{{item.batch_no}}</td>
                                        <td>{{item.manufactured_date | date:'dd-MM-yyyy'}}</td>
                                        <td>{{item.expiry_date | date:'dd-MM-yyyy' }}</td>
                                    </tr>
                                </tbody>
                            </table>
                            
                        </div>

                    </div>
                </div>  
            </div>

            <div class="modal fade" id="myModal">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body">
                      <h2 class="text-center">{{drugName}}</h2>

                      <form >
                        <div class="form-group">
                          <label for="rs"><b>Received Stocks</b></label>
                          <input type="number" [(ngModel)]="receivedStock" class="form-control" placeholder="Received Stocks" name="receivedStock">
                        </div>
                        {{receivedStock}}
                        <div class="form-group">
                          <label for="pwd"><b>Remarks</b></label>
                          <input type="text" [(ngModel)]="remarks" class="form-control" placeholder="Remarks" name="remarks">
                        </div>
                        <div class="text-center">
                            <button type="submit" class="btn btn-success mx-3" (click)="updateStock()">Add</button>
                            <button type="button" class="btn btn-light" (click)="close()">Cancel</button>
                        </div>
                        
                      </form>

                    </div>
                  </div>
                </div>
              </div>


        </div>
    </div>
</div>



