<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="container-fluid">
        <form [formGroup]="drugAgentForm" (ngSubmit)="onSubmit($event)">
            <div class="row">
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">State <span class="text-danger">*</span> </label>
                        <div>
                            <select class="form-control form-control-sm"
                                (change)=" getAllstates($event.target.value)" formControlName="state_id">
                                <option value="" selected hidden>Select</option>
                                <option *ngFor="let state of stateList" [value]="state.id">{{state.state_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Pharmacist/Healthcare Professional <span class="text-danger">*</span></label>
                        <div>
                            <select class="form-control form-control-sm" formControlName="agent_id">
                                <option value="" selected hidden>Select</option>
                                <option *ngFor="let agent of lstAgents;" [value]="agent.id">{{agent.first_name}}
                                    {{agent.last_name}}</option>
                            </select>

                        </div>
                    </div>
                </div>
                <div class="col-xl-5">
                    <div class="form-group">
                        <div>
                            <label class="control-label">Drug Type/Drug Name/Batch No <span class="text-danger">*</span></label>
                            <select class="form-control form-control-sm" formControlName="drugtype_id" (change)="getcheckdrug()" >
                                <option value="" selected hidden>Select</option>
                                <option *ngFor="let item of statedrugList;"  [value]="item.id">
                                    {{item.drug_type}}
                                    ({{item.drug_name}})
                                    B:({{item.batch_no}})
                                   S-Qty:({{item.current_stock}})
                                </option>
                            </select>
                            <!-- <span>{{statedrugList}}</span> -->

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">

                <!-- <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Batch No
                        </label>
                        <div>
                            <input type="text" class="form-control form-control-sm" formControlName="batchNo_id"
                            (change)="getcheckdrug()" placeholder="Batch No">
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Available Stock At Pharmacist/Healthcare Professional
                        </label>
                        <div>
                            <input type="text" class="form-control form-control-sm" disabled formControlName="available_stock" placeholder="Available Pharmacist/Healthcare Professional Stock">
                        </div>
                    </div>
                </div>
                <div class="col-xl-3">
                    <div class="form-group">
                        <label class="control-label">Available Stock At State
                        </label>
                        <div>
                            <input type="text" class="form-control form-control-sm" disabled formControlName="available_stock_state" placeholder="Available State Stock">
                        </div>
                    </div>
                </div>
                <div class="col-xl-1">
                    <div class="form-group">
                        <label class="control-label">Qty <span class="text-danger">*</span>
                        </label>
                        <div>
                            <input type="text"  class="form-control form-control-sm" formControlName="qty_id" placeholder="Qty">
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">Expiry Date </label>
                        <div>
                            <!-- <input type="text" autocomplete="off" formControlName="expiry_date" id="expiry_date"
                             bsDatepicker #dp="bsDatepicker" (focus)="dp.show()" class="form-control form-control-sm"
                           [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                                > -->
                                <input type="text" formControlName="expiry_date" disabled id="expiry_date" class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
                <div class="col-xl-2">
                    <div class="form-group">
                        <label class="control-label">MFG </label>
                        <div> 
                            <!-- <input type="text" autocomplete="off" formControlName="mfg_date" id="mfg_date" 
                                bsDatepicker #dp1="bsDatepicker" class="form-control form-control-sm"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false,adaptivePosition: true,isAnimated: true }"
                                (focus)="dp1.show()"> --> 
                                <input type="text"disabled formControlName="mfg_date" id="mfg_date" class="form-control form-control-sm">
                        </div>
                    </div>
                </div>
            </div>
            <!-- <input type="submit" id="btnsubmit" style="display: none;" (click)="onSubmit($event)"> -->
        </form>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" value="Submit" (click)="onSubmit($event)">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>