<form [formGroup]="drugForm" (ngSubmit)="onSubmit($event)">
    <div class="modal-header">
        <h4 class="modal-title">{{title}}</h4>
        <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="container-fluid">
            <form [formGroup]="drugForm" (ngSubmit)="onSubmit($event)">

                <div class="row">
                    <div class="col-xl-8">
                        <div class="form-group">
                            <label class="control-label">Drug Type<span class="text-danger">*</span> </label>
                            <div>
                                <input type="text" autocomplete="off" id="drug_type" placeholder="Drug Type"
                                    formControlName="drug_type"
                                    [ngClass]="{ 'is-invalid': submitted && f.drug_type.errors }"
                                    class="form-control form-control-sm text-uppercase"
                                    onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97 && event.charCode <= 122)|| event.charCode == 32)'>
                                <div *ngIf="submitted && f.drug_type.errors" class="invalid-feedback">
                                    <div *ngIf="f.drug_type.errors.required">Drug Type is required</div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4">
                        <label class="control-label">Status <span class="text-danger">*</span></label>
                        <div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input class="custom-control-input" formControlName="is_active" [value]="true"
                                    id="customRadio1" type="radio">
                                <label class="custom-control-label" for="customRadio1">Active</label>
                            </div>
                            <div class="custom-control custom-radio custom-control-inline">
                                <input class="custom-control-input" formControlName="is_active" [value]="false"
                                    id="customRadio2" type="radio">
                                <label class="custom-control-label" for="customRadio2">Inactive</label>
                            </div>
                        </div>
                    </div>
                </div>
                <input type="submit" id="btnsubmit" style="display: none;">
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <input type="submit" class="btn btn-sm btn-primary" value="Submit">&nbsp;&nbsp;
        <button type="button" class="btn btn-sm btn-outline-dark"
            (click)="activeModal.close('Close click')">Close</button>
    </div>
</form>