<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" style="height: 450px;">
    <div class="row mt-2">
        <div class="col-md-4 mt-2">
            <label for="module_name">Module Name <span class="text-danger">*</span></label>
            <input type="text" class="form-control" id="module_name" [(ngModel)]="payLoad.module_name"
            maxlength="30" minlength="5" autocomplete="off"  [ngClass]="{ 'is-invalid': submitted && istmpltError }"
            onkeypress='return ((event.charCode >= 65 && event.charCode <= 90) || (event.charCode >= 97
            && event.charCode <= 122) || (event.charCode >= 48 && event.charCode <= 57))' (keyup)="onKeyUp($event.target.value)">
            <div *ngIf="submitted && istmpltError" class="invalid-feedback">
                {{errMsg}} <!-- Accept value from 5 to 30 -->
            </div>
        </div>
        <div class="col-md-4 mt-2">
            <label>Chief Complaints <span class="text-danger">*</span></label>
            <div>
                <div tabindex="0" class="">
                    <ng-multiselect-dropdown [settings]="ccDDStngs" [data]="lstCC" (onSelect)="onMultiSlct($event, 's', 'ChiefComplaints')"
                        [placeholder]="'Select'" (onDeSelect)="onMultiSlct($event, 'd', 'ChiefComplaints')" (onDeSelectAll)="onDeSelectAll($event, 'm', 'ChiefComplaints')" (onSelectAll)="onSelectAllComplaints($event, 'm', 'ChiefComplaints')" [(ngModel)]="ccEdt"></ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-2">
            <label>Provisional Diagnosis <span class="text-danger">*</span></label>
            <div>
                <div tabindex="0" class="">
                    <ng-multiselect-dropdown [settings]="pdDDStngs" [data]="lstPD" (onSelect)="onMultiSlct($event, 's', 'ProvisionalDiagnosis')"
                        [placeholder]="'Select'" (onDeSelect)="onMultiSlct($event, 'd', 'ProvisionalDiagnosis')" (onDeSelectAll)="onDeSelectAll($event, 'm', 'ProvisionalDiagnosis')" (onSelectAll)="onSelectAllComplaints($event, 'm', 'ProvisionalDiagnosis')" [(ngModel)]="pdEdt"></ng-multiselect-dropdown>
                </div>
            </div>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-md-4 mt-2">
            <label>Investigations <span class="text-danger">*</span></label>
            <div>
                <div tabindex="0" class="" >
                    <ng-multiselect-dropdown [settings]="invDDStngs" [data]="lstINV" (onSelect)="onMultiSlct($event, 's', 'Investigations')"
                        [placeholder]="'Select'" (onDeSelect)="onMultiSlct($event, 'd', 'Investigations')" (onDeSelectAll)="onDeSelectAll($event, 'm', 'Investigations')" (onSelectAll)="onSelectAllComplaints($event, 'm', 'Investigations')" [(ngModel)]="invEdt"></ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-2">
            <label>Medications <span class="text-danger">*</span></label>
            <div>
                <div tabindex="0" class="" >
                    <ng-multiselect-dropdown [settings]="dtDDStngs" [data]="lstDT" (onSelect)="onMultiSlct($event, 's', 'DrugTypes')"
                        [placeholder]="'Select'" (onDeSelect)="onMultiSlct($event, 'd', 'DrugTypes')" (onDeSelectAll)="onDeSelectAll($event, 'm', 'DrugTypes')" (onSelectAll)="onSelectAll($event, 'm', 'DrugTypes')" [(ngModel)]="dtEdt"></ng-multiselect-dropdown>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-2" *ngIf="payLoad.module_data.DrugTypes.length!=0">
            <label>Selected Medications <span class="text-danger">*</span></label>
            <div>
                <select class="form-control" (change)="onChngDT($event.target.value)" [(ngModel)]="slctDT">
                    <option value="">Select</option>
                    <option *ngFor="let dt of payLoad.module_data.DrugTypes" [value]="dt.id">{{dt.drug_type}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-4 mt-2" *ngIf="+slctDT.length>0">
            <label>Drug Names <span class="text-danger">*</span></label>
            <div>
                <div tabindex="0" class="" >
                    <ng-multiselect-dropdown [settings]="dnDDStngs" [data]="lstDN" (onSelect)="onMultiSlct($event, 's', 'dn')"
                        [placeholder]="'Select'" (onDeSelect)="onMultiSlct($event, 'd', 'dn')" (onSelectAll)="onSelectAll($event, 'm', 'dn')" (onDeSelectAll)="onDeSelectAll($event, 'm', 'dn')" [(ngModel)]="dnEdt"></ng-multiselect-dropdown>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" value="Submit" (click)="onSave()">
    <button type="button" class="btn btn-sm btn-outline-dark" data-dismiss="modal" (click)="activeModal.close('Close click')">Close</button>
</div>