import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { Common } from 'src/app/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';

declare let $: any;
@Component({
  selector: 'app-vendor-registration',
  templateUrl: './vendor-registration.component.html',
  styleUrls: ['./vendor-registration.component.css']
})
export class VendorRegistrationComponent implements OnInit {
  @Input() title: string;
  @Input() editData: any;
  @Input() vaccineList: Array<any> = [];
  @Input() stateList: Array<any> = [];

  vendorForm: FormGroup;
  submitted = false;
  selectedItems: Array<any> = [];
  dropdownSettings: any = {};

  districtList: Array<any> = [];
  mandalList: Array<any> = [];

  private readonly onDestroy = new Subscription();
  constructor(public activeModal: NgbActiveModal

    , private toastr: ToastrService
    , private formBuilder: FormBuilder
    , private registrationService: RegistrationService
    , private masterService: MasterService) { }

  ngOnInit(): void {
    this.initFormControls();
    // this.locationChanged('', 'state'); this.getAllVaccine(this.editData || false);
    // this.onDestroy.add(this.route.data.subscribe(response => this.getAll(response.mastersData)));
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'id',
      textField: 'vaccine_name',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      disabled: false
    }

    this.editData && this.locationChanged(this.editData.state_id, 'district', true);
  }

  ngOnDestroy(): void {
    this.onDestroy.unsubscribe();
  }
  submitForm = (): void => $('#btnsubmit').click();

  initFormControls() {
    this.vendorForm = this.formBuilder.group({
      id: 0,
      vendor_name: ['', Validators.required],
      vendor_email: ['', Validators.required],
      vendor_mobile_no: ['', Validators.required],
      state_id: ['', Validators.required],
      district_id: ['', Validators.required],
      location_name: ['', Validators.required],
      mandal_id: ['0'],
      address: ['', Validators.required],
      city: [''],
      is_active: [true],
      pin_code: ['', Validators.required],
      vaccine_minimum: ['', Validators.required],
      vaccine_maximum: ['', Validators.required],
      vaccine_mapping: this.formBuilder.array([]),
    })
    this.validationChanges();
  }

  get f() { return this.vendorForm.controls; }

  vaccinations(): FormArray {
    return this.vendorForm.get("vaccine_mapping") as FormArray;
  }

  newVaccine(item: any): FormGroup {
    return this.formBuilder.group({
      vaccine_id: [item.id],
      price: [item.price || 0, Validators.required],
      available_stock: [item.available_stock || 0, Validators.required],
      is_active: [true],
    });
  }

  onItemSelect(item: any, type: string) {
    this.addVaccine();
  }

  onSelectAll(items: any, type: string) {
    this.selectedItems = items;
    this.addVaccine();
  }

  addVaccine() {
    this.vaccinations().clear();
    this.selectedItems.forEach(e => this.vaccinations().push(this.newVaccine(e)));
  }

  removeVaccine(i: number) {
    const arr = [...this.selectedItems];
    if (arr.splice(i, 1).length > 0) {
      this.selectedItems = arr;
    }
    this.vaccinations().removeAt(i);
  }

  locationChanged(id: string, locationType: string, isEdit: boolean = false) {
    let query: string = '',
      locations: any = {
        'state': () => {
          this.districtList = [],
            this.mandalList = [],
            query = 'getallstates';
          this.vendorForm.get('city').setValue('');
        },
        'district': () => {
          this.districtList = [],
            this.mandalList = [];
          this.vendorForm.get('city').setValue('');
          if (+id > 0)
            query = `getstatebyid?stateId=${id}`;
        },
        'mandal': () => {
          this.mandalList = [];
          if (+id > 0)
            query = `getdistrictbyid?districtId=${id}`;
        }
      };
    locations[locationType]();
    if (query.length > 0)
      this.getLocations(query, locationType, isEdit);
  }

  validationChanges(): void {
    this.vendorForm.get('district_id').valueChanges.subscribe((id: number) => {
      let cityName: string = '';
      if (id > 0)
        cityName = this.districtList.find(d => d.id == +id).district_name || '';
      this.vendorForm.get('city').setValue(cityName);
    });
  }

  getLocations(queryString: string, type: string, isEdit: boolean) {
    this.masterService.getLocations(queryString).subscribe(data => {
      let result = data;
      if (result.status == "OK") {
        let arr: any = result.data;
        let assign_locations = {
          'state': () => this.stateList = arr,
          'district': () => this.districtList = arr.districts,
          'mandal': () => this.mandalList = arr.mandals,
        };
        assign_locations[type]();
        if (isEdit) {
          const vaccineIds: number[] = this.editData.vaccines_mapping.map(vv => vv.vaccine_id);
          const vaccinesMapping: any[] = this.editData.vaccines_mapping.map(({ vaccine_id, price, available_stock }) => ({ vaccine_id, price, available_stock }));
          this.selectedItems = this.vaccineList.filter(v => vaccineIds.includes(v.id));
          this.vendorForm.patchValue(this.editData);
          this.selectedItems = this.selectedItems.map((m, i) => ({ ...m, vaccine_id: vaccinesMapping[i].vaccine_id, price: vaccinesMapping[i].price, available_stock: vaccinesMapping[i].available_stock }))
          this.addVaccine();
          this.editData.vaccines_mapping.forEach(element => element.is_active = false);
        }
      }
    }, error => this.toastr.error(error));
  }

  onSubmit(event: any) {
    event.preventDefault(), this.submitted = true, Common.getFormValidationErrors(this.vendorForm);
    if (this.vendorForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else {
      const sendData = Object.assign(this.vendorForm.getRawValue());
      sendData.state_name = this.stateList.find(e => e.id == sendData.state_id)?.state_name || '';
      sendData.district_name = this.districtList.find(e => e.id == sendData.district_id)?.district_name || '';

      if (sendData.id > 0) {
        sendData.vaccine_mapping.forEach(e => {
          let item = this.editData.vaccines_mapping.find(v => v.vaccine_id == e.vaccine_id);
          if (item)
            item = Object.assign(item, e);
          else
            this.editData.vaccines_mapping.push(e);
        });
        sendData.vaccine_mapping = this.editData.vaccines_mapping;
      }
      else
        delete sendData.id;

      this.checkValidate_vaccine(sendData);
    }
  }

  checkValidate_vaccine(sendData: any) {
    if (sendData.vaccine_mapping.length > 0) {
      this.registrationService.saveVendorRegistration(sendData).subscribe(data => {
        let result = data;
        if (result.status == 'OK') {
          this.toastr.success(result.message);
          this.activeModal.close('changes saved');
        }
        else
          this.toastr.error(result.message)
      });
    }
    else
      this.toastr.error('Please select at least one vaccine', this.title);
  }

}