import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {

    private currentUserSubject: BehaviorSubject<any>; public currentUser: Observable<any>; private sessionExpired$: BehaviorSubject<boolean> = new BehaviorSubject(false);
    sessionExpired = this.sessionExpired$.asObservable(); userId: string = ''; roleId = null; loginAPI: string = ''; public tenantId = null;

    public tnntSbjct: BehaviorSubject<number> = new BehaviorSubject<number>(null);
    public tnntId: Observable<any> = this.tnntSbjct.asObservable();

    public bannerSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public isBannerShow: Observable<any> = this.bannerSubject.asObservable();

    public msdCalSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public msdCalData: Observable<any> = this.msdCalSubject.asObservable();

    public crntOrgIDSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
    public crntOrgIDData: Observable<any> = this.crntOrgIDSubject.asObservable();

    public isShowGlblOrg: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isShowGlbl: Observable<boolean> = this.isShowGlblOrg.asObservable();

    public cnfgrdOrgSubject: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
    public cnfgrdOrgData: Observable<any> = this.cnfgrdOrgSubject.asObservable();

    public drugsSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public isDrugsShow: Observable<string> = this.drugsSubject.asObservable();

    public appSrceSubject: BehaviorSubject<string> = new BehaviorSubject<string>('dhanush');
    public appSrce: Observable<string> = this.appSrceSubject.asObservable();

    public appTtlSubject: BehaviorSubject<string> = new BehaviorSubject<string>('VIDMED');
    public appTtl: Observable<string> = this.appTtlSubject.asObservable();

    public appClntSubject: BehaviorSubject<string> = new BehaviorSubject<string>('DHANUSH VIDMED');
    public appClnt: Observable<string> = this.appClntSubject.asObservable();

    public tollFreeSubject: BehaviorSubject<string> = new BehaviorSubject<string>(environment.tollFreeIND);
    public tollFree: Observable<string> = this.tollFreeSubject.asObservable();

    public mobPtrnSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public mobPtrn: Observable<string> = this.mobPtrnSubject.asObservable();
    public paymentSub: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public maxLngthSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public maxLngth: Observable<string> = this.maxLngthSubject.asObservable();

    public mobErrMsgSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public mobErrMsg: Observable<string> = this.mobErrMsgSubject.asObservable();

    public mobValdValSubject: BehaviorSubject<string> = new BehaviorSubject<string>('');
    public mobValdVal: Observable<string> = this.mobValdValSubject.asObservable();

    public isSubjectGNM: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    public isGNM: Observable<boolean> = this.isSubjectGNM.asObservable();

    constructor(private toastr: ToastrService, private apiService: ApiService, private api: ApiService) {
        this.currentUserSubject = new BehaviorSubject<any>(JSON.parse(sessionStorage.getItem('currentUser'))),
            this.currentUser = this.currentUserSubject.asObservable(), this.loginAPI = environment.loginAPI;    // vidmedanh
        let urlRef = location.href.toLowerCase(), srceText = (urlRef.includes('sage') ? 'sage' : (urlRef.includes('doctoranh') ? 'anh' : (urlRef.includes('genome') ? 'genome' : (urlRef.includes('incipe') ? 'incipe' : (urlRef.includes('swashodhan') ? 'swashodhan' : (urlRef.includes('esic') ? 'esic' : (urlRef.includes('kampe') ? 'kampe' : (urlRef.includes('samaritan') ? 'samaritan' : (urlRef.includes('eclinic234') || urlRef.includes('qadoctors.eclinic234') ) ? 'eclinic' : (urlRef.includes('aarogyamplus') ? 'sewa' : (urlRef.includes('esanjeevani') ? 'esanjeevani' : (urlRef.includes('eSubcenter') ? 'esubcenter' : ((urlRef.includes('ng.vidmed') || urlRef.includes('ngqa.vidmed')) ? 'nandghar' : (urlRef.includes('ccad-doctor') ? 'ccad' : ((urlRef.includes('germanium.vidmed') || urlRef.includes('germaniumqa.vidmed')) ? 'germanium' :
            ((urlRef.includes('abhayasahaya.vidmed') || urlRef.includes('qaabhayasahaya.vidmed')) ? 'abhaya' :
                ((urlRef.includes('tngcc.vidmed') || urlRef.includes('qatngcc.vidmed')) ? 'tngcc' : ((urlRef.includes('hcs.org.in') || urlRef.includes('hcsqa')) ? 'hcs' :
                    ((urlRef.includes('salem.vmedtm.com') || urlRef.includes('qasalem.vmedtm.com')) ? 'salem' :   ((urlRef.includes('gam.vmedtm.com') || urlRef.includes('qagam.vmedtm.com')) ? 'gam' : ((urlRef.includes('vaccination.uferwas.in') || urlRef.includes('qavaccination.uferwas.in')) ? 'uferwas' : ((urlRef.includes('ccmc.vmedtm.com') || urlRef.includes('qaccmc.vmedtm.com')) ? 'coimbatore' : ((urlRef.includes('telemedicine-uk-qa'))  ? 'ukt': ((urlRef.includes('doctors.uktelemedicine.in'))  ? 'ukt':((urlRef.includes('telemedicine-uk-dev'))  ? 'ukt': ((urlRef.includes('telemedicine-uk-training'))  ? 'ukt':((urlRef.includes('telemedicine-uk-preprod'))  ? 'ukt' : ((urlRef.includes('telemedicine.vmedtm.com') || (urlRef.includes('qatelemedicine.vmedtm.com'))  ? 'chs' : (urlRef.includes('nwhtm1.nwh.co.ke')) ? 'nwh' : (urlRef.includes('janahitha.rakthadaanbharathi.org')) ? 'janahitha': 'dhanush')))))))))))))))))))))))))))),
            appTtlText = (urlRef.includes('sage') ? 'SAGE' : (urlRef.includes('doctoranh') ? 'ANES' : (urlRef.includes('genome') ? 'GENOME' : (urlRef.includes('incipe') ? 'INCIPE' : (urlRef.includes('swashodhan') ? 'SWASHODHAN' : (urlRef.includes('esic') ? 'ESIC' : (urlRef.includes('kampe') ? 'KAMPE' : (urlRef.includes('samaritan') ? 'SAMARITAN' : (urlRef.includes('eclinic234') || urlRef.includes('qadoctors.eclinic234') ? 'eCLINIC234' : (urlRef.includes('aarogyamplus') ? 'Aarogyamplus' : (urlRef.includes('esanjeevani') ? 'eSANJEEVANI' : (urlRef.includes('eSubcenter') ? 'eSubcenter' : ((urlRef.includes('ng.vidmed') || urlRef.includes('ngqa.vidmed')) ? 'Nand Ghar' : (urlRef.includes('ccad-doctor') ? 'CCAD' : ((urlRef.includes('germanium.vidmed') || urlRef.includes('germaniumqa.vidmed')) ? 'GERMANIUM' :
                ((urlRef.includes('abhayasahaya.vidmed') || urlRef.includes('qaabhayasahaya.vidmed')) ? 'ABHAYA' :
                    ((urlRef.includes('tngcc.vidmed') || urlRef.includes('qatngcc.vidmed')) ? 'TNGCC' : ((urlRef.includes('hcs.org.in') || urlRef.includes('hcsqa')) ? 'HCS' : ((urlRef.includes('salem.vmedtm.com') || urlRef.includes('qasalem.vmedtm.com')) ? 'SALEM VMED' :((urlRef.includes('vaccination.uferwas.in') || urlRef.includes('qavaccination.uferwas.in')) ? 'U-FERWAS': ((urlRef.includes('ccmc.vmedtm.com') || urlRef.includes('qaccmc.vmedtm.com')) ? 'CBE': ((urlRef.includes('gam.vmedtm.com') || urlRef.includes('qagam.vmedtm.com')) ? 'GAM': ((urlRef.includes('telemedicine-uk-qa'))  ? 'UTTARAKHAND TELEMEDICINE' : ((urlRef.includes('telemedicine-uk-training'))  ? 'UTTARAKHAND TELEMEDICINE': ((urlRef.includes('telemedicine-uk-preprod'))  ? 'UTTARAKHAND TELEMEDICINE':(urlRef.includes('telemedicine-uk-dev'))  ? 'UTTARAKHAND TELEMEDICINE': ((urlRef.includes('doctors.uktelemedicine.in'))  ? 'UTTARAKHAND TELEMEDICINE' :((urlRef.includes('telemedicine.vmedtm.com') || (urlRef.includes('qatelemedicine.vmedtm.com')) ? 'VMed Corp': (urlRef.includes('nwhtm1.nwh.co.ke')) ? 'NWH': (urlRef.includes('janahitha.rakthadaanbharathi.org')) ? 'JANAHITHA VMED': 'VMED')))))))))))))))))))))))))))),
            clientText = (urlRef.includes('sage') ? 'SAGE' : (urlRef.includes('doctoranh') ? 'ANES' : (urlRef.includes('genome') ? 'GENOME' : (urlRef.includes('incipe') ? 'INCIPE' : (urlRef.includes('swashodhan') ? 'SWASHODHAN' : (urlRef.includes('esic') ? 'ESIC' : (urlRef.includes('kampe') ? 'KAMPE HOSPITAL TELECLINIC' : (urlRef.includes('samaritan') ? 'SAMARITAN HOSPITAL TELECLINIC' : (urlRef.includes('eclinic234') || urlRef.includes('qadoctors.eclinic234') ? 'eCLINIC234 HOSPITAL TELECLINIC' : (urlRef.includes('aarogyamplus') ? 'Aarogyamplus' : (urlRef.includes('esanjeevani') ? 'eSANJEEVANI' :
                (urlRef.includes('eSubcenter') ? 'esubcenter' : ((urlRef.includes('ng.vidmed') || urlRef.includes('ngqa.vidmed')) ? 'Nand Ghar' : (urlRef.includes('ccad-doctor') ? 'CCAD' : ((urlRef.includes('germanium.vidmed') || urlRef.includes('germaniumqa.vidmed')) ? 'GERMANIUM' :
                    ((urlRef.includes('abhayasahaya.vidmed') || urlRef.includes('qaabhayasahaya.vidmed')) ? 'ABHAYA SAHAYA' :
                        ((urlRef.includes('tngcc.vidmed') || urlRef.includes('qatngcc.vidmed')) ? 'TNGCC' : ((urlRef.includes('hcs.org.in') || urlRef.includes('hcsqa')) ? 'HEALTH CARE SERVICES' : ((urlRef.includes('salem.vmedtm.com') || urlRef.includes('qasalem.vmedtm.com')) ? 'SALEM VMED' : ((urlRef.includes('gam.vmedtm.com') || urlRef.includes('qagam.vmedtm.com')) ? 'GAM VMED' : ((urlRef.includes('vaccination.uferwas.in') || urlRef.includes('qavaccination.uferwas.in')) ? 'U-FERWAS': ((urlRef.includes('ccmc.vmedtm.com') || urlRef.includes('qaccmc.vmedtm.com')) ? 'CBE': urlRef.includes('telemedicine-uk-qa') ? 'UTTARAKHAND TELEMEDICINE' : urlRef.includes('telemedicine-uk-training') ? 'UTTARAKHAND TELEMEDICINE': urlRef.includes('telemedicine-uk-dev') ? 'UTTARAKHAND TELEMEDICINE'  : urlRef.includes('doctors.uktelemedicine.in') ? 'UTTARAKHAND TELEMEDICINE':urlRef.includes('telemedicine-uk-preprod') ? 'UTTARAKHAND TELEMEDICINE' :((urlRef.includes('telemedicine.vmedtm.com') || (urlRef.includes('qatelemedicine.vmedtm.com'))  ? 'VMed Corp': (urlRef.includes('nwhtm1.nwh.co.ke'))  ? 'NWH': (urlRef.includes('janahitha.rakthadaanbharathi.org'))  ? 'JANAHITHA VMED':'DHANUSH VMED')))))))))))))))))))))))),
            tollFreeNo = (urlRef.includes('sage') ? environment.tollFreeSage : (urlRef.includes('samaritan') ? environment.tollFreeSMR : (urlRef.includes('eclinic234') || urlRef.includes('qadoctors.eclinic234') ? environment.tollFreeKMP : (urlRef.includes('esanjeevani') ? environment.tollFreeSJN : ((urlRef.includes('ng.vidmed') || urlRef.includes('ngqa.vidmed')) ? environment.tollFreeNDGHR : ((urlRef.includes('abhayasahaya.vidmed') || urlRef.includes('qaabhayasahaya.vidmed')) ? environment.tollFreeABH : ((urlRef.includes('hcs.org.in') || urlRef.includes('hcsqa')) ? environment.tollFreeHCS : ((urlRef.includes('salem.vmedtm.com') || urlRef.includes('qasalem.vmedtm.com')) ? environment.tollFreeSalem : (urlRef.includes('janahitha.rakthadaanbharathi.org')) ? environment.tollFreeJanahitha : environment.tollFreeIND))))))));
        this.appSrceSubject.next(srceText), this.drugsSubject.next(srceText), this.appTtlSubject.next(appTtlText), this.appClntSubject.next(clientText),
            this.isSubjectGNM.next(urlRef.includes('localhost') || urlRef.includes('genome') || urlRef.includes('incipe')), this.tollFreeSubject.next(tollFreeNo),
            this.maxLngthSubject.next((urlRef.includes('sage') ? environment.maxLngthSAGE : (urlRef.includes('eclinic234') ? environment.maxLngthNIG : environment.maxLngthIND))), // this.isSubjectAPK.next(urlRef.includes('localhost') || urlRef.includes('genome') || urlRef.includes('incipe'));
            this.mobPtrnSubject.next((urlRef.includes('sage') ? environment.mobPtrnSAGE : (urlRef.includes('eclinic234') ? environment.mobPtrnNIG : environment.mobPtrnIND))),
            this.mobErrMsgSubject.next((urlRef.includes('sage') ? environment.mobErrMsgSAGE : (urlRef.includes('eclinic234') ? environment.mobErrMsgNIG : environment.mobErrMsgIND)));
    }

    updateSession(style: boolean) {
        this.sessionExpired$.next(style);
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    loginold(username: string, password: string, tntId: number, isVerifed: boolean = false) { // , isTnt: boolean = true
          let tenant: string = `${tntId != null ? `?TENANT_ID=${tntId}` : ''}`;//Production Pointing
    //    let tenant: string = `${tntId != null ? `?TENANT_ID=50` : ''}`;//QA Pointing and Training
         if (isVerifed)
             tenant = `${tenant}&isVerifed=true`;
         return this.apiService.post(`${this.loginAPI}nojwt/user/login${tenant}`, { "login_name": username, "password": password }).pipe(
             map((user: any) => {
                 const responseData = user;
                 if (responseData.response == null || responseData.status !== 'success')
                     return (responseData.response);
                 else {
                     const currentUser = responseData.response;
                     let orgId = currentUser.orgnization_registration.id;
                     this.roleId = currentUser.user_role_mapping[0].user_type_id.id, 
                     this.tnntSbjct.next(orgId), this.tnntSbjct.subscribe(s => this.tenantId = s), // this.tenantId = currentUser.orgnization_registration.id,
                         this.userId = currentUser.id, this.currentUserSubject.next(currentUser), 
                         sessionStorage.setItem('currentUser', JSON.stringify(currentUser)),
                         sessionStorage.setItem('session_id', JSON.stringify(currentUser.id)),
                         sessionStorage.setItem('orgName',currentUser.orgnization_registration.orgnization_name), 
                         sessionStorage.setItem('currentTenant', JSON.stringify(currentUser.orgnization_registration.id)), 
                         sessionStorage.setItem('agentUserId',currentUser.agent_user_id),sessionStorage.setItem('firstname', JSON.stringify(currentUser.first_name)),sessionStorage.setItem('lastname', JSON.stringify(currentUser.last_name)),sessionStorage.setItem('orgid', JSON.stringify(currentUser.orgnization_registration.id)),sessionStorage.setItem('userroleA', currentUser.user_role_mapping[0].user_type_id.user_type),sessionStorage.setItem('uid',currentUser.user_role_mapping[0].id),sessionStorage.setItem('tenantUrl',currentUser.orgnization_registration.url_path);
                     return user;
                 }
             })
         );
     }
 

   //   New login method added for captha and encrypted password #chandu 28-04-2022
   

   login(username: string, password: string,captcha:String,captchaId: string , tntId: number, isVerifed: boolean = false) { // , isTnt: boolean = true
     let tenant: string = `${tntId != null ? `?TENANT_ID=${tntId}` : ''}`;//Production Pointing
    // let tenant: string = `${tntId != null ? `?TENANT_ID=50` : ''}`;//QA Pointing and Training
     if (isVerifed)
       {
        tenant = `${tenant}&isVerifed=true`;
        return this.apiService.post(`${this.loginAPI}nojwt/user/signin${tenant}`, { "loginName": username, "password": password }).pipe(
            map((user: any) => {
                const responseData = user;
                if (responseData.response == null || responseData.status !== 'success')
                    return (responseData.response);
                else {
                    const currentUser = responseData.response;
                     let orgId = currentUser.orgnization_registration.id;
                 //  let orgId = currentUser.organization_id;
                    this.roleId = currentUser.user_role_mapping[0].user_type_id.id, 
                    this.tnntSbjct.next(orgId), this.tnntSbjct.subscribe(s => this.tenantId = s), // this.tenantId = currentUser.orgnization_registration.id,
                        this.userId = currentUser.id, this.currentUserSubject.next(currentUser), 
                        sessionStorage.setItem('currentUser', JSON.stringify(currentUser)),
                        sessionStorage.setItem('session_id', JSON.stringify(currentUser.id)),
                       sessionStorage.setItem('orgName',currentUser.orgnization_registration.orgnization_name), 
                        sessionStorage.setItem('currentTenant', JSON.stringify(currentUser.orgnization_registration.id)), 
                      // sessionStorage.setItem('currentTenant', JSON.stringify(currentUser.organization_id)),
                       sessionStorage.setItem('agentUserId',currentUser.agent_user_id),
                        sessionStorage.setItem('firstname', JSON.stringify(currentUser.first_name)),
                        sessionStorage.setItem('lastname', JSON.stringify(currentUser.last_name)),
                         sessionStorage.setItem('orgid', JSON.stringify(currentUser.orgnization_registration.id)),
                      // sessionStorage.setItem('orgid', JSON.stringify(currentUser.organization_id)),
                        sessionStorage.setItem('userroleA', currentUser.user_role_mapping[0].user_type_id.user_type),
                        sessionStorage.setItem('uid',currentUser.user_role_mapping[0].id),
                         sessionStorage.setItem('tenantUrl',currentUser.orgnization_registration.url_path);
                      // sessionStorage.setItem('tenantUrl',currentUser.url_path);
                    return user;
                }
            })
        );
       }
       else{
        return this.apiService.post(`${this.loginAPI}nojwt/user/signin${tenant}`, { "loginName": username, "password": password ,"captcha":captcha,"captchaId":captchaId }).pipe(
            map((user: any) => {
                const responseData = user;
                if (responseData.response == null || responseData.status !== 'success')
                    return (responseData.response);
                else {
                    const currentUser = responseData.response;
                     let orgId = currentUser.orgnization_registration.id;
                 //  let orgId = currentUser.organization_id;
                    this.roleId = currentUser.user_role_mapping[0].user_type_id.id, 
                    this.tnntSbjct.next(orgId), this.tnntSbjct.subscribe(s => this.tenantId = s), // this.tenantId = currentUser.orgnization_registration.id,
                        this.userId = currentUser.id, this.currentUserSubject.next(currentUser), 
                        sessionStorage.setItem('currentUser', JSON.stringify(currentUser)),
                        sessionStorage.setItem('session_id', JSON.stringify(currentUser.id)),
                       sessionStorage.setItem('orgName',currentUser.orgnization_registration.orgnization_name), 
                        sessionStorage.setItem('currentTenant', JSON.stringify(currentUser.orgnization_registration.id)), 
                      // sessionStorage.setItem('currentTenant', JSON.stringify(currentUser.organization_id)),
                       sessionStorage.setItem('agentUserId',currentUser.agent_user_id),
                        sessionStorage.setItem('firstname', JSON.stringify(currentUser.first_name)),
                        sessionStorage.setItem('lastname', JSON.stringify(currentUser.last_name)),
                         sessionStorage.setItem('orgid', JSON.stringify(currentUser.orgnization_registration.id)),
                      // sessionStorage.setItem('orgid', JSON.stringify(currentUser.organization_id)),
                        sessionStorage.setItem('userroleA', currentUser.user_role_mapping[0].user_type_id.user_type),
                        sessionStorage.setItem('uid',currentUser.user_role_mapping[0].id),
                         sessionStorage.setItem('tenantUrl',currentUser.orgnization_registration.url_path);
                      // sessionStorage.setItem('tenantUrl',currentUser.url_path);
                    return user;
                }
            })
        );
       }
 }
    // changepassword(data: any): Observable<any> { return this.api.post(`${this.loginAPI}jwt/user/change_password `, data); }
    //new lines added for change password for audit #chandu 04-05-2022
    changepassword(data: any): Observable<any> { return this.api.post(`${this.loginAPI}jwt/user/user_change_password `, data); }
     // Change password lines ended
    // forgotpassword(data: any, tnt: any): Observable<any> { return this.api.post(`${this.loginAPI}nojwt/user/forgot_password${tnt}`, data); }
    //new lines added for forgot password for audit #chandu 04-05-2022
    forgotpassword(data: any, tnt: any): Observable<any> { return this.api.post(`${this.loginAPI}nojwt/user/user_forgot_password${tnt}`, data); }
     // Forgot password lines ended
    logout() {
        let lgnSsn = sessionStorage.getItem('lgnSsn') != null ? JSON.parse(sessionStorage.getItem('lgnSsn')) : null;
        sessionStorage.clear(), this.currentUserSubject.next(null);
        localStorage.clear();
        if (lgnSsn != null)
            sessionStorage.setItem('lgnSsn', JSON.stringify(lgnSsn));
    }

}