import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { Common } from 'src/app/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';

@Component({
  selector: 'app-apartment-status',
  templateUrl: './apartment-status.component.html',
  styleUrls: ['./apartment-status.component.css'],
  providers: [DatePipe]
})
export class ApartmentStatusComponent implements OnInit {
  @Input() title: string;
  @Input() DataEdit: any;
  appointmentStatusForm: FormGroup;

  appartmentDetails: any;
  statusList = [];
  min_date: Date = new Date();
  submitted = false;

  // master_Data: { state_name: string, district_name: string, mandal_name: string} =
  //   { state_name: '', district_name: '', mandal_name: '' };

  constructor(public activeModal: NgbActiveModal
    , private registrationService: RegistrationService
    , private masterService: MasterService
    , private toastr: ToastrService
    , private datePipe: DatePipe
    , private formbuilder: FormBuilder) {
    this.min_date = new Date(this.min_date.setDate(this.min_date.getDate() + 1));
  }

  ngOnInit(): void {
    this.initFormControls();
    this.getAllMasters();
    setTimeout(() => {
      if (this.DataEdit)
        // this.appointmentStatusForm.patchValue({ vaccines_mapping: this.DataEdit.vaccines_mapping });
        this.addVaccine();
    }, 100);

  }

  getAllMasters() {
    const status = this.masterService.getLocations('status/getall');

    forkJoin([status]).subscribe({
      next: res => {
        res.forEach((list: any, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              console.log('No records available');
            else {
              const assignList: any = {
                '0': () => this.statusList = list.data
              }
              assignList[ind]();
            }
          }
        });
      },
      error: err => console.error('something wrong occurred: ', err),
      complete: () => { }
    });
  }

  initFormControls() {
    this.appointmentStatusForm = this.formbuilder.group({
      id: [this.DataEdit.admin_status.id],
      camp_date: [new Date(this.DataEdit.admin_status.camp_date), [Validators.required]],
      mobile_no: [this.DataEdit.admin_status.mobile_no],
      email: [this.DataEdit.email, [Validators.required]],
      name: [this.DataEdit.admin_status.name, [Validators.required]],
      reason: [this.DataEdit.admin_status.reason, [Validators.required]],
      society_id: [this.DataEdit.id],
      status_id: [this.DataEdit.admin_status.status_id, [Validators.required]],
      status_name: [this.DataEdit.admin_status.status_name],
      vaccines_mapping: this.formbuilder.array([]),
    })
  }

  get f() { return this.appointmentStatusForm.controls; }

  vaccinations(): FormArray {
    return this.appointmentStatusForm.get("vaccines_mapping") as FormArray;
  }

  newVaccine(item: any): FormGroup {
    return this.formbuilder.group({
      id: [item.id],
      vaccine_id: [item.vaccine_id],
      vaccine_name: [item.vaccine_name],
      dose: [item.dose],
      members_to_vaccined: [item.members_to_vaccined],
      price: [item.price, Validators.required],
      is_active: [true]
    });
  }

  addVaccine() {
    this.DataEdit.vaccines_mapping.forEach((e: any) => this.vaccinations().push(this.newVaccine(e)));
  }

  onSubmit(event: any) {
    event.preventDefault(), this.submitted = true, Common.getFormValidationErrors(this.appointmentStatusForm);
    if (this.appointmentStatusForm.invalid) {
      this.toastr.info('Please Enter All Mandatory Fields');
      return;
    }
    else {
      const saveData = JSON.parse(JSON.stringify(this.appointmentStatusForm.getRawValue()));
      saveData.camp_date = this.datePipe.transform(saveData.camp_date, 'yyyy-MM-dd HH:mm:ss');
      saveData.status_name = this.statusList.find(e => e.id == saveData.status_id).status_name;
      saveData.status_id = +saveData.status_id;

      for (let index = 0; index < saveData.vaccines_mapping.length; index++) {
        delete saveData.vaccines_mapping[index].vaccine_id;
        delete saveData.vaccines_mapping[index].vaccine_name;
        delete saveData.vaccines_mapping[index].dose;
        delete saveData.vaccines_mapping[index].members_to_vaccined;
        delete saveData.vaccines_mapping[index].is_active;
        saveData.vaccines_mapping[index].price = +saveData.vaccines_mapping[index].price;
      }

      this.registrationService.saveApartment(saveData).subscribe(resp => {
        if (resp.status == 'OK') {
          this.toastr.success(resp.message);
          this.activeModal.close('changes saved');
        }
      })
    }
  }

  submitForm() {
    document.getElementById('btnsubmit').click();
  }
}
