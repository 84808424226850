import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MasterService } from 'src/app/shared/services/master.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { forkJoin, Subject, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-dr-template-controls',
  templateUrl: './dr-template-controls.component.html',
  styleUrls: ['./dr-template-controls.component.css']
})

export class DrTemplateControlsComponent implements OnInit {
  drugnamesList: any; drug_list = []
  chiefComplaintList: Array<any> = [];
  diagnosislList: Array<any> = [];
  drugNameLst: Array<any> = [];
  directionLst: Array<any> = [];
  dosageLst: Array<any> = [];
  directionsList: any;
  instructionList: any;
  investigationsList: any;
  drugtypesList: any;
  qualificationsList: any;
  specializationList: any;
  superSpecialityDegreeList: any;
  gendersList: any;
  lstLabs = [];
  lstPhmcy = [];
  diagnosysgroupList: any;
  optionChanged: any = '';
  checkedobj1: any;
  dataOnOptionChange: any = [];
  selectedName: any;
  id1: any;
  count: number;
  emailListObject: any = [];
  unselIndex: any;
  indexCh: any;
  unselIndex1: any;
  duplicateChief: any [];
  tmplName = ''; pocOptns = '';
  dropdownSettings_CC = {}; drugNameSettings = {}; dropdownSettings_PD = {};
  dropdownSettings_INV= {}; dropdownSettings_DGN = {};
  cc = ''; pd = ''; inv = ''; dgn = ''; drTemplateForm: FormGroup;
  dN: string;

  constructor(public activeModal: NgbActiveModal
    , private masterService: MasterService
    ,  private toastr: ToastrService
    , private registrationService: RegistrationService
    , private formBuilder: FormBuilder) {
    this.activeModal.close('changes saved');
  }

  ngOnInit(): void {
    this.getAllMaster(); this.defaultDropDowns();this.form();
  }

  defaultDropDowns() {
    this.dropdownSettings_CC = {
      singleSelection: false,
      idField: 'id',
      textField: 'chief_complaint',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettings_PD = {
      singleSelection: false,
      idField: 'id',
      textField: 'diagnosis_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettings_INV = {
      singleSelection: false,
      idField: 'id',
      textField: 'investigation',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };

    this.dropdownSettings_DGN = {
      singleSelection: false,
      idField: 'id',
      textField: 'drug_type',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
    this.drugNameSettings = {
      singleSelection: true,
      idField: 'id',
      textField: 'drug_name',
      selectAllText: 'Select All',
      unSelectAllText: 'Deselect All',
      itemsShowLimit: 1,
      // enableCheckAll: false,
      allowSearchFilter: true
    };
  }
  onDrgSlct(item: any, ctrl: string) {
    if (ctrl == 'dt')
      this.drugtypesList = [], this.dN = '', this.drTemplateForm.patchValue({ drugs: { drugType: item.id, drugName: '' } }), this.drugnamesList = [],
        this.LoadDrugsbyDTId(item.id);
    else
      this.drTemplateForm.patchValue({ drugs: { drugName: item.id } });
  }

  onDrgDeSlct(ctrl: string) {
    if (ctrl == 'dt')
      this.drTemplateForm.patchValue({ drugs: { drugType: '', drugName: '' } }), this.drug_list = [], this.drug_list = [...this.drug_list];
    else
      this.drTemplateForm.patchValue({ drugs: { drugName: '' } });
  }


 
  LoadDrugsbyDTId(dtId: any) {
    this.masterService.searchbyIdMasters(`drugtypemapping/getdrugbydrugtypeid?drugTypeId=${dtId}`).subscribe(res => {
      if (res) {
        this.drugnamesList = res.data;
        if (this.drugnamesList.length > 0)
          this.drugnamesList.forEach(e => { this.drug_list.push({ id: e.drug_id.id, drug_name: e.drug_id.drug_name }); });
        this.drug_list = [...this.drug_list];
      }
    });
  }
  getAllMaster() {
    const chiefComplaint = this.masterService.getAll('getallchiefcomplaints');
    const diagnosis = this.masterService.getAll('getalldiagnosis');
    const drugtypes = this.masterService.getAll('getalldrugtypes');
    const investigations = this.masterService.getAll('getallinvestigations');
    const directions = this.masterService.getAll('getalldirections');
    const instruction = this.masterService.getAll('getallinstruction');
    const qualifications = this.masterService.getAll('getallqualifications');
    const specialization = this.masterService.getAll('getallspecialization');
    const superSpecialityDegree = this.masterService.getAll('getallsuperspecialityqualifications');
    const genders = this.masterService.getAll('getallgenders');
    const diagnosismedicine = this.masterService.getAll('getalldiagnosis');
    const labsList = this.registrationService.getAll('lab/getalllabregistrations');
    const phrmcyList = this.registrationService.getByAllPharmas();

    forkJoin([chiefComplaint, diagnosis, drugtypes, investigations, directions, instruction, qualifications,
      specialization, superSpecialityDegree, genders, diagnosismedicine, labsList, phrmcyList]).subscribe({
        next: res => {
          res.forEach((list, ind) => {
            if (list.status === 'OK') {
              if (list.data == null || list.data.length == 0)
                console.log('No records available', 'Plan of Care');
              else {
                const assignList = {
                  '0': () => this.chiefComplaintList = list.data,
                  '1': () => this.diagnosislList = list.data,
                  '2': () => this.drugtypesList = list.data,
                  '3': () => this.investigationsList = list.data,
                  '4': () => this.directionsList = list.data,
                  '5': () => this.instructionList = list.data,
                  '6': () => this.qualificationsList = list.data,
                  '7': () => this.specializationList = list.data,
                  '8': () => this.superSpecialityDegreeList = list.data,
                  '9': () => this.gendersList = list.data,
                  '10': () => this.diagnosysgroupList = list.data,
                  /* {
                    if (this.isLcl) {
                      let dgnsData: any[] = list.data;
                      console.log(this.addOthersPD(dgnsData)); // dgnsData = dgnsData.unshift({ id: '0', diagnosis_name: 'OTHER', is_active: true });
                      this.diagnosysgroupList = dgnsData;
                    }
                    else
                      this.diagnosysgroupList = list.data;
                  }, */
                  '11': () => this.lstLabs = list.data,
                  '12': () => this.lstPhmcy = list.data,
                }
                assignList[ind]();
              }
            }
          });
          // this.duplicateChief = this.chiefComplaintList;
          // console.log(this.duplicateChief,"duplicated chief compliants...");
          
        },
        error: err => console.error('something wrong occurred: ', err),
        complete: () => {
          this.qualificationsList.sort((a, b) => a.id - b.id);
          // this.getDoctorDetails(); //this.userData.id
        },
      });
  }

  form() {
    this.drTemplateForm = this.formBuilder.group({
      id: ['0'],
      module_name: [''],
      ChiefComplaints: ['', Validators.required],
      ProvisionalDiagnosis: ['', Validators.required],
      Investigations: ['', Validators.required],
      DrugTypes: ['', Validators.required],
      dN: [''],
    })
  }

  OnSave() {
    let result = this.drTemplateForm.getRawValue(); let arr1 = [], output: any;
    result.DrugTypes.forEach(e => arr1.push({id:e.id, drug_type: e.drug_type, drug_names: result.dN}));
    delete result.dN; delete result.DrugTypes;
    result.DrugTypes = arr1;

    output = { id: 0, module_name: this.tmplName, module_data: result };
    ['id', 'module_name'].forEach(e => { delete output.module_data[e]; });
    // result = { id: 0, module_name: this.tmplName, module_data: { } };
    if (output) {
      this.registrationService.saveDctrMdule(JSON.stringify(output)).subscribe(s => {
        if (s.status === 'OK')
          this.toastr.success(s.message), this.activeModal.close('changes saved');
        else
          this.toastr.error(s.message);
      });
    }
  }
 

}