<div class="row">
  <div class="container-fluid pl-0 pr-0">
    <div class="row">
      <div class="col-xl-12">
        <div class="card card-header-actions mx-auto">
          <div class="card-header">
            Pharmacist/Healthcare Professional Session List
            <button class="btn btn-blue btn-icon float-right" title="Refresh" (click)="getagentdata()">
              <i class="fa fa-retweet"></i>
            </button>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table datatable id="agentlistcallTable" class="table table-striped table-bordered table-sm"
                style="width:100%">
                <thead class="text-center">
                  <tr>
                    <th>S No</th>
                    <th>Doctor Name</th>
                    <th>Patient Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let agent of agentlist;let i=index;">
                    <td class="text-center">{{i+1}}</td>
                    <td class="text-center">{{agent.doctorName}}</td>
                    <td class="text-center">{{agent.beneficiaryName}}</td>
                    <td class="text-center" (click)="join(agent.sessionId)">
                      <button type="button" class="btn btn-success btn-sm" [disabled]="!agent.sessionId">JOIN</button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>