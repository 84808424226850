<div class="modal-header">
    <h4 class="modal-title">{{title}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="ndlOfcrForm" (ngSubmit)="onSubmit($event)">
        <div class="container-fluid">
            <div class="row small">
                <!-- <div class="col-xl-4">
                    <div class="form-group">
                        <label for="inputPassword" class="col-form-label">Organization Name
                        </label>
                        <div class="">
                            <select class="form-control form-control-sm"
                                formControlName="orgnization_id" id="orgnization_id" 
                                [ngClass]="{ 'is-invalid': submitted && f.orgnization_id.errors }">
                                <option value="">Select</option>
                                <option *ngFor="let item of organizationList" [value]="item.id">
                                    {{item.orgnization_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.orgnization_id.errors" class="invalid-feedback">
                                <div *ngIf="f.orgnization_id.errors.required">Organization Name is
                                    required</div>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Full Name <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" formControlName="full_name" id="full_name" autocomplete="off"
                                placeholder="Full Name" maxlength="20"
                                [ngClass]="{ 'is-invalid': submitted && f.full_name.errors }"
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.full_name.errors" class="invalid-feedback">
                                <div *ngIf="f.full_name.errors.required">Full Name is required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Hospital <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="hospital_id" id="hospital_id" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && f.hospital_id.errors }">
                                <option value="">Select</option>
                                <option *ngFor="let item  of lstGrid" [value]="item.id">
                                    {{item.hospital_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.hospital_id.errors" class="invalid-feedback">
                                <div *ngIf="f.hospital_id.errors.required">Hospital is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label"> Email <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" formControlName="email" id="email" placeholder=" Email"
                                autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.email.errors }"
                                class="form-control form-control-sm" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
                            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                <div *ngIf="f.email.errors.pattern">
                                    sample email address is x@yahoo.com
                                </div>
                                <div *ngIf="f.email.errors.required">
                                    Email is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label"> Pincode <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" formControlName="pincode" id="pincode" placeholder=" Pincode"
                                autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.pincode.errors }"
                                class="form-control form-control-sm" maxlength="6">
                            <div *ngIf="submitted && f.pincode.errors" class="invalid-feedback">
                                <div *ngIf="f.pincode.errors.required">
                                    Pincode is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Address <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" formControlName="address" id="address" placeholder="Address"
                                autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.address.errors }"
                                class="form-control form-control-sm">
                            <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                <!-- <div *ngIf="f.address.errors.email">
                             sample email address is x@yahoo.com
                       </div> -->
                                <div *ngIf="f.address.errors.required">
                                    Address is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label"> Contact Number <span class="text-danger">*</span> </label>
                        <div>
                            <input type="text" formControlName="mobile" id="mobile" autocomplete="off"
                                placeholder="Contact Number" [maxlength]="maxLngth" [pattern]="mobPtrn"
                                onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }"
                                class="form-control form-control-sm" (keyup)="onKeyNmbr('mobile', $event.target.value)">
                            <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                <div *ngIf="f.mobile.errors.required"> Contact Number is required</div>
                                <!-- <div *ngIf="f.mobile.errors.required">Please Enter 10 digit contact Number</div> -->
                                <div *ngIf="f.mobile.errors.pattern">Contact Number {{mobErrMsg}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">{{isECLINIC ? 'States' : 'State'}} <span
                                class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="state_id" id="state_id" #State
                                (change)="locationChanged(State.value,'district')" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }">
                                <option value="">Select</option>
                                <option *ngFor="let state of stateList" [value]="state.id">
                                    {{state.state_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.state_id.errors" class="invalid-feedback">
                                <div *ngIf="f.state_id.errors.required">
                                    {{isECLINIC ? 'States' : 'State'}} is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">{{isECLINIC ? 'State Capitals' : 'District'}} <span
                                class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="district_id" id="district_id" #District
                                (change)="locationChanged(District.value,'mandal')" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && f.state_id.errors }">
                                <option value="">Select</option>
                                <option *ngFor="let district of districtList" [value]="district.id">
                                    {{district.district_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.district_id.errors" class="invalid-feedback">
                                <div *ngIf="f.district_id.errors.required">
                                    {{isECLINIC ? 'State Capitals' : 'District'}} is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">{{isECLINIC ? 'Local Government Areas ' : 'Mandal '}}
                            <span class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="mandal_id" id="mandal_id" #Mandal
                                (change)="locationChanged(Mandal.value,'village')" class="form-control form-control-sm"
                                [ngClass]="{ 'is-invalid': submitted && f.mandal_id.errors }">
                                <option value="">Select</option>
                                <option *ngFor="let mandal of mandalList" [value]="mandal.id">
                                    {{mandal.mandal_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.mandal_id.errors" class="invalid-feedback">
                                <div *ngIf="f.mandal_id.errors.required">
                                    {{isECLINIC ? 'Local Government Areas ' : 'Mandal '}} is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-xl-4">
                    <div class="form-group">
                        <label class="control-label">Village <span *ngIf="!isECLINIC" class="text-danger">*</span> </label>
                        <div>
                            <select formControlName="village_id" id="village_id"
                                [ngClass]="{ 'is-invalid': submitted && f.village_id.errors }"
                                class="form-control form-control-sm">
                                <option value="">Select</option>
                                <option *ngFor="let village of villageList" [value]="village.id">
                                    {{village.village_name}}</option>
                            </select>
                            <div *ngIf="submitted && f.village_id.errors" class="invalid-feedback">
                                <div *ngIf="f.village_id.errors.required">Village
                                    is required
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-xl-6">
                    <label class="control-label">Status <span class="text-danger">*</span></label>
                    <div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input class="custom-control-input" formControlName="is_active" [value]="true"
                                id="customRadio1" type="radio">
                            <label class="custom-control-label" for="customRadio1">Active</label>
                        </div>
                        <div class="custom-control custom-radio custom-control-inline">
                            <input class="custom-control-input" formControlName="is_active" [value]="false"
                                id="customRadio2" type="radio">
                            <label class="custom-control-label" for="customRadio2">Inactive</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <input type="submit" id="btnsubmit" style="display: none;">
    </form>
</div>
<div class="modal-footer">
    <input type="submit" class="btn btn-sm btn-primary" (click)="submitForm()" value="Submit">&nbsp;&nbsp;
    <button type="button" class="btn btn-sm btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>