import { Component, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { forkJoin } from 'rxjs';

import { RegistrationService } from 'src/app/shared/services/registration.service';
import { MasterService } from 'src/app/shared/services/master.service';
import { ReportService } from 'src/app/shared/services/report.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { Common } from 'src/app/common';
import { AgentService } from 'src/app/shared/services/agent.service';
import { AgentRegistrationComponent } from '../agent-registration/agent-registration.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DssFlowComponent } from '../dss-flow/dss-flow.component';
import * as XLSX from 'xlsx';
import { ExcelDownloadService } from 'src/app/shared/services/excel-download.service';
declare let $: any;

@Component({
  selector: 'app-agent-list',
  templateUrl: './agent-list.component.html',
  styleUrls: ['./agent-list.component.css'],
  providers: [DatePipe],
})

export class AgentListComponent extends BaseComponent implements OnInit {

  modalTitle: string = ''; lstAgents: Array<any> = []; lstLabs = []; lstPharma = []; lstLocTypes = [];

  search_string: any = '';
  submitted = false;
  CurpageNumber: any = 0;
  NextpageNumber: any = 0;
  PrevpageNumber: any = 0;
  totalElements: any = 0;
  rowIndex: any = 0;
  pageSize: any = 10;
  agenttSearchForm: FormGroup;
  excelData: any = [];
  // fileName = 'Agent list.xlsx';

  constructor(config: NgbModalConfig
    , private toastr: ToastrService
    , private datePipe: DatePipe
    , private regService: RegistrationService
    , private agentSvc: AgentService
    , private reportService: ReportService
    , private formBuilder: FormBuilder
    , private modalService: NgbModal
    , private authSvc: AuthenticationService,
    private excelDownloadService:ExcelDownloadService
    , private masterService: MasterService) {
      super(); config.backdrop = 'static', config.keyboard = false; 
      authSvc.crntOrgIDSubject.subscribe(s => { this.lstAgents = []
        //  Common.loadDataTable(this.lstAgents, '#agentTable');
        if (location.href.includes('home/agent'))
          this.ngOnInit();
      }), authSvc.isShowGlblOrg.next(true);
      config.backdrop = 'static';
      config.keyboard = false;
    }

  ngOnInit(): void {
    this.getMasters();
    this.agenttSearchForm = this.formBuilder.group({ search_string: ['', Validators.required] });
    this.getAgentsData(this.CurpageNumber);
  }

  openAgentModal(agent: any = null) {
    const modalRef = this.modalService.open(AgentRegistrationComponent, { scrollable: true, size: 'xl' }); // AgentControlsComponent
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.agent = agent;
    modalRef.result.then(result => {
      if (result == 'changes saved'){
       
        this.getAgentsData(this.CurpageNumber);
      } 
    }, (reason) => { });
  }

  ChkLimit(): boolean {
    let result: boolean;
    this.regService.chkLimit('agent').subscribe(res => {
      if (res) {
        if (res.status == "OK")
          result = true, this.openAgentModal();
        else
          result = false, this.toastr.warning(res.message);
      }
    });
    return result;
  }

  onAgentEdit(id: any) {
    if (id)
      this.regService.getAPI(`agent/getagentrecordbyid`, `&agentId=${id}`).subscribe(res => { this.openAgentModal(res.data) });
  }

  getMasters() {
    const labs = this.regService.getAll('lab/getalllabregistrations');
    const pharmas = this.regService.getAll('pharmacy/getallrecords');
    const locTypes = this.masterService.getAll('getalllocationtypes');

    forkJoin([labs, pharmas, locTypes]).subscribe({
      next: res => {
        res.forEach((list, ind) => {
          if (list.status === 'OK') {
            if (list.data == null || list.data.length == 0)
              console.log('No records available');
            else {
              const assignList = {
                '0': () => this.lstLabs = list.data,
                '1': () => this.lstPharma = list.data,
                '2': () => this.lstLocTypes = list.data,
              }
              assignList[ind]();
            }
          }
        });
      },
      error: err => console.error('something wrong occurred: ', err),
      // complete: () => { this.LoadGrid(); }
    });
  }

  getName(id: any, type: string = 'pharma'): string {
    let name = '';
    if (type == 'lab')
      name = this.lstLabs.filter(f => f.id == id)[0]?.lab_name ?? '';
    else if (type == 'loc')
      name = this.lstLocTypes.filter(f => f.id == id)[0]?.location_type ?? '';
    else
      name = this.lstPharma.filter(f => f.id == id)[0]?.pharmacy_name ?? '';
    return name;
  }

  // LoadGrid() {
  //   this.regService.postAPI('agent/web_list', null).subscribe(res => { // getAll('agent/getagentrecords')
  //     if (res.status === 'ACCEPTED') { // message == 'Success'
  //       if (res.data == null || res.data.length == 0)
  //         this.toastr.info('No records available', 'Agent Registration List');
  //       else
  //         this.lstAgents = res.data;
  //       Common.loadDataTable(this.lstAgents, '#agentTable');
  //     }
  //   });
  // }

  onDelete(id: any) {
    if (confirm(`Are you sure you want delete record?`)) {
      this.regService.delByAgentId(id).subscribe(res => {
        if (res.status == "OK")
          this.getAgentsData, this.toastr.success('Record Deleted Successfully');
      }, error => { this.toastr.error('An Error Occured'); });
    }
  }
  
  // Agent pagination with global search

  getAgentsData(page_number) {
    this.submitted = true;
    this.search_string = this.agenttSearchForm.value.search_string.toString().trim();
    let payload = { "page": page_number, "search_string": this.search_string, "size": this.pageSize };
    this.regService.getAgentsBySearch('POST', payload).subscribe(res => {
      if (res?.status == "ACCEPTED") {
        if (res.data['content'].length > 0) {
          this.totalElements = res['data']['totalElements'];
          this.lstAgents = res.data['content'];
          this.excelData = this.lstAgents;
          
          if ((res.data["totalPages"] == 1) || (this.lstAgents.length <= this.pageSize && res.data["totalPages"] == this.NextpageNumber + 1)) {
            this.CurpageNumber = res.data["number"];
           // this.NextpageNumber = 0;
            this.PrevpageNumber = res.data["number"] - 1;

          } else {
            this.CurpageNumber = res.data["number"];
            this.NextpageNumber = res.data["number"] + 1;
            this.PrevpageNumber = res.data["number"] - 1;
            this.rowIndex = 0;
          }
          this.rowIndex = this.CurpageNumber * this.pageSize;

        }
        else {
          this.toastr.warning("No matching records found", "Pharmacist/Healthcare Professional List");
          this.CurpageNumber = 0;
          this.NextpageNumber = 0;
          this.PrevpageNumber = 0;
          this.totalElements = 0;
          this.lstAgents = [];
        }
      }
      else {
        this.lstAgents = [];
        this.toastr.warning(res.message, 'Pharmacist/Healthcare Professional List');
        this.CurpageNumber = 0;
        this.NextpageNumber = 0;
        this.PrevpageNumber = 0;
        this.totalElements = 0;
      }
    });

  }

  downLoadExcel(){
    const fileName = 'Pharmacist or Healthcare Professional reg'
    this.search_string = ''
    let payload = {"page":0,"search_string":"","size":this.totalElements}
    this.regService.getAgentsBySearch('POST', payload).subscribe(res => {
      if (res.status == "ACCEPTED") {
        if (res.data['content'].length > 0) {
          this.excelData =res.data['content'];
          var excelData = this.excelData;
          if(excelData.length>0){
            excelData.map(res =>{
              res.fullName = (res.first_name +' '+ res.last_name),
              res.lab_preferred = this.getName(res?.preferred_lab_id, 'lab'),
              res.preferred_pharmacy = this.getName(res?.preferred_pharmacy_id),
              res.location_preferred = this.getName(res?.location_type_id, 'loc')
            })
            const excelHeaders = ['District','Block','Centre','Pharmacist/Healthcare Professional Name','Pharmacist/Healthcare Professional Type', 'Pharmacist/Healthcare Professional No', 'Lab Preferred', 'Pharmacy Preferred','Location Type'];
            const internalHeaders = ['district_name','mandal_name','center_name','fullName','center_type', 'mobile','lab_preferred','preferred_pharmacy','location_preferred'];
            this.toastr.success('Please wait excel is downloading')
            this.excelDownloadService.downloadExcel(excelHeaders,internalHeaders,fileName,excelData)
          }
          else{
            this.toastr.error('No data to download')
          }
        }
      }
    });
     
    
  }
  
  openDSSForm(){
    const modalRef = this.modalService.open(DssFlowComponent, { scrollable: true, size: 'xl' }); 
  }
 


}