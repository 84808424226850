<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        Favorite
                    </div>
                    <form [formGroup]="favoriteForm" (ngSubmit)="onSubmit($event)">
                        <div class="card-body group">

                            <div class="row">
                                <div class="col-xl-8 offset-xl-2">
                                    <div class="form-group">
                                        <label class="control-label">Chief Complaints <span class="text-danger">*</span>
                                        </label>
                                        <div>
                                            <ng-multiselect-dropdown [settings]="dropdownSettings_cheifcomplaint"
                                                [placeholder]="'Select'" [data]="chiefComplaintList"
                                                formControlName="chief_complaint">
                                            </ng-multiselect-dropdown>
                                        </div>

                                    </div>

                                </div>

                                <div class="col-xl-8 offset-xl-2">
                                    <div class="form-group">
                                        <label class="control-label">Lab Investigations <span
                                                class="text-danger">*</span>
                                        </label>
                                        <div>
                                            <ng-multiselect-dropdown [settings]="dropdownSettings_investigation"
                                                [placeholder]="'Select'" [data]="investigationsList"
                                                formControlName="investigation"></ng-multiselect-dropdown>
                                        </div>

                                    </div>

                                </div>

                                <!-- <div class="col-xl-8 offset-xl-2">
                                    <div class="form-group">
                                        <label class="control-label">Diagnosis <span class="text-danger">*</span>
                                        </label>
                                        <div>
                                            <ng-multiselect-dropdown [settings]="dropdownSettings_cheifcomplaint"
                                                [placeholder]="'Select'" [data]="chiefComplaintList"
                                                [(ngModel)]="chiefComplaints"></ng-multiselect-dropdown>
                                        </div>

                                    </div>

                                </div> -->
                            </div>

                            <div class="row mt-2">

                                <div class="col-xl-12 text-center">

                                    <!-- *ngIf="setPrivilege(Screen_Name.UploadConsentForm, Permission.Add)" -->
                                    <input type="submit" class="btn btn-sm btn-primary" value="Submit">

                                </div>
                            </div>

                        </div>
                    </form>

                </div>
            </div>
        </div>

    </div>
</div>