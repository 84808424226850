
<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        
                    TENANT CONFIGURATIONS
                      <button class="btn btn-blue btn-icon float-right" (click)="openform()"  id="applist" data-toggle="modal" data-target="#appModal"
                       >
                      <i class="fa fa-plus"></i>
                  </button>
                  <button class="btn btn-blue btn-icon float-right" (click)="openModal()" 
                  >
                   <i class="fa fa-eye"></i>
               </button>
                    </div>
                    <div class="col-xl-1 text-right" style="padding-top: 2%;">
                        <!-- <button type="submit" (click)="onAddEdit()" class="btn btn-info mb-2 mr-1 text-center"><i class="fa fa-plus"></i> Add</button> -->
                        <button type="button" class="btn btn-secondary mb-2 mr-1 text-center" *ngIf= "excelData.length>0" (click)="downLoadExcel()" >
                           <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                    </div>
                    <div class="card-body">
                        <div class="table-responsive">
                            <table datatable id="permissionTable" class="table table-striped table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>S No </th>
                                        <th>Flag Name</th>
                                        <th>Feature Name</th>
                                        <th>Reason</th>
                                        <th>Status</th>
                                        <th class="text-center">Actions</th>
                                        <!-- <th>Update</th> -->
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of Flagslist; let i=index">
                                        <td style="width: 8%;" class="text-center">{{i+1}}</td>
                                        <td>{{item.feature_id.feature_name}}</td>
                                        <td>{{item.feature_id.flag_name}}</td>
                                        <td>{{item.feature_id.reason}}</td>
                                        <td ><input type="checkbox" [(ngModel)]="item.patient" (click)="chkbxclick(item)" ></td>
                                        <td class="text-center">  <span>
                                            <a 
                                                title="Edit"
                                                (click)="onFeatureEdit(item)"
                                                class="badge badge-info badge-pill"><i
                                                    class="fa fa-edit text-white pointer"></i></a>&nbsp;
                                        </span>
                                        </td>
                                       
                                    </tr>
                                </tbody>
                            </table>
                            <div class="col-sm-12 text-center">
                                <button type="button" class="btn btn-primary text-right" (click)="updateFeature()">Update</button>
                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade t10" id="appModal" *ngIf="isShowModal">
    <div class="modal-dialog">
      <div class="modal-content modalpopborder">  
        <div class="modal-header headerbg modalpopborderhead p-2">
          <h6 class="modal-title text-white">Add Feature</h6>
          <button type="button" id="closeid" class="close text-white" (click)="close()" >&times;</button>
        </div>  
        <div class="modal-body modalbodybg">
         <form [formGroup]="featureForm"  >
             <div class="row">
                <div class="col-md-6 mt-2">
                    <label for="flag_name">Flag Name <span class="text-danger">*</span></label>
                    <input type="text" autocomplete="off"  class="form-control form-control-sm" id="feature_name" formControlName="feature_name">
                   
                </div>
                <div class="col-md-6 mt-2">
                    <label for="feature_name">Feature Name <span class="text-danger">*</span></label>
                    <input type="text" autocomplete="off" class="form-control form-control-sm" id="flag_name" formControlName="flag_name">
                   
                </div>

             </div>
             <div class="row">
                <div class="col-md-6">
                    <div class="form-group">
                        <label class="control-label"><span>Reason</span> <span class="text-danger">*</span>
                        </label>
                        <div>
                            <textarea rows="2" class="form-control form-control-sm" style="resize: none; height: 100px;"
                                maxlength="150"
                               
                              formControlName="reason">
                            </textarea>
                          
                        </div>
                    </div>
                </div>
                
                <div class="col-md-4 " style="margin-top: 60px;">
                    <label></label>
                 <button type="button" value="submit" class="btn btn-sm btn-success" (click)="savePermission()">Add</button>
                 <button type="button" value="submit" class="btn btn-sm btn-danger ml-2" (click)="close()">Cancel</button>
                </div>
             </div>
          
         </form>
        </div>
      </div>
    </div>
    </div>