<div class="row">
    <div class="container-fluid pl-0 pr-0">
        <div class="row">
            <div class="col-xl-12">
                <div class="card card-header-actions mx-auto">
                    <div class="card-header">
                        <!-- Assessment Management  -->
                        Assets Report
                        <button class="btn btn-blue btn-icon float-right"
                            
                            > <i class="fa fa-plus"></i>
                        </button>
                    </div>
                    
                    <div class="card-body">
                        <div class="">
                            <div class="custom-control-inline custom-control custom-radio">
                                <input  checked class="custom-control-input" id="customRadio11"
                                    type="radio" name="is_active" (change)="isShow=true;getcompanyByorgid()"> <!-- getAllCompanies() -->
                                <label class="custom-control-label" for="customRadio11"><b>Register Assets</b></label>
                            </div>
                            <div class="custom-control-inline custom-control custom-radio">
                                <input  class="custom-control-input " id="customRadio22"
                                    type="radio" name="is_active" (change)="isShow=false;getcorporatebyorgid(this.OrgId)"> <!-- getAllCorporte() -->
                                <label class="custom-control-label" for="customRadio22"><b>View Assets</b></label>
                            </div>
                        </div> <br />
                        <div class="table-responsive"  [hidden]="!isShow">
                            <table datatable id="companyTable" class="table table-striped table-bordered table-sm"
                                style="width:100%">
                                <thead>
                                    <tr>
                                        <th>S No</th>
                                        <th>Assets Name</th>
                                        <!-- <th>Assessment Name</th> -->
                                        <!-- Assets Report -->
                                        <th>Quantity</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr><h6>Spoke for 4G Sim Connectivity</h6></tr>
                                    <tr *ngFor="let item of companyList;let i = index">
                                        <td>{{i+1}}</td>
                                        <td>{{item.assessment_name}}</td>
                                        <td>{{item.quantity}}</td>
                                        <td>
                                            <button type="button" class="btn btn-success" data-toggle="modal" data-target="#myModal"(click)="assestdataget(item)">Register</button>
                                        </td>
                                    </tr>
                                    <tr><h6>Spoke for RF Connectivity</h6></tr>
                                    <tr *ngFor="let item of companyList1;let i = index">
                                        <td>{{i+1}}</td>
                                        <td>{{item.assessment_name}}</td>
                                        <td>{{item.quantity}}</td>
                                        <td>
                                            <button type="button" class="btn btn-success" data-toggle="modal" data-target="#myModal" (click)="assestdataget(item)">Register</button>
                                        </td>
                                    </tr>
                                    <tr><h6>Spoke for VSA Connectivity</h6></tr>
                                    <tr *ngFor="let item of companyList2;let i = index">
                                        <td>{{i+1}}</td>
                                        <td>{{item.assessment_name}}</td>
                                        <td>{{item.quantity}}</td>
                                        <td>
                                            <button type="button" class="btn btn-success" data-toggle="modal" data-target="#myModal" (click)="assestdataget(item)">Register</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                       
                        <div class="table-responsive" [hidden]="isShow">
                            <table datatable id="asseschecklist" class="table table-striped table-bordered table-sm"
                                style="width:100%">
                                <thead>
                                    <tr>
                                        <th>S.No</th>
                                        <th>Assets Name</th>
                                        <!-- <th>Assessment Name</th> -->
                                        <th>QR Code Id</th>
                                        <th>Verify</th>
                                        <th>Scanned QR Code Date & Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr  *ngFor="let asset of assesckeckList;let i = index">
                                        <td>{{i+1}}</td>
                                        <td>{{asset.assessment_name}}</td>
                                        <td>{{asset.qr_code}}</td>
                                        <td>
                                            <input type="checkbox" class="radio" style="margin-left: 20px" [value]="asset.checked" name="verify" id="vrfy"   (change)="onNativeChange($event,asset)">
                                        </td>
                                        <td>{{asset.qr_code_time }}</td>
                                      
                                    </tr>
                                   
                                 
                                </tbody>
                            </table>
                            
                        </div>

                    </div>
                </div>  
            </div>

            <div class="modal fade" id="myModal">
                <div class="modal-dialog modal-dialog-centered">
                  <div class="modal-content">
                    <div class="modal-body">
                      <h2 class="text-center">Assessment</h2>

                      <form >
                        <div class="form-group">
                          <label for="rs"><b>QR Code Text</b></label>
                          <input type="text" class="form-control" name="qrcodetxt" [(ngModel)]="qrcodetxt" placeholder="QR Code Text" id="rs">
                        </div>
                        <div class="form-group" [hidden]="isShow">
                          <label for="pwd"><b>Remarks</b></label>
                          <input type="text" class="form-control" name="remrks" [(ngModel)]="remrks" placeholder="Remarks" id="pwd">
                        </div>
                        <div class="text-center">
                            <button type="button" class="btn btn-success mx-3" (click)="regAsset()" [hidden]="!isShow">Add</button>
                            <button type="button" class="btn btn-success mx-3" (click)="viewregAsset()" [hidden]="isShow">Add</button>
                            <button type="button" class="btn btn-light" data-dismiss="modal"(click)="assetcancel()">Cancel</button>
                        </div>
                        
                      </form>

                    </div>
                  </div>
                </div>
              </div>


        </div>
    </div>
</div>




