
<div class="row">
    <div class="container-fluid pl-0 pr-0">
      <div class="row">
        <div class="col-xl-12">
          <div class="card card-header-actions mx-auto">
            <div class="card-header">
            Attendance Report
            </div>
  
            <div class="card-body">
              <form [formGroup]="attendanceForm" (ngSubmit)="LoadData()">
                <div class="row ">
                  <div class="container">
                    <div class="row ">
                      <div class='col-md-2'>
                        <div class="form-group">
                          <div class='date' id='datetimepicker6'>
                            <label class="control-label">From Date <span class="text-danger">*</span> </label>
                            <div>
                              <input id="start_date" name="start_date" type="text" autocomplete="off" onpaste="return false"
                                placeholder="From" bsDatepicker #dp="bsDatepicker" (focus)="dp.show()" (focus)="dp1.hide()"
                                formControlName="start_date" [maxDate]="max_date"
                                (bsValueChange)="onFromDateChange($event)" onkeypress='return event.charCode == 8'
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }"
                                [ngClass]="{ 'is-invalid': submitted && f.start_date.errors }"
                                class="form-control form-control-sm">
                              <div *ngIf="submitted && f.start_date.errors" class="invalid-feedback">
                                <div *ngIf="f.start_date.errors.required">From Date is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class='col-md-2'>
                        <div class="form-group">
                          <div class='date' id='datetimepicker7'>
                            <label class="control-label">To Date <span class="text-danger">*</span> </label>
                            <div>
                              <input id="end_date" name="end_date" type="text" autocomplete="off" onpaste="return false"
                                placeholder="To" bsDatepicker formControlName="end_date"  [minDate]="f.start_date.value" [maxDate]="max_date" (focus)="dp.hide()"
                                bsDatepicker #dp1="bsDatepicker" (focus)="dp1.show()" (bsValueChange)="onToDateChange($event)"
                                [bsConfig]="{ dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false}"
                                [ngClass]="{ 'is-invalid': submitted && f.end_date.errors }"
                                class="form-control form-control-sm" onkeypress='return event.charCode == 8'>
                              <div *ngIf="submitted && f.end_date.errors" class="invalid-feedback">
                                <div *ngIf="f.end_date.errors.required">To Date is required</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-2">
                        <div class="form-group">
                            <label class="control-label">User Type <span class="text-danger">*</span> </label>
                            <div>
                                <select class="form-control form-control-sm" formControlName="user_type" (change)="onUserChange($event)"
                                    [ngClass]="{ 'is-invalid': submitted && f.user_type.errors }">
    
                                    <option value="">Select</option>
                                    <option *ngFor="let user of userList" [value]="user.uk_user_type">{{user.uk_user_type}}</option>
                                </select>
                                <!-- <div *ngIf="submitted && f.user_type.errors" class="invalid-feedback">
                                    <div *ngIf="f.user_type.errors.required">User Type is required</div>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                      <div class="form-group">
                          <label class="control-label">User Name </label>
                          <div>
                             <select class="form-control form-control-sm" formControlName="user_id"
                                  >
                                  <option value="null" selected>Select</option>
                                  <option *ngFor="let item of userNames" [value]="item.user_id">{{item.first_name}} {{item.last_name}}</option>
                              </select>
                      
                          </div>
                      </div>
                  </div>
                      <div class='col-md-4' style="margin-top: 2rem;"  >
                        <button type="submit" class="btn btn-sm btn-primary" value="Submit" (focus)="dp1.hide()">Submit</button>&nbsp;&nbsp;
                         <button type="button" class="btn btn-sm btn-danger" (click)="clear()">Clear</button> &nbsp;&nbsp;&nbsp;
                         <button type="button" class="btn  btn-sm btn-secondary  text-center" *ngIf="excelData && excelData.length>0" (click)="downLoadExcel()" > <i class="fa fa-file-excel-o"></i>&nbsp;Export</button> &nbsp; &nbsp;
                      
                        </div>
                      
                    </div>
                  </div>
                </div>
              </form>
  
              <div class="table-responsive">
                <table datatable id="attendanceReport" class="table table-striped table-bordered table-sm" style="width:100%">
                  <thead class="text-center">
                    <tr>
                      <th class="singleLine">S No </th>
                      <th class="singleLine">District</th>
                      <th class="singleLine">Block</th>
                      <th class="singleLine">Centre</th>
                      <th class="singleLine">User Name</th>
                      <th class="singleLine">Mobile</th>
                      <th class="singleLine">Email</th>
                      <th class="singleLine">Attendance Date</th>
                      <th class="singleLine">Start Time</th>
                      <th class="singleLine">End Time</th>
                      <th class="singleLine">In Latitude</th>
                      <th class="singleLine">In Longitude</th>
                      <!-- <th class="singleLine">In Distance</th> -->
                      <th class="singleLine">Out Latitude</th>
                      <th class="singleLine">Out Longitude</th>
                       <!-- <th class="singleLine">Out Distance</th> -->

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of attendancelist;let i= index;">
                      <td class="singleLine text-center">{{i+1}}</td>
                      <td class="singleLine">{{item?.district_name}}</td>
                      <td class="singleLine">{{item?.mandal_name}}</td>
                      <td class="singleLine">{{item?.center_name}}</td>
                      <td class="singleLine text-uppercase">{{item.user_name}}</td>
                      <td class="singleLine" style="width: 15%;">{{item.mobile}}</td>
                      <td class="singleLine" style="width: 15%;">{{item.email}}</td>
                      <td class="singleLine" style="width: 15%;">{{item.attendance_date | date:'dd-MM-yyyy'}}</td>
                      <td class="singleLine" style="width: 15%;">{{item.start_time}}</td>
                      <td style="width: 15%;">{{item.end_time}}</td>
                      <td style="width: 15%;">{{item.login_latitude | number : '1.2-5'}}</td>
                      <td style="width: 15%;">{{item.login_longitude | number : '1.2-5'}}</td>
                      <!-- <td style="width: 15%;">{{item.end_time}}</td> -->
                      <td style="width: 15%;">{{item.logout_latitude | number : '1.2-5'}}</td>
                      <td style="width: 15%;">{{item.logout_longitude | number : '1.2-5'}}</td>
                      <!-- <td style="width: 15%;">{{item.end_time}}</td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
  
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </div>
