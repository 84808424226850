import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { Common } from 'src/app/common';
import { MasterService } from 'src/app/shared/services/master.service';
import { RegistrationService } from 'src/app/shared/services/registration.service';
import { FileService } from 'src/app/shared/services/file.service';
import { UserService } from 'src/app/shared/services/user.service';
import { BaseComponent } from 'src/app/shared/base.component';
import { EnrollcorpempcontrolsComponent } from '../enrollcorpempcontrols/enrollcorpempcontrols.component';
import { AuthenticationService } from 'src/app/shared/services/authentication.service';
import * as XLSX from 'xlsx';
declare let $: any;


@Component({
  selector: 'app-enrollcorpemplist',
  templateUrl: './enrollcorpemplist.component.html',
  styleUrls: ['./enrollcorpemplist.component.css']
})

export class EnrollcorpemplistComponent extends BaseComponent implements OnInit {

  modalTitle = ''; slctCmpny = ''; companyList = []; lstAll = []; corpEmpList = []; chkdCnt = 0; isChkdAll = false; rbAct = false;
  OrgId: string;
  fileName = 'enroll corporate employee list.xlsx';

  constructor(private modalService: NgbModal
    , private toastr: ToastrService
    , private registrationService: RegistrationService
    , private masterService: MasterService
    , private fileSvc: FileService
    , private userservice: UserService
    , private authSvc: AuthenticationService) {
    super();
    authSvc.crntOrgIDSubject.subscribe(s => {
      this.corpEmpList = [], Common.loadDataTable(this.corpEmpList, '#enrollcorporateTable');
      sessionStorage.setItem('cmpnySlct', this.slctCmpny), this.OrgId = sessionStorage.getItem('currentTenant');
      if (location.href.includes('home/enrlEmpList'))
        this.ngOnInit();
      this.getAllcmpany(this.slctCmpny), setTimeout(() => { let oldSlctCmpnyVal = sessionStorage.getItem('cmpnySlct');
        this.slctCmpny = this.companyList.filter(f => f.id == oldSlctCmpnyVal).length > 0 ? oldSlctCmpnyVal : '';
      }, 500);
    }), authSvc.isShowGlblOrg.next(true);
  }

  ngOnInit(): void {
    // this.getAllCorporateId();    
    this.companyList = [], this.getcorporatebyorgid(this.OrgId)
  }

  onImport(evnt: any) {
    if (evnt.target.files.length > 0) {
      var plFrmData = new FormData();
      if (evnt.target.files[0]) {
        plFrmData.append("file", evnt.target.files[0]);
        // var showMsg = setTimeout(() => { this.toastr.success("Data Imported Successfully"), this.getAllcmpany(this.slctCmpny); }, 1000);
        this.fileSvc.upldFile(plFrmData).subscribe(s => {
          if (s.status == "OK") // (s.length == 0)
            this.toastr.success("Data Imported Successfully"), this.getAllcmpany(this.slctCmpny);
          else
            this.toastr.error(`Phone No '${s[0].mobile}' is already exists, try with other number`); // clearTimeout(showMsg), 
        });
      }
      else
        this.toastr.error('Please upload image files like xls/xlsx');
    }
  }

  onBtnImport() {
    document.getElementById('xlImprt')?.click();
  }

  onChkAll() {
    this.isChkdAll = !this.isChkdAll, this.corpEmpList.forEach(e => { e.isChk = this.isChkdAll; }), this.chkdCnt = this.isChkdAll ? this.corpEmpList.length : 0;
  }

  onRowChk(indx: number, isChkd: boolean) {
    this.corpEmpList[indx].isChk = isChkd, this.chkdCnt = 0, this.corpEmpList.forEach(e => { if (e.isChk) this.chkdCnt++; });
  }

  openModal(item: any = null) {
    const modalRef = this.modalService.open(EnrollcorpempcontrolsComponent, { scrollable: true, size: 'xl' });
    modalRef.componentInstance.title = this.modalTitle;
    modalRef.componentInstance.editData = item;
    modalRef.result.then(result => {
      if (result == 'changes saved')
        this.corpEmpList = [], Common.loadDataTable(this.corpEmpList, '#enrollcorporateTable'), this.getAllcmpany(this.slctCmpny);
    }, (reason) => { });
  }

  // getAllCorporateId() {
  //   this.userservice.getAll('corporate/getallcorporates').subscribe(res => {
  //     if (res.status === "OK") {
  //       if (res.data == null || res.data.length == 0)
  //         this.toastr.info('No records available', 'Company List');
  //       else
  //         this.companyList = res.data;
  //     }
  //     this.corpEmpList = [], Common.loadDataTable(this.corpEmpList, "#enrollcorporateTable");
  //   });
  // }
  getcorporatebyorgid(orgId: any) {
    if (orgId != '') {
      this.corpEmpList = [], this.userservice.getOrgById(orgId).subscribe(res => {
        if (res) {
          if (res.status == "OK") {
            if (res.data == null || res.data.length == 0)
              this.toastr.info('No records available', 'Company List');
            else
              this.companyList = res.data;
          }
        }
        else
          this.corpEmpList = [], Common.loadDataTable(this.corpEmpList, "#enrollcorporateTable");
      });
    }
    else
      this.corpEmpList = [], Common.loadDataTable(this.corpEmpList, "#enrollcorporateTable");
  }

  getAllcmpany(corporteId: string) {
    $('.dataTable').DataTable().destroy(), $('.dataTable').DataTable({ destroy: true, searching: false });
    if (corporteId != '') {
      this.corpEmpList = [], this.registrationService.GetBenfbyComp(+corporteId).subscribe(res => {
        if (res) {
          if (res.status == "OK") {
            if (res.data == null || res.data.length == 0)
              this.toastr.info('No records available', 'Company List');
            else
              res.data.forEach(e => { Object.assign(e, { isChk: false }); }), this.lstAll = res.data, this.corpEmpList = res.data.filter(f => f.is_active == this.rbAct);
            Common.loadDataTable(this.corpEmpList, "#enrollcorporateTable");
          }
        }
        else
          this.corpEmpList = [], Common.loadDataTable(this.corpEmpList, "#enrollcorporateTable");
      });
    }
    else
      this.corpEmpList = [], Common.loadDataTable(this.corpEmpList, "#enrollcorporateTable");
  }

  onChngRB() {
    this.chkdCnt = this.rbAct ? 0 : this.lstAll.filter(f => f.isChk == true).length, this.isChkdAll = this.chkdCnt == this.lstAll.filter(f => f.is_active == this.rbAct).length,
      this.corpEmpList = this.lstAll.filter(f => f.is_active == this.rbAct), Common.loadDataTable(this.corpEmpList, "#enrollcorporateTable");
  }

  onApprove() {
    let aprvLst = [];
    this.corpEmpList.forEach(e => { if (e.isChk) aprvLst.push({ id: e.user_id, is_active: true }); });
    if (aprvLst.length > 0) {
      this.registrationService.ActiveBenf(JSON.stringify(aprvLst)).subscribe(res => {
        if (res) {
          if (res.status == "OK")
            this.toastr.success('Record(s) Approved Successfully'), this.getAllcmpany(this.slctCmpny);
          else
            this.toastr.error(res.message);
        }
        else
          this.toastr.error(res.message);
      });
    }
  }

  deleterowById(beneficiaryId: any) {
    if (confirm(`Are you sure you want delete record?`)) {
      this.registrationService.deleteBybeneficiaryId(beneficiaryId).subscribe(res => {
        if (res.status == "OK")
          this.toastr.success(res.message, 'Enroll Corporate Employee List'), this.getAllcmpany(this.slctCmpny);
      }, error => { this.toastr.error('An Error Occured'); });
    }
  }
  exportexcel(): void {
    var table = $('#enrollcorporateTable').DataTable();
    if (!table.data().count()) {
      this.toastr.error('No data available in table! Please select and try again');
    }
    else {	
      / table id is passed over here /
      let element = document.getElementById('enrollcorporateTable');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

      / generate workbook and add the worksheet /
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
      ws['!cols'] = [];
      ws['!cols'][10] = { hidden: true };

      / save to file /
      XLSX.writeFile(wb, this.fileName);
    }
  }

}